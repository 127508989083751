import { UIManager } from './uiManager';
import { UIElement } from './uiElement';
import { UIElementOptions } from "./uiElement";
import { UIBounds } from './uiBounds';

export interface UITextOptions extends UIElementOptions {
  text: string;
  fontSize?: number;
  fontFamily?: string;
  fontStyle?: string;
  fontWeight?: string;
  color?: string;
  verticalAlign?: string;
  horizontalAlign?: string;
  margin?: UIBounds;
  strokeLineWidth?: number;
  strokeColor?: string;
  autoSize?: boolean;
  autoWidth?: boolean;
  autoHeight?: boolean;
  wordWrap?: boolean;
  shadow?: {
    color?: string,
    blur?: number,
    offset?: {
      x?: number,
      y?: number
    }
  }
}

export class UIText extends UIElement
{
  public text: string;
  public fontSize: number;
  public fontFamily: string;
  public fontStyle: string;
  public fontWeight: string;
  public color: string;
  public verticalAlign: string;
  public horizontalAlign: string;
  public margin: UIBounds;
  public strokeLineWidth: number;
  public strokeColor: string;
  public autoWidth: boolean;
  public autoHeight: boolean;
  public wordWrap: boolean;
  public shadow: any;

  protected textWidth: number[];
  protected textHeight: number;
  protected lastText: string;
  protected lastFontSize: number;
  protected lastFontFamily: string;
  protected lastFontWeight: string;
  protected lines: string[];
  protected widestText: number;
  protected lineHeight: number;

  constructor(options: UITextOptions)
  {
    super(options);

    this.text = '';
    this.fontSize = 20;
    this.fontStyle = '';
    this.fontFamily = 'verdana';
    this.fontWeight = 'normal';
    this.color = '#000000';
    this.horizontalAlign = 'left';
    this.verticalAlign = 'top';
    this.textWidth = [];
    this.textHeight = 0;
    this.margin = {left: 0, top: 0, right: 0, bottom: 0};
    this.strokeLineWidth = 0;
    this.strokeColor = 'black';
    this.autoWidth = true;
    this.autoHeight = true;
    this.lines = [];
    this.lineHeight = 0;
    this.wordWrap = false;
    this.fontStyle = '';
    this.shadow = null;
    this.lastText = '';
    this.lastFontSize = 0;
    this.lastFontFamily = '';
    this.lastFontWeight = '';
    this.widestText = 0;

    if(options.text)
      this.text = options.text;
    if(options.fontSize)
      this.fontSize = options.fontSize;
    if(options.fontStyle)
      this.fontStyle = options.fontStyle;
    if(options.fontWeight)
      this.fontWeight = options.fontWeight;
    if(options.fontFamily)
      this.fontFamily = options.fontFamily;
    if(options.color)
      this.color = options.color;
    if(options.horizontalAlign)
      this.horizontalAlign = options.horizontalAlign;
    if(options.verticalAlign)
      this.verticalAlign = options.verticalAlign;
    if(options.margin)
      this.margin = options.margin;
    if(options.strokeLineWidth)
      this.strokeLineWidth = options.strokeLineWidth;
    if(options.strokeColor)
      this.strokeColor = options.strokeColor;
    if(options.autoSize != null) {
      this.autoWidth = options.autoSize;
      this.autoHeight = options.autoSize;
    }
    if(options.autoWidth != null)
      this.autoWidth = options.autoWidth;
    if(options.autoHeight != null)
      this.autoHeight = options.autoHeight;
    if(options.wordWrap != null)
      this.wordWrap = options.wordWrap;
    if(options.shadow)
      this.shadow = options.shadow;
 }

  protected recalculateTextSize()
  {
    if(this.lastText == this.text &&
      this.lastFontFamily == this.fontFamily &&
      this.lastFontSize == this.fontSize &&
      this.lastFontWeight == this.fontWeight)
      return;

    this.lineHeight = this.fontSize + Math.floor(this.fontSize * 0.25);

    var lines:string[] = [];
    if(this.text && this.text.length > 0)
      lines = this.text.split('\n');

    this.lines = [];
    for(var i = 0; i < lines.length; i++) {
      var line = lines[i];

      if(this.wordWrap) {
        var words = line.split(' ');
        var workingLine = '';

        for(var j = 0; j < words.length; j++) {
          var testLine = workingLine;
          if(testLine.length > 0)
            testLine += ' ';
          testLine += words[j];

          var testLineWidth = UIManager.ctx.measureText(testLine).width;

          if(testLineWidth < this.width) {
            workingLine = testLine;
          }
          else {
            this.lines.push(workingLine);
            workingLine = '';
            j--;
          }
        }

        line = workingLine;
      }

      this.lines.push(line);
    }

    this.textWidth = [];
    for(var i = 0; i < this.lines.length; i++) {
      var tw = UIManager.ctx.measureText(this.lines[i]).width;

      this.textWidth.push(tw);
    }
    this.textHeight = this.lineHeight * this.lines.length;

    this.widestText = 0;
    for(var i = 0; i < this.textWidth.length; i++)
      if(this.widestText < this.textWidth[i])
      this.widestText = this.textWidth[i];

    this.lastText = this.text;
    this.lastFontFamily = this.fontFamily;
    this.lastFontSize = this.fontSize;
    this.lastFontWeight = this.fontWeight;
  }

  public draw()
  {
    //UIManager.ctx.fillStyle = '#00aa0088';
    //UIManager.ctx.fillRect(this.getScreenX(), this.getScreenY(), this.width, this.height);

    UIManager.ctx.font = this.fontWeight + ' ' + this.fontSize + 'px ' + this.fontFamily;
    if (this.fontStyle !== '') {
      UIManager.ctx.font += ' ' + this.fontStyle;
    }
    UIManager.ctx.fillStyle = this.color;
    UIManager.ctx.textAlign = 'left';
    UIManager.ctx.textBaseline = 'bottom';

    this.recalculateTextSize();

    if(this.autoWidth)
      this.size.x = this.widestText;

    if(this.autoHeight)
      this.size.y = this.textHeight;

    for(var i = 0; i < this.lines.length; i++) {
      var xoffset: number = 0;
      switch(this.horizontalAlign)
      {
        case 'left':
          xoffset = this.margin.left;
          break;
        case 'right':
          xoffset = this.width - this.textWidth[i] - this.margin.right;
          break;
        case 'center':
          xoffset = (this.width - this.textWidth[i]) / 2;
          break;
      }

      var yoffset: number = 0;
      switch(this.verticalAlign)
      {
        case 'top':
          yoffset = this.lineHeight + this.margin.top;
          break;
        case 'bottom':
          yoffset = this.height - this.margin.bottom;
          break;
        case 'center':
          yoffset = ((this.height - this.textHeight) / 2) + this.lineHeight;
          break;
      }

      if(this.strokeLineWidth > 0) {
        UIManager.ctx.strokeStyle = this.strokeColor;
        UIManager.ctx.lineWidth = this.strokeLineWidth;
        UIManager.ctx.strokeText(this.lines[i], this.getScreenX() + xoffset, this.getScreenY() + yoffset + ((this.lineHeight) * i));
      }

      if(this.shadow) {
        UIManager.ctx.save();
        UIManager.ctx.shadowOffsetX = this.shadow.offset.x;
        UIManager.ctx.shadowOffsetY = this.shadow.offset.y;
        UIManager.ctx.shadowColor = this.shadow.color;
        UIManager.ctx.shadowBlur = this.shadow.blur;
      }

      UIManager.ctx.fillText(this.lines[i], this.getScreenX() + xoffset, this.getScreenY() + yoffset + ((this.lineHeight) * i));

      if(this.shadow)
        UIManager.ctx.restore();
    }
  }
}