export function numberWithCommas(x: number): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function hashString(str: string): number {
  let hashedValue = 30744573451;
  for (let i = 0; i < str.length; i++) {
    let val = +str.charCodeAt(i);
    hashedValue += val;
    hashedValue *= 30744573451;
    hashedValue %= 274877906944;
  }
  return hashedValue;
}

export function getDeviceType() {
  let height = window.outerHeight;
  let width = window.outerWidth;

  let returnStr = '.';
  if (width === 320 && height === 480) {
    returnStr = 'iPhone 4';
  }
  else if (width === 320 && height === 533) {
    returnStr = 'Nokia Lumina';
  }
  else if (width === 320 && height === 568) {
    returnStr = 'iPhone 5/SE';
  }
  else if (width === 360 && height === 640) {
    returnStr = 'Moto G4/Galaxy S5/Galaxy Note 3/Nexus 5';
  }
  else if (width === 375 && height === 667) {
    returnStr = 'iPhone 6/7/8';
  }
  else if (width === 375 && height === 812) {
    returnStr = 'iPhone X';
  }
  else if (width === 384 && height === 640) {
    returnStr = 'Nexus 4';
  }
  else if (width === 411 && height === 731) {
    returnStr = 'Pixel 2';
  }
  else if (width === 411 && height === 823) {
    returnStr = 'Pixel 2 XL';
  }
  else if (width === 412 && height === 732) {
    returnStr = 'Nexus 5X/Nexus 6/Nexus 6P';
  }
  else if (width === 414 && height === 736) {
    returnStr = 'iPhone 6/7/8+';
  }
  else if (width === 480 && height === 854) {
    returnStr = 'Nokia N9';
  }
  else if (width === 600 && height === 960) {
    returnStr = 'Nexus 7';
  }
  else if (width === 768 && height === 1024) {
    returnStr = 'iPad/iPad Mini';
  }
  else if (width === 800 && height === 1280) {
    returnStr = 'Nexus 10/Kindle Fire';
  }
  else if (width === 1024 && height === 1366) {
    returnStr = 'iPad Pro';
  }
  else if (width === 1112 && height === 834) {
    returnStr = '10.5-inch iPad Pro';
  }

  return `${returnStr} (${width}x${height})`;
}

export function fitInRange(value: number, min: number, max: number, defaultVal: number): number {
  if (isNaN(value)) {
    return defaultVal;
  }

  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }

  return value;
}

export function getTimeString(time: number): string {
  let minutes = Math.floor(time / 60000);
  let seconds = Math.floor((time % 60000) / 1000);
  let tenth = Math.floor((time % 1000) / 100);
  let padding = seconds < 10 ? '0' : '';
  // return `${minutes} : ${padding}${seconds}.${tenth}`;
  return `${minutes}:${padding}${seconds}`;
}

/**
 * Generates a random integer number between min and max
 * @param min 
 * @param max 
 * @returns random integer number
 */
export function randomNum(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}


let randomNumberSeed = 0;
let randomNoiseTable = [
  59576763,  964850402, 945970468, 425702901, 921367513, 537127926, 145890335, 508321418, 246160880, 486114288,
  360727618, 712552078, 32629124,  916095981, 757964281, 979388998, 834833282, 476508413, 27411981,  656977687,
  227434434, 741452512, 936277105, 683453430, 643059366, 76187854,  183288406, 969804877, 346940299, 545271154,
  816078355, 803897950, 777893621, 213054772, 790211359, 37238359,  656479723, 229814448, 229814448, 229814448,
  572122456, 884624320, 279852942, 780210820, 830058227, 941720849, 664047918, 714945388, 26766689,  346228495,
  688563538, 628285790, 904721702, 985578764, 967769261, 345956138
];
let randomTable = [];
let n24 = 24;
let n55 = 55;

export function setRandomSeed(seed:number) {
  randomNumberSeed = seed;
  randomTable = [];
  n24 = 24;
  n55 = 55;

  for (let i = 0; i < randomNoiseTable.length; i++) 
    randomTable.push(randomNoiseTable[i] + seed);

  let loopCount = 1000 + seed % 1024;
  for (let i = 0; i < loopCount; i++) 
    getRandom();
}

export function getRandom() {
  let random = randomTable[n55] + randomTable[n24];
  randomTable[n55] += randomTable[n24];

  // Keep numbers in our table below 2 to the 50th
  randomTable[n55] %= 1125899906842624;

  n55++;
  if (n55 === 56) 
    n55 = 0;

  n24++;
  if (n24 === 56) 
    n24 = 0;

  // scale 0 to 1
  random %= 1234567890;
  random /= 1234567889;

  return random;
}

export function isLocalHost(): boolean {
  if(window.location.hostname.indexOf('localhost') != -1)
    return true;

  if(window.location.hostname.indexOf('192.168') != -1)
    return true;

  if(window.location.hostname.indexOf('10.0.') != -1)
    return true;

  return false;
}


export function getNumberSuffix(v:number) {
  let suffix = 'th';
  let r = v - Math.floor(v/10)*10;
  if(r == 1)
    suffix = 'st';
  else if(r == 2)
    suffix = 'nd';
  else if(r == 3)
    suffix = 'rd';
  return suffix;
}
