import React from 'react';
import { Database } from '../util/database';
import { getAssetImage } from '../util/assets';
import PageEnvelope from '../elements/PageEnvelope';
import './ShopPage.css';
import { Server } from '../../server/server';
import { Navigate } from 'react-router-dom';
import AlertModal from '../modals/AlertModal';
import QuestionModal from '../modals/QuestionModal';
import MessageModal from '../modals/MessageModal';
import CoinIcon from '../elements/CoinIcon';
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill, BsFillXCircleFill } from 'react-icons/bs';
import AssetPanel from '../elements/AssetPanel';

interface ShopPageState {
  navigate: string;
  alert: string;
  question: string;
  message: string;
  category: string;
  modal: string;
  asset: string;
  quantity: number;
  index: number;
  assets: any[];
}

class ShopPage extends React.Component<{}, ShopPageState> {
  protected shopAssetToPurchase:any;

  constructor(props: {}) {
    super(props);
    this.state = {
      navigate: '',
      alert: '',
      question: '',
      message: '',
      category: 'All',
      modal: '',
      asset: '',
      quantity: 0,
      index: -1,
      assets: null
    }
  }

  componentDidMount(): void {
    // let category = window.sessionStorage.getItem('ShopCategory');
    // if(category)
    //   this.setState({category});
  }

  componentWillUnmount(): void {
    // window.sessionStorage.setItem('ShopCategory', this.state.category);
  }

  async purchaseAsset(assetId:string, quantity:number, packId:string = null) {
    let asset = Database.getAsset(assetId);
    let shopAsset = Database.getShopAsset(packId ? packId : assetId);

    if(shopAsset.currency != 'usd') {
      let currencyQuantity = Server.assets.getInventoryQuantity(shopAsset.currency);
      if(shopAsset.price > currencyQuantity) {
        this.setState({alert: 'You do not have enough Coins to make this purchase.'});
        return;
      }
    }

    let assetName = `${quantity > 1 ? `${quantity} ` : ''}${asset.name}`;
    if(packId) {
      let packAsset = Database.getAsset(packId);
      assetName = packAsset.name;
    }

    this.setState({message: `Purchasing ${assetName}...`});
    
    setTimeout(async () => {
      let response = await Server.assets.purchaseAsset(asset.id, quantity, packId);
      
      this.setState({message: ''});

      let assets = [];

      if(response.success) {
        if(response.assets != undefined)
          assets = response.assets;
        else
          assets = [{id: asset.id, quantity: quantity}];

        for(let i = 0; i < assets.length; i++)
          Server.assets.addAssetToInventory(assets[i].id, assets[i].quantity);

        if(shopAsset.currency != 'usd')
          Server.assets.removeAssetFromInventory(shopAsset.currency, shopAsset.price * quantity);

        Server.user.incrementCounter('purchase#' + asset.id, quantity)

        if(response.assets)
          this.setState({modal: 'assets', assets: response.assets})
        else
          this.forceUpdate();
      }
      else
        this.setState({alert: response.message});
    }, 500);
  }

  onPackClick(shopAsset:any) {
    if(!Server.account.isLoggedIn() && shopAsset.currency == 'usd') {
      this.setState({alert: 'Please login to continue.'});
      return;
    }

    let asset = Database.getAsset(shopAsset.id);
    if(shopAsset.currency == 'usd') {
      this.shopAssetToPurchase = shopAsset;
      this.setState({question: `Do you want to purchase ${asset.name} for $${shopAsset.price.toFixed(2)}?`});
    }
    else if(shopAsset.choose) {
      this.setState({modal: 'select', asset: asset.id, index: -1, quantity: 1});
    }
    else
      this.setState({modal: 'purchase', asset: asset.id, quantity: 1});
  }

  onQuestionYes() {
    this.setState({question: ''});
    this.purchaseAsset(this.shopAssetToPurchase.id, 1);
  }

  onDecreaseQuantity() {
    if(this.state.quantity > 1)
      this.setState({quantity: this.state.quantity - 1});
  }

  onIncreaseQuantity() {
    this.setState({quantity: this.state.quantity + 1});
  }

  onPurchasePack() {
    this.purchaseAsset(this.state.asset, this.state.quantity);
  }

  onPurchaseAssetFromPack(selectedAsset:string) {
    if(!selectedAsset) {
      this.setState({alert: 'Please make a selection to continue.'});
      return;
    }

    this.purchaseAsset(selectedAsset, this.state.quantity, this.state.asset)
  }

  onSetBanner(asset:any) {
    this.setState({message: `Updating profile...`});
    
    setTimeout(async () => {
      let response = await Server.user.setProfile({banner: asset.id});
      this.setState({message: ''});
      if(response.success) {
        Server.user.setCachedBanner(asset.id);
        this.setState({navigate: '/profile'})
      }
      else
        this.setState({alert: response.message})
    }, 500);

  }

  onSetPortrait(asset:any) {
    this.setState({message: `Updating profile...`});
    
    setTimeout(async () => {
      let response = await Server.user.setProfile({portrait: asset.id});
      this.setState({message: ''});
      if(response.success) {
        Server.user.setCachedPortrait(asset.id);
        this.setState({navigate: '/profile'})
      }
      else
        this.setState({alert: response.message})
    }, 500);

  }

  renderPanel(shopAsset:any, key:number) {
    let asset = Database.getAsset(shopAsset.id);
    let price = null;
    if(shopAsset.currency == 'usd')
      price = <div>${shopAsset.price.toFixed(2)}</div>
    else
      price = <CoinIcon quantity={shopAsset.price} />

    return (
      <div key={key} className="site-page-panel" style={{cursor: 'pointer', padding: '10px'}} onClick={()=>this.onPackClick(shopAsset)}>
        <div className="site-page-column" style={{rowGap: '10px'}}>
          <div style={{width: '100%', aspectRatio: '1', backgroundColor: 'var(--body-background-color)', borderRadius: '6px', position: 'relative'}} >
            <img 
              style={{width: '100%', aspectRatio: '1', borderRadius: '6px', objectFit: 'cover', position: 'absolute', top: '0px', bottom: '0px', right: '0px', left: '0px'}} 
              src={getAssetImage(asset)} 
            />
          </div>
          {/* <div style={{textAlign: 'center', fontSize: '0.9em', height: '45px'}}>
            {asset.name}
          </div> */}
          <button style={{borderRadius: '6px'}}>{price}</button>
        </div>
      </div>
    )
  }

  renderHeader() {
    let shop = Database.getShop();
    let options = [<option key={-1} value="All">All</option>];

    // let categories = [];
    // for(let i = 0; i < shop.length; i++)
    //   if(categories.indexOf(shop[i].category) == -1)
    //     categories.push(shop[i].category);

    // categories.sort();

    // for(let i = 0; i < categories.length; i++) {
    //   options.push(
    //     <option key={i} value={categories[i]}>{categories[i]}</option>
    //   )
    // }

    let coins = Server.assets.getInventoryQuantity('coin');

    return (
      <div className="site-page-panel" style={{flexDirection: 'row', justifyContent: 'space-between', columnGap: '10px'}}>
          <select style={{width: '100%', maxWidth: '200px'}} value={this.state.category} onChange={(e:any)=>this.setState({category: e.currentTarget.value})}>
            {options}
          </select>
          <div className="site-page-row" style={{maxWidth: '150px', height: '20px', justifyContent: 'right', alignItems: 'center', backgroundColor: 'var(--body-background-color)', borderRadius: '6px', padding: '10px'}}>
            <CoinIcon quantity={coins} />
          </div>
      </div>
    )
  }

  renderPurchasePackModal() {
    let asset = Database.getAsset(this.state.asset);
    let shopAsset = Database.getShopAsset(this.state.asset);
    let coins = Server.assets.getInventoryQuantity('coin');
    let total = shopAsset.price * this.state.quantity;

    let lines = asset.description.split('<br/>');
    let descDivs = [];
    for(let i = 0; i < lines.length; i++)
      descDivs.push(<div key={i}>{lines[i]}</div>)

    // let description = <div>{'Line 1'}<br/>{'Line 2'}</div>;

    return (
      <div className="modal open">
        <div className="modal-content" style={{paddingTop: '8px'}}>
          <div className="site-page-column" style={{rowGap: '15px', color: 'var(--panel-subtext-color)'}}>
            <div className="site-page-column">
              <div style={{color: 'white', fontSize: '1.1em', textAlign: 'left'}}>
                {asset.name}
              </div>
              <div style={{display: 'grid', gridTemplateColumns: '105px 1fr', columnGap: '10px', backgroundColor: 'var(--body-background-color)', borderRadius: '6px', padding: '10px', }}>
                <img src={getAssetImage(asset)} style={{width: '100%', borderRadius: '6px'}} />
                <div className="site-page-column" style={{rowGap: '0px', textAlign: 'left', fontSize: '0.9em'}}>
                  {descDivs}
                </div>
              </div>
            </div>
            <div className="site-page-column" style={{rowGap: '5px'}}>
              <div>How many {asset.noun.plural} do you want?</div>
              <div className="site-page-row" style={{justifyContent: 'center', alignItems:'center'}}>
                <div style={{transform: 'translateY(4px)'}} onClick={()=>this.onDecreaseQuantity()}><BsFillArrowLeftCircleFill size={25} /></div>
                <input style={{width: '80px', marginTop: '5px', textAlign: 'center'}} value={this.state.quantity} onChange={(e:any)=>this.setState({quantity: +e.currentTarget.value})}/>
                <div style={{transform: 'translateY(4px)'}} onClick={()=>this.onIncreaseQuantity()}><BsFillArrowRightCircleFill size={25} /></div>
              </div>
            </div>
            <div className="site-page-column" style={{rowGap: '5px'}}>
              <div>Each {asset.noun.singular} costs <CoinIcon quantity={shopAsset.price} /></div>
              {this.state.quantity > 1 &&
                <div>{this.state.quantity} {asset.noun.plural} will cost <CoinIcon quantity={total} /></div>
              }
              <div>You have <CoinIcon quantity={coins} /></div>
            </div>
            {total > coins && 
              <div className="site-page-notification-panel">You do not have enough Coins.</div>
            }
            <div style={{textAlign: 'center'}} >
              <button onClick={()=>this.onPurchasePack()}>Purchase</button>
            </div>
          </div>
          <BsFillXCircleFill className="site-page-header-close-button" onClick={()=>this.setState({modal: ''})}/>
        </div>
      </div>
    )
  }

  renderSelectAssetModal() {
    let asset = Database.getAsset(this.state.asset);
    let pack = Database.getPack(this.state.asset);
    let shopAsset = Database.getShopAsset(this.state.asset);
    let total = shopAsset.price * this.state.quantity;
    let loot = pack.slots[0].loot;
    let assets = [];

    for(let i = 0; i < loot.length; i++) {
      let aid = loot[i].asset;
      let idx = aid.indexOf('-*');
      if(idx == -1) {
        assets.push(loot[i].asset);
      }
      else {
        let base = aid.substring(0, idx+1);
        let count = +aid.substring(idx+2);
        for(let j = 0; j < count; j++) 
          assets.push(base + (j+1).toString().padStart(3, '0'))
      }
    }

    assets.sort((a, b)=>{
      let aasset = Database.getAsset(a);
      let basset = Database.getAsset(b);
      if(aasset.name > basset.name)
        return 1;
      else if(aasset.name < basset.name)
        return -1;
      return 0;
    })
    
    let divs = [];
    let firstAsset = null;

    for(let i = 0; i < assets.length; i++) {
      let asset = Database.getAsset(assets[i]);
      let imageHeight = 'unset';
      let imageWidth = '150px';
      let borderRadius = '6px';
      let imageMargin = '0px';
      let objectFit:any = 'unset';
      let borderColor = (i == this.state.index) ? 'yellow' : 'var(--body-background-color)';

      if(!firstAsset)
        firstAsset = asset;
  
      if(asset.collection == 'portraits')
        borderRadius = '50%';
  
      if(asset.collection == 'banners') {
        imageWidth = '100%';
        imageHeight = '80px';
        objectFit = 'cover';
      }

      let fontSize = asset.collection == 'fantasy-gems' ? '0.8em' : '0.9em';
      
      let quantity = 0; //Server.assets.getInventoryQuantity(assets[i]);

      divs.push(
        <div key={i} className="site-page-column" style={{width: '100%', rowGap: '5px', position: 'relative'}}>
          <img 
            key={i}
            style={{
              width: '100%',
              borderRadius, 
              margin: imageMargin,
              objectFit: objectFit,
              cursor: 'pointer',
              boxShadow: '0 0 0 2px ' + borderColor,
              display: 'block'
            }} 
            onClick={()=>this.setState({index: i})}
            src={getAssetImage(asset)} 
          />
          <div style={{fontSize}}>{asset.name ? asset.name : ''}</div>
          {quantity > 0 &&
            <div style={{minWidth: '15px', textAlign: 'center', borderTopRightRadius: '6px', borderBottomLeftRadius: '6px', fontSize: '0.7em', position: 'absolute', top: '0px', right: '0px', backgroundColor: '#000000a0', padding: '3px'}}>
              {quantity}
            </div>
          }
        </div>
      )
    }

    let columns = '1fr 1fr 1fr';
    if(firstAsset.collection == 'banners')
      columns = '1fr';

    let showQuantity = true;
    if(firstAsset.collection == 'banners' || firstAsset.collection == 'portraits')
      showQuantity = false;

    let selectedAsset = this.state.index == -1 ? null : assets[this.state.index];
    let collection = Database.getCollection(firstAsset.collection);

    return (
      <div className="modal open">
        <div className="modal-content" style={{paddingTop: '8px', position: 'relative'}}>
          <div className="site-page-column">
            <div style={{fontSize: '1.1em', textAlign: 'left'}}>
              Select {collection.noun.singular}
            </div>
            <div className="site-page-scroll-panel" style={{maxHeight: '250px'}}>
              <div style={{padding: '10px 15px 10px 10px', display: 'grid', gridTemplateColumns: columns, columnGap: '10px', rowGap: '10px'}}>
                {divs}
              </div>
            </div>
            {showQuantity &&
              <div className="site-page-column" style={{rowGap: '5px', color: 'var(--panel-subtext-color)'}}>
                <div>How many {asset.noun.plural} do you want?</div>
                <div className="site-page-row" style={{justifyContent: 'center', alignItems:'center'}}>
                  <div style={{transform: 'translateY(4px)'}} onClick={()=>this.onDecreaseQuantity()}><BsFillArrowLeftCircleFill size={25} /></div>
                  <input style={{width: '80px', marginTop: '5px', textAlign: 'center'}} value={this.state.quantity} onChange={(e:any)=>this.setState({quantity: +e.currentTarget.value})}/>
                  <div style={{transform: 'translateY(4px)'}} onClick={()=>this.onIncreaseQuantity()}><BsFillArrowRightCircleFill size={25} /></div>
                </div>
              </div>
            }
            <div className="site-page-column" style={{rowGap: '5px', color: 'var(--panel-subtext-color)'}}>
              {this.state.quantity == 1 &&
                <div>Each {collection.noun.singular.toLowerCase()} costs <CoinIcon quantity={shopAsset.price} /></div>
              }
              {this.state.quantity > 1 &&
                <div>{this.state.quantity} {asset.noun.plural} will cost <CoinIcon quantity={total} /></div>
              }
              <div>You have <CoinIcon quantity={Server.assets.getInventoryQuantity('coin')} /></div>
            </div>
            <div style={{marginTop: '5px'}}>
              <button onClick={()=>this.onPurchaseAssetFromPack(selectedAsset)}>Purchase</button>
            </div>
          </div>
          <BsFillXCircleFill className="site-page-header-close-button" onClick={()=>this.setState({modal: ''})}/>
        </div>
      </div>
    );
  }

  renderPurchasedAssetsModal() {
    let divs = [];

    let assets = [...this.state.assets];
    assets.sort((a, b)=>{
      if(a.quantity > b.quantity)
        return -1;
      else if(a.quantity < b.quantity)
        return 1;
      return 0;
    })

    for(let i = 0; i < assets.length; i++) {
      let asset = Database.getAsset(assets[i].id);
      let quantity = assets[i].quantity;
      divs.push(
        <AssetPanel
          key={i}
          asset={asset}
          quantity={quantity}
          width="110px"
          navigate={true}
        />
      )
    }

    let columns = '1fr';
    if(divs.length > 1)
      columns += ' 1fr';

    // let note = 'These items have';
    // if(assets.length == 1 && assets[0].quantity == 1)
    //   note = 'This item has';
    // note += ' been added to your inventory.';

    let banner:any = null;
    let portrait:any = null;
    if(assets.length == 1 && assets[0].quantity == 1) {
      let asset = Database.getAsset(assets[0].id);
      if(asset.collection == 'banners') 
        banner = asset;
      else if(asset.collection == 'portraits') 
        portrait = asset;
    }

    let itemsText = '';
    let firstAsset = Database.getAsset(assets[0].id);
    let collection = Database.getCollection(firstAsset.collection);

    if(assets.length == 1 && assets[0].quantity == 1) {
      itemsText = `This ${collection.noun.singular.toLowerCase()}`;
    }
    else {
      let sameCollection = true;
      for(let i = 0; i < assets.length; i++) 
        if(assets[i].collection != assets[0].collection)
          sameCollection = false;

      if(sameCollection) 
        itemsText = `These ${collection.noun.plural.toLowerCase()}`;
      else
        itemsText = 'These items';
    }

    let note = (
      <div>
        <div className="site-page-subtext">{itemsText} added to your inventory.</div>
        {banner && 
          <div style={{marginTop: '10px', color: 'white'}}>Do you want to set this as your<br/>profile banner?</div>
        }
        {portrait && 
          <div style={{marginTop: '10px', color: 'white'}}>Do you want to set this as your<br/>profile portrait?</div>
        }
      </div>
    )

    let buttons = (
      <div style={{marginTop: '5px'}}>
        <button onClick={()=>this.setState({modal: ''})}>Close</button>
      </div>
    );

    if(banner || portrait) {
      buttons = (
        <div className="site-page-row" style={{justifyContent: 'center', columnGap: '70px', marginTop: '5px'}}>
          <button onClick={()=>this.setState({modal: ''})}>No</button>
          {banner && 
            <button onClick={()=>this.onSetBanner(banner)}>Yes</button>
          }
          {portrait && 
            <button onClick={()=>this.onSetPortrait(portrait)}>Yes</button>
          }
        </div>
      )
    }

    return (
      <div className="modal open">
        <div className="modal-content site-page-column" style={{width: '300px', textAlign: 'center', rowGap: '10px'}}>
          <div>Purchase Complete!</div>
          <div className="site-page-column site-page-scroll-panel" style={{maxHeight: '400px', marginTop: '5px'}}>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: '10px', rowGap: '10px', columnGap: '10px', alignItems: 'center', justifyContent: 'center'}}>
              {divs}
            </div>
          </div>
          {note}
          {buttons}
        </div>
      </div>
    )
  }

  render() {
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />

    let shop = Database.getShop();

    let divs = [];
    for(let i = 0; i < shop.length; i++) {
      if(shop[i].developer == true && !Server.account.isDeveloper())
        continue;
      if((shop[i].category && this.state.category == 'All') || this.state.category == shop[i].category)
        divs.push(this.renderPanel(shop[i], i));
    }

    return (
      <div className="site-page">
        <PageEnvelope width="660px">
          <div className="site-page-column">
            {this.renderHeader()}
            <div className="shop-page-grid">
              {divs}
            </div>      
          </div>
        </PageEnvelope>
        {this.state.modal == 'purchase' && this.renderPurchasePackModal()}
        {this.state.modal == 'select' && this.renderSelectAssetModal()}
        {this.state.modal == 'assets' && this.renderPurchasedAssetsModal()}
        <MessageModal message={this.state.message}/>
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''}) }/>
        <QuestionModal message={this.state.question} onYes={()=>this.onQuestionYes()} onNo={()=>this.setState({question: ''})} />
      </div>
    )
  }
}

export default ShopPage;

