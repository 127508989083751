import { Server } from "../../server/server";
import { GameFooter } from "../common/gameFooter";
import { GameHeader } from "../common/gameHeader";
import { UICanvas } from "../common/ui/uiCanvas";
import { UIImage } from "../common/ui/uiImage";
import { UIManager } from "../common/ui/uiManager";
import { UIPanel } from "../common/ui/uiPanel";
import { UIPanelButton } from "../common/ui/uiPanelButton";
import { UIScreen } from "../common/ui/uiScreen";
import { UIText } from "../common/ui/uiText";
import { getTimeString, numberWithCommas } from "../common/util";
import { Game } from "./game";

const TileColors = [
  ['#efbe3a', '#f7e373', '#c59619', '#ad7119', '#f7ca42'], // yellow
  ['#ce3131', '#ef8184', '#ad2829', '#842021', '#d64142'], // red
  ['#ef7521', '#ffbe84', '#bd5d19', '#9c4508', '#ff8631'], // orange
  ['#4a61de', '#9cbaff', '#3a51c5', '#29398c', '#5271ef'], // blue
  ['#8c61ce', '#d69eef', '#7345a4', '#5a2d84', '#9c6dd6'], // purple
  ['#3ab642', '#8cf3a4', '#298e3a', '#197131', '#4aca52'], // green
  ['#42b6e6', '#9cf3ff', '#3196ce', '#196da4', '#52ceef'], // cyan
];

const ShapeTilePatterns = [
  // blocks (0-4)
  [
    'XXX',
    'XXX',
    'XXX'
  ],
  [
    'XX',
    'XX'
  ],
  [
    'X'
  ],
  [
    'XX',
    'XX',
    'XX'
  ],
  [
    'XXX',
    'XXX'
  ],
  // lines (5-8)
  [
    'XXX'
  ],
  [
    'X',
    'X',
    'X'
  ],
  [
    'XX'
  ],
  [
    'X',
    'X'
  ],
  // Ls (9-12)
  [
    'XXX',
    'X  ',
    'X  '
  ],
  [
    'XXX',
    '  X',
    '  X'
  ],
  [
    '  X',
    '  X',
    'XXX'
  ],
  [
    'X  ',
    'X  ',
    'XXX'
  ],
  // small Ls (13-20)
  [
    'XX',
    'X  ',
    'X  '
  ],
  [
    'XXX',
    '  X'
  ],
  [
    ' X',
    ' X',
    'XX'
  ],
  [
    'X  ',
    'XXX'
  ],
  [
    'XX',
    ' X',
    ' X'
  ],
  [
    '  X',
    'XXX'
  ],
  [
    'X ',
    'X ',
    'XX'
  ],
  [
    'XXX',
    'X  '
  ],
  // Ts (21-24)
  [
    'XXX',
    ' X '
  ],
  [
    ' X',
    'XX',
    ' X'
  ],
  [
    ' X ',
    'XXX'
  ],
  [
    'X ',
    'XX',
    'X '
  ],
  // corners (25-28)
  [
    'XX',
    'X '
  ],
  [
    'XX',
    ' X'
  ],
  [
    ' X',
    'XX'
  ],
  [
    'X ',
    'XX'
  ],
  // diagonals (29-34)
  [
    ' X',
    'X '
  ],
  [
    'X ',
    ' X'
  ],
  [
    ' X',
    'XX',
    'X ',
  ],
  [
    'XX ',
    ' XX'
  ],
  [
    'X ',
    'XX',
    ' X',
  ],
  [
    ' XX',
    'XX '
  ],
]

const ShapeRotationMap = [
  // blocks (0-4)
  [-1, -1],
  [-1, -1],
  [-1, -1],
  [4, 4],
  [3, 3],
  // lines (5-8)
  [6, 6],
  [5, 5],
  [8, 8],
  [7, 7],
  // Ls (9-12)
  [12, 10],
  [9, 11],
  [10, 12],
  [11, 9],
  // small Ls (13-20)
  [16, 14],
  [13, 15],
  [14, 16],
  [15, 13],
  [20, 18],
  [17, 19],
  [18, 20],
  [19, 17],
  // Ts (21-24)
  [24, 22],
  [21, 23],
  [22, 24],
  [23, 21],
  // corners (25-28)
  [28, 26],
  [25, 27],
  [26, 28],
  [27, 25],
  // diagonals (29-34)
  [30, 30],
  [29, 29],
  [32, 32],
  [31, 31],
  [34, 34],
  [33, 33],
]

const ShapeFlipMap = [
  // blocks (0-4)
  [-1, -1],
  [-1, -1],
  [-1, -1],
  [-1, -1],
  [-1, -1],
  // lines (5-8)
  [-1, -1],
  [-1, -1],
  [-1, -1],
  [-1, -1],
  // Ls (9-12)
  [12, 10],
  [11, 9],
  [10, 12],
  [9, 11],
  // small Ls (13-20)
  [19, 17],
  [18, 20],
  [17, 19],
  [20, 18],
  [15, 13],
  [14, 16],
  [13, 15],
  [16, 14],
  // Ts (21-24)
  [23, -1],
  [-1, 24],
  [21, -1],
  [-1, 22],
  // corners (25-28)
  [28, 26],
  [27, 25],
  [26, 28],
  [25, 27],
  // diagonals (29-34)
  [30, 30],
  [29, 29],
  [33, 33],
  [34, 34],
  [31, 31],
  [32, 32],
]

class Shape {
  public pattern:number;
  public tiles:string[];
  public color:number;

  constructor(pattern:number = -1, color:number = -1) {
    this.pattern = pattern;
    if(this.pattern == -1)
      this.pattern = Math.floor(Math.random() * ShapeTilePatterns.length);
    this.tiles = ShapeTilePatterns[this.pattern];
    this.color = color;
    if(this.color == -1)
      this.color = Math.floor(Math.random() * 7) + 1;
  }

  clone() {
    return new Shape(this.pattern, this.color);
  }

  getColumns() {
    return this.tiles[0].length;
  }

  getRows() {
    return this.tiles.length;
  }

  isTile(x:number, y:number) {
    return this.tiles[y].substring(x, x+1) != ' ';
  }

  getTileCount() {
    let count = 0;
    for(let i = 0; i < this.tiles.length; i++) {
      let row = this.tiles[i];
      for(let j = 0; j < row.length; j++)
        if(row.substring(j, j+1) != ' ')
          count++;
    }
    return count;
  }

  rotate(left:boolean) {
    let pattern = ShapeRotationMap[this.pattern][left ? 0 : 1];
    if(pattern == -1) return;
    this.pattern = pattern;
    this.tiles = ShapeTilePatterns[this.pattern];
  }

  flip(vertical:boolean) {
    let pattern = ShapeFlipMap[this.pattern][vertical ? 0 : 1];
    if(pattern == -1) return;
    this.pattern = pattern;
    this.tiles = ShapeTilePatterns[this.pattern];
  }

  canRotate(left:boolean) {
    return ShapeRotationMap[this.pattern][left ? 0 : 1] != -1;
  }

  canFlip(vertical:boolean) {
    return ShapeFlipMap[this.pattern][vertical ? 0 : 1] != -1;
  }
}

export class GameScreen extends UIScreen {
  protected board: any[];
  protected pool: Shape[];
  protected poolRects: any[];
  protected dragShape: Shape;
  protected dragPos: any;
  protected dragSource: number;
  protected boardRect: any;
  protected dragBoardPos: any;
  protected scoreLabel: UIText;
  protected streak: number;
  protected grace: number;
  protected score: number;
  protected showLineScore: boolean;
  protected header: GameHeader;
  protected footer: GameFooter;
  protected gameTime: number;
  protected storageBorder: boolean;
  protected poolBorder: boolean;
  protected editShapePanel: UIPanel;
  protected editShape: string;
  protected originalShape: Shape;
  protected save: any;
  protected noMoreMoves: boolean;
  protected boardWidth: number;
  protected boardPos: any;
  protected tileWidth: number;

  public build() {
    let background = new UIPanel({
      fitParent: {left: 0, right: 0, top: 0, bottom: 0},
      // color: '#455AAE',
      // color: '#333333',
      gradient: {
        startColor: 'rgb(102, 40, 164)',
        endColor: 'black',
        type: 'radial',
        radius: 2.25
      },
      parent : this
    });

    this.header = new GameHeader({
      game: Game.instance,
      callback: (action:string)=>{
        if(action == 'restart')
          this.restart();
      },
      parent : this
    });

    this.buildEditShapePanel();

    this.footer = new GameFooter({
      game: Game.instance,
      callback: (id:string)=>{
        this.onPowerup(id);
      },
      parent : this
    });
  }

  protected buildEditShapePanel() {
    this.editShapePanel = new UIPanel({
      color: '#00000040',
      size: {x: 0, y: 100},
      borderColor: 'yellow',
      borderWidth: 1,
      parent : this
    });

    let labelText = new UIText({
      text : '',
      fontFamily : 'verdana',
      fontSize : 14,
      anchor: {x: 0, y: 0.5},
      pivot: {x: 0, y: 0.5},
      position: {x: 10, y: 0},
      color: 'white',
      parent : this.editShapePanel
    });

    let firstButton = new UIPanelButton({
      size: {x: 40, y: 40},
      anchor: {x: 1, y: 0.5},
      pivot: {x: 1, y: 0.5},
      position: {x: -140, y: 0},
      callback : (btn:UIPanelButton) => {
        this.onEditShapeButton(0);
      },
      panelColors: {normal: '#00000080'},
      parent : this.editShapePanel
    });

    let firstButtonIcon = new UIImage({
      url : require('../common/assets/rotate-left.png'),
      fitParent: {left: 0, right: 0, top: 0, bottom: 0},
      stretch: true,
      parent : firstButton
    });

    let secondButton = new UIPanelButton({
      size: {x: 40, y: 40},
      anchor: {x: 1, y: 0.5},
      pivot: {x: 1, y: 0.5},
      position: {x: -95, y: 0},
      callback : (btn:UIPanelButton) => {
        this.onEditShapeButton(1);
      },
      panelColors: {normal: '#00000080'},
      parent : this.editShapePanel
    });

    let secondButtonIcon = new UIImage({
      url : require('../common/assets/rotate-right.png'),
      fitParent: {left: 0, right: 0, top: 0, bottom: 0},
      stretch: true,
      parent : secondButton
    });

    let confirmButton = new UIPanelButton({
      size: {x: 40, y: 40},
      anchor: {x: 1, y: 0.5},
      pivot: {x: 1, y: 0.5},
      position: {x: -50, y: 0},
      callback : (btn:UIPanelButton) => {
        this.onConfirmEditShape();
      },
      panelColors: {normal: '#00000080'},
      parent : this.editShapePanel
    });

    let confirmIcon = new UIImage({
      url : require('../common/assets/check-mark.png'),
      fitParent: {left: 0, right: 0, top: 0, bottom: 0},
      stretch: true,
      parent : confirmButton
    });

    let cancelButton = new UIPanelButton({
      size: {x: 40, y: 40},
      anchor: {x: 1, y: 0.5},
      pivot: {x: 1, y: 0.5},
      position: {x: -5, y: 0},
      callback : (btn:UIPanelButton) => {
        this.onCancelEditShape();
      },
      panelColors: {normal: '#00000080'},
      parent : this.editShapePanel
    });

    let cancelIcon = new UIImage({
      url : require('../common/assets/cancel.png'),
      fitParent: {left: 0, right: 0, top: 0, bottom: 0},
      stretch: true,
      parent : cancelButton
    });
  }

  public onWake() {
    super.onWake();
    this.mouseEvents = true;

    this.header.setLabels(['STREAK', 'SCORE', 'TIME']);
    this.footer.loadPowerups();

    this.restart();

    Game.instance.pause();
    Game.instance.showWelcome(()=>{
      Game.instance.resume();
    });
  }

  public onSleep() {
    super.onSleep();
  }

  public update() {
    super.update();

    if(!Game.instance.isPaused())
      this.gameTime += this.deltaTime;

    this.header.setValue(0, 'x' + this.streak);
    this.header.setValue(1, numberWithCommas(this.score));
    this.header.setValue(2, getTimeString(this.gameTime));

    this.drawGame();
  }

  protected restart() {
    this.dragShape = null;
    this.dragPos = {x: 0, y: 0};
    this.boardRect = {x:0, y:0, w:0, h:0};
    this.dragBoardPos = null;
    this.streak = 1;
    this.grace = 0;
    this.score = 0;
    this.showLineScore = false;
    this.gameTime = 0;
    this.storageBorder = false;
    this.poolBorder = false;
    this.editShape = '';
    this.save = null;
    this.noMoreMoves = false;

    this.board = [
      [0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0]
    ];

    this.pool = [null, null, null, null];
    this.spawnShapeInPool();
    this.spawnShapeInPool();
    this.spawnShapeInPool();

    this.poolRects = [
      {x:0, y:0, w:0, h:0},
      {x:0, y:0, w:0, h:0},
      {x:0, y:0, w:0, h:0},
      {x:0, y:0, w:0, h:0}
    ];

    this.footer.reset();
  }

  protected spawnShapeInPool(slot:number=-1) {
    if(slot == -1) {
      for(let i = 0; i < this.pool.length; i++) {
        if(!this.pool[i]) {
          slot = i;
          break;
        }
      }
    }

    if(slot == -1)
      return;

    let chance = Math.floor(this.score / 200);
    let r = Math.random() * 100;
    let useHardPool = (r <= chance);

    let patternIndexes = [];
    for(let i = 0; i < ShapeTilePatterns.length; i++) {
      let shape = new Shape(i, 0);
      let count = shape.getTileCount();
      if(!useHardPool || (useHardPool && count >= 4))
        patternIndexes.push(i);
    }

    let spawning = true;
    while(spawning) {
      let r = Math.floor(Math.random() * patternIndexes.length);
      let pattern = patternIndexes[r];
      let newShape = new Shape(pattern);
      let existing = this.pool.find((s)=>s && (s.pattern == newShape.pattern || s.color == newShape.color));
      if(!existing) {
        this.pool[slot] = newShape;
        spawning = false;
      }
    }
  }

  protected getClosestPosForShape(bx:number, by:number, tw:number, sx:number, sy:number, shape:Shape) {
    let columns = shape.getColumns();
    let rows = shape.getRows();
    let x = Math.floor((sx - bx + (tw/2)) / tw);
    if(x > 8 - columns) return null;
    if(x < 0) return null;
    let y = Math.floor((sy - by + (tw/2)) / tw);
    if(y > 8 - rows) return null;
    if(y < 0) return null;
    return {x, y};
  }

  protected doesShapeFit(bx:number, by:number, shape:Shape) {
    let columns = shape.getColumns();
    let rows = shape.getRows();

    for(let y = 0; y < rows; y++) {
      for(let x = 0; x < columns; x++) {
        if(by+y > 7) return false;
        if(bx+x > 7) return false;
        if(shape.isTile(x, y) && this.board[by+y][bx+x] != 0)
          return false;

      }

    }

    return true;
  }

  protected updateStorageBorder() {
    if(this.editShape != '') {
      this.storageBorder = true;
      return;
    }

    let x = this.dragPos.x;
    let y = this.dragPos.y;
    let r = this.poolRects[3];
    this.storageBorder = this.dragShape && !this.pool[3] && (x >= r.x && x <= r.x + r.w && y >= r.y && y <= r.y + r.h);
  }

  protected updatePoolBorder() {
    this.poolBorder = this.isMouseOverPool(this.dragPos.x, this.dragPos.y);
  }

  protected startEditShape(mode:string) {
    // let shape = this.pool[3];

    // if(mode == 'block-buster-rotate' && !shape.canRotate(true)) {
    //   Game.instance.showAlert('This shape cannot be rotated.');
    //   return;
    // }

    // if(mode == 'block-buster-flip' && !shape.canFlip(true)) {
    //   Game.instance.showAlert('This shape cannot be flipped.');
    //   return;
    // }

    this.editShape = mode;

    let label = '';
    if(this.editShape == 'block-buster-rotate')
      label = 'Rotate shape';
    else if(this.editShape == 'block-buster-flip')
      label = 'Flip shape';
    else if(this.editShape == 'block-buster-compress')
      label = 'Compress shape down to 1 block?';
    else if(this.editShape == 'block-buster-random')
      label = 'Randomly replace shape?';
    else if(this.editShape == 'block-buster-undo')
      label = 'Undo last shape placed?';

    let labelText = this.editShapePanel.getChild(0) as UIText;
    labelText.text = label;

    let firstButton = this.editShapePanel.getChild(1) as UIPanelButton;
    let firstIcon = firstButton.getChild(5) as UIImage;
    let secondButton = this.editShapePanel.getChild(2) as UIPanelButton;
    let secondIcon = secondButton.getChild(5) as UIImage;

    if(this.editShape == 'block-buster-rotate') {
      firstIcon.url = require('../common/assets/rotate-left.png');
      secondIcon.url = require('../common/assets/rotate-right.png');
      firstButton.visible = true;
      secondButton.visible = true;
    }
    else if(this.editShape == 'block-buster-flip') {
      firstIcon.url = require('../common/assets/flip-vert.png');
      secondIcon.url = require('../common/assets/flip-horiz.png');
      firstButton.visible = true;
      secondButton.visible = true;
    }
    else {
      firstButton.visible = false;
      secondButton.visible = false;
    }

    this.storageBorder = true;
    this.originalShape = new Shape(this.pool[3].pattern, this.pool[3].color);
  }

  protected stopEditShape() {
    this.editShape = '';
    this.storageBorder = false;
  }

  protected placeShape(bx:number, by:number, shape:Shape, remove:boolean=false) {
    let cols = shape.getColumns();
    let rows = shape.getRows();
    for(let y = 0; y < rows; y++) 
      for(let x = 0; x < cols; x++) 
        if(shape.isTile(x, y))
          this.board[by+y][bx+x] = remove ? 0 : shape.color;
  }

  protected getLines() {
    let lines = {rows: [], columns: []};

    for(let y = 0; y < 8; y++) {
      let rowFull = true;
      for(let x = 0; x < 8; x++) 
        if(this.board[y][x] == 0)
          rowFull = false;
      if(rowFull)
        lines.rows.push(y);
    }

    for(let x = 0; x < 8; x++) {
      let columnFull = true;
      for(let y = 0; y < 8; y++) 
        if(this.board[y][x] == 0)
          columnFull = false;
      if(columnFull)
        lines.columns.push(x);
    }

    return lines;
  }

  protected collapseLines(lines:any) {
    for(let y = 0; y < 8; y++) 
      for(let x = 0; x < 8; x++) 
        if(lines.columns.includes(x) || lines.rows.includes(y))
          this.board[y][x] = 0;
  }

  protected saveState() {
    this.save = {
      score: this.score,
      streak: this.streak,
      grace: this.grace,
      pool: [],
      board: JSON.stringify(this.board),
      dragShape: new Shape(this.dragShape.pattern, this.dragShape.color),
      dragSource: this.dragSource
    }

    for(let i = 0; i < this.pool.length; i++) {
      if(this.pool[i])
        this.save.pool.push(new Shape(this.pool[i].pattern, this.pool[i].color));
      else
        this.save.pool.push(null);
    }
  }

  protected restoreState() {
    if(!this.save) return;
    this.score = this.save.score;
    this.streak = this.save.streak;
    this.grace = this.save.grace;
    this.pool = this.save.pool;
    this.board = JSON.parse(this.save.board);
    this.pool[this.save.dragSource] = this.save.dragShape;
  }

  protected anyMovesRemaining() {
    // last shape can go in storage
    let count = 0;
    for(let i = 0; i < this.pool.length; i++)
      if(this.pool[i])
        count++;

    if(count == 1 && !this.pool[3]) {
      // console.log('empty storage')
      return true;
    }

    // let unusedRotate = !this.footer.isPowerupUsed('block-buster-rotate');
    // let unusedFlip = !this.footer.isPowerupUsed('block-buster-flip');
    // let unusedCompress = !this.footer.isPowerupUsed('block-buster-compress');
    // let unusedRandom = !this.footer.isPowerupUsed('block-buster-random');

    // console.log(unusedRotate, unusedFlip, unusedCompress, unusedRandom);

    // if(unusedRandom) {
    //   console.log('unused random');
    //   return true;
    // }

    // check single
    // if(unusedCompress) {
    //   let singleBlock = new Shape(2, 0);
    //   for(let y = 0; y < 8; y++) {
    //     for(let x = 0; x < 8; x++) {
    //       if(this.doesShapeFit(x, y, singleBlock)) {
    //         console.log(`single fits`)
    //         return true;
    //       }
    //     }
    //   }
    // }

    // check pool shapes
    for(let y = 0; y < 8; y++) {
      for(let x = 0; x < 8; x++) {
        for(let i = 0; i < this.pool.length; i++) {
          if(!this.pool[i]) continue;

          if(this.doesShapeFit(x, y, this.pool[i])) {
            // console.log(`slot ${i} fits`)
            return true;
          }

          // if(i < 3 && storageShape) continue;

          // if(unusedRotate) {
          //   let rotatedShape = this.pool[i].clone();
          //   for(let j = 0; j < 3; j++) {
          //     rotatedShape.rotate(true);
          //     if(this.doesShapeFit(x, y, rotatedShape)) {
          //       console.log(`slot ${i} rotated fits`)
          //       return true;
          //     }
          //   }
          // }
  
          // if(unusedFlip) {
          //   let flippedShape = this.pool[i].clone();
          //   for(let j = 0; j < 4; j++) {
          //     if(j == 0 || j == 2)
          //       flippedShape.flip(true);
          //     else if(j == 1 || j == 3)
          //       flippedShape.flip(false);
  
          //     if(this.doesShapeFit(x, y, flippedShape)) {
          //       console.log(`slot ${i} flipped fits`)
          //       return true;
          //     }
          //   }
          // }
        }
      }
    }

    return false;
  }

  protected drawTile(x:number, y:number, w:number, color:number, border:boolean, cover:boolean=false) {
    let tx = x;
    let ty = y;
    let tw = w;
    let cx = x+(w/2);
    let cy = y+(w/2);
    let bw = w * 0.15;

    if(color > 0) {
      let colors = TileColors[color-1];
      UIManager.drawFilledRect(tx, ty, (tw/2), tw, colors[4]);
      UIManager.drawFilledRect(tx + (w/2), ty, (tw/2), tw, colors[2]);
      UIManager.drawFilledTriangle(tx, ty, tx + tw, ty, cx, cy, colors[1]);
      UIManager.drawFilledTriangle(tx, ty + tw, cx, cy, tx + tw, ty + tw, colors[3]);
      UIManager.drawFilledRect(tx + bw, ty + bw, tw - (bw * 2), tw - (bw * 2), colors[0]);
    }

    if(border)
      UIManager.drawRect(tx, ty, tw, tw, 'black');
  }

  protected drawShape(sx:number, sy:number, tw:number, shape:Shape, outline:boolean=false) {
    let cols = shape.getColumns();
    let rows = shape.getRows();
    for(let y = 0; y < rows; y++) {
      for(let x = 0; x < cols; x++) {
        if(shape.isTile(x, y)) {
          let rx = sx + (x*tw);
          let ry = sy + (y*tw);
          if(outline) {
            let colors = TileColors[shape.color-1];
            UIManager.drawRect(rx, ry, tw, tw, 'white');
            UIManager.drawFilledRect(rx, ry, tw, tw, colors[0]);
            UIManager.drawFilledRect(rx, ry, tw, tw, '#000000a0');
          }
          else
            this.drawTile(rx, ry, tw, shape.color, false);
        }
      } 
    } 
  }

  protected drawLines(bx:number, by:number, bw:number, tw:number, lines:any) {
    let color = '#ffffff80'

    if(this.showLineScore && this.dragShape) {
      let colors = TileColors[this.dragShape.color-1];
      color = colors[0]
    }

    // phase 1 solid color rects
    for(let i = 0; i < lines.rows.length; i++) {
      let row = lines.rows[i];
      let lx = bx;
      let ly = by + (row * tw);
      UIManager.drawFilledRect(lx, ly, bw, tw, color);
    }

    for(let i = 0; i < lines.columns.length; i++) {
      let column = lines.columns[i];
      let lx = bx + (column * tw);
      let ly = by;
      UIManager.drawFilledRect(lx, ly, tw, bw, color);
    }

    if(!this.showLineScore)
      return;

    // phase 2 black out center
    for(let i = 0; i < lines.rows.length; i++) {
      let row = lines.rows[i];
      let lx = bx;
      let ly = by + (row * tw);
      UIManager.drawFilledRect(lx + 5, ly + 5, bw - 10, tw - 10, 'black');
    }

    for(let i = 0; i < lines.columns.length; i++) {
      let column = lines.columns[i];
      let lx = bx + (column * tw);
      let ly = by;
      UIManager.drawFilledRect(lx + 5, ly + 5, tw - 10, bw - 10, 'black');
    }

    // phase 3 text
    for(let i = 0; i < lines.rows.length; i++) {
      let row = lines.rows[i];
      let lx = bx;
      let ly = by + (row * tw);
      let score = 100 * this.streak;
      UIManager.drawText('+' + numberWithCommas(score), lx+(bw/2), ly+(tw/2)+2, 'verdana', 20, 'middle', 'white');
    }

    for(let i = 0; i < lines.columns.length; i++) {
      let column = lines.columns[i];
      let lx = bx + (column * tw);
      let ly = by;
      let score = 100 * this.streak;
      UIManager.ctx.save();
      UIManager.ctx.rotate(Math.PI * 1.5);
      UIManager.drawText('+' + numberWithCommas(score), -ly-(bw/2), lx+(tw/2)+2, 'verdana', 20, 'middle', 'white');
      UIManager.ctx.restore();
    }
  }

  protected drawStreakPanel(bx:number, by:number, bw:number) {
    // UIManager.drawPanel(bx, by-50, bw, 40, '#111111', 6);
    // UIManager.drawFilledRect(bx, by-70, bw, 50, '#222222');
    let outerY = by - (UIManager.isMobile ? 35 : 80);

    // UIManager.drawRect(bx, outerY, bw, 60, 'gray');
    // UIManager.drawLine(bx+80, outerY, bx+80, outerY+60, 'gray');

    UIManager.drawText('STREAK', bx+35, outerY+14, 'verdana', 15, 'center', 'white');
    // UIManager.drawText('BONUS', bx+40, outerY+42, 'verdana', 15, 'center', 'white');

    let px = bx + 80;
    let py = outerY;
    let pw = bw - 80;

    UIManager.drawPanel(px, py, pw, 25, '#00000080', 4);

    let cw = pw / 10;
    UIManager.drawPanel(px, py, (cw * this.streak), 25, 'rgb(192, 165, 219)', 4);
    // UIManager.drawRect(px+2, py+2, (cw * this.streak), 21, 'white');

    // for(let i = 0; i < 10; i++) 
    //   UIManager.drawLine(px+(i*cw), py, px+(i*cw), py+20, '#222222');

    UIManager.drawText('x' + this.streak.toString(), px + (cw * this.streak) - (cw/2) + 1, py + 14, 'verdana', 16, 'center', 'black');

    // UIManager.drawPanel(px, py+28, pw, 5, '#00000080', 4);
    // if(this.grace > 0)
    //   UIManager.drawPanel(px, py+28, pw * (this.grace/3), 5, 'orange', 4);
  }

  protected drawGame() {
    let cx = (this.width/2);
    let bw = Math.min(400, this.width - (UIManager.isMobile ? 20 : 40));
    let bx = cx-(bw/2);
    let by = 100;
    let tw = bw/8;

    if(UIManager.isMobile) {
      by = Math.max(70, Math.min(100, this.height - 625));
      // console.log(by);
    }

    this.boardWidth = bw;
    this.boardPos = {x: bx, y: by};
    this.tileWidth = tw;

    // UIManager.ctx.fillStyle = '#455AAE';
    // UIManager.ctx.fillRect(0, 0, this.width, this.height);    

    // UIManager.ctx.fillStyle = '#232D55';
    UIManager.ctx.fillStyle = 'black';
    UIManager.ctx.fillRect(bx, by, bw, bw);    

    // streak panel
    // this.drawStreakPanel(bx, by, bw);

    // tiles
    for(let y = 0; y < 8; y++) {
      for(let x = 0; x < 8; x++) {
        let tx = bx + (tw*x);
        let ty = by + (tw*y);
        this.drawTile(tx, ty, tw, this.board[y][x], false);
      }
    }

    // grid lines
    for(let y = 0; y < 9; y++) {
      let ly = by + (tw*y);
      UIManager.drawLine(bx, ly, bx+bw, ly, '#222222', 1);
    }

    for(let x = 0; x < 9; x++) {
      let lx = bx + (tw*x);
      UIManager.drawLine(lx, by, lx, by+bw, '#222222', 1);
    }

    // draw pool
    let px = bx;
    let py = by + bw + 10;
    let pw = (bw-10) / 4;
    let tws = tw * 0.48;

    // UIManager.drawFilledRect(px, py, pw, pw, '#00000040');

    for(let i = 0; i < this.pool.length; i++) {
      let shape = this.pool[i];

      if(i == this.pool.length-1) {
        px += 10;
      }

      UIManager.drawFilledRect(px, py, pw, pw, '#00000040');

      if(this.storageBorder && i == this.pool.length-1)
        UIManager.drawRect(px, py, pw, pw, 'yellow', 1);

      if(i == this.pool.length-1 && !shape) {
        UIManager.drawText('STORAGE', px+(pw/2), py+(pw/2), 'verdana', 12, 'center', '#ffffff80');
      }

      if(shape) {
        let sw = shape.getColumns() * tws;
        let sh = shape.getRows() * tws;
        this.drawShape(px + (pw/2) - (sw/2), py + (pw/2) - (sh/2), tws, shape);
      }
      else if(i <= 2) {
        UIManager.drawFilledRect(px + (pw/2) - 5, py + (pw/2) - 5, 10, 10, '#ffffff20');
      }
      
      this.poolRects[i].x = px;
      this.poolRects[i].y = py;
      this.poolRects[i].w = pw;
      this.poolRects[i].h = pw;
      
      px += pw;
    }

    if(this.poolBorder && this.dragSource == 3) {
      let px = bx;
      let py = by + bw + 10;
      let pw = (bw-10) / 4;
      UIManager.drawRect(px, py, pw * 3, pw, 'yellow', 1);
    }

    // drag shape
    if(this.dragShape && !this.showLineScore) {
      let sw = this.dragShape.getColumns() * tw;
      let sh = this.dragShape.getRows() * tw;
      let sx = this.dragPos.x - (sw/2);
      let sy = this.dragPos.y - (sh/2);

      // console.log(bx, by, bw, sx, sy);
      this.dragBoardPos = this.getClosestPosForShape(bx, by, tw, sx, sy, this.dragShape);

      if(this.dragBoardPos) {
        let shapeFit = this.doesShapeFit(this.dragBoardPos.x, this.dragBoardPos.y, this.dragShape);
        if(shapeFit && !this.noMoreMoves) {
          this.drawShape(bx + (this.dragBoardPos.x * tw), by+ (this.dragBoardPos.y * tw), tw, this.dragShape, true);
          this.placeShape(this.dragBoardPos.x, this.dragBoardPos.y, this.dragShape);
          let lines = this.getLines();
          this.placeShape(this.dragBoardPos.x, this.dragBoardPos.y, this.dragShape, true);
          this.drawLines(bx, by, bw, tw, lines);
        }
      }

      this.drawShape(sx, sy, tw, this.dragShape);
    }

    // collapsing lines
    if(this.showLineScore) {
      let lines = this.getLines();
      this.drawLines(bx, by, bw, tw, lines);
    }

    // grace on streak
    let panel = this.header.getPanel(0);
    let pipw = 8;
    let pipx = panel.getScreenX() + (panel.size.x / 2) - (pipw/2) - 5 - pipw;
    let pipy = panel.getScreenY() + panel.size.y - 2;
    for(let i = 0; i < 3; i++) {
      let color = this.grace > i ? 'orange' : '#000000a0';
      UIManager.drawPanel(pipx, pipy, pipw, 4, color, 2);
      pipx += (pipw + 5);
    }

    // rotate flip panel
    this.editShapePanel.position.x = bx;
    this.editShapePanel.position.y = by + bw + 10 + (bw/4) + 5;
    this.editShapePanel.size.x = bw;
    this.editShapePanel.size.y = 50;
    this.editShapePanel.visible = this.editShape != '';

    // no more moves message
    if(this.noMoreMoves) {
      let px = bx+(tw/2);
      let py = by+(tw/2);
      UIManager.drawPanel(px, py, bw-tw, 100, '#000000c0', 0);
      UIManager.drawRect(px, py, bw-tw, 100, 'white');
      UIManager.drawText('No More Moves!', bx + (bw/2), py + 25, 'verdana', 20, 'center', 'yellow');
      UIManager.drawText('Use a powerup or', bx + (bw/2), py + 55, 'verdana', 18, 'center', 'white');
      UIManager.drawText('tap here to end game.', bx + (bw/2), py + 77, 'verdana', 18, 'center', 'white');
    }

    // footer
    this.footer.visible = true;
    if(this.height < 650 && (this.editShapePanel.visible)) 
      this.footer.visible = false;
  }

  protected isMouseOverPool(x:number, y:number) {
    let emptySlot = this.getEmptyPoolSlot();
    if(emptySlot == -1)
      return false;

    let slot = -1;
    for(let i = 0; i < this.poolRects.length-1; i++) {
      let r = this.poolRects[i];
      if(x >= r.x && x <= r.x + r.w && y >= r.y && y <= r.y + r.h) 
        slot = i;
    }

    return slot != -1;
  }

  protected getEmptyPoolSlot() {
    for(let i = 0; i < this.poolRects.length-1; i++)
      if(!this.pool[i])
        return i;
    return -1;
  }

  public onMouseDown(x:number, y:number) {
    if(this.showLineScore || this.dragShape || this.editShape != '')
      return;

    let bx = Math.floor((x - this.boardPos.x) / this.tileWidth);
    let by = Math.floor((y - this.boardPos.y) / this.tileWidth);

    if(this.noMoreMoves && bx >= 0 && bx < 8 && by >= 0 && (y - this.boardPos.y) <= 150) {
      this.showGameOver();
      return;
    }

    for(let i = 0; i < this.poolRects.length; i++) {
      let r = this.poolRects[i];
      if(x >= r.x && x <= r.x + r.w && y >= r.y && y <= r.y + r.h) {
        this.dragShape = this.pool[i];
        this.pool[i] = null;
        this.dragPos.x = x;
        this.dragPos.y = y;
        this.dragSource = i;
      }
    }

    this.updateStorageBorder();
    this.updatePoolBorder();
  }

  public onMouseMove(x:number, y:number) {
    if(!this.dragShape || this.showLineScore) return;
    this.dragPos.x = x;
    this.dragPos.y = y;
    this.updateStorageBorder();
    this.updatePoolBorder();
  }

  public onMouseUp(x:number, y:number) {
    if(!this.dragShape || this.showLineScore) return;

    this.storageBorder = false;
    this.poolBorder = false;

    // check if dropping in storage
    let r = this.poolRects[3];
    if(x >= r.x && x <= r.x + r.w && y >= r.y && y <= r.y + r.h) {
      if(!this.pool[3]) {
        this.saveState();
        this.pool[3] = this.dragShape;
        this.finishDropShape(true);
        return;
      }
    }

    // check if dropping storage shape into pool
    if(this.dragSource == 3 && this.isMouseOverPool(x, y)) {
      let slot = this.getEmptyPoolSlot();
      if(slot != -1) {
        this.saveState();
        this.pool[slot] = this.dragShape;
        this.pool[3] = null;
        this.finishDropShape(true);
        return;
      }
    }

    let returnToPool = true;

    if(this.dragBoardPos) {
      let shapeFit = this.doesShapeFit(this.dragBoardPos.x, this.dragBoardPos.y, this.dragShape);
      if(shapeFit && !this.noMoreMoves) {
        this.saveState();
        this.placeShape(this.dragBoardPos.x, this.dragBoardPos.y, this.dragShape);
        this.score += (this.dragShape.getTileCount() * 10);
        returnToPool = false;
      }
    }

    if(returnToPool) {
      this.pool[this.dragSource] = this.dragShape;
      this.finishDropShape(true);
    }
    else {
      let lines = this.getLines();
      if(lines.rows.length > 0 || lines.columns.length > 0) {
        this.showLineScore = true;
        setTimeout(() => {
          this.showLineScore = false;
          this.finishDropShape(false);
        }, 750);
      }
      else {
        this.finishDropShape(false);
      }
    }
  }

  protected finishDropShape(returnToPool:boolean) {
    if(!returnToPool) {
      let lines = this.getLines();
      let lineCount = lines.rows.length + lines.columns.length;

      if(lineCount > 0) {
        let score = lineCount * 100 * this.streak;
        this.collapseLines(lines);
        this.score += score;

        this.streak += lineCount;
        if(this.streak > 10)
          this.streak = 10;
        this.grace = 3;
      }
      else {
        this.grace--;
        if(this.grace <= 0) {
          this.streak = 1;
          this.grace = 0;
        }
      }
    }

    this.dragShape = null;

    let count = 0;
    for(let i = 0; i < this.pool.length-1; i++)
      if(this.pool[i])
        count++;

    if(count == 0) {
      this.spawnShapeInPool();
      this.spawnShapeInPool();
      this.spawnShapeInPool();
    }

    this.noMoreMoves = !this.anyMovesRemaining();
  }

  protected clearBoard() {
    for(let y = 0; y < 8; y++) 
      for(let x = 0; x < 8; x++) 
        this.board[y][x] = 0;
  }

  protected onPowerup(id:string) {
    if(this.showLineScore)
      return;

    if(id == 'block-buster-undo' && !this.save) {
      Game.instance.showAlert(`No shapes have been placed yet.`);
      return;
    }

    if(id != 'block-buster-undo' && !this.pool[3]) {
      Game.instance.showAlert(`You must put a shape in storage first.`);
      return;
    }

    this.noMoreMoves = false;

    if(this.editShape != '')
      this.onCancelEditShape();

    this.startEditShape(id);
  }

  protected onEditShapeButton(i:number) {
    let shape = this.pool[3];

    if(this.editShape == 'block-buster-rotate')
      shape.rotate(i == 0);
    else
      shape.flip(i == 0);
  }

  protected onCancelEditShape() {
    if(this.editShape == 'block-buster-rotate' || this.editShape == 'block-buster-flip')
      this.pool[3] = new Shape(this.originalShape.pattern, this.originalShape.color);
    this.stopEditShape();
    this.noMoreMoves = !this.anyMovesRemaining();
  }

  protected onConfirmEditShape() {
    if(this.editShape == 'block-buster-compress') {
      let c = this.pool[3].color;
      this.pool[3] = new Shape(2, c);
    }
    else if(this.editShape == 'block-buster-random') {
      this.spawnShapeInPool(3);
    }

    this.footer.usePowerup(this.editShape);
    this.stopEditShape();

    this.noMoreMoves = !this.anyMovesRemaining();
  }

  protected onUndo() {
    this.restoreState();
    this.save = null;
    // this.footer.usePowerup('block-buster-undo');
  }

  protected showGameOver() {
    Game.instance.showGameOver(this.score, this.score, (action:string)=>{
      if(action == 'restart')
        this.restart();
    });
  }
}