import { UIButton } from '../common/ui/uiButton';
import { UIImage } from '../common/ui/uiImage';
import { UIPanel } from '../common/ui/uiPanel';
import { UIPanelButton } from '../common/ui/uiPanelButton';
import { UIScreen } from '../common/ui/uiScreen';
import { UIText } from '../common/ui/uiText';
import { getTimeString, numberWithCommas } from '../common/util';
import { SolitairePanel } from './solitairePanel';
import { SoundManager } from '../common/audio/soundManager';
import { GameStyle } from './gameStyle';
import { Game, GameMode } from './game';
import { TournamentType } from './solitaire';
import { LeaderboardPanel } from '../common/leaderboardPanel';
import { UICanvas } from '../common/ui/uiCanvas';
import { Server } from '../../server/server';

export class GameScreen extends UIScreen {
  protected finishButton: UIButton;
  protected playWithFriendsButton: UIPanelButton;
  protected scoreText: UIText;
  protected timeText: UIText;
  protected bonusText: UIText;
  protected timeLabel: UIText;
  protected undoButton: UIPanelButton;
  protected menuPanel: UIPanel;
  protected toggleSoundOnIcon: UIImage;
  protected toggleSoundOffIcon: UIImage;
  protected toggleAutoplayButton: UIPanelButton;
  protected toggleAutoplayOnIcon: UIImage;
  protected toggleAutoplayOffIcon: UIImage;
  protected timePanel: UIPanel;
  protected bonusPanel: UIPanel;
  protected scorePanel: UIPanel;
  protected playPlaceButton: UIPanelButton;
  protected leaderboard: LeaderboardPanel;
  protected readyGoText: UIText;
  protected toastReadyTimer:any;

  public build() {
    let background = new UIPanel({
      color : GameStyle.backgroundColors.game.outer,
      fitParent : {left : 0, top : 0, right : 0, bottom : 0},
      parent : this
    });

    let innerPanel = new UIPanel({
      color : GameStyle.backgroundColors.game.inner,
      fitParent : {left : 0, top : 60, right : 0, bottom : 60},
      radius: 30,
      parent : this
    });

    let solitairePanel = new SolitairePanel({
      fitParent : {left : 10, top : 70, right : 10, bottom : 70},
      parent : this
    });

    this.buildTopBar();
    this.buildBottomBar();
    this.buildMenu();

    this.readyGoText = new UIText({
      text : '',
      fontFamily : 'verdana',
      fontSize : 40,
      fontWeight: '800',
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      position: {x: 0, y: 180},
      strokeLineWidth: 5,
      strokeColor: '#000000',
      color: '#FFF200',
      parent : this
    })
  }

  protected buildTopBar() {
    let topbar = new UIPanel({
      fitParent: {left: 0, right: 0, top: -1, bottom: -1},
      size: {x: 0, y: 60},
      parent : this
    });

    this.scorePanel = new UIPanel({
      anchor: {x: 0.5, y: 0.5},
      pivot: {x: 0.5, y: 0.5},
      size: {x: 80, y: 40},
      radius: GameStyle.header.panel.radius,
      color: GameStyle.header.panel.color,
      parent : topbar
    });

    let scoreLabel = new UIText({
      text : 'SCORE',
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      position: {x: 0, y: 1},
      fontFamily: GameStyle.header.label.fontFamily,
      fontSize: GameStyle.header.label.fontSize,
      color: GameStyle.header.label.color,
      parent : this.scorePanel
    });

    this.scoreText = new UIText({
      text : '123',
      anchor: {x: 0.5, y: 0.5},
      pivot: {x: 0.5, y: 0.5},
      position: {x: 0, y: 7},
      fontFamily: GameStyle.header.value.fontFamily,
      fontSize: GameStyle.header.value.fontSize,
      color: GameStyle.header.value.color,
      parent : this.scorePanel
    });

    this.timePanel = new UIPanel({
      size : {x : 80, y : 40},
      position : {x : 60, y : 10},
      radius: GameStyle.header.panel.radius,
      color: GameStyle.header.panel.color,
      parent : topbar
    });

    this.timeLabel = new UIText({
      text : 'TIME',
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      position: {x: 0, y: 1},
      fontFamily: GameStyle.header.label.fontFamily,
      fontSize: GameStyle.header.label.fontSize,
      color: GameStyle.header.label.color,
      parent : this.timePanel
    });

    this.timeText = new UIText({
      text : '1:23',
      anchor: {x: 0.5, y: 0.5},
      pivot: {x: 0.5, y: 0.5},
      position: {x: 0, y: 7},
      fontFamily: GameStyle.header.value.fontFamily,
      fontSize: GameStyle.header.value.fontSize,
      color: GameStyle.header.value.color,
      parent : this.timePanel
    });

    this.bonusPanel = new UIPanel({
      size : {x : 80, y : 40},
      position : {x : -60, y : 10},
      anchor : {x : 1, y : 0},
      pivot : {x : 1, y : 0},
      radius: GameStyle.header.panel.radius,
      color: GameStyle.header.panel.color,
      parent : topbar
    });

    let bonusLabel = new UIText({
      text : 'BONUS',
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      position: {x: 0, y: 1},
      fontFamily: GameStyle.header.label.fontFamily,
      fontSize: GameStyle.header.label.fontSize,
      color: GameStyle.header.label.color,
      parent : this.bonusPanel
    });

    this.bonusText = new UIText({
      text : '+12',
      anchor: {x: 0.5, y: 0.5},
      pivot: {x: 0.5, y: 0.5},
      position: {x: 0, y: 7},
      fontFamily: GameStyle.header.value.fontFamily,
      fontSize: GameStyle.header.value.fontSize,
      color: GameStyle.header.value.color,
      parent : this.bonusPanel
    });

    this.playPlaceButton = new UIPanelButton({
      size: {x: 40, y: 40},
      position: {x: 10, y: 10},
      callback : (btn:UIPanelButton) => {
        this.onPPMenu();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      borderWidth: GameStyle.dialog.button.borderWidth,
      borderColor: GameStyle.dialog.button.borderColor,
      parent : topbar
    });

    let playPlaceIcon = new UIImage({
      url : '/playplace.png',
      parent : this.playPlaceButton
    });

    let pauseButton = new UIPanelButton({
      anchor: {x: 1, y: 0},
      pivot: {x: 1, y: 0},
      size: {x: 40, y: 40},
      position: {x: -10, y: 10},
      callback : (btn:UIPanelButton) => {
        this.onPause();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      borderWidth: GameStyle.dialog.button.borderWidth,
      borderColor: GameStyle.dialog.button.borderColor,
      parent : topbar
    });

    let menuIcon = new UIImage({
      url : require('./assets/menu.png'),
      parent : pauseButton
    });
  }

  protected buildBottomBar() {
    let bottombar = new UIPanel({
      fitParent: {left: 0, right: 0, top: -1, bottom: -1},
      size: {x: 0, y: 60},
      anchor: {x: 0, y: 1},
      pivot: {x: 0, y: 1},
      parent : this
    });

    this.leaderboard = new LeaderboardPanel({
      fitParent: {left: 10, right: 110, top: 10, bottom: 10},
      color: '#00000020',
      radius: 10,
      parent : bottombar
    });

    let finishButton = new UIPanelButton({
      anchor: {x: 1, y: 0},
      pivot: {x: 1, y: 0},
      size: {x: 40, y: 40},
      position: {x: -60, y: 10},
      callback : (btn:UIPanelButton) => {
        this.onFinish();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      borderWidth: GameStyle.dialog.button.borderWidth,
      borderColor: GameStyle.dialog.button.borderColor,
      parent : bottombar
    });

    let finishIcon = new UIImage({
      url : require('./assets/tournament.png'),
      parent : finishButton
    });

    this.undoButton = new UIPanelButton({
      anchor: {x: 1, y: 0},
      pivot: {x: 1, y: 0},
      size: {x: 40, y: 40},
      position: {x: -10, y: 10},
      callback : (btn:UIPanelButton) => {
        this.onUndo();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      borderWidth: GameStyle.dialog.button.borderWidth,
      borderColor: GameStyle.dialog.button.borderColor,
      parent : bottombar
    });

    let undoIcon = new UIImage({
      url : require('./assets/undo.png'),
      parent : this.undoButton
    });
  }

  protected buildMenu() {
    this.menuPanel = new UIPanel({
      size: {x: 40, y: 140},
      position: {x: 10, y: 60},
      parent : this
    });

    let flipLayoutButton = new UIPanelButton({
      size : {x : 40, y : 40},
      position : {x : 0, y : 0},
      callback : () => {
        this.menuPanel.visible = false;
        Game.instance.solitaire.toggleFlipLayout();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      borderWidth: GameStyle.dialog.button.borderWidth,
      borderColor: GameStyle.dialog.button.borderColor,
      parent : this.menuPanel
    });

    let flipLayoutIcon = new UIImage({
      url : require('./assets/fliplayout.png'),
      parent : flipLayoutButton
    });

    let toggleSoundButton = new UIPanelButton({
      size : {x : 40, y : 40},
      position : {x : 0, y : 50},
      callback : () => {
        this.menuPanel.visible = false;
        Game.instance.solitaire.toggleSfxOn();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      borderWidth: GameStyle.dialog.button.borderWidth,
      borderColor: GameStyle.dialog.button.borderColor,
      parent : this.menuPanel
    });

    this.toggleSoundOnIcon = new UIImage({
      url : require('./assets/togglesoundon.png'),
      parent : toggleSoundButton
    });

    this.toggleSoundOffIcon = new UIImage({
      url : require('./assets/togglesoundoff.png'),
      parent : toggleSoundButton
    });

    this.toggleAutoplayButton = new UIPanelButton({
      size : {x : 40, y : 40},
      position : {x : 0, y : 100},
      callback : () => {
        this.menuPanel.visible = false;
        Game.instance.solitaire.toggleAutoplay();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      borderWidth: GameStyle.dialog.button.borderWidth,
      borderColor: GameStyle.dialog.button.borderColor,
      parent : this.menuPanel
    });

    this.toggleAutoplayOnIcon = new UIImage({
      url : require('./assets/toggleautoplayon.png'),
      parent : this.toggleAutoplayButton
    });

    this.toggleAutoplayOffIcon = new UIImage({
      url : require('./assets/toggleautoplayoff.png'),
      parent : this.toggleAutoplayButton
    });
  }

  public onWake() {
    this.menuPanel.visible = false;
    this.leaderboard.visible = Game.instance.isMultiplayer();
    this.readyGoText.visible = false;

    if(Game.instance.isMultiplayer())
      this.leaderboard.connect(Game.instance.getPlaceHost())
    
    Game.instance.start();

    if(Game.instance.gameMode == GameMode.Arcade) {
      Game.instance.started = false;
      this.toastReadyGo(()=>{
        Game.instance.started = true;
      });
    }
  }

  public onSleep() {
    this.leaderboard.disconnect();
    super.onSleep();
  }

  public update() {
    super.update();

    let sol = Game.instance.solitaire;

    if (sol.playingTournament && sol.tournamentType === TournamentType.Timed) {
      this.timeLabel.text = 'TIME';
      this.timeText.text = `${getTimeString(sol.tournamentTimeLeft)}`;
    }
    else if (sol.playingTournament && sol.tournamentType === TournamentType.Games) {
      this.timeLabel.text = 'GAME';
      this.timeText.text = `${sol.tournamentGamesPlayed} of ${sol.tournamentGamesToPlay}`;
    }
    else if (sol.timedGame && sol.passesAllowed === 0) {
      this.timeLabel.text = 'TIME';
      this.timeText.text = `${getTimeString(sol.gameTime)}`;
    }
    else {
      this.timeLabel.text = 'DECK PASS';
      if (sol.passesAllowed === 0) {
        this.timeText.text = sol.passes.toString();
      }
      else {
        this.timeText.text = `${sol.passes} of ${sol.passesAllowed}`;
      }
    }

    this.bonusText.text = '+' + sol.getCountdownPoints().toString();

    let score = sol.baseScore;
    if (sol.playingTournament) {
      score += sol.tournamentTotalScore;
      if (!Game.instance.playing)
        score -= sol.baseScore;
    }

    this.scoreText.text = `${numberWithCommas(score)}`;

    this.scorePanel.visible = sol.scoring;
    this.bonusPanel.visible = sol.scoring;

    this.timePanel.anchor.x = sol.scoring ? 0 : 0.5;
    this.timePanel.pivot.x = sol.scoring ? 0 : 0.5;
    this.timePanel.position.x = sol.scoring ? 60 : 0;

    // this.playWithFriendsButton.visible = !Site.room.isActive();

    this.undoButton.disabled = !Game.instance.solitaire.canUndo();
  }

  protected onMenu() {
    this.menuPanel.visible = !this.menuPanel.visible;

    if(this.menuPanel.visible) {
      this.toggleSoundOnIcon.visible = SoundManager.instance.sfxOn;
      this.toggleSoundOffIcon.visible = !SoundManager.instance.sfxOn;
      // this.toggleAutoplayButton.disabled = Site.room.isActive();
      this.toggleAutoplayOnIcon.visible = Game.instance.solitaire.useAutoPlay;
      this.toggleAutoplayOffIcon.visible = !Game.instance.solitaire.useAutoPlay;
    }
  }

  protected onPause() {
    this.pause();

    Game.instance.pauseDialog.showModal((action:string)=>{
      if(action == 'restart') {
        Game.instance.askQuestion('Start a new game?', async (response:string)=>{
          if(response == 'Yes')  {
            Game.instance.incrementGameCounter('restart');
            Game.instance.start();
          }
          else
            this.resume();
        });
      }
      else if(action == 'quit') {
        Game.instance.askQuestion('Quit the game and\nreturn to Play Place?', (response:string)=>{
          if(response == 'Yes')
            Game.instance.quit();
          else
            this.resume();
        });
      } 
      else if(action == 'options') {
        Game.instance.gameOptionsDialog.showModal(()=>{
          this.onPause();
        });
      }
      else
        this.resume();
    });
  }

  protected onPPMenu() {
    this.pause();

    Game.instance.playPlaceMenu.showModal((action:string)=>{
      if (action) 
        Game.instance.quit(action);
      else
        this.resume();
    });
  }

  protected onUndo() {
    Game.instance.solitaire.undo();
  }

  public pause() {
    if(this.readyGoText.visible) 
      this.cancelToastReadyGo();
    Game.instance.started = false;
  }

  public resume() {
    Game.instance.started = true;
  }

  protected onFinish() {
    this.pause();
    let solitaire = Game.instance.solitaire;

    let msg = '';
    if (solitaire.playingTournament) {
      let askFinish = (solitaire.tournamentType !== TournamentType.Timed) &&
                      (solitaire.tournamentGamesPlayed === solitaire.tournamentGamesToPlay);
      msg = askFinish ? 'Finish the tournament?' : 'Start next game in tournament?';
      if(Game.instance.gameMode === GameMode.Arcade)
        msg = 'Finish game early and start another?\nYour running score will remain.'
    }
    else {
      msg = 'Do you want to finish this game?'
    }

    Game.instance.askQuestion(msg, (response:string)=>{
      if(response == 'Yes') {
        if(Game.instance.gameMode === GameMode.Klondike)
          Game.instance.incrementGameCounter('restart');
        Game.instance.finish();
      }
      else
        this.resume();
    })
  }

  public toastReadyGo(callback:Function) {
    let y = this.readyGoText.position.y;
    let duration = 200;
    let delay = 800;
    let halfWidth = (UICanvas.instance.width/2);

    this.toastReadyTimer = setTimeout(() => {
      this.readyGoText.text = 'Ready...';
      this.readyGoText.visible = true;
      this.readyGoText.slide(0 + halfWidth + this.readyGoText.width, y, 0, y, duration);

      this.toastReadyTimer = setTimeout(() => {
        this.readyGoText.slide(0, y, 0 - halfWidth - this.readyGoText.width, y, duration, ()=>{
          this.readyGoText.text = 'Set...';
          this.readyGoText.slide(0 + halfWidth + this.readyGoText.width, y, 0, y, duration);

          this.toastReadyTimer = setTimeout(() => {
            this.readyGoText.slide(0, y, 0 - halfWidth - this.readyGoText.width, y, duration, ()=>{
              this.readyGoText.text = 'GO!';
              this.readyGoText.slide(0 + halfWidth + this.readyGoText.width, y, 0, y, duration);

              this.toastReadyTimer = setTimeout(() => {
                this.readyGoText.slide(0, y, 0 - halfWidth - this.readyGoText.width, y, duration, ()=>{
                  this.readyGoText.visible = false;
                  callback();
                });
              }, delay);
            });
          }, delay);
        });
      }, delay);
    }, 350);
  }

  public cancelToastReadyGo() {
    clearTimeout(this.toastReadyTimer);    
    this.readyGoText.cancelSlide();
    this.readyGoText.visible = false; 
  }
}