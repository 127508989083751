import { UICanvas } from "./uiCanvas";
import { UIPoint } from "./uiPoint";
import { UIBounds } from "./uiBounds";
import { UIManager } from "./uiManager";

export interface UIElementOptions {
  position?: UIPoint;
  size?: UIPoint;
  anchor?: UIPoint;
  pivot?: UIPoint;
  slice?: UIBounds;
  fitParent?: UIBounds;
  fitAspect?: UIPoint;
  minSize?: UIPoint;
  maxSize?: UIPoint;
  mouseEvents?: boolean;
  visible?: boolean;
  scale?: number;
  draggable?: boolean;
  clipChildren?: boolean;
  scrollChildren?: {horizontal: boolean, vertical: boolean};
  name?: string;
  parent?: UIElement;
}

export class UIElement {
  public position: UIPoint;
  public size: UIPoint;
  public pivot: UIPoint;
  public anchor: UIPoint;
  public fitParent: UIBounds;
  public fitAspect: UIPoint;
  public minSize: UIPoint;
  public maxSize: UIPoint;
  public visible: boolean;
  public mouseEvents: boolean;
  public scale: number;
  public draggable: boolean;
  public dragging: boolean;
  public clipChildren: boolean;
  public scrollChildren: {horizontal: boolean, vertical: boolean};
  public scrollPosition: UIPoint;
  public name: string;

  protected _parent: UIElement;
  protected children: Array<UIElement>;
  protected dragElement: UIElement;
  protected dragPoint: UIPoint;
  protected scrolling: boolean;
  protected scrollChild: UIElement;
  protected scrollChildOrigin: UIPoint;
  protected scrollChildLock: UIElement[];
  protected lastUpdate: number;
  protected deltaTime: number;
  protected fadingIn:boolean;
  protected fadingOut:boolean;
  protected fadeAlpha:number;
  protected fadeRate:number;
  protected scrollStartY: number;
  protected waitForTimeout: any;

  protected slideFrom:UIPoint;
  protected slideTo:UIPoint;
  protected sliding:boolean;
  protected slideCallback:Function;
  protected slideDuration:number;
  protected slideStartTime:number;
  protected slidePauseTime:number;

  protected scaleCallback:Function;
  protected scalingIn:boolean;
  protected scalingOut:boolean;
  protected scaleDuration:number;
  protected scaleStartTime:number;

  constructor(options: UIElementOptions = {}) {
    this.position = {x: 0, y: 0};
    this.size = {x: 0, y: 0};
    this.pivot = {x: 0, y: 0};
    this.anchor = {x: 0, y: 0};
    this.fitParent = {left: -1, top: -1, right: -1, bottom: -1};
    this.fitAspect = {x: 0, y: 0};
    this.minSize = {x: 0, y: 0};
    this.maxSize = {x: 0, y: 0};
    this.visible = true;
    this.mouseEvents = false;
    this.scale = 1.0;
    this.draggable = false;
    this.clipChildren = false;
    this.scrollChildren = {horizontal: false, vertical: false};
    this.scrolling = false;
    this.scrollPosition = {x: 0, y: 0};
    this.name = '';
    this.dragPoint = {x: 0, y: 0};
    this.scrollChild = null;
    this.scrollChildOrigin = {x: 0, y: 0};
    this.fadeRate = 0;
    this.scrollStartY = 0;
    this.waitForTimeout = null;
    this.scrollChildLock = [];

    this._parent = null;
    this.children = [];
    this.dragging = false;
    this.dragElement = null;
    this.lastUpdate = 0;
    this.deltaTime = 0;
    this.fadingIn = false;
    this.fadingOut = false;
    this.fadeAlpha = 1;

    this.slideFrom = {x: 0, y: 0};
    this.slideTo = {x: 0, y: 0};
    this.sliding = false;
    this.slideCallback = null;
    this.slideDuration = 0;
    this.slideStartTime = 0;

    this.scaleCallback = null;
    this.scalingIn = false;
    this.scalingOut = false;
    this.scaleDuration = 0;
    this.scaleStartTime = 0;

    if(options.position)
      this.position = options.position;
    if(options.size)
      this.size = options.size;
    if(options.visible != null)
      this.visible = options.visible;
    if(options.mouseEvents)
      this.mouseEvents = options.mouseEvents;
    if(options.anchor)
      this.anchor = options.anchor;
    if(options.pivot)
      this.pivot = options.pivot;
    if(options.minSize)
      this.minSize = options.minSize;
    if(options.maxSize)
      this.maxSize = options.maxSize;
    if(options.fitParent)
      this.fitParent = options.fitParent;
    if(options.fitAspect)
      this.fitAspect = options.fitAspect;
    if(options.scale != null)
      this.scale = options.scale;
    if(options.draggable != null) {
      this.draggable = options.draggable;
      this.mouseEvents = true;
    }
    if(options.clipChildren != null)
      this.clipChildren = options.clipChildren;
    if(options.scrollChildren != null)
      this.scrollChildren = options.scrollChildren;
    if(options.name != null)
      this.name = options.name;
    if(options.parent)
      this.parent = options.parent;
  }

  public get x(): number {
    return this.position.x;
  }
  public get y(): number {
    return this.position.y;
  }

  public get width(): number {
    return this.size.x;
  }
  public get height(): number {
    return this.size.y;
  }

  public get parent():UIElement {
    return this._parent;
  }
  public set parent(p:UIElement) {
    if(p)
      p.addChild(this);
    else
      this._parent = null;
  }

  public addChild(child: UIElement, childIdx:number = -1) {
    // remove child from current parent
    if (child._parent)
      child._parent.removeChild(child);

    // set new parent
    child._parent = this;

    // add child to our list
    if(childIdx == -1)
    this.children.push(child);
    else
      this.children.splice(childIdx, 0, child);
  }

  public removeChild(child: UIElement) {
    // find child
    var idx = this.children.indexOf(child);
    if(idx == -1)
      return;

    // remove from list
    this.children.splice(idx, 1);
  }

  public removeAllChildren() {
    this.children = [];
  }

  public removeChildren(startIdx:number, count:number) {
    this.children.splice(startIdx, count);
  }

  public deleteAllChildren() {
    for(var i = 0; i < this.children.length; i++)
      delete this.children[i];
    this.removeAllChildren();
  }

  public getChild(idx: number) {
    return this.children[idx];
  }

  public getChildByName(name: string) {
    for(var i = 0; i < this.children.length; i++)
      if(this.children[i].name == name)
        return this.children[i];
    return null;
  }

  public get childCount(): number {
    return this.children.length;
  }

  protected updateAutoSize() {
    if(this.fitParent.left != -1) {
      let parentWidth = this.parent ? this.parent.width : 0;
      if(this.maxSize.x > 0)
        parentWidth = Math.min(this.maxSize.x, parentWidth);
      this.size.x = Math.max(this.minSize.x, parentWidth - this.fitParent.left - this.fitParent.right);
    }

    if(this.fitParent.top != -1) {
      let parentHeight = this.parent ? this.parent.height : 0;
      if(this.maxSize.y > 0)
        parentHeight = Math.min(this.maxSize.y, parentHeight);
      this.size.y = Math.max(this.minSize.y, parentHeight - this.fitParent.top - this.fitParent.bottom);
    }

    if(this.fitParent.left == -1 && this.fitAspect.x > 0)
      this.size.x = this.size.y * this.fitAspect.x;

    if(this.fitParent.top == -1 && this.fitAspect.y > 0)
      this.size.y = this.size.x * this.fitAspect.y;
  }

  public update() {
    var now = performance.now();
    if(this.lastUpdate == 0)
      this.lastUpdate = now;
    this.deltaTime = now - this.lastUpdate;
    this.lastUpdate = now;

    this.updateAutoSize();
    this.updateFade();
    this.updateSlide();
    this.updateScale();

    if(this.fadeAlpha > 0) {
      if(this.fadeAlpha < 1) {
        UIManager.ctx.save();
        UIManager.ctx.globalAlpha = this.fadeAlpha;
      }

    this.draw();

      if(this.fadeAlpha < 1)
        UIManager.ctx.restore();
    }

    this.updateChildren();
  }

  public draw() {
  }

  protected drawClipPath() {
    UIManager.ctx.beginPath();
    UIManager.ctx.moveTo(this.getScreenX(), this.getScreenY());
    UIManager.ctx.lineTo(this.getScreenX() + this.width, this.getScreenY());
    UIManager.ctx.lineTo(this.getScreenX() + this.width, this.getScreenY() + this.height);
    UIManager.ctx.lineTo(this.getScreenX(), this.getScreenY() + this.height);
    UIManager.ctx.closePath();
  }

  public updateChildren() {
    if(this.scrollChild) {
      this.scrollChild.position.x = this.scrollChildOrigin.x + this.scrollPosition.x;
      this.scrollChild.position.y = this.scrollChildOrigin.y + this.scrollPosition.y;
    }

    if(this.clipChildren) {
      UIManager.ctx.save();
      this.drawClipPath();
      UIManager.ctx.clip();
    }

    for(var i = 0; i < this.children.length ; i++) {
      if(this.children[i].visible)
        this.children[i].update();
    }

    if(this.clipChildren) {
      UIManager.ctx.restore();
    }
  }

  public getScreenX(): number {
    if(this.parent == null)
      return 0;

    let px = this.position.x;
    if(this.fitParent.left != -1) {
      if(this.maxSize.x > this.width)
        px = 0;
      else
        px = this.fitParent.left;
    }

    let sx = px - Math.floor(this.width * this.pivot.x) + Math.floor(this.parent.width * this.anchor.x);

    sx += this.parent.getScreenX();

    return sx;
  }

  public getScreenY(): number {
    if(this.parent == null)
      return 0;

    let py = this.position.y;
    if(this.fitParent.top != -1) {
      if(this.maxSize.y > this.height)
        py = 0;
      else
        py = this.fitParent.top;
    }

    let sy = py - Math.floor(this.height * this.pivot.y) + Math.floor(this.parent.height * this.anchor.y);

    sy += this.parent.getScreenY();

    return sy;
  }

  protected setScrollChild() {
    if(this.scrollChild == null) {
      this.scrollChild = this.children[0];
      this.scrollChildOrigin = {x: this.scrollChild.x, y: this.scrollChild.y};
    }
  }

  public onMouseDown(x:number, y: number): void {
    if(this.draggable) {
      this.dragging = true;
      this.dragPoint = new UIPoint(x, y);
      UICanvas.instance.lockMouse(this);
      UICanvas.instance.stopEventPropogation();
      this.onDragStart();
    }

    if(this.scrollChildren.horizontal || this.scrollChildren.vertical) {
      this.setScrollChild();
      this.scrolling = true;
      this.dragPoint = new UIPoint(x, y);
      this.scrollChildLock = UICanvas.instance.getLockedMouse();
      this.scrollStartY = y;
      UICanvas.instance.lockMouse(this);
    }
  }

  public onMouseMove(x:number, y: number): void {
    if(this.dragging) {
      this.onDrag(x, y);
      UICanvas.instance.stopEventPropogation();
    }

    if(this.scrolling) {
      var dx = this.scrollChildren.horizontal ? (x - this.dragPoint.x) : 0;
      var dy = this.scrollChildren.vertical ? (y - this.dragPoint.y) : 0;
      this.dragPoint.x = x;
      this.dragPoint.y = y;
      if(Math.abs(y - this.scrollStartY) > 10)
        this.scrollChildLock = [];
      this.adjustScrollPosition(dx, dy);
    }
  }

  public onMouseUp(x:number, y: number): void {
    if(this.dragging) {
      this.dragging = false;
      this.onDragEnd();
      UICanvas.instance.unlockMouse(this);
      UICanvas.instance.stopEventPropogation();
    }

    if(this.scrollChildren.horizontal || this.scrollChildren.vertical) {
      this.scrolling = false;
      UICanvas.instance.unlockMouse(this);
      if(this.scrollChildLock.length > 0)
        for(let i = this.scrollChildLock.length-1; i >= 0; i--)
          if(this.scrollChildLock[i])
            this.scrollChildLock[i].onMouseUp(x, y);
    }
  }

  public onMouseEnter(x:number, y: number): void {
    //console.log("onMouseEnter " + this.constructor.toString().match(/\w+/g)[1]);
  }

  public onMouseLeave(x:number, y: number): void {
    //console.log("onMouseLeave " + this.constructor.toString().match(/\w+/g)[1]);
  }

  public onMouseWheelUp(dx:number, dy: number): void {
    //console.log("onMouseWheelUp " + this.constructor.toString().match(/\w+/g)[1]);
    if(this.scrollChildren.horizontal || this.scrollChildren.vertical) {
      this.setScrollChild();
      var dx = this.scrollChildren.horizontal ? dx : 0;
      var dy = this.scrollChildren.vertical ? dy : 0;
      this.adjustScrollPosition(dx, dy);
      UICanvas.instance.stopEventPropogation();
    }
  }

  public onMouseWheelDown(dx:number, dy: number): void {
    //console.log("onMouseWheelDown " + this.constructor.toString().match(/\w+/g)[1]);
    if(this.scrollChildren.horizontal || this.scrollChildren.vertical) {
      this.setScrollChild();
      var dx = this.scrollChildren.horizontal ? -dx : 0;
      var dy = this.scrollChildren.vertical ? -dy : 0;
      this.adjustScrollPosition(dx, dy);
      UICanvas.instance.stopEventPropogation();
    }
  }

  public adjustScrollPosition(dx:number, dy:number) {
    this.setScrollChild();

    if(this.height > this.scrollChild.height) {
      this.scrollPosition = {x: 0, y: 0};
      return;
    }

    this.scrollPosition.x += dx;
    this.scrollPosition.y += dy;

    if(this.scrollChild.width > this.width) {
      this.scrollPosition.x = Math.min(this.scrollPosition.x, 0);
      this.scrollPosition.x = Math.max(this.scrollPosition.x, 0 - (this.scrollChild.width - this.width));
    }
    else
      this.scrollPosition.x = 0;

    this.scrollPosition.y = Math.min(this.scrollPosition.y, 0);
    this.scrollPosition.y = Math.max(this.scrollPosition.y, 0 - (this.scrollChild.height - this.height));
  }

  public scrollTo(px:number, py:number) {
    this.setScrollChild();
    this.scrollPosition.y = 0 - (this.scrollChild.height * py) + (this.height/2);
    this.scrollPosition.y = Math.min(this.scrollPosition.y, 0);
    this.scrollPosition.y = Math.max(this.scrollPosition.y, 0 - (this.scrollChild.height - this.height));
    //console.log('after   ' + this.scrollPosition.y);
  }

  public scrollToBottom() {
    this.setScrollChild();
    if(this.height > this.scrollChild.height || this.height == 0)
      this.scrollPosition.y = 0;
    else
      this.scrollPosition.y = 0 - (this.scrollChild.height - this.height);
  }

  public onDragStart() {
    this.dragElement = this;
  }

  public onDrag(x:number, y: number) {
    this.dragElement.position.x += (x - this.dragPoint.x);
    this.dragElement.position.y += (y - this.dragPoint.y);
    this.dragPoint.x = x;
    this.dragPoint.y = y;
  }

  public onDragEnd() {
  }

  public findChildFromPoint(x:number, y:number): UIElement {
    if(!this.visible)
      return null;

    if(this.clipChildren) {
      if(x < this.getScreenX() || x > this.getScreenX() + this.width)
        return null;

      if(y < this.getScreenY() || y > this.getScreenY() + this.height)
        return null;
    }

    for(var i = this.children.length-1; i >=0; i--) {
      var child:UIElement = this.children[i].findChildFromPoint(x, y);
      if(child)
        return child;
    }

    if(!this.mouseEvents)
      return null;

    if(x < this.getScreenX() || x > this.getScreenX() + this.width)
      return null;

    if(y < this.getScreenY() || y > this.getScreenY() + this.height)
      return null;

    return this;
  }

  public findChildFromName(name: string): UIElement {
    for(let i = 0; i < this.children.length; i++)
      if(this.children[i].name == name)
        return this.children[i];
    return null;
  }

  public getChildIndex(child:UIElement):number {
    for(var i = this.children.length-1; i >=0; i--) {
      if(this.children[i] == child)
        return i;
    }
    return -1;
  }

  public fadeIn(duration:number = 250) {
    this.fadeRate = 1 / duration;
    this.fadingIn = true;
  }

  public fadeOut(duration:number = 250) {
    this.fadeRate = 1 / duration;
    this.fadingOut = true;
  }

  public updateFade() {
    if(this.fadingIn) {
      var newAlpha = this.fadeAlpha + (this.deltaTime * this.fadeRate);
      if(newAlpha >= 1) {
        this.setAlpha(1);
        this.fadingIn = false;
      }
      else
        this.setAlpha(newAlpha);
    }

    if(this.fadingOut) {
      var newAlpha = this.fadeAlpha - (this.deltaTime * this.fadeRate);
      if(newAlpha <= 0) {
        this.setAlpha(0);
        this.fadingOut = false;
      }
      else
        this.setAlpha(newAlpha);
    }
  }

  public setAlpha(alpha:number) {
    this.fadeAlpha = alpha;
    for(var i = this.children.length-1; i >=0; i--)
      this.children[i].setAlpha(alpha);
  }

  public getAlpha():number {
    return this.fadeAlpha;
  }

  public slide(fromX:number, fromY:number, toX:number, toY:number, duration:number, callback:Function = null) {
    this.sliding = true;
    this.slideFrom = new UIPoint(fromX, fromY);
    this.slideTo = new UIPoint(toX, toY);
    this.slideDuration = duration;
    this.slideCallback = callback;
    this.slideStartTime = performance.now();
    this.position = {x: fromX, y: fromY};
  }

  public cancelSlide() {
    this.sliding = false;
  }

  public pauseSlide() {
    this.sliding = false;
    this.slidePauseTime = performance.now() - this.slideStartTime;
  }

  public resumeSlide() {
    this.sliding = true;
    this.slideStartTime = performance.now() - this.slidePauseTime;
  }

  public isSliding() {
    return this.sliding;
  }

  protected updateSlide() {
    if(!this.sliding)
      return;

    var elapsed = performance.now() - this.slideStartTime;

    var lerpAmount = elapsed / this.slideDuration;
    if(lerpAmount > 1) {
      this.position = {x: this.slideTo.x, y: this.slideTo.y};
      this.sliding = false;
      if(this.slideCallback)
        this.slideCallback();
    }
    else {
      var newX = this.lerp(this.slideFrom.x, this.slideTo.x, lerpAmount);
      var newY = this.lerp(this.slideFrom.y, this.slideTo.y, lerpAmount);
      this.position = {x: newX, y: newY};
    }
  }

  protected lerp(start:number, end:number, amt:number) {
    return (1-amt)*start+amt*end
  }

  public scaleIn(duration:number = 250, callback:Function = null) {
    this.scalingIn = true;
    this.scaleDuration = duration;
    this.scaleCallback = callback;
    this.scaleStartTime = performance.now();
  }

  public scaleOut(duration:number = 250, callback:Function = null) {
    this.scalingOut = true;
    this.scaleDuration = duration;
    this.scaleCallback = callback;
    this.scaleStartTime = performance.now();
  }

  public updateScale() {
    var elapsed = performance.now() - this.scaleStartTime;

    if(this.scalingIn) {
      var newScale = elapsed / this.scaleDuration;
      if(newScale >= 1) {
        this.scale = 1;
        this.scalingIn = false;
        if(this.scaleCallback)
          this.scaleCallback();
      }
      else {
        this.scale = newScale;
      }
    }

    if(this.scalingOut) {
      var newScale = 1 - (elapsed / this.scaleDuration);
      if(newScale <= 0) {
        this.scale = 0;
        this.scalingOut = false;
        if(this.scaleCallback)
          this.scaleCallback();
      }
      else {
        this.scale = newScale;
      }
    }
  }

  public waitFor(duration:number, callback:Function) {
    this.waitForTimeout = setTimeout(() => {
      callback();
    }, duration);
  }

  public cancelWaitFor() {
    clearTimeout(this.waitForTimeout);
  }
}