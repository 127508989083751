import { getPortraitImage } from "../../app/util/assets";
import { Server } from "../../server/server";
import { Game } from "../whacka/game";
import { UIManager } from "./ui/uiManager";
import { UIPanel, UIPanelOptions } from "./ui/uiPanel";
import { numberWithCommas } from "./util";

interface LeaderboardPanelOptions extends UIPanelOptions {
}

export class LeaderboardPanel extends UIPanel {
  protected leaderboard: any[];
  protected portraits: any[];

  constructor(options: LeaderboardPanelOptions) {
    super(options);

    this.portraits = [];
    this.leaderboard = [];

    // this.leaderboard.push({id: '29cb7c27-0846-4746-9409-d0e2cd94c5f2', score: 987654});
    // this.leaderboard.push({id: '64b8aea4-8293-4130-81a2-6aab12684221', score: 345});
    // this.leaderboard.push({id: '64b8aea4-8293-4130-81a2-6aab12684221', score: 345});
    // this.leaderboard.push({id: '64b8aea4-8293-4130-81a2-6aab12684221', score: 345});
    // this.leaderboard.push({id: '64b8aea4-8293-4130-81a2-6aab12684221', score: 345});
    // this.leaderboard.push({id: '64b8aea4-8293-4130-81a2-6aab12684221', score: 345});
    // this.leaderboard.push({id: '64b8aea4-8293-4130-81a2-6aab12684221', score: 345});
    // this.leaderboard.push({id: '64b8aea4-8293-4130-81a2-6aab12684221', score: 345});
    // this.loadPortraits();
    // this.sortLeaderboard();

    this.onLeaderboard = this.onLeaderboard.bind(this);
    this.onScore = this.onScore.bind(this);
  }

  public connect(host:string) {
    Server.places.addEventListener('place-leaderboard', this.onLeaderboard);
    Server.places.addEventListener('place-score', this.onScore);
    this.leaderboard = Server.places.getLeaderboard(host);
    this.loadPortraits();
    this.sortLeaderboard();
  }

  public disconnect() {
    Server.places.removeEventListener('place-leaderboard', this.onLeaderboard);
    Server.places.removeEventListener('place-score', this.onScore);
  }

  protected onLeaderboard(leaderboard:any[]) {
    this.leaderboard = leaderboard;
    this.loadPortraits();
    this.sortLeaderboard();
  }

  protected loadPortraits() {
    for(let i = 0; i < this.leaderboard.length; i++) {
      let profile = Server.public.getProfile(this.leaderboard[i].id);
      this.leaderboard[i].name = profile.name;
      let portrait = new Image();
      portrait.src = getPortraitImage(profile);
      this.leaderboard[i].portrait = portrait;
    }
  }

  protected onScore(user:any) {
    for(let i = 0; i < this.leaderboard.length; i++)
      if(this.leaderboard[i].id == user.id)
        this.leaderboard[i].score = user.score;
    this.sortLeaderboard();
  }

  protected sortLeaderboard() {
    this.leaderboard.sort((a, b)=>{
      if(a.score < b.score)
        return 1;
      else if(a.score > b.score)
        return -1;
      return 0;
    });
  }

  public draw() {
    super.draw();

    UIManager.ctx.font = '10pt arial';
    UIManager.ctx.fillStyle = 'white';
    UIManager.ctx.textAlign = 'left';
    UIManager.ctx.textBaseline = 'middle';

    UIManager.ctx.save();
    UIManager.ctx.beginPath();
    UIManager.ctx.moveTo(this.getScreenX(), this.getScreenY());
    UIManager.ctx.lineTo(this.getScreenX() + this.width, this.getScreenY());
    UIManager.ctx.lineTo(this.getScreenX() + this.width, this.getScreenY() + this.height);
    UIManager.ctx.lineTo(this.getScreenX(), this.getScreenY() + this.height);
    UIManager.ctx.closePath();
    UIManager.ctx.clip(); 

    let sx = 5;
    
    for(let i = 0; i < this.leaderboard.length; i++) {
      if(this.leaderboard[i].portrait) {
        let px = this.getScreenX() + sx;
        let py = this.getScreenY() + 5;
        let pw = this.height - 10;
        UIManager.ctx.save();
        UIManager.ctx.beginPath();
        UIManager.ctx.arc(px+pw/2, py+pw/2, pw/2, 0, 2 * Math.PI, false); 
        UIManager.ctx.clip(); 
        UIManager.ctx.drawImage(this.leaderboard[i].portrait, px, py, pw, pw);
        UIManager.ctx.restore();
        sx += pw+5;
      }

      if(this.leaderboard[i].name) {
        let name = this.leaderboard[i].name;
        let parts = name.split(' ');
        if(parts.length > 1)
          name = parts[0];
        let max = UIManager.isMobile ? 10 : 15;
        if(name.length > max)
          name = name.substring(0, max-3) + '...';
        let s = numberWithCommas(this.leaderboard[i].score);
        UIManager.ctx.fillText(name, this.getScreenX() + sx, this.getScreenY() + 14);
        let nameWidth = UIManager.ctx.measureText(name).width;
        UIManager.ctx.fillText(s, this.getScreenX() + sx, this.getScreenY() + 29);
        let scoreWidth = UIManager.ctx.measureText(s).width;
        sx += Math.max(nameWidth, scoreWidth);
        sx += 10;
      }
    }
 
    UIManager.ctx.restore();
  }
}