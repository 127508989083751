import React from 'react';
import './Modal.css'

interface ViewImageModalProps {
  src: string;
  onClose: Function;
}

class ViewImageModal extends React.Component<ViewImageModalProps, {}> {
  constructor(props:ViewImageModalProps) {
    super(props);
  }
  
  render() {
    if(this.props.src == '')
      return null;

    return (
      <div className="modal open">
        <div style={{display: 'flex', flexDirection: 'column', backgroundColor: 'black', height: '100%'}} onClick={()=>this.props.onClose()}>
          <img style={{objectFit: 'scale-down', width: '100%', height: '100%'}} src={this.props.src} />
        </div>
      </div>
    )
  }
}

export default ViewImageModal;