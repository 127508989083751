import React from 'react';
import { Navigate } from 'react-router-dom';
import { Server } from '../../server/server';
import PageEnvelope from '../elements/PageEnvelope';
import NotificationsPanel from '../elements/NotificationsPanel';

class NotificationsPage extends React.Component {
  render() {
    if(!Server.account.isLoggedIn())
      return(<Navigate replace to="/" />);

    return (
      <div className="site-page">
        <PageEnvelope width="500px">
          <NotificationsPanel />
        </PageEnvelope>
      </div>
    )
  }
}

export default NotificationsPage;