import React from 'react';
import { Navigate } from 'react-router-dom';
import { Game as Whacka } from '../../games/whacka/game';
import { Game as Matcha } from '../../games/matchThree/game';
import { GameMode as MatchaGameMode } from '../../games/matchThree/game';
import { Game as Solitaire } from '../../games/solitaire/game';
import { GameMode as SolitaireGameMode } from '../../games/solitaire/game';
import { Game as Wordsy } from '../../games/words/game';
import { Game as Yahtzee } from '../../games/dice/game';
import { Game as BlockBuster } from '../../games/shapes/game';
import { Game as Bubblez } from '../../games/bubbles/game';
import { Game as BermudaTriangle } from '../../games/battleship/game';
import { Game as DoubleUp } from '../../games/numbers/game';
import { Database } from '../util/database';
import { Server } from '../../server/server';
import './PlayGamePage.css'

interface PlayGamePageState {
  navigate: string;
}

class PlayGamePage extends React.Component<{}, PlayGamePageState> {
  protected game: any;

  constructor(props: {}) {
    super(props);
    this.game = null;
    this.onGameEvent = this.onGameEvent.bind(this);
    this.state = {
      navigate: ''
    }
  }

  getGameId():string {
    return window.location.pathname.substring(6);
  }

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    let s = window.sessionStorage.getItem('PlayingGame');
    if(s) {
      console.warn('Removing PlayingGame');
      window.sessionStorage.removeItem('PlayingGame');
    }

    if(this.game) {
      this.game.unload();
      this.game = null;
    }
  }

  async load() {
    let canvas = document.getElementById('GameCanvas') as HTMLCanvasElement;
    if(!canvas) return;
    canvas.style.display = 'none';

    let multiplayer = false;
    let s = window.sessionStorage.getItem('PlayingGame');
    if(s) {
      multiplayer = true;
      let item = JSON.parse(s);
      Server.places.setUserState(`game:${item.game}`, item.host);
    }

    let gameId = this.getGameId();

    if (gameId == 'whacka') 
      this.game = new Whacka(canvas, this.onGameEvent);
    else if (gameId == 'solitaire') 
      this.game = new Solitaire(canvas, this.onGameEvent, SolitaireGameMode.Arcade);
    else if (gameId == 'matcha') 
      this.game = new Matcha(canvas, this.onGameEvent, MatchaGameMode.Daily);
    else if (gameId == 'matcha-blitz') 
      this.game = new Matcha(canvas, this.onGameEvent, MatchaGameMode.Arcade);
    else if (gameId == 'matcha-adventure') 
      this.game = new Matcha(canvas, this.onGameEvent, MatchaGameMode.Adventure);
    else if (gameId == 'wordsy') 
      this.game = new Wordsy(canvas, this.onGameEvent);
    else if (gameId == 'yahtzee') 
      this.game = new Yahtzee(canvas, this.onGameEvent);
    else if (gameId == 'block-buster') 
      this.game = new BlockBuster(canvas, this.onGameEvent);
    else if (gameId == 'bubblez') 
      this.game = new Bubblez(canvas, this.onGameEvent);
    else if (gameId == 'bermuda-triangle') 
      this.game = new BermudaTriangle(canvas, this.onGameEvent);
    else if (gameId == 'double-up') 
      this.game = new DoubleUp(canvas, this.onGameEvent);

    Server.games.addRecentlyPlayedGame(gameId);

    setTimeout(() => {
      this.game.load();
    }, 500);
  }

  async onGameEvent(action:string) {
    let s = window.sessionStorage.getItem('PlayingGame');
    let item = s ? JSON.parse(s) : null;

    if(action == 'loaded') {
      let canvas = document.getElementById('GameCanvas') as HTMLCanvasElement;
      if(canvas)
        canvas.style.display = 'block';
    }
    else if(action) {
      if(item)
        Server.places.leavePlace(item.host);
      this.setState({navigate: action});
    }
    else {
      if(item) {
        Server.places.setUserState('finished', item.host);
        this.setState({navigate: '/place/' + item.slug});
      }
      else {
        Server.games.clearCaches(this.getGameId());
        sessionStorage.setItem('HomePageTab', 'games');
        this.setState({navigate: '/'});
      }
    }
  }

  render() {
    if(!Server.account.isLoggedIn())
      return <Navigate to="/" replace />;

    let gameId = this.getGameId();
    let game = Database.getGame(gameId);

    if(!game)
      return (<Navigate to="/play" />);

    if(this.state.navigate != '')
      return (<Navigate to={this.state.navigate} />);
      
    return (
      <div className="canvas-container">
        <div className="site-page-row">
          <div className="site-page-panel" style={{rowGap: '5px', margin: 'auto', marginTop: '30px'}}>
            <img src={'/games/' + game.image} style={{width: '200px', borderRadius: '10px', margin: 'auto'}} />
            <div style={{textAlign: 'center'}}>Loading...</div>
          </div>
        </div>
        <canvas id="GameCanvas" className="game-canvas" />
      </div>
    );
  }
}

export default PlayGamePage;
