import { UIElement } from "./uiElement";
import { UIScreen } from "./uiScreen";
import { UIDialog } from "./uiDialog";
import { UIPanel } from "./uiPanel";
import { UIPoint } from "./uiPoint";
import {UIManager} from './uiManager';

export class UICanvas extends UIElement {
  public static instance:UICanvas;

  public mousePosition: UIPoint;

  protected lockedMouse: UIElement[];
  protected lastMouseMoveChild: UIElement;
  protected currentScreen: UIScreen;
  protected dialogs: Array<UIDialog>;
  protected covers: Array<UIPanel>;
  protected propgatingEvent:boolean;

  constructor() {
    super({});

    this.mouseEvents = true;
    this.lockedMouse = [];
    this.lastMouseMoveChild = null;
    this.mousePosition = {x: 0, y: 0};
    this.currentScreen = null;
    this.dialogs = new Array<UIDialog>();
    this.covers = new Array<UIPanel>();
    this.propgatingEvent = false;

    UICanvas.instance = this;
  }

  public lockMouse(child:UIElement) {
    this.lockedMouse.push(child);
  }

  public unlockMouse(child:UIElement) {
    let i = this.lockedMouse.indexOf(child);
    if(i != -1)
      this.lockedMouse.splice(i, 1);
  }

  public getLockedMouse():UIElement[] {
    return this.lockedMouse;
  }

  public onSystemMouseDown(x:number, y: number): void {
    this.mousePosition = {x:x, y:y};
    if(this.lockedMouse.length > 0) {
      for(let i = this.lockedMouse.length-1; i >= 0; i--)
        this.lockedMouse[i].onMouseDown(x, y);
    }
    else {
      this.propgatingEvent = true;
      var child = this.findChildFromPoint(x, y);
      if(child) {
        child.onMouseDown(x, y);
        while(this.propgatingEvent && child.parent) {
          child.parent.onMouseDown(x, y);
          child = child.parent;
        }
      }
    }
  }

  public onSystemMouseUp(x:number, y: number): void {
    this.mousePosition = {x:x, y:y};
    if(this.lockedMouse.length > 0) {
      for(let i = this.lockedMouse.length-1; i >= 0; i--) {
        if(this.lockedMouse[i])
          this.lockedMouse[i].onMouseUp(x, y);
      }
    }
    else {
      this.propgatingEvent = true;
      var child = this.findChildFromPoint(x, y);
      if(child) {
        child.onMouseUp(x, y);
        while(this.propgatingEvent && child.parent) {
          child.parent.onMouseUp(x, y);
          child = child.parent;
        }
      }
    }
  }

  public onSystemMouseMove(x:number, y: number): void {
    this.mousePosition = {x:x, y:y};

    var child = this.findChildFromPoint(x, y);

    if(child == null)
      return;

    if(child != this.lastMouseMoveChild) {
      if(this.lastMouseMoveChild)
        this.lastMouseMoveChild.onMouseLeave(x, y);
      child.onMouseEnter(x, y);
      this.lastMouseMoveChild = child;
    }

    if(this.lockedMouse.length > 0) {
      for(let i = this.lockedMouse.length-1; i >= 0; i--)
        this.lockedMouse[i].onMouseMove(x, y);
    }
    else {
      this.propgatingEvent = true;
      child.onMouseMove(x, y);
      while(this.propgatingEvent && child.parent) {
        child.parent.onMouseMove(x, y);
        child = child.parent;
      }
    }
  }

  public onSystemMouseWheelUp(x:number, y: number): void {
    this.mousePosition = {x:x, y:y};
    this.propgatingEvent = true;
    var child = this.findChildFromPoint(x, y);
    if(child) {
      child.onMouseWheelUp(x, y);
      while(this.propgatingEvent && child.parent) {
        child.parent.onMouseWheelUp(x, y);
        child = child.parent;
      }
    }
  }

  public onSystemMouseWheelDown(x:number, y: number): void {
    this.mousePosition = {x:x, y:y};
    this.propgatingEvent = true;
    var child = this.findChildFromPoint(x, y);
    if(child) {
      child.onMouseWheelDown(x, y);
      while(this.propgatingEvent && child.parent) {
        child.parent.onMouseWheelDown(x, y);
        child = child.parent;
      }
    }
  }

  public stopEventPropogation():void {
    this.propgatingEvent = false;
  }

  public setScreen(screen:UIScreen) {
    this.removeCurrentScreen();
    this.currentScreen = screen;
    this.addChild(this.currentScreen);
    this.currentScreen.onWake();
  }

  public removeCurrentScreen() {
    if(this.currentScreen) {
      this.removeChild(this.currentScreen);
      this.currentScreen.onSleep();
      this.currentScreen = null;
    }

    while(this.dialogs.length > 0) {
      this.hideDialog(this.dialogs[this.dialogs.length-1]);
    }
  }

  public showDialog(dialog:UIDialog, modal:boolean = true){
    var cover = new UIPanel({
      color: modal ? '#00000080' : '#00000000',
      fitParent: {left:0,top:0,right:0,bottom:0},
      mouseEvents: modal
    });

    this.covers.push(cover);
    this.dialogs.push(dialog);

    this.addChild(cover);
    this.addChild(dialog);

    dialog.onWake();
  }

  public hideDialog(dialog:UIDialog){
    var i = this.dialogs.indexOf(dialog);
    if(i == -1)
      return;

    var cover = this.covers[i];

    dialog.onSleep();

    this.removeChild(dialog);
    this.removeChild(cover);

    this.dialogs.splice(i, 1);
    this.covers.splice(i, 1);

    // if (UIManager.ctx)
    //    UIManager.ctx.clearRect(0,0,UIManager.htmlCanvas.width,UIManager.htmlCanvas.height);
  }

  public hideAllDialogs() {
    while(this.dialogs.length > 0) {
      this.hideDialog(this.dialogs[this.dialogs.length-1]);
    }
  }

  public popDialog() {
    if(this.dialogs.length == 0)
      return;
    this.hideDialog(this.dialogs[this.dialogs.length-1]);
  }

  public dialogsVisible():boolean {
    return this.dialogs.length > 0;
  }

  public isDialogVisible(dlg:UIDialog):boolean {
    for(let i = 0; i < this.dialogs.length; i++)
      if(this.dialogs[i] == dlg)
        return true;
    return false;
  }

  public isCurrentScreen(s:UIScreen):boolean {
    return this.currentScreen == s;
  }

  public findChildUnderMouse():UIElement {
    return this.findChildFromPoint(this.mousePosition.x, this.mousePosition.y);
  }

  public isPortrait():boolean {
    return UIManager.canvas.size.y > UIManager.canvas.size.x;
  }

  public isLandscape():boolean {
    return !this.isPortrait();
  }
}