import { UIElement } from "./uiElement";
import { UIElementOptions } from "./uiElement";
import { UIText } from "./uiText";
import { UICanvas } from "./uiCanvas";
import { UIPoint } from "./uiPoint";
import { UIManager } from "./uiManager";

export interface UIButtonOptions extends UIElementOptions {
  elements?: {
    normal: UIElement,
    hover?: UIElement,
    pressed?: UIElement,
    disabled?: UIElement
  };
  callback?: Function;
  text?: string;
  textOffset?: UIPoint;
  textHorizontalAlign?: string,
  textVerticalAlign?: string,
  fontSize?: number;
  fontFamily?: string;
  textColors?: {
    normal: string,
    hover?: string,
    pressed?: string,
    disabled?: string
  };
  disabled?: boolean;
  value?: any;
  toggle?: boolean;
  clickRadius?: number;
}

export class UIButton extends UIElement {
  public disabled:boolean;
  public pressed: boolean;

  protected elements:any[];
  protected textElement: UIText;
  protected textColors:string[];
  protected hovering: boolean;
  protected callback: Function;
  protected value: any;
  protected toggle: boolean;
  protected startX: number;
  protected startY: number;
  protected clickRadius: number;
  protected wasPressed:boolean;

  public set text(s:string) {
    if(this.textElement != null)
      this.textElement.text = s;
  };

  public get text():string {
    if(this.textElement != null)
      return this.textElement.text;
    else
      return '';
  }

  constructor(options: UIButtonOptions) {
    super(options);

    this.mouseEvents = true;
    this.hovering = false;
    this.pressed = false;
    this.disabled = false;

    this.elements = [];
    this.textColors = [];
    this.value = undefined;
    this.toggle = false;
    this.clickRadius = 0;
    this.callback = null;
    this.startX = 0;
    this.startY = 0;
    this.wasPressed = false;

    for(var i = 0; i < 4; i++) {
      this.elements.push(null);
      this.textColors.push('#ffffff');
    }

    if(this.elements) {
      if(options.elements.normal)
        this.elements[0] = options.elements.normal;

      if(options.elements.hover)
        this.elements[1] = options.elements.hover;

      if(options.elements.pressed)
        this.elements[2] = options.elements.pressed;

      if(options.elements.disabled)
        this.elements[3] = options.elements.disabled;
    }

    for(var i = 0; i < 4; i++)
      if(this.elements[i])
        this.addChild(this.elements[i]);

    if(options.textColors) {
      if(options.textColors.normal)
        this.textColors[0] = options.textColors.normal;

      if(options.textColors.hover)
        this.textColors[1] = options.textColors.hover;
      else
        this.textColors[1] = options.textColors.normal;

      if(options.textColors.pressed)
        this.textColors[2] = options.textColors.pressed;
      else
        this.textColors[2] = options.textColors.normal;

      if(options.textColors.disabled)
        this.textColors[3] = options.textColors.disabled;
      else
        this.textColors[3] = options.textColors.normal;
    }

    if(options.disabled != null)
      this.disabled = options.disabled;

    if(options.callback)
      this.callback = options.callback;

    if(options.value != null)
      this.value = options.value;

    if(options.toggle != null)
      this.toggle = options.toggle;

      var fontFamily = 'verdana';
    if(options.fontFamily)
      fontFamily = options.fontFamily;

    var fontSize = 24;
    if(options.fontSize)
      fontSize = options.fontSize;

    var textOffset = {x: 0, y: 0};
    if(options.textOffset)
      textOffset = options.textOffset;

    this.textElement = new UIText({
      text: options.text ? options.text : '',
      color: this.textColors[0],
      fontFamily: fontFamily,
      fontSize: fontSize,
      verticalAlign: options.textVerticalAlign ? options.textVerticalAlign : 'center',
      horizontalAlign: options.textHorizontalAlign ? options.textHorizontalAlign : 'center',
      anchor: {x: 0.5, y: 0.5},
      pivot: {x: 0.5, y: 0.5},
      position: textOffset,
      parent: this
    });

    if (options.textVerticalAlign == 'top')
      this.textElement.pivot.y = 0;
    else if (options.textVerticalAlign == 'bottom')
      this.textElement.pivot.y = 1;

    if (options.textHorizontalAlign == 'left')
      this.textElement.pivot.x = 0;
    else if (options.textHorizontalAlign == 'right')
      this.textElement.pivot.x = 1;

    if(options.clickRadius != undefined)
      this.clickRadius = options.clickRadius;
  }

  public onMouseDown(x:number, y: number): void {
    if(this.disabled)
      return;

    if(this.toggle) {
      this.pressed = !this.pressed;
      this.doCallback();
    }
    else {
      this.pressed = true;
      this.hovering = true;
      this.startX = x;
      this.startY = y;
    }

    UICanvas.instance.lockMouse(this);
    UICanvas.instance.stopEventPropogation();
  }

  public onMouseUp(x:number, y: number): void {
    if(!this.toggle) {
      if(!this.disabled && this.pressed && this.hovering) {
        let a = x - this.startX;
        let b = y - this.startY;
        let dist = Math.sqrt( a*a + b*b )
        if((this.clickRadius == 0 || dist < this.clickRadius))
          this.doCallback();
      }

      this.pressed = false;
    }

    UICanvas.instance.unlockMouse(this);
    UICanvas.instance.stopEventPropogation();
  }

  public onMouseEnter(x:number, y: number): void {
    this.hovering = true;
  }

  public onMouseLeave(x:number, y: number): void {
    this.hovering = false;
  }

  protected doCallback() {
    if(this.callback)
      this.callback(this);
  }

  public forcePressed(b:boolean) {
    this.pressed = b;
  }

  public isPressed():boolean {
    return this.pressed;
  }

  public updateAutoSize() {
    super.updateAutoSize();

    if(this.width == 0 && this.elements[0])
      this.size.x = this.elements[0].width;

    if(this.height == 0 && this.elements[0])
      this.size.y = this.elements[0].height;

    this.updateElements();
    this.updateTextColor();
  }

  public getState(): number {
    var ci = 0;
    if(this.disabled)
      ci = 3;
    else if(!this.toggle && this.pressed && this.hovering)
      ci = 2;
    else if(this.toggle && this.pressed)
      ci = 2;
    else if(this.hovering)
      ci = 1;

    return ci;
  }

  public updateElements() {
    var state = this.getState();
    if(!this.elements[state])
      state = 0;

    for(var i = 0; i < this.elements.length; i++)
      if(this.elements[i])
        this.elements[i].visible = (i == state);
  }

  public updateTextColor() {
    if(this.textElement) {
      var state = this.getState();
      if(!this.textColors[state])
        state = 0;
      this.textElement.color = this.textColors[state];
    }
  }
}