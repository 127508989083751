import React from 'react';
import PageEnvelope from '../elements/PageEnvelope';
import PageHeader from '../elements/PageHeader';
import { WithRouterProps } from '../util/withRouter';
import { Server } from '../../server/server';
import { Database } from '../util/database';
import AssetPanel from '../elements/AssetPanel';
import './SitePage.css';
import './InventoryPage.css';
import MessageModal from '../modals/MessageModal';
import AlertModal from '../modals/AlertModal';
import { Navigate } from 'react-router-dom';
import { getBannerImage } from '../util/assets';

interface ProfileAssetsPageProps {
  router: WithRouterProps;
}

interface ProfileAssetsPageState {
  collection: string;
  message: string;
  alert: string;
  navigate: string;
}

class ProfileAssetsPage extends React.Component<ProfileAssetsPageProps , ProfileAssetsPageState> {
  constructor(props:ProfileAssetsPageProps) {
    super(props);
    this.state = {
      collection: '',
      message: '',
      alert: '',
      navigate: ''
    }
  }

  componentDidMount(): void {
    this.load();
  }

  componentDidUpdate(prevProps: Readonly<ProfileAssetsPageProps>) {
    if(prevProps.router.location.pathname != this.props.router.location.pathname) 
      this.load();
  }

  async load() {
    let parts = window.location.pathname.split('/');
    if(parts[1] == 'place')
      this.setState({collection: parts[3]});
    else
      this.setState({collection: parts[2]});
  }

  onSetPortrait(id:string) {
    this.setState({message: `Saving profile...`});
    
    setTimeout(async () => {
      let response = await Server.user.setProfile({portrait: id});
      this.setState({message: ''});
      if(response.success) {
        Server.user.setCachedPortrait(id)
        Server.network.sendEvent('friends', {
          id: 'friend-portrait', 
          portrait: id, 
          user: Server.user.getId()
        });    
        this.setState({navigate: '/profile'});
      }
      else
        this.setState({alert: response.message})
    }, 500);
  }

  onSetPlaceBanner(id:string) {
    this.setState({message: `Saving ...`});

    setTimeout(async () => {
      await Server.places.setBanner(id);
      this.setState({navigate: '/place/' + Server.user.getSlug()});
      }, 500);
  }

  onSetBanner(id:string) {
    let parts = window.location.pathname.split('/');

    if(parts[1] == 'place') {
      this.onSetPlaceBanner(id);
      return;
    }

    this.setState({message: `Saving profile...`});
    
    setTimeout(async () => {
      let response = await Server.user.setProfile({banner: id});
      this.setState({message: ''});
      if(response.success) {
        Server.user.setCachedBanner(id);
        this.setState({navigate: '/profile'})
      }
      else
        this.setState({alert: response.message})
    }, 500);
  }

  renderPortraits(assets:any) {
    let divs = [];
    for(let i = 0; i < assets.length; i++) {
      let asset = Database.getAsset(assets[i].id);
      if(!asset) continue;
      divs.push(
        <AssetPanel 
          key={i}
          asset={asset} 
          quantity={assets[i].quantity} 
          showQuantity={false}
          onClick={()=>this.onSetPortrait(asset.id)}
          navigate={false}
        />
      )
    }

    return (
      <div className="inventory-page-grid">
        {divs}
      </div>
    )
  }

  renderBanners(assets:any) {
    let divs = [];
    for(let i = 0; i < assets.length; i++) {
      divs.push(
        <div key={i} className="site-page-panel" style={{padding: '5px', cursor: 'pointer'}} onClick={()=>this.onSetBanner(assets[i].id)}>
          <img src={getBannerImage({banner: assets[i].id})} style={{width: '100%', borderRadius: '6px'}} /> 
        </div>
      )
    }

    return (
      <div className="inventory-page-banner-grid">
        {divs}
      </div>
    )
  }

  render() {
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />
      
    let header = 'Select ' + (this.state.collection == 'banners' ? 'Banner' : 'Portrait');

    let inventory = Server.assets.getInventory();
    let assets = [];

    for(let i = 0; i < inventory.length; i++) {
      let asset = Database.getAsset(inventory[i].id);
      if(!asset) continue;
      if(this.state.collection == asset.collection)
        assets.push(inventory[i]);
      else if(this.state.collection == 'portraits') {
        let assetCollection = Database.getCollection(asset.collection);
        if(assetCollection && assetCollection.portrait) 
          assets.push(inventory[i]);
      }
    }

    assets.sort((a, b)=>{
      let aasset = Database.getAsset(a.id);
      let basset = Database.getAsset(b.id);

      if(aasset.collection == 'portraits' && basset.collection !=' portraits')
        return -1;
      else if(aasset.collection != 'portraits' && basset.collection ==' portraits')
        return 1;
      
      if(aasset.collection < basset.collection)
        return -1;
      else if(aasset.collection > basset.collection)
        return 1;

      if(aasset.name < basset.name)
        return -1;
      else if(aasset.name > basset.name)
        return 1;

      return 0;
    })

    return (
      <div className="site-page">
        <PageEnvelope width="660px">
          <div className="site-page-column">
            <PageHeader text={header} navigate={`/profile`} />
            {this.state.collection == 'banners' ? this.renderBanners(assets) : this.renderPortraits(assets)}
          </div>
        </PageEnvelope>
        <MessageModal message={this.state.message}/>
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
      </div>
    )
  }
}

export default ProfileAssetsPage;