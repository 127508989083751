export class Database {
  public static app: any = require('../../data/app.json');
  public static games: any = require('../../data/games.json');
  public static assets: any = [];
  public static collections: any = require('../../data/collections.json');
  public static shop: any = require('../../data/shop.json');
  public static rewards: any = require('../../data/rewards.json');
  public static packs: any = require('../../data/packs.json');

  public static init() {
    Database.loadAssets();
    Database.loadCollections();
    Database.loadShop();
  }

  protected static loadAssets() {
    let db = require('../../data/assets.json');
    let collections = Object.keys(db);
    for(let i = 0; i < collections.length; i++) {
      let collection = db[collections[i]];
      let assets = Object.keys(collection);

      for(let j = 0; j < assets.length; j++) {
        let assetId = assets[j];
        let idx = assetId.indexOf('-*');

        if(idx != -1) {
          let base = assetId.substring(0, idx+1);
          let count = +assetId.substring(idx+2);

          for(let k = 0; k < count; k++) {
            let aid = (k+1).toString();
            aid = base + aid.padStart(3, '0');
            let asset = {...collection[assetId]};
            asset.id = aid;
            asset.collection = collections[i];
            Database.assets[aid] = asset;
          }
        }
        else {
          let asset = {...collection[assetId]};
          asset.id = assetId;
          asset.collection = collections[i];
          Database.assets[assetId] = asset;
        }
      }
    }
  }

  protected static loadCollections() {
    let ids = Object.keys(Database.collections);
    for(let i = 0; i < ids.length; i++) 
      Database.collections[ids[i]].id = ids[i];
  }

  protected static loadShop() {
    let ids = Object.keys(Database.shop);
    for(let i = 0; i < ids.length; i++) 
      Database.shop[ids[i]].id = ids[i];
  }

  public static getAssets(collection:string = 'all'):any[] {
    let assets = [];
    let ids = Object.keys(Database.assets);
    for(let i = 0; i < ids.length; i++) {
      let asset = Database.assets[ids[i]];
      if(collection == 'all' || asset.collection == collection)
        assets.push(asset);
    }
    return assets;
  }

  public static getAsset(id:string): any {
    return Database.assets[id];
  }

  public static getAssetName(id:string, singular:boolean = true): string {
    let asset = this.getAsset(id);
    if(!asset)
      return 'Unknown';

    let name = asset.name;
    
    if(!name) {
      let collection = this.getCollection(asset.collection);
      if(!collection)
        return 'Unknown';
      name = singular ? collection.noun.singular : collection.noun.plural;
    }
    
    return name;
  }

  public static getCollections() {
    return Database.collections;
  }

  public static getCollection(id:string) {
    return Database.collections[id];
  }

  public static getGame(id:string): any {
    for(let i = 0; i < Database.games.length; i++) 
      if(Database.games[i].id == id)
        return Database.games[i];
    return null;
  }

  public static getPreviousGame(id:string, isDeveloper:boolean): any {
    let idx = -1;
    for(let i = 0; i < Database.games.length; i++) 
      if(Database.games[i].id == id)
        idx = i;

    if(idx == -1)
      return null;

    let search = true;
    while(search) {
      idx--;
      if(idx < 0)
        idx = Database.games.length-1;

      let game = Database.games[idx];
      if(game.leaderboard && (!game.developer || game.developer && isDeveloper))
        search = false;
    }

    return Database.games[idx];
  }

  public static getNextGame(id:string, isDeveloper:boolean): any {
    let idx = -1;
    for(let i = 0; i < Database.games.length; i++) 
      if(Database.games[i].id == id)
        idx = i;

    if(idx == -1)
      return null;

    let search = true;
    while(search) {
      idx++;
      if(idx > Database.games.length - 1)
        idx = 0;
  
      let game = Database.games[idx];
      if(game.leaderboard && (!game.developer || game.developer && isDeveloper))
        search = false;
    }

    return Database.games[idx];
  }

  public static getShop() {
    let shop = [];
    let ids = Object.keys(Database.shop);
    for(let i = 0; i < ids.length; i++) 
      shop.push(Database.shop[ids[i]]);
    return shop;
  }

  public static getShopAsset(id:string) {
    return Database.shop[id];
  }

  public static getShopAssetPrice(id:string) {
    let asset = this.getShopAsset(id);
    if(asset) return asset.price;
    return 0;
  }

  public static getRewards() {
    return Database.rewards;    
  }

  public static getMinRewardScore(gameId:string) {
    let game = Database.rewards.games[gameId]; 
    return game.score.min;
  }

  public static getMaxRewardScore(gameId:string) {
    let game = Database.rewards.games[gameId]; 
    return game.score.max;
  }

  public static getRewardType(gameId:string) {
    let game = Database.rewards.games[gameId]; 
    return game.type;
  }

  public static getBaseRewardChance() {
    return Database.rewards.chance;
  }

  public static getPack(id:string) {
    return Database.packs[id];
  }

  public static getPackWithAsset(assetId:string) {
    let pids = Object.keys(Database.packs);
    for(let p = 0; p < pids.length; p++) {
      let pack = Database.packs[pids[p]];
      let match = false;

      for(let i = 0; i < pack.slots.length; i++) {
        let slot = pack.slots[i];

        for(let j = 0; j < slot.loot.length; j++) {
          let aid = slot.loot[j].asset;
          if(aid) {
            let idx = aid.indexOf('-*');
            if(idx == -1)
              match = (aid == assetId);
            else
              match = (aid.substring(0, idx) == assetId.substring(0, idx));
          }
        }

        if(match)
          return pids[p];
      }
    }

    return null;
  }

  public static getRecipeIngredients(assetId:any) {
    let asset = this.getAsset(assetId);
    
    let ingredients = [];

    if(!asset.recipe)
      return ingredients;

    for(let i = 0; i < asset.recipe.length; i++) {
      let ingredient = asset.recipe[i];
      let ingredientQuantity = ingredient.quantity ? ingredient.quantity : 1;

      if(ingredient.id.indexOf('-*') == -1) {
        ingredients.push({id: ingredient.id, quantity: ingredientQuantity});
      }
      else {
        let parts = ingredient.id.split('*');
        let baseId = parts[0];
        let count = +parts[1];

        for(let j = 0; j < count; j++) {
          let id = baseId + (j+1).toString().padStart(3, '0');
          ingredients.push({id, quantity: ingredientQuantity});
        }
      }
    }

    return ingredients;
  }
}