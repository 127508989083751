import { Server } from "./server";

export class ServiceResponse {
  success: boolean;
  message?: string;
}

export class Service {
  public static authorizationToken: string = '';
  public static endpoint: string = '';

  public static configure(endpoint:string) {
    Service.endpoint = endpoint;
  }

  protected listeners:any[];

  constructor() {
    this.listeners = [];
  }

  public async init(): Promise<ServiceResponse> {
    return new Promise((resolve)=>{
      resolve({success: true});
    })
  }

  public async sync(): Promise<ServiceResponse> {
    return new Promise((resolve)=>{
      resolve({success: true});
    })
  }

  protected async sendCommand(method:string, command:string, params:any = null) {
    let response = await this.postCommand(method, command, params);

    if(response.status == 500) {
      let refreshResponse = await Server.refreshServiceLogin();
      if(!refreshResponse.success)
        return response;
      response = await this.postCommand(method, command, params);
    }
    
    return response;
  }

  protected postCommand(method:string, command:string, params:any = null): Promise<{status:number, body?:any}> {
    return new Promise((resolve, reject)=>{
      var request = new XMLHttpRequest();
      request.open('POST', `https://${Service.endpoint}/${method}`);
      request.setRequestHeader('Content-Type', 'application/json');

      if(Service.authorizationToken != '')
        request.setRequestHeader('Authorization', Service.authorizationToken);
  
      let payload = {command};

      if(params) 
        payload = {command, ...params};

      let body = JSON.stringify(payload);

      let isProduction = (window.location.href.indexOf('www.') != -1);
      if(!isProduction)
        console.log('==>', payload);
      
      request.send(body);
  
      request.onload = () => {
        let body = null;
        if (request.responseText != '')
          body = JSON.parse(request.responseText);
  
        if(!isProduction)
          console.log('<==', body);
  
        resolve({
          status: request.status,
          body: body
        });
      };
  
      request.onerror = (e) => {
        if(!isProduction)
          console.log('<== ERROR');
        resolve({
          status: 500,
          body: {message: 'Internal service error!'}
        });
      };
    });
  }

  public addEventListener(id:string, callback:Function) {
    this.listeners.push({id, callback});
  }

  public removeEventListener(id:string, callback:Function) {
    for(let i = 0; i < this.listeners.length; i++) {
      if(this.listeners[i].id == id && this.listeners[i].callback == callback) {
        this.listeners.splice(i, 1);
        return;
      }
    }
  }

  public notifyListeners(eventId:string, eventData:any = {}) {
    for(let i = 0; i < this.listeners.length; i++) 
      if(this.listeners[i].id == eventId) 
        this.listeners[i].callback(eventData);
  }
}
