import React from 'react';
import './PageEnvelope.css';

interface PageEnvelopeProps {
  children: any;
  width?: string;
  color?: string;
  padding?: string;
}

class PageEnvelope extends React.Component<PageEnvelopeProps, {}> {
  render() {
    let style:any = {
      backgroundColor: this.props.color ? this.props.color : 'transparent'
    }

    if(this.props.padding) {
      style.paddingTop = this.props.padding;
      style.paddingBottom = this.props.padding;
    }

    return (
      <div className="page-envelope" style={style}>
        <div className="page-envelope-content" style={{maxWidth: this.props.width ? this.props.width : '900px'}}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default PageEnvelope;

