import React from 'react';
import PageEnvelope from '../elements/PageEnvelope';

class MaintenancePage extends React.Component {
  render() {
    return (
      <div className="site-page">
        <PageEnvelope width="450px">
          <div className="site-page-column" style={{alignItems: 'center'}}>
            <img style={{width: '280px', borderRadius: '10px'}} src="/maintenance.png" />
            <div style={{marginTop: '10px'}}>We will be right back!</div>
          </div>
        </PageEnvelope>
      </div>
    )
  }
}

export default MaintenancePage;

