import { UICanvas } from '../common/ui/uiCanvas';
import { UIElement, UIElementOptions } from '../common/ui/uiElement';
import { UIManager } from '../common/ui/uiManager';
import { UIPoint } from '../common/ui/uiPoint';
import { Game } from './game';

export class SolitairePanel extends UIElement {
  protected gamePosition: UIPoint;
  protected maxGameWidth: number;
  protected gameScale: number;
  protected mouseDown: number;

  constructor(options: UIElementOptions) {
    super(options);
    this.gameScale = 1;
    this.mouseEvents = true;
    this.mouseDown = 0;
    this.maxGameWidth = 500;
  }

  public update() {
    var now = performance.now();
    if(this.lastUpdate == 0)
      this.lastUpdate = now;
    this.deltaTime = now - this.lastUpdate;
    this.lastUpdate = now;

    Game.instance.tick(this.deltaTime);

    this.updateAutoSize();

    this.draw();
  }

  public draw() {
    // UIManager.ctx.fillStyle = '#aa0000';
    // UIManager.ctx.fillRect(this.getScreenX(), this.getScreenY(), this.width, this.height);

    let newWidth = 972 * (this.height / 800);
    //console.log(this.height + '   ' + newWidth);

    this.gameScale = Math.min(newWidth, this.width) / 972;

    this.children.forEach((child) => {
      child.visible = false;
    });

    UIManager.ctx.save();
    UIManager.ctx.scale(this.gameScale, this.gameScale);

    let offsetX = 0;
    if (this.width > newWidth)
      offsetX = (this.width - newWidth) / 2;

    UIManager.ctx.translate((this.getScreenX() + offsetX) / this.gameScale, this.getScreenY() / this.gameScale);

    if(UIManager.isMobile && this.width < this.height) {
      UIManager.ctx.imageSmoothingEnabled = true;
      UIManager.ctx.imageSmoothingQuality = 'high';
    }

    Game.instance.solitaire.drawAll();

    UIManager.ctx.restore();

    this.children.forEach((child) => {
      child.update();
    });
  }

  protected localToGameX(mx: number): number {
    let ox = 0;
    let newWidth = 972 * (this.height / 800);
    if (this.width > newWidth)
      ox = (this.width - newWidth) / 2;
    let gx = (mx - this.getScreenX() - ox) / this.gameScale
    return gx;
  }

  public localToGameY(my: number): number {
    let gy = (my - this.getScreenY()) / this.gameScale
    return gy;
  }

  public onMouseDown(x: number, y: number): void {
    Game.instance.solitaire.onMouseDown(this.localToGameX(x), this.localToGameY(y));
    UICanvas.instance.lockMouse(this);
  }

  public onMouseUp(x: number, y: number): void {
    UICanvas.instance.unlockMouse(this);
    Game.instance.solitaire.onMouseUp(this.localToGameX(x), this.localToGameY(y));
  }

  public onMouseMove(x: number, y: number): void {
    Game.instance.solitaire.onMouseMove(this.localToGameX(x), this.localToGameY(y));
  }
}