import React from 'react';
import { Navigate } from 'react-router-dom';
import { BsExclamationCircleFill, BsEyeSlashFill, BsFillArchiveFill, BsPencilSquare } from 'react-icons/bs';
import { Server } from '../../server/server';
import { formatTimestamp } from '../util/util';
import PageEnvelope from '../elements/PageEnvelope';
import './SitePage.css'
import AlertModal from '../modals/AlertModal';

interface ForumsPageState {
  loading: boolean,
  forums: any[];
  navigate: string;
  alert: string;
}

class ForumsPage extends React.Component<{}, ForumsPageState> {
  protected lastLogin: boolean;

  constructor(props: {}) {
    super(props);

    this.state = {
      loading: false,
      forums: [],
      navigate: '',
      alert: ''
    };

    this.onForumsUpdated = this.onForumsUpdated.bind(this);
  }

  componentDidMount() {
    this.loadForums();
  }

  getProfilesInForums(forums:any[]) {
    let profiles = [];
    for (let i = 0; i < forums.length; i++) {
      let forum = forums[i];
      if(forum.recent && profiles.indexOf(forum.recent.author) == -1)
        profiles.push(forum.recent.author);
    }
    return profiles;
  }

  async loadForums() {
    this.setState({loading: true});

    let forums = [];
    let response = await Server.forums.getForums();
    if(response.success) 
      forums = response.forums;

    if(forums.length > 0) {
      let profiles = this.getProfilesInForums(forums);
      await Server.public.loadProfiles(profiles);
    }

    forums.sort((a:any, b:any)=>{
      if(a.title > b.title)
        return 1;
      else if(a.title < b.title)
        return -1;
      return 0;
    })

    this.setState({forums: forums, loading: false});
  }

  onViewForum(forum:any, vip:boolean) {
    // if(forum.recent && Server.account.isLoggedIn())
    //   Server.forums.setLastReadDate(forum.recent.date);

    if(Server.account.isLoggedIn())
      this.setState({navigate: '/forum/' + forum.id})
    else
      this.setState({alert: 'Please login to view Forums.'});
  }

  onForumsUpdated() {
    this.loadForums();
  }

  onCreate() {
    this.setState({navigate: '/forum/create'})
  }

  onEdit(e:any, forum:any) {
    e.stopPropagation();
    this.setState({navigate: `/forum/${forum.id}/edit`});
  }

  renderForums() {
    if(this.state.loading) 
      return (<div className="site-page-panel">Loading...</div>); 

    if(this.state.forums.length == 0) 
      return (<div className="site-page-panel">There are no forums yet.</div>); 

    let lastReadDate = Server.forums.getLastReadDate();

    let divs = [];
    for (let i = 0; i < this.state.forums.length; i++) {
      let forum = this.state.forums[i];
      let recentUser = forum.recent ? Server.public.getProfile(forum.recent.author) : null;
      let lastPoster = recentUser ? recentUser.name : '';
      let lastPostTime = forum.recent ? formatTimestamp(new Date(forum.recent.date).getTime() / 1000, true) : '';
      let vip = (forum.title.indexOf('VIP') == 0);
      let admin = false; //Server.account.isAdmin();

      let notify = false;
      if(forum.recent && Server.account.isLoggedIn() && forum.title == 'Announcements') {
        let recentPostDate = new Date(forum.recent.date);
        notify = !lastReadDate || recentPostDate > lastReadDate;
      }

      divs.push(
        <div key={i} className="site-page-panel" style={{position: 'relative', cursor: 'pointer'}} onClick={()=>this.onViewForum(forum, vip)}>
            <div className="site-page-column" style={{rowGap: '10px'}}>
              <div className="site-page-row" style={{columnGap: '5px'}}>
                {forum.archived && <BsFillArchiveFill style={{color: 'var(--notification-background-color)', marginBottom: '2px'}} />}
                {forum.hidden && <BsEyeSlashFill style={{color: 'var(--notification-background-color)', marginBottom: '2px'}}/>}
                <div className="site-page-text">{forum.title}</div>
                {notify && <BsExclamationCircleFill style={{color: 'var(--notification-background-color)', fontSize: '0.9em', paddingRight: '5px'}}/>}
              </div>
              <div className="site-page-panel" style={{rowGap: '2px', padding: '5px 10px 5px 10px', backgroundColor: '#00000040', borderRadius: '5px', border: '0px'}}>
                {forum.description}

                {recentUser && 
                  <div className="site-page-subtext">Last post by {lastPoster}&nbsp;&#x2022;&nbsp;{lastPostTime}</div>
                }

                {!recentUser && 
                  <div className="site-page-subtext">No posts yet</div>
                }
              </div>
            </div>

            {admin && 
              <div style={{position: 'absolute', top: '10px', right: '10px'}} onClick={(e:any)=>this.onEdit(e, forum)}>
                <BsPencilSquare size="1.2em"/>
              </div>
            }
        </div>
      );
    }

    return divs;
  }

  render() {
    if(this.state.navigate != '') 
      return <Navigate to={this.state.navigate}  state={{from:'forums'}} />;

    return (
      <div className="site-page">
        <PageEnvelope width="500px">
          <div className="site-page-column">
            {this.renderForums()}
            {/* {Server.account.isLoggedIn() && Server.account.isAdmin() &&
              <div style={{textAlign: 'right'}}><button onClick={()=>this.onCreate()}>Create Forum</button></div>
            } */}
          </div>
        </PageEnvelope>
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
      </div>
    );
  }
}

export default ForumsPage;