import { UIDialog } from "./ui/uiDialog";
import { UIPanel } from "./ui/uiPanel";
import { UIText } from "./ui/uiText";

export class MessageDialog extends UIDialog {
  protected messageText:UIText;
  protected panel: UIPanel;

  public build() {
    this.panel = new UIPanel({
      size: {x: 300, y: 80},
      anchor: {x: 0.5, y: 0.35},
      pivot: {x: 0.5, y: 0.5},
      position: {x: 0, y: 0},
      radius: this.options.panel.radius,
      color: this.options.panel.color,
      gradient: this.options.panel.gradient,
      borderColor: this.options.panel.borderColor,
      borderWidth: this.options.panel.borderWidth,
      shadow: this.options.panel.shadow,
      parent: this
    });

    this.messageText = new UIText({
      text: '',
      color: this.options.message.color,
      fontFamily: this.options.message.fontFamily,
      fontSize: this.options.message.fontSize,
      position: {x: 0, y: 0},
      anchor: {x: 0.5, y: 0.5},
      pivot: {x: 0.5, y: 0.5},
      horizontalAlign: 'center',
      parent: this.panel
    });
  }

  public setMessage(message:string) {
    this.messageText.text = message;
    let lines = message.split('\n');
    this.panel.size.y = 80 + ((lines.length - 1) * (this.options.message.fontSize * 1.2));
  }
}
