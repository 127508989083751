import { getAssetImage } from "../../app/util/assets";
import { Database } from "../../app/util/database";
import { Server } from "../../server/server";
import { UIButton } from "./ui/uiButton";
import { UIDialog } from "./ui/uiDialog";
import { UIImage } from "./ui/uiImage";
import { UIPanel } from "./ui/uiPanel";
import { UIPanelButton } from "./ui/uiPanelButton";
import { UIText } from "./ui/uiText";
import { getNumberSuffix, getTimeString, isLocalHost, numberWithCommas } from "./util";

export class GameOverCommonDialog extends UIDialog {
  protected scoreText: UIText;
  protected bestScoreText: UIText;
  protected continueButton: UIPanelButton;
  protected quitButton: UIPanelButton;
  protected playAgainButton: UIPanelButton;
  protected bestScoreLabel: UIText;
  protected rewardsPanel: UIPanel;
  protected rewardsMessageText: UIText;
  protected rewardAssetImage: UIImage;
  protected rewardAssetNameText: UIText;
  protected rewardAssetDescriptionText: UIText;
  protected chanceToWinText: UIText;
  protected rewardsTitle: UIText;
  protected reward: any;
  protected scoreLabel: UIText;
  protected score: number;
  protected rewardScore: number;
  protected gameId: string;
  protected multiplayer: boolean;
  protected bestScorePanel: UIPanel;

  public init(gameId:string, score:number, rewardScore:number, multiplayer:boolean) {
    this.gameId = gameId;
    this.score = score;
    this.rewardScore = rewardScore;
    this.multiplayer = multiplayer;
  }

  public getReward() {
    return this.reward;
  }

  public build() {
    this.popup = new UIPanel({
      size: {x: 320, y: 430},
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      position: {x: 0, y: 80},
      radius: this.options.panel.radius,
      color: this.options.panel.color,
      gradient: this.options.panel.gradient,
      borderColor: this.options.panel.borderColor,
      borderWidth: this.options.panel.borderWidth,
      shadow: this.options.panel.shadow,
      parent: this
    });

    let titleText = new UIText({
      text: 'GAME OVER!',
      position: {x: 0, y: 10},
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      horizontalAlign: 'center',
      color: this.options.title.color,
      fontFamily: this.options.title.fontFamily,
      fontSize: this.options.title.fontSize,
      fontWeight: this.options.title.fontWeight,
      shadow: this.options.title.shadow,
      parent: this.popup
    });

    let scorePanel = new UIPanel({
      fitParent: {left: 15, right: 15, top: -1, bottom: -1},
      position : {x : 0, y : 50},
      size : {x : 0, y : 40},
      color : this.options.field.panel.color,
      radius : this.options.field.panel.radius,
      parent : this.popup
    });

    this.scoreLabel = new UIText({
      text: 'Score',
      position: {x: 10, y: 0},
      anchor: {x: 0, y: 0.5},
      pivot: {x: 0, y: 0.5},
      horizontalAlign: 'right',
      color : this.options.message.color,
      fontFamily : this.options.message.fontFamily,
      fontSize : this.options.message.fontSize,
      parent : scorePanel
    });

    this.scoreText = new UIText({
      text : '',
      position: {x: -10, y: 0},
      anchor: {x: 1, y: 0.5},
      pivot: {x: 1, y: 0.5},
      horizontalAlign: 'right',
      color : this.options.message.color,
      fontFamily : this.options.message.fontFamily,
      fontSize : this.options.message.fontSize,
      parent : scorePanel
    });

    this.bestScorePanel = new UIPanel({
      fitParent: {left: 15, right: 15, top: -1, bottom: -1},
      position : {x : 0, y : 100},
      size : {x : 0, y : 40},
      color : this.options.field.panel.color,
      radius : this.options.field.panel.radius,
      parent : this.popup
    });

    this.bestScoreLabel = new UIText({
      text: 'Best Today',
      position: {x: 10, y: 0},
      anchor: {x: 0, y: 0.5},
      pivot: {x: 0, y: 0.5},
      horizontalAlign: 'right',
      color : this.options.message.color,
      fontFamily : this.options.message.fontFamily,
      fontSize : this.options.message.fontSize,
      parent : this.bestScorePanel
    });

    this.bestScoreText = new UIText({
      text : '',
      position: {x: -10, y: 0},
      anchor: {x: 1, y: 0.5},
      pivot: {x: 1, y: 0.5},
      horizontalAlign: 'right',
      color : this.options.message.color,
      fontFamily : this.options.message.fontFamily,
      fontSize : this.options.message.fontSize,
      parent : this.bestScorePanel
    });

    this.rewardsTitle = new UIText({
      text: 'Reward',
      position: {x: 0, y: 185},
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      horizontalAlign: 'center',
      color: this.options.title.color,
      fontFamily: this.options.title.fontFamily,
      fontSize: this.options.title.fontSize,
      fontWeight: this.options.title.fontWeight,
      parent: this.popup
    });

    this.rewardsPanel = new UIPanel({
      fitParent: {left: 15, right: 15, top: -1, bottom: -1},
      position: {x: 0, y: 220},
      size: {x: 0, y: 85},
      color : this.options.field.panel.color,
      radius : this.options.field.panel.radius,
      parent: this.popup
    })

    let infoButton = new UIPanelButton({
      panelColors: {normal: this.options.subtext.color},
      textColors: {normal: this.options.panel.color},
      radius: 12,
      fontFamily: 'verdana',
      fontSize: 20,
      text: '?',
      textOffset: {x: 0, y: -2},
      callback : () => {
        this.callback('reward');
      },
      position: {x: 0, y: -32},
      anchor: {x: 1, y: 0},
      pivot: {x: 1, y: 0},
      size : {x : 24, y : 24},
      parent : this.rewardsPanel
    });

    this.rewardsMessageText = new UIText({
      text: 'Loading...',
      fitParent: {left: 10, right: 10, top: 5, bottom: 5},
      wordWrap: true,
      autoWidth: false,
      autoHeight: false,
      color: this.options.message.color,
      fontFamily: this.options.message.fontFamily,
      fontSize: this.options.message.fontSize+1,
      fontWeight: this.options.message.fontWeight,
      horizontalAlign: 'center',
      verticalAlign: 'center',
      parent: this.rewardsPanel
    });

    let rewardAssetPanel = new UIPanel({
      size: {x: 75, y: 75},
      position: {x: 5, y: 5},
      radius: this.options.button.radius-2,
      clipChildren: true,
      parent: this.rewardsPanel
    })

    this.rewardAssetImage = new UIImage ({
      fitParent: {left: 0, right: 0, top: 0, bottom: 0},
      stretch: true,
      parent: rewardAssetPanel
    });

    this.rewardAssetNameText = new UIText({
      text: '',
      position: {x: 90, y: 5},
      color: this.options.message.color,
      fontFamily: this.options.message.fontFamily,
      fontSize: this.options.message.fontSize-2,
      parent: this.rewardsPanel
    });

    this.rewardAssetDescriptionText = new UIText({
      text: '',
      position: {x: 0, y: 25},
      fitParent: {left: 90, right: 10, top: -1, bottom: -1},
      wordWrap: true,
      color: this.options.message.color+'a0',
      fontFamily: this.options.message.fontFamily,
      fontSize: this.options.message.fontSize-4,
      parent: this.rewardsPanel
    });

    this.chanceToWinText = new UIText({
      text: '',
      size: {x: 280, y: 0},
      position: {x: 0, y: 8},
      anchor: {x: 0.5, y: 1},
      pivot: {x: 0.5, y: 0},
      color: this.options.subtext.color,
      fontFamily: this.options.subtext.fontFamily,
      fontSize: this.options.subtext.fontSize,
      autoWidth: false,
      wordWrap: true,
      horizontalAlign: 'center',
      parent: this.rewardsPanel
    });

    this.playAgainButton = new UIPanelButton({
      text : 'Play Again',
      anchor : {x : 0.5, y : 1},
      pivot : {x : 0, y : 1},
      position : {x : 15, y : -20},
      size : {x : 120, y : 40},
      callback : () => {
        this.onPlayAgain();
      },
      panelColors: this.options.button.panelColors,
      textColors: this.options.button.textColors,
      radius: this.options.button.radius,
      fontFamily: this.options.button.fontFamily,
      fontSize: this.options.button.fontSize,
      borderColor: this.options.button.borderColor,
      borderWidth: this.options.button.borderWidth,
      parent : this.popup
    });

    this.quitButton = new UIPanelButton({
      text : 'Quit Game',
      anchor : {x : 0.5, y : 1},
      pivot : {x : 1, y : 1},
      position : {x : -15, y : -20},
      size : {x : 120, y : 40},
      callback : () => {
        this.hide();
        this.callback('quit');
      },
      panelColors: this.options.button.panelColors,
      textColors: this.options.button.textColors,
      radius: this.options.button.radius,
      fontFamily: this.options.button.fontFamily,
      fontSize: this.options.button.fontSize,
      borderColor: this.options.button.borderColor,
      borderWidth: this.options.button.borderWidth,
      parent : this.popup
    });
  }

  public onWake() {
    let game = Database.getGame(this.gameId);

    if(this.multiplayer) {
      this.popup.size.y = 355;
      this.rewardsTitle.position.y = 105;
      this.rewardsPanel.position.y = 140;
      this.bestScorePanel.visible = false;
    }
    else {
      this.popup.size.y = 400;
      this.rewardsTitle.position.y = 150;
      this.rewardsPanel.position.y = 185;
      this.bestScorePanel.visible = true;
    }

    if(game.leaderboardType == 'time') {
      this.scoreLabel.text = 'Time';
      let time = (60*60*1000) - this.score;
      this.scoreText.text = getTimeString(time);
    }
    else {
      this.scoreLabel.text = 'Score';
      this.scoreText.text = numberWithCommas(this.score);
    }

    this.bestScoreText.text = '-';
    this.rewardsMessageText.text = 'Loading...';
    this.rewardAssetImage.visible = false;
    this.rewardAssetNameText.text = '';
    this.rewardAssetDescriptionText.text = '';
    this.rewardsTitle.text = 'Reward';          

    if(this.multiplayer) {
      this.playAgainButton.visible = false;
      this.quitButton.pivot.x = 0.5;
      this.quitButton.position.x = 0;
      this.quitButton.text = 'Return';
    }
    else {
      this.playAgainButton.visible = true;
      this.quitButton.pivot.x = 1;
      this.quitButton.position.x = -15;
      this.quitButton.text = 'Quit Game';
    }

    this.bestScoreLabel.text = `Best${game.leaderboard ? ' Today' : ''}`;

    this.recordScore();
  }

  public async submitScore(score:number, reward:number, place:boolean, callback:Function) {
    if(isLocalHost())
      score = 0;

    let response = await Server.games.submitScore({game: this.gameId, score, reward, place});

    if(response.success) {
      if(response.assets) 
        for(let i = 0; i < response.assets.length; i++)
          Server.assets.addAssetToInventory(response.assets[i].id, response.assets[i].quantity);
  
      await Server.user.incrementCounter('game#' + this.gameId + '#finish', 1);
  
      if(response.reward && response.reward.asset)
        await Server.user.incrementCounter('reward#' + response.reward.asset);

      if(response.stats)
        Server.user.setLifetimeGameStats(this.gameId, response.stats);
    }

    if(response.success) 
      callback(true, response);
    else 
      callback(false);
  }

  protected setRewardElements(assetId:string, quantity:number, image:UIImage, nameText:UIText, descriptionText:UIText) {
    let asset = Database.getAsset(assetId);

    image.visible = true;
    image.url = getAssetImage(asset);

    if(asset.collection == 'banners') {
      image.fitParent = {left: -1, right: -1, top: 0, bottom: 0};
      image.fitAspect = {x: 1024/273, y: 0};
      image.anchor = {x: 0.5, y: 0};
      image.pivot = {x: 0.5, y: 0};
    }
    else {
      image.fitParent = {left: 0, right: 0, top: 0, bottom: 0};
      image.fitAspect = {x: 0, y: 0};
      image.anchor = {x: 0, y: 0};
      image.pivot = {x: 0, y: 0};
    }

    let collection = Database.getCollection(asset.collection);

    if(asset.id == 'coin')
      nameText.text = `${quantity} Coins`;
    else if(asset.name && asset.name != '')
      nameText.text = asset.name;
    else
      nameText.text = collection.noun.singular;

    if(asset.description) {
      descriptionText.text = asset.description;
    }
    else {
      if(collection) {
        if(collection.bonus)
          descriptionText.text = `Collect all ${collection.count} ${collection.noun.plural} to complete the collection!`;
        else
          descriptionText.text = collection.description;
      }
    }
  }

  protected setChanceToWinText(reward:any, chanceText:UIText) {
    chanceText.text = '';
    if(!reward) return;
    
    if(reward.assets) {
      let tier = Math.min(1, Math.floor(reward.chances.tier / 100));
      let tierChance = Math.min(100, reward.chances.tier - (tier * 100));

      if(reward.chances.tier == 0)
        chanceText.text = `Create boosters to improve your chances at a higher tier reward.`;
      else
      chanceText.text = `Your boosters gave the reward a ${tierChance}% chance at coming from Tier ${tier+2}.`;
    }
    else if(Database.getRewardType(this.gameId) == 'level') {
      if(reward.chances.win == 0) {
        let level = Database.getMinRewardScore(this.gameId);
        let chance = Database.getBaseRewardChance();
        chanceText.text = `Reach level ${level} for a ${chance}% chance\nto win a reward.`;
      }
      else
        chanceText.text = `Reaching level ${reward.score} gave you a ${reward.chances.win}% chance to win a reward.`;
    }
    else if(Database.getRewardType(this.gameId) == 'time') {
      if(reward.chances.win == 0) {
        let time = (60*60*1000) - Database.getMinRewardScore(this.gameId);
        let chance = Database.getBaseRewardChance();
        chanceText.text = `Finish in ${getTimeString(time)} for a ${chance}% chance\nto win a reward.`;
      }
      else {
        let time = (60*60*1000) - reward.score;
        chanceText.text = `Finishing with a time of ${getTimeString(time)} gave you a ${reward.chances.win}% chance to win a reward.`;
      }
    }
    else if(reward.chances) {
      if(reward.chances.win == 0) {
        let chance = Database.getBaseRewardChance();
        let score = Database.getMinRewardScore(this.gameId);
        chanceText.text = `Score at least ${numberWithCommas(score)} points for a ${chance}% chance to win a reward.`;
      }
      else
        chanceText.text = `Your score gave you a ${reward.chances.win}% chance\nto win a reward.`;
    }
  }

  public disableButtons(d:boolean) {
    this.playAgainButton.disabled = d;
  }

  protected async recordScore() {
    this.playAgainButton.disabled = true;
    this.rewardAssetImage.visible = false;
    this.rewardAssetNameText.visible = false;
    this.rewardAssetDescriptionText.visible = false;
    this.rewardsMessageText.text = 'Checking for reward...';
    this.chanceToWinText.text = 'Calculating chances...'
    this.reward = null;

    let game = Database.getGame(this.gameId);
    let score = (game.leaderboard && !this.multiplayer) ? this.score : 0;
    let place = (game.leaderboard && !this.multiplayer);

    this.submitScore(score, this.rewardScore, place, (success:boolean, results:any)=>{
      this.playAgainButton.disabled = false;
      this.chanceToWinText.text = '';

      if(success) {
        if(game.leaderboard && !this.multiplayer) 
          this.setBestScore(results.best, results.high, results.place);

        this.rewardsMessageText.text = '';
        this.chanceToWinText.text = '';
        this.rewardsTitle.text = 'Reward'
        this.reward = results.reward;

        if(!this.reward) {
          this.callback('success');
          return;
        }

        this.reward.score = this.rewardScore;
        this.reward.game = this.gameId;

        // add reward assets to local inventory cache
        if(this.reward.assets) 
          for(let i = 0; i < this.reward.assets.length; i++) 
            Server.assets.addAssetToInventory(this.reward.assets[i].id, this.reward.assets[i].quantity);
        
        // rewards title
        if(this.reward.asset)
          this.rewardsTitle.text = `Tier ${this.reward.tier+1} Reward`;          

        // reward panel
        if(this.reward.assets) {
          this.rewardAssetImage.visible = true;
          this.rewardAssetNameText.visible = true;
          this.rewardAssetDescriptionText.visible = true;

          this.setRewardElements(
            this.reward.assets[0].id, 
            this.reward.assets[0].quantity, 
            this.rewardAssetImage, 
            this.rewardAssetNameText, 
            this.rewardAssetDescriptionText
          )
        }
        else {
          this.rewardsMessageText.text = 'You did not win a reward.';
        }

        // chance to win
        this.setChanceToWinText(this.reward, this.chanceToWinText);

        this.callback('success');
      }
      else {
        this.bestScoreText.text = 'ERR';
        this.rewardsMessageText.text = 'Failed to submit score!';
        this.callback('error');
      }
    });
  }

  public setBestScore(score:number, high:boolean, place:number = -1) {
    let game = Database.getGame(this.gameId);

    this.bestScoreLabel.text = `Best${game.leaderboard ? ' Today' : ''}`;

    if(place != -1) {
      let suffix = getNumberSuffix(place)
      this.bestScoreLabel.text += ` (${place}${suffix})`;
    }

    if(game.leaderboardType == 'time') {
      let time = (60*60*1000) - score;
      this.bestScoreText.text = getTimeString(time);
    }
    else 
      this.bestScoreText.text = numberWithCommas(score);
  }

  protected onPlayAgain() {
    this.hide();
    this.callback('restart');
  }
}