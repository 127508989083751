/**
 * Sprite Sheet manager.
 *
 * Kevin Ryan
 */

import { UIManager } from "./ui/uiManager";
import { UIPoint } from "./ui/uiPoint";

export interface UvInfo {
  uv0: UIPoint;
  uv1: UIPoint;
}

interface imageInfo {
  x: number;
  y: number;
  w: number;
  h: number;
}

export class SsImage {
  public ii: imageInfo;
  public uvInfo: UvInfo;

  constructor(public ss: SpriteSheet, frame: any) {
    this.ii = {x : frame.x, y : frame.y, w : frame.w, h : frame.h};

    let width = ss.data.meta.size.w;
    let height = ss.data.meta.size.h;
    let x0 = frame.x + 0.5;
    let y0 = height - frame.y - 0.5;
    let x1 = frame.x + frame.w - 0.5;
    let y1 = height - (frame.y + frame.h) + 0.5;
    let uv0 = new UIPoint(x0 / width, y0 / height);
    let uv1 = new UIPoint(x1 / width, y1 / height);
    this.uvInfo = {uv0, uv1};
  }

  /**
   * Draws this image on the screen.
   * @param x - Screen x location
   * @param y - Screen y location
   * @param w - Width to draw
   * @param h - Height to draw
   */
  draw(x: number, y: number, w: number, h: number) {
    UIManager.ctx.drawImage(this.ss.image, this.ii.x, this.ii.y, this.ii.w, this.ii.h, x, y, w, h);
  }

  drawToCtx(ctx: CanvasRenderingContext2D, x: number, y: number, w: number, h: number) {
    ctx.drawImage(this.ss.image, this.ii.x, this.ii.y, this.ii.w, this.ii.h, x, y, w, h);
  }
}

export class SpriteSheet {
  public image: HTMLImageElement;
  public data: any;
  public materialIndex: number;
  public imageReady: boolean;
  public jsonReady: boolean;
  public failed: boolean;

  public ssImages: SsImage[];

  constructor(public handle: number, public fileName: String) {
    this.image = new Image();
    this.data = null;
    this.materialIndex = -1;
    this.imageReady = false;
    this.jsonReady = false;
    this.failed = false;

    this.ssImages = [];
  }

  /**
   * Called once a sprite sheet is completely loaded. Sets up the info on all
   * the images within the sprite sheet.
   */
  loaded() {
    for (let i = 0; i < this.data.frames.length; i++) {
      let ssImage = new SsImage(this, this.data.frames[i].frame);
      this.ssImages.push(ssImage);
    }
  }

  /**
   * Returns the SsImage for the passed in file name. Defaults to 'missing.png' if the file
   * can't be found in the sprite sheet.
   * @param filePathName - File to find.
   */
  getSsImage(filePathName: String): SsImage {
    let fileName = filePathName.split('/');
    let fn = fileName[fileName.length - 1];

    let frames = this.data.frames;
    for (let i = 0; i < frames.length; i++) {
      if (fn === frames[i].filename) {
        return this.ssImages[i];
      }
    }

    // Bitmap doesn't exist so use the missing bitmap
    if (fn !== 'missing.png') {
      return this.getSsImage('missing.png');
    }

    return null;
  }

  /**
   * Returns the frames array index for the passed in file name. This has the info about the size
   * and location of the single bitmap within the sprite sheet.
   * @param fileName - Filename to find.
   */
  getFrame(fileName: String): number {
    let frames = this.data.frames;
    for (let i = 0; i < frames.length; i++) {
      if (fileName === frames[i].filename) {
        return i;
      }
    }

    return -1;
  }

  /**
   * Returns the uv value for the passed in file name. This is used for texturing on
   * threejs shapes.
   * @param fileName - File name to find.
   */
  getUv(fileName: String): UvInfo {
    let index = this.getFrame(fileName);
    if (index !== -1) {
      return this.ssImages[index].uvInfo;
    }

    // Bitmap doesn't exist so use the missing bitmap
    if (fileName !== 'missing.jpg') {
      return this.getUv('missing.jpg');
    }

    // Failsafe - returns the whole image
    let uv0 = new UIPoint(0, 1);
    let uv1 = new UIPoint(1, 0);
    return {uv0, uv1};
  }
}

export class SpriteSheetManager {
  public spriteSheets: SpriteSheet[];

  constructor() {
    this.load = this.load.bind(this);
    this.spriteSheets = [];
  }

  /**
   * Loads the passed in sprite sheet.
   * @param filePathName - File to load
   * @param callback - Function to call after file load succeeds or fails.
   */
  load(imagePath: string, json: any, callback: Function, scale:number=1): SpriteSheet {
    // Only want the file name - not the whole path
    let fileName = imagePath.split('/');

    // Create this sprite sheet
    let ss = new SpriteSheet(this.spriteSheets.length, `${fileName[fileName.length - 1]}`)

    ss.jsonReady = true;
    ss.data = JSON.parse(JSON.stringify(json));

    // Listen for when it is loaded
    ss.image.addEventListener('load', () => {
      ss.imageReady = true;
      if (callback !== null && ss.jsonReady) {
        // If the JSON is ready then we are done - do the callback
        ss.loaded();
        callback(ss);
      }
    });

    ss.image.addEventListener('error', function() {
      ss.imageReady = true;
      if (!ss.failed) {
        ss.failed = true;
        if (callback !== null) {
          callback(ss);
        }
      }
    });

    ss.image.src = imagePath;
    this.spriteSheets.push(ss);

    if(scale != 1) {
      for(let i = 0; i < ss.data.frames.length; i++) {
        let frame = ss.data.frames[i];
        frame.frame.x *= scale;
        frame.frame.y *= scale;
        frame.frame.w *= scale;
        frame.frame.h *= scale;
        frame.sourceSize.w *= scale;
        frame.sourceSize.h *= scale;
        frame.spriteSourceSize.x *= scale;
        frame.spriteSourceSize.y *= scale;
        frame.spriteSourceSize.w *= scale;
        frame.spriteSourceSize.h *= scale;
      }
    }

    return ss;
  }

  /**
   * Returns sprite sheet that has the passed in handle.
   * @param handle - Handle of sprite sheet to return.
   */
  getSpriteSheetByHandle(handle: number): SpriteSheet {
    if (handle < this.spriteSheets.length) {
      return this.spriteSheets[handle];
    }

    return null;
  }

  /**
   * Returns sprite sheet that has the passed in file name.
   * @param fileName - File name to find.
   */
  getSpriteSheetByFileName(fileName: String): SpriteSheet {
    for (let i = 0; i < this.spriteSheets.length; i++)
      if (fileName === this.spriteSheets[i].fileName) {
        return this.spriteSheets[i];
      }

    return null;
  }
}