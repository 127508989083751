import { Server } from './server';
import { Service, ServiceResponse } from './service';

export class ActivityService extends Service {
  protected posts: any[];
  protected filter: string;

  constructor() {
    super();
    this.posts = [];
    this.filter = '';
  }

  public async sync(): Promise<ServiceResponse> {
    await this.updatePosts();
    return {success: true};
  }

  public async checkPosts() {
    if(!Server.account.isLoggedIn())
      return;

    let params:any = null;

    if(this.filter != '' || this.posts.length > 0) {
      params = {};
      if(this.filter != '')
        params.filter = this.filter;
      if(this.posts.length > 0)
        params.after = {date: this.posts[0].date, id: this.posts[0].id};
    }
  
    let response = await this.getPosts(params);
    if(response.success && response.posts.length > 0) {
      for(let i = response.posts.length-1; i >= 0; i--) 
        this.posts.unshift(response.posts[i]);
      this.notifyListeners('activity-updated');
    }
  }

  public async updatePosts() {
    let params:any = null;
    if(this.filter != '') 
      params = {filter: this.filter};

    let response = await this.getPosts(params);

    if(response.success) 
      this.posts = response.posts;
  }

  public getPostsFromCache() {
    return this.posts;
  }

  public newPostsFromCache(): boolean {
    if(this.posts.length == 0)
      return false;

    let lastActivityReadCookie = Server.user.getCookie('LastActivityRead');
    let lastActivityRead = lastActivityReadCookie ? new Date(lastActivityReadCookie) : null;
    let recentPostDate = new Date(this.posts[0].date);

    return (recentPostDate > lastActivityRead);
  }

  public markActivityAsRead(date?:string) {
    if(!date && this.posts.length == 0)
      return;

    let checkDateValue = date ? date : this.posts[0].date;

    let lastActivityReadCookie = Server.user.getCookie('LastActivityRead');

    if(lastActivityReadCookie) {
      let checkDate = new Date(checkDateValue);
      let lastActivityRead = new Date(lastActivityReadCookie);
      if(checkDate <= lastActivityRead)
        return;
    }

    Server.user.setCookie({key: 'LastActivityRead', value: checkDateValue});
  }

  public updatePostInCache(post:any) {
    for(let i = 0; i < this.posts.length; i++) {
      if(this.posts[i].id == post.id) 
        this.posts[i] = post;
      else if(this.posts[i].repost && this.posts[i].repost.id == post.id) 
        this.posts[i].repost = post;
    }
  }

  public deletePostFromCache(id:string) {
    for(let i = 0; i < this.posts.length; i++) {
        if(this.posts[i].id == id) {
          this.posts.splice(i, 1);
          return;
        }
    }
  }

  public likeCachedPost(id:string, like:boolean) {
    for(let i = 0; i < this.posts.length; i++) {
      if(this.posts[i].id == id) {
        this.posts[i].likes += like ? 1 : -1;
        this.posts[i].liked = like;
      }

      if(this.posts[i].repost && this.posts[i].repost.id == id) {
        this.posts[i].repost.likes += like ? 1 : -1;
        this.posts[i].repost.liked = like;
      }
    }
  }

  public repostCachedPost(id:string, repost:boolean) {
    for(let i = 0; i < this.posts.length; i++) {
      if(this.posts[i].id == id) {
        this.posts[i].reposts += repost ? 1 : -1;
        this.posts[i].reposted = repost;
      }

      if(this.posts[i].repost && this.posts[i].repost.id == id) {
        this.posts[i].repost.reposts += repost ? 1 : -1;
        this.posts[i].repost.reposted = repost;
      }
    }

    if(!repost) {
      for(let i = 0; i < this.posts.length; i++) {
        if(this.posts[i].repost && this.posts[i].repost.id == id && this.posts[i].author == Server.user.getId()) {
          this.posts.splice(i, 1);
          i--;
        }
      }
    }
  }

  public async loadMore() {
    let n = this.posts.length-1;
    
    let params:any = {
      before: {date: this.posts[n].date, id: this.posts[n].id},
    };

    if(this.filter != '')
      params.filter = this.filter;

    let response = await this.sendActivityCommand('get-posts', params, ['posts']);
    
    if(response.success) 
      for(let i = 0; i < response.posts.length; i++)
        this.posts.push(response.posts[i]);
  }

  public setFilter(filter:string) {
    if(filter == 'community')
      this.filter = '';
    else
      this.filter = filter;
    
    window.localStorage.setItem('ActivityFilter', this.filter);
  }

  public getFilter() {
    return this.filter;
  }


  // helpers
  protected async sendActivityCommand(command:string, params:any, data?:string[]) {
    let response = await this.sendCommand('activity', command, params);
    if(response.status != 200)
      return {success: false, message: response.body.message};
    let ret:any = {success: true};
    if(data) {
      for(let i = 0; i < data.length; i++)
        ret[data[i]] = response.body[data[i]];
    }
    return ret;
  }
  
  
  // posts
  public async createPost(content:string) {
    return await this.sendActivityCommand('create-post', {content}, ['id', 'date']);
  }

  public async editPost(id:string, date:string, content:string) {
    return await this.sendActivityCommand('edit-post', {id, date, content});
  }

  public async deletePost(id:string) {
    return await this.sendActivityCommand('delete-post', {id});
  }

  public async repost(id:string) {
    return await this.sendActivityCommand('repost', {id}, ['id', 'date']);
  }

  public async undoRepost(id:string) {
    return await this.sendActivityCommand('undo-repost', {id});
  }

  public async likePost(id:string) {
    return await this.sendActivityCommand('like-post', {id});
  }

  public async unlikePost(id:string) {
    return await this.sendActivityCommand('unlike-post', {id});
  }

  public async getPost(id:string, sort:boolean, more:any) {
    if(Server.account.isLoggedIn()) {
      let params:any = {id, sort};
      if(more) params.more = more;
      return await this.sendActivityCommand('get-post', params, ['post', 'more']);
    }
    else {
      let response = await this.sendCommand('public', 'get-activity-post', {id});
      if(response.status != 200)
        return {success: false, message: response.body.message};
      return {success: true, post: response.body.post}
    }
  }

  public async getPosts(params:{author?:string, filter?:string, before?:any, after?:any} = {}) {
    if(Server.account.isLoggedIn()) {
      return await this.sendActivityCommand('get-posts', params, ['posts']);
    }
    else {
      let response = await this.sendCommand('public', 'get-activity-posts', params);
      if(response.status != 200)
        return {success: false, message: response.body.message};
      return {success: true, posts: response.body.posts}
    }
  }

  public async createReply(params:{id:string, content:string}) {
    return await this.sendActivityCommand('create-reply', params, ['date']);
  }

  public async deleteReply(params:{id:string, date:string}) {
    return await this.sendActivityCommand('delete-reply', params);
  }

  public async editReply(params:{id:string, date:string, content:string}) {
    return await this.sendActivityCommand('edit-reply', params);
  }
}