import { Database } from '../app/util/database';
import { Service, ServiceResponse } from './service';

export class ShopService extends Service {
  protected assets:any[];

  constructor() {
    super();
    this.assets = [];
  }

  public async sync(): Promise<ServiceResponse> {
    let params = null;
    let version = window.localStorage.getItem('ShopDatabaseVersion');
    if(version)
      params = {version};
    
    let response = await this.sendCommand('public', 'get-shop', params);
    if(response.status != 200)
      return {success: false};

    if(!response.body.assets) {
      let assets = window.localStorage.getItem('ShopDatabase');
      this.assets = JSON.parse(assets);
      return {success: true}
    }

    this.assets = response.body.assets;

    for(let i = 0; i < this.assets.length; i++) {
      let shopAsset = this.assets[i];
      let asset = Database.getAsset(shopAsset.id);
      this.assets[i] = { ...shopAsset, ...asset };
      if(!this.assets[i].currency)
        this.assets[i].currency = 'coin';
      if(!this.assets[i].stock)
        this.assets[i].stock = -1;
    }

    window.localStorage.setItem('ShopDatabaseVersion', response.body.version)
    window.localStorage.setItem('ShopDatabase', JSON.stringify(this.assets))

    return {success: true};
  }

  public getAssets(): any[] {
    return this.assets;
  }

  public getAsset(id:string): any {
    return this.assets.find(a => a.id == id);
  }

  protected sortAssets(assets:any[]) {
    assets.sort((a, b)=>{
      if(a.price > b.price)
        return 1;
      else if(a.price < b.price)
        return -1;

      if(a.type > b.type)
        return 1;
      else if(a.type < b.type)
        return -1;

      if(a.name > b.name)
        return 1;
      else if(a.name < b.name)
        return -1;

      return 0;
    });
  }

  public getAssetsByCategory(category:string): any[] {
    let assets = [];
    for(let i = 0; i < this.assets.length; i++) 
      if(this.assets[i].category == category || category == 'all')
        assets.push(this.assets[i]);

    this.sortAssets(assets);
    return assets;
  }

  public getAssetsByTypeAndCategory(type:string, category:string): any[] {
    let assets = [];
    for(let i = 0; i < this.assets.length; i++) 
      if(this.assets[i].type == type || type == 'all')
        if(this.assets[i].category == category || category == 'all')
          assets.push(this.assets[i]);

    this.sortAssets(assets);
    return assets;
  }

  // admin
  public async addAsset(params:any) {
    let response = await this.sendCommand('shop', 'add-asset', params);

    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true};
  }

  public async removeAsset(params:any) {
    let response = await this.sendCommand('shop', 'remove-asset', params);

    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true};
  }

}
