import React from 'react';
import { Navigate } from 'react-router-dom';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import '../pages/SitePage.css';

interface PageHeaderProps {
  navigate: string;
  text: string;
  children?: any;
  force?: boolean;
}

interface PageHeaderState {
  navigate: string;
}

class PageHeader extends React.Component<PageHeaderProps, PageHeaderState> {
  constructor(props: PageHeaderProps) {
    super(props);
    this.state = {
      navigate: ''
    };
  }
  
  onBack() {
    if(this.props.force || (window.history.state && window.history.state.idx == 0))
      this.setState({navigate: this.props.navigate});
    else
      window.history.back();
  }

  render() {
    if(this.state.navigate != '') 
      return <Navigate to={this.state.navigate} />;

    return (
      <div className="site-page-header">
        <div className="site-page-row">
          <div className="site-page-row">
            <div style={{paddingTop: '3px', cursor: 'pointer'}} onClick={()=>this.onBack()}>
              <BsFillArrowLeftCircleFill size={22} />
            </div>
            <div>{this.props.text}</div>
          </div>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default PageHeader;