import React from 'react';
import { BsChatDotsFill, BsFillArrowLeftCircleFill, BsFillGearFill, BsFillTrashFill, BsFillXCircleFill, BsPencilSquare, BsPeopleFill, BsRecycle } from 'react-icons/bs';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import { Server } from '../../server/server';
import PageEnvelope from '../elements/PageEnvelope';
import Portrait from '../elements/Portrait';
import MessageModal from '../modals/MessageModal';
import AlertModal from '../modals/AlertModal';
import QuestionModal from '../modals/QuestionModal';
import ContentDiv from '../elements/ContentDiv';
import { getBannerImage } from '../util/assets';
import PostPanel from '../elements/PostPanel';
import { Navigate } from 'react-router-dom';
import { WithRouterProps } from '../util/withRouter';
import { Database } from '../util/database';
import './SitePage.css'
import './PlacePage.css'
import { formatTime, numberWithCommas } from '../util/util';

interface PlacePageProps {
  router: WithRouterProps;
}

interface PlacePageState {
  id: string;
  loading: boolean;
  message: string;
  alert: string;
  question: string;
  questionYes: Function;
  navigate: string;
  mobile: boolean;
  streamChannel: string;
  game: string;
  showMembers: boolean;
  showSettings: boolean;
  showSelectGame: boolean;
  showStreamChannel: boolean;
  showFinishGame: boolean;
  watchStream: boolean;
  gameCountdown: boolean;
  playingGame: boolean;
  timeLeft: number;
  joining: boolean;
  error: string;
  name: string;
  description: string;
  banner: string;
  place: any;
  chat: any[];
  leaderboard: any[];
  results: any[],
  medals: any[]
}

class PlacePage extends React.Component<PlacePageProps, PlacePageState> {
  protected messagesDiv: React.RefObject<HTMLDivElement>;
  protected lastIsMobile: boolean;
  protected startGameTimer: any;

  constructor(props:PlacePageProps) {
    super(props);
    this.state = {
      id: '',
      loading: false,
      message: '',
      alert: '',
      question: '',
      questionYes: null,
      navigate: '',
      mobile: false,
      streamChannel: '',
      game: '',
      showMembers: true,
      showSettings: false,
      showSelectGame: false,
      showStreamChannel: false,
      showFinishGame: false,
      watchStream: false,
      gameCountdown: false,
      playingGame: false,
      timeLeft: 0,
      joining: false,
      error: '',
      name: '',
      description: '',
      banner: '',
      place: null,
      chat: [],
      leaderboard: [],
      results: [],
      medals: []
    }

    this.messagesDiv = React.createRef();
    this.onResize = this.onResize.bind(this);
    this.lastIsMobile = false;
    this.startGameTimer = null;

    this.onPlaceInfo = this.onPlaceInfo.bind(this);
    this.onPlaceChat = this.onPlaceChat.bind(this);
    this.onPlaceChatMessage = this.onPlaceChatMessage.bind(this);
    this.onPlaceState = this.onPlaceState.bind(this);
    this.onPlaceUserState = this.onPlaceUserState.bind(this);
    this.onPlaceLaunchGame = this.onPlaceLaunchGame.bind(this);
    this.onPlaceFinishGame = this.onPlaceFinishGame.bind(this);
    this.onPlaceLeaderboard = this.onPlaceLeaderboard.bind(this);
    this.onPlaceScore = this.onPlaceScore.bind(this);
    this.onPlaceMedals = this.onPlaceMedals.bind(this);
    this.onPlaceInit = this.onPlaceInit.bind(this);
    this.onFriendUpdated = this.onFriendUpdated.bind(this);
    this.onCloneEnterPlace = this.onCloneEnterPlace.bind(this);
  }

  componentDidMount(): void {
    this.lastIsMobile = this.isMobile();
    this.setState({mobile: this.isMobile()});

    window.addEventListener("resize", this.onResize);
    Server.places.addEventListener('place-info', this.onPlaceInfo);
    Server.places.addEventListener('place-chat', this.onPlaceChat);
    Server.places.addEventListener('place-chat-message', this.onPlaceChatMessage);
    Server.places.addEventListener('place-state', this.onPlaceState);
    Server.places.addEventListener('place-user-state', this.onPlaceUserState);
    Server.places.addEventListener('place-leaderboard', this.onPlaceLeaderboard);
    Server.places.addEventListener('place-score', this.onPlaceScore);
    Server.places.addEventListener('place-medals', this.onPlaceMedals);
    Server.places.addEventListener('place-init', this.onPlaceInit);
    Server.network.addEventListener('place-launch-game', this.onPlaceLaunchGame);
    Server.network.addEventListener('place-finish-game', this.onPlaceFinishGame);
    Server.network.addEventListener('clone-enter-place', this.onCloneEnterPlace);
    Server.user.addEventListener('friend-updated', this.onFriendUpdated);

    this.setState({chat: []});

    clearInterval(this.startGameTimer);

    if(Server.account.isLoggedIn())
      this.loadPage();
  }

  componentDidUpdate(prevProps: Readonly<PlacePageProps>, prevState: Readonly<PlacePageState>): void {
    if(prevState.chat.length != this.state.chat.length && this.messagesDiv && this.messagesDiv.current)
      this.messagesDiv.current.scrollTop = this.messagesDiv.current.scrollHeight;

    if(prevState.place && this.state.place && prevState.place.host != this.state.place.host)
      this.loadPage();
  }

  componentWillUnmount() {
    clearInterval(this.startGameTimer);

    if(this.state.place) {
      let item = window.sessionStorage.getItem('PlayingGame');
      if(!item)
        Server.places.leavePlace(this.state.place.host);
    }

    window.removeEventListener("resize", this.onResize);
    Server.user.removeEventListener('friend-updated', this.onFriendUpdated);
    Server.places.removeEventListener('place-info', this.onPlaceInfo);
    Server.places.removeEventListener('place-chat', this.onPlaceChat);
    Server.places.removeEventListener('place-chat-message', this.onPlaceChatMessage);
    Server.places.removeEventListener('place-state', this.onPlaceState);
    Server.places.removeEventListener('place-user-state', this.onPlaceUserState);
    Server.places.removeEventListener('place-leaderboard', this.onPlaceLeaderboard);
    Server.places.removeEventListener('place-score', this.onPlaceScore);
    Server.places.removeEventListener('place-medals', this.onPlaceMedals);
    Server.places.removeEventListener('place-init', this.onPlaceInit);
    Server.network.removeEventListener('place-launch-game', this.onPlaceLaunchGame);
    Server.network.removeEventListener('place-finish-game', this.onPlaceFinishGame);
  }

  isUserTheHost() {
    if(!this.state.place) return false;
    return (this.state.place.host == Server.user.getId());
  }

  isUserInPlace() {
    if(!this.state.place) return false;

    for(let i = 0; i < this.state.place.users.length; i++)
      if(this.state.place.users[i].id == Server.user.getId())
        return true;
    
    return false;
  }

  isMobile() {
    return window.matchMedia('(max-width: 500px)').matches;
  }

  async loadPage() {
    let parts = window.location.pathname.split('/');
    if(parts.length < 3)
      return;

    let slug = parts[2];

    if(slug == Server.user.getSlug()) {
      Server.places.joinPlace(Server.user.getId());
      Server.network.sendEvent('clones', {id: 'clone-enter-place'});
      return;
    }

    this.setState({joining: true});

    let response = await Server.public.loadProfileFromSlug(slug);

    if(!response.success) {
      this.setState({error: response.message, joining: false});
      return;
    }

    let profile = response.profile;
    let friend = Server.user.getFriend(profile.id);

    if(!friend) {
      this.setState({error: 'You can only visit friends.', joining: false});
      return;
    }

    if(!friend.presence || !friend.presence.online) {
      this.setState({error: 'You can only visit your friends when they are online.', joining: false});
      return;
    }

    Server.places.joinPlace(profile.id);
  }

  async loadProfilesFromInfoAndChat(info:any, chat:any[]) {
    let ids = [info.host];

    for(let i = 0; i < info.users.length; i++)
      ids.push(info.users[i].id);

    for(let i = 0; i < chat.length; i++)
      if(ids.indexOf(chat[i].user) == -1)
        ids.push(chat[i].user);

    await Server.public.loadProfiles(ids);
  }

  async onPlaceInit(data:any) {
    await this.loadProfilesFromInfoAndChat(data.info, data.chat);

    this.setState({
      place: JSON.parse(JSON.stringify(data.info)),
      chat: JSON.parse(JSON.stringify(data.chat)),
      leaderboard: JSON.parse(JSON.stringify(data.leaderboard)),
      medals: JSON.parse(JSON.stringify(data.medals)),
      joining: false
    });

    this.updateState(data.info.state);
  }

  async onPlaceInfo(info:any) {
    if(this.state.place && info.host != this.state.place.host)
      return;

    let oldState = this.state.place ? this.state.place.state : 'lobby';

    await this.loadProfilesFromInfoAndChat(info, []);

    if(this.state.place.users.length < info.users.length)
      console.warn('place#enter');

    let place = JSON.parse(JSON.stringify(info));
    this.setState({place});

    if(info.state != oldState)
      this.updateState(info.state);
  }

  onPlaceState(state:string) {
    if(!this.state.place || this.state.place.state == state)
      return;

    let place = {...this.state.place};
    place.state = state;
    this.setState({place});

    this.updateState(state);
  }

  onPlaceUserState(user:any) {
    if(!this.state.place || !this.state.place.users)
      return;

    let place = {...this.state.place};

    for(let i = 0; i < place.users.length; i++)
      if(place.users[i].id == user.id)
        place.users[i].state = user.state;

    this.setState({place});
  }

  onPlaceLeaderboard(leaderboard:any[]) {
    let lb = [...leaderboard];
    this.setState({leaderboard: lb});
  }

  onPlaceScore(user:any) {
    let lb = [...this.state.leaderboard];
    for(let i = 0; i < lb.length; i++)
      if(lb[i].id == user.id)
        lb[i].score = user.score;

    this.setState({leaderboard: lb});
  }

  onPlaceMedals(medals:any[]) {
    let m = [...medals];
    this.setState({medals: m});
  }

  updateState(state:string) {
    clearInterval(this.startGameTimer);

    let parts = state.split(':');

    if(parts[0] == 'lobby') {
      if(this.state.watchStream)
        this.setState({watchStream: false});
      if(this.state.gameCountdown)
        this.setState({gameCountdown: false});
      if(this.state.playingGame)
        this.setState({playingGame: false});
    }
    else if(parts[0] == 'watch') {
      if(!this.state.watchStream)
        this.setState({watchStream: true, streamChannel: parts[1]});
    } 
    else if(parts[0] == 'countdown') {
      let gameId = parts[1];
      let duration = +parts[2];
      let startDate = new Date();
      startDate.setTime(+parts[3]);
      let now = new Date();
      let diff = now.getTime() - startDate.getTime();
      duration = Math.round(duration - (diff/1000));
      this.setState({gameCountdown: true, timeLeft: duration, game: gameId});
      this.startGameCountdown(gameId, duration);
    }
    else if(parts[0] == 'game') {
      let gameId = parts[1];
      let startDate = new Date();
      startDate.setTime(+parts[2]);
      let now = new Date();
      let elapsed = Math.round((now.getTime() - startDate.getTime())/1000);
      this.setState({gameCountdown: false, playingGame: true, timeLeft: elapsed, game: gameId});
      this.startGameTimer = setInterval(()=>{
        this.setState({timeLeft: this.state.timeLeft + 1});
      }, 1000)
    }

  }

  onFriendUpdated(data:any) {
    if(this.isUserTheHost()) {
      let user = Server.places.getUser(data.friend);
      if(user && data.action == 'disconnect')
        Server.places.onUserLeave({user: data.friend})
    }
    else {
      if(this.state.place.host == data.friend) {
        if(data.action == 'connect')
          this.loadPage();
        else
          this.forceUpdate();
      }
    }
  }

  getLeaderboardScore(user:string) {
    for(let i = 0; i < this.state.leaderboard.length; i++) 
      if(this.state.leaderboard[i].id == user) 
        return this.state.leaderboard[i].score;
    return 0;
  }

  getPlaceMedals(user:string) {
    for(let i = 0; i < this.state.medals.length; i++) 
      if(this.state.medals[i].id == user) 
        return this.state.medals[i].count;
    return 0;
  }

  onCloneEnterPlace() {
    Server.places.setState('lobby');
    this.setState({place: null, navigate: '/places'});
  }

  // settings modal
  onShowSettings() {
    this.setState({
      showSettings: true, 
      name: this.state.place.name, 
      description: this.state.place.description,
      banner: this.state.place.banner
    })
  }

  onSaveSettings() {
    Server.places.setInfo(this.state.name, this.state.description);
    this.setState({showSettings: false})
  }

  onResize(event:any) {
    if(this.isMobile() == this.lastIsMobile)
      return;

    this.lastIsMobile = this.isMobile();
    this.setState({mobile: this.isMobile(), showMembers: !this.isMobile()});
  }

  // chat
  addMessageToChat(message:string, user:string) {
    let chat = [...this.state.chat];
    chat.push({message, user});
    this.setState({chat});
  }

  onSend(message:string) {
    Server.places.sendChatMessage(message, this.state.place.host);
    this.addMessageToChat(message, Server.user.getId());
  }

  onPlaceChatMessage(entry:any) {
    this.addMessageToChat(entry.message, entry.user);
  }

  onPlaceChat(chat:any[]) {
    let entries = [...chat];
    this.setState({chat: entries});
  }

  onClearChat() {
    this.setState({question: 'Do you want to clear chat messages?', questionYes: ()=>{this.onClearChatYes()}})
  }

  onClearChatYes() {
    Server.places.clearChat();
    this.setState({question: '', chat: []});
  }

  onResetMedals() {
    this.setState({question: 'Do you want to reset the medals to zero?', questionYes: ()=>{this.onResetMedalsYes()}})
  }

  onResetMedalsYes() {
    Server.places.resetMedals();
    this.setState({question: '', medals: []});
  }

  onShowMembers() {
    this.setState({showMembers: !this.state.showMembers})
  }

  onBack() {
    this.setState({navigate: '/places'});
  }

  onWatchStream() {
    if(this.state.streamChannel == '')
      return;
    Server.places.setState('watch:' + this.state.streamChannel);
    this.setState({showStreamChannel: false});

    if(this.state.place.users.length > 1)
      Server.user.incrementCounter('place#watch');
  }

  onEndStream() {
    Server.places.setState('lobby')
  }

  onPlayGame(gameId:string) {
    let duration = 10;
    let now = new Date().getTime().toString();
    Server.places.setState(`countdown:${gameId}:${duration}:${now}`);
    this.setState({showSelectGame: false});
  }

  startGameCountdown(gameId:string, timeLeft:number) {
    clearInterval(this.startGameTimer);

    this.startGameTimer = setInterval(()=>{
      if(this.state.timeLeft > 0) 
        this.setState({timeLeft: this.state.timeLeft - 1});
      else {
        clearInterval(this.startGameTimer);
        if(this.isUserTheHost()) 
          this.startGame(gameId);
      }
    }, 1000)
  }

  startGame(game:string) {
    let now = new Date().getTime().toString();
 
    Server.places.resetLeaderboard();
    Server.places.setState(`game:${game}:${now}`);

    let d = new Date();
    let seed = d.getTime();

    for(let i = 0; i < this.state.place.users.length; i++) {
      let user = this.state.place.users[i];
      if(user.id == Server.user.getId()) continue;
      Server.network.sendEvent('user:' + user.id, {id: 'place-launch-game', game, seed});
    }

    if(this.state.place.users.length > 1)
      Server.user.incrementCounter('place#play');

    this.launchGame(game, seed);
  }

  launchGame(game:string, seed:number) {
    let host = this.state.place.host;
    let profile = Server.public.getProfile(host);
    let item = {game, host, seed, slug: profile.slug};
    window.sessionStorage.setItem('PlayingGame', JSON.stringify(item));
    this.setState({navigate: '/play/' + game})
  }

  onPlaceLaunchGame(event:any) {
    this.launchGame(event.game, event.seed);
  }

  onCancelGame() {
    Server.places.setState('lobby');
  }

  onFinishGame() {
    let allFinished = true;
    for(let i = 0; i < this.state.place.users.length; i++)
      if(this.state.place.users[i].state != 'finished' && this.state.place.users[i].state != 'lobby')
        allFinished = false;

    if(allFinished)
      this.onFinishGameYes();
    else
      this.setState({question: 'Do you want to end the game before all players have finished?', questionYes: ()=>{this.onFinishGameYes()}})
  }

  onFinishGameYes() {
    let users = this.getSortedUsers('score');
    let medals:any[] = [];
    let results:any[] = [];

    for(let i = 0; i < users.length; i++) {
      let count = 1;
      if(users[i].score == 0) 
        count = 0;
      else if(i == 0) 
        count = 10;
      else if(i == 1) 
        count = 5;
      else if(i == 2) 
        count = 3;

      medals.push({id: users[i].id, count});
      results.push({id: users[i].id, name: users[i].name, score: users[i].score, medals: count})
    }

    if(medals.length == 1) {
      medals[0].count = 0;
      results[0].medals = 0;
    }

    Server.places.awardMedals(medals);

    for(let i = 0; i < this.state.place.users.length; i++) {
      let user = this.state.place.users[i];
      if(user.id == Server.user.getId()) continue;
      Server.network.sendEvent('user:' + user.id, {id: 'place-finish-game', results});
    }

    Server.places.setState('lobby');
    Server.places.setStateForPlaceAndUsers('lobby');

    this.setState({showFinishGame: true, results, question: ''});
  }

  onPlaceFinishGame(event:any) {
    this.setState({showFinishGame: true, results: event.results});
  }

  renderTitleBar() {
    let info = this.state.place;

    return (
      <div className="place-page-header">
        <BsFillArrowLeftCircleFill className="site-page-header-button" onClick={()=>this.onBack()}/>
        <div style={{textAlign: 'center'}}>{info.name}</div>
        {this.isUserTheHost() &&
          <BsFillGearFill className="site-page-header-button" onClick={()=>this.onShowSettings()}/>
        }
      </div>
    )    
  }

  renderBanner() {
    let info = this.state.place;

    return (
      <img style={{width: '100%'}} src={getBannerImage({banner: info.banner})} />
    )
  }

  renderWelcomeHeader() {
    let bannerUrl = window.location.pathname + '/banners';
    let info = this.state.place;

    return (
      <div style={{position: 'relative'}}>
        {this.renderTitleBar()}
        {this.renderBanner()}
        <div className="place-page-message-area">
          <div>
            {info.description}
          </div>
        </div>
        {this.isUserTheHost() && 
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#000000a0', position: 'absolute', top: '45px', right: '5px', width: '30px', height: '30px', borderRadius: '6px'}}>
            <BsPencilSquare 
              className="site-page-header-button" 
              style={{color: 'var(--panel-subtext-color)'}} 
              onClick={()=>this.setState({navigate: bannerUrl})}
            />
          </div>
        }
      </div>
    )
  }

  renderVideoHeader() {
    let state = this.state.place.state;
    let parts = state.split(':');
    let channel = parts[1];
    return (
      <div style={{position: 'relative'}}>
        {this.renderTitleBar()}
        <iframe
          src={`https://player.twitch.tv/?channel=${channel}&parent=${window.location.hostname}`}
          frameBorder={0}
          allowFullScreen={true} 
          style={{width: '100%', height: '225px', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px'}}
        />
      </div>
    )
  }

  renderGameHeader() {
    let game = Database.getGame(this.state.game);
    let messageFontSize = this.isMobile() ? '0.9em' : '1.0em';

    let msg = '';
    if(this.state.playingGame)
      msg = `Waiting for all players to finish...`;
    else {
      if(this.state.place.host == Server.user.getId()) 
        msg = `You are starting a game of ${game.name}!`;
      else {
        let hostProfile = Server.public.getProfile(this.state.place.host);
        msg = `${hostProfile.name} is starting a game of ${game.name}!`;
      }
    }

    let label = 'STARTS IN';
    let timeLeft = `${this.state.timeLeft}s`;
    let timeFontSize = '1.3em';
    let timeMarginTop = '0px';

    if(this.state.playingGame) {
      label = 'TIME';
      timeLeft = formatTime(this.state.timeLeft);
      timeFontSize = '1.0em';
      timeMarginTop = '5px';
    }

    return (
      <div style={{position: 'relative'}}>
        {this.renderTitleBar()}
        {this.renderBanner()}
        <div className="place-page-notification-area" style={{padding: '10px', alignItems:'center'}}>
          <div style={{display: 'grid', gridTemplateColumns: 'min-content 1fr min-content', columnGap: '10px', alignItems: 'center', justifyContent: 'center', maxWidth: '400px'}}>
            <img style={{width: '80px', borderRadius: '6px', border: '2px solid #00000080'}} src={'/games/' + game.image} />
            <div className="site-page-row" style={{fontSize: messageFontSize, textAlign: 'left'}}>
              {msg}
            </div>
            <div style={{width: '60px', height: '50px', borderRadius: '10px', backgroundColor: '#00000020'}}>
              <div style={{fontSize: '0.5em', marginTop: '5px', color: '#ffffff80'}}>{label}</div>
              <div style={{fontSize: timeFontSize, marginTop: timeMarginTop}}>{timeLeft}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderHeader() {
    if(this.state.watchStream)
      return this.renderVideoHeader();

    if(this.state.gameCountdown || this.state.playingGame)
      return this.renderGameHeader();
    
    return this.renderWelcomeHeader();
  }

  renderActivities() {
    let buttons = [];
    if(this.state.watchStream) {
      buttons.push(
        <button key={0} onClick={()=>this.onEndStream()}>End Stream</button>
      );
    }
    else if(this.state.gameCountdown) {
      buttons.push(
        <button key={0} onClick={()=>this.onCancelGame()}>Cancel</button>
      );
    }
    else if(this.state.playingGame) {
      buttons.push(
        <button key={0} onClick={()=>this.onFinishGame()}>Finish Game</button>
      );
    }
    else {
      buttons.push(<button key={0} onClick={()=>this.setState({showSelectGame: true})}>{!this.state.mobile && 'Play '}Game</button>);
      buttons.push(<button key={1} onClick={()=>this.setState({showStreamChannel: true})}>{!this.state.mobile && 'Watch '}Stream</button>);
      buttons.push(<button key={2}>{!this.state.mobile && 'Start '}Poll</button>);
    }

    return (
      <div className="site-page-panel" style={{flexDirection: 'row', justifyContent: 'center', columnGap: '20px'}}>
        {buttons}
      </div>
    )
  }

  getSortedUsers(sort:string) {
    let users = this.state.place.users;

    let sortedUsers = [];
    for(let i = 0; i < users.length; i++) {
      let user = {...users[i]};
      let profile = Server.public.getProfile(user.id);
      user.name = profile.name;
      user.score = this.getLeaderboardScore(user.id);
      user.medals = this.getPlaceMedals(user.id);
      sortedUsers.push(user);
    }

    sortedUsers.sort((a, b)=>{
      if(sort == 'score') {
        if(a.score > b.score)
          return -1;
        else if(a.score < b.score)
          return 1;
      }

      if(sort == 'medals') {
        if(a.medals > b.medals)
          return -1;
        else if(a.medals < b.medals)
          return 1;
      }

      return 0;
    })

    return sortedUsers;
  }

  renderMembers() {
    if(!this.state.showMembers)
      return null;

    let width = this.state.mobile ? 'unset' : '250px';
    let isLobby = this.state.place.state == 'lobby';
    let isGame = this.state.place.state.indexOf('game:') == 0;
    let isCountdown = this.state.place.state.indexOf('countdown:') == 0;
    let isWatching = this.state.place.state.indexOf('watch:') == 0;

    let sortedUsers = this.getSortedUsers(isGame ? 'score' : 'medals');

    let divs = [];
    for(let i = 0; i < sortedUsers.length; i++) {
      let user = sortedUsers[i];

      let desc = '';
      if(isLobby || isCountdown || isWatching) 
        desc = `🏅 ${numberWithCommas(user.medals)}`;
      else if(isGame && user.state == 'lobby' && user.score == 0) 
        desc = 'Waiting...';
      else
        desc = `🎲 ${numberWithCommas(user.score)}`;

      let name = user.name;
      if(user.state == 'finished' && isGame)
        name += ' ☑️';

      divs.push(
        <div key={i} className="site-page-row">
          <Portrait user={user.id} hideOnline={true} />
          <div className="site-page-column" style={{rowGap: '0px'}}>
            {name}
            <div className="site-page-subtext" style={{fontSize: '0.8em'}}>{desc}</div>
          </div>
        </div>
      )
    }

    return (
      <div className="site-page-panel" style={{width, minHeight: '0'}}>
        <div className="site-page-row" style={{justifyContent: 'space-between'}}>
          <div>People</div>
          <div style={{display: 'flex', columnGap: '15px'}}>
            {this.isUserTheHost() &&
              <BsRecycle className="site-page-header-button" onClick={()=>this.onResetMedals()}/>
            }
            {this.state.mobile &&
              <BsChatDotsFill className="site-page-header-button" onClick={()=>this.onShowMembers()}/>
            }
          </div>
        </div>
        <div className="place-page-members">
          {divs}
        </div>
      </div>
    )
  }

  renderMessages() {
    let chat:any[] = this.state.chat;
    let messages:any[] = [];

    let combinedMessages = [];
    for(let i = 0; i < chat.length; i++) {
      let entry = chat[i];
      let combined = false;

      if(combinedMessages.length > 0) {
        let lastCombinedMessage = combinedMessages[combinedMessages.length-1];
        if(lastCombinedMessage.user == entry.user) {
          lastCombinedMessage.messages.push(entry.message);
          combined = true;
        }
      }

      if(!combined) {
        combinedMessages.push({
          user: entry.user,
          messages: [entry.message]
        });
      }
    }

    // for checking an image URL
    for (let i = 0; i < combinedMessages.length; i++) {
      let msg = combinedMessages[i];
      let profile = Server.public.getProfile(msg.user);

      let contentDivs = [];
      for(let j = 0; j < msg.messages.length; j++) 
        contentDivs.push(<ContentDiv key={j} content={msg.messages[j]} viewImage={false} />)

      messages.push(
        <div key={i} className="site-page-row" style={{alignItems: 'unset', columnGap: '5px'}}>
          <Portrait user={profile.id} hideOnline={true} size={0.6} />
          <div className="site-page-column" style={{rowGap: '0px'}}>
            <div><b>{profile.name}</b></div>
            <div className="site-page-column" style={{rowGap: '0px'}}>
              {contentDivs}
            </div>
          </div>
        </div>
      )
    }

    return messages;
  }

  renderChat() {
    let messages = this.renderMessages();

    return (
      <div className="site-page-panel" style={{minHeight: '0', display: 'grid', gridTemplateRows: 'min-content 1fr min-content'}}>
        <div className="site-page-row" style={{justifyContent: 'space-between'}}>
          <div>Chat</div>
          <div style={{display: 'flex', columnGap: '15px'}}>
            {this.isUserTheHost() &&
              <BsRecycle className="site-page-header-button" onClick={()=>this.onClearChat()}/>
            }
            <div onClick={()=>this.onShowMembers()}>
              {!this.isMobile() && !this.state.showMembers &&
                <BiLeftArrow className="site-page-header-button" size="0.7em" style={{transform: 'translateY(-2px)', marginRight: '3px'}} />
              }
              <BsPeopleFill className="site-page-header-button"/>
              {!this.isMobile() && this.state.showMembers &&
                <BiRightArrow className="site-page-header-button" size="0.7em" style={{transform: 'translateY(-2px)', marginLeft: '3px'}} />
              }
            </div>
          </div>
        </div>
        <div className="place-page-messages" ref={this.messagesDiv}>
          {messages}
        </div>
        <div className="place-page-footer">
          <PostPanel mini={true} onPost={(content:string)=>this.onSend(content)} />
        </div>
      </div>
    )
  }

  renderBottom() {
    return (
      <div style={{minHeight: '0', maxHeight: '500px', display: 'grid', gridTemplateColumns: '1fr min-content', columnGap: '10px'}}>
        {this.renderChat()}
        {this.renderMembers()}
      </div>
    )
  }

  renderBottomMobile() {
    return (
      <div style={{minHeight: '0', display: 'grid', gridTemplateColumns: '1fr'}}>
        {this.state.showMembers ? this.renderMembers() : this.renderChat()}
      </div>
    )
  }

  renderSettingsModal() {
    return (
      <div className="modal open">
        <div className="modal-content" style={{width: '300px', maxHeight: '450px'}}>
          <div className="site-page-column">
            <div className="site-page-row" style={{justifyContent: 'space-between'}}>
              <div style={{fontSize: '1.1em', paddingBottom: '6px'}}>Place Settings</div>
              <BsFillXCircleFill className="site-page-header-button" onClick={()=>this.setState({showSettings: false})}/>
            </div>
            <input placeholder="Name" value={this.state.name} onChange={(e:any)=>this.setState({name: e.currentTarget.value})} />
            <textarea placeholder="Description" value={this.state.description} onChange={(e:any)=>this.setState({description: e.currentTarget.value})}></textarea>
            <div className="site-page-row">
              <input type="checkbox" style={{width: '20px', height: '20px'}} checked={true} disabled={true} />
              <div>Friends Only</div>
            </div>
            <div className="site-page-row" style={{justifyContent: 'right'}}>
              <button onClick={()=>this.onSaveSettings()}>Save</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderSelectGameModal() {
    let isDeveloper = (window.location.href.indexOf('playplace.io') == -1);
    let cards = [];
    for (let i = 0; i < Database.games.length; i++) {
      let game = Database.games[i];
      if(game.developer && !isDeveloper)
        continue;
      if(!game.multiplayer)
        continue;
      cards.push(
        <img 
          key={i} 
          style={{width: '140px', borderRadius: '10px', cursor: 'pointer'}} 
          src={'/games/' + game.image} 
          onClick={()=>this.onPlayGame(game.id)}
        />
      );
    }

    return (
      <div className="modal open">
        <div className="modal-content" style={{width: '300px', maxHeight: '450px'}}>
          <div className="site-page-column">
            <div className="site-page-row" style={{justifyContent: 'space-between'}}>
              <div style={{fontSize: '1.1em', paddingBottom: '6px'}}>Select Game</div>
              <BsFillXCircleFill className="site-page-header-button" onClick={()=>this.setState({showSelectGame: false})}/>
            </div>
            <div className="site-page-row" style={{flexWrap: 'wrap', columnGap: '20px', rowGap: '15px', justifyContent: 'center'}}>
              {cards}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderStreamChannelModal() {
    return (
      <div className="modal open">
        <div className="modal-content" style={{width: '300px', maxHeight: '450px'}}>
          <div className="site-page-column">
            <div className="site-page-row" style={{justifyContent: 'space-between'}}>
              <div style={{fontSize: '1.1em', paddingBottom: '6px'}}>Watch Stream</div>
              <BsFillXCircleFill className="site-page-header-button" onClick={()=>this.setState({showStreamChannel: false})}/>
            </div>
            <div style={{textAlign: 'left', color: 'var(--panel-subtext-color)'}}>Enter Twitch Channel:</div>
            <input value={this.state.streamChannel} onChange={(e:any)=>this.setState({streamChannel: e.currentTarget.value})} />
            <div>
              <button onClick={()=>this.onWatchStream()}>Watch</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderMessage(message:string) {
    return (
      <div className="site-page">
        <PageEnvelope width="600px">
          <div className="site-page-panel">
            <div className="site-page-row">
              <div style={{display: 'flex'}}>
                <BsFillArrowLeftCircleFill className="site-page-header-button" onClick={()=>this.onBack()}/>
              </div>
              <div>
                {message}
              </div>
            </div>
          </div>
        </PageEnvelope>
      </div>
    )
  }

  renderFinishGameModal() {
    let divs = [];
    for(let i = 0; i < this.state.results.length; i++) {
      let user = this.state.results[i];

      divs.push(
        <div key={i} style={{display: 'grid', gridTemplateColumns: 'min-content 1fr min-content', columnGap: '10px', alignItems: 'center'}}>
          <Portrait user={user.id} hideOnline={true} />
          <div className="site-page-column" style={{textAlign: 'left', rowGap: '0px'}}>
            <div >{user.name}</div>
            <div className="site-page-subtext" style={{fontSize: '0.8em'}}>
              🎲 {numberWithCommas(user.score)}
            </div>
          </div>
          {user.medals > 0 &&
            <div>+{user.medals}&nbsp;🏅</div>
          }
        </div>
      )
    }
    
    return (
      <div className="modal open">
        <div className="modal-content" style={{width: '300px', maxHeight: '450px'}}>
          <div className="site-page-column">
            <div style={{fontSize: '1.1em', paddingBottom: '6px'}}>Final Results</div>
            <div className="place-page-members" style={{height: '300px'}}>
              {divs}
            </div>
            <div>
              <button onClick={()=>this.setState({showFinishGame: false})}>Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if(!Server.account.isLoggedIn())
      return <Navigate replace to="/" />;
     
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />

    if(this.state.error != '') 
      return this.renderMessage(this.state.error);

    if(this.state.joining) 
      return this.renderMessage('Connecting...');

    if(!this.state.place)
      return null;

    if(!this.isUserTheHost()) {
      let friend = Server.user.getFriend(this.state.place.host);
      if(!friend.presence || !friend.presence.online) 
        return this.renderMessage('You can only visit your friends when they are online.');
    }

    let showActivities = this.isUserTheHost();

    let gridTemplateRows = 'min-content 1fr';
    if(showActivities)
      gridTemplateRows = 'min-content min-content 1fr';

    return (
      <div className="site-page">
        <PageEnvelope width="700px">

          <div className="place-page" style={{gridTemplateRows}}>
            {this.renderHeader()}
            {showActivities && this.renderActivities()}
            {this.state.mobile ? this.renderBottomMobile() : this.renderBottom()}
          </div>

          {this.state.showSettings && this.renderSettingsModal()}
          {this.state.showSelectGame && this.renderSelectGameModal()}
          {this.state.showStreamChannel && this.renderStreamChannelModal()}
          {this.state.showFinishGame && this.renderFinishGameModal()}

          <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
          <MessageModal message={this.state.message} />
          <QuestionModal message={this.state.question} onYes={()=>this.state.questionYes()} onNo={()=>this.setState({question: ''})} />
        </PageEnvelope>
      </div>
    )
  }
}

export default PlacePage;
