import {UICanvas} from './uiCanvas';
import {UIElement} from './uiElement';
import {UIElementOptions} from './uiElement';

export interface UIScreenOptions extends UIElementOptions {}

export class UIScreen extends UIElement {
  constructor(options: UIScreenOptions = {}) {
    super(options);
    this.fitParent = {left: 0, top: 0, right: 0, bottom: 0};
  }

  public build() {
  }

  public show() {
    UICanvas.instance.setScreen(this);
  }

  public onWake() {
  }

  public onSleep() {
  }
}