import { Service } from './service';

export class PublicService extends Service {
  protected profiles:any;
  protected posts:any;
  protected position:number;
  
  constructor() {
    super();
    this.profiles = [];
  }

  public getProfile(id:string) {
    return this.profiles[id];
  }

  public async loadProfiles(ids:string[]) {
    let missing = [];
    for(let i = 0; i < ids.length; i++)
      if(!this.profiles[ids[i]])
        missing.push(ids[i]);

    if(missing.length == 0)
      return {success: true};

    let response = await this.sendCommand('public', 'get-profiles', {ids: missing});

    if(response.status != 200)
      return {success: false, message: response.body.message};

    let profiles = response.body.profiles;

    for(let i = 0; i < profiles.length; i++) 
      this.addProfileToCache(profiles[i]);

    return {success: true};
  }

  public async loadProfileFromSlug(slug:string) {
    if(this.profiles[slug])
      return {success: true, profile: this.profiles[slug]};

    let response = await this.sendCommand('public', 'get-profile', {slug});
    if(response.status != 200)
      return {success: false, message: response.body.message};

    let profile = response.body.profile;
    this.addProfileToCache(profile);

    return {success: true, profile};
  }

  public addProfileToCache(profile:any) {
    this.profiles[profile.id] = profile;
    this.profiles[profile.slug] = profile;
  }

  public addPostsToCache(posts:any) {
    this.posts = posts;
  }

  public removePostsFromCache() {
    this.posts = null;
  }

  public getPosts() {
    return this.posts;
  }

  public addPositionToCache(position:number) {
    this.position = position;
  }

  public getPosition() {
    return this.position;
  }

  public async unfurl(url:string) {
    let response = await this.sendCommand('public', 'unfurl', {url});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true, data: response.body};
  }

  public async getStatus() {
    let response = await this.sendCommand('public', 'get-status');
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true, status: response.body.status};
  }

  public async requestAccess(email:string) {
    let userAgent = window.navigator.userAgent;
    let response = await this.sendCommand('public', 'request-access', {email, userAgent});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true};
  }

  public async checkInvite(id:string) {
    let response = await this.sendCommand('public', 'check-invite', {id});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true};
  }

  public async getAccessRequests() {
    let response = await this.sendCommand('public', 'get-access-requests');
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true, requests: response.body.requests};
  }
}
