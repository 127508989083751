import { AccountService } from './accountService';
import { GamesService } from './gamesService';
import { UserService } from './userService';
import { Service, ServiceResponse } from './service';
import { ShopService } from './shopService';
import { PublicService } from './publicService';
import { ChatService } from './chatService';
import { NetworkService } from './networkService';
import { ForumsService } from './forumsService';
import { BlogsService } from './blogsService';
import { ActivityService } from './activityService';
import { MediaService } from './mediaService';
import { AssetsService } from './assetsService';
import { PlaceManager } from './placeManager';

const Config = require('../data/server.json');

export class Server {
  public static account: AccountService = new AccountService();
  public static user: UserService = new UserService();
  public static games: GamesService = new GamesService();
  public static shop: ShopService = new ShopService();
  public static public: PublicService = new PublicService();
  public static chat: ChatService = new ChatService();
  public static forums: ForumsService = new ForumsService();
  public static blogs: BlogsService = new BlogsService();
  public static activity: ActivityService = new ActivityService();
  public static media: MediaService = new MediaService();
  public static assets: AssetsService = new AssetsService();
  public static network: NetworkService = new NetworkService();
  public static places: PlaceManager = new PlaceManager();
  public static initialized: boolean = false;
  
  public static synchronizing: boolean = false;
  protected static signUpDetails: any = null;
  protected static loggingIn: boolean = false;

  public static async init() {
    let stage = Config.stage[window.location.hostname];
    Service.configure(Config.api[stage]);
    Server.network.configure(Config.network[stage]);
    Server.account.configure(Config.account);
    Server.media.configure(Config.media);

    let promises = [];
    promises.push(Server.chat.init());
    promises.push(Server.assets.init());
    promises.push(Server.activity.init());
    promises.push(Server.user.init());
    promises.push(Server.games.init());
    promises.push(Server.forums.init());
    // promises.push(Server.shop.init());

    let responses = await Promise.all(promises);

    for(let i = 0; i < responses.length; i++)
      if(!responses[i].success)
        return responses[i];

    Server.places.init();

    await Server.autoLogin();
    
    Server.initialized = true;
  }

  public static async startSignUp(email: string, password: string): Promise<ServiceResponse> {
    let response = await Server.account.signUp(email, password);
    Server.signUpDetails = response.success ? {email, password} : null;
    return response;
  }

  public static async completeSignUp(code: string): Promise<ServiceResponse> {
    let response = await Server.account.confirmRegistration(code);

    if(!response.success)
      return response;
    
    await Server.login(Server.signUpDetails.email, Server.signUpDetails.password);

    if(Server.account.isLoggedIn()) {
      let now = new Date().toISOString();
      Server.activity.markActivityAsRead(now);
      Server.forums.setLastReadDate(now);
      Server.blogs.setLastReadBlogDate(now);
    }

    Server.signUpDetails = null;

    return response;
  }

  protected static isSigningUp():boolean {
    return Server.signUpDetails != null;
  }

  protected static isLoggingIn():boolean {
    return this.loggingIn;
  }

  public static async login(email: string, password: string): Promise<ServiceResponse> {
    Server.loggingIn = true;

    let response = await Server.account.authenticate(email, password);
    if(!response.success) {
      Server.loggingIn = false;
      return response;
    }

    let refreshToken = Server.account.getRefreshToken();
    window.localStorage.setItem('RefreshToken', refreshToken);
    window.localStorage.setItem('RefreshEmail', email);

    return Server.finishLogin();
  }

  public static hasAutoLogin():boolean {
    let email = window.localStorage.getItem('RefreshEmail');
    let token = window.localStorage.getItem('RefreshToken');
    return (email != null && token != null);
  }

  public static async autoLogin(): Promise<ServiceResponse> {
    let email = window.localStorage.getItem('RefreshEmail');
    let token = window.localStorage.getItem('RefreshToken');

    if(!email || !token)
      return {success: false};

    Server.loggingIn = true;

    let response = await Server.account.authenticateWithToken(email, token);

    if(!response.success) {
      Server.loggingIn = false;
      return response;
    }

    return Server.finishLogin();
  }

  protected static async finishLogin() {
    Service.authorizationToken = Server.account.getAuthorizationToken();

    let response = await Server.sync();
    
    if(!response.success) {
      Server.loggingIn = false;
      return response;
    }

    await Server.network.connect();
    Server.account.notifyListeners('login');

    Server.loggingIn = false;

    let now = new Date();
    let day = now.toISOString().split('T')[0];
    let lastLogin = Server.user.getCookie('LastLoginDay');
    if(day != lastLogin) {
      await Server.user.incrementCounter('login#first');
      await Server.user.setCookie({key: 'LastLoginDay', value: day});

      let isMobile = /android|iPhone|iPod|BlackBerry|Windows Phone|Opera Mini/i.test(navigator.userAgent);
      let isIPad = /iPad|Macintosh/i.test(navigator.userAgent) && 'ontouchend' in document;
      if(isMobile || isIPad)
        await Server.user.incrementCounter('login#first#mobile');
    }
    else
      await Server.user.incrementCounter('login#return');

    return {success: true};
  }

  public static async signOut() {
    await Server.account.signOut();
    Server.user.clear();
    Server.chat.clear();
    Server.assets.clear();
    Server.games.clear();
    Server.places.disconnect();
    Server.network.disconnect();
    window.localStorage.removeItem('RefreshToken');
    window.localStorage.removeItem('RefreshEmail');
    Server.account.notifyListeners('logout');
  }

  public static async sync(): Promise<ServiceResponse> {
    Server.synchronizing = true;

    let response = await Server.user.sync();  
    if(!response.success) {
      Server.synchronizing = false;
      return response;
    }

    let promises = [];
    promises.push(Server.assets.sync());
    promises.push(Server.chat.sync());
    promises.push(Server.activity.sync());

    let responses = await Promise.all(promises);

    for(let i = 0; i < responses.length; i++) {
      if(!responses[i].success) {
        Server.synchronizing = false;
        return responses[i];
      }
    }

    Server.synchronizing = false;

    return {success: true};
  }

  public static async refreshServiceLogin(): Promise<ServiceResponse> {
    let email = window.localStorage.getItem('RefreshEmail');
    let token = window.localStorage.getItem('RefreshToken');

    if(email && token) {
      let response = await Server.account.authenticateWithToken(email, token);
      if(!response.success)
        return response;

      console.log('Authentication token refreshed.');
        
      Service.authorizationToken = Server.account.getAuthorizationToken();

      return response;
    }

    return {success: false};
  }

  public static getServiceState() {
    let state:any = {
      notifications: 0,
      chat: 0,
      friends: 0,
      forums: 0,
      blogs: 0,
      activity: 0
    }

    if(!Server.account.isLoggedIn())
      return state;

    if(Server.isSigningUp() || Server.isLoggingIn())
      return state;

    // notifications
    state.notifications = Server.user.getUnreadNotificationsCount();

    // friends
    let friends = Server.user.getFriends();
    for(let i = 0; i < friends.length; i++) 
      if(friends[i].state == 'invited')
        state.friends++;

    // messages
    let chats = Server.chat.getChats();
    for(let i = 0; i < chats.length; i++)
      if(chats[i].id != 'community')
        if(Server.chat.isNewChatMessages(chats[i].id))
          state.chat++;

    // forums
    state.forums = Server.forums.getUnreadForumCount();

    // activity
    state.activity = Server.activity.newPostsFromCache() ? 1 : 0;

    // blogs
    state.blogs = Server.blogs.getUnreadBlogCount();

    return state;
  }

}
