import { UIManager } from "./uiManager";
import { UIElement } from "./uiElement";
import { UIElementOptions } from "./uiElement";
import { UIBounds } from "./uiBounds";
import { UIPoint } from "./uiPoint";

export interface UIGridOptions extends UIElementOptions {
  rows: number;
  columns: number;
  columnWidths?: Array<number>;
  rowHeights?: Array<number>;
  color?: string;
  spacing?: UIPoint;
  margin?: UIBounds;
  autoSizeChildren?: boolean;
  fitToChildren?: boolean;
  drawGrid?: boolean;
}

export class UIGrid extends UIElement
{
  public rows: number;
  public columns: number;
  public columnWidths: number[];
  public rowHeights: number[];
  public color: string;
  public spacing: UIPoint;
  public margin: UIBounds;
  public positionChildren: boolean;
  public autoSizeChildren: boolean;
  public fitToChildren: boolean;
  public drawGrid: boolean;

  constructor(options: UIGridOptions)
  {
    super(options);

    this.color ='#00000000';
    this.rows = 1;
    this.columns = 1;
    this.drawGrid = false;

    this.rows = options.rows;
    this.columns = options.columns;
    this.spacing = {x: 0, y: 0};
    this.margin = {left: 0, top: 0, right: 0, bottom: 0};
    this.positionChildren = true;
    this.autoSizeChildren = true;
    this.fitToChildren = false;
    this.columnWidths = [];
    this.rowHeights = [];

    if(options.columnWidths)
      this.columnWidths = options.columnWidths;

    if(options.rowHeights)
      this.rowHeights = options.rowHeights;

    if(options.color)
      this.color = options.color;

    if(options.spacing)
      this.spacing = options.spacing;

    if(options.margin)
      this.margin = options.margin;

    if(options.autoSizeChildren != null)
      this.autoSizeChildren = options.autoSizeChildren;

    if(options.fitToChildren != null)
      this.fitToChildren = options.fitToChildren;

    if(options.drawGrid != null)
      this.drawGrid = options.drawGrid;
  }

  public update() {
    if(this.autoSizeChildren)
      this.resizeChildren();
    else
      this.layoutChildren();

    if(this.fitToChildren)
      this.resizeToFitChildren();

    super.update();
  }

  public draw() {
    if (this.drawGrid) {
      var widths = this.calculateColumnWidths();
      var heights = this.calculateRowHeights();
      var sx = this.getScreenX() + this.margin.left;
      var sy = this.getScreenY() + this.margin.top;

      UIManager.ctx.lineWidth = 1;
      UIManager.ctx.strokeStyle = '#000000FF';
      for(var r = 0; r < this.rows + 1; r++)
      {
        let dy = (r === this.rows) ? heights[r-1] : heights[r];
        UIManager.ctx.beginPath();
        UIManager.ctx.moveTo(sx - widths[0]/2 + 1, sy - dy/2 + 1);
        UIManager.ctx.lineTo(sx + this.width - widths[0]/2 + 1, sy - dy/2 + 1);
        UIManager.ctx.stroke();

        sy += dy;
      }

      sx = this.getScreenX() + this.margin.left;
      sy = this.getScreenY() + this.margin.top;
      for(var c = 0; c < this.rows + 1; c++)
      {
        let dx = (c === this.columns) ? widths[c-1] : widths[c];
        UIManager.ctx.beginPath();
        UIManager.ctx.moveTo(sx - widths[0]/2 + 1, sy - heights[0]/2 + 1);
        UIManager.ctx.lineTo(sx - widths[0]/2 + 1, sy + this.height - heights[0]/2 + 1);
        UIManager.ctx.stroke();

        sx += dx;
      }
    }
  }

  protected resizeToFitChildren() {
    var newWidth = 0;
    var newHeight = 0;
    for(var i = 0; i < this.children.length; i++) {
      var w = this.children[i].x + this.children[i].width;
      var h = this.children[i].y + this.children[i].height;
      if(w > newWidth)
        newWidth = w;
      if(h > newHeight)
        newHeight = h;
    }

    newWidth += this.margin.right;
    newHeight += this.margin.bottom;

    this.size = {x: newWidth, y: newHeight};
  }

  protected resizeChildren() {
    UIManager.ctx.fillStyle = this.color;

    // calculate cell sizes
    var widths = this.calculateColumnWidths();
    var heights = this.calculateRowHeights();

    // draw background and position children
    var sx = this.margin.left;
    var sy = this.margin.top;
    var ci = 0;

    for(var r = 0; r < this.rows; r++)
    {
      for(var c = 0; c < this.columns; c++)
      {
        UIManager.ctx.fillRect(sx + this.getScreenX(), sy + this.getScreenY(), widths[c]-1, heights[r]-1);

        if(ci < this.children.length)
        {
          if(this.positionChildren)
          this.children[ci].position = {x: sx, y: sy};
          if(this.autoSizeChildren)
          this.children[ci].size = {x: widths[c], y: heights[r]};
          ci++;
        }

        sx += (this.spacing.x + widths[c]);
      }

      sx = this.margin.left;
      sy += (this.spacing.y + heights[r]);
    }
  }

  protected layoutChildren() {
    var sx = this.margin.left;
    var sy = this.margin.top;
    var row = 0;
    var col = 0;
    for(var i = 0; i < this.children.length; i++) {
      this.children[i].position = {x: sx, y: sy};

      col++;
      sx += this.children[i].width + this.spacing.x;

      if(col > this.columns-1) {
        col = 0;
        sx = this.margin.left;
        row++;
        sy += this.children[i].height + this.spacing.y;
      }
    }
  }

  protected calculateColumnWidths(): Array<number> {
    var widths = new Array<number>();

    if(this.columnWidths.length > 0) {
      var widthZeroCount = 0;
      var widthUsed = 0;
      var gridWidthMinusSpacing = (this.width - (this.spacing.x * (this.columns-1)));

      for(var i = 0; i < this.columns; i++) {
        if(this.columnWidths[i] > 0 && this.columnWidths[i] < 1) {
          var w = Math.floor(this.columnWidths[i] * this.width);
          widths.push(w);
          widthUsed += w;
        }
        else {
          widths.push(this.columnWidths[i]);
          widthUsed += this.columnWidths[i];
          if(this.columnWidths[i] == 0)
            widthZeroCount++;
        }
      }

      var remainingColumnWidth = (gridWidthMinusSpacing - widthUsed) / widthZeroCount;
      for(var i = 0; i < this.columns; i++) {
        if(widths[i] == 0)
          widths[i] = remainingColumnWidth;
      }
    }
    else {
      var colWidth = ((this.width - (this.spacing.x * (this.columns-1))) / this.columns);
      for(var i = 0; i < this.columns; i++)
        widths.push(colWidth);
    }

    return widths;
  }

  protected calculateRowHeights(): Array<number> {
    var heights = new Array<number>();

    if(this.rowHeights.length > 0) {
      var heightZeroCount = 0;
      var heightUsed = 0;

      for(var i = 0; i < this.rows; i++) {
        if(this.rowHeights[i] > 0 && this.rowHeights[i] < 1) {
          var h = Math.floor(this.rowHeights[i] * this.height);
          heights.push(h);
          heightUsed += h;
        }
        else {
          heights.push(this.rowHeights[i]);
          heightUsed += this.rowHeights[i];
          if(this.rowHeights[i] == 0)
            heightZeroCount++;
        }
      }

      var remainingRowHeight = (this.height - heightUsed) / heightZeroCount;
      for(var i = 0; i < this.rows; i++) {
        if(heights[i] == 0)
          heights[i] = remainingRowHeight;
      }
    }
    else
      for(var i = 0; i < this.rows; i++)
        heights.push((this.height - (this.spacing.y * (this.rows-1))) / this.rows);

    return heights;
  }

  public getCellSize():number {
    return this.width / this.columns;
  }
}