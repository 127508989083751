import React from 'react';
import { Server } from '../../server/server';
import { Navigate } from 'react-router-dom';
import PageEnvelope from '../elements/PageEnvelope';

interface AdminBetaPageState {
  loading: boolean;
  requests: any[];
}

class AdminBetaPage extends React.Component<{}, AdminBetaPageState> {
  constructor(props:{}) {
    super(props);
    this.state = {
      loading: true,
      requests: []
    }
  }

  componentDidMount(): void {
    this.load();  
  }

  async load() {
    let response = await Server.public.getAccessRequests();
    if(response.success) 
      this.setState({requests: response.requests});
    else
      this.setState({requests: []})
  }

  render() {
    if(!Server.account.isLoggedIn() || !Server.account.isAdmin())
      return <Navigate to="/" replace={true} />

    let now = new Date().toUTCString();

    let divs = [];

    let requests = this.state.requests;
    requests.sort((a, b)=>{
      if(a.date > b.date)
        return -1;
      else if(a.date < b.date)
        return 1;
      return 0;
    })

    for(let i = 0; i < requests.length; i++) {
      let request = requests[i];
      let date = new Date(request.date);
      divs.push(
        <div key={i} style={{display: 'grid', gridTemplateColumns: '150px 1fr'}}>
          <div className="site-page-column" style={{rowGap: '5px'}}>
            <div>{date.toLocaleDateString()}</div>
            <div style={{fontSize: '0.8em', color: 'var(--panel-subtext-color)'}}>{date.toLocaleTimeString()}</div>
          </div>
          <div className="site-page-column" style={{rowGap: '5px'}}>
            <div>{request.email}</div>
            <div style={{fontSize: '0.8em', color: 'var(--panel-subtext-color)'}}>{request.userAgent}</div>
          </div>
        </div>
      )      
    }


    return (
      <div className="site-page">
        <PageEnvelope width="700px">
          <div className="site-page-column">
            {divs}
          </div>
        </PageEnvelope>
      </div>
    )
  }
}

export default AdminBetaPage;