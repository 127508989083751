export function getDeviceType() {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  } else if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
  ) {
    return "mobile";
  }
  return "desktop";
};

export function isVideoUrl(url:string) {
  let match = url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
      url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/) ||
      url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/);
  if (match && match[2].length === 11) {
      return true;
  }
  if (match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/)) { // eslint-disable-line no-cond-assign
      return true;
  }
  if (url.indexOf('www.dropbox.com') != -1) {
    return true
  }
  return false;

}

export function getPublicVideoUrl(url:string) {
  let match = url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
      url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/) ||
      url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/);
  if (match && match[2].length === 11) {
      return ('https') + '://www.youtube.com/embed/' + match[2] + '?showinfo=0';
  }
  if (match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/)) { // eslint-disable-line no-cond-assign
      return (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/';
  }
  if (url.indexOf('www.dropbox.com') != -1) {
    return url.replace('www.dropbox.com', 'dl.dropboxusercontent.com')
  }
  return url;
}

export function convertUrls(str: string): string {
  // console.warn('convertUrls');
  // console.log(str);

  let urlRegex = /(f|ht){1}(tp|tps):\/\/([\w-]+\S)+[\w-]+([\w-?%#&=@.]*)?(\/[\w- ./?%#&=]*)?/g;
  if(!str.match(urlRegex)) {
    // console.log('No URLs found!');
    return str;
  }

  let parser = new DOMParser();
  let doc = parser.parseFromString(str, "text/html");
  // console.log(doc);

  let paragraphs = doc.getElementsByTagName('p');
  // console.log(paragraphs);

  for(let i = 0; i < paragraphs.length; i++) {
    // console.warn('paragraph', i);
    // console.log(paragraphs[i].outerHTML);
    if(paragraphs[i].children.length > 0) {
      // console.log('skipping, has children');
      continue;
    }
    paragraphs[i].innerHTML = replaceUrls(paragraphs[i].innerHTML);
  }

  let test = doc.body.innerHTML;

  // console.warn('FINAL');

  // if(test == str)
  //   console.log('no change');
  // else
  //   console.log(test);
  
  return test;
}

function replaceUrls(str: string): string {
  // console.warn('replaceUrls');
  // console.log(str);

  let urlRegex = /(f|ht){1}(tp|tps):\/\/([\w-]+\S)+[\w-]+([\w-?%#&=@.]*)?(\/[\w- ./?%#&=]*)?/g;

  let match;
  let matches = [];
  while ((match = urlRegex.exec(str)) !== null) {
    matches.push(match);
  }

  // console.log(matches);

  let si = 0;
  for(let i = 0; i < matches.length; i++) {
    // console.warn('MATCH', i);

    let match = matches[i];
    let url = match[0];
    let pos = str.indexOf(url, si);

    // console.log('url', url);
    // console.log('pos', pos);

    if(pos == -1) 
      continue;

    let prefix = str.substring(0, pos);
    let suffix = str.substring(pos + url.length)
    // console.log('prefix', prefix);
    // console.log('suffix', suffix);

    let anchor = `<a href="${url}" target="_blank">${url}</a>`;
    // console.log('anchor', anchor);

    str = prefix + anchor + suffix;
    // console.log(str);
    
    si = pos + anchor.length;
    // console.log('si', si);
  }

  return str;
}

export function numberWithCommas(x: number): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatDate(str:string) {
  let date = new Date(str);
  let now = new Date();
  let yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);


  let isToday = (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate()
  );

  let isYesterday = (
    date.getFullYear() === yesterday.getFullYear() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getDate() === yesterday.getDate()
  );

  let dateConfig:any = {month: "short", day: "numeric"};
  if(date.getFullYear() != now.getFullYear())
    dateConfig.year = "numeric";

  let dateStr = date.toLocaleString(undefined, dateConfig);
  let timeStr = date.toLocaleString(undefined, {hour: "numeric", minute: "numeric"});

  if(isToday)
    dateStr = 'Today';
  else if(isYesterday)
    dateStr = 'Yesterday';

  return dateStr + ' at ' + timeStr;
}

export function formatTime(time:number) {
  let hours = Math.floor(time / 3600);
  let minutes = Math.floor((time - (hours * 3600)) / 60);
  let seconds = Math.floor(time - (hours * 3600) - (minutes * 60));
  let s = '';

  if(hours > 9) 
    s += `${hours}:`;
  else if(hours > 0)
    s += `0${hours}:`

  if(minutes > 9) 
    s += `${minutes}:`;
  else
    s += `0${minutes}:`

  if(seconds > 9) 
    s += `${seconds}`;
  else
    s += `0${seconds}`

  return s;
}

export function secondsOfNow() {
  return Math.floor(new Date().getTime() / 1000);
}

export function formatTimestamp(time: number, ago?: boolean) {

  const m = new Map([[1, 'Jan'], [2, 'Feb'], [3, 'Mar'], [4, 'Apr'], [5, 'May'], [6, 'Jun'], 
    [7, 'Jul'], [8, 'Aug'], [9, 'Sep'], [10, 'Oct'], [11, 'Nov'], [12, 'Dec']]);

  let now  = secondsOfNow();
  let diff = now - time;

  const days    = Math.floor(diff / (60 * 60 * 24));
  const hours   = Math.floor((diff % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((diff % (60 * 60)) / 60);
  const seconds = Math.floor(diff % 60);

  if (days > 0) {
    const date = new Date(time * 1000);
    
    if (days > 365) {
      return date.toLocaleString();
    } else {
      const month = date.getMonth() + 1;
      const day   = date.getDate();
      return m.get(month) + ' ' + day;
    }
  }

  if (hours > 0) {
    let t = hours + 'h';
    if (ago) t += ' ago';
    return t;
  }

  if (minutes > 0) {
    let t = minutes + 'm';
    if (ago) t += ' ago';
    return t;
  }

  if (seconds > 0) {
    let t = seconds + 's';
    if (ago) t += ' ago';
    return t;
  }

  return 'just now';
};

export function getPlaceText(place:number) {
  if(place == 0)
    return '';

  let suffix = 'th';
  if(place == 1)
    suffix = 'st';
  else if(place == 2)
    suffix = 'nd';
  else if(place == 3)
    suffix = 'rd';

  return place + suffix;
}
