import React from 'react';
import { Server } from '../../server/server';
import 'react-quill/dist/quill.snow.css';
import MessageModal from '../modals/MessageModal';
import AlertModal from '../modals/AlertModal';
import PageEnvelope from '../elements/PageEnvelope';
import PageHeader from '../elements/PageHeader';
import PostPanel from '../elements/PostPanel';
import ContentPanel from '../elements/ContentPanel';
import QuestionModal from '../modals/QuestionModal';
import ActivityButtons from '../elements/ActivityButtons';
import { Navigate } from 'react-router-dom';
import './SitePage.css'
import { BsArrowDown, BsArrowDownUp, BsArrowUp, BsCaretDownFill, BsCaretUpFill, BsSortDown, BsSortDownAlt, BsSortUp } from 'react-icons/bs';
import { BiSolidSortAlt } from 'react-icons/bi';
import { HiArrowUturnDown } from 'react-icons/hi2';

interface ActivityPostPageState {
  post: any;
  message: string;
  alert: string;
  question: string;
  onQuestionYes: Function;
  loading: boolean;
  missing: boolean;
  date: string;
  navigate: string;
  sort: boolean;
  more: any;
  editReply: string;
  editContent: boolean;
  loadingMore: boolean;
}

class ActivityPostPage extends React.Component<{}, ActivityPostPageState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      post: '',
      message: '',
      alert: '',
      question: '',
      onQuestionYes: null,
      loading: true,
      missing: false,
      date: '',
      navigate: '',
      sort: true,
      more: null,
      editReply: '',
      editContent: false,
      loadingMore: false
    };
  }

  componentDidMount() {
    this.getPost(this.state.sort);
  }

  sortReplies(replies:any[], sort:boolean) {
    replies.sort((a:any, b:any)=>{
      if(a.date > b.date)
        return sort ? 1 : -1;
      else if(a.date < b.date)
        return sort ? -1 : 1;
      return 0;
    })
  }

  async getPost(sort:boolean, more:any = null) {
    let id = window.location.pathname.substring(15);

    let response = await Server.activity.getPost(id, sort, more);

    if (!response.success) {
      this.setState({missing: true});
      Server.activity.deletePostFromCache(id);
      return;
    }

    Server.activity.updatePostInCache(response.post);

    // cache profiles
    let profiles = [response.post.author];
    let replies = response.post.replies;

    for (let i = 0; i < replies.length; i++) {
      if(profiles.indexOf(replies[i].user) == -1)
        profiles.push(replies[i].user);
    }

    await Server.public.loadProfiles(profiles);

    let post = null;
    if(more) {
      post = this.state.post;
      for(let i = 0; i < response.post.replies.length; i++) {
        let exists = false;
        for(let j = 0; j < post.replies.length; j++) 
          if(response.post.replies[i].date ==  post.replies[j].date)
            exists = true;
        if(!exists)
          post.replies.push(response.post.replies[i]);
      }
      this.sortReplies(post.replies, sort);
    }
    else 
      post = response.post;

    this.setState({ post, more: response.more, loading: false });
  }

  async onPostReply(content:string) {
    let now = new Date().toISOString();

    let newReply = {
      user: Server.user.getId(),
      content: content,
      date: now
    };
    
    this.state.post.replies.push(newReply);
    this.sortReplies(this.state.post.replies, this.state.sort);

    this.forceUpdate();

    let response = await Server.activity.createReply({
      id: this.state.post.id,
      content: content
    });
  
    if(response.success) {
      Server.user.incrementCounter('activity#reply');
      newReply.date = response.date;
    }
    else 
      this.setState({alert: response.message})
  }

  onEditContent() {
    this.setState({editContent: true});
  }

  async onSaveContent(content:string) {
    this.setState({editContent: false});

    if(content == null)
      return;

    if(content.length == 0) {
      this.onDeletePost();
      return;
    }

    this.state.post.content = content;

    this.forceUpdate();

    let response = await Server.activity.editPost(this.state.post.id, this.state.post.date, content);

    if (!response.success) 
      this.setState({alert: response.message});
  }

  onEditReply(date:string) {
    this.setState({editReply: date});
  }

  async onSaveReply(content:string) {
    this.setState({editReply: null});

    if(content == null)
      return;

    for(let i = 0; i < this.state.post.replies.length; i++) {
      if(this.state.post.replies[i].date == this.state.editReply) {
        if(content == '')
          this.state.post.replies.splice(i, 1);
        else
          this.state.post.replies[i].content = content;
        break;
      }
    }

    let response = null;

    if(content == '') {
      response = await Server.activity.deleteReply({
        id: this.state.post.id, 
        date: this.state.editReply
      });
    }
    else {
      response = await Server.activity.editReply({
        id: this.state.post.id,
        date: this.state.editReply,
        content: content
      });
    }

    if (!response.success) 
      this.setState({alert: response.message})
  }

  onDeletePost() {
    this.setState({
      question: 'Do you want to delete this post and all its replies?', 
      onQuestionYes: ()=>this.onDeletePostYes()
    });
  }

  onDeletePostYes() {
    this.setState({question: '', message: 'Deleting post...'});
    setTimeout(async () => {
      let response = await Server.activity.deletePost(this.state.post.id);
      if(response.success) {
        Server.activity.deletePostFromCache(this.state.post.id);
        this.setState({navigate: '/activity', message: ''});
      }
      else
        this.setState({alert: response.message, message: ''});
    }, 500);
  }

  onJumpToBottom() {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }

  onSort() {
    if(this.state.more)
      this.getPost(!this.state.sort);
    else 
      this.sortReplies(this.state.post.replies, !this.state.sort);

    this.setState({sort: !this.state.sort});
  }

  onJumpToReplies() {
    document.getElementById('RepliesHeader')?.scrollIntoView({behavior: 'smooth', block: 'center'});
  }

  async onLoadMore() {
    this.setState({loadingMore: true});
    await this.getPost(this.state.sort, this.state.more);
    this.setState({loadingMore: false});
  }

  renderLoading() {
    return (
      <div className="site-page-column">
        <PageHeader text={this.state.loading ? 'Loading...' : 'View Post'} navigate="/activity"/>

        {this.state.missing &&
          <div className="site-page-panel">
            This post has been deleted.
          </div>
        }
      </div>
    )
  }

  renderRepliesHeader() {
    let count = this.state.post.replies.length;
    let label = (count > 1) ? `${count}${this.state.more ? '+' : ''} Replies` : '1 Reply';
    let headerId = 'RepliesHeader';
    
    return (
      <div className="site-page-header" id={headerId}>
        <div className="site-page-row" style={{justifyContent: 'space-between'}}>
          <div>
            {label}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', columnGap: '15px'}}>
            <div style={{cursor: 'pointer'}} onClick={()=>this.onJumpToBottom()}>
              Bottom&nbsp;<HiArrowUturnDown style={{fontSize: '0.9em', transform: 'translateY(2px)'}} />
            </div>
            <div style={{cursor: 'pointer'}} onClick={()=>this.onSort()}>
              Sort&nbsp;
              {this.state.sort ?
                <BsCaretUpFill style={{fontSize: '1.0em', transform: 'translateY(3px)'}} /> :
                <BsCaretDownFill style={{fontSize: '1.0em', transform: 'translateY(3px)'}} />
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderContent() {
    let replyDivs = [];
    let replies = this.state.post.replies;
    
    for (let i = 0; i < this.state.post.replies.length; i++) {
      if(this.state.editReply == this.state.post.replies[i].date) {
        replyDivs.push(
          <PostPanel 
            key={i}
            placeholder="Enter reply..."
            edit={this.state.post.replies[i].content}
            onPost={(content:string)=>this.onSaveReply(content)}
            words={1000}
          />
        )
      }
      else {
        let action = replies[i].user == Server.user.getId() ? 'edit' : '';
        replyDivs.push(
          <ContentPanel 
            key={i}
            author={replies[i].user}
            date={replies[i].date}
            content={replies[i].content}
            reply={true}
            action={action}
            onAction={()=>this.onEditReply(replies[i].date)}
          />
        )
      }
    }

    let action = this.state.post.author == Server.user.getId() ? 'edit' : '';

    return (
      <div className="site-page-column">
        <PageHeader text="View Post" navigate="/activity"> 
          {this.state.post.replies.length > 0 &&
            <div style={{display: 'flex', cursor: 'pointer'}} onClick={()=>this.onJumpToReplies()}>
              Replies&nbsp;<HiArrowUturnDown style={{fontSize: '0.9em', transform: 'translateY(4px)'}} />
            </div>
          }
        </PageHeader>

        {this.state.editContent &&
          <PostPanel 
            placeholder="Enter content..."
            edit={this.state.post.content}
            onPost={(content:string)=>this.onSaveContent(content)}
          />
        }

        {!this.state.editContent &&
          <ContentPanel 
            author={this.state.post.author}
            date={this.state.post.date}
            content={this.state.post.content}
            action={action}
            onAction={()=>this.onEditContent()}
          >
            <ActivityButtons 
              reposts={this.state.post.reposts} 
              reposted={this.state.post.reposted} 
              likes={this.state.post.likes} 
              liked={this.state.post.liked} 
              post={this.state.post} 
            />
          </ContentPanel>
        }

        {!this.state.editContent &&
          <PostPanel 
            placeholder="Enter reply..."
            onPost={(content:string)=>this.onPostReply(content)}
            words={1000}
          />
        }

        {this.state.post.replies.length > 0 &&
          this.renderRepliesHeader()
        }

        {replyDivs}

        {this.state.more &&
          <button onClick={()=>this.onLoadMore()} style={{backgroundColor: 'var(--page-background-color)', border: '1px solid var(--panel-background-color)'}}>
            {this.state.loadingMore ? 'Loading...' : 'Load More Replies'}
          </button>
        }

        {this.state.post.replies.length > 2 && !this.state.editContent &&
          <PostPanel 
            placeholder="Enter reply..."
            onPost={(content:string)=>this.onPostReply(content)}
            words={1000}
          />
        }
      </div>
    )
  }

  render() {
    if(this.state.navigate != '')
      return (<Navigate to={this.state.navigate} />);
      
    return (
      <div className="site-page">
        <PageEnvelope width="600px">
          {this.state.loading || this.state.missing ? this.renderLoading() : this.renderContent()}
        </PageEnvelope>

        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
        <QuestionModal message={this.state.question} onYes={()=>this.state.onQuestionYes()} onNo={()=>this.setState({question: ''})} />
      </div>
    );
  }
}

export default ActivityPostPage;