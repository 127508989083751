import React from 'react';
import { NavLink } from 'react-router-dom';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import '../pages/SitePage.css';
import BadWords from '../util/badWords';
import { convertUrls } from '../util/util';
import ViewImageModal from '../modals/ViewImageModal';

interface ContentDivProps {
  content: string;
  onImageLoad?: Function;
  viewImage?: boolean;
  summary?: boolean;
}

interface ContentDivState {
  openImage: boolean;
  image: string;
}

class ContentDiv extends React.Component<ContentDivProps, ContentDivState> {
  parseOptions = {
    replace: (domNode: any) => {
      if (domNode.attribs && domNode.name === 'img') {
        let props = attributesToProps(domNode.attribs);
        if(props.src.indexOf('https://s3.us-west-2.amazonaws.com/media.playplace.io') != -1)
          props.src = props.src.replace('https://s3.us-west-2.amazonaws.com/media.playplace.io', 'https://media.playplace.io')
        return (
          <div className="ql-editor-image-container">
            <img 
              className='ql-editor-image' 
              onClick={(e)=>this.onViewImage(e, props.src)} 
              onLoad={(e)=>{if(this.props.onImageLoad) this.props.onImageLoad(props.src)}}
              {...props} 
            />
          </div>
        );
      }
  
      if (domNode.attribs && domNode.name === 'slug') {
        const props = attributesToProps(domNode.attribs);
        return <NavLink style={{textDecoration: 'none'}} onClick={(e)=>e.stopPropagation()} to={props.href}>{props.id}</NavLink>;
      }

      if (domNode.attribs && domNode.name === 'hashtag') {
        const props = attributesToProps(domNode.attribs);
        return <NavLink style={{textDecoration: 'none'}} onClick={(e)=>this.onHashtag(e)} to={props.href} >{props.id}</NavLink>;
      }

      if (domNode.attribs && domNode.name === 'a') {
        const props = attributesToProps(domNode.attribs);
        const regex = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif))/i;

        if (regex.test(props.href)) {
          return (
            <div>
              <a {...props}>{props.href}</a>
              <img 
                style={{margin: '8px 0'}} 
                className='ql-editor-image' 
                src={props.href} 
                onClick={(e)=>this.onViewImage(e, props.href)} 
              />
            </div>
          )
        }


        if(props.href.indexOf(window.location.host) != -1) {
          let href = props.href.substring(window.location.origin.length);
          return <NavLink className={props.className} onClick={(e)=>e.stopPropagation()} to={href}>{domToReact(domNode.children)}</NavLink>;
        }
        else {
          return <a {...props} onClick={(e)=>e.stopPropagation()}>{domToReact(domNode.children)}</a>;
        }
      }
    }
  };

  constructor(props: ContentDivProps) {
    super(props);

    this.state = {
      openImage: false,
      image: ''
    };
  }
  
  onViewImage(e:any, src:string) {
    e.stopPropagation();
    this.setState({openImage: true, image: src})
  }

  onHashtag(e:any) {
    e.stopPropagation();
    // if (this.props.onHashtag)
    //   this.props.onHashtag();
  }


  render() {
    let content = decodeURIComponent(this.props.content);
    content     = BadWords.clean(content);
    content     = convertUrls(content);

    if(this.props.summary && content.length > 1000)
      content = content.substring(0, 1000) + '...';

    let includeModal = (this.props.viewImage == undefined || this.props.viewImage == true);

    return (
      <div>
        <div style={{wordWrap: 'break-word'}}>
          {parse(content, this.parseOptions)}
        </div>
        {includeModal &&
          <ViewImageModal src={this.state.image} onClose={()=>this.setState({image: ''})} />
        }
      </div>
    )
  }
}

export default ContentDiv;