import React from 'react';
import '../pages/SitePage.css';
import { BsFillXCircleFill } from 'react-icons/bs';
import { Server } from '../../server/server';

interface DailyResetPanelProps {
  onUpdate: Function;
}

interface DailyResetPanelState {
}

class DailyResetPanel extends React.Component<DailyResetPanelProps, DailyResetPanelState> {
  protected timer:any;
  protected lastTime:number;

  constructor(props: DailyResetPanelProps) {
    super(props);
    this.state = {
    }
    this.timer = null;
    this.lastTime = -1;
  }

  componentDidMount(): void {
    this.timer = setInterval(() => {
      this.onUpdate();
    }, 1000);
  }

  componentWillUnmount(): void {
    clearInterval(this.timer);
  }

  getHoursLeft() {
    let now = new Date();
    let reset = new Date();
    reset.setUTCHours(16, 0, 0, 0);
    if(now > reset)
      reset.setMonth(now.getMonth(), now.getDate() + 1);
    return (reset.getTime() - now.getTime()) / 3600000; 
  }

  onUpdate() {
    let hoursLeft = this.getHoursLeft();
    if(this.lastTime != -1 && hoursLeft > this.lastTime) {
      Server.games.clearCaches();
      this.props.onUpdate();
    }
    this.lastTime = hoursLeft;
    this.forceUpdate();
  }

  render() {
    let hoursLeft = this.getHoursLeft();
    let h = Math.floor(hoursLeft);
    let m = Math.floor((hoursLeft - h) * 60);
    let s = Math.floor((hoursLeft - h - (m/60)) * 3600);

    let ss = (h == 0 && m < 2) ? `:${s<10?'0':''}${s}` : '';

    return (
      <div style={{backgroundColor: 'transparent', transform: 'translateY(1px)'}}>
        {h<10?'0':''}{h}:{m<10?'0':''}{m}{ss}
      </div>
    )
  }
}

export default DailyResetPanel;