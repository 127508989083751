import { UIImage } from "./ui/uiImage";
import { UIManager } from "./ui/uiManager";
import { AlertDialog } from "./alertDialog";
import { MessageDialog } from "./messageDialog";
import { QuestionDialog } from "./questionDialog";
import { UIDialogOptions } from "./ui/uiDialog";
import { SettingsDialog } from "./settingsDialog";
import { PauseDialog } from "./pauseDialog";
import { Server } from "../../server/server";
import { PlayPlaceMenu } from "./playPlaceMenu";
import { Database } from "../../app/util/database";
import { RewardDialog } from "./rewardDialog";
import { GameOverCommonDialog } from "./gameOverDialog";
import { WelcomeDialog } from "./welcomeDialog";

export class GameBase {
  public alertDialog: AlertDialog;
  public messageDialog: MessageDialog;
  public questionDialog: QuestionDialog;
  public pauseDialog: PauseDialog;
  public playPlaceMenu: PlayPlaceMenu;
  public dialogStyle: UIDialogOptions;
  public settingsDialog: SettingsDialog;
  public rewardDialog: RewardDialog;
  public gameOverCommonDialog: GameOverCommonDialog;
  public welcomeDialog: WelcomeDialog;
  public callback: Function;
  protected canvas: HTMLCanvasElement;
  protected gameId: string;
  protected paused: boolean;

  constructor(canvas:HTMLCanvasElement, callback:Function, gameId:string) {
    this.canvas = canvas;
    this.callback = callback;
    this.gameId = gameId;
    this.paused = false;
  }

  public load() {
    UIManager.connect(this.canvas);
    // UIManager.setFrameRate(UIManager.isMobile ? 40 : 60);
    UIManager.setFrameRate(60);

    this.messageDialog = new MessageDialog(this.dialogStyle);
    this.messageDialog.build();
    this.alertDialog = new AlertDialog(this.dialogStyle);
    this.alertDialog.build();
    this.questionDialog = new QuestionDialog(this.dialogStyle);
    this.questionDialog.build();
    this.pauseDialog = new PauseDialog(this.dialogStyle);
    this.pauseDialog.build();
    this.playPlaceMenu = new PlayPlaceMenu(this.dialogStyle);
    this.playPlaceMenu.build();
    this.rewardDialog = new RewardDialog(this.dialogStyle);
    this.rewardDialog.build();
    this.gameOverCommonDialog = new GameOverCommonDialog(this.dialogStyle);
    this.gameOverCommonDialog.build();
    this.welcomeDialog = new WelcomeDialog(this.dialogStyle);
    this.welcomeDialog.build();
  }

  public unload() {
    this.messageDialog = null;
    this.alertDialog = null;
    this.questionDialog = null;
    this.pauseDialog = null;
    this.playPlaceMenu = null;
    this.rewardDialog = null;
    this.gameOverCommonDialog = null;
    this.welcomeDialog = null;

    UIManager.disconnect();
    UIImage.cleanupImageCache();

    Server.chat.removeEventListener('chat-started', null);
    Server.chat.removeEventListener('chat-updated', null);
    Server.user.removeEventListener('friend-updated', null);
  }

  public showMessage(message:string, callback:Function = ()=>{}) {
    this.messageDialog.setMessage(message);
    this.messageDialog.showModal(callback);
  }

  public setMessage(message:string) {
    this.messageDialog.setMessage(message);
  }

  public hideMessage() {
    this.messageDialog.hide();
  }

  public showAlert(question:string, callback:Function = ()=>{}) {
    this.alertDialog.setMessage(question);
    this.alertDialog.showModal(callback);
  }

  public askQuestion(question:string, callback:Function = ()=>{}) {
    this.questionDialog.setMessage(question);
    this.questionDialog.setButtons(['No', 'Yes']);
    this.questionDialog.showModal(callback);
  }

  public quit(val:string=null) {
    if(val) 
      this.callback(val);
    else
      this.callback();
  }

  public showWelcome(callback:Function=null) {
    let key = 'Welcome-' + this.gameId;
    if(Server.user.getCookie(key)) {
      if(callback)
        callback();
      return;
    }

    this.welcomeDialog.init(this.gameId);

    this.welcomeDialog.showModal(async ()=>{
      this.showMessage('Claiming powerups...');

      let response = await Server.assets.welcomeGame(this.gameId);

      if(response.success && response.assets) 
        for(let i = 0; i < response.assets.length; i++)
          Server.assets.addAssetToInventory(response.assets[i].id, response.assets[i].quantity);

      Server.user.addCookieToCache(key, '1');

      setTimeout(() => {
        this.hideMessage();
        if(callback)
          callback();
      }, 1000);
    });
  }

  public showSettings(callback:Function=null) {
    this.settingsDialog.showModal((settings:any)=>{
      if(settings)
        this.start(settings);
      if(callback)
        callback(settings != null);
    });
  }

  public showReward(reward:any) {
    if(!reward) return;
    this.rewardDialog.setReward(reward);
    this.rewardDialog.showModal();
  }

  public showGameOver(score:number, rewardScore:number, callback:Function, handleQuit:boolean=true) {
    this.pause();
    this.gameOverCommonDialog.init(this.gameId, score, rewardScore, this.isMultiplayer());
    this.gameOverCommonDialog.showModal((action:string)=>{
      if(action == 'quit' && handleQuit) 
        this.quit();
      else if(action == 'reward')
        this.showReward(this.gameOverCommonDialog.getReward());
      else if(action == 'restart') {
        this.resume();
        callback('restart');
      }
      else 
        callback(action);
    });
  }

  public setGameOverBestScore(score:number, best:boolean, place:number=-1) {
    this.gameOverCommonDialog.setBestScore(score, best, place);
  }

  public disableGameOverButtons(b:boolean) {
    this.gameOverCommonDialog.disableButtons(b);

  }

  public showPlayPlaceMenu() {
    this.playPlaceMenu.showModal((action:string)=>{
      if (action) 
        this.quit(action);
    });
  }

  public showPauseDialog(pauseCallback:Function, handleQuit:boolean=true) {
    this.pauseDialog.init(this.isMultiplayer());
    this.pauseDialog.showModal((action:string)=>{
      if(action == 'restart') {
        this.askQuestion('Start a new game?', (response:string)=>{
          if(response == 'Yes')  {
            this.incrementGameCounter('restart');
            pauseCallback('restart');
          }
          else
            pauseCallback('resume');
        });
      }
      else if(action == 'quit' && handleQuit) {
        this.askQuestion('Quit the game and\nreturn to Play Place?', (response:string)=>{
          if(response == 'Yes')
            this.quit();
          else
            pauseCallback('resume');
        });
      } 
      else
        pauseCallback(action);
    });
  }

  public start(settings:any = null) {
    // if(Site.room.isActive()) {

    //   if(Site.room.isLocalPlayerHost()) {
    //     Site.room.sendEvent({
    //       id: 'start-game',
    //       settings: this.stringifySettings(settings)
    //     });
    //     // Site.room.setState('playing');
    //   }

    //   Site.room.setLocalPlayerState('playing', true);
    // }
  }

  public pause() {
    this.paused = true;
  }

  public resume() {
    this.paused = false;
  }

  public isPaused() {
    return this.paused;
  }

  public getPlace():string {
    let s = window.sessionStorage.getItem('PlayingGame');
    if(s) {
      let item = JSON.parse(s);
      return item.slug;
    }
    return null;
  }

  public getPlaceHost():string {
    let s = window.sessionStorage.getItem('PlayingGame');
    if(s) {
      let item = JSON.parse(s);
      return item.host;
    }
    return null;

  }

  public getPlaceSeed():string {
    let s = window.sessionStorage.getItem('PlayingGame');
    if(s) {
      let item = JSON.parse(s);
      return item.seed;
    }
    return null;

  }

  public isMultiplayer():boolean {
    return this.getPlace() != null;
  }

  public getGameId():string {
    return this.gameId;
  }

  public async incrementGameCounter(id:string, amount:number=1) {
    await Server.user.incrementCounter('game#' + this.getGameId() + '#' + id, amount);
  }
}