import { UICanvas } from '../common/ui/uiCanvas';
import { UIElement, UIElementOptions } from '../common/ui/uiElement';
import { Game } from './game';

export class MatchThreePanel extends UIElement {
  protected lastScreenWidth: number;
  protected lastScreenHeight: number;

  constructor(options: UIElementOptions) {
    super(options);
    this.mouseEvents = true;
    this.lastScreenWidth = 0;
    this.lastScreenHeight = 0;
  }

  public update() {
    var now = performance.now();
    if(this.lastUpdate == 0)
      this.lastUpdate = now;
    this.deltaTime = now - this.lastUpdate;
    this.lastUpdate = now;

    Game.instance.tick(this.deltaTime);

    this.updateAutoSize();

    if(this.lastScreenWidth != this.width || this.lastScreenHeight != this.height) {
      this.lastScreenWidth = this.width;
      this.lastScreenHeight = this.height;
      this.resizeGrid();
    }

    this.draw();
  }

  public draw() {
    this.children.forEach((child) => {
      child.visible = false;
    });

    let gameSize = Game.instance.matchThree.gemWidth * 9;
    let margin = Game.instance.matchThree.boardMargin;
    let boardWidth = gameSize - margin.left - margin.right;
    let boardHeight = gameSize - margin.top - margin.bottom;
    
    Game.instance.matchThree.boardX = this.getScreenX() - (margin.left) + (this.width/2) - (boardWidth/2);
    Game.instance.matchThree.boardY = this.getScreenY() - (margin.top) + (this.height/2) - (boardHeight/2);
    Game.instance.matchThree.drawAll();

    this.children.forEach((child) => {
      child.update();
    });
  }

  protected resizeGrid() {
    let gemWidth = Math.floor(this.width / 9);
    let gemHeight = Math.floor(this.height / 9);
    let gemSize = Math.min(gemWidth, gemHeight);
    Game.instance.matchThree.setGemSize(gemSize);
  }

  public onMouseDown(x: number, y: number): void {
    Game.instance.matchThree.onMouseDown(x, y);
    UICanvas.instance.lockMouse(this);
  }

  public onMouseUp(x: number, y: number): void {
    UICanvas.instance.unlockMouse(this);
    Game.instance.matchThree.onMouseUp(x, y);
  }

  public onMouseMove(x: number, y: number): void {
    Game.instance.matchThree.onMouseMove(x, y);
  }
}