import React from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import { Server } from '../../server/server';
import { getPortraitImage } from '../util/assets';
import { getAssetImage, getBannerImage } from '../util/assets';
import PageEnvelope from '../elements/PageEnvelope';
import { Database } from '../util/database';
import { BsActivity, BsArrowRight, BsBarChartLineFill, BsBoxFill, BsCaretDownFill, BsCaretRightFill, BsDice6Fill, BsFillArrowLeftCircleFill, BsFillBellFill, BsFillPeopleFill, BsFillRocketTakeoffFill, BsFillXCircleFill } from 'react-icons/bs';
import MessageModal from '../modals/MessageModal';
import AlertModal from '../modals/AlertModal';
import InvitePanel from '../elements/InvitePanel';
import CoinIcon from '../elements/CoinIcon';
import ContentPanel from '../elements/ContentPanel';
import ActivityButtons from '../elements/ActivityButtons';
import GamesPanel from '../elements/GamesPanel';
import { getIcon } from '../util/icon';
import './HomePage.css';
import AssetsPanel from '../elements/AssetsPanel';
import NotificationsPanel from '../elements/NotificationsPanel';
import FriendsPanel from '../elements/FriendsPanel';
import InfoPanel from '../elements/InfoPanel';
import LifetimeStatsPanel from '../elements/LifetimeStatsPanel';
import { getTimeString } from '../../games/common/util';
import DailyResetPanel from '../elements/DailyResetPanel';
import QuestionModal from '../modals/QuestionModal';

let maxDailyGames: number = 8;

interface HomePageState {
  navigate: string;
  email: string;
  requestAccess: boolean;
  alert: string;
  message: string;
  welcomeStart: boolean;
  welcomeFinish: boolean;
  index: number;
  asset: string;
  portrait: string;
  banner: string;
  name: string;
  tab: string;
  loading: boolean;
  posts: any[];
  hasNewPosts: boolean;
  scorePeriod: string;
  gameSort: string;
  notify: boolean[];
  gamesUpdate: number;
  question: string;
  showClaim: boolean;
  dailyGames: number;
}

class HomePage extends React.Component<{}, HomePageState> {
  protected updateTimer:any;

  constructor(props:{}) {
    super(props);
    this.state = {
      navigate: '',
      email: '',
      requestAccess: false,
      alert: '',
      message: '',
      welcomeStart: false,
      welcomeFinish: false,
      index: -1,
      asset: '',
      portrait: '',
      banner: '',
      name: '',
      tab: 'activity',
      loading: false,
      posts: [],
      hasNewPosts: false,
      scorePeriod: 'today',
      gameSort: 'recent',
      notify: [false, false, false],
      gamesUpdate: 0,
      question: '',
      showClaim: false,
      dailyGames: 0
    };

    this.onActivityUpdated = this.onActivityUpdated.bind(this);
  }

  componentDidMount() {
    let s = localStorage.getItem('HomePageHideInvite');
    if(s) {
      let d = new Date(s);
      let now = new Date();
      let hours = (now.getTime() - d.getTime()) / 1000 / 60 / 60;
      if(hours >= 48)
        localStorage.removeItem('HomePageHideInvite');
    }

    if(Server.account.isLoggedIn() && !Server.user.getCookie('Welcome')) {
      let inventory = Server.assets.getInventory();
      if(inventory.length == 0) {
        this.setState({
          welcomeStart: true,
          name: Server.user.getName()
        });
      }
      else {
        let now = new Date().toISOString();
        Server.user.setCookie({key: 'Welcome', value: now});
      }
    }
    
    let tab = sessionStorage.getItem('HomePageTab');
    if(tab && tab != 'inventory') 
      this.setState({tab});

    if(tab == 'activity' || !tab)
      this.loadActivity();

    Server.activity.addEventListener('activity-updated', this.onActivityUpdated);

    this.startMonitoringNotifications();
  }

  componentWillUnmount(): void {
    this.stopMonitoringNotifications();
    Server.activity.removeEventListener('activity-updated', this.onActivityUpdated);
  }

  async welcome(name:string, portrait:string, banner:string) {
    await Server.user.setProfile({name, portrait, banner});

    this.forceUpdate();

    let response = await Server.assets.welcome(portrait, banner);

    if(response.success) {
      Server.assets.addAssetToInventory(portrait, 1);
      Server.assets.addAssetToInventory(banner, 1);
    
      if(response.assets) 
        for(let i = 0; i < response.assets.length; i++)
          Server.assets.addAssetToInventory(response.assets[i].id, response.assets[i].quantity);

      let now = new Date();
      Server.user.addCookieToCache('Welcome', now.toISOString());
  
      Server.user.incrementCounter('login#new');
    }
  }

  async loadActivity() {
    this.setState({loading: true});
    
    let posts = Server.activity.getPostsFromCache();
    // let response = await Server.activity.getPosts();
    // this.setState({loading: false});
    // if(response.success)

    let profiles = [];
    for (let i = 0; i < posts.length; i++) {
      if(profiles.indexOf(posts[i].author) == -1)
        profiles.push(posts[i].author);

      if (posts[i].repost)
        if(profiles.indexOf(posts[i].repost.author) == -1)
          profiles.push(posts[i].repost.author);
    }

    await Server.public.loadProfiles(profiles);

    Server.activity.markActivityAsRead();
    
    this.setState({posts, loading: false});
  }

  startMonitoringNotifications() {
    this.updateNotification();
    this.updateTimer = setInterval(()=>{
      this.updateNotification();
    }, 250)
  }

  stopMonitoringNotifications() {
    clearInterval(this.updateTimer);
  }

  updateNotification() {
    let state = Server.getServiceState();

    let notify = [];
    notify.push(state.activity > 0);
    notify.push(state.friends > 0);
    notify.push(state.notifications > 0);

    let set = false;
    for(let i = 0; i < notify.length; i++)
      if(this.state.notify[i] != notify[i])
        set = true;

    if(set)
      this.setState({notify});
  }

  onActivityUpdated() {
    this.setState({hasNewPosts: true})
  }

  onShowNewPosts() {
    this.setState({hasNewPosts: false});
    this.loadActivity();
  }

  onActivityButtonsUpdate() {
    let posts = [...Server.activity.getPostsFromCache()];
    this.setState({posts});
  }

  onRequestAccess() {
    if(this.state.email == '')
      return;

    let valid = this.state.email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if(!valid) {
      this.setState({alert: 'Please enter a valid email address.'});
      return;
    }

    this.setState({requestAccess: false});

    let requested = [];

    let s = localStorage.getItem('RequestAccessEmails');
    if(s) 
      requested = JSON.parse(s);

    this.setState({message: 'Requesting access...'});

    let existing = requested.find((r) => r.email == this.state.email);

    if(existing && existing.count >= 2){
      setTimeout(async () => {
        this.setState({message: '', alert: 'Access has already been requested for this email address.'});
      }, 1000);
      return;
    }

    if(existing)
      existing.count++;
    else
      requested.push({email: this.state.email, count: 1});

    localStorage.setItem('RequestAccessEmails', JSON.stringify(requested));

    setTimeout(async () => {
      let response = await Server.public.requestAccess(this.state.email);
      this.setState({message: ''});

      if(response.success) 
        this.setState({alert: 'Thank you for requesting access!  We will follow up with you soon.'});
      else
        this.setState({alert: response.message});
    }, 250);
  }

  onWelcomeContinue() {
    this.setState({welcomeStart: false, asset: 'pack-portraits-illustrated'})
  }

  onWelcomeFinish() {
    this.setState({welcomeFinish: false});
  }

  onSelectAsset(assetId:string) {
    if(!assetId) {
      this.setState({alert: 'Please make a selection.'});
      return;
    }

    let asset = Database.getAsset(assetId);
    if(asset.collection == 'portraits') 
      this.setState({portrait: assetId, asset: 'pack-banners-abstract', index: -1})
    else  {
      this.welcome(this.state.name, this.state.portrait, assetId);
      this.setState({banner: assetId, asset: '', welcomeFinish: true})
    }
  }

  onSelectAssetBack() {
    if(this.state.asset == 'pack-portraits-illustrated')
      this.setState({welcomeStart: true, asset: ''})
    else
      this.setState({asset: 'pack-portraits-illustrated', index: -1})
  }

  onTabChange(tab:string) {
    this.setState({tab});

    sessionStorage.setItem('HomePageTab', tab);

    if(tab == 'activity')
      this.loadActivity();
  }

  onHideTabHint() {
    localStorage.setItem('HomePageHideTabHint', '1');
    this.forceUpdate();
  }

  onScoreFilter() {
    let scorePeriod = '';
    let gameSort = '';

    if(this.state.scorePeriod == 'today') 
      scorePeriod = 'this-month';
    else if(this.state.scorePeriod == 'this-month')
      scorePeriod = '';
    else if(this.state.scorePeriod == '') {
      gameSort = 'recent';
      scorePeriod = 'today';
    }

    this.setState({scorePeriod, gameSort});
  }

  onAssetClick(id:string) {
    sessionStorage.setItem('InventoryViewAsset', id);
    this.setState({navigate: '/inventory'});
  }

  onClaimDailyReward() {
    if(this.state.dailyGames < maxDailyGames) 
      this.setState({question: 'You can earn more coins if you play more games today.  Do you still want to claim now?'});
    else
      this.continueClaimDailyReward();
  }

  onQuestionYes() {
    this.setState({question: ''});
    this.continueClaimDailyReward();
  }

  continueClaimDailyReward() {
    this.setState({message: 'Claiming...'});

    setTimeout(async () => {
      let response = await Server.games.claimDailyReward();
      this.setState({message: ''});

      if(response.success) {
        Server.assets.addAssetToInventory('coin', response.reward);
        Server.user.addCookieToCache('DailyRewardClaim', new Date().toISOString())
        this.setState({showClaim: false, alert: `${response.reward} coins have been added to your inventory.  You can claim another reward tomorrow.`})
      }
      else
        this.setState({alert: response.message});
    }, 1000);
  }

  onDailyReset() {
    this.setState({gamesUpdate: this.state.gamesUpdate+1});
  }

  onGamesLoaded(state:any) {
    let showClaim = !state.loading;
    let dailyGames = state.scores ? state.scores.length : 0;

    if(!state.loading) {
      let claimed = false;
      let cookie = Server.user.getCookie('DailyRewardClaim');
  
      if(cookie) {
        let lastClaim = new Date(cookie);
        let now = new Date();
        let reset = new Date();
        reset.setUTCHours(16, 0, 0, 0);
        if(now > reset)
          reset.setMonth(now.getMonth(), now.getDate() + 1);
        let hoursElapsed = (reset.getTime() - lastClaim.getTime()) / 3600000; 
        claimed = (hoursElapsed < 24);
      }
  
      if(claimed)
        showClaim = false;
    }

    this.setState({showClaim, dailyGames});
  }

  renderRequestAccess() {
    return (
      <div className="modal open">
        <div className="modal-content" style={{paddingTop: '8px', position: 'relative'}}>
          <div className="site-page-column" style={{rowGap: '15px'}}>
            <div className="login-modal-header">Request Access</div>
            <div>An invitation is required to gain access.  Send us your email if you are interested in joining and we will send you an invitation.</div>
            <input type="email" placeholder="Email" value={this.state.email} onChange={(e:any)=>this.setState({email: e.currentTarget.value})}></input>
            <div><button onClick={()=>this.onRequestAccess()}>Send</button></div>
          </div>
          <BsFillXCircleFill className="site-page-header-close-button" onClick={()=>this.setState({requestAccess: false})}/>
        </div>
      </div>
    )  
  }

  renderGuest() {
    return (
      <div className="home-page">
        <PageEnvelope width="800px">
          <div className="home-page-guest">
            <div className="home-page-guest-text">
              <div style={{fontSize: '2.5em', lineHeight: '1.1em', fontWeight: 'bold'}}>
                A fun, free,<br/>game playing<br/><span style={{color: '#17B9FF'}}>community.</span>
              </div>
              <div className="site-page-row" style={{justifyContent: 'center'}}>
                <div style={{fontSize: '0.9em', fontWeight: 'thin', padding: '5px', maxWidth: '350px'}}>
                  Rediscover the joy of gaming with PlayPlace, where fun and connection come first. We’re a small company on a big mission to bring the fun back to games.
                </div>
              </div>
              <div>
                <button 
                  style={{borderRadius: '6px', width: '220px'}}
                  onClick={()=>this.setState({requestAccess: true})}
                >
                  Request Access
                </button>
              </div>
            </div>
            <div style={{backgroundColor: 'transparent', textAlign: 'center'}}>
              <img src="/phones.png" style={{width: '100%', maxWidth: '450px', backgroundColor:'transparent'}} />
            </div>
          </div>
          {this.state.requestAccess && this.renderRequestAccess()}
        </PageEnvelope>
        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
      </div>
    )
  }

  renderWelcomeStartModal() {
    return (
      <div className="modal open">
        <div className="modal-content" style={{paddingTop: '10px'}}>
          <div>
            <div style={{textAlign: 'center'}}>
              <img src="logo.png" style={{width: '180px'}} />
            </div>
            <div className="site-page-column" style={{rowGap: '20px'}}>
              <div style={{color: 'var(--panel-subtext-color'}}>Thank you for creating an account!  What name would you like to use on your PlayPlace profile?</div>
              {/* <div style={{marginTop: '5px'}}>What name would you like people to see when they view your profile?</div> */}
              <input value={this.state.name} onChange={(e:any)=>this.setState({name: e.currentTarget.value})} />
              <div>
                <button onClick={()=>this.onWelcomeContinue()}>Continue</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderWelcomeFinishModal() {
    return (
      <div className="modal open">
        <div className="modal-content" style={{paddingTop: '10px'}}>
          <div className="site-page-column">
            <div style={{textAlign: 'center'}}>
              <img src="logo.png" style={{width: '180px'}} />
            </div>
            <div>Your profile has been setup!</div>
            {/* <div>We hope you have fun playing games, competing with friends, completing collections, and sharing your stories!</div> */}
            <div style={{color: 'var(--panel-subtext-color)'}}>
              You new account also comes with <CoinIcon quantity={1000} /> that you can spend in the shop on powerups, collectibles, and more.
            </div>
            <div style={{marginTop: '5px'}}>
              <button onClick={()=>this.onWelcomeFinish()}>Lets Play</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderSelectAssetModal() {
    let asset = Database.getAsset(this.state.asset);
    let pack = Database.getPack(this.state.asset);
    let shopAsset = Database.getShopAsset(this.state.asset);
    let loot = pack.slots[0].loot;
    let assets = [];

    for(let i = 0; i < loot.length; i++) {
      let aid = loot[i].asset;
      let idx = aid.indexOf('-*');
      if(idx == -1) {
        assets.push(loot[i].asset);
      }
      else {
        let base = aid.substring(0, idx+1);
        let count = +aid.substring(idx+2);
        for(let j = 0; j < count; j++) 
          assets.push(base + (j+1).toString().padStart(3, '0'))
      }
    }
    
    let divs = [];
    let firstAsset = null;

    for(let i = 0; i < assets.length; i++) {
      let asset = Database.getAsset(assets[i]);
      let imageHeight = 'unset';
      let imageWidth = '150px';
      let borderRadius = '6px';
      let imageMargin = '0px';
      let objectFit:any = 'unset';
      let borderColor = (i == this.state.index) ? 'yellow' : 'var(--body-background-color)';

      if(!firstAsset)
        firstAsset = asset;
  
      if(asset.collection == 'portraits')
        borderRadius = '50%';
  
      if(asset.collection == 'banners') {
        imageWidth = '100%';
        imageHeight = '80px';
        objectFit = 'cover';
      }

      let key = Math.random();

      divs.push(
        <img 
          key={key}
          style={{
            // height: imageHeight, 
            width: '100%',
            borderRadius, 
            margin: imageMargin,
            objectFit: objectFit,
            border: '3px solid ' + borderColor,
            cursor: 'pointer'
          }} 
          onClick={()=>this.setState({index: i})}
          src={getAssetImage(asset)} 
        />
      )
    }

    let columns = '1fr 1fr 1fr';
    if(firstAsset.collection == 'banners')
      columns = '1fr';

    let selectedAsset = this.state.index == -1 ? null : assets[this.state.index];
    let collection = Database.getCollection(firstAsset.collection);

    let scrollKey = firstAsset.collection == 'banners' ? 1 : 0;

    return (
      <div className="modal open">
        <div className="modal-content" style={{paddingTop: '8px', position: 'relative'}}>
          <div className="site-page-column">
            <div className="site-page-row">
              <BsFillArrowLeftCircleFill  style={{fontSize: '1.2em', cursor: 'pointer', color: 'var(--panel-subtext-color)', transform: 'translateY(1px)'}} onClick={()=>this.onSelectAssetBack()}/>
              <div style={{fontSize: '1.1em', textAlign: 'left'}}>
                Select a profile {collection.noun.singular.toLowerCase()}
              </div>
            </div>
            <div key={scrollKey} className="site-page-scroll-panel" style={{maxHeight: '300px'}}>
              <div style={{padding: '10px 15px 10px 10px', display: 'grid', gridTemplateColumns: columns, columnGap: '10px', rowGap: '10px'}}>
                {divs}
              </div>
            </div>
            <div style={{marginTop: '5px'}}>
              <button onClick={()=>this.onSelectAsset(selectedAsset)}>Continue</button>
            </div>
          </div>
          {/* <BsFillXCircleFill className="site-page-header-close-button" onClick={()=>this.setState({modal: ''})}/> */}
        </div>
      </div>
    );
  }

  renderTabs() {
    let normalStyle = {
      backgroundColor: '#00000060',
      color: 'var(--panel-subtext-color)',
      padding: '8px 15px 0px 15px',
      display: 'flex',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      height: '34px',
      cursor: 'pointer'
    }

    let selectedStyle = JSON.parse(JSON.stringify(normalStyle));
    selectedStyle.backgroundColor = 'var(--panel-background-color)';
    selectedStyle.color = 'var(--panel-text-color)';

    let notifyNormalStyle = JSON.parse(JSON.stringify(normalStyle));
    notifyNormalStyle.color = 'var(--notification-background-color)';
    notifyNormalStyle.border = '2px solid var(--notification-background-color)';

    let activityStyle = this.state.tab == 'activity' ? selectedStyle : this.state.notify[0] ? notifyNormalStyle : normalStyle;
    let scoresStyle = this.state.tab == 'games' ? selectedStyle : normalStyle;
    let boostersStyle = this.state.tab == 'boosters' ? selectedStyle : normalStyle;
    let friendsStyle = this.state.tab == 'friends' ? selectedStyle : this.state.notify[1] ? notifyNormalStyle : normalStyle;
    let notificationsStyle = this.state.tab == 'notifications' ? selectedStyle : this.state.notify[2] ? notifyNormalStyle : normalStyle;
    let statsStyle = this.state.tab == 'stats' ? selectedStyle : normalStyle;

    let barStyle:any = {
      backgroundColor: 'var(--panel-background-color)',
      borderRadius: 'var(--panel-border-radius)',
      borderTopLeftRadius: '0px',
      height: '30px',
      transform: 'translateY(-3px)',
      fontSize: '0.9em',
      color: 'var(--panel-subtext-color)',
      textAlign: 'right',
      padding: '0px 5px 2px 7px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }

    let iconStyle = {
      transform: 'translateY(4px)'
    }

    let activityText = this.state.tab == 'activity' ? 'Activity' : <BsActivity  style={iconStyle}/>;
    let gamesText:any = <BsDice6Fill style={iconStyle} />;
    let notificationsText:any = <BsFillBellFill style={iconStyle} />;
    let friendsText:any = <BsFillPeopleFill style={iconStyle} />;
    let boostersText:any = <BsFillRocketTakeoffFill style={iconStyle} />;
    let statsText:any = <BsBarChartLineFill style={iconStyle} />;

    let isMobile = window.matchMedia('(max-width: 500px)').matches;
    if(this.state.tab == 'games' && !isMobile) gamesText = 'Games'
    if(this.state.tab == 'notifications' && !isMobile) notificationsText = 'Notifications'
    if(this.state.tab == 'friends' && !isMobile) friendsText = 'Friends'
    if(this.state.tab == 'boosters' && !isMobile) boostersText = 'Boosters'
    if(this.state.tab == 'stats' && !isMobile) statsText = 'Stats'

    let periods = ['today', 'this-month', ''];
    let periodNames = ['Today', 'This Month', 'All Time'];

    let buttons = null;
    if(this.state.tab == 'activity') {
      buttons = (
        <div onClick={()=>this.setState({navigate: '/activity'})}>New Post <BsCaretRightFill style={{transform: 'translateY(2px)'}} /></div>
      )
    }
    else if(this.state.tab == 'games') {
      buttons = (
        <div className="site-page-row" style={{columnGap: '15px', justifyContent: 'right'}}>
          {this.state.scorePeriod == 'today' &&
            <DailyResetPanel onUpdate={()=>this.onDailyReset()} />
          }
          <div onClick={()=>this.onScoreFilter()}>{periodNames[periods.indexOf(this.state.scorePeriod)]} <BsCaretDownFill style={{transform: 'translateY(2px)'}} /></div>
        </div>
      )
    }
    else if(this.state.tab == 'boosters') {
      buttons = (
        <div className="site-page-row" style={{justifyContent: 'right'}}>
          <div onClick={()=>this.setState({navigate: '/inventory'})}>Inventory <BsCaretRightFill style={{transform: 'translateY(2px)'}} /></div>
        </div>
      )
    }
    else if(this.state.tab == 'friends') {
      buttons = (
        <div onClick={()=>this.setState({navigate: '/friends'})}>Add Friend <BsCaretRightFill style={{transform: 'translateY(2px)'}} /></div>
      )
    }

    return (
      <div>
        <div className="site-page-row" style={{columnGap: '5px'}}>
          <div style={activityStyle} onClick={()=>this.onTabChange('activity')}>{activityText}</div>
          <div style={scoresStyle} onClick={()=>this.onTabChange('games')}>{gamesText}</div>
          <div style={boostersStyle} onClick={()=>this.onTabChange('boosters')}>{boostersText}</div>
          <div style={friendsStyle} onClick={()=>this.onTabChange('friends')}>{friendsText}</div>
          <div style={notificationsStyle} onClick={()=>this.onTabChange('notifications')}>{notificationsText}</div>
          <div style={statsStyle} onClick={()=>this.onTabChange('stats')}>{statsText}</div>
        </div>
        <div style={barStyle}>
          {buttons}
        </div>
      </div>
    )
  }

  renderActivity() {
    if(this.state.loading)
      return (<div className="site-page-panel">Loading...</div>);

    let divs:any[] = [];

    if(this.state.hasNewPosts) {
      divs.push(
        <div 
          key={Math.random()} 
          className="site-page-notification-panel" 
          style={{textAlign: 'center', cursor: 'pointer'}}
          onClick={()=>this.onShowNewPosts()}
        >
          Show New Posts
        </div>
      )
    }

    for(let i = 0; i < this.state.posts.length; i++) {
      let post = this.state.posts[i];
      divs.push(
        <ContentPanel 
          key={i}
          author={post.author}
          date={post.date}
          content={post.content}
          reply={true}
          summary={true}
          onClick={()=>this.setState({navigate: "/activity/post/" + post.id})}
        >
          <ActivityButtons 
            reposts={post.reposts} 
            reposted={post.reposted} 
            likes={post.likes} 
            liked={post.liked} 
            post={post} 
            reply={true} 
            onUpdate={()=>this.onActivityButtonsUpdate()} 
          />
        </ContentPanel>
      )
    }

    if(divs.length == 0)
      return (<div className="site-page-panel">No activity posts yet.</div>);
    
    divs.push(
      <div key={Math.random()} className="site-page-panel" style={{cursor: 'pointer'}} onClick={()=>this.setState({navigate: '/activity'})}>
        <div className="site-page-row" style={{fontSize: '0.9em', columnGap: '5px', color: 'var(--panel-subtext-color)', justifyContent: 'right'}}>
          All Posts <BsCaretRightFill style={{transform: 'translateY(2px)'}} />
        </div>
      </div>
    )

    return (
      <div className="site-page-column">
        {divs}
      </div>
    )
  }

  renderTabName(text:string, iconId:any) {
    let iconSpan = null;
    if(iconId != '') {
      let icon = React.createElement(getIcon(iconId), {style: {fontSize: '0.8em', transform: 'translateY(1px)'}});
      iconSpan = <span style={{whiteSpace: 'nowrap'}}> ({icon})</span>;
    }
    
    return(
      <span>
        {text}{iconSpan}
      </span>
    )
  }

  renderDailyClaimPanel() {
    if(!this.state.showClaim)
      return;

    let count = Math.min(8, this.state.dailyGames);

    let coins = 0;
    if(count >= 3) 
      coins = 50 + ((count-3)*15);

    return (
      <div className="site-page-panel">
        <div className="site-page-row" style={{justifyContent: 'space-between'}}>
          <div>
            {count == 0 &&
              <div>Play 3 different games and win a reward daily!</div>
            }
            {count > 0 && count < 3 &&
              <div>Play {3-count} more game{count==1?'s':''} that you haven't played yet today.</div>
            }
            {count >= 3 && count < maxDailyGames &&
              <div>Claim now and earn <CoinIcon quantity={coins}/> or play more to earn more.</div>
            }
            {count == maxDailyGames &&
              <div>You have played all {maxDailyGames} games.  Claim now to earn <CoinIcon quantity={coins}/>.</div>
            }
          </div>
          <div>
            <button disabled={count < 3} onClick={()=>this.onClaimDailyReward()}>Claim</button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />

    if(!Server.account.isLoggedIn()) 
      return this.renderGuest();

    let profile  = Server.user.getProfile();
    let backgroundImage = `linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)), url(${getBannerImage(profile)})`;
    let showInvite = localStorage.getItem('HomePageHideInvite') == null;
    let showTabHint = localStorage.getItem('HomePageHideTabHint') == null;
    let inventory = Server.assets.getInventory();

    let boosters = [];
    for(let i = 0; i < inventory.length; i++) {
      let asset = Database.getAsset(inventory[i].id);
      if(!asset) continue;
      if(asset.rarity != undefined)
        boosters.push(inventory[i]);
    }

    let assets = Database.getAssets();
    for(let i = 0; i < assets.length; i++) {
      let asset = assets[i];
      if(boosters.find((b)=>Database.getAsset(b.id).collection == asset.collection))
        continue;
      if(asset.rarity == 0)
        boosters.push({id: asset.id, quantity: 0});
    }

    boosters.sort((a, b)=>{
      if(a.quantity > b.quantity)
        return -1;
      else if(a.quantity < b.quantity)
        return 1;

      if(a.date > b.date)
        return -1;
      else if(a.date < b.date)
        return 1;

      return 0;
    });

    let stats = Server.user.parseCookie('LifetimeStats');

    return (
      <div className="site-page">
        <PageEnvelope width="600px">
          <div className="site-page-column" style={{rowGap: '15px'}}>
            <div className="home-page-header-panel" style={{backgroundImage}} onClick={()=>this.setState({navigate: '/profile'})}>
              <img 
                className="home-page-portrait" 
                src={getPortraitImage(profile)} 
              />
              <div>
                <div style={{borderRadius: '10px'}}>
                  <div style={{fontSize: '1.1em', fontWeight: 'bold'}}>{profile.name}</div>
                  <div style={{fontSize: '0.9em'}}>@{profile.slug}</div>
                </div>
              </div>
            </div>
            {showInvite && 
              <InvitePanel showClose={true} />
            }
            {showTabHint &&
              <div className="site-page-panel" style={{display: 'grid', gridTemplateColumns: '1fr min-content', backgroundColor: 'var(--header-panel-background-color)'}}>
                <span>
                  Use the tabs to switch between {this.renderTabName('Activity', '')},{' '} 
                  {this.renderTabName('Games', 'BsDice6Fill')},{' '} 
                  {this.renderTabName('Boosters', 'BsFillRocketTakeoffFill')},{' '} 
                  {this.renderTabName('Friends', 'BsFillPeopleFill')}, {' '} 
                  {this.renderTabName('Notifications', 'BsFillBellFill')}, and{' '} 
                  {this.renderTabName('Stats', 'BsBarChartLineFill')}.
                </span>
                <div style={{cursor: 'pointer'}} onClick={()=>this.onHideTabHint()}>
                  <BsFillXCircleFill style={{display: 'block', fontSize: '1.1em', marginLeft: '5px'}} />
                </div>
              </div>
            } 
            <div className="site-page-column" style={{rowGap: '7px'}}>
              {this.renderTabs()}
              {this.state.tab == 'activity' && 
                this.renderActivity()
              }
              {this.state.tab == 'games' && 
                <div className="site-page-column">
                  {this.state.scorePeriod == 'today' && this.renderDailyClaimPanel()}
                  <GamesPanel 
                    user={Server.user.getId()} 
                    period={this.state.scorePeriod} 
                    sort={this.state.gameSort} 
                    all={true} 
                    stats={this.state.scorePeriod == 'today'} 
                    update={this.state.gamesUpdate} 
                    callback={(state:any)=>this.onGamesLoaded(state)}
                  />
                </div>
              }
              {this.state.tab == 'boosters' &&
                <div className="site-page-column">
                  <InfoPanel item="HomePageHideBoostersHint" message="Boosters improve your chance at higher tier rewards when playing games.  Tap a booster to learn more!" />
                  <AssetsPanel inventory={boosters} sort={false} onClick={(id:string)=>this.onAssetClick(id)}/>
                </div>
              }
              {this.state.tab == 'notifications' &&
                <div className="site-page-column">
                  <InfoPanel item="HomePageHideNotificationsHint" message="These are your notifications about events that happen on PlayPlace." />
                  <NotificationsPanel />
                </div>
              }
              {this.state.tab == 'friends' &&
                <div className="site-page-column">
                  <InfoPanel item="HomePageHideFriendsHint" message="Invite other users on PlayPlace to be your friend, chat, and send gifts." />
                  <FriendsPanel />
                </div>
              }
              {this.state.tab == 'stats' &&
                <div className="site-page-column">
                  <InfoPanel item="HomePageHideStatsHint" message="View your lifetime total score, top score, and number of times you have played each game." />
                  <LifetimeStatsPanel stats={stats} />
                </div>
              }
            </div>
          </div>
        </PageEnvelope>
        {this.state.welcomeStart && this.renderWelcomeStartModal()}
        {this.state.welcomeFinish && this.renderWelcomeFinishModal()}
        {this.state.asset != '' && this.renderSelectAssetModal()}
        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
        <QuestionModal message={this.state.question} onYes={()=>this.onQuestionYes()} onNo={()=>this.setState({question: ''})} />
      </div>
    );
  }
}

export default HomePage;