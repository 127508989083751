import React from 'react';
import { Database } from '../util/database';
import { Navigate } from 'react-router-dom';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { Server } from '../../server/server';
import Portrait from './Portrait';
import '../pages/SitePage.css';
// import './NotificationsPanel.css';

interface NotificationsPanelProps {
}

interface NotificationsPanelState {
  loading: boolean;
  notifications: any[];
  lastRead: Date;
  navigate: string;
}

class NotificationsPanel extends React.Component<NotificationsPanelProps, NotificationsPanelState> {
  constructor(props: NotificationsPanelProps) {
    super(props);
    this.state = {
      loading: false,
      notifications: [],
      lastRead: null,
      navigate: ''
    }
  }

  componentDidMount(): void {
    let lastRead = Server.user.getLastNotificationRead();
    if(lastRead)
      this.setState({lastRead: new Date(lastRead)});
    this.loadNotifications();
  }

  async loadNotifications() {
    this.setState({loading: true});

    let response = await Server.user.getNotifications();
    let notifications = response.notifications;

    if(notifications.length > 0) 
      Server.user.setLastNotificationRead(notifications[0].date);

    // cache profiles
    let profiles = [];
    for (let i = 0; i < notifications.length; i++) 
      if(notifications[i].user && profiles.indexOf(notifications[i].user) == -1)
        profiles.push(notifications[i].user);

    await Server.public.loadProfiles(profiles);

    this.setState({notifications, loading: false});
  }

  onPortraitClick(userId:string) {
    let profile = Server.public.getProfile(userId);
    if(profile)
      this.setState({navigate: `/profile/${profile.slug}`})
  }

  onMessageClick(msg:any) {
    switch(msg.type) {
      case 'blog-comment':
        this.setState({navigate: '/blog/' + msg.slug})
        break;      
      case 'forum-post':
      case 'forum-new-topic':
        this.setState({navigate: `/forum/${msg.forum}/${msg.topic}`})
        break;      
      case 'post-like':
      case 'post-reply':
      case 'post-reply-reply':
      case 're-post':
        this.setState({navigate: '/activity/post/' + msg.post})
        break;      

      case 'follow':
      case 'unfollow': {
        let profile = Server.public.getProfile(msg.user);
        if(profile)
          this.setState({navigate: `/profile/${profile.slug}`})
        break;      
      }

      case 'friend-invite':
      case 'friend-accept':
      case 'friend-decline':
      case 'friend-remove':
        this.setState({navigate: `/friends`})
        break;      

      case 'gift-sent':
        this.setState({navigate: '/inventory'})
        break;      

      case 'lost-lead':
        this.setState({navigate: `/game/${msg.game}`})
        break;      

      case 'invite-used': {
        let profile = Server.public.getProfile(msg.user);
        this.setState({navigate: `/profile/${profile.slug}`})
        break;      
      }
    }
  }

  renderNotifications() {
    if(this.state.loading) 
      return (<div className="site-page-panel">Loading...</div>); 

    let divs = [];
    for (let i = 0; i < this.state.notifications.length; i++) {
      let notification = this.state.notifications[i];

      let user = null;
      if(notification.type == 'event-win')
        user = Server.user.getProfile();
      else
        user = Server.public.getProfile(notification.user);

      let userName = user ? user.name : 'Deleted User';
      if(notification.type == 'event-win')
        userName = 'You';

      // parse the content of notification
      let title = '';
      let content = '';

      switch(notification.type) {
        case 'post-like':
          title = 'liked your activity';
          content = notification.content;
          break;
        case 'post-reply':
          title = 'replied to your activity';
          content = `"${notification.content}"`;
          break;
        case 'post-reply-reply':
          title = 'replied to activity you also replied to';
          content = `"${notification.content}"`;
          break;
        case 're-post':
          title = 'reposted your activity';
          content = notification.content;
          break;
        case 'forum-post':
          title = 'posted in your forum topic';
          content = `"${notification.post}"`;
          break;
        case 'forum-new-topic':
          title = 'posted a new forum topic';
          content = `"${notification.title}"`;
          break;
        case 'blog-comment':
          title = `commented on "${notification.title}"`;
          content = `"${notification.comment}"`;
          break;
        case 'friend-invite':
          title = 'sent you a friend invite';
          break;
        case 'friend-accept':
          title = 'accepted your friend request';
          break;
        case 'friend-decline':
          title = 'declined your friend request';
          break;
        case 'friend-remove':
          title = 'is no longer your friend';
          break;
        case 'follow':
          title = 'followed you';
          break;
        case 'unfollow':
          title = 'stopped following you';
          break;
        case 'user-joined':
          title = ' has joined your community';
          break;
        case 'site-invite-accept':
          title = ' has accepted your invitation and joined the community';
          break;
        case 'gift-sent':
          let name = Database.getAssetName(notification.asset, true);
          title = ` sent you ${notification.quantity} ${name}`;
          break;
        case 'event-win': {
          let place = notification.place.toString();
          if(notification.place == 1) place += 'st';
          else if(notification.place == 2) place += 'nd';
          else if(notification.place == 3) place += 'rd';
          else place += 'th';
          let prizeName = Database.getAssetName(notification.asset);
          let game = Database.getGame(notification.event);
          let gameName = game ? game.name : 'Unknown';
          title = ` placed ${place} in ${gameName} and won ${notification.quantity} ${prizeName}!`
          break;
        }
        case 'lost-lead': {
          let game = Database.getGame(notification.game);
          let gameName = game ? game.name : 'Unknown';
          title = `took the lead from you in ${gameName}!`;
          break;
        }
        case 'invite-used': {
          title = ' has used your invitation to create an account.  Conisder sending them a friend request!';
          break;
        }
      }

      let unread = true;
      if(this.state.lastRead) {
        let msgDate = new Date(notification.date);
        unread = msgDate > this.state.lastRead;
      }

      divs.push(
        <div key={i} className="site-page-panel" style={{rowGap: '0px', cursor: 'pointer'}} onClick={()=>this.onMessageClick(notification)}>
          <div className="site-page-row" style={{justifyContent: 'space-between'}}>
            <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr 15px', alignItems: 'center'}}>
              {user && 
                <Portrait user={user.id} onClick={(id:string)=>this.onPortraitClick(id)}/>
              }
              <div style={{marginLeft: '8px'}}>
                <div><b>{userName}</b> {title}</div>
                {content != '' &&
                  <div className="site-page-subtext" style={{marginLeft: '10px', marginTop:'5px'}}>{content}</div>
                }
              </div>
            </div>
            {unread && 
              <div className="site-page-notify-circle">
                <BsExclamationCircleFill size="1.4em" />
              </div>
            }
          </div>
        </div>
      );
    }

    if(divs.length == 0) 
      return <div className="site-page-panel">No notifications yet.</div>;

    return divs;
  }

  render() {
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />;
    
    return (
      <div className="site-page-column">
        {this.renderNotifications()}
      </div>
    )
  }
}

export default NotificationsPanel;