import { formatTime } from "../../app/util/util";
import { GameFooter } from "../common/gameFooter";
import { GameHeader } from "../common/gameHeader";
import { UICanvas } from "../common/ui/uiCanvas";
import { UIImage } from "../common/ui/uiImage";
import { UIManager } from "../common/ui/uiManager";
import { UIPanel } from "../common/ui/uiPanel";
import { UIPanelButton } from "../common/ui/uiPanelButton";
import { UIScreen } from "../common/ui/uiScreen";
import { getRandom, getTimeString, numberWithCommas, setRandomSeed } from "../common/util";
import { Game } from "./game";

// TODO
// shake for coins
// mulitplayer

const GRID_SIZE = 4;

const LetterPoints = {
  'A': 1, 'E': 1, 'I': 1, 'O': 1, 'U': 1, 'L': 1, 'N': 1, 'S': 1, 'T': 1, 'R': 1,
  'D': 2, 'G': 2,
  'B': 3, 'C': 3, 'M': 3, 'P': 3,
  'F': 4, 'H': 4, 'V': 4, 'W': 4, 'Y': 4,
  'K': 5,
  'J': 8, 'X': 8,
  'Qu': 10, 'Z': 10
}

export class GameScreen extends UIScreen {
  protected selectedDice: any[];
  protected selecting: boolean;
  protected selectedWord: string;
  protected selectedWordValid: boolean;
  protected selectedWordPoints: number;
  protected boardPanel: UIPanel;
  protected letters: string[];
  protected words: string[];
  protected foundWords: string[];
  protected loading: boolean;
  protected score: number;
  protected seed: number;
  protected showingWord: boolean;
  protected gameTimer: any;
  protected gameOver: boolean;
  protected showWordTimer: any;
  protected bonusDice: any[];
  protected header: GameHeader;
  protected footer: GameFooter;
  protected wordCount: number;

  public build() {
    let background = new UIImage({
      url : require('./assets/background.png'),
      cover: true,
      parent : this
    });

    this.header = new GameHeader({
      game: Game.instance,
      callback: (action:string)=>{
        if(action == 'restart')
          this.startGame();
      },
      parent : this
    });

    this.boardPanel = new UIPanel({
      fitParent: {left: 15, right: 15, top: -1, bottom: -1},
      fitAspect: {x: 1, y: 1},
      maxSize: {x: 400, y: 400},
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      position : {x : 0, y : 130},
      // mouseEvents: true,
      borderColor: '#442879',
      borderWidth: 10,
      color: '#34343480',
      radius: 20,
      parent : this
    });

    this.footer = new GameFooter({
      game: Game.instance,
      callback: (id:string)=>{
        this.onPowerup(id);
      },
      parent : this
    });
  }

  public onWake() {
    super.onWake();

    this.mouseEvents = true;
    this.loading = true;
    this.letters = [];
    this.score = 0;
    this.showingWord = false;
    this.gameTimer = 0;
    this.gameOver = false;
    this.showWordTimer = null;
    this.foundWords = [];
    this.wordCount = 0;

    // this.seed = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    // this.seed = 23345;
    // let s = new Date().toISOString().split('T')[0].replaceAll('-', '');
    // this.seed = +s;
    // setRandomSeed(this.seed);

    this.header.setLabels(['WORDS', 'SCORE', 'TIME']);
    this.footer.loadPowerups();

    this.load();
  }

  protected async load(){
    Game.instance.pause();
    let url = window.location.origin + '/words.txt';
    let response = await fetch(url);
    let text = await response.text();
    this.words = text.split('\r\n');
    this.loading = false;
    this.startGame();
    Game.instance.callback('loaded');
  }

  protected startGame() {
    this.score = 0;
    this.wordCount = 0;
    this.gameTimer = 999 + (3 * 60 * 1000);
    this.gameOver = false;
    this.footer.resetPowerups();
    this.shake();

    Game.instance.pause();
    Game.instance.showWelcome(()=>{
      Game.instance.resume();
    });
  }

  protected endGame() {
    this.gameOver = true;
    this.gameTimer = 0;

    if(this.selecting) {
      this.selecting = false;
      this.selectedWord = '';
      this.selectedWordValid = false;
      this.selectedWordPoints = 0;
      this.selectedDice = [];
    }

    Game.instance.showGameOver(this.score, this.score, (action:string)=>{
      if(action == 'restart')
        this.startGame();
    });
  }

  protected rollDice() {
    // let sides = ['AACIOT', 'ABILTY', 'ABJMO1', 'ACDEMP', 'ACELRS', 'ADENVZ', 'AHMORS', 'BIFORX', 'DENOSW', 'DKNOTU', 'EEFHIY', 'EGKLUY', 'EGINTV', 'EHINPS', 'ELPSTU', 'GILRUW'];
    // let sides = ['AEANEG', 'AHSPCO', 'ASPFFK', 'OBJOAB', 'IOTMUC', 
    //   'RYVDEL', 'LREIXD', 'EIUNES', 'WNGEEH', 'LNHNRZ', 'TSTIYD', 
    //   'OWTOAT', 'ERTTYL', 'TOESSI', 'TERWHV', 'NUIHMQ'];

    // let sides = [
    //   'AAEEGN',
    //   'ABBJOO',
    //   'ACHOPS',
    //   'AFFKPS',
    //   'AOOWTT',
    //   'CIMOTU',
    //   'DEILRX',
    //   'DELRVY',
    //   'DISTTY',
    //   'EEGHNW',
    //   'EEINSV',
    //   'EHRTVW',
    //   'EIOSST',
    //   'ELTTRY',
    //   'HIMNQU',
    //   'HLNNRZ',
    //   'AAEEGN',
    //   'ACHOPS',
    //   'AFFKPS',
    //   'DEILRX',
    //   'DELRVY',
    //   'EEGHNW',
    //   'EIOSST',
    //   'HIMNQU',
    //   'HLNNRZ',
    // ]

    let sides = [
      'AAEEGN',
      'ABBJOO',
      'ACHOPS',
      'AFFKPS',
      'AOOTTW',
      'CIMOTU',
      'DEILRX',
      'DELRVY',
      'DISTTY',
      'EEGHNW',
      'EEINSU',
      'EHRTVW',
      'EIOSST',
      'ELRTTY',
      'HIMNUQ',
      'HLNNRZ'
    ]

    sides.sort(() => Math.random() - 0.5);
    
    let attempts = 0;
    let searching = true;
    while(searching) {
      attempts++;

      this.letters = [];
      for(let i = 0; i < sides.length; i++) {
        let r = Math.floor(Math.random() * 6);
        let letter = sides[i].substring(r, r+1);
        if(letter == 'Q')
          letter = 'Qu';
        this.letters.push(letter);
      }
  
      let vowels = 'AEIOU';
      let count = 0;
      for(let c of this.letters) 
        if(vowels.includes(c))
          count++;

      searching = (count <= 2 || count >= 7) && (attempts < 10);
    }

    this.bonusDice = [];
    this.rollBonusDice();
  }

  protected rollBonusDice(type:string='') {
    let bonusTypes = ['DL', 'TL', 'DW', 'TW'];
    let bonusColors = ['darkorange', 'maroon', 'blue', 'green'];

    let bonusType = bonusTypes.indexOf(type);
    if(bonusType == -1)
      bonusType = Math.floor(Math.random() * 4);

    let searching = true;
    while(searching) {
      let x = Math.floor(Math.random() * GRID_SIZE);
      let y = Math.floor(Math.random() * GRID_SIZE);
      let existing = this.bonusDice.find((d)=>d.x == x && d.y == y);
      if(!existing) {
        searching = false;
        this.bonusDice.push({
          x, y,
          type: bonusTypes[bonusType],
          color: bonusColors[bonusType]
        })
      }
    }
  }

  protected getSelectedWord() {
    let word = '';
    for(let i = 0; i < this.selectedDice.length; i++) {
      let n = this.selectedDice[i].x + (this.selectedDice[i].y * GRID_SIZE);
      word += this.letters[n];
    }
    return word;
  }

  protected getDiceFromPoint(px:number, py:number, tight:boolean):any {
    let margin = 20;
    let gap = 12;

    let cw = (this.boardPanel.width - (margin*2) - ((GRID_SIZE-1) * gap)) / GRID_SIZE;
    let ch = (this.boardPanel.height - (margin*2) - ((GRID_SIZE-1) * gap)) / GRID_SIZE;
    let sx = this.boardPanel.getScreenX() + margin;
    let sy = this.boardPanel.getScreenY() + margin;

    let hcw = tight ? (cw * 0.35) : (cw * 0.5);
    for(let y = 0; y < GRID_SIZE; y++) {
      for(let x = 0; x < GRID_SIZE; x++) {
        let cx = sx + (cw/2);
        let cy = sy + (ch/2);
        if(px >= cx-hcw && px <= cx+hcw && py >= cy-hcw && py <= cy+hcw)
          return {x, y};
        sx += cw + gap;
      }
      sx = this.boardPanel.getScreenX() + margin;
      sy += ch + gap;
    }

    return null;
  }

  public update() {
    super.update();

    if(!this.loading)
      this.drawBoard();

    if(!Game.instance.isPaused()) {
      this.gameTimer -= this.deltaTime;
      if(this.gameTimer <= 0) {
        this.gameTimer = 0;
        if(!this.showingWord && !this.gameOver)
          this.endGame();
      }
    }

    this.header.setValue(0, this.wordCount.toString());
    this.header.setValue(1, numberWithCommas(this.score));
    this.header.setValue(2, getTimeString(this.gameTimer));
  }

  public drawBoard() {
    let margin = 20;
    let gap = 12;
    let cw = (this.boardPanel.width - (margin*2) - ((GRID_SIZE-1) * gap)) / GRID_SIZE;
    let ch = (this.boardPanel.height - (margin*2) - ((GRID_SIZE-1) * gap)) / GRID_SIZE;
    // console.log(cw, ch);

    // draw dice panels
    let sx = this.boardPanel.getScreenX() + margin;
    let sy = this.boardPanel.getScreenY() + margin;
    for(let y = 0; y < GRID_SIZE; y++) {
      for(let x = 0; x < GRID_SIZE; x++) {
        UIManager.drawPanel(sx, sy+5, cw, ch, '#0000003C', 8);
        UIManager.drawPanel(sx, sy, cw, ch, 'white', 8, '#969696', 2);
        sx += cw + gap;
      }
      sx = this.boardPanel.getScreenX() + 20;
      sy += ch + gap;
    }

    // draw selected dice
    for(let i = 0; i < this.selectedDice.length; i++) {
      let cx = this.boardPanel.getScreenX() + margin + (cw/2) + (this.selectedDice[i].x * (cw + gap));
      let cy = this.boardPanel.getScreenY() + margin + (ch/2) + (this.selectedDice[i].y * (ch + gap));
      
      UIManager.drawFilledCircle(cx, cy, cw*0.42, '#5ABCDD');
      
      if(i > 0) {
        let cx2 = this.boardPanel.getScreenX() + margin + (cw/2) + (this.selectedDice[i-1].x * (cw + gap));
        let cy2 = this.boardPanel.getScreenY() + margin + (ch/2) + (this.selectedDice[i-1].y * (ch + gap));
        UIManager.ctx.lineWidth = 10;
        UIManager.ctx.strokeStyle = '#5ABCDD';
        UIManager.ctx.beginPath();
        UIManager.ctx.moveTo(cx, cy);
        UIManager.ctx.lineTo(cx2, cy2);
        UIManager.ctx.stroke();
      }
    }

    // draw selected word
    sx = this.boardPanel.getScreenX();
    sy = this.boardPanel.getScreenY();

    if(this.selectedWord.length > 0) {
      let word = this.selectedWord.toUpperCase();
      let wordWidth = UIManager.measureText(word, 'arial', 40);
      let bw = this.boardPanel.width;
      let panelColor = this.selecting ? '#00000080' : this.selectedWordValid ? '#80C95F' : '#F44444';
      UIManager.drawPanel(sx + (bw/2) - ((wordWidth+20)/2), sy - 65, wordWidth+20, 50, panelColor, 10);
      UIManager.drawText(word, sx + (bw/2), sy - 37, 'arial:bold', 40, 'center', 'white');

      if(!this.selecting && this.selectedWordValid) {
        let text = this.selectedWordPoints > 0 ? `+${this.selectedWordPoints*10}` : "Repeat";
        let fontSize = this.selectedWordPoints > 0 ? 18 : 12;
        UIManager.drawText(text, sx + (bw/2) + (wordWidth/2) + 16, sy - 37, 'arial', fontSize, 'left', 'white');
      }
    }

    // draw letters
    sx = this.boardPanel.getScreenX() + margin;
    sy = this.boardPanel.getScreenY() + margin;
    let i = 0;
    for(let y = 0; y < GRID_SIZE; y++) {
      for(let x = 0; x < GRID_SIZE; x++) {
        let letter = this.letters[i++];
        let selected = this.selectedDice.find((d)=>d.x == x && d.y == y);
        let color = selected ? 'white' : 'black';
        UIManager.drawText(letter, sx + (cw/2), sy + (ch/2) + 3, 'arial:bold', 40, 'center', color);
        UIManager.drawText(LetterPoints[letter], sx + cw - 5, sy + ch - 8, 'arial:bold', 15, 'right', 'black');

        let bonus = this.bonusDice.find((d)=>d.x == x && d.y == y);
        if(bonus) {
          // let panelWidth = UIManager.ctx.measureText(bonus.type).width;
          UIManager.drawPanel(sx-5, sy-5, cw*0.5, 18, bonus.color, 8);
          UIManager.drawText(bonus.type, sx-5+(cw*0.25), sy+5, 'arial:bold', 12, 'center', 'white');
        }

        sx += cw + gap;
      }
      sx = this.boardPanel.getScreenX() + margin;
      sy += ch + gap;
    }
  }

  public onMouseDown(x:number, y: number): void {
    if(this.showingWord) 
      this.finishShowingWord();

    let dice = this.getDiceFromPoint(x, y, false);
    if(!dice) return;
    
    this.selectedDice.push(dice);
    this.selectedWord = this.getSelectedWord();
    this.selecting = true;
  }

  public onMouseMove(x:number, y: number): void {
    if(!this.selecting) 
      return;

    let dice = this.getDiceFromPoint(x, y, true);
    if(!dice) 
      return;

    let isSelected = this.selectedDice.find((d)=>d.x == dice.x && d.y == dice.y) != null;

    if(isSelected) {
      if(this.selectedDice.length > 1) {
        let lastDice = this.selectedDice[this.selectedDice.length-2];
        if(lastDice.x == dice.x && lastDice.y == dice.y) {
          this.selectedDice.pop();
          this.selectedWord = this.getSelectedWord();
        }
      }
    }
    else {
      let lastDice = this.selectedDice[this.selectedDice.length-1];

      let valid = (
        dice.x >= lastDice.x - 1 && 
        dice.x <= lastDice.x + 1 && 
        dice.y >= lastDice.y - 1 && 
        dice.y <= lastDice.y + 1 
      )
      
      if(valid) {
        this.selectedDice.push(dice);
        this.selectedWord = this.getSelectedWord();
      }
    }
  }

  public onMouseUp(x:number, y: number): void {
    if(this.showingWord || !this.selecting)
      return;

    let word = this.getSelectedWord();
    word = word.toLowerCase();

    this.selectedWordValid = word.length > 1 && this.words.includes(word);

    if(this.selectedWordValid) {
        this.scoreWord(word);
    }

    this.selecting = false;

    if(word.length == 1) {
      this.selectedWord = '';
      this.selectedWordValid = false;
      this.selectedWordPoints = 0;
      this.selectedDice = [];
    }
    else {
      this.showingWord = true;
      this.selectedDice = [];
      this.showWordTimer = setTimeout(() => {
        this.finishShowingWord();
      }, 1000);
    }
  }

  protected scoreWord(word:string) {
    this.selectedWordPoints = 0;

    if(this.foundWords.includes(word))
      return;

    this.foundWords.push(word);

    this.wordCount++;

    let wordMultiplier = 0;

    for(let i = 0; i < this.selectedDice.length; i++) {
      let dice = this.selectedDice[i];
      let n = dice.x + (dice.y * GRID_SIZE);
      let points = LetterPoints[this.letters[n]];

      let bonus = this.bonusDice.find((d)=>d.x == dice.x && d.y == dice.y);

      if(bonus) {
        if(bonus.type == 'DL')
          points *= 2;
        else if(bonus.type == 'TL')
          points *= 3;

        if(bonus.type == 'DW') 
          wordMultiplier += 2;
        else if(bonus.type == 'TW') 
          wordMultiplier += 3;
      }

      this.selectedWordPoints += points;
    }

    if(wordMultiplier > 0)
      this.selectedWordPoints *= wordMultiplier;
  }

  protected finishShowingWord() {
    clearTimeout(this.showWordTimer);
    this.score += (this.selectedWordPoints * 10);
    this.selectedWord = '';
    this.selectedWordValid = false;
    this.selectedWordPoints = 0;
    this.showingWord = false;
  }

  protected onQuit() {
    Game.instance.quit();
  }

  protected shake() {
    this.selectedDice = [];
    this.selectedWord = '';
    this.selectedWordValid = false;
    this.selectedWordPoints = 0;
    this.selecting = false;
    this.foundWords = [];
    this.rollDice();
  }

  protected shuffle() {
    let bonusLetters = [];
    for(let i = 0; i < this.bonusDice.length; i++) {
      let n = this.bonusDice[i].x + (this.bonusDice[i].y * GRID_SIZE);
      bonusLetters.push(this.letters[n]);
    }

    for(let i = 0; i < 100; i++) {
      let r1 = Math.floor(Math.random() * this.letters.length);
      let r2 = Math.floor(Math.random() * this.letters.length);
      let t = this.letters[r1];
      this.letters[r1] = this.letters[r2];
      this.letters[r2] = t;
    }

    for(let i = 0; i < this.bonusDice.length; i++) {
      let letter = bonusLetters[i];

      let indexes = [];
      for(let j = 0; j < this.letters.length; j++)
        if(this.letters[j] == letter)
          indexes.push(j);

      let r = Math.floor(Math.random() * indexes.length);
      let n = indexes[r];
      this.bonusDice[i].y = Math.floor(n/GRID_SIZE);
      this.bonusDice[i].x = n - (this.bonusDice[i].y * GRID_SIZE);
    }
  }

  protected onPowerup(id:string) {
    if(id == 'wordsy-shake')
      this.shake();
    else if(id == 'wordsy-shuffle') 
      this.shuffle();
    else if(id == 'wordsy-triple-letter')
      this.rollBonusDice('TL');
    else if(id == 'wordsy-triple-word')
      this.rollBonusDice('TW');

    this.footer.usePowerup(id);
  }
}