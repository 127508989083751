import { UIElement } from "./uiElement";
import { UIElementOptions } from "./uiElement";
import { UICanvas } from "./uiCanvas";
import { UIPanelOptions } from "./uiPanel";
import { UIPanelButtonOptions } from "./uiPanelButton";
import { UITextOptions } from "./uiText";
import { UIInputOptions } from "./uiInput";
import { UIScrollBarOptions } from "./uiScrollBar";

export interface UIDialogOptions extends UIElementOptions {
  panel?: UIPanelOptions;
  title?: UITextOptions;
  message?: UITextOptions;
  subtext?: UITextOptions;
  button?: UIPanelButtonOptions;
  field?: {
    panel?: UIPanelOptions;
    input?: UIInputOptions;
    label?: UITextOptions;
  },
  scrollBar?: UIScrollBarOptions
}

export class UIDialog extends UIElement {
  public callback: Function;
  public popup: UIElement;

  protected options: UIDialogOptions;

  constructor(options: UIDialogOptions = {}) {
    super(options);

    this.options = options;
    this.popup = null;
    this.fitParent = {left: 0, top: 0, right: 0, bottom: 0};
    this.callback = null;
  }

  protected build() {
  }

  public show(callback:Function = null) {
    if(callback)
      this.callback = callback;
    UICanvas.instance.showDialog(this, false);
  }

  public showModal(callback:Function = null) {
    if(callback)
      this.callback = callback;
    UICanvas.instance.showDialog(this, true);
  }

  public hide() {
    UICanvas.instance.hideDialog(this);
  }

  public onWake() {
  }

  public onSleep() {
  }
}