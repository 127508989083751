import React from 'react';
import { BsFillXCircleFill, BsPencilSquare } from 'react-icons/bs';
import ContentDiv from './ContentDiv';
import { NavLink } from 'react-router-dom';
import { AiOutlineRetweet } from 'react-icons/ai';
import './ContentPanel.css';
import Portrait from './Portrait';
import { Server } from '../../server/server';


interface ContentPanelProps {
  author: string;
  date: string;
  title?: string;
  content: string;
  reply?: boolean;
  action?: string;
  onAction?: Function;
  children?: any;
  onClick?: Function;
  repost?: string;
  summary?: boolean;
}

interface ContentPanelState {
}

class ContentPanel extends React.Component<ContentPanelProps, ContentPanelState> {
  
  constructor(props: ContentPanelProps) {
    super(props);

    this.state = {
    };
  }
  
  formatDate(date:Date) {
    let now = new Date();
    let elapsed = now.getTime() - date.getTime();
    let hours = Math.floor(elapsed / 1000 / 60 / 60);
    let mins = Math.floor(elapsed / 1000 / 60);
    let secs = Math.floor(elapsed / 1000);
    

    let dateStr = '';
    if(hours >= 24) 
      dateStr = date.toLocaleString(undefined, {month: "short", day: "numeric", hour: "numeric", minute: "numeric"})
    else if(hours > 0 && hours < 24)
      dateStr = hours + 'h ago';
    else if(hours == 0 && mins > 0)
      dateStr = mins + 'm ago';
    else if(hours == 0 && mins == 0 && secs > 0)
      dateStr = secs + 's ago';
    else if(hours == 0 && mins == 0 && secs == 0)
      dateStr = 'Just Now';

    return dateStr;
  }

  onAction(e:any) {
    e.stopPropagation();
    this.props.onAction();
  }

  onClick() {
    if(this.props.onClick)
      this.props.onClick();
  }

  render() {
    let author = Server.public.getProfile(this.props.author);
    let margin = this.props.reply ? 55 : 0;
    let d = new Date(this.props.date);
    let date = this.props.reply ? this.formatDate(d) : d.toLocaleString(undefined, {month: "short", day: "numeric", hour: "numeric", minute: "numeric"});
    let slug ='@' + author.slug;
    let actionButton = null;
    
    if(this.props.action == 'delete')
      actionButton = <BsFillXCircleFill size="1.2em" />;
    else if(this.props.action == 'edit')
      actionButton = <BsPencilSquare size="1.2em" />;

    let cursor = this.props.onClick ? 'pointer' : 'unset';

    let repostAuthor = null;
    if(this.props.repost) 
      repostAuthor = Server.public.getProfile(this.props.repost);

    return (
      <div className="site-page-panel" style={{position: 'relative', rowGap: '5px', cursor, paddingRight: '15px'}} onClick={()=>this.onClick()}>

        {this.props.repost && 
          <NavLink to={'/profile/' + repostAuthor.slug} style={{textDecoration: 'none'}}>
            <div className="site-page-row" style={{alignItems: 'center', marginLeft: '25px', marginBottom: '-5px', color: 'var(--panel-subtext-color)'}}>
              <AiOutlineRetweet style={{fontSize: '1.1em'}} />
              <div style={{fontSize: '0.9em'}}>
                {repostAuthor.name} reposted
              </div>
            </div>
          </NavLink>
        }

        <div className="site-page-row">
          <NavLink to={'/profile/' + author.slug} onClick={(e)=>e.stopPropagation()}>
            <Portrait user={author.id} />
          </NavLink>
          <div className="site-page-column" style={{rowGap: '0px'}}>
            <div className="site-page-row">
              <NavLink to={'/profile/' + author.slug} style={{textDecoration: 'none'}} onClick={(e)=>e.stopPropagation()}>
                {author.name}
              </NavLink>
              <NavLink to={'/profile/' + author.slug} className="site-page-small-subtext" style={{marginBottom: '1px', textDecoration: 'none'}} onClick={(e)=>e.stopPropagation()}>
                {this.props.reply && slug}
              </NavLink>    
            </div>
            <NavLink to={'/profile/' + author.slug} className="site-page-small-subtext" style={{marginTop: this.props.reply ? '2px' : '0px', textDecoration: 'none'}}>
              {this.props.reply ? date : slug}
            </NavLink>
          </div>
        </div>

        {this.props.title && 
          <div style={{fontSize: '1.0em', marginTop: '2px'}}>{this.props.title}</div>
        }

        <div style={{marginLeft: margin + 'px', color: 'var(--panel-subtext-color)'}}>
          <ContentDiv content={this.props.content} summary={this.props.summary} />
        </div>

        {!this.props.reply && 
          <div className="site-page-subtext">{date}</div>
        }

        {this.props.children}

        {this.props.action != '' &&
          <div 
            style={{position: 'absolute', top: '8px', right: '8px', cursor: 'pointer', color: 'var(--panel-subtext-color)'}} 
            onClick={(e:any)=>this.onAction(e)}
          >
            {actionButton}
          </div>
        }

      </div>
    )
  }
}

export default ContentPanel;