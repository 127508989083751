import {UIImage} from '../common/ui/uiImage';
import { UIPanelButton } from '../common/ui/uiPanelButton';
import {UIScreen} from '../common/ui/uiScreen';
import {UIText} from '../common/ui/uiText';
import {Game} from './game';
import { UIPanel } from '../common/ui/uiPanel';
import { Server } from '../../server/server';
import { GameStyle } from './gameStyle';
import Levels from './assets/levels.json';
import { isLocalHost, numberWithCommas } from '../common/util';
import { getPortraitImage } from '../../app/util/assets';
import { UIManager } from '../common/ui/uiManager';
import { UICanvas } from '../common/ui/uiCanvas';
import { LevelDialog } from './levelDialog';

export class MapScreen extends UIScreen {

  userPortrait: UIImage;
  friendPortrait: UIImage[];
  background: UIPanel;
  scrollToBottomNextUpdate: boolean;
  lastScreenWidth: number;
  mapImages: UIImage[];
  nodes: UIImage[];
  medals: UIImage[];
  mapTopImage: UIImage;
  mapBottomImage: UIImage;
  mapPanel: UIPanel;
  mapY: number = 0;
  mouseDown:boolean = false;
  mouseDownPos:any = null;
  mouseDownTime:number = 0;
  lastMousePos:any = null;
  lastMouseDiff:number = null;
  portraits:UIImage[];
  topbar: UIPanel;
  scoreText: UIText;
  leaderboards: any[];

  public build() {
    this.mapPanel = new UIPanel({
      anchor: {x: 0.5, y: 1},
      pivot: {x: 0.5, y: 1},
      parent: this
    });

    this.mapTopImage = new UIImage({
      url: require('./assets/map-top.png'),
      stretch: true,
      parent: this.mapPanel
    });

    this.mapBottomImage = new UIImage({
      url: require('./assets/map-bottom.png'),
      stretch: true,
      parent: this.mapPanel
    });

    this.mapImages = [];
    this.nodes = [];
    this.medals = [];
    let levelNumber = 100;

    for(let i = 0; i < 10; i++) {
      let mapImage = new UIImage({
        url: require('./assets/map.png'),
        stretch: true,
        parent: this.mapPanel
      });

      this.mapImages.push(mapImage);

      for(let j = 0; j < 10; j++) {
        let image =new UIImage({
          url: require('./assets/map-marker-locked.png'),
          stretch: true,
          size: {x: 100, y: 100},
          // anchor: {x: 0.5, y: 0.5},
          pivot: {x: 0.5, y: 0.5},
          position: {x: 0, y: 0},
          parent: mapImage
        });

        let level = new UIText({
          text: levelNumber.toString(),
          color: 'white',
          fontFamily: 'verdana',
          fontSize: 20,
          shadow: {
            color: '#00000080',
            blur: 2,
            offset: {x: 2, y: 2}
          },
          anchor: {x: 0.5, y: 0.5},
          pivot: {x: 0.5, y: 0.5},
          position: {x: 0, y: -8},
          horizontalAlign: 'center',
          parent: image
        });

        let button = new UIPanelButton({
          name: levelNumber.toString(),
          text : '',
          fitParent: {left: 0, top: 0, right: 0, bottom: 0},
          callback : (button:UIPanelButton) => {
            this.onMapNode(+button.name);
          },
          panelColors: {normal: '#00000000'},
          parent : image
        });
    
        let frame = new UIImage({
          name: 'portrait',
          url: require('./assets/player-portrait-frame.png'),
          size: {x: 150/3, y: 170/3},
          stretch: true,
          anchor: {x: 0.5, y: 0.5},
          pivot: {x: 0.5, y: 0.5},
          position: {x: 2, y: -55},
          visible: false,
          parent: image
        });

        let portrait = new UIImage({
          url: getPortraitImage(null),
          fitParent: {left: 3 , top: 3, right: 4, bottom: 11},
          radius: 4,
          stretch: true,
          parent: frame
        });

        this.nodes.unshift(image);
        levelNumber--;
      }
    }

    for(let i =0 ; i < 100; i++) {
      let medal = new UIImage({
        name: 'medal',
        url: require('./assets/ranking_medal_gold.png'),
        size: {x: 20, y: 27},
        pivot: {x: 0.5, y: 0.5},
        stretch: true,
        visible: false,
        parent: this.mapPanel
      });

      this.medals.push(medal);
    }

    this.buildTopBar();

    let resetButton = new UIPanelButton({
      text: 'Reset',
      size: {x: 80, y: 40},
      position: {x: -10, y: -10},
      anchor: {x: 1, y: 1},
      pivot: {x: 1, y: 1},
      callback : (btn:UIPanelButton) => {
        this.onReset();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      borderWidth: GameStyle.dialog.button.borderWidth,
      borderColor: GameStyle.dialog.button.borderColor,
      visible: false,
      parent : this
    });

    let unlockButton = new UIPanelButton({
      text: 'Unlock',
      size: {x: 80, y: 40},
      position: {x: 10, y: -10},
      anchor: {x: 0, y: 1},
      pivot: {x: 0, y: 1},
      callback : (btn:UIPanelButton) => {
        this.onUnlock();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      borderWidth: GameStyle.dialog.button.borderWidth,
      borderColor: GameStyle.dialog.button.borderColor,
      visible: false,
      parent : this
    });
  }

  protected buildTopBar() {
    this.topbar = new UIPanel({
      fitParent: {left: 0, right: 0, top: -1, bottom: -1},
      // position: {x: , y: 10},
      size: {x: 0, y: 60},
      // color: '#00000080',
      parent : this
    });

    let backButton = new UIPanelButton({
      size: {x: 40, y: 40},
      position: {x: 10, y: 10},
      callback : (btn:UIPanelButton) => {
        Game.instance.quit();
      },
      panelColors: {normal: '#000000a0'},
      radius: GameStyle.dialog.button.radius,
      parent : this.topbar
    });

    let icon = new UIImage({
      url : require('./assets/back-button.png'),
      anchor: {x: 0.5, y: 0.5},
      pivot: {x: 0.5, y: 0.5},
      position: {x: -1, y: 0},
      scale: 0.9,
      stretch: true,
      parent : backButton
    });

    let scorePanel = new UIPanel({
      anchor: {x: 0.5, y: 0.5},
      pivot: {x: 0.5, y: 0.5},
      size: {x: 200, y: 40},
      radius: GameStyle.header.panel.radius,
      color: '#000000a0',
      parent : this.topbar
    });

    let scoreLabel = new UIText({
      text : 'TOTAL SCORE',
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      position: {x: 0, y: 2},
      fontFamily: GameStyle.header.label.fontFamily,
      fontSize: GameStyle.header.label.fontSize-3,
      color: GameStyle.header.label.color,
      parent : scorePanel
    });

    this.scoreText = new UIText({
      text : '0',
      anchor: {x: 0.5, y: 0.5},
      pivot: {x: 0.5, y: 0.5},
      position: {x: 0, y: 6},
      fontFamily: GameStyle.header.value.fontFamily,
      fontSize: GameStyle.header.value.fontSize+3,
      color: GameStyle.header.value.color,
      parent : scorePanel
    });
  }

  public onWake() {
    this.friendPortrait = [];
    this.lastScreenWidth = 0;
    this.scoreText.text = numberWithCommas(Game.instance.getTotalAdventureScore());

    this.updateMapSize();
    this.updateMapNodes();
    this.buildPortraits();
    this.loadFriends();

    this.mouseEvents = true;
  }

  protected async loadFriends() {
    let response = await Server.user.getFriendsCookie('MatchaAdventureLastLevel');
    let cookies = response.cookies; 

    for(let i = 0; i < cookies.length; i++) {
      let level = +cookies[i].value;
      let profile = Server.public.getProfile(cookies[i].user);
      this.showPortrait(level, profile);
    }

    let scoresResponse = await Server.user.getFriendsCookie('MatchaAdventureScores');
    let scoreCookies = scoresResponse.cookies; 

    let friendScores = [];
    for(let i = 0; i < scoreCookies.length; i++) {
      let scores = JSON.parse(scoreCookies[i].value);
      friendScores.push({user: scoreCookies[i].user, scores});
    }

    this.leaderboards = [];
    for(let i = 0; i < this.nodes.length; i++) {
      let leaderboard:any[] = [];

      let userScore = Game.instance.getAdventureScore(i+1);
      if(userScore > 0) {
        leaderboard.push({
          user: Server.user.getId(),
          score: userScore
        });
      }
      
      for(let j = 0; j < friendScores.length; j++) {
        if(i > friendScores[j].scores.length - 1)
          continue;

        if(friendScores[j].scores[i] == 0)
          continue;

        leaderboard.push({
          user: friendScores[j].user, 
          score: friendScores[j].scores[i]
        })
      }
      
      this.leaderboards.push(leaderboard);
    }
          
    for(let i = 0; i < this.leaderboards.length; i++) {
      let leaderboard:any[] = this.leaderboards[i];
      leaderboard.sort((a, b)=>{
        if(a.score > b.score)
          return -1;
        else if(a.score < b.score)
          return 1;
        return 0;
      })
    }

    let level = Game.instance.getAdventureLevel();

    for(let i = 0; i < this.nodes.length; i++) {
      let unlocked = (i <= level);

      let medal = this.medals[i];

      if(!unlocked) {
        medal.visible = false;
        continue;
      }

      let leaderboard = this.leaderboards[i];

      if(leaderboard.length == 1 && leaderboard[0].user == Server.user.getId()) {
        medal.visible = false;
        continue;
      }

      let place = 0;
      for(let j = 0; j < leaderboard.length; j++) {
        if(leaderboard[j].user == Server.user.getId()) {
          place = j+1;
          break;
        }
      }

      medal.visible = (place >= 1 && place <= 3);

      if(place == 1)
        medal.url = require('./assets/ranking_medal_gold.png');
      else if(place == 2)
        medal.url = require('./assets/ranking_medal_silver.png');
      else if(place == 3)
        medal.url = require('./assets/ranking_medal_bronze.png');
    }    
  }

  public scrollToCurrentNode() {
    let node = this.nodes[0];
    let level = Game.instance.getAdventureLastLevel();
    if(level == 0)
      level = Game.instance.getAdventureLevel();
    if(level > 0) 
      node = this.nodes[level-1];

    let windowHeight = UIManager.htmlCanvas.height * UIManager.canvasScale;
    let diff = Math.abs(node.getScreenY() - this.nodes[0].getScreenY());
    let offset = this.mapBottomImage.size.y + (this.mapImages[0].size.y - this.nodes[0].position.y);

    this.mapY = offset + diff - (windowHeight/2) + (this.topbar.size.y/2);
    this.constrainMapY();

    this.mapPanel.position.y = this.mapY;
  }

  protected async buildPortraits() {
    this.hideAllPortraits();
    let level = Game.instance.getAdventureLastLevel();
    if(level == 0)
      level = Game.instance.getAdventureLevel();
    this.showPortrait(level, Server.user.getProfile());
  }

  protected hideAllPortraits() {
    for(let i = 0; i < this.nodes.length; i++) {
      let frame = this.nodes[i].getChildByName('portrait') as UIImage;
      frame.visible = false;
    }    
  }

  protected showPortrait(level:number, profile:any) {
    if(level == 0)
      return;

    let node = this.nodes[level-1];
    let frame = node.getChildByName('portrait') as UIImage;
    let portrait = frame.getChild(0) as UIImage;

    if(!frame.visible) {
      frame.visible = true;
      portrait.url = getPortraitImage(profile);
    }
  }

  public update() {
    super.update();

    if(this.lastScreenWidth != UIManager.htmlCanvas.width) {
      this.lastScreenWidth = UIManager.htmlCanvas.width;
      this.updateMapSize();
    }

    let f = this.mouseDown ? 0.75 : 0.1;
    this.mapPanel.position.y += ((this.mapY - this.mapPanel.position.y) * f);
    // if(this.mapPanel.position.y < 0)
    //   this.mapPanel.position.y = 0;
  }

  protected updateMapSize() {
    let width = Math.min(600, UIManager.htmlCanvas.width) * UIManager.canvasScale;
    let height = width * (2048/1536);
    let scale = width/1536;

    let extraHeight = width * (150/1536);

    let nodePositions = [
      {x: 730, y: 120},
      {x: 984, y: 276},
      {x: 1225, y: 460},
      {x: 1150, y: 746},
      {x: 825, y: 830},
      {x: 605, y: 1020},
      {x: 725, y: 1265},
      {x: 583, y: 1512},
      {x: 279, y: 1703},
      {x: 420, y: 1970}
    ];

    for(let i = 0; i < nodePositions.length; i++) {
      nodePositions[i].x *= scale;
      nodePositions[i].y *= scale;
    }

    let mx = 0;//((UIManager.htmlCanvas.width * UIManager.canvasScale) - width) / 2;
    let my = extraHeight-1;

    this.mapTopImage.size = {x: width, y: extraHeight};
    this.mapTopImage.position = {x: mx, y: 0};

    for(let i = 0; i < this.mapImages.length; i++) {
      this.mapImages[i].size = {x: width, y: height};
      this.mapImages[i].position = {x: mx, y: my};
      my += (height-1);

      for(let j = 0; j < this.mapImages[i].childCount; j++) {
        let node = this.mapImages[i].getChild(j);
        node.position = {x: nodePositions[j].x, y: nodePositions[j].y};
        node.size = {x: 220*scale, y: 160*scale};
      }

    }

    this.mapBottomImage.size = {x: width, y: extraHeight};
    this.mapBottomImage.position = {x: mx, y: my};

    for(let i = 0; i < this.nodes.length; i++) {
      this.medals[i].position = {
        x: this.nodes[i].parent.position.x + this.nodes[i].position.x + (3 * scale),
        y: this.nodes[i].parent.position.y + this.nodes[i].position.y + (60 * scale)
      }
      this.medals[i].size = {x: 62*scale, y: 83*scale};
    }

    this.mapPanel.size = {x: width, y: my + extraHeight};
  }

  protected updateMapNodes() {
    let level = Game.instance.getAdventureLevel();

    for(let i = 0; i < this.nodes.length; i++) {
      let unlocked = (i <= level);
      if(unlocked)
        this.nodes[i].url = require('./assets/map-marker-normal.png');
      else
        this.nodes[i].url = require('./assets/map-marker-locked.png');
    }
  }

  protected onMapNode(level:number) {
    let savedLevel = Game.instance.getAdventureLevel();
    if(level > savedLevel + 1 && !isLocalHost())
      return;

    let leaderboard = this.leaderboards[level-1];
    let skipModal = leaderboard.length == 0 || (leaderboard.length == 1 && leaderboard[0].user == Server.user.getId()) 

    if(skipModal) {
      Game.instance.levelSelected = level;
      Game.instance.start();
    }
    else {
      this.showLevelDialog(level);
    }
  }

  public showLevelDialog(level:number) {
    Game.instance.levelDialog.init(level, this.leaderboards);
    Game.instance.levelDialog.showModal();
  }

  protected async onReset() {
    this.leaderboards = [];
    await Game.instance.resetAdventure();
  }

  protected async onUnlock() {
    await Game.instance.saveAdventureScore(Levels.length-1, 0, true);
  }

  public onMouseDown(x:number, y: number): void {
    this.mouseDown = true;
    this.lastMousePos = {x, y};
    this.lastMouseDiff = 0;
    this.mouseDownPos = {x, y};
    this.mouseDownTime = performance.now();
    this.mapY = this.mapPanel.position.y;
    UICanvas.instance.lockMouse(this);
  }

  public onMouseUp(x:number, y: number): void {
    this.mouseDown = false;
    if(Math.abs(this.lastMouseDiff) > 1)
      this.mapY += this.lastMouseDiff * 25;   
    this.constrainMapY();
    UICanvas.instance.unlockMouse(this);
  }

  public onMouseMove(x:number, y: number): void {
    if(!this.mouseDown) return;
    let diff = (y - this.lastMousePos.y);
    this.lastMouseDiff = diff;
    this.mapY += diff;   
    this.constrainMapY();
    this.lastMousePos = {x, y};
  }

  public onMouseWheelUp(x:number, y: number): void {
    this.mapY += 100;
    this.constrainMapY();
  }

  public onMouseWheelDown(x:number, y: number): void {
    this.mapY -= 100;
    this.constrainMapY();
  }

  protected constrainMapY() {
    if(this.mapY < 0) 
      this.mapY = 0;
    let windowHeight = UIManager.htmlCanvas.height * UIManager.canvasScale;
    if(this.mapY > this.mapPanel.height - windowHeight) 
      this.mapY = this.mapPanel.height - windowHeight;
  }
}