/**
 * A random number generator.
 *
 * Kevin Ryan
 */

// Table was generated from random atmospheric noise at random.org
let sRandomTable = [
  59576763,  964850402, 945970468, 425702901, 921367513, 537127926, 145890335, 508321418, 246160880, 486114288,
  360727618, 712552078, 32629124,  916095981, 757964281, 979388998, 834833282, 476508413, 27411981,  656977687,
  227434434, 741452512, 936277105, 683453430, 643059366, 76187854,  183288406, 969804877, 346940299, 545271154,
  816078355, 803897950, 777893621, 213054772, 790211359, 37238359,  656479723, 229814448, 229814448, 229814448,
  572122456, 884624320, 279852942, 780210820, 830058227, 941720849, 664047918, 714945388, 26766689,  346228495,
  688563538, 628285790, 904721702, 985578764, 967769261, 345956138
];

/**
 * Knuth hash (slightly modified).
 * @param str - String to hash
 */
function hashString(str: string): number {
  let hashedValue = 30744573451;
  for (let i = 0; i < str.length; i++) {
    let val = +str.charCodeAt(i);
    hashedValue += val;
    hashedValue *= 30744573451;
    hashedValue %= 274877906944;
  }
  return hashedValue;
}

export class RandomNumberGenerator {
  private randomTable: number[];
  private n24: number;
  private n55: number;

  constructor(private seedValue: number = 0) {
    this.n24 = 24;
    this.n55 = 55;
    this.seed(this.seedValue);
  }

  /**
   * Creates the initial random table using the passed in table and seed.
   * @param randomTable - Random table of values to use
   * @param seed - seed for these values.
   */
  seed(seedValue: number) {
    this.randomTable = [];
    this.n24 = 24;
    this.n55 = 55;
    this.seedValue = seedValue;
    for (let i = 0; i < sRandomTable.length; i++) {
      this.randomTable[i] = sRandomTable[i] + seedValue;
    }

    /**
     * Warming up with a least 1000 so chi-square values are better
     * Reference: http://actel.kr/_hdl/1/erm1.u-strasbg.fr/vhdl/misc/rnd_gene/random.pdf
    */
    let randCount = this.getRandMod(2048);
    let loopCount = 4000 + randCount + (seedValue % 2048);
    for (let i = 0; i < loopCount; i++) {
      this.getRand();
    }
  }

  /**
   * Resets this random number generator to its initial state.
   * @param randomTable - Random table
   */
  reset() {
    this.seed(this.seedValue);
  }

  /**
   * Gets a random number between 0 and 1,125,899,906,842,624
   * Has a period length of 2^f * (2^55 -1)
   * The value of f is 50 so ~2^105 period length
   * @returns Returns a random number.
   */
  getRand(): number {
    let random = this.randomTable[this.n55] + this.randomTable[this.n24];
    this.randomTable[this.n55] += this.randomTable[this.n24];

    // Keep numbers in our table below 2 to the 50th
    this.randomTable[this.n55] %= 1125899906842624;

    this.n55++;
    if (this.n55 === 56) {
      this.n55 = 0;
    }
    this.n24++;
    if (this.n24 === 56) {
      this.n24 = 0;
    }

    return random;
  }

  /**
   * Gets a random number between 0 and mod-1.
   * @param mod - Modulus to use
   * @returns Returns a random number between 0 and mod-1.
   */
  getRandMod(mod: number): number {
    let random = this.getRand();
    random %= mod;

    return random;
  }

  /**
   * Gets a random number between/including min and max.
   * @param min - Minimum value to return
   * @param max - Maximum value to return
   * @returns Returns a random number between/including min and max.
   */
  getRandRange(min: number, max: number): number {
    let delta = 1 + max - min;
    let random = this.getRandMod(delta);

    return random + min;
  }
}

/**
 * This class has a longer period than the normal random number generator.
 */
export class RandomNumberGeneratorHighRes {
  private readonly twoToFifty: number;
  private randGen1: RandomNumberGenerator;
  private randGen2: RandomNumberGenerator;

  constructor(private seedValue: number = 0) {
    this.twoToFifty = 1125899906842624;
    this.randGen1 = new RandomNumberGenerator(seedValue);
    let hashValue = hashString(`${seedValue}`);
    this.randGen2 = new RandomNumberGenerator(hashValue);
  }

  /**
   * Creates the initial random table using the passed in table and seed.
   * @param randomTable - Random table of values to use
   * @param seed - seed for these values.
   */
  seed(seedValue: number) {
    this.randGen1.seed(seedValue);
    let hashValue = hashString(`${seedValue}`);
    this.randGen2.seed(hashValue);
  }

  /**
   * Resets this random number generator to its initial state.
   * @param randomTable - Random table
   */
  reset() {
    this.randGen1.seed(this.seedValue);
    this.randGen2.seed(this.seedValue);
  }

  /**
   * Gets a random number between 0 and 1,125,899,906,842,624
   * @returns Returns a random number.
   */
  getRand(): number {
    let rand1 = this.randGen1.getRand();
    let rand2 = this.randGen2.getRand();

    return (rand1 % this.twoToFifty) + (rand2 % this.twoToFifty);
  }

  /**
   * Gets a random number between 0 and mod-1.
   * @param mod - Modulus to use
   * @returns Returns a random number between 0 and mod-1.
   */
  getRandMod(mod: number): number {
    let random = this.getRand();
    random %= mod;

    return random;
  }

  /**
   * Gets a random number between/including min and max.
   * @param min - Minimum value to return
   * @param max - Maximum value to return
   * @returns Returns a random number between/including min and max.
   */
  getRandRange(min: number, max: number): number {
    let delta = 1 + max - min;
    let random = this.getRandMod(delta);

    return random + min;
  }
}