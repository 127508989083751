import {Howl} from 'howler';

export interface ISoundProperties {
  src: any, volume?: number
}
;

export class SoundManager {
  public static instance: SoundManager = new SoundManager();

  public sfxOn: boolean;

  soundFileToSoundMap: Array<Howl>;
  nextSoundHandle: number;

  constructor() {
    this.sfxOn = true;
    this.soundFileToSoundMap = [];
    this.nextSoundHandle = 1;
  }

  public loadSound(props: ISoundProperties): number {
    let handle = this.nextSoundHandle;
    this.soundFileToSoundMap[handle] = new Howl(props);
    this.nextSoundHandle++;
    return handle;
  }

  public playSound(handle: number) {
    // if (!this.sfxOn) {
    //   return;
    // }

    // this.soundFileToSoundMap[handle].play();
  }
}