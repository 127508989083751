import { UIDialog, UIDialogOptions } from "./ui/uiDialog";
import { UIPanel } from "./ui/uiPanel";
import { UIPanelButton } from "./ui/uiPanelButton";
import { UIText } from "./ui/uiText";

export interface SettingsDialogOptions {
}

export class SettingsDialog extends UIDialog {
  playButton: UIPanelButton;
  backButton: UIPanelButton;
  constructor(options: UIDialogOptions) {
    super(options);
  }

  public build() {
    this.popup = new UIPanel({
      size: {x: 340, y: 320},
      anchor: {x: 0.5, y: 0.4},
      pivot: {x: 0.5, y: 0.5},
      position: {x: 0, y: 0},
      radius: this.options.panel.radius,
      color: this.options.panel.color,
      gradient: this.options.panel.gradient,
      borderColor: this.options.panel.borderColor,
      borderWidth: this.options.panel.borderWidth,
      shadow: this.options.panel.shadow,
      parent: this
    });

    let titleText = new UIText({
      text: 'GAME SETTINGS',
      position: {x: 0, y: 15},
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      horizontalAlign: 'center',
      color: this.options.title.color,
      fontFamily: this.options.title.fontFamily,
      fontSize: this.options.title.fontSize,
      fontWeight: this.options.title.fontWeight,
      shadow: this.options.title.shadow,
      parent: this.popup
    });

    this.playButton = new UIPanelButton({
      text : 'Play',
      anchor : {x : 0.5, y : 1},
      pivot : {x : 0.5, y : 1},
      size : {x : 120, y : 40},
      position : {x : 0, y : -20},
      callback : () => {
        this.onPlay();
      },
      panelColors : this.options.button.panelColors,
      radius : this.options.button.radius,
      borderWidth : this.options.button.borderWidth,
      borderColor : this.options.button.borderColor,
      textColors : this.options.button.textColors,
      fontFamily : this.options.button.fontFamily,
      fontSize : this.options.button.fontSize,
      textOffset: this.options.button.textOffset,
      parent : this.popup
    });

    this.backButton = new UIPanelButton({
      text : 'Back',
      anchor : {x : 0, y : 1},
      pivot : {x : 0, y : 1},
      size : {x : 120, y : 40},
      position : {x : 20, y : -20},
      callback : () => {
        this.onBack();
      },
      panelColors : this.options.button.panelColors,
      radius : this.options.button.radius,
      borderWidth : this.options.button.borderWidth,
      borderColor : this.options.button.borderColor,
      textColors : this.options.button.textColors,
      fontFamily : this.options.button.fontFamily,
      fontSize : this.options.button.fontSize,
      textOffset: this.options.button.textOffset,
      parent : this.popup
    });
  }

  public onWake() {
    this.backButton.visible = false;
    this.playButton.anchor.x = 0.5;
    this.playButton.pivot.x = 0.5;
    this.playButton.position.x = 0;
  }

  public showBackButton() {
    this.backButton.visible = true;
    this.playButton.anchor.x = 1;
    this.playButton.pivot.x = 1;
    this.playButton.position.x = -20;
  }

  public getSettings():any {
    return {};
  }

  protected onPlay() {
    this.hide();
    this.callback(this.getSettings());
  }

  protected onBack() {
    this.hide();
    this.callback(null);
  }
}