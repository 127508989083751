import React from 'react';
import { Database } from '../util/database';
import { Navigate } from 'react-router-dom';
import AssetPanel from './AssetPanel';
import '../pages/SitePage.css';
import './AssetsPanel.css';
import { BsCaretRightFill } from 'react-icons/bs';

interface AssetsPanelProps {
  inventory: any[]
  sort?: boolean;
  showName?: boolean;
  onClick?: Function;
}

interface AssetsPanelState {
  navigate: string;
}

class AssetsPanel extends React.Component<AssetsPanelProps, AssetsPanelState> {
  static lastInvite:any = null;

  constructor(props: AssetsPanelProps) {
    super(props);
    this.state = {
      navigate: ''
    }
  }

  componentDidMount(): void {
  }

  componentDidUpdate(prevProps: Readonly<AssetsPanelProps>, prevState: Readonly<AssetsPanelState>, snapshot?: any): void {
  }

  onAssetClick(id:string) {
    if(this.props.onClick)
      this.props.onClick(id);
  }

  renderAssets() {
    let inventory = [];

    for(let i = 0; i < this.props.inventory.length; i++) {
      let asset = Database.getAsset(this.props.inventory[i].id);
      if(!asset) continue;
      inventory.push({
        id: asset.id,
        name: asset.name,
        quantity: this.props.inventory[i].quantity,
        date: this.props.inventory[i].date,
      })
    }

    if(this.props.sort == undefined || this.props.sort == true) {
      inventory.sort((a, b)=>{
        if(a.date > b.date)
          return -1;
        else if(a.date < b.date)
          return 1;
  
        if(a.name > b.name)
          return 1;
        else if(a.name < b.name)
          return -1;
  
        return 0;
      });
    }

    let divs:any[] = [];
    let max = Math.min(20, inventory.length);
    for(let i = 0; i < max; i++) {
      let asset = Database.getAsset(inventory[i].id);
      if(!asset) continue;
      divs.push(
        <AssetPanel
          key={Math.random()}
          asset={asset}
          quantity={inventory[i].quantity}
          showQuantity={true}
          showName={this.props.showName}
          onClick={()=>this.onAssetClick(inventory[i].id)}
        />
      )
    }

    if(divs.length == 0)
      return (<div className="site-page-panel">There are no items in your inventory.</div>);

    return (
      <div className="assets-panel-grid">
        {divs}
      </div>
    )
  }

  render() {
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />;
    
    return (
      <div className="site-page-column">
        {this.renderAssets()}
      </div>
    )
  }
}

export default AssetsPanel;