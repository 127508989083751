import { UICanvas } from '../common/ui/uiCanvas';
import { UIImage } from '../common/ui/uiImage';
import { UIPanel, UIPanelOptions } from '../common/ui/uiPanel';

export class CloudsPanel extends UIPanel {
  protected clouds:UIImage[];
  protected started:boolean;
  protected nextSpawnTime:number;
  protected pauseSpawnTime:number;

  constructor(options: UIPanelOptions) {
    options.color = '#4d85bd';

    super(options);

    this.started = false;
    this.nextSpawnTime = 0;

    this.clouds = [];
    for(let i = 0; i < 5; i++) {
      let cloud = new UIImage({
        url : require('./assets/cloud' + (i+1).toString() + '.png'),
        position: {x: 0, y: 0},
        pivot: {x: 0, y: 0},
        anchor: {x: 0, y: 0},
        visible: false,
        parent : this
      });
      this.clouds.push(cloud);
    }
  }

  public start() {
    this.spawnCloud(this.clouds[0], Math.random() * (UICanvas.instance.width / 2));
    this.spawnCloud(this.clouds[1], (UICanvas.instance.width / 2) + (Math.random() * (UICanvas.instance.width / 2)));
    this.nextSpawnTime = performance.now() + (5000 + (Math.random() * 8000));
    this.started = true;
  }

  public update() {
    super.update();

    if(!this.started)
      return;

    let now = performance.now();
    if(now > this.nextSpawnTime) {
      let clouds = [];
      for(let i = 0; i < this.clouds.length; i++)
        if(!this.clouds[i].visible)
          clouds.push(this.clouds[i]);

      if(clouds.length > 0) {
        let i = Math.floor(Math.random() * clouds.length);
        this.spawnCloud(clouds[i]);
      }

      this.nextSpawnTime = now + (5000 + (Math.random() * 8000));
    }
  }

  protected spawnCloud(cloud:UIImage, cx:number = -1) {
    let cloudHeight = Math.max(100, cloud.imageHeight);
    let range = this.height - 35 - (this.height * 0.2) - cloudHeight;
    let randomY = Math.random() * range;
    let cy = 35 + randomY;
    let duration = 18000 + (Math.random() * 12000);

    if(cx == -1)
      cx = 0 - cloud.width - 20;
    else
      duration *= ((UICanvas.instance.width - cx) / UICanvas.instance.width);

    cloud.visible = true;
    cloud.slide(cx, cy, UICanvas.instance.width + 10, cy, duration, ()=>{
      cloud.visible = false;
    });
  }

  public pause() {
    for(let i = 0; i < this.clouds.length; i++) 
      if(this.clouds[i].visible)
        this.clouds[i].pauseSlide();
    this.started = false;
    this.pauseSpawnTime = this.nextSpawnTime - performance.now();
  }

  public resume() {
    for(let i = 0; i < this.clouds.length; i++) 
      if(this.clouds[i].visible)
        this.clouds[i].resumeSlide();
    this.started = true;
    this.nextSpawnTime = performance.now() + this.pauseSpawnTime;
  }
}