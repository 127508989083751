import { SoundManager } from '../common/audio/soundManager';
import { PauseDialog } from '../common/pauseDialog';
import { UIDialog } from '../common/ui/uiDialog';
import { UIPanel } from '../common/ui/uiPanel';
import { UIPanelButton } from '../common/ui/uiPanelButton';
import { UIText } from '../common/ui/uiText';
import { Game, GameMode } from './game';
import { GameStyle } from './gameStyle';

export class GameOptionsDialog extends UIDialog {
  toggleSoundButton: UIPanelButton;
  toggleAutoPlayButton: UIPanelButton;
  cardFlipButton: UIPanelButton;
  reverseLayoutButton: UIPanelButton;
  cardFlipPanel: UIPanel;
  foundationPanel: UIPanel;
  autoPlayPanel: UIPanel;
  soundPanel: UIPanel;
  popup: UIPanel;

  public build() {
    this.popup = new UIPanel({
      size: {x: 290, y: 380},
      anchor: {x: 0.5, y: 0.4},
      pivot: {x: 0.5, y: 0.5},
      position: {x: 0, y: 0},
      radius: this.options.panel.radius,
      color: this.options.panel.color,
      gradient: this.options.panel.gradient,
      borderColor: this.options.panel.borderColor,
      borderWidth: this.options.panel.borderWidth,
      shadow: this.options.panel.shadow,
      parent: this
    });

    let titleText = new UIText({
      text: 'OPTIONS',
      position: {x: 0, y: 15},
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      horizontalAlign: 'center',
      color: this.options.title.color,
      fontFamily: this.options.title.fontFamily,
      fontSize: this.options.title.fontSize,
      fontWeight: this.options.title.fontWeight,
      shadow: this.options.title.shadow,
      parent: this.popup
    });

    this.cardFlipPanel = this.buildToggle('Cards to Flip', '1', '3', 70, ()=>{this.onCardFlip()})
    this.foundationPanel = this.buildToggle('Foundation', 'Left', 'Right', 130, ()=>{this.onFoundation()})
    this.autoPlayPanel = this.buildToggle('Auto Play', 'Off', 'On', 190, ()=>{this.onAutoPlay()})
    this.soundPanel = this.buildToggle('Sound', 'Off', 'On', 250, ()=>{this.onSound()})

    let backButton = new UIPanelButton({
      text : '< Back',
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      position : {x : 0, y : 310},
      size : {x : 160, y : 40},
      callback : () => {
        this.hide();
        this.callback('back');
      },
      panelColors: this.options.button.panelColors,
      textColors: this.options.button.textColors,
      radius: this.options.button.radius,
      fontFamily: this.options.button.fontFamily,
      fontSize: this.options.button.fontSize,
      textOffset: this.options.button.textOffset,
      borderColor: this.options.button.borderColor,
      borderWidth: this.options.button.borderWidth,
      parent : this.popup
    });
  }

  public buildToggle(label:string, option1:string, option2:string, posY:number, callback:Function) {
    let panel = new UIPanel({
      position : {x : 20, y : posY},
      parent: this.popup
    });

    new UIText({
      text : label,
      color : '#ffffff',
      position : {x : 0, y : 0},
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize,
      parent : panel
    });

    new UIPanelButton({
      text : option1,
      toggle : true,
      size : {x : 60, y : 40},
      position : {x : 120, y : -10},
      callback : () => {
        callback();
      },
      panelColors: {normal: '#eb521a'},
      // panelColors : GameStyle.dialog.button.panelColors,
      radius : GameStyle.dialog.button.radius,
      borderWidth : GameStyle.dialog.button.borderWidth,
      borderColor : GameStyle.dialog.button.borderColor,
      // textColors: {normal: '#820303', pressed: '#ffffff', disabled: 'black'},
      textColors : GameStyle.dialog.button.textColors,
      fontFamily : GameStyle.dialog.button.fontFamily,
      fontSize : GameStyle.dialog.button.fontSize,
      textOffset: GameStyle.dialog.button.textOffset,
      parent : panel
    });

    new UIPanelButton({
      text : option2,
      toggle : true,
      size : {x : 60, y : 40},
      position : {x : 190, y : -10},
      callback : () => {
        callback();
      },
      panelColors: {normal: '#eb521a'},
      // panelColors : GameStyle.dialog.button.panelColors,
      radius : GameStyle.dialog.button.radius,
      borderWidth : GameStyle.dialog.button.borderWidth,
      borderColor : GameStyle.dialog.button.borderColor,
      // textColors: {normal: '#820303', pressed: '#ffffff', disabled: 'black'},
      textColors : GameStyle.dialog.button.textColors,
      fontFamily : GameStyle.dialog.button.fontFamily,
      fontSize : GameStyle.dialog.button.fontSize,
      textOffset: GameStyle.dialog.button.textOffset,
      parent : panel
    });

    return panel;
  }

  public onWake() {
    this.updateButtons();
  }

  protected onCardFlip() {
    if(Game.instance.gameMode == GameMode.Arcade)
      return;

    if(!Game.instance.solitaire.getFirstMoveMade()) {
      Game.instance.solitaire.toggleCardsPerFlip();
      window.localStorage.setItem('KlondikeCardsPerFlip', Game.instance.solitaire.getCardsPerFlip().toString());
      this.updateButtons();
      return;
    }

    Game.instance.askQuestion('The game needs to be restarted.\nContinue?', (response:string)=>{
      if(response == 'Yes') {
        let cardsPerFlip = (Game.instance.solitaire.getCardsPerFlip() == 1) ? 3 : 1;
        Game.instance.klondikeMode(cardsPerFlip);
        window.localStorage.setItem('KlondikeCardsPerFlip', Game.instance.solitaire.getCardsPerFlip().toString());
        this.updateButtons();
      }
    });
  }

  protected onFoundation() {
    Game.instance.solitaire.toggleFlipLayout();
    window.localStorage.setItem('KlondikeFlipLayout', Game.instance.solitaire.getLayoutFlipped() ? '1' : '0');
    this.updateButtons();
  }

  protected onAutoPlay() {
    Game.instance.solitaire.toggleAutoplay();
    window.localStorage.setItem('KlondikeAutoPlay', Game.instance.solitaire.getUseAutoPlay() ? '1' : '0');
    this.updateButtons();
  }

  protected onSound() {
    Game.instance.solitaire.toggleSfxOn();
    this.updateButtons();
  }

  public updateToggle(panel:UIPanel, option:number) {
    let button1 = panel.getChild(1) as UIPanelButton;
    let button2 = panel.getChild(2) as UIPanelButton;
    button1.forcePressed(option == 0);
    button2.forcePressed(option == 1);
    let normal = '#eb521a';
    let selected = '#820303';
    button1.setBorderColor((option == 0) ? selected : normal);
    button2.setBorderColor((option == 1) ? selected : normal);
  }

  public disableToggle(panel:UIPanel, option:number) {
    let button1 = panel.getChild(1) as UIPanelButton;
    let button2 = panel.getChild(2) as UIPanelButton;
    // button1.setAlpha((option == 0) ? 0.5 : 1);
    // button2.setAlpha((option == 1) ? 0.5 : 1);
    button1.disabled = true;
    button2.disabled = true;

    let button = (option == 0) ? button1 : button2;
    button.setDisabledColors('#820303', '#888888');
    button.setBorderColor('#820303');
  }

  protected updateButtons() {
    this.updateToggle(this.cardFlipPanel, Game.instance.solitaire.getCardsPerFlip() == 3 ? 1 : 0);
    this.updateToggle(this.foundationPanel, Game.instance.solitaire.getLayoutFlipped() ? 1 : 0);
    this.updateToggle(this.autoPlayPanel, Game.instance.solitaire.getUseAutoPlay() ? 1 : 0);
    this.updateToggle(this.soundPanel, SoundManager.instance.sfxOn ? 1 : 0);

    if(Game.instance.gameMode == GameMode.Arcade)
      this.disableToggle(this.cardFlipPanel, Game.instance.solitaire.getCardsPerFlip() == 3 ? 0 : 1);

    // this.cardFlipButton.text = `Flip ${Game.instance.solitaire.getCardsPerFlip()} Card${Game.instance.solitaire.getCardsPerFlip() > 1 ? 's' : ''}`;
    // this.cardFlipButton.disabled = Game.instance.gameMode == GameMode.Arcade;
    
    // this.reverseLayoutButton.text = Game.instance.solitaire.getLayoutFlipped() ? 'Foundation Right' : 'Foundation Left';

    // if(Game.instance.solitaire.useAutoPlay)
    //   this.toggleAutoPlayButton.text = 'Disable Auto Play';
    // else
    //   this.toggleAutoPlayButton.text = 'Enable Auto Play';

    // if(SoundManager.instance.sfxOn) 
    //   this.toggleSoundButton.text = 'Disable Sound';
    // else
    //   this.toggleSoundButton.text = 'Enable Sound';
  }
}