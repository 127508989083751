import { Server } from "../../server/server";
import { UIImage } from "../common/ui/uiImage";
import { UIManager } from "../common/ui/uiManager";
import { UIPanel } from "../common/ui/uiPanel";
import { UIPanelButton } from "../common/ui/uiPanelButton";
import { UIScreen } from "../common/ui/uiScreen";
import { UIText } from "../common/ui/uiText";
import { numberWithCommas } from "../common/util";
import { Game } from "./game";
import { GameStyle } from "./gameStyle";

const Neighborsoffsets = [
  [[1, 0], [0, 1], [-1, 1], [-1, 0], [-1, -1], [0, -1]],  // Even row tiles
  [[1, 0], [1, 1], [0, 1], [-1, 0], [0, -1], [1, -1]]     // Odd row tiles
];  

const GameState = { Init: 0, Ready: 1, ShootBubble: 2, RemoveCluster: 3, GameOver: 4 };
const BubbleColors = 7;

class Tile {
  public x: number;
  public y: number;
  public type: number;
  public removed: boolean;
  public shift: number;
  public velocity: number;
  public alpha: number;
  public processed: boolean;

  constructor(x: number, y: number, type: number, shift: number) {
    this.x = x;
    this.y = y;
    this.type = type;
    this.removed = false;
    this.shift = shift;
    this.velocity = 0;
    this.alpha = 1;
    this.processed = false;
  }
}

export class GameScreen extends UIScreen {
  protected scoreLabel: UIText;
  protected score: number;
  protected level: any;
  protected player: any;
  protected gameState: number;
  protected turnCounter: number;
  protected rowOffset: number;
  protected animationState: number;
  protected animationTime: number;
  protected showCluster: boolean;
  protected cluster: any[];
  protected floatingClusters: any[];
  protected aiming: boolean;

  public build() {
    let background = new UIPanel({
      fitParent: {left: 0, right: 0, top: 0, bottom: 0},
      // color: '#455AAE',
      color: '#333333',
      parent : this
    });

    this.buildHeader();
  }

  protected buildHeader() {
    let header = new UIPanel({
      fitParent: {left: 0, right: 0, top: -1, bottom: -1},
      size: {x: 0, y: 60},
      color: '#222222',
      parent : this
    });

    let playPlaceButton = new UIPanelButton({
      size: {x: 40, y: 40},
      position: {x: 10, y: 10},
      callback : (btn:UIPanelButton) => {
        Game.instance.showPlayPlaceMenu();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      borderWidth: GameStyle.dialog.button.borderWidth,
      borderColor: GameStyle.dialog.button.borderColor,
      parent : header
    });

    let playPlaceIcon = new UIImage({
      url : '/playplace.png',
      parent : playPlaceButton
    });

    let pauseButton = new UIPanelButton({
      anchor: {x: 1, y: 0},
      pivot: {x: 1, y: 0},
      size: {x: 40, y: 40},
      position: {x: -10, y: 10},
      callback : (btn:UIPanelButton) => {
        this.onPause();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      borderWidth: GameStyle.dialog.button.borderWidth,
      borderColor: GameStyle.dialog.button.borderColor,
      parent : header
    });

    let menuIcon = new UIImage({
      url : require('../common/assets/menu.png'),
      position: {x: 0, y: 1},
      parent : pauseButton
    });

    let scoreLabel = new UIText({
      text : 'SCORE',
      fontFamily : 'verdana',
      fontSize : 14,
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      position: {x: 2, y: 5},
      color: 'gray',
      parent : header
    })

    this.scoreLabel = new UIText({
      text : '',
      fontFamily : 'verdana',
      fontSize : 28,
      fontWeight: 'bold',
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      position: {x: 0, y: 22},
      color: 'white',
      parent : header
    })
  }

  public onWake() {
    super.onWake();
    this.mouseEvents = true;
    this.init();
    this.restart();
  }

  protected init() {
    this.showCluster = false;
    this.cluster = [];
    this.floatingClusters = [];
    this.aiming = false;

    this.level = {
      x: 4,           // X position
      y: 83,          // Y position
      width: 0,       // Width, gets calculated
      height: 0,      // Height, gets calculated
      columns: 9,    // Number of tile columns
      rows: 15,       // Number of tile rows
      tilewidth: 40,  // Visual width of a tile
      tileheight: 40, // Visual height of a tile
      rowheight: 34,  // Height of a row
      radius: 20,     // Bubble collision radius
      tiles: []       // The two-dimensional tile array
    };

    this.player = {
      x: 0,
      y: 0,
      angle: 0,
      tiletype: 0,
      bubble: {
        x: 0,
        y: 0,
        angle: 0,
        speed: 900,
        dropspeed: 250,
        tiletype: 0,
        visible: false
      },
      nextbubble: {
        x: 0,
        y: 0,
        tiletype: 0
      }
    }

    // init the level
    for (let i = 0; i < this.level.columns; i++) {
      this.level.tiles[i] = [];
      for (let j = 0; j < this.level.rows; j++) {
          // Define a tile type and a shift parameter for animation
          this.level.tiles[i][j] = new Tile(i, j, 0, 0);
      }
    }

    this.level.width = this.level.columns * this.level.tilewidth + this.level.tilewidth/2;
    this.level.height = (this.level.rows-1) * this.level.rowheight + this.level.tileheight;
    
    // init the player
    this.player.x = this.level.x + this.level.width/2 - this.level.tilewidth/2;
    this.player.y = this.level.y + this.level.height;
    this.player.angle = 90;
    this.player.tiletype = 0;
    this.player.nextbubble.x = this.player.x - 2 * this.level.tilewidth;
    this.player.nextbubble.y = this.player.y;    
  }

  protected restart() {
    this.score = 0;
    this.turnCounter = 0;
    this.rowOffset = 0;
    this.cluster = [];
    this.floatingClusters = [];
    this.setGameState(GameState.Ready);
    this.createLevel();
    this.nextBubble();
    this.nextBubble();
  }

  protected setGameState(s:number) {
    this.gameState = s;
    this.animationState = 0;
    this.animationTime = 0;
  }

  protected randRange(low: number, high: number) {
    return Math.floor(low + Math.random() * (high-low+1));
  }

  protected degToRad(angle: number) {
    return angle * (Math.PI / 180);
  }

  protected radToDeg(angle: number) {
    return angle * (180 / Math.PI);
  }

  protected circleIntersection(x1: number, y1: number, r1: number, x2: number, y2: number, r2: number) {
    // Calculate the distance between the centers
    var dx = x1 - x2;
    var dy = y1 - y2;
    var len = Math.sqrt(dx * dx + dy * dy);
    
    if (len < r1 + r2) {
        // Circles intersect
        return true;
    }
    
    return false;
  }

  protected getTileCoordinate(column: number, row: number) {
    var tilex = this.level.x + column * this.level.tilewidth;
    
    // X offset for odd or even rows
    if ((row + this.rowOffset) % 2) {
        tilex += this.level.tilewidth/2;
    }
    
    var tiley = this.level.y + row * this.level.rowheight;

    return { tilex: tilex, tiley: tiley };
  }

  protected getGridPosition(x: number, y: number) {
    var gridy = Math.floor((y - this.level.y) / this.level.rowheight);
    
    // Check for offset
    var xoffset = 0;
    if ((gridy + this.rowOffset) % 2) {
        xoffset = this.level.tilewidth / 2;
    }
    var gridx = Math.floor(((x - xoffset) - this.level.x) / this.level.tilewidth);
    
    return { x: gridx, y: gridy };
  }

  protected resetProcessed() {
    for (var i = 0; i < this.level.columns; i++) {
      for (var j = 0; j < this.level.rows; j++) {
        this.level.tiles[i][j].processed = false;
      }
    }
  }

  protected resetRemoved() {
    for (var i=0; i<this.level.columns; i++) {
        for (var j=0; j<this.level.rows; j++) {
          this.level.tiles[i][j].removed = false;
        }
    }
  }

  protected getNeighbors(tile) {
    var tilerow = (tile.y + this.rowOffset) % 2; // Even or odd row
    var neighbors = [];
    
    // Get the neighbor offsets for the specified tile
    var n = Neighborsoffsets[tilerow];
    
    // Get the neighbors
    for (var i=0; i<n.length; i++) {
        // Neighbor coordinate
        var nx = tile.x + n[i][0];
        var ny = tile.y + n[i][1];
        
        // Make sure the tile is valid
        if (nx >= 0 && nx < this.level.columns && ny >= 0 && ny < this.level.rows) {
            neighbors.push(this.level.tiles[nx][ny]);
        }
    }
    
    return neighbors;
  }

  protected findCluster(tx: number, ty: number, matchtype: boolean, reset: boolean, skipremoved: boolean) {
    // Reset the processed flags
    if (reset) {
      this.resetProcessed();
    }
    
    // Get the target tile. Tile coord must be valid.
    var targettile = this.level.tiles[tx][ty];
    
    // Initialize the toprocess array with the specified tile
    var toprocess = [targettile];
    targettile.processed = true;
    var foundcluster = [];

    while (toprocess.length > 0) {
        // Pop the last element from the array
        var currenttile = toprocess.pop();
        
        // Skip processed and empty tiles
        if (currenttile.type == -1) {
            continue;
        }
        
        // Skip tiles with the removed flag
        if (skipremoved && currenttile.removed) {
            continue;
        }
        
        // Check if current tile has the right type, if matchtype is true
        if (!matchtype || (currenttile.type == targettile.type)) {
            // Add current tile to the cluster
            foundcluster.push(currenttile);
            
            // Get the neighbors of the current tile
            var neighbors = this.getNeighbors(currenttile);
            
            // Check the type of each neighbor
            for (var i=0; i<neighbors.length; i++) {
                if (!neighbors[i].processed) {
                    // Add the neighbor to the toprocess array
                    toprocess.push(neighbors[i]);
                    neighbors[i].processed = true;
                }
            }
        }
    }
    
    // Return the found cluster
    return foundcluster;
  }

  protected findFloatingClusters():any[] {
    let level = this.level;

    // Reset the processed flags
    this.resetProcessed();
    
    var foundclusters = [];
    
    // Check all tiles
    for (var i=0; i<level.columns; i++) {
        for (var j=0; j<level.rows; j++) {
            var tile = level.tiles[i][j];
            if (!tile.processed) {
                // Find all attached tiles
                var foundcluster = this.findCluster(i, j, false, false, true);
                
                // There must be a tile in the cluster
                if (foundcluster.length <= 0) {
                    continue;
                }
                
                // Check if the cluster is floating
                var floating = true;
                for (var k=0; k<foundcluster.length; k++) {
                    if (foundcluster[k].y == 0) {
                        // Tile is attached to the roof
                        floating = false;
                        break;
                    }
                }
                
                if (floating) {
                    // Found a floating cluster
                    foundclusters.push(foundcluster);
                }
            }
        }
    }
    
    return foundclusters;
  }

  protected createLevel() {
    // Create a level with random tiles
    for (let j = 0; j < this.level.rows; j++) {
      let randomtile = this.randRange(0, BubbleColors-1);
      let count = 0;
      for (let i = 0; i < this.level.columns; i++) {
        if (count >= 2) {
          // Change the random tile
          let newtile = this.randRange(0, BubbleColors-1);
          
          // Make sure the new tile is different from the previous tile
          if (newtile == randomtile) {
            newtile = (newtile + 1) % BubbleColors;
          }
          randomtile = newtile;
          count = 0;
        }
        count++;
        
        if (j < Math.floor(this.level.rows/2)) {
          this.level.tiles[i][j].type = randomtile;
        } else {
          this.level.tiles[i][j].type = -1;
        }
      }
    }
  }

  protected createRow(j:number) {
    let count = 0;
    let randomtile = this.randRange(0, BubbleColors-1);

    for (let i = 0; i < this.level.columns; i++) {
      if (count >= 2) {
        // Change the random tile
        let newtile = this.randRange(0, BubbleColors-1);
        
        // Make sure the new tile is different from the previous tile
        if (newtile == randomtile) {
          newtile = (newtile + 1) % BubbleColors;
        }
        randomtile = newtile;
        count = 0;
      }
      count++;
      
      this.level.tiles[i][j].type = randomtile;
    }
  }

  protected findColors() {
    let foundcolors = [];
    let colortable = [];
    for (let i = 0; i < BubbleColors; i++) {
      colortable.push(false);
    }
    
    // Check all tiles
    for (let i = 0; i < this.level.columns; i++) {
      for (let j=0; j < this.level.rows; j++) {
        let tile = this.level.tiles[i][j];
        if (tile.type >= 0) {
          if (!colortable[tile.type]) {
            colortable[tile.type] = true;
            foundcolors.push(tile.type);
          }
        }
      }
    }
    
    return foundcolors;
  }

  protected getExistingColor() {
    let existingcolors = this.findColors();
        
    var bubbletype = 0;
    if (existingcolors.length > 0) {
        bubbletype = existingcolors[this.randRange(0, existingcolors.length-1)];
    }
    
    return bubbletype;
  }

  protected nextBubble() {
    // Set the current bubble
    this.player.tiletype = this.player.nextbubble.tiletype;
    this.player.bubble.tiletype = this.player.nextbubble.tiletype;
    this.player.bubble.x = this.player.x;
    this.player.bubble.y = this.player.y;
    this.player.bubble.visible = true;
    
    // Get a random type from the existing colors
    var nextcolor = this.getExistingColor();
    
    // Set the next bubble
    this.player.nextbubble.tiletype = nextcolor;
  }

  protected shootBubble() {
    let player = this.player;

    // Shoot the bubble in the direction of the mouse
    player.bubble.x = player.x;
    player.bubble.y = player.y;
    player.bubble.angle = player.angle;
    player.bubble.tiletype = player.tiletype;

    // Set the gamestate
    this.setGameState(GameState.ShootBubble);
  }

  protected checkGameOver() {
    if(this.gameState == GameState.GameOver)
      return true;

    // Check for game over
    for (var i=0; i<this.level.columns; i++) {
        // Check if there are bubbles in the bottom row
        if (this.level.tiles[i][this.level.rows-1].type != -1) {
            // Game over
            this.nextBubble();
            this.setGameState(GameState.GameOver);
            setTimeout(() => {
              this.showGameOver();
            }, 1500);
            return true;
        }
    }
    
    return false;
  }

  protected showGameOver() {
    Game.instance.showGameOver(this.score, this.score, (action:string)=>{
      if(action == 'restart')
        this.restart();
    });
  }

  protected addBubbles() {
    let level = this.level;

    // Move the rows downwards
    for (var i=0; i<level.columns; i++) {
        for (var j=0; j<level.rows-1; j++) {
            level.tiles[i][level.rows-1-j].type = level.tiles[i][level.rows-1-j-1].type;
        }
    }
    
    // Add a new row of bubbles at the top
    // for (var i=0; i<level.columns; i++) {
        // Add random, existing, colors
    //     level.tiles[i][0].type = this.getExistingColor();
    // }
    this.createRow(0);
  }

  protected snapBubble() {
    let player = this.player;
    let level = this.level;

    // Get the grid position
    var centerx = player.bubble.x + level.tilewidth/2;
    var centery = player.bubble.y + level.tileheight/2;
    var gridpos = this.getGridPosition(centerx, centery);

    // Make sure the grid position is valid
    if (gridpos.x < 0) {
        gridpos.x = 0;
    }
        
    if (gridpos.x >= level.columns) {
        gridpos.x = level.columns - 1;
    }

    if (gridpos.y < 0) {
        gridpos.y = 0;
    }
        
    if (gridpos.y >= level.rows) {
        gridpos.y = level.rows - 1;
    }

    // Check if the tile is empty
    var addtile = false;
    if (level.tiles[gridpos.x][gridpos.y].type != -1) {
        // Tile is not empty, shift the new tile downwards
        for (var newrow=gridpos.y+1; newrow<level.rows; newrow++) {
            if (level.tiles[gridpos.x][newrow].type == -1) {
                gridpos.y = newrow;
                addtile = true;
                break;
            }
        }
    } else {
        addtile = true;
    }

    // Add the tile to the grid
    if (addtile) {
        // Hide the player bubble
        player.bubble.visible = false;
    
        // Set the tile
        level.tiles[gridpos.x][gridpos.y].type = player.bubble.tiletype;
        
        // Check for game over
        if (this.checkGameOver()) {
            return;
        }
        
        // Find clusters
        this.cluster = this.findCluster(gridpos.x, gridpos.y, true, true, false);
        
        if (this.cluster.length >= 3) {
            // Remove the cluster
            this.setGameState(GameState.RemoveCluster);
            return;
        }
    }
    
    // No clusters found
    this.checkAddBubbles();

    // Next bubble
    this.nextBubble();
    this.setGameState(GameState.Ready);
  }

  protected checkAddBubbles() {
    let maxTurns = Math.max(10 - Math.floor(this.score/5000), 3);
    this.turnCounter++;

    if (this.turnCounter >= maxTurns) {
        // Add a row of bubbles
        this.addBubbles();
        this.turnCounter = 0;
        this.rowOffset = (this.rowOffset + 1) % 2;
        
        if (this.checkGameOver()) {
            return;
        }
    }
  }

  protected stateShootBubble(dt: number) {
    let player = this.player;
    let level = this.level;

    // Bubble is moving
    
    // Move the bubble in the direction of the mouse
    player.bubble.x += dt * player.bubble.speed * Math.cos(this.degToRad(player.bubble.angle));
    player.bubble.y += dt * player.bubble.speed * -1*Math.sin(this.degToRad(player.bubble.angle));
    
    // Handle left and right collisions with the level
    if (player.bubble.x <= level.x) {
        // Left edge
        player.bubble.angle = 180 - player.bubble.angle;
        player.bubble.x = level.x;
    } else if (player.bubble.x + level.tilewidth >= level.x + level.width) {
        // Right edge
        player.bubble.angle = 180 - player.bubble.angle;
        player.bubble.x = level.x + level.width - level.tilewidth;
    }

    // Collisions with the top of the level
    if (player.bubble.y <= level.y) {
        // Top collision
        player.bubble.y = level.y;
        this.snapBubble();
        return;
    }
    
    // Collisions with other tiles
    for (var i=0; i<level.columns; i++) {
        for (var j=0; j<level.rows; j++) {
            var tile = level.tiles[i][j];
            
            // Skip empty tiles
            if (tile.type < 0) {
                continue;
            }
            
            // Check for intersections
            var coord = this.getTileCoordinate(i, j);
            if (this.circleIntersection(player.bubble.x + level.tilewidth/2,
                                   player.bubble.y + level.tileheight/2,
                                   level.radius,
                                   coord.tilex + level.tilewidth/2,
                                   coord.tiley + level.tileheight/2,
                                   level.radius)) {
                                    
                // Intersection with a level bubble
                this.snapBubble();
                return;
            }
        }
    }
  }

  protected stateRemoveCluster(dt: number) {
    let level = this.level;

    if (this.animationState == 0) {
        this.resetRemoved();
        
        // Mark the tiles as removed
        for (var i=0; i<this.cluster.length; i++) {
            // Set the removed flag
            this.cluster[i].removed = true;
        }
        
        // Add cluster score
        this.score += this.cluster.length * 100;
        
        // Find floating clusters
        this.floatingClusters = this.findFloatingClusters();
        
        if (this.floatingClusters.length > 0) {
            // Setup drop animation
            for (var i=0; i<this.floatingClusters.length; i++) {
                for (var j=0; j<this.floatingClusters[i].length; j++) {
                    var tile = this.floatingClusters[i][j];
                    tile.shift = 0;
                    tile.shift = 1;
                    tile.velocity = this.player.bubble.dropspeed;
                    
                    this.score += 100;
                }
            }
        }
        
        this.animationState = 1;
    }
    
    if (this.animationState == 1) {
        // Pop bubbles
        var tilesleft = false;
        for (var i=0; i<this.cluster.length; i++) {
            var tile = this.cluster[i];
            
            if (tile.type >= 0) {
                tilesleft = true;
                
                // Alpha animation
                tile.alpha -= dt * 12;
                if (tile.alpha < 0) {
                    tile.alpha = 0;
                }

                if (tile.alpha == 0) {
                    tile.type = -1;
                    tile.alpha = 1;
                }
            }                
        }
        
        // Drop bubbles
        for (var i=0; i<this.floatingClusters.length; i++) {
            for (var j=0; j<this.floatingClusters[i].length; j++) {
                var tile = this.floatingClusters[i][j];
                
                if (tile.type >= 0) {
                    tilesleft = true;
                    
                    // Accelerate dropped tiles
                    tile.velocity += dt * 700;
                    tile.shift += dt * tile.velocity;
                        
                    // Alpha animation
                    tile.alpha -= dt * 3;
                    if (tile.alpha < 0) {
                        tile.alpha = 0;
                    }

                    // Check if the bubbles are past the bottom of the level
                    if (tile.alpha == 0 || (tile.y * level.rowheight + tile.shift > (level.rows - 1) * level.rowheight + level.tileheight)) {
                        tile.type = -1;
                        tile.shift = 0;
                        tile.alpha = 1;
                    }
                }

            }
        }
        
        if (!tilesleft) {
          // Next bubble
          this.nextBubble();
          
          // Check for game over
          var tilefound = false
          for (var i=0; i<level.columns; i++) {
              for (var j=0; j<level.rows; j++) {
                  if (level.tiles[i][j].type != -1) {
                      tilefound = true;
                      break;
                  }
              }
          }
          
          if (tilefound) {
            this.checkAddBubbles();
          } else {
            this.createRow(0);
            this.turnCounter = 0;
            this.rowOffset = 0;
          }

          this.setGameState(GameState.Ready);
        }
    }
  }

  protected setShootAngleFromMousePosition(x:number, y:number) {
    // Get the mouse angle
    var mouseangle = this.radToDeg(Math.atan2((this.player.y+this.level.tileheight/2) - y, x - (this.player.x+this.level.tilewidth/2)));

    // Convert range to 0, 360 degrees
    if (mouseangle < 0) {
        mouseangle = 180 + (180 + mouseangle);
    }

    // Restrict angle to 8, 172 degrees
    var lbound = 8;
    var ubound = 172;
    if (mouseangle > 90 && mouseangle < 270) {
        // Left
        if (mouseangle > ubound) {
            mouseangle = ubound;
        }
    } else {
        // Right
        if (mouseangle < lbound || mouseangle >= 270) {
            mouseangle = lbound;
        }
    }

    // Set the player angle
    this.player.angle = mouseangle;    
  }

  public update() {
    super.update();

    let levelWidth = Math.min(400, this.width-40);
    // console.log(levelWidth);
    let tileWidth = levelWidth / (this.level.columns+0.5);

    this.level.tilewidth = tileWidth;
    this.level.tileheight = tileWidth;
    this.level.rowheight = tileWidth * 0.85;
    this.level.radius = tileWidth/2;

    this.level.width = this.level.columns * this.level.tilewidth + this.level.tilewidth/2;
    this.level.height = (this.level.rows-1) * this.level.rowheight + this.level.tileheight;
    this.level.x = this.getScreenX() + (this.width/2) - (this.level.width/2);
    this.level.y = UIManager.isMobile ? 80 : 100;

    this.player.x = this.level.x + (this.level.width/2) - (this.level.tilewidth/2);
    this.player.y = this.level.y + this.level.height;
    this.player.nextbubble.x = this.player.x - 2 * this.level.tilewidth;
    this.player.nextbubble.y = this.player.y;  
    
    if(this.gameState == GameState.Ready) {
      this.player.bubble.x = this.player.x;
      this.player.bubble.y = this.player.y;
    }

    let dt = this.deltaTime / 1000;

    if (this.gameState == GameState.Ready) {
      // Game is ready for player input
    } else if (this.gameState == GameState.ShootBubble) {
      // Bubble is moving
      this.stateShootBubble(dt);
    } else if (this.gameState == GameState.RemoveCluster) {
      // Remove cluster and drop tiles
      this.stateRemoveCluster(dt);
    }

    this.scoreLabel.text = numberWithCommas(this.score);

    this.drawGame();
  }

  protected drawGame() {
    let context = UIManager.ctx;
    let level = this.level;

    let yoffset =  level.tileheight/2;
        
    // Draw level background
    context.fillStyle = "#8c8c8c";
    context.fillRect(level.x - 4, level.y - 4, level.width + 8, level.height + 4 - yoffset);

    // Render tiles
    this.drawTiles();

    // Draw level bottom
    context.fillStyle = "#656565";
    context.fillRect(level.x - 4, level.y - 4 + level.height + 4 - yoffset, level.width + 8, 2*level.tileheight + 3);
  
    // Render cluster
    if (this.showCluster) {
      this.drawCluster(this.cluster, 255, 128, 128);
      
      for (let i = 0; i < this.floatingClusters.length; i++) {
        let col = Math.floor(100 + 100 * i / this.floatingClusters.length);
        this.drawCluster(this.floatingClusters[i], col, col, col);
      }
    }

    // Render player bubble
    this.drawPlayer();

    // Game Over overlay
    if (this.gameState == GameState.GameOver) {
      context.fillStyle = "rgba(0, 0, 0, 0.8)";
      context.fillRect(level.x - 4, level.y - 4, level.width + 8, level.height + 2 * level.tileheight + 8 - yoffset);
      
      context.fillStyle = "#ffffff";
      context.font = "24px Verdana";
      this.drawCenterText("GAME OVER!", level.x, level.y + (level.height / 2), level.width);
    }
  }

  protected drawCenterText(text: string, x: number, y: number, width: number) {
    var textdim = UIManager.ctx.measureText(text);
    UIManager.ctx.fillText(text, x + (width-textdim.width)/2, y);
  }

  protected drawCluster(cluster:any[], r: number, g: number, b: number) {
    let context = UIManager.ctx;
    let level = this.level;

    for (var i=0; i<cluster.length; i++) {
        // Calculate the tile coordinates
        var coord = this.getTileCoordinate(cluster[i].x, cluster[i].y);
        
        // Draw the tile using the color
        context.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
        context.fillRect(coord.tilex+level.tilewidth/4, coord.tiley+level.tileheight/4, level.tilewidth/2, level.tileheight/2);
    }
  }

  protected drawTiles() {
    let context = UIManager.ctx;
    let level = this.level;

    // Top to bottom
    for (let j = 0; j < level.rows; j++) {
      for (let i = 0; i < level.columns; i++) {
        // Get the tile
        let tile = level.tiles[i][j];
    
        // Get the shift of the tile for animation
        let shift = tile.shift;
        
        // Calculate the tile coordinates
        let coord = this.getTileCoordinate(i, j);
        
        // Check if there is a tile present
        if (tile.type >= 0) {
          // Support transparency
          context.save();
          context.globalAlpha = tile.alpha;
          
          // Draw the tile using the color
          this.drawBubble(coord.tilex, coord.tiley + shift, tile.type);
          
          context.restore();
        }
      }
    }
  }

  protected drawPlayer() {
    let context = UIManager.ctx;
    let level = this.level;
    let player = this.player;

    var centerx = player.x + level.tilewidth/2;
    var centery = player.y + level.tileheight/2;
    
    // Draw player background circle
    context.fillStyle = "#7a7a7a";
    context.beginPath();
    context.arc(centerx, centery, level.radius+12, 0, 2*Math.PI, false);
    context.fill();
    context.lineWidth = 2;
    context.strokeStyle = "#8c8c8c";
    context.stroke();

    // Draw the angle
    if(this.aiming) {
      context.lineWidth = 3;
      context.strokeStyle = "#0000ff";
      context.beginPath();
      context.moveTo(centerx, centery);
      context.lineTo(centerx + 2*level.tilewidth * Math.cos(this.degToRad(player.angle)), centery - 2*level.tileheight * Math.sin(this.degToRad(player.angle)));
      context.stroke();
    }
    
    // Draw the next bubble
    this.drawBubble(player.nextbubble.x, player.nextbubble.y, player.nextbubble.tiletype);
    
    // Draw the bubble
    if (player.bubble.visible) {
      this.drawBubble(player.bubble.x, player.bubble.y, player.bubble.tiletype);
    }
  }

  protected drawBubble(x: number, y: number, index: number) {
    if (index < 0 || index >= BubbleColors)
        return;
    
    // Draw the bubble sprite
    // UIManager.ctx.fillStyle = 'black';
    // UIManager.ctx.fillRect(x, y, this.level.tilewidth, this.level.tileheight);
    let colors = ['red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'violet'];
    let cx = x+(this.level.tilewidth/2);
    let cy = y+(this.level.tileheight/2);
    let radius = this.level.tilewidth/2

    UIManager.drawFilledCircle(cx, cy, radius, colors[index]);
    UIManager.drawCircle(cx, cy, radius-2, '#00000040', 4);

  }

  public onMouseDown(x:number, y:number) {
    if(this.gameState == GameState.GameOver)
      return;

    if(x < this.level.x || x > this.level.x + this.level.width)
      return;

    if(y < this.level.y || y > this.level.y + this.level.height)
      return;

    this.setShootAngleFromMousePosition(x, y);

    this.aiming = true;
  }

  public onMouseMove(x:number, y:number) {
    if(this.gameState == GameState.GameOver)
      return;

    if(!this.aiming)
      return;

    this.setShootAngleFromMousePosition(x, y);
  }

  public onMouseUp(x:number, y:number) {
    if(this.gameState == GameState.GameOver)
      return;

    if(!this.aiming) return;
    
    if(this.gameState == GameState.Ready) {
      this.shootBubble();
    } else if (this.gameState == GameState.GameOver) {
      this.restart();
    }

    this.aiming = false;
  }

  protected onPause() {
    Game.instance.showPauseDialog((action:string)=>{
      if(action == 'restart') 
        this.restart();
      else if(action == 'options') {
        // what
      }
    });
  }
}