import { getPortraitImage } from '../../app/util/assets';
import { Server } from '../../server/server';
import { UIButton } from '../common/ui/uiButton';
import { UIDialog } from '../common/ui/uiDialog';
import { UIGrid } from '../common/ui/uiGrid';
import { UIImage } from '../common/ui/uiImage';
import { UIPanel } from '../common/ui/uiPanel';
import { UIPanelButton } from '../common/ui/uiPanelButton';
import { UIScrollBar } from '../common/ui/uiScrollBar';
import { UIText } from '../common/ui/uiText';
import { numberWithCommas } from '../common/util';
import { Game } from './game';
import { GameStyle } from './gameStyle';

export class LevelDialog extends UIDialog {
  protected level:number;
  protected leaderboards:any[];
  protected titleText: UIText;
  leaderboardGrid: UIGrid;
  nextButton: UIButton;
  prevButton: UIButton;

  public init(level:number, leaderboards:any[]) {
    this.level = level;
    this.leaderboards = leaderboards;
  }

  public build() {
    let popup = new UIPanel({
      size: {x: 260, y: 335},
      anchor: {x: 0.5, y: 0.4},
      pivot: {x: 0.5, y: 0.5},
      position: {x: 0, y: 0},
      radius: GameStyle.dialog.panel.radius,
      color: GameStyle.dialog.panel.color,
      gradient: GameStyle.dialog.panel.gradient,
      borderColor: GameStyle.dialog.panel.borderColor,
      borderWidth: GameStyle.dialog.panel.borderWidth,
      shadow: GameStyle.dialog.panel.shadow,
      parent: this
    });

    this.titleText = new UIText({
      text: 'LEVEL 1',
      position: {x: 0, y: 10},
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      horizontalAlign: 'center',
      color: GameStyle.dialog.title.color,
      fontFamily: GameStyle.dialog.title.fontFamily,
      fontSize: GameStyle.dialog.title.fontSize,
      fontWeight: GameStyle.dialog.title.fontWeight,
      shadow: GameStyle.dialog.title.shadow,
      parent: popup
    });

    this.nextButton = new UIButton({
      elements: {
        normal: new UIImage({
          url: require('./assets/next-button.png'),
          size: {x: 16, y: 20},
          anchor: {x: 0.5, y: 0.5},
          pivot: {x: 0.5, y: 0.5},
          stretch: true
        })
      },
      callback : () => {
        this.onNext();
      },
      pivot : {x : 0.5, y : 0},
      anchor : {x : 0.5, y : 0},
      size : {x : 40, y : 40},
      position: {x: 75, y: 3},
      parent : popup
    });

    this.prevButton = new UIButton({
      elements: {
        normal: new UIImage({
          url: require('./assets/back-button.png'),
          size: {x: 16, y: 20},
          anchor: {x: 0.5, y: 0.5},
          pivot: {x: 0.5, y: 0.5},
          stretch: true
        })
      },
      callback : () => {
        this.onBack()
      },
      pivot : {x : 0.5, y : 0},
      anchor : {x : 0.5, y : 0},
      size : {x : 40, y : 40},
      position: {x: -75, y: 3},
      parent : popup
    });

    let leaderboardPanel = new UIPanel({
      fitParent: {left: 15, right: 15, top: 45, bottom: 65},
      radius: 10,
      color: '#00000080',
      parent: popup
    });

    let scrollPanel = new UIPanel({
      fitParent: {left: 5, top: 5, right: 20, bottom: 5},
      mouseEvents : true,
      clipChildren : true,
      scrollChildren : {horizontal : false, vertical : true},
      parent : leaderboardPanel
    });

    this.leaderboardGrid = new UIGrid({
      columns : 1,
      rows : 20,
      spacing : {x : 0, y : 3},
      // margin : {left : 0, top : 5, right : 0, bottom : 5},
      fitParent : {left : 0, top : -1, right : 0, bottom : -1},
      position: {x: 0, y: 0},
      autoSizeChildren : false,
      fitToChildren : true,
      parent : scrollPanel
    });

    let scrollBar = new UIScrollBar({
      color : '#00000080',
      thumbColor : '#5c4c62',
      element : scrollPanel,
      radius : 5,
      fitParent : {left : -1, top : 5, right : -1, bottom : 5},
      size : {x : 10, y : 0},
      position : {x : -5, y : 0},
      anchor : {x : 1, y : 0},
      pivot : {x : 1, y : 0},
      parent : leaderboardPanel
    });

    let playButton = new UIPanelButton({
      text : 'Play',
      anchor : {x : 1, y : 1},
      pivot : {x : 1, y : 1},
      position : {x : -15, y : -15},
      size : {x : 100, y : 40},
      callback : () => {
        this.onPlay();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      textOffset: GameStyle.dialog.button.textOffset,
      borderColor: GameStyle.dialog.button.borderColor,
      borderWidth: GameStyle.dialog.button.borderWidth,
      parent : popup
    });

    let closeButton = new UIPanelButton({
      text : 'Close',
      anchor : {x : 0, y : 1},
      pivot : {x : 0, y : 1},
      position : {x : 15, y : -15},
      size : {x : 100, y : 40},
      callback : () => {
        this.hide();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      textOffset: GameStyle.dialog.button.textOffset,
      borderColor: GameStyle.dialog.button.borderColor,
      borderWidth: GameStyle.dialog.button.borderWidth,
      parent : popup
    });
  }

  public onWake() {
    this.updateControls();
  }

  protected updateControls() {
    this.titleText.text = 'LEVEL ' + this.level;

    let level = Game.instance.getAdventureLevel();
    this.nextButton.visible = (this.level <= level && this.level < 100);
    this.prevButton.visible = (this.level> 1);

    this.leaderboardGrid.removeAllChildren();

    let leaderboard = this.leaderboards[this.level-1];

    for(let i = 0; i < leaderboard.length; i++) {
      let profile = Server.public.getProfile(leaderboard[i].user);

      let row = new UIPanel({
        fitParent: {left: 0, right: 0, top: -1, bottom: -1},
        size: {x: 0, y: 40},
        // color: 'orange',
        parent: this.leaderboardGrid
      });
  
      let portrait = new UIImage({
        url: getPortraitImage(profile),
        position: {x: 5, y: 5},
        size: {x: 30, y: 30},
        radius: 16,
        stretch: true,
        parent: row
      });

      let scoreColor = 'white';
      let nameColor = '#ffffffa0';

      if(profile.id == Server.user.getId()) {
        nameColor = '#ffff00a0';
        scoreColor = 'yellow';
      }

      let scoreText = new UIText({
        text: numberWithCommas(leaderboard[i].score),
        position: {x: 43, y: 1},
        fontSize: 16,
        color: scoreColor,
        parent: row
      });
  
      let nameText = new UIText({
        text: profile.name,
        position: {x: 43, y: 21},
        color: nameColor,
        fontSize: 12,
        parent: row
      });
    }
  }

  protected onBack() {
    this.level--;
    this.updateControls();
  }

  protected onNext() {
    this.level++;
    this.updateControls();
  }

  protected onPlay() {
    Game.instance.levelSelected = this.level;
    Game.instance.start();
    this.hide();
  }
}