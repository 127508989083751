import React from 'react';
import '../pages/SitePage.css';
import MessageModal from '../modals/MessageModal';
import { Server } from '../../server/server';
import { BsFillXCircleFill } from 'react-icons/bs';
import AlertModal from '../modals/AlertModal';
import QuestionModal from '../modals/QuestionModal';

interface InvitePanelProps {
  showClose: boolean;
}

interface InvitePanelState {
  message: string;
  invite: any;
  question: string;
}

class InvitePanel extends React.Component<InvitePanelProps, InvitePanelState> {
  static lastInvite:any = null;

  constructor(props: InvitePanelProps) {
    super(props);
    this.state = {
      message: '',
      invite: null,
      question: ''
    }
  }

  componentDidMount(): void {
    if(InvitePanel.lastInvite)
      this.setState({invite: InvitePanel.lastInvite});
    else
      this.loadInvite();
  }

  async loadInvite() {
    let response = await Server.user.getInvite();
    
    if(response.success) 
      InvitePanel.lastInvite = response.invite;
    else
      InvitePanel.lastInvite = null;

    this.setState({invite: InvitePanel.lastInvite});
  }

  async onCopyLink() {
    if(!this.state.invite)
      return;

    let url = window.location.origin + '/invite?' + this.state.invite.id;

    let element = document.createElement("textarea");
    element.setAttribute('readonly', 'true');
    element.setAttribute('contenteditable', 'true');
    element.style.position = 'fixed'
    element.value = url;
    document.body.appendChild(element)
    element.select();
    document.execCommand("copy");
    document.body.removeChild(element);

      // await navigator.clipboard.writeText(url);

    this.setState({message: 'Link copied to clipboard!'});
    setTimeout(async () => {
      this.setState({message: ''});
    }, 1000);
  }

  onHideInvite() {
    this.setState({question: 'Do you want to hide the friend invite panel?\n\nThis panel is also available on the Friends page.'});
  }

  onQuestionYes() {
    let now = new Date();
    localStorage.setItem('HomePageHideInvite', now.toISOString());
    this.setState({invite: null});
  }

  render() {
    if(!this.state.invite)
      return null;

    if(this.state.invite.used >= this.state.invite.max)
      return null;

    let inviteUrl = `${window.location.host}/invite?${this.state.invite.id}`;
    if(inviteUrl.indexOf('www') == 0)
      inviteUrl = inviteUrl.substring(4);

    return (
      <div className="site-page-panel" style={{border: '1px solid yellow'}}>
        <div style={{display: 'grid', gridTemplateColumns: '1fr min-content'}}>
          <div>
            {this.state.invite.used == 0 ?
              <div>Invite {this.state.invite.max} friends with this link!</div> :
              <div>Invite {this.state.invite.max - this.state.invite.used} more friends!</div>
            }
            <span className="site-page-subtext">{inviteUrl}</span>
          </div>
          <div className="site-page-row">
            <button onClick={()=>this.onCopyLink()}>Copy</button>
            {this.props.showClose &&
              <div onClick={()=>this.onHideInvite()}>
                <BsFillXCircleFill style={{display: 'block', fontSize: '1.2em', color: 'var(--panel-subtext-color)'}} />
              </div>
            }
          </div>
        </div>
        <MessageModal message={this.state.message} />
        <QuestionModal message={this.state.question} onYes={()=>this.onQuestionYes()} onNo={()=>this.setState({question: ''})} />
      </div>
    )
  }
}

export default InvitePanel;