import { UIDialog } from "./ui/uiDialog";
import { UIPanel } from "./ui/uiPanel";
import { UIPanelButton } from "./ui/uiPanelButton";
import { UIText } from "./ui/uiText";

export class AlertDialog extends UIDialog {
  protected messageText:UIText;
  protected panel: UIPanel;

  public build() {
    this.panel = new UIPanel({
      size: {x: 340, y: 130},
      anchor: {x: 0.5, y: 0.35},
      pivot: {x: 0.5, y: 0.5},
      position: {x: 0, y: 0},
      radius: this.options.panel.radius,
      color: this.options.panel.color,
      gradient: this.options.panel.gradient,
      borderColor: this.options.panel.borderColor,
      borderWidth: this.options.panel.borderWidth,
      shadow: this.options.panel.shadow,
      parent: this
    });

    this.messageText = new UIText({
      text: '',
      color: this.options.message.color,
      fontFamily: this.options.message.fontFamily,
      fontSize: this.options.message.fontSize,
      position: {x: 0, y: 20},
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      horizontalAlign: 'center',
      parent: this.panel
    });

    var okButton = new UIPanelButton({
      text : 'OK',
      callback : () => {
        this.hide();
        if(this.callback)
          this.callback();
      },
      anchor : {x : 0.5, y : 1},
      pivot : {x : 0.5, y : 1},
      position : {x : 0, y : -20},
      size : {x : 120, y : 40},
      panelColors: this.options.button.panelColors,
      panelGradients: this.options.button.panelGradients,
      textColors: this.options.button.textColors,
      fontFamily: this.options.button.fontFamily,
      fontSize: this.options.button.fontSize,
      borderColor: this.options.button.borderColor,
      borderWidth: this.options.button.borderWidth,
      parent : this.panel
    });
  }

  public setMessage(message:string) {
    this.messageText.text = message;
    let lines = message.split('\n');
    this.panel.size.y = 130 + ((lines.length - 1) * (this.options.message.fontSize * 1.2));
  }
}
