import React from 'react';
import { Server } from '../../server/server';
import AlertModal from '../modals/AlertModal';
import MessageModal from '../modals/MessageModal';
import PageEnvelope from '../elements/PageEnvelope';
import { Navigate } from 'react-router-dom';
import InvitePanel from '../elements/InvitePanel';
import FriendsPanel from '../elements/FriendsPanel';
import './SitePage.css'

interface FriendsPageState {
  slug: string;
  alert: string;
  message: string;
}

class FriendsPage extends React.Component<{}, FriendsPageState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      slug: '',
      alert: '',
      message: ''
    };

    this.onSlugChange = this.onSlugChange.bind(this);
    this.onSlugKeyDown = this.onSlugKeyDown.bind(this);
  }

  onSlugChange(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({slug: e.currentTarget.value});
  };

  onSlugKeyDown(e:any): void {
    if (e.key === 'Enter') 
      this.onInvite();
  };

  async onInvite() {
    if (!this.state.slug.trim()) {
      this.setState({alert: 'Slug invalid.'});
      return;
    } 

    this.setState({message: 'Sending invite...'});

    setTimeout(async () => {
      let slug = this.state.slug;
      if(slug.substring(0, 1) == '@')
        slug = slug.substring(1);
  
      let response = await Server.user.inviteFriend(slug);

      if (response.success) {
        await Server.user.incrementCounter('friends#invite');
        await Server.public.loadProfileFromSlug(slug);
        this.setState({message: '', slug: ''});
      }
      else
        this.setState({alert: response.message, message: ''});
    }, 500);
  }

  render() {
    if(!Server.account.isLoggedIn())
      return(<Navigate replace to="/" />);

    return (
      <div className="site-page">
        <PageEnvelope width="500px">
          <div className="site-page-column">
            <InvitePanel showClose={false} />

            <div className="site-page-panel">
              <div className="site-page-row">
                @<input
                style={{width: '100%'}}
                  placeholder="Enter PlayPlace handle of user"
                  value={this.state.slug}
                  onChange={this.onSlugChange}
                  onKeyDown={this.onSlugKeyDown}>
                </input>
                <button onClick={() => this.onInvite()}>Invite</button>
              </div>
            </div>

            <FriendsPanel />
          </div>
        </PageEnvelope>

        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
      </div>
    );
  }
}

export default FriendsPage;