import { Server } from './server';
import { Service, ServiceResponse } from './service';

export class AssetsService extends Service {
  protected inventory:any[];

  constructor() {
    super();
    this.inventory = [];
    this.onAssetSent = this.onAssetSent.bind(this);
  }

  public async init(): Promise<ServiceResponse> {
    Server.network.addEventListener('asset-sent', this.onAssetSent);
    return {success: true};
  }

  public async sync(): Promise<ServiceResponse> {
    let response = await this.sendCommand('assets', 'get-inventory');

    if(response.status != 200)
      return {success: false, message: response.body.message};

    this.inventory = response.body.inventory;

    // let now = new Date().toISOString();
    // this.inventory.push({id: 'portrait-rendered-people-002', quantity: 1, date: now});
    // this.inventory.push({id: 'portrait-rendered-animals-002', quantity: 1, date: now});
    // this.inventory.push({id: 'portrait-rendered-creatures-002', quantity: 1, date: now});
    // this.inventory.push({id: 'portrait-illustrated-faces-002', quantity: 1, date: now});
    // this.inventory.push({id: 'portrait-illustrated-people-002', quantity: 1, date: now});
    // this.inventory.push({id: 'portrait-illustrated-scifi-002', quantity: 1, date: now});
    // console.warn(this.inventory);

    return {success: true};
  }

  public clear() {
    this.inventory = [];
  }


  // get a list of asset data
  public async openPack(id:string, quantity:number) {
    let response = await this.sendCommand('assets', 'open-pack', {id, quantity});

    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true, assets: response.body.assets};
  }

  public async sendAsset(id:string, quantity:number, user:string) {
    let response = await this.sendCommand('assets', 'send-asset', {id, quantity, user});

    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true};
  }

  public async consumeAsset(id:string, quantity:number) {
    let response = await this.sendCommand('assets', 'consume-asset', {id, quantity});

    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true};
  }

  public async purchaseAsset(id:string, quantity:number, pack:string = null) {
    let params:any = {id, quantity};
    if(pack)
      params.pack = pack;

    let response = await this.sendCommand('assets', 'purchase-asset', params);

    if(response.status != 200)
      return {success: false, message: response.body.message};

    let ret:any = {success: true};
    if(response.body.assets != undefined)
      ret.assets = response.body.assets;

    return ret;
  }

  public async craftAsset(id:string, quantity:number) {
    let response = await this.sendCommand('assets', 'craft-asset', {id, quantity});

    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true};
  }

  public async destroyAsset(id:string, quantity:number) {
    let response = await this.sendCommand('assets', 'destroy-asset', {id, quantity});

    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true};
  }

  // inventory
  public getInventory() {
    return this.inventory;
  }

  public getInventoryAsset(id:string) {
    for(let i = 0; i < this.inventory.length; i++) 
      if(this.inventory[i].id == id)
        return this.inventory[i];
    return null;
  }

  public addAssetToInventory(id:string, quantity:number) {
    let asset = this.getInventoryAsset(id);
    if(!asset) {
      asset = {id, quantity};
      this.inventory.push(asset);
    }
    else
      asset.quantity += quantity;
    asset.date = new Date().toISOString();
  }

  public removeAssetFromInventory(id:string, quantity:number) {
    let asset = this.getInventoryAsset(id);
    if(!asset) return;

    asset.quantity = Math.max(0, asset.quantity - quantity);
    
    if(asset.quantity == 0) {
      for(let i = 0; i < this.inventory.length; i++) {
        if(this.inventory[i].id == id) {
          this.inventory.splice(i, 1);
          break;
        }
      }
    }
  }

  public getInventoryQuantity(id:string):number {
    let asset = this.getInventoryAsset(id);
    if(!asset) return 0;
    return asset.quantity;
  }

  public hasRecipeIngredients(ingredients:any[]) {
    if(ingredients.length == 0)
      return false;
    
    let haveIngredients = true;
    for(let i = 0; i < ingredients.length; i++) {
      let ingredient = ingredients[i];
      if(ingredient.quantity > this.getInventoryQuantity(ingredient.id))
        haveIngredients = false;
    }
    return haveIngredients;
  }

  public async getFriendsInventory(id:string) {
    let response = await this.sendCommand('assets', 'get-friends-inventory', {id});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true, inventory: response.body.inventory};
  }

  // event handlers
  public onAssetSent(event:any) {
    this.addAssetToInventory(event.asset, event.quantity);
  }  

  // welcome package
  public async welcome(portrait:string, banner:string) {
    let response = await this.sendCommand('assets', 'welcome', {portrait, banner});
    
    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true, assets: response.body.assets};
  }

  public async welcomeGame(id:string) {
    let response = await this.sendCommand('assets', 'welcome-game', {id});
    
    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true, assets: response.body.assets};
  }
}