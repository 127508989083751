import { GameBase } from '../common/gameBase';
import { GameScreen } from './gameScreen';
import { GameStyle } from './gameStyle';

export class Game extends GameBase {
  public static instance:Game;

  public score: number;
  public level: number;
  public combo: number;
  public maxCombo: number;
  public gameScreen: GameScreen;
  
  constructor(canvas:HTMLCanvasElement, callback:Function) {
    super(canvas, callback, 'whacka');
    this.score = 0;
    this.level = 1;
    this.dialogStyle = GameStyle.dialog;
  }

  public load() {
    super.load();

    Game.instance = this;

    this.gameScreen = new GameScreen();
    this.gameScreen.build();
    this.gameScreen.show();

    this.callback('loaded');
  }

  public unload() {
    this.gameScreen = null;
    Game.instance = null;
    super.unload();
  }

  public pause() {
    super.pause();
    this.gameScreen.pause();
  }

  public resume() {
    this.gameScreen.resume();
    super.resume();
  }
}