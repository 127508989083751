import { Server } from './server';
import { Service, ServiceResponse } from './service';

export class ForumsService extends Service {
  protected forums:any[];

  constructor() {
    super();
    this.forums = [];
  }

  public async init(): Promise<ServiceResponse> {
    return {success: true};
  }

  public async checkPosts() {
    if(!Server.account.isLoggedIn()) 
      return;

    let response = await this.getForums();
    if(response.success)
      this.forums = response.forums;
  }

  public getUnreadForumCount() {
    let lastReadForumDate = this.getLastReadDate();

    if(!lastReadForumDate)
      return this.forums.length;

    let count = 0;
    for(let i = 0; i < this.forums.length; i++) {
      if(!this.forums[i].recent)
        continue;
      if(this.forums[i].recent.author == Server.user.getId())
        continue;
      if(this.forums[i].title != 'Announcements')
        continue;
      let d = new Date(this.forums[i].recent.date);
      if(d > lastReadForumDate)
        count++;
    }

    return count;
  }

  public getLastReadDate() {
    if(!Server.account.isLoggedIn())
      return null;
    let lastReadForumDateCookie = Server.user.getCookie('LastForumsRead');
    return lastReadForumDateCookie ? new Date(lastReadForumDateCookie) : null;
  }

  public setLastReadDate(dateStr:string) {
    let cookieDate = this.getLastReadDate();
    let date = new Date(dateStr);
    if(!cookieDate || date > cookieDate)
      Server.user.setCookie({key: 'LastForumsRead', value: dateStr});
  }

  // forums
  public async createForum(title: string, description: string) {
    let response = await this.sendCommand('forums', 'create-forum', {title, description});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true};
  }

  public async editForum(forum:string, title: string, description: string) {
    let response = await this.sendCommand('forums', 'edit-forum', {forum, title, description});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true};
  }

  public async archiveForum(forum:string) {
    let response = await this.sendCommand('forums', 'archive-forum', {forum});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true};
  }

  public async restoreForum(forum:string) {
    let response = await this.sendCommand('forums', 'restore-forum', {forum});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true};
  }

  public async hideForum(forum:string) {
    let response = await this.sendCommand('forums', 'hide-forum', {forum});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true};
  }

  public async showForum(forum:string) {
    let response = await this.sendCommand('forums', 'show-forum', {forum});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true};
  }

  public async getForums() {
    let method = Server.account.isLoggedIn() ? 'forums' : 'public';
    let response = await this.sendCommand(method, 'get-forums');

    if(response.status != 200)
      return {success: false, message: response.body.message};

    let forums = response.body.forums;

    forums.sort((a:any, b:any)=>{
      if(a.archived && !b.archived)
        return 1;
      else if(!a.archived && b.archived)
        return -1;

      if(a.recent && !b.recent)
        return -1;
      else if(!a.recent && b.recent)
        return 1;

      if(a.recent && b.recent) {
        if(a.recent.date && !b.recent.date)
          return -1;
        else if(!a.recent.date && b.recent.date)
          return 1;

        let adate = new Date(a.recent.date);
        let bdate = new Date(b.recent.date);

        if(adate > bdate)
          return -1;
        else if(adate < bdate)
          return 1;
      }

      if(a.title < b.title)
        return -1;
      else if(a.title > b.title)
        return 1;

      return 0;
    });

    return {success: true, forums};
  }

  public async getForum(forum:string) {
    let response = await this.sendCommand('forums', 'get-forum', {forum});

    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true, forum: response.body.forum};
  }

  // topics
  public async createTopic(forum:string, title: string, content: string, notify:boolean=false) {
    let response = await this.sendCommand('forums', 'create-topic', {forum, title, content, notify});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true};
  }

  public async editTopic(forum:string, topic:string, title: string) {
    let response = await this.sendCommand('forums', 'edit-topic', {forum, topic, title});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true};
  }

  public async archiveTopic(forum:string, topic:string) {
    let response = await this.sendCommand('forums', 'archive-topic', {forum, topic});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true};
  }

  public async restoreTopic(forum:string, topic:string) {
    let response = await this.sendCommand('forums', 'restore-topic', {forum, topic});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true};
  }

  public async getTopics(forum:string, params:any={}) {
    params.forum = forum;

    let response = await this.sendCommand('forums', 'get-forum-topics', params);

    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true, topics: response.body.topics, page: response.body.page};
  }

  public async getTopic(forum:string, topic:string) {
    let response = await this.sendCommand('forums', 'get-forum-topic', {forum, topic});

    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true, topic: response.body.topic};
  }

  // posts
  public async createPost(forum:string, topic:string, content: string) {
    let response = await this.sendCommand('forums', 'create-post', {forum, topic, content});
    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true, date: response.body.date};
  }

  public async editPost(forum:string, topic:string, date:string, content:string, user:string=null) {
    let params:any = {forum, topic, date, content};
    if(user)
      params.user = user;

    let response = await this.sendCommand('forums', 'edit-post', params);
    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true};
  }

  public async deletePost(forum:string, topic:string, date:string, user:string=null) {
    let params:any = {forum, topic, date};
    if(user)
      params.user = user;

    let response = await this.sendCommand('forums', 'delete-post', params);
    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true};
  }

  public async getPosts(forum:string, topic:string, params:any = {}) {
    params.forum = forum;
    params.topic = topic;

    let response = await this.sendCommand('forums', 'get-forum-topic-posts-2', params);

    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true, posts: response.body.posts, more: response.body.more};
  }

  public async getPost(forum:string, topic:string, date:string, user:string=null) {
    let params:any = {forum, topic, date};
    if(user)
      params.user = user;

    let response = await this.sendCommand('forums', 'get-post', params);
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true, post: response.body.post};
  }
}