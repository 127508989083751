import { getTimeString, numberWithCommas } from '../common/util';
import { UIButton } from '../common/ui/uiButton';
import { UICanvas } from '../common/ui/uiCanvas';
import { UIGrid } from '../common/ui/uiGrid';
import { UIPanel } from '../common/ui/uiPanel';
import { UIPanelButton } from '../common/ui/uiPanelButton';
import { UIScreen } from '../common/ui/uiScreen';
import { UIText } from '../common/ui/uiText';
import { CloudsPanel } from './cloudsPanel';
import { CountdownPanel } from './countdownPanel';
import { Game } from './game';
import { MolePanel } from './molePanel';
import { DistractorPanel } from './distractorPanel';
import { Server } from '../../server/server';
import { LeaderboardPanel } from '../common/leaderboardPanel';
import { GameFooter } from '../common/gameFooter';
import { GameHeader } from '../common/gameHeader';

/**
 * A small amount of time will be added to the clock when reached higher level.
 */
const EXTEND_TIME = 5000; // 5s

export class GameScreen extends UIScreen {
  protected clouds: CloudsPanel;
  protected moles: MolePanel[];
  protected moleSpawnTime: number;
  protected distractor: DistractorPanel;
  protected distractorSpawnTime: number;
  protected toasts: any[];
  protected grid: UIGrid;
  protected countdownPanel: CountdownPanel;
  protected started: boolean;
  protected gameOver: boolean;
  protected playWithFriendsButton: UIButton;
  protected levelToastText: UIText;
  protected playPlaceButton: UIPanelButton;
  protected leaderboard: LeaderboardPanel;
  protected footer: GameFooter;
  protected header: GameHeader;
  protected timeRemaining: number;
  protected levelToastTimer: any;
  protected levelToastCancelled: boolean;
  protected startGameTimer: any;

  public build() {
    this.mouseEvents = true;

    let background = new UIPanel({
      fitParent: {left: 0, right: 0, top: 0, bottom: 0},
      gradient: {
        startColor: '#b9967b',
        endColor: '#512701',
        type: 'vertical'
      },
      parent : this
    });

    this.clouds = new CloudsPanel({
      fitParent: {left: 0, right: 0, top: -1, bottom: -1},
      size: {x: 0, y: 180},
      parent : this
    });

    this.distractor = new DistractorPanel({
      fitParent: {left: 0, right: 0, top: -1, bottom: -1},
      position: {x: 0, y: 50},
      size: {x: 0, y: 120},
      callback: (score:number, panel: DistractorPanel, x: number, y: number) => {
        this.distractorTapped(score, x, y);
      },
      parent : this
    });

    this.header = new GameHeader({
      game: Game.instance,
      callback: (action:string)=>{
        if(action == 'restart')
          this.onRestart();
      },
      parent : this
    });

    this.leaderboard = new LeaderboardPanel({
      fitParent: {left: 0, right: 0, top: -1, bottom: -1},
      size: {x: 0, y: 40},
      anchor: {x: 0, y: 0},
      pivot: {x: 0, y: 0},
      position: {x: 0, y: 50},
      color: '#00000020',
      parent : this
    });

    this.footer = new GameFooter({
      game: Game.instance,
      callback: (action:string)=>{
        this.footer.usePowerup(action);
      },
      parent : this
    });

    this.grid = new UIGrid({
      columns: 3,
      rows: 4,
      spacing: {x: 10, y: 0},
      size: {x: 20 + (100 * 3), y: 0 + (100 * 4)},
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      position : {x : 0, y : 150},
      // color: '#ffffff80',
      parent : this
    });

    this.toasts = [];
    this.moles = [];
    for(let i = 0; i < 12; i++) {
      let mole = new MolePanel({
        name: i.toString(),
        position: {x: 20, y: 200},
        callback: (score:number, panel:MolePanel, isMissed:boolean)=>{
          this.moleTapped(score, panel, isMissed);
        },
        parent : this.grid
      });
      this.moles.push(mole);
    }

    this.levelToastText = new UIText({
      text : '',
      fontFamily : 'verdana',
      fontSize : 32,
      fontWeight: '800',
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0.5},
      position: {x: 0, y: 150},
      strokeLineWidth: 2,
      strokeColor: '#444000',
      color: '#FFF200',
      parent : this
    })

    this.countdownPanel = new CountdownPanel({
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      fitParent: {left: 0, right: 0, top: -1, bottom: -1},
      size: {x: 0, y: 100},
      position: {x: 0, y: 200},
      parent : this
    });
  }

  public onWake() {
    this.header.setLabels(['LEVEL', 'SCORE', 'TIME']);
    this.footer.loadPowerups();
    this.footer.setPowerupDurations([10, 10, 10]);
    
    let gridY = (UICanvas.instance.height - 400) * 0.60;
    this.grid.position.y = gridY;
    this.clouds.size.y = gridY + 30;
    this.distractor.size.y = gridY + 30 - this.distractor.position.y;
    this.levelToastText.visible = false;
    this.levelToastText.position.y = this.distractor.position.y + (this.distractor.size.y/2);
    this.levelToastCancelled = false;

    if(Game.instance.isMultiplayer()) {
      this.leaderboard.connect(Game.instance.getPlaceHost());
      this.leaderboard.visible = true;
    }
    else
      this.leaderboard.visible = false;

    this.clouds.start();
    this.resetGame();

    Game.instance.showWelcome(()=>{
      this.startGame();
    });
  }

  public onSleep() {
    clearTimeout(this.levelToastTimer);
    this.leaderboard.disconnect();
    super.onSleep();
  }

  public update() {
    super.update();

    this.header.setValue(0, Game.instance.level.toString());
    this.header.setValue(2, getTimeString(this.timeRemaining));
    
    if(Game.instance.isPaused() || this.gameOver || !this.started || this.levelToastText.visible) 
      return;

    this.timeRemaining -= this.deltaTime;
    if(this.timeRemaining < 0) {
      this.timeRemaining = 0;
      if(!this.gameOver)
        this.onGameOver();        
    }

    let now = performance.now();
    
    if(now >= this.moleSpawnTime) {
      let moles = [];
      for(let i = 0; i < this.moles.length; i++)
        if(this.moles[i].hidden)
          moles.push(this.moles[i]);

      if(moles.length == 0)
        return;

      let i = Math.floor(Math.random() * moles.length);
      moles[i].show(this.footer.isPowerupActive('whacka-slow-motion'), this.getMoleSpawnRate());

      this.scheduleNextMole();
    }

    for(let i = 0; i < this.toasts.length; i++) {
      let t = this.toasts[i];
      t.text.position.x += (t.vx * this.deltaTime);
      t.text.position.y += (t.vy * this.deltaTime);
      t.vy += (0.0005 * this.deltaTime);
      if(t.text.getAlpha() == 0) {
        t.text.parent.removeChild(t.text);
        this.toasts.splice(i, 1);
        i--;
      }
    }

    // show distractor
    if (this.distractor.hidden && now >= this.distractorSpawnTime) {
      this.distractor.show();
      this.scheduleNextDistractor();
    }
  }

  protected resetGame() {
    Game.instance.score = 0;
    Game.instance.level = 1;
    this.header.setValue(1, '0');
    this.started = false;
    this.gameOver = false;
    this.timeRemaining = (60*1000);
    this.footer.reset();
    this.distractor.reset();
    this.resetLevel();
    this.levelToastCancelled = false;
    this.cancelLevelToast();
  }

  public startGame() {
    this.resetGame();
    this.toastLevelUp(()=>{
      this.continueStartGame()
    });
  }

  public continueStartGame() {
    this.started = true;
    this.scheduleNextMole();
    this.scheduleNextDistractor();
    this.timeRemaining = (60*1000);
  }

  public getMoleSpawnRate() {
    return 600 - ((Game.instance.level-1) * 35);
  }

  protected scheduleNextMole() {
    this.moleSpawnTime = performance.now() + this.getMoleSpawnRate();
  }

  protected scheduleNextDistractor() {
    this.distractorSpawnTime = performance.now() + (5000 + (Math.random() * 3000));
  }

  public score(score:number, mole:MolePanel, isMissed: boolean, sx:number = 0, sy:number = 0, eject:boolean=true) {
    if(!this.started)
      return;

    Game.instance.score += score;

    if(Game.instance.isMultiplayer())
      Server.places.setScore(Game.instance.score, Game.instance.getPlaceHost());

    this.header.setValue(1, numberWithCommas(Game.instance.score));

    let scoreStr = score.toString();
    if (scoreStr.substring(0, 1) != '-')
      scoreStr = '+' + scoreStr;

    let toast = {
      text: new UIText({
        text : scoreStr,
        fontFamily : 'arial',
        fontSize : 24,
        fontWeight: '700',
        anchor: {x: mole ? 0.5 : 0, y: mole ? 0.5 : 0},
        pivot: {x: 0.5, y: 0.5},
        position : {x : sx, y : sy},
        strokeLineWidth: 3,
        strokeColor: score > 0 ? '#00aa00' : '#aa0000',
        color: score > 0 ? '#004400' : '#440000',
        parent : mole ? mole : this
      }),
      vx: eject ? (0.005 + (Math.random() * 0.01)) * (Math.random() > 0.5 ? 1 : -1) : 0,
      // vx: 0,
      vy: eject ? -0.35 : -0.1
    }

    setTimeout(() => {
      toast.text.fadeOut(1000);
    }, 500);

    this.toasts.push(toast);
  }

  private toastBlocked(mole: MolePanel, sx: number = 0, sy: number = 0) {
    let toast = {
      text: new UIText({
        text : 'BLOCKED',
        fontFamily : 'arial',
        fontSize : 20,
        fontWeight: '700',
        anchor: {x: mole ? 0.5 : 0, y: mole ? 0.5 : 0},
        pivot: {x: 0.5, y: 0.5},
        position : {x : sx, y : sy},
        strokeLineWidth: 2,
        strokeColor: 'black',
        color: '#DB00C2',
        parent : mole ? mole : this
      }),

      vx: (0.005 + (Math.random() * 0.01)) * (Math.random() > 0.5 ? 1 : -1),
      vy: -0.35
    }

    setTimeout(() => {
      toast.text.fadeOut(1000);
    }, 500);

    this.toasts.push(toast);
  }

  public onMouseDown(x:number, y: number): void {
    let child = this.findChildFromPoint(x, y);
    if (child == this) {
      this.groundTapped(x - this.getScreenX(), y - this.getScreenY());
    }
  }

  public pause() {
    this.levelToastCancelled = this.levelToastText.visible;
    this.cancelLevelToast();

    this.footer.pause();
    this.distractor.pause();
    this.clouds.pause();

    for(let i = 0; i < this.moles.length; i++)
      this.moles[i].pause();
  }

  public resume() {
    if(this.levelToastCancelled && !this.gameOver && Game.instance.level == 1) 
      this.continueStartGame();

    this.footer.resume();
    this.distractor.resume();
    this.clouds.resume();

    if(this.distractor.hidden)
      this.scheduleNextDistractor();
  
    if(this.levelToastCancelled && this.gameOver) 
      this.showGameOver();

    this.levelToastCancelled = false;
  }

  protected onRestart() {
    if(!this.gameOver)
      this.startGame();
  }

  protected onGameOver() {
    this.gameOver = true;

    this.hideAllMoles();
    if(!this.distractor.hidden)
      this.distractor.hide();

    this.started = false;

    let y = this.levelToastText.position.y;

    this.levelToastText.text = 'GAME OVER';
    this.levelToastText.visible = true;
    this.levelToastText.slide(0 + (UICanvas.instance.width/2) + this.levelToastText.width, y, 0, y, 250);

    this.levelToastTimer = setTimeout(() => {
      this.levelToastText.slide(0, y, 0 - (UICanvas.instance.width/2) - this.levelToastText.width, y, 250, ()=>{
        this.showGameOver();
      });
    }, 2500);
  }

  protected showGameOver() {
    this.levelToastText.visible = false;
    Game.instance.showGameOver(Game.instance.score, Game.instance.level, (action:string)=>{
      if(action == 'restart')
        this.startGame();
    });
  }

  private resetLevel() {
    Game.instance.level = 1;
    Game.instance.combo = 0;
    Game.instance.maxCombo = 16; 
  }

  private levelScore(score: number, isMissed: boolean) {
    // increase 25% score per level (from level 2)
    score = score * (1 + (Game.instance.level - 1) * 0.25);
    score = Math.round(score);
    return score;
  }

  private showScore(score: number, panel: MolePanel, isMissed: boolean) {
    if(score == 0)
      return;

    this.score(score, panel, isMissed);
    
    if (this.footer.isPowerupActive('whacka-double-score') && score > 0) {
      setTimeout(()=>{
        this.score(score, panel, isMissed);
      }, 100);
    }
  }

  private processCombo(score:number, panel:MolePanel, sx: number, sy: number) {
    if(score <= 0) {
      if(Game.instance.combo > 0) {
        if (this.footer.isPowerupActive('whacka-shields')) {
          this.toastBlocked(panel, sx, sy);
        }
        else {
          Game.instance.combo = Math.max(0, Game.instance.combo - 4);
          this.toastStreakBroken(panel, sx, sy);
        }
      } 
    }
    else {
      Game.instance.combo++;
      if (Game.instance.combo === Game.instance.maxCombo) {
        Game.instance.level++;
        Game.instance.combo = 0;
        Game.instance.maxCombo += 1;
        this.timeRemaining += EXTEND_TIME;
        this.toastLevelUp();
        this.hideAllMoles();
        if(!this.distractor.hidden)
          this.distractor.hide();
        this.scheduleNextDistractor();
      }
    }
  }

  private groundTapped(x: number, y: number) {
    if(this.levelToastText.visible || !this.started)
      return;

    this.processCombo(0, null, x, y);
  }

  private distractorTapped(score:number, x: number, y: number) {
    if(this.levelToastText.visible || !this.started)
      return;

    let sx = x - this.getScreenX();
    let sy = y - this.getScreenY();
    this.processCombo(score, null, sx, sy);

    if(score > 0)
      this.score(score, null, false, sx, sy, false);
  }
  
  private moleTapped(score:number, panel:MolePanel, isMissed:boolean) {
    if(this.levelToastText.visible || !this.started)
      return;

    this.processCombo(score, panel, 0, 0);

    if(score > 0) {
      score = this.levelScore(score, isMissed);
      this.showScore(score, panel, isMissed);
    }
  }

  private toastLevelUp(callback:Function=null) {
    let y = this.levelToastText.position.y;

    this.levelToastText.text = 'LEVEL ' + Game.instance.level;
    this.levelToastText.visible = true;
    this.levelToastText.slide(0 + (UICanvas.instance.width/2) + this.levelToastText.width, y, 0, y, 250);

    this.levelToastTimer = setTimeout(() => {
      this.levelToastText.slide(0, y, 0 - (UICanvas.instance.width/2) - this.levelToastText.width, y, 250, ()=>{
        this.levelToastText.visible = false;
        if(callback)
          callback();
      });
    }, 1500);
  }

  private cancelLevelToast() {
    if(this.levelToastText.visible) {
      clearTimeout(this.levelToastTimer);
      this.levelToastText.cancelSlide();
      this.levelToastText.visible = false;
    }
  }

  private toastStreakBroken(mole:MolePanel, sx:number=0, sy:number=0) {
    let toast = {
      text: new UIText({
        text : '-STREAK',
        fontFamily : 'arial',
        fontSize : 20,
        fontWeight: '700',
        anchor: {x: mole ? 0.5 : 0, y: mole ? 0.5 : 0},
        pivot: {x: 0.5, y: 0.5},
        position : {x : mole ? 0 : sx, y : mole ? 0 : sy},
        strokeLineWidth: 2,
        strokeColor: '#000000',
        color: '#FF0000',
        parent : mole ? mole : this
      }),
      vx: (0.005 + (Math.random() * 0.01)) * (Math.random() > 0.5 ? 1 : -1),
      vy: -0.35
    }

    setTimeout(() => {
      toast.text.fadeOut(1000);
    }, 500);

    this.toasts.push(toast);
  }

  private hideAllMoles() {
    for(let i = 0; i < this.moles.length; i++) {
      if(!this.moles[i].hidden)
        this.moles[i].hide(true);
    } 
  }
}