import {UICanvas} from './uiCanvas';

export class UIManager {
  public static canvas: UICanvas = null;
  public static htmlCanvas: HTMLCanvasElement = null;
  public static ctx: CanvasRenderingContext2D = null;
  public static canvasScale: number = 1;
  public static isMobile: boolean = false;

  public static get checkMobile(): boolean {
    try{ document.createEvent("TouchEvent"); return true; }
    catch(e){ return false; }
  }

  protected static lastCanvasWidth: number = 0;
  protected static lastCanvasHeight: number = 0;
  protected static lastUpdateTime: number = 0;
  protected static updateTimer: number = 0;
  protected static frameTime: number = 0;
  protected static touchDowns: any[] = [];
  protected static totalUpdateTime: number = 0;
  protected static totalUpdates: number = 0;

  public static connect(htmlCanvas: HTMLCanvasElement): void {
    UIManager.htmlCanvas = htmlCanvas;
    UIManager.ctx = htmlCanvas.getContext('2d');
    UIManager.canvas = new UICanvas();
    UIManager.canvas.size = {x : htmlCanvas.width, y : htmlCanvas.height};
    UIManager.isMobile = UIManager.checkMobile;
    UIManager.lastCanvasWidth = 0;
    UIManager.lastCanvasHeight = 0;
    UIManager.lastUpdateTime = 0;
    UIManager.updateTimer = 0;
    UIManager.frameTime = 0;
    UIManager.touchDowns = [];
    UIManager.totalUpdateTime = 0;
    UIManager.totalUpdates = 0;

    if (UIManager.isMobile) {
      htmlCanvas.addEventListener('touchstart', UIManager.onTouchStart);
      htmlCanvas.addEventListener('touchend', UIManager.onTouchEnd);
      htmlCanvas.addEventListener('touchmove', UIManager.onTouchMove);
    }
    else {
      htmlCanvas.addEventListener('mousedown', UIManager.onMouseDown, false);
      htmlCanvas.addEventListener('mouseup', UIManager.onMouseUp, false);
      htmlCanvas.addEventListener('mousemove', UIManager.onMouseMove, false);
      htmlCanvas.addEventListener('mouseout', UIManager.onMouseOut, false);
      htmlCanvas.addEventListener('wheel', UIManager.onMouseWheel, false);
      htmlCanvas.addEventListener("contextmenu", e => e.preventDefault());
    }

    window.addEventListener('focus', UIManager.onFocus, false);

    let fr = window.localStorage.getItem('CanvasFrameRate');
    if(fr)
      UIManager.setFrameRate(+fr);

    UIManager.resizeCanvas();
    UIManager.update();
  }

  public static onFocus(event:any) {
    UIManager.totalUpdateTime = 0;
    UIManager.totalUpdates = 0;
    UIManager.lastUpdateTime = 0;
    UIManager.updateTimer = 0;
  }

  public static disconnect() {
    if (UIManager.isMobile) {
      UIManager.htmlCanvas?.removeEventListener('touchstart', UIManager.onTouchStart, false);
      UIManager.htmlCanvas?.removeEventListener('touchend', UIManager.onTouchEnd, false);
      UIManager.htmlCanvas?.removeEventListener('touchmove', UIManager.onTouchMove, false);
    }
    else {
      UIManager.htmlCanvas?.removeEventListener('mousedown', UIManager.onMouseDown, false);
      UIManager.htmlCanvas?.removeEventListener('mouseup', UIManager.onMouseUp, false);
      UIManager.htmlCanvas?.removeEventListener('mousemove', UIManager.onMouseMove, false);
      UIManager.htmlCanvas?.removeEventListener('wheel', UIManager.onMouseWheel, false);
    }

    UIManager.touchDowns = [];

    UIManager.canvas.removeCurrentScreen();
    UIManager.canvas = null;
    UICanvas.instance = null;

    UIManager.htmlCanvas = null;
    UIManager.ctx = null;
  }

  public static update(): void {
    if(!UIManager.ctx || !UIManager.canvas)
      return;

    let now = performance.now();
    if(UIManager.lastUpdateTime == 0)
      UIManager.lastUpdateTime = now;

    let elapsed = now - UIManager.lastUpdateTime;

    UIManager.updateTimer += elapsed;
    UIManager.lastUpdateTime = now;

    if(UIManager.frameTime == 0 || UIManager.updateTimer == 0 || UIManager.updateTimer >= UIManager.frameTime || elapsed > UIManager.frameTime) {
      UIManager.ctx.clearRect(0, 0, UIManager.canvas.width, UIManager.canvas.height);
      UIManager.resizeCanvas();
      UIManager.canvas.update();

      UIManager.totalUpdates++;
      if(UIManager.frameTime == 0)
        UIManager.totalUpdateTime += elapsed;
      else
        UIManager.totalUpdateTime += (elapsed > UIManager.frameTime ? elapsed : UIManager.frameTime);

      if(UIManager.updateTimer > 0)
        UIManager.updateTimer -= (elapsed > UIManager.frameTime ? elapsed : UIManager.frameTime);
    }

    requestAnimationFrame(UIManager.update);
  }

  public static setFrameRate(rate:number) {
    window.localStorage.setItem('CanvasFrameRate', rate.toString());
    UIManager.frameTime = (rate == 0) ? 0 : (1000/rate);
    UIManager.updateTimer = 0;
    UIManager.totalUpdateTime = 0;
    UIManager.totalUpdates = 0;
  }

  public static getFrameRate(): number {
    if(UIManager.frameTime == 0)
      return 0;
    return Math.round(1000 / UIManager.frameTime);
  }

  public static getAverageFrameRate(): number {
    if(UIManager.totalUpdateTime == 0)
      return 0;
    return (UIManager.totalUpdates / (UIManager.totalUpdateTime / 1000));
  }

  public static resizeCanvas():boolean {
    if (UIManager.ctx == null)
      return false;

    if(UIManager.htmlCanvas.width == window.innerWidth && UIManager.htmlCanvas.height == window.innerHeight)
      return false;

    UIManager.htmlCanvas.width = window.innerWidth;
    UIManager.htmlCanvas.height = window.innerHeight;

    UIManager.canvas.size.x = window.innerWidth * UIManager.canvasScale;
    UIManager.canvas.size.y = window.innerHeight * UIManager.canvasScale;

    return true;
  }

  public static onMouseDown(event: MouseEvent) {
    var sx = (event.x - UIManager.htmlCanvas.offsetLeft) * UIManager.canvasScale;
    var sy = (event.y - UIManager.htmlCanvas.offsetTop) * UIManager.canvasScale;
    UIManager.canvas.onSystemMouseDown(sx, sy);
  }

  public static onMouseUp(event: MouseEvent) {
    var sx = (event.x - UIManager.htmlCanvas.offsetLeft) * UIManager.canvasScale;
    var sy = (event.y - UIManager.htmlCanvas.offsetTop) * UIManager.canvasScale;
    UIManager.canvas.onSystemMouseUp(sx, sy);
  }

  public static onMouseMove(event: MouseEvent) {
    var sx = (event.x - UIManager.htmlCanvas.offsetLeft) * UIManager.canvasScale;
    var sy = (event.y - UIManager.htmlCanvas.offsetTop) * UIManager.canvasScale;
    UIManager.canvas.onSystemMouseMove(sx, sy);
  }

  public static onMouseOut(event: MouseEvent) {
    var sx = (event.x - UIManager.htmlCanvas.offsetLeft) * UIManager.canvasScale;
    var sy = (event.y - UIManager.htmlCanvas.offsetTop) * UIManager.canvasScale;
    UIManager.canvas.onSystemMouseUp(sx, sy);
  }

  public static onMouseWheel(event: WheelEvent) {
    if (event.deltaY > 0)
      UIManager.canvas.onSystemMouseWheelDown(0, event.deltaY/2);
    else
      UIManager.canvas.onSystemMouseWheelUp(0, -event.deltaY/2);
  }

  public static onTouchStart(event: TouchEvent) {
    if (event.target != UIManager.htmlCanvas && event.target != document.body)
      return;

    if (!event.touches || event.touches.length != 1)
      return;

    var sx = (event.touches[0].clientX - UIManager.htmlCanvas.offsetLeft) * UIManager.canvasScale;
    var sy = (event.touches[0].clientY - UIManager.htmlCanvas.offsetTop) * UIManager.canvasScale;
    UIManager.canvas.onSystemMouseDown(sx, sy);

    event.preventDefault();
  }

  public static onTouchEnd(event: TouchEvent) {
    if (event.target != UIManager.htmlCanvas && event.target != document.body)
      return;

    if (!event.changedTouches || event.changedTouches.length != 1)
      return;

    var sx = (event.changedTouches[0].clientX - UIManager.htmlCanvas.offsetLeft) * UIManager.canvasScale;
    var sy = (event.changedTouches[0].clientY - UIManager.htmlCanvas.offsetTop) * UIManager.canvasScale;
    UIManager.canvas.onSystemMouseUp(sx, sy);

    event.preventDefault();
  }

  public static onTouchMove(event: TouchEvent) {
    if (event.target != UIManager.htmlCanvas && event.target != document.body)
      return;

    if (!event.touches || event.touches.length != 1)
      return;

    var sx = (event.touches[0].clientX - UIManager.htmlCanvas.offsetLeft) * UIManager.canvasScale;
    var sy = (event.touches[0].clientY - UIManager.htmlCanvas.offsetTop) * UIManager.canvasScale;
    UIManager.canvas.onSystemMouseMove(sx, sy);

    event.preventDefault();
  }

  // drawing helper functions
  public static drawPanel(sx:number, sy:number, cw:number, ch:number, color:string, radius:number, borderColor:string='', borderWidth:number=0) {
    var path = new Path2D();
    path.moveTo(sx + radius, sy);
    path.lineTo(sx + cw - radius, sy);
    path.quadraticCurveTo(sx + cw, sy, sx + cw, sy + radius);
    path.lineTo(sx + cw, sy + ch - radius);
    path.quadraticCurveTo(sx + cw, sy + ch, sx + cw - radius, sy + ch);
    path.lineTo(sx + radius, sy + ch);
    path.quadraticCurveTo(sx, sy + ch, sx, sy + ch - radius);
    path.lineTo(sx, sy + radius);
    path.quadraticCurveTo(sx, sy, sx + radius, sy);
    path.closePath();
    UIManager.ctx.fillStyle = color;
    UIManager.ctx.fill(path);

    if(borderWidth > 0) {
      UIManager.ctx.lineWidth = borderWidth;
      UIManager.ctx.strokeStyle = borderColor;
      UIManager.ctx.stroke(path);
     }
  }

  public static drawLine(x1:number, y1:number, x2:number, y2:number, color:string, lineWidth:number=1) {
    UIManager.ctx.strokeStyle = color;
    UIManager.ctx.lineWidth = lineWidth;
    UIManager.ctx.beginPath(); 
    UIManager.ctx.moveTo(x1, y1); 
    UIManager.ctx.lineTo(x2, y2);
    UIManager.ctx.stroke();   
  }

  public static drawFilledRect(x:number, y:number, w:number, h:number, color:string) {
    UIManager.ctx.fillStyle = color;
    UIManager.ctx.fillRect(x, y, w, h)
  }

  public static drawRect(x:number, y:number, w:number, h:number, color:string, lineWidth:number = 1) {
    UIManager.ctx.lineWidth = lineWidth;
    UIManager.ctx.strokeStyle = color;
    UIManager.ctx.strokeRect(x, y, w, h)
  }

  public static drawFilledTriangle(x1:number, y1:number, x2:number, y2:number, x3:number, y3:number, color:string) {
    UIManager.ctx.beginPath();
    UIManager.ctx.moveTo(x1, y1);
    UIManager.ctx.lineTo(x2, y2);
    UIManager.ctx.lineTo(x3, y3);
    UIManager.ctx.fillStyle = color;
    UIManager.ctx.fill();    
  }

  public static drawText(text:string, sx:number, sy:number, family:string, size:number, align:any, color:string, strokeWidth:number=0, strokeColor:string='') {
    let parts = family.split(':');
    let weight = 'normal';
    if(parts.length > 1) {
      weight = parts[1];
      family = parts[0];
    }

    UIManager.ctx.font = `${weight} ${size}px ${family}`;
    UIManager.ctx.fillStyle = color;
    UIManager.ctx.textAlign = align;
    UIManager.ctx.textBaseline = 'middle';
    UIManager.ctx.fillText(text, sx, sy);

    if(strokeWidth > 0) {
      UIManager.ctx.strokeStyle = strokeColor;
      UIManager.ctx.lineWidth = strokeWidth;
      UIManager.ctx.strokeText(text, sx, sy);
    }
  }

  public static drawFilledCircle(sx:number, sy:number, radius:number, color:string) {
    UIManager.ctx.fillStyle = color;
    UIManager.ctx.beginPath();
    UIManager.ctx.ellipse(sx, sy, radius, radius, 0, 0, Math.PI*2);
    UIManager.ctx.fill();    
  }

  public static drawCircle(sx:number, sy:number, radius:number, color:string, lineWidth:number) {
    UIManager.ctx.lineWidth = lineWidth;
    UIManager.ctx.strokeStyle = color;
    UIManager.ctx.beginPath();
    UIManager.ctx.ellipse(sx, sy, radius, radius, 0, 0, Math.PI*2);
    UIManager.ctx.stroke();    
  }

  public static measureText(text:string, family:string, size:number) {
    let parts = family.split(':');
    let weight = 'normal';
    if(parts.length > 1) {
      weight = parts[1];
      family = parts[0];
    }

    UIManager.ctx.font = `${weight} ${size}px ${family}`;

    return UIManager.ctx.measureText(text).width;
  }
}