import React from 'react';
import { BsFillXCircleFill } from 'react-icons/bs';
import { Server } from '../../server/server';
import AlertModal from './AlertModal';
import MessageModal from './MessageModal';
import './Modal.css'
import './AddMediaModal.css'

interface AddMediaModalProps {
  open: boolean;
  onClose: Function;
  callback: (data: any) => void;
}

interface AddMediaModalState {
  files: any;
  message: string;
  alert: string;
}

class AddMediaModal extends React.Component<AddMediaModalProps, AddMediaModalState> {
  constructor(props: AddMediaModalProps) {
    super(props);
    this.state = {
      files: '',
      message: '',
      alert: '',
    }

    this.onSelectFileChange = this.onSelectFileChange.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<AddMediaModalProps>) {
    if(!prevProps.open && this.props.open) 
      this.getFiles();
  }

  async getFiles() {
    let response = await Server.media.getFiles();
    if (!response.success)
      return;

    this.setState({ files: response.files });
  }
  
  renderFiles() {
    let files = this.state.files;

    if (!files)
      return (<div className="media-page">Loading...</div>);

    if (files.length == 0)
      return (<div>No media files yet.</div>)

    let divs = [];
    for (let i = 0; i < files.length; i++) {
      let url  = Server.media.getFileUrl(files[i]);
      let data = {...files[i], ...{url: url}};

      let pos = data.metadata.type.indexOf('/');
      let category = data.metadata.type.substring(0, pos);
      let insertData = {
        url: Server.media.getFileUrl(files[i]),
        category: category
      };

      divs.push(
        <div key={i}>
          <div className='add-media-modal-media-container' onClick={()=>this.props.callback(insertData)}>
            <img className='add-media-modal-image' src={data.url} />
          </div>
          {/* <div className='add-media-modal-media-name'>{data.name}</div> */}
        </div>
      )
    }

    return divs;
  }

  //-----------------------------------------
  // Pick a media file from local machine

  onSelectFileChange(e: React.FormEvent<HTMLInputElement>): void {
    this.uploadFile(e.currentTarget.files[0]);
  };

  async uploadFile(file: any) {
    if (!file) return;

    if (file.size > 5242880) { // 5 MB
      this.setState({alert: 'File must be at most 5MB size.'});
      return;
    }

    if (file.type.indexOf('image') == -1 && file.type.indexOf('audio') == -1) {
      this.setState({alert: 'Currently just image and audio are allowed.'});
      return;
    }

    this.setState({message: 'Uploading file...'})

    let response = await Server.media.uploadFile(file);

    if (response.success) {
      this.getFiles();
      this.setState({message: ''})
    }
    else
      this.setState({message: '', alert: response.message})
  }

  selectFile() {
    const fileElem = document.getElementById("AddMediaModalInput");
    if (fileElem)
      fileElem.click();
  }

  render() {
    if(!this.props.open)
      return (<div></div>);

    return (
      <div className="modal open">
        <div className="modal-content add-media-modal-content">
          <div className='add-media-modal-add-new'>
            <input
              type="file"
              id="AddMediaModalInput"
              accept="image/*, video/*, audio/*"
              className="media-page-file-select"
              onChange={this.onSelectFileChange}
            />
          </div>

          <button className="modal-close-button" onClick={()=>this.props.onClose()}>
            <BsFillXCircleFill />
          </button>
          <div className="add-media-modal-header">Select Image</div>

          <div className="add-media-modal-container">
            <div className='add-media-modal-files'>
              {this.renderFiles()}
            </div>

            <div style={{textAlign: 'right', marginTop: '10px'}}>
              <button onClick={() => this.selectFile()}>Upload Image</button>
            </div>
          </div>
        </div>

        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert:''})}/>
      </div>
    );
  }
}

export default AddMediaModal;