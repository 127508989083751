import { SettingsDialog } from '../common/settingsDialog';
import { UIButton } from '../common/ui/uiButton';
import { UICheckbox } from '../common/ui/uiCheckbox';
import { UIPanel } from '../common/ui/uiPanel';
import { UIPanelButton } from '../common/ui/uiPanelButton';
import { UIText } from '../common/ui/uiText';
// import { Site } from 'src/site/site';
import { Game } from './game';
import { GameStyle } from './gameStyle';

export class GameSettingsDialog extends SettingsDialog {
  protected rowPanelFlip1: UIPanelButton;
  protected rowPanelFlip3: UIPanelButton;
  protected timedGameCheckbox: UIButton;
  protected tournamentMinutesCheckbox: UIButton;
  protected tournamentMinutesValuePanel: UIPanel;
  protected tournamentMinutesText: UIText;
  protected tournamentGamesCheckbox: UIButton;
  protected tournamentGamesValuePanel: UIPanel;
  protected tournamentGamesText: UIText;

  public build() {
    super.build();

    this.popup.size.y = 330;

    this.buildGameTypeSelections();

    this.timedGameCheckbox = new UICheckbox({
      text : 'Timed Game',
      size: {x: 40, y: 40},
      position : {x : 20, y : 110},
      checkmarkUrl: GameStyle.checkbox.checkmarkUrl,
      radius: GameStyle.checkbox.radius,
      panelColors: GameStyle.checkbox.panelColors,
      textColors : GameStyle.checkbox.textColors,
      borderColor: GameStyle.checkbox.borderColor,
      borderWidth: GameStyle.checkbox.borderWidth,
      textOffset : GameStyle.checkbox.textOffset,
      fontFamily : GameStyle.checkbox.fontFamily,
      fontSize : GameStyle.checkbox.fontSize,
      parent : this.popup
    });

    // tournament minutes
    this.tournamentMinutesCheckbox = new UICheckbox({
      text : 'Tourney Time',
      size: {x: 40, y: 40},
      position : {x : 20, y : 160},
      callback : () => {
        this.onTournamentTimed();
      },
      checkmarkUrl: GameStyle.checkbox.checkmarkUrl,
      radius: GameStyle.checkbox.radius,
      panelColors: GameStyle.checkbox.panelColors,
      textColors : GameStyle.checkbox.textColors,
      borderColor: GameStyle.checkbox.borderColor,
      borderWidth: GameStyle.checkbox.borderWidth,
      textOffset : GameStyle.checkbox.textOffset,
      fontFamily : GameStyle.checkbox.fontFamily,
      fontSize : GameStyle.checkbox.fontSize,
      parent : this.popup
    });

    this.tournamentMinutesValuePanel = new UIPanel({
      size: {x: 110, y: 40},
      position: {x: -20, y: 160},
      anchor: {x: 1, y: 0},
      pivot: {x: 1, y: 0},
      // color: '#ff0000',
      parent: this.popup
    });

    let tournamentMinutesMinusButton = new UIPanelButton({
      text : '-',
      size : {x : 30, y : 40},
      position : {x : 0, y : 0},
      callback : () => {
        this.onDecreaseTourneyTime();
      },
      panelColors : GameStyle.dialog.button.panelColors,
      radius : GameStyle.dialog.button.radius,
      borderWidth : GameStyle.dialog.button.borderWidth,
      borderColor : GameStyle.dialog.button.borderColor,
      textColors : GameStyle.dialog.button.textColors,
      textOffset : GameStyle.dialog.button.textOffset,
      fontFamily : GameStyle.dialog.button.fontFamily,
      fontSize : GameStyle.dialog.button.fontSize,
      parent : this.tournamentMinutesValuePanel
    });

    let tournamentMinutesTextPanel = new UIPanel({
      size : {x : 40, y : 40},
      position : {x : 35, y : 0},
      radius : GameStyle.dialog.field.panel.radius,
      color : GameStyle.dialog.field.panel.color,
      parent : this.tournamentMinutesValuePanel
    });

    this.tournamentMinutesText = new UIText({
      text : '',
      anchor : {x : 0.5, y : 0.5},
      pivot : {x : 0.5, y : 0.5},
      color : GameStyle.dialog.message.color,
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize,
      fontWeight : GameStyle.dialog.message.fontWeight,
      parent : tournamentMinutesTextPanel
    });

    let tournamentMinutesPlusButton = new UIPanelButton({
      text : '+',
      size : {x : 30, y : 40},
      position : {x : 80, y : 0},
      callback : () => {
        this.onIncreaseTourneyTime();
      },
      panelColors : GameStyle.dialog.button.panelColors,
      radius : GameStyle.dialog.button.radius,
      borderWidth : GameStyle.dialog.button.borderWidth,
      borderColor : GameStyle.dialog.button.borderColor,
      textColors : GameStyle.dialog.button.textColors,
      textOffset : GameStyle.dialog.button.textOffset,
      fontFamily : GameStyle.dialog.button.fontFamily,
      fontSize : GameStyle.dialog.button.fontSize,
      parent : this.tournamentMinutesValuePanel
    });


    // tournament games
    this.tournamentGamesCheckbox = new UICheckbox({
      text : 'Tourney Games',
      size: {x: 40, y: 40},
      position : {x : 20, y : 210},
      callback : () => {
        this.onTournamentGames();
      },
      checkmarkUrl: GameStyle.checkbox.checkmarkUrl,
      radius: GameStyle.checkbox.radius,
      panelColors: GameStyle.checkbox.panelColors,
      textColors : GameStyle.checkbox.textColors,
      borderColor: GameStyle.checkbox.borderColor,
      borderWidth: GameStyle.checkbox.borderWidth,
      textOffset : GameStyle.checkbox.textOffset,
      fontFamily : GameStyle.checkbox.fontFamily,
      fontSize : GameStyle.checkbox.fontSize,
      parent : this.popup
    });

    this.tournamentGamesValuePanel = new UIPanel({
      size: {x: 110, y: 40},
      position: {x: -20, y: 210},
      anchor: {x: 1, y: 0},
      pivot: {x: 1, y: 0},
      parent: this.popup
    });

    let tournamentGamesMinusButton = new UIPanelButton({
      text : '-',
      size : {x : 30, y : 40},
      position : {x : 0, y : 0},
      callback : () => {
        this.onDecreaseTourneyGames();
      },
      panelColors : GameStyle.dialog.button.panelColors,
      radius : GameStyle.dialog.button.radius,
      borderWidth : GameStyle.dialog.button.borderWidth,
      borderColor : GameStyle.dialog.button.borderColor,
      textColors : GameStyle.dialog.button.textColors,
      textOffset : GameStyle.dialog.button.textOffset,
      fontFamily : GameStyle.dialog.button.fontFamily,
      fontSize : GameStyle.dialog.button.fontSize,
      parent : this.tournamentGamesValuePanel
    });

    let tournamentGamesTextPanel = new UIPanel({
      size : {x : 40, y : 40},
      position : {x : 35, y : 0},
      radius : GameStyle.dialog.field.panel.radius,
      color : GameStyle.dialog.field.panel.color,
      parent : this.tournamentGamesValuePanel
    });

    this.tournamentGamesText = new UIText({
      text : '',
      anchor : {x : 0.5, y : 0.5},
      pivot : {x : 0.5, y : 0.5},
      color : GameStyle.dialog.message.color,
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize,
      fontWeight : GameStyle.dialog.message.fontWeight,
      parent : tournamentGamesTextPanel
    });

    let tournamentGamesPlusButton = new UIPanelButton({
      text : '+',
      size : {x : 30, y : 40},
      position : {x : 80, y : 0},
      callback : () => {
        this.onIncreaseTourneyGames();
      },
      panelColors : GameStyle.dialog.button.panelColors,
      radius : GameStyle.dialog.button.radius,
      borderWidth : GameStyle.dialog.button.borderWidth,
      borderColor : GameStyle.dialog.button.borderColor,
      textColors : GameStyle.dialog.button.textColors,
      textOffset : GameStyle.dialog.button.textOffset,
      fontFamily : GameStyle.dialog.button.fontFamily,
      fontSize : GameStyle.dialog.button.fontSize,
      parent : this.tournamentGamesValuePanel
    });

    this.rowPanelFlip1.forcePressed(true);
    this.timedGameCheckbox.forcePressed(true);
    this.tournamentMinutesValuePanel.visible = false;
    this.tournamentGamesValuePanel.visible = false;
    this.tournamentMinutesText.text = '8';
    this.tournamentGamesText.text = '3';
  }

  public buildGameTypeSelections() {
    new UIText({
      text : 'Cards to Flip',
      color : '#ffffff',
      position : {x : 20, y : 70},
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize,
      parent : this.popup
    });

    this.rowPanelFlip1 = new UIPanelButton({
      text : '1',
      toggle : true,
      size : {x : 60, y : 40},
      anchor : {x : 1, y : 0},
      pivot : {x : 1, y : 0},
      position : {x : -90, y : 60},
      callback : () => {
        this.rowPanelFlip3.forcePressed(!this.rowPanelFlip1.isPressed());
        this.updateToggleButtonBorders();
      },
      panelColors : GameStyle.dialog.button.panelColors,
      radius : GameStyle.dialog.button.radius,
      borderWidth : GameStyle.dialog.button.borderWidth,
      borderColor : GameStyle.dialog.button.borderColor,
      textColors : GameStyle.dialog.button.textColors,
      fontFamily : GameStyle.dialog.button.fontFamily,
      fontSize : GameStyle.dialog.button.fontSize,
      textOffset: GameStyle.dialog.button.textOffset,
      parent : this.popup
    });

    this.rowPanelFlip3 = new UIPanelButton({
      text : '3',
      toggle : true,
      size : {x : 60, y : 40},
      anchor : {x : 1, y : 0},
      pivot : {x : 1, y : 0},
      position : {x : -20, y : 60},
      callback : () => {
        this.rowPanelFlip1.forcePressed(!this.rowPanelFlip3.isPressed());
        this.updateToggleButtonBorders();
      },
      panelColors : GameStyle.dialog.button.panelColors,
      radius : GameStyle.dialog.button.radius,
      borderWidth : GameStyle.dialog.button.borderWidth,
      borderColor : GameStyle.dialog.button.borderColor,
      textColors : GameStyle.dialog.button.textColors,
      fontFamily : GameStyle.dialog.button.fontFamily,
      fontSize : GameStyle.dialog.button.fontSize,
      textOffset: GameStyle.dialog.button.textOffset,
      parent : this.popup
    });
  }

  public onWake() {
    super.onWake();

    this.showBackButton();

    this.updateToggleButtonBorders();

    // if(Site.room.isActive()) {
    //   if(!this.tournamentMinutesCheckbox.isPressed() && !this.tournamentGamesCheckbox.isPressed()) {
    //     this.setTournamentTimed(true);
    //   }
    // }
    // else {
    //   this.setTournamentTimed(false);
    //   this.setTournamentGames(false);
    // }

    this.setTournamentTimed(false);
    this.setTournamentGames(false);
  }

  protected updateToggleButtonBorders() {
    this.rowPanelFlip1.setBorderColor(this.rowPanelFlip1.pressed ? '#ffff00' : '#820303');
    this.rowPanelFlip3.setBorderColor(this.rowPanelFlip3.pressed ? '#ffff00' : '#820303');
  }

  protected onDecreaseTourneyTime() {
    let curTime = +this.tournamentMinutesText.text;
    if (curTime !== 1) {
      curTime--;
      this.tournamentMinutesText.text = `${curTime}`;
    }
  }

  protected onIncreaseTourneyTime() {
    let curTime = +this.tournamentMinutesText.text;
    curTime++;
    this.tournamentMinutesText.text = `${curTime}`;
  }

  protected onDecreaseTourneyGames() {
    let curGames = +this.tournamentGamesText.text;
    if (curGames !== 2) {
      curGames--;
      this.tournamentGamesText.text = `${curGames}`;
    }
  }

  protected onIncreaseTourneyGames() {
    let curGames = +this.tournamentGamesText.text;
    curGames++;
    this.tournamentGamesText.text = `${curGames}`;
  }

  protected setTournamentGames(checked: boolean) {
    this.tournamentGamesValuePanel.visible = checked;
    this.tournamentGamesCheckbox.forcePressed(checked);
  }

  protected setTournamentTimed(checked: boolean) {
    this.tournamentMinutesValuePanel.visible = checked;
    this.tournamentMinutesCheckbox.forcePressed(checked);
    // this.tournamentGamesCheckbox.position.y = 230 + (checked ? 50 : 0);
  }

  protected onTournamentGames() {
    if (this.tournamentGamesCheckbox.isPressed()) {
      this.setTournamentTimed(false);
      this.setTournamentGames(true);
    }
    else {
      // if (Site.room.isActive()) {
      //   this.setTournamentTimed(true);
      // }
      this.setTournamentGames(false);
    }
  }

  protected onTournamentTimed() {
    if (this.tournamentMinutesCheckbox.isPressed()) {
      this.setTournamentGames(false);
      this.setTournamentTimed(true);
    }
    else {
      // if (Site.room.isActive())
      //   this.setTournamentGames(true);
      this.setTournamentTimed(false);
    }
  }

  public getSettings():any {
    let settings = {
      cardsPerFlip : this.rowPanelFlip1.isPressed() ? 1 : 3,
      useAutoPlay : Game.instance.solitaire.useAutoPlay,
      passesAllowed : 0,
      timedGame : this.timedGameCheckbox.isPressed(),
      playingTournamentTimed : this.tournamentMinutesCheckbox.isPressed(),
      playingTournamentGames : this.tournamentGamesCheckbox.isPressed(),
      tournamentTime : parseInt(this.tournamentMinutesText.text),
      tournamentGames : parseInt(this.tournamentGamesText.text),
      scoring: true
    };

    return settings;
  }
}