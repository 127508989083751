import React from 'react';
import '../pages/SitePage.css';
import { BsFillXCircleFill } from 'react-icons/bs';
import { Database } from '../util/database';
import { numberWithCommas } from '../util/util';
import { getTimeString } from '../../games/common/util';

interface LifetimeStatsPanelProps {
  stats:any;
}

interface LifetimeStatsPanelState {
}

class LifetimeStatsPanel extends React.Component<LifetimeStatsPanelProps, LifetimeStatsPanelState> {
  constructor(props: LifetimeStatsPanelProps) {
    super(props);
    this.state = {
    }
  }

  componentDidMount(): void {
  }

  render() {
    if(this.props.stats == null)
      return null;

    let stats = this.props.stats;
    console.log(stats);

    let divs = [];

    let games = [];

    let keys = Object.keys(stats);
    for(let i = 0; i < keys.length; i++) {
      let gameStats = stats[keys[i]];
      let game = Database.getGame(keys[i]);
      games.push({game, stats: gameStats});
    }

    games.sort((a, b)=>{
      // if(a.stats.total > b.stats.total)
      //   return -1;
      // else if(a.stats.total < b.stats.total)
      //   return 1;

      if(a.game.name > b.game.name)
        return 1;
      else if(a.game.name < b.game.name)
        return -1;

      return 0;
    });

    for(let i = 0; i < games.length; i++) {
      let game = games[i].game;
      let total = Math.floor(games[i].stats.total * 1000);
      if(total == 0) continue;
      console.log(games[i].stats.total);

      let totalStr = '';
      let bestStr = '';

      if(game.leaderboardType == 'points') {
        totalStr = numberWithCommas(total);
        bestStr = numberWithCommas(games[i].stats.high);
      }
      else if(game.leaderboardType == 'time') {
        let max = (60*60*1000);
        totalStr = getTimeString(max - total);
        bestStr = getTimeString(max - games[i].stats.high);
      }
  
      divs.push(
        <div key={i} className="site-page-panel">
          <div style={{display: 'grid', gridTemplateColumns: '1fr min-content'}}>
            <div>
              {games[i].game.name}
            </div>
            <div style={{whiteSpace: 'nowrap', textAlign: 'right'}}>
              {totalStr}
            </div>
            <div className="site-page-subtext" >
              Played {games[i].stats.count} time{games[i].stats.count != 1 ? 's' : ''}
            </div>
            <div className="site-page-subtext" style={{whiteSpace: 'nowrap', textAlign: 'right'}}>
              Best:&nbsp;&nbsp;{bestStr}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="site-page-column">
        {divs}
      </div>
    )
  }
}

export default LifetimeStatsPanel;