import { Database } from "./database";
import { getDeviceType } from "./util";

export function getNotificationsPermission() {
  if('Notification' in window) 
    return Notification.permission;
  return null;
}

export function sendDeviceNotification(message:string, storage:string) {
  if(document.hasFocus())
    return;

  if(getDeviceType() == 'mobile')
    return;

  if(getNotificationsPermission() != 'granted')
    return;

  if(window.localStorage.getItem('DeviceNotifications') != '1')
    return;

  if(window.localStorage.getItem(storage) != '1')
    return;

  let notification = new Notification(Database.app.title, {body: message});
  notification.addEventListener('click', ()=>{
    window.parent.focus();
  });
}

export function setDeviceBadgeCount(count:number) {
  if(getDeviceType() == 'mobile')
    return;
  
  if('setAppBadge' in navigator) 
    (navigator as any).setAppBadge(count);
}