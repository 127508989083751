import React from 'react';
import { Navigate } from 'react-router-dom';
import { Server } from '../../server/server';
import { BsFillXCircleFill } from 'react-icons/bs';
import AlertModal from './AlertModal';
import { Database } from '../util/database';
import './Modal.css'
import './LoginModal.css'

interface LoginModalProps {
  mode: string;
  onClose: Function;
}

interface LoginModalState {
  email: string;
  password: string;
  passwordAgain: string;
  code: string;
  message: string;
  alert: string;
  mode: string;
  processing: boolean;
  sent: boolean;
  navigate: string;
}

class LoginModal extends React.Component<LoginModalProps, LoginModalState> {
  constructor(props:LoginModalProps) {
    super(props);

    this.state = {
      email: '',
      password: '',
      passwordAgain: '',
      message: '',
      alert: '',
      code: '',
      mode: '',
      processing: false,
      sent: false,
      navigate: ''
    }

    this.onKeyDown = this.onKeyDown.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onPasswordAgainChange = this.onPasswordAgainChange.bind(this);
    this.onCodeChange = this.onCodeChange.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onForgotPassword = this.onForgotPassword.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onSignUp = this.onSignUp.bind(this);
    this.onResendEmail = this.onResendEmail.bind(this);
    this.onConfirmEmail = this.onConfirmEmail.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onNewPassword = this.onNewPassword.bind(this);
  }

  componentDidMount(): void {
    this.setState({mode: this.props.mode});
  }
  
  componentDidUpdate(prevProps: Readonly<LoginModalProps>) {
    if(this.props.mode == '' && prevProps.mode != '')
      this.setState({navigate: ''});

    if(prevProps.mode != this.props.mode) 
      this.setState({mode: this.props.mode});
  }

  onEmailChange(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({email: e.currentTarget.value, message: ''});
  };

  onPasswordChange(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({password: e.currentTarget.value, message: ''});
  };

  onPasswordAgainChange(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({passwordAgain: e.currentTarget.value, message: ''});
  };

  onCodeChange(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({code: e.currentTarget.value, message: ''});
  };

  onKeyDown(e:any): void {
    if (e.key === 'Enter') {
      switch(this.state.mode) {
        case 'login': 
          this.onLogin();
          break;
        case 'forgotpass': 
          this.onForgotPassword();
          break;
        case 'changepass': 
          this.onChangePassword();
          break;
        case 'newpass': 
          this.onNewPassword();
          break;
      }
    }
  };

  onClose() {
    if(!this.state.processing)
      this.close();
  }

  close() {
    this.setState({mode: 'login', alert: '', message: '', processing: false, password: '', passwordAgain: ''})
    this.props.onClose();
  }

  setMode(mode:string) {
    this.setState({mode: mode, message: '', password: ''});
  }

  setForgotPasswordMode() {
    if(this.state.processing)
      return;
    this.setMode('forgotpass');
  }

  setSignUpMode() {
    if(this.state.processing)
      return;
    this.setMode('signup');
  }

  setMessage(s:string) {
    this.setState({message: s});
  }

  startProcessing() {
    this.setState({processing: true});
  }

  stopProcessing() {
    this.setState({processing: false});
  }

  async onLogin() {
    if(this.state.email.length == 0 || this.state.email.indexOf('.') == -1 || this.state.email.indexOf('@') == -1) {
      this.setMessage('Email address is not valid.');
      return;
    }

    if(this.state.password.length == 0) {
      this.setMessage('Password cannot be blank.');
      return;
    }

    this.startProcessing();

    let response = await Server.login(this.state.email, this.state.password);

    this.stopProcessing();

    if(response.success) {
      this.close();
      // this.setState({navigate: '/'})
    }
    else {
      if(response.message == 'User is not confirmed.')
        this.setMode('confirm');
      else if(response.message == 'New Password Challenge')
        this.setMode('newpass');
      else
        this.setMessage(response.message);
    }
  }

  async onForgotPassword() {
    if(this.state.email.length == 0 || this.state.email.indexOf('.') == -1 || this.state.email.indexOf('@') == -1) {
      this.setMessage('Email address is not valid.');
      return;
    }

    this.startProcessing();

    let response = await Server.account.forgotPassword(this.state.email);
    
    this.stopProcessing();

    if(response.success)
      this.setMode('changepass')
    else
      this.setMessage(response.message);
  }

  async onChangePassword() {
    if(this.state.code.length == 0) {
      this.setMessage('Code cannot be blank.');
      return;
    }

    if(this.state.password != this.state.passwordAgain) {
      this.setMessage('Passwords do not match.');
      return;
    }

    this.startProcessing();

    let response = await Server.account.confirmPassword(this.state.code, this.state.password);

    if(response.success) {
      response = await Server.login(this.state.email, this.state.password);

      if(response.success) {
        this.close();
        return;
      }
    }

    this.stopProcessing();
    this.setMessage(response.message);
  }

  async onNewPassword() {
    if(this.state.password.length == 0) {
      this.setMessage('Password cannot be blank.');
      return;
    }

    this.startProcessing();

    let response = await Server.account.completeNewPasswordChallenge(this.state.email, this.state.password);

    if(response.success) {
      response = await Server.login(this.state.email, this.state.password);

      if(response.success) {
        this.close();
        return;
      }
    }

    this.stopProcessing();
    this.setMessage(response.message);
  }

  async onSignUp() {
    if(this.state.email.length == 0 || this.state.email.indexOf('.') == -1 || this.state.email.indexOf('@') == -1) {
      this.setMessage('Email address is not valid.');
      return;
    }

    if(this.state.password.length < 6) {
      this.setMessage('Passwords must be at least 6 characters long.');
      return;
    }

    if(this.state.password != this.state.passwordAgain) {
      this.setMessage('Passwords do not match.');
      return;
    }

    this.startProcessing();

    let response = await Server.startSignUp(this.state.email, this.state.password);

    this.stopProcessing();

    if(response.success)
      this.setMode('confirm')
    else
      this.setMessage(response.message);
  }

  async onResendEmail() {
    this.setState({sent: true});
    
    let response = await Server.account.resendConfirmationCode();

    if(!response.success) {
      this.setState({sent: false});
      this.setMessage(response.message);
    }
  }

  async onConfirmEmail() {
    if(this.state.code.length == 0) {
      this.setMessage('Code cannot be blank.');
      return;
    }

    this.startProcessing();

    let response = await Server.completeSignUp(this.state.code)

    this.stopProcessing();

    if(response.success) 
      this.close();
    else
      this.setMessage(response.message);
  }

  renderProcessingButton(text:string, callback:Function) {
    if(this.state.processing)
      return (<button disabled={true}><i className="fa fa-circle-o-notch fa-spin"></i>&nbsp;&nbsp;{text}</button>)
    else
      return (<button onClick={()=>callback()}>{text}</button>)
  }

  renderMessage() {
    if(this.state.message == '')
      return null;
    return (<div className="login-modal-error">{this.state.message}</div>);
  }

  renderLogin() {
    return (
      <div className="login-modal-container">
        <div>
          <div className="login-modal-header">{Database.app.title}</div>
        </div>
        <input type="email" placeholder="Email" value={this.state.email} onChange={this.onEmailChange} onKeyDown={this.onKeyDown}></input>
        <input type="password" placeholder="Password" value={this.state.password} onChange={this.onPasswordChange} onKeyDown={this.onKeyDown} />
        {this.renderMessage()}
        {this.renderProcessingButton('Login', this.onLogin)}
        <div className="login-modal-link" onClick={()=>this.setForgotPasswordMode()}>Forgot Password?</div>
        {/* <div>
          Don't have an account yet?<br/><span className="login-modal-link" onClick={()=>this.setSignUpMode()}>Sign up</span>
        </div> */}
      </div>
    )
  }

  renderSignUp() {
    return (
      <div className="login-modal-container">
        <div>
          <div className="login-modal-header">Create Account</div>
          <div>Please enter your email and password for the new account.</div>
        </div>
        <input type="email" placeholder="Email" value={this.state.email} onChange={this.onEmailChange}></input>
        <input type="password" placeholder="Password" value={this.state.password} onChange={this.onPasswordChange}></input>
        <input type="password" placeholder="Password Again" value={this.state.passwordAgain} onChange={this.onPasswordAgainChange}></input>
        {this.renderMessage()}
        {this.renderProcessingButton('Continue', this.onSignUp)}
        {/* <div>
          Already a user?&nbsp;&nbsp;&nbsp;<span className="login-modal-link" onClick={()=>this.setMode('login')}>Login</span>
        </div> */}
      </div>
    )  
  }

  renderConfirmEmail() {
    return (
      <div className="login-modal-container">
        <div>
          <div className="login-modal-header">Confirm Email</div>
          <div>Enter the confirmation code sent to your email address.</div>
        </div>
        <input placeholder="Code" value={this.state.code} onChange={this.onCodeChange} onKeyDown={this.onKeyDown}></input>
        {this.renderMessage()}
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <button onClick={this.onResendEmail} disabled={this.state.sent}>Resend</button>
          {this.renderProcessingButton('Confirm', this.onConfirmEmail)}
        </div>
      </div>      
    )
  }

  renderForgotPassword() {
    return (
      <div className="login-modal-container">
        <div>
          <div className="login-modal-header">Forgot Password</div>
          <div>Enter the email of the account you forgot the password for.</div>
        </div>
        <input type="email" placeholder="Email" value={this.state.email} onChange={this.onEmailChange} onKeyDown={this.onKeyDown}></input>
        {this.renderMessage()}
        {this.renderProcessingButton('Continue', this.onForgotPassword)}
      </div>
    )  
  }

  renderChangePassword() {
    return (
      <div className="login-modal-container">
        <div>
          <div className="login-modal-header">Change Password</div>
          <div>Enter the code sent to your email along with your new password.</div>
        </div>
        <input placeholder="Verification Code" value={this.state.code} onChange={this.onCodeChange} onKeyDown={this.onKeyDown}></input>
        <input type="password" placeholder="New Password" value={this.state.password} onChange={this.onPasswordChange} onKeyDown={this.onKeyDown}></input>
        <input type="password" placeholder="New Password Again" value={this.state.passwordAgain} onChange={this.onPasswordAgainChange} onKeyDown={this.onKeyDown}></input>
        {this.renderMessage()}
        {this.renderProcessingButton('Confirm', this.onChangePassword)}
      </div>
    )  
  }

  renderNewPassword() {
    return (
      <div className="login-modal-container">
        <div>
          <div className="login-modal-header">New Password</div>
          {/* <div>Enter a password for your account.</div> */}
        </div>
        <input type="password" placeholder="Password" value={this.state.password} onChange={this.onPasswordChange} onKeyDown={this.onKeyDown}></input>
        {this.renderMessage()}
        {this.renderProcessingButton('Confirm', this.onNewPassword)}
      </div>
    )  
  }

  render() {
    if(this.state.mode == '')
      return null;

    if(this.state.alert != '')
      return (<AlertModal message={this.state.alert} button="OK" onClose={this.onClose}/>);

    if(this.state.navigate != '')
      return (<Navigate replace to={this.state.navigate} />);

    let content = null;
    switch(this.state.mode) {
      case 'login':
        content = this.renderLogin();
        break;
      case 'signup':
        content = this.renderSignUp();
        break;
      case 'confirm':
        content = this.renderConfirmEmail();
        break;
      case 'forgotpass':
        content = this.renderForgotPassword();
        break;
      case 'changepass':
        content = this.renderChangePassword();
        break;
      case 'newpass':
        content = this.renderNewPassword();
        break;
    }

    if(!content)
      return (<div></div>);

    return (
      <div className="modal open">
        <div className="login-modal-content">
          <button className="modal-close-button" onClick={this.onClose}>
            <BsFillXCircleFill />
          </button>
          {content}
        </div>
      </div>
    )
  }
}

export default LoginModal;