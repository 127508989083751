import React from 'react';
import { Database } from '../util/database';
import { getPlaceText, numberWithCommas } from '../util/util';
import { Server } from '../../server/server';
import { Navigate } from 'react-router-dom';
import '../pages/SitePage.css';
import './GamesPanel.css';
import { getTimeString } from '../../games/common/util';
import { BsCircle, BsCircleFill, BsDot } from 'react-icons/bs';

interface GamesPanelProps {
  user?: string;
  period?: string;
  sort?: string;
  stats: boolean;
  all: boolean;
  update?: number;
  callback?: Function;
}

interface GamesPanelState {
  loading: boolean;
  scores: any[];
  navigate: string;
}

class GamesPanel extends React.Component<GamesPanelProps, GamesPanelState> {
  static lastInvite:any = null;

  constructor(props: GamesPanelProps) {
    super(props);
    this.state = {
      loading: false,
      scores: [],
      navigate: ''
    }
  }

  componentDidMount(): void {
    if(this.props.user)
      this.loadScores();
  }

  componentDidUpdate(prevProps: Readonly<GamesPanelProps>, prevState: Readonly<GamesPanelState>, snapshot?: any): void {
    if(prevProps.period != this.props.period)
      this.loadScores();
    else if(prevProps.update != this.props.update) {
      this.setState({scores: []});
      this.props.callback({loading: false, scores: []});
    }
  }

  async loadScores() {
    if(this.props.callback)
      this.props.callback({loading: true});
    this.setState({loading: true});
    let response = await Server.games.getScores(this.props.user, this.props.period);
    this.setState({loading: false});
    if(response.success) {
      if(this.props.callback)
        this.props.callback({loading: false, scores: response.scores});
      this.setState({scores: response.scores});
    }
  }

  onGame(id:string) {
    let game = Database.getGame(id);

    if(game.leaderboard)
      this.setState({navigate: '/game/' + id});
    else
      this.setState({navigate: '/play/' + id});
  }

  renderGames() {
    if(this.state.loading)
      return (<div className="site-page-panel">Loading...</div>);

    let isDeveloper = Server.account.isLoggedIn() && Server.account.isDeveloper();

    let games = [];

    // games with scores
    for(let i = 0; i < this.state.scores.length; i++) {
      let gid = this.state.scores[i].leaderboard;

      if(this.props.period) {
        let parts = gid.split('-');
        parts.splice(parts.length-1, 1);
        gid = parts.join('-');
      }

      let game = Database.getGame(gid);
      if(!game)
        continue;

      if(game.developer && !isDeveloper)
        continue;

      if(!game.leaderboard)
        continue;

      games.push({
        id: gid,
        image: game.image,
        name: game.name,
        leaderboardType: game.leaderboardType,
        score: this.state.scores[i].score,
        place: this.state.scores[i].place ? this.state.scores[i].place : 0
      })
    }

    // remaining games
    if(this.props.all) {
      for (let i = 0; i < Database.games.length; i++) {
        let game = Database.games[i];
  
        if(game.developer && !isDeveloper)
          continue;
  
        let existing = games.find((g)=>g.id == game.id);
        if(existing)
          continue;
  
        games.push({
          id: game.id,
          name: game.name,
          image: game.image,
          leaderboardType: game.leaderboardType,
          place: 0,
          score: 0 
        })
      }
    }

    let recentGames = Server.games.getRecentlyPlayedGames();

    games.sort((a, b)=>{
      // if(this.props.sort == 'recent') {
        let ai = recentGames.indexOf(a.id);
        let bi = recentGames.indexOf(b.id);

        if(ai == -1 && bi != -1)
          return 1;
        else if(ai != -1 && bi == -1)
          return -1;

        if(ai > bi)
          return 1;
        else if(ai < bi)
          return -1;
      // }

      // if(a.score > 0 && b.score == 0)
      //   return -1;
      // else if(a.score == 0 && b.score > 0)
      //   return 1;

      if(a.name > b.name)
        return 1;
      else if(a.name < b.name)
        return -1;
      return 0;
    })

    let panels = [];
    for(let i = 0; i < games.length; i++) {
      let game = games[i];
      let placeText = games[i].place > 0 ? getPlaceText(games[i].place) + ' Place' : 'Best Score';
      let scoreText = numberWithCommas(game.score);

      if(games[i].leaderboardType == 'time') {
        let time = (60*60*1000) - game.score;
        scoreText = getTimeString(time);
      }

      panels.push(
        <div key={i} onClick={()=>this.onGame(game.id)} className="site-page-panel" style={{padding: '5px', rowGap: '5px'}}>
          <div style={{position:'relative'}}>
            <img style={{display: 'block', width: '100%', cursor: 'pointer', borderRadius: '6px'}} src={'/games/' + game.image} alt='' />
          </div>
          {game.score > 0 &&
            <div className="site-page-row" style={{justifyContent: 'space-between', fontSize: '0.9em'}}>
              <div>&nbsp;{placeText}</div>
              <div>{scoreText}&nbsp;</div>
            </div>
          }
        </div>
      );
    }

    return (
      <div className="games-panel-container">
        {panels}
      </div>
    );
  }

  render() {
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />;
    
    return (
      <div className="site-page-column">
        {this.renderGames()}
      </div>
    )
  }
}

export default GamesPanel;