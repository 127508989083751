import React from 'react';
import { getPublicVideoUrl, isVideoUrl } from '../util/util';
import './Modal.css'
import { Server } from '../../server/server';

interface AddEmbedModalProps {
  open: boolean;
  onClose: Function;
  quillRef: any;
  quillPosition: number;
}

interface AddEmbedModalState {
  url: string;
  unfurl: any;
  loading: boolean;
}

class AddEmbedModal extends React.Component<AddEmbedModalProps, AddEmbedModalState> {
  constructor(props: AddEmbedModalProps) {
    super(props);
    this.state = {
      url: '',
      unfurl: null,
      loading: false
    }
  }

  componentDidUpdate(prevProps: Readonly<AddEmbedModalProps>) {
    if(!prevProps.open && this.props.open) {
      this.setState({url: '', unfurl: null});
    } 
  }

  getFirstParagraph(encodedContent:string) {
    let parser = new DOMParser();
    let content = decodeURIComponent(encodedContent);
    let doc = parser.parseFromString(content, "text/html");
    let paragraphs = doc.getElementsByTagName('p');
    for(let i = 0; i < paragraphs.length; i++) {
      if(paragraphs[i].children.length > 0) 
        continue;
      return paragraphs[i].innerText;
    }
    return '';
  }

  async onInsert() {
    let imageRegex = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif|webp))/i;
    let audioRegex = /(https?:\/\/.*\.(?:wav|mp3|ogg|aac|m4a|flac))/i;
    let isImage = imageRegex.test(this.state.url);
    let isVideo = isVideoUrl(this.state.url);
    let isAudio = audioRegex.test(this.state.url);

    let isLocal = (this.state.url.indexOf(window.location.host) != -1);
    let isSpotify = this.state.url.indexOf('https://open.spotify.com/') == 0;
    let isSoundCloud = this.state.url.indexOf('https://soundcloud.com/') == 0;
    let isYouTube = this.state.url.indexOf('https://www.youtube.com/') == 0 || this.state.url.indexOf('https://music.youtube.com/') == 0 || this.state.url.indexOf('https://youtu.be/') == 0;
    let isDropBox = this.state.url.indexOf('https://www.dropbox.com/') == 0;
    let isVimeo = this.state.url.indexOf('https://vimeo.com/') == 0;

    if(isLocal) {
      let parts = this.state.url.split('/');
      let page = parts[3];
      let embedded = false;

      if(page == 'blog') {
        this.setState({loading: true})
        let response = await Server.blogs.getBlog({slug: parts[4]});
        if(response.success) {
          let blog = response.blog;
          
          await Server.public.loadProfiles([blog.author]);
          let author = Server.public.getProfile(blog.author);
          let description = this.getFirstParagraph(blog.content);
        
          this.props.quillRef.insertEmbed(this.props.quillPosition, 'unfurl', {
            title: blog.title,
            author: author.name,
            url: this.state.url,
            image: blog.image,
            description
          });

          embedded = true;
        }
        this.setState({loading: false})
      }
      else if(page == 'forum' && parts.length == 6) {
        this.setState({loading: true})
        let response:any = await Server.forums.getForum(parts[4]);
        if(response.success) {
          let forum = response.forum;          
          response = await Server.forums.getTopic(parts[4], parts[5]);
          if(response.success) {
            let topic = response.topic;

            response = await Server.forums.getPosts(parts[4], parts[5], {limit: 1});

            if(response.success) {
              let post = response.posts[0];
              let description = this.getFirstParagraph(post.content);

              await Server.public.loadProfiles([post.author]);
              let author = Server.public.getProfile(post.author);
  
              this.props.quillRef.insertEmbed(this.props.quillPosition, 'unfurl', {
                title: `${forum.title} > ${topic.title}`,
                author: author.name,
                url: this.state.url,
                description
              });
  
              embedded = true;
            }
          }
        }
        this.setState({loading: false})
      }

      if(!embedded) {
        this.props.quillRef.insertText(this.props.quillPosition, this.state.url);
        this.props.quillRef.formatText(this.props.quillPosition, this.state.url.length, 'link', this.state.url);
        this.props.quillRef.setSelection(this.props.quillPosition + this.state.url.length, 0);
      }
    }
    else if(isDropBox) {
      let url = this.state.url.replace('www.dropbox.com', 'dl.dropboxusercontent.com');
      let type = isAudio ? 'audio' : (isImage ? 'image' : 'video');
      this.props.quillRef.insertEmbed(this.props.quillPosition, type, url);
    }
    else if(isVimeo) {
      let response = await fetch(`https://vimeo.com/api/oembed.json?url=${this.state.url}`);
      let json = await response.json();
      this.props.quillRef.insertEmbed(this.props.quillPosition, 'embed', {html: json.html, video: true});
    }
    else if(isYouTube) {
      let response = await fetch(`https://www.youtube.com/oembed?format=json&url=${this.state.url}`);
      let json = await response.json();
      this.props.quillRef.insertEmbed(this.props.quillPosition, 'embed', {html: json.html, video: true});
    }
    else if(isSpotify) {
      let response = await fetch(`https://open.spotify.com/oembed?url=${this.state.url}`);
      let json = await response.json();
      this.props.quillRef.insertEmbed(this.props.quillPosition, 'embed', {html: json.html, spotify: true});
    }
    else if(isSoundCloud) {
      let response = await fetch(`https://soundcloud.com/oembed?format=json&url=${this.state.url}`);
      let json = await response.json();
      this.props.quillRef.insertEmbed(this.props.quillPosition, 'embed', {html: json.html, video: true});
    }
    else if(isImage) {
      this.props.quillRef.insertEmbed(this.props.quillPosition, 'image', this.state.url);
    }
    else if(isVideo) {
      this.props.quillRef.insertEmbed(this.props.quillPosition, 'video', getPublicVideoUrl(this.state.url));
    }
    else {
      this.setState({loading: true})
      let response = await Server.public.unfurl(this.state.url);
      this.setState({loading: false})

      if(response.success && response.data.og.title) {
        let data = {...response.data.og};
        if(!data.url)
          data.url = this.state.url
        this.props.quillRef.insertEmbed(this.props.quillPosition, 'unfurl', data);
      }
      else {
        this.props.quillRef.insertText(this.props.quillPosition, this.state.url);
        this.props.quillRef.formatText(this.props.quillPosition, this.state.url.length, 'link', this.state.url);
        this.props.quillRef.setSelection(this.props.quillPosition + this.state.url.length, 0);
      }
    }

    this.props.onClose(true);
  }

  render() {
    if(!this.props.open)
      return null;

    return (
      <div className="modal open">
        <div className="modal-content">
          <div className="site-page-column">
            <div style={{textAlign: 'center'}}>Embed Content</div>
            <input 
              autoFocus={true}
              placeholder="Enter the URL"
              value={this.state.url}
              onChange={(e:any)=>this.setState({url: e.currentTarget.value})}
            />
            <div className="site-page-row" style={{justifyContent: 'space-between'}}>
              <button disabled={this.state.loading} onClick={()=>this.props.onClose(false)}>Cancel</button>
              <button disabled={this.state.loading} onClick={()=>this.onInsert()}>Insert</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddEmbedModal;