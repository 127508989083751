import React from 'react';
import { Server } from '../../server/server';
import PageEnvelope from '../elements/PageEnvelope';
import { Navigate } from 'react-router-dom';
import { BsFillPeopleFill } from 'react-icons/bs';

interface PlacesPageState {
  userPlace: any;
  friendPlaces: any[];
  navigate: string;
  loading: boolean;
}

class PlacesPage extends React.Component<{}, PlacesPageState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      userPlace: null,
      friendPlaces: [],
      navigate: '',
      loading: false
    }
    this.onFriendUpdated = this.onFriendUpdated.bind(this);
    this.onPlaceInfo = this.onPlaceInfo.bind(this);
  }

  componentDidMount(): void {
    if(Server.account.isLoggedIn())
      this.load();

    Server.user.addEventListener('friend-updated', this.onFriendUpdated);
    Server.places.addEventListener('place-info', this.onPlaceInfo);
  }

  componentWillUnmount() {
    Server.places.removeEventListener('place-info', this.onPlaceInfo);
    Server.user.removeEventListener('friend-updated', this.onFriendUpdated);
  }

  load() {
    let userPlace = Server.places.getInfo();

    let friends = Server.user.getFriends();
    let onlineFriends = 0;

    for(let i = 0; i < friends.length; i++) {
      if(friends[i].presence && friends[i].presence.online) {
        onlineFriends++;
        Server.places.getInfo(friends[i].id);
      }
    }

    this.setState({userPlace, friendPlaces: [], loading: onlineFriends > 0});
  }

  onPlaceInfo(info:any) {
    if(info.host == Server.user.getId()) {
      this.setState({userPlace: info})
    }
    else {
      let friendPlaces = [...this.state.friendPlaces];
      for(let i = 0; i < friendPlaces.length; i++)
        if(friendPlaces[i].host == info.host)
          friendPlaces.splice(i, 1);
      friendPlaces.push(info);
      this.sortPlaces(friendPlaces);
      this.setState({friendPlaces});
    }

    this.setState({loading: false});
  }

  sortPlaces(places:any[]) {
    places.sort((a, b)=>{
      if(a.users.length > b.users.length)
        return 1;
      else if(a.users.length < b.users.length)
        return -1;

      if(a.name > b.name)
        return 1;
      else if(a.name < b.name)
        return -1;

      return 0;
    });
  }

  onFriendUpdated(data:any) {
    if(data.action == 'connect') {
      Server.places.getInfo(data.friend);
    }
    else {
      let friendPlaces = [...this.state.friendPlaces];
      for(let i = 0; i < friendPlaces.length; i++)
        if(friendPlaces[i].host == data.friend)
          friendPlaces.splice(i, 1);
      this.setState({friendPlaces});
    }
  }

  onPlace(place:any) {
    let profile = Server.public.getProfile(place.host);
    if(profile)
      this.setState({navigate: '/place/' + profile.slug})
  }

  renderPlace(place:any, key:number=0) {
    let users = 0;
    if(place.users)
        users += place.users.length;

    return (
      <div key={key} className="site-page-panel" style={{cursor: 'pointer'}} onClick={()=>this.onPlace(place)}>
        <div style={{display: 'grid', gridTemplateColumns: '1fr min-content', columnGap: '10px'}}>
          <div className="site-page-column" style={{rowGap: '0px'}}>
            <div>{place.name}</div>
            <div className="site-page-subtext">{place.description}</div>
          </div>
          <div>
            <div className="site-page-row" style={{color: 'var(--panel-subtext-color)'}}>
              <div className="site-page-subtext">{users}</div>
              <div><BsFillPeopleFill size="1.1em" style={{transform: 'translate(-4px, 2px)'}} /></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if(!Server.account.isLoggedIn())
      return(<Navigate replace to="/" />);

    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />
        
    let divs = [];
    let key = 0;

    if(this.state.userPlace) {
      divs.push(
        <div key={key++} className="site-page-column">
          <div className="site-page-header">Your Place</div>
          {this.renderPlace(this.state.userPlace)}
        </div>
      )
    }

    let fdivs = [];
    for(let i = 0; i < this.state.friendPlaces.length; i++) 
      fdivs.push(this.renderPlace(this.state.friendPlaces[i], i));

    if(fdivs.length == 0) {
      if(this.state.loading)
        fdivs.push(
          <div key={0} className="site-page-panel">
            <div className="site-page-subtext">
              Loading...
            </div>
          </div>
        );
      else
        fdivs.push(
          <div key={0} className="site-page-panel">
            <div className="site-page-subtext">
              None of your friends are currently online.
            </div>
          </div>
        );
    }

    divs.push(
      <div key={key++} className="site-page-column" style={{marginTop: '10px'}}>
        <div className="site-page-header">Friends Places</div>
        {fdivs}
      </div>
    )

    return (
      <div className="site-page">
        <PageEnvelope width="500px">
          <div className="site-page-column" style={{color: 'var(--panel-subtext-color)'}}>
            {divs}
          </div>
        </PageEnvelope>
      </div>
    )
  }
}

export default PlacesPage;

