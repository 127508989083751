import { Server } from '../../server/server';
import {GameBase} from '../common/gameBase';
import {ParticleManager} from '../common/particleManager';
import {RandomNumberGenerator} from '../common/randomNumberGenerator';
import {SpriteSheetManager} from '../common/spriteSheetManager';
import {getDeviceType, hashString} from '../common/util';
import {GameScreen} from './gameScreen';
import {GameStyle} from './gameStyle';
import {MatchThree} from './matchThree';
import { MatchThreeExperimental } from './matchThreeExperimental';
import { MapScreen } from './mapScreen';
import { LevelDialog } from './levelDialog';
import Levels from './assets/levels.json';

export enum GameMode {
  Arcade,
  Adventure,
  Experimental,
  Daily
}

const ArcadeBoard = {
  'colors': 6,
  'minutes': 2,
  'goals': [] as any,
  'board': [
    '+++++++++', '+++++++++', '+++++++++', '+++++++++', '+++++++++',
    '+++++++++', '+++++++++', '+++++++++', '+++++++++'
  ]
};

export class Game extends GameBase {
  public static instance: Game = null;

  public spriteSheetManager: SpriteSheetManager;
  public particleManager: ParticleManager;
  public matchThree: any;  //MatchThree;

  public mapScreen: MapScreen;
  public gameScreen: GameScreen;
  public levelDialog: LevelDialog;

  public playing: boolean;
  public gameLaunched: boolean;
  public version: string;
  public deviceInfo: string;
  public randGen: RandomNumberGenerator;

  public gameMode: GameMode;
  public levelSelected: number;

  public fontLoaded: boolean;

  constructor(canvas:HTMLCanvasElement, callback:Function, gameMode: number) {
    let gid = 'matcha';
    if(gameMode == GameMode.Adventure) gid = 'matcha-adventure';
    if(gameMode == GameMode.Arcade) gid = 'matcha-blitz';
    
    super(canvas, callback, gid);

    this.version = '0.1.0.3';
    this.deviceInfo = `**${getDeviceType()}** - ${navigator.platform} - ${navigator.appVersion}`;
    this.playing = false;
    this.gameLaunched = false;
    this.dialogStyle = GameStyle.dialog;
    this.gameMode = gameMode;
  }

  public load() {
    super.load();

    Game.instance = this;

    let seed = this.getInitialSeed();
    this.randGen = new RandomNumberGenerator(seed);

    this.onKeyDown = this.onKeyDown.bind(this);
    document.addEventListener('keydown', this.onKeyDown);

    this.spriteSheetManager = new SpriteSheetManager();
    this.particleManager = new ParticleManager();
    this.matchThree = null;
    if(this.gameMode != GameMode.Adventure)
      this.matchThree = new MatchThreeExperimental();
    else
      this.matchThree = new MatchThree();

    this.mapScreen = new MapScreen();
    this.mapScreen.build();

    this.gameScreen = new GameScreen();
    this.gameScreen.build();

    this.levelDialog = new LevelDialog();
    this.levelDialog.build();

    this.loadAssets();
  };

  public unload() {
    this.mapScreen = null;
    this.gameScreen = null;
    this.levelDialog = null;

    this.matchThree = null;
    this.spriteSheetManager = null;
    this.particleManager = null;

    this.randGen = null;
    document.removeEventListener('keydown', this.onKeyDown);
    Game.instance = null;
    super.unload();
  };

  public tick(deltaTime: number) {
    this.randGen.getRand();
    this.matchThree.update(deltaTime);
  }

  protected loadAssets() {
    this.fontLoaded = false;

    let webFont = require('webfontloader');
    webFont.load({
      google: {families: ['Roboto']},
      active: () => {
        this.fontLoaded = true;
      },
      inactive: () => {
        this.fontLoaded = true;
      }
    });

    this.checkLoadingState();
  }

  protected checkLoadingState() {
    let ready = this.matchThree.getIsReadyToStart() && this.fontLoaded;

    if(ready) {
      this.start();
      this.callback('loaded');
      return;
    }

    setTimeout(() => {
      this.checkLoadingState();
    }, 100);
  }

  public finish() {
    // super.finish();

    this.playing = false;
    //this.matchThree.setState(MatchThreeState.Limbo);
    this.matchThree.finish();

    if (this.matchThree.isGameWon())
      this.matchThree.handleWin();
    else
      this.matchThree.handleLoss();

    // let lifetimeScore = Site.cookies.getNumber('MatchThreeLifetimeScore');
    // lifetimeScore += this.matchThree.totalScore;
    // Site.cookies.setNumber('MatchThreeLifetimeScore', lifetimeScore);
  }

  protected stringifySettings(settings: any): string {
    let s = `${settings.boardNumber}~${settings.timedGame}~${settings.minutesAllowed}~${settings.movesAllowed}~${
        settings.playingMatch3Saga}~${settings.seed}`;
    return s;
  }

  protected parseSettings(s: string): any {
    let parts = s.split('~');
    let settings = {
      boardNumber : parseInt(parts[0]),
      timedGame : (parts[1] == 'true') ? true : false,
      minutesAllowed : parseInt(parts[2]),
      movesAllowed : parseInt(parts[3]),
      playingMatch3Saga : (parts[4] == 'true') ? true : false,
      seed : parseInt(parts[5])
    };
    return settings;
  }

  /**
   * Gets a random seed using milliseconds since Jan 1, 1970.
   */
  public getInitialSeed(): number {
    let d = new Date();
    let t = d.getTime();  // milliseconds since Jan 1, 1970
    let h = hashString(`${t}`);
    return (t + h);
  }

  /**
   * Called when a key is pressed. Will then route to the correct
   * place that will process it.
   *
   * @param event - Contains info on key press.
   */
  public onKeyDown(event: KeyboardEvent) {
    // For testing and debugging
    this.matchThree.onKeyDown(event);
  }

  //-----------------------------
  // The Start Game Workflow
  public start() {
    if (this.gameMode == GameMode.Arcade)
      this.arcadeMode();
    else if (this.gameMode == GameMode.Adventure)
      this.adventureMode();
    else if (this.gameMode == GameMode.Experimental)
      this.arcadeMode();
    else if (this.gameMode == GameMode.Daily)
      this.dailyMode();
  }

  public arcadeMode() {
    this.startGame(ArcadeBoard);
  }

  public adventureMode() {
    if (this.levelSelected)
      this.startGame(Levels[this.levelSelected - 1]);
    else {
      this.mapScreen.show();
      this.mapScreen.scrollToCurrentNode();
    }
  }

  public dailyMode() {
    // let now = new Date(2024, 12, 1, 8);
    // let yesterday = new Date(2024, 12, 1, 8);
    let now = new Date();
    let yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);
    // console.log(now.toLocaleString());
    // console.log(yesterday.toLocaleString());

    // let reset = new Date(2024, 12, 1, 10);
    let reset = new Date();
    reset.setUTCHours(16, 0, 0, 0);

    let levelDate = now;
    if(now.getUTCDay() == reset.getUTCDay() && now.getUTCHours() < reset.getUTCHours()) 
      levelDate = yesterday;

    let levels = [
      39, 43, 44, 48, 50, 53, 54, 55, 59, 60, 
      61, 62, 63, 64, 65, 66, 69, 70, 73, 74, 
      76, 77, 78, 79, 83, 84, 85, 80, 81, 86, 
      89, 93
    ]

    this.levelSelected = levels[levelDate.getUTCDate() - 1];

    this.startGame(Levels[this.levelSelected - 1]);
  }

  public startGame(boardInfo: any) {
    this.matchThree.startGame(boardInfo);
    this.gameScreen.show();
    this.gameScreen.startGame();
    this.gameLaunched = true;
    this.playing = true;
  }

  public getAdventureLevel():number {
    let value = Server.user.getCookie('MatchaAdventureLevel');
    if(value)
      return +value;
    return 0;
  }

  public getAdventureLastLevel():number {
    let value = Server.user.getCookie('MatchaAdventureLastLevel');
    if(value)
      return +value;
    return 0;
  }

  public getAdventureScores():number[] {
    let value = Server.user.getCookie('MatchaAdventureScores');
    let scores = value ? JSON.parse(value) : [];
    return scores;
  }

  public getAdventureScore(level:number):number {
    let scores = this.getAdventureScores();
    if(scores.length < level)
      return 0;
    return scores[level-1];
  }

  public getTotalAdventureScore():number {
    let scores = this.getAdventureScores();
    let score = 0;
    for(let i = 0; i < scores.length; i++)
      score += scores[i];
    return score;
  }

  public async resetAdventure() {
    await Server.user.deleteCookie({key: 'MatchaAdventureLevel'});
    await Server.user.deleteCookie({key: 'MatchaAdventureLastLevel'});
    await Server.user.deleteCookie({key: 'MatchaAdventureScores'});
    // await Server.user.setCookie({key: 'MatchaAdventureLevel', value: '0'});
    // await Server.user.setCookie({key: 'MatchaAdventureLastLevel', value: '0'});
    // await Server.user.setCookie({key: 'MatchaAdventureScores', value: JSON.stringify([])});
  }

  public async saveAdventureScore(level:number, score:number, won:boolean) {
    let savedLevel = this.getAdventureLevel();
    if(won && level > savedLevel)
      await Server.user.setCookie({key: 'MatchaAdventureLevel', value: level.toString()});

    let lastLevel = this.getAdventureLastLevel();
    if(lastLevel != level)
      await Server.user.setCookie({key: 'MatchaAdventureLastLevel', value: level.toString()});

    let scores = this.getAdventureScores();
    let dirty = false;

    if(level > scores.length) {
      dirty = true;
      while(scores.length < level)
        scores.push(0);
    }

    if(score > scores[level-1]) {
      dirty = true;
      scores[level-1] = score;
    }

    if(dirty)
      await Server.user.setCookie({key: 'MatchaAdventureScores', value: JSON.stringify(scores)});
  }

  public pause() {
    super.pause();
    this.gameScreen.pause();
  }

  public resume() {
    this.gameScreen.resume();
    super.resume();
  }
}
