import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Server } from '../../server/server';
import AlertModal from '../modals/AlertModal';
import MessageModal from '../modals/MessageModal';
import AddMediaModal from '../modals/AddMediaModal';
import AddEmbedModal from '../modals/AddEmbedModal';

let IMAGE_MIME_REGEX = /^image\/(p?jpeg|gif|png)$/i;

const Quill = ReactQuill.Quill;
const BlockEmbed = Quill.import('blots/block/embed');

class AudioBlot extends BlockEmbed {
  static blotName = 'audio';
  static tagName = 'audio';

  static create(url: any) {
    let node = super.create();
    node.setAttribute('class', 'ql-audio');
    node.setAttribute('src', url);
    node.setAttribute('controls', '');
    return node;
  }
  
  static value(node: any) {
    return node.getAttribute('src');
  }
}

class EmbedBlot extends BlockEmbed {
  static blotName = 'embed';
  static tagName = 'div';

  static create(data:any) {
    let node = super.create();
    let html = data.html;

    if(data.video)
      node.setAttribute('class', 'ql-embed-video');

    if(data.spotify) {
      node.setAttribute('class', 'ql-embed-spotify');
      html = html.replace('width="100%" ', '');
      html = html.replace('height="352" ', '');
    }

    node.innerHTML = html;

    return node;
  }
}

class VideoBlot extends BlockEmbed {
  static blotName = 'video';
  static tagName = 'video';

  static create(url: string) {
    let node = super.create();
    node.setAttribute('class', 'ql-video');
    node.setAttribute('controls', '');
    node.setAttribute('src', url);
    return node;
  }

  static value(node: any) {
    return node.getAttribute('src');
  }
}

class ImageBlot extends BlockEmbed {
  static blotName = 'image';
  static tagName = 'div';

  static create(url: string) {
    let node = super.create();
    let imageNode = document.createElement('img');
    imageNode.setAttribute('class', 'ql-editor-image');
    imageNode.setAttribute('src', url);
    node.appendChild(imageNode);
    return node;
  }

  static value(node: any) {
    return node.getAttribute('src');
  }
}

class UnfurlBlot extends BlockEmbed {
  static blotName = 'unfurl';
  static tagName = 'div';

  static create(data: any) {
    let node = super.create();

    if(!data.url || !data.title)
      return node;

    node.setAttribute('class', 'ql-unfurl');

    let author = data.author;

    if(!author && data.url) {
      let regex = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/;
      let match = data.url.match(regex);
      author = match && match[1];
    }

    let html = '';
    html += `<a style="text-decoration:none" class="ql-unfurl-anchor" href="${data.url}" target="_blank">`;
    html +=   `<div class="ql-unfurl-container">`

    if(data.image)
    html +=     `<img class="ql-unfurl-image" src="${data.image}" />`;

    html +=     `<div class="ql-unfurl-text" contenteditable="false" suppressContentEditableWarning="true">`;
    html +=       `<div class="site-page-subtext">${author}</div>`;
    html +=       `<div><b>${data.title}</b></div>`;

    if(data.description)
    html +=       `<div>${data.description}</div>`;

    html +=     `</div>`;
    html +=   `</div>`;
    html += `</a>`;

    node.innerHTML = html;

    return node;
  }
}

Quill.register(UnfurlBlot);
Quill.register(AudioBlot);
Quill.register(EmbedBlot);
Quill.register(VideoBlot);
Quill.register(ImageBlot);

interface SharedQuillEditorProps {
  placeholder?: string;
  isActivity?: boolean;
  hasFontSize?: boolean;
  onChange?: Function;
  onKeyDown?: Function;
  getPosition?: Function;
  getRef: Function;
  content?: string;
}

interface SharedQuillEditorState {
  content: string;
  openAddMedia: boolean;
  openAddEmbed: boolean;
  message: string;
  alert: string;
}

class SharedQuillEditor extends React.Component<SharedQuillEditorProps, SharedQuillEditorState> {
  quillRef: any;
  quillPosition: number;
  reactQuillRef: any;
  quillModules: any;
  quillFormats: any;

  constructor(props: SharedQuillEditorProps) {
    super(props);

    this.state = {
      content: '',
      openAddMedia: false,
      openAddEmbed: false,
      message: '',
      alert: '',
    }

    this.quillRef = null;
    this.reactQuillRef = null;
    this.quillPosition = 0;

    this.onContentChange = this.onContentChange.bind(this);
    this.onContentChangeSelection = this.onContentChangeSelection.bind(this);
    this.onContentKeyDown = this.onContentKeyDown.bind(this);
    this.onQuillImage = this.onQuillImage.bind(this);
    this.onQuillEmbed = this.onQuillEmbed.bind(this);
    this.onPaste = this.onPaste.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount() { 
    this.attachQuillRefs();
    this.quillRef.root.addEventListener('paste', this.onPaste);
    this.quillRef.root.addEventListener('drop', this.onDrop, false);
    this.setState({content: this.props.content});
  }

  componentWillUnmount(): void {
    this.quillRef.root.removeEventListener('paste', this.onPaste);
    this.quillRef.root.removeEventListener('drop', this.onDrop);
  }

  attachQuillRefs() {
    // Ensure React-Quill reference is available:
    if (typeof this.reactQuillRef.getEditor !== 'function') return;

    // Skip if Quill reference is defined:
    if (this.quillRef != null) return;
    
    const quillRef = this.reactQuillRef.getEditor();
    if (quillRef != null) {
      this.quillRef = quillRef;
      this.props.getRef(quillRef);
    } 
  }

  onPaste(e:any) {
    if (e.clipboardData && e.clipboardData.items && e.clipboardData.items.length) {
      let item = e.clipboardData.items[0];
      if (IMAGE_MIME_REGEX.test(item.type)) {
        this.uploadFile(item.getAsFile());
        e.stopPropagation();
        e.preventDefault();
        return;
      }
    }
  }

  onDrop(e:any) {
    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length) {
      let file = e.dataTransfer.files[0];
      if (IMAGE_MIME_REGEX.test(file.type)) {
        this.uploadFile(file);
        e.stopPropagation();
        e.preventDefault();
        return;
      }
    }
  }

  async uploadFile(file:any) {
    if (file.size > 5242880) { // 5 MB
      this.setState({alert: 'File must be at most 5MB size.'});
      return;
    }

    this.setState({message: 'Uploading file...'})

    let response:any = await Server.media.uploadFile(file);

    if(response.success) {
      response = await Server.media.getFile(response.id);

      if(response.success) {
        let url = Server.media.getFileUrl(response.file);
        this.setState({message: ''});
        this.quillRef.insertEmbed(this.quillPosition, 'image', url);
        this.quillRef.setSelection(this.quillPosition+1, 0);
      }
      else
        this.setState({message: '', alert: response.message});
    }
    else
      this.setState({message: '', alert: response.message});
  }

  onContentChange(value: any) {
    this.setState({content: value});

    if (this.props.onChange) {
      let text = this.quillRef.getText().trim();
      text     = text.replace(/[\r\n]/g, ''); // remove \n (enter)
      this.props.onChange(text.length);
    }
  };

  onContentChangeSelection() {
    let range = this.quillRef.getSelection();
    if (range) {
      this.quillPosition = range.index;
      if (this.props.getPosition)
        this.props.getPosition(range.index);
    }
  };

  onContentKeyDown(e:any) {
    if (this.props.onKeyDown) 
      this.props.onKeyDown(e);
  }

  onQuillImage() {
    this.setState({openAddMedia: true});
  }

  onInsertMedia(data: any) {
    this.setState({openAddMedia: false});
    this.quillRef.insertEmbed(this.quillPosition, data.category, data.url);
    this.quillRef.setSelection(this.quillPosition+1, 0);
  }

  onQuillEmbed() {
    this.setState({openAddEmbed: true});
  }

  onCloseEmbed(embedded:boolean) {
    this.setState({openAddEmbed: false}); 
    if(embedded)
      this.quillRef.setSelection(this.quillPosition+1, 0);
  }

  setQuill() {
    let container = [
      this.props.hasFontSize && {'header': [1, 2, 3, false]},
      'bold', 'italic', 'underline', 'strike',
      {'align': ''}, {'align': 'center'}, {'align': 'right'},
      {'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}, 'link', 'image', 'code-block'
    ];

    this.quillModules = {
      toolbar: {
        handlers: {
          image: this.onQuillImage,
          'code-block': this.onQuillEmbed
        },
        container: [container]
      }
    };
    
    if (this.props.isActivity)
      this.quillModules = {toolbar: null};

    this.quillFormats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'indent', 'align', 'link', 'image', 'audio', 'video', 'embed', 'unfurl'];
    if (this.props.isActivity)
      this.quillFormats = ['image', 'video', 'embed', 'audio', 'unfurl'];
  }

  render() {
    this.setQuill();

    return (
      <div>
        <ReactQuill
          theme="snow"
          value={this.state.content}
          onChange={this.onContentChange}
          onChangeSelection={this.onContentChangeSelection}
          onKeyDown={this.onContentKeyDown}
          modules={this.quillModules}
          formats={this.quillFormats}
          placeholder={this.props.placeholder ? this.props.placeholder : 'Enter...'}
          ref={(el) => { this.reactQuillRef = el }}
        />

        <AddMediaModal 
          open={this.state.openAddMedia} 
          onClose={()=>this.setState({openAddMedia: false})} 
          callback={(data:any)=>this.onInsertMedia(data)} 
        />

        <AddEmbedModal 
          open={this.state.openAddEmbed} 
          onClose={(embedded:boolean)=>this.onCloseEmbed(embedded)} 
          quillRef={this.quillRef}
          quillPosition={this.quillPosition}
        />

        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert:''})}/>
      </div>
    )
  }
}

export default SharedQuillEditor;