import { Server } from './server';
import { Service, ServiceResponse } from './service';

export class MediaService extends Service {
  protected bucket: string;

  constructor() {
    super();
    this.bucket = '';
  }

  public configure(config:any) {
    this.bucket = config.bucket;
  }

  public async init(): Promise<ServiceResponse> {
    return {success: true};
  }

  public async sync(): Promise<ServiceResponse> {
    return {success: true};
  }

  // helpers
  protected async sendMediaCommand(command:string, params:any, data?:string) {
    let response = await this.sendCommand('user', command, params);
    if(response.status != 200)
      return {success: false, message: response.body.message};
    let ret:any = {success: true};
    if(data)
      ret[data] = response.body[data];
    return ret;
  }
  
  protected async getImageMetadata(file:any, metadata:any) {
    let img = new Image();
    img.src = URL.createObjectURL(file);
    await img.decode();
    metadata.width = img.width;
    metadata.height = img.height;
  }

  protected async getVideoMetadata(file:any, metadata:any) {
    return new Promise((resolve, reject) => {
      let video = document.createElement('video');
      video.addEventListener('loadedmetadata', event => {
        metadata.width = video.videoWidth;
        metadata.height = video.videoHeight;
        metadata.duration = video.duration;
        resolve(metadata);
      });
      video.src = URL.createObjectURL(file);
    });    
  }

  protected async getAudioMetadata(file:any, metadata:any) {
    const url = URL.createObjectURL(file);
   
    return new Promise((resolve) => {
      const audio = document.createElement("audio");
      audio.muted = true;
      const source = document.createElement("source");
      source.src = url; //--> blob URL
      audio.preload= "metadata";
      audio.appendChild(source);
      audio.onloadedmetadata = function(){
        metadata.duarion = audio.duration;
        resolve(metadata);
      };
    });
  }

  // files
  public async uploadFile(file:any) {
    let i = file.name.lastIndexOf('.');
    if(i == -1)
      return {success: false, message: 'Invalid file name.'};

    let fileName = file.name.substring(0, i);
    let fileExt = file.name.substring(i+1);

    let params:any = {
      name: fileName,
      bucket: this.bucket,
      metadata: {
        ext: fileExt,
        type: file.type,
        size: file.size
      }
    }

    if(file.type.indexOf('image') == 0) 
      await this.getImageMetadata(file, params.metadata);
    else if(file.type.indexOf('video') == 0) 
      await this.getVideoMetadata(file, params.metadata);
    else if(file.type.indexOf('audio') == 0) 
      await this.getAudioMetadata(file, params.metadata);

    let response = await this.sendCommand('user', 'create-file', params);

    if(response.status != 200)
      return {success: false, message: response.body.message};

    await fetch(response.body.url, {
      method: 'PUT', 
      body: file
    });

    return {success: true, id: response.body.id};
  }

  public async deleteFile(id:string) {
    await this.sendMediaCommand('delete-file', {id, bucket: this.bucket});
    return {success: true};
  }

  public async getFile(id:string) {
    return await this.sendMediaCommand('get-file', {id}, 'file');
  }

  public async updateFile(params: {id:string, name?:string, description?:string}) {
    return await this.sendMediaCommand('update-file', params);
  }

  public async getFiles(params: {sort?:string, before?:string, after?:string, limit?:number} = {}) {
    return await this.sendMediaCommand('get-files', params, 'files');
  }

  public getFileUrl(file:any) {
    return 'https://media.playplace.io/' + Server.user.getId() + '/' + file.id + '.' + file.metadata.ext;
  }
}