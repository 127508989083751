import React from 'react';
import { Link, Navigate, NavLink } from "react-router-dom"
import { Database } from '../util/database';
import { Server } from '../../server/server';
import LoginModal from '../modals/LoginModal';
import GameCard from '../elements/GameCard';
import './GamePage.css'
import { getPortraitImage } from '../util/assets';
import PageEnvelope from '../elements/PageEnvelope';
import { BsArrowClockwise, BsCaretDownFill, BsCaretLeftFill, BsCaretRightFill, BsFillPlayFill, BsQuestion, BsTextIndentLeft, BsTextIndentRight } from 'react-icons/bs';
import { WithRouterProps } from '../util/withRouter';
import AlertModal from '../modals/AlertModal';
import Portrait from '../elements/Portrait';
import { numberWithCommas } from '../util/util';
import InfoPanel from '../elements/InfoPanel';

interface GamePageProps {
  router: WithRouterProps;
}

interface GamePageState {
  play: boolean;
  login: boolean;
  loading: boolean;
  leaderboard: any[];
  bestScore: number;
  rows: any[];
  navigate: string;
  timer: number;
  timeLeft: number;
  period: string;
  game: string;
  alert: string;
  showLeaderboards: boolean;
  continue: string;
}

class GamePage extends React.Component<GamePageProps, GamePageState> {
  protected updateTimer: any;
  protected lastTimeLeft: number;
  protected currentCache: any[];
  protected archiveCache: any[];

  constructor(props:GamePageProps) {
    super(props);

    this.currentCache = null;
    this.archiveCache = null;

    this.state = {
      play: false,
      login: false,
      loading: false,
      leaderboard: [],
      bestScore: 0,
      rows: [],
      navigate: '',
      timer: 0,
      timeLeft: 0,
      period: '',
      game: '',
      alert: '',
      showLeaderboards: false,
      continue: ''
    };

    this.lastTimeLeft = 0;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let game = window.location.pathname.substring(6);
    let period = 'today';
    this.setState({game, period});
    this.refresh(game, period);
    this.startUpdateTimer();
  }

  componentDidUpdate(prevProps: Readonly<GamePageProps>, prevState: Readonly<GamePageState>): void {
    if(prevProps.router.location.pathname != this.props.router.location.pathname) {
      this.stopUpdateTimer();
      this.startUpdateTimer();
      this.forceUpdate();
      let game = this.props.router.location.pathname.substring(6);
      this.setState({game, period: prevState.period});
      this.refresh(game, prevState.period);
    }
  }

  componentWillUnmount() {
    this.stopUpdateTimer();
  }

  startUpdateTimer() {
    this.onUpdateTimer();
    this.updateTimer = setInterval(() => {
      this.onUpdateTimer();
    }, 15000);
  }

  stopUpdateTimer() {
    clearInterval(this.updateTimer);
  }

  async refresh(game:string, period:string) {
    this.setState({loading: true});

    let response = await Server.games.getLeaderboard(game, period);

    let leaderboard = [];
    let bestScore = 0;

    if(response.success) {
      leaderboard = response.leaderboard;

      let userIds = [];
      for(let i = 0; i < leaderboard.length; i++)
        userIds.push(leaderboard[i].id);
      await Server.public.loadProfiles(userIds);

      if(period != 'yesterday') {
        let scoreResponse = await Server.games.getScore(game, period);
        if(scoreResponse.success)
          bestScore = scoreResponse.score;
      }
    }

    this.setState({loading: false, leaderboard, bestScore});
  }

  processLeaderboard(leaderboard: any[]) {
    let rows = [];
    // console.log("--> leaderboard", leaderboard)

    let fakeNames = ['Wile', 'Daffy', 'Elmer', 'Sylvester', 'Marvin', 'Porky', 'Bugs', 'Sam', 'Foghorn' , 'Tweety']
    let top10 = false;
    let gameId = window.location.pathname.substring(6);
    let game = Database.getGame(gameId);

    for(let i = 0; i < 10; i++) {
      let entry = null;
      if(i < leaderboard.length)
        entry = leaderboard[i];
        
      let profile  = entry ? Server.public.getProfile(entry.id) : null;
      let id       = profile ? profile.id : '';
      let name     = profile ? profile.name : fakeNames[i];
      let slug     = profile ? profile.slug : '';
      let portrait = getPortraitImage(profile);
      let score    = '';

      if(game.leaderboardType == 'time') {
        if(entry) {
          let time = (60*60*1000) - entry.score;
          let minutes = Math.floor(time / 60000);
          let seconds = Math.floor((time % 60000) / 1000);
          let padding = seconds < 10 ? '0' : '';
          score = `${minutes}:${padding}${seconds}`;
        }
        else
          score = '60:00';
      }
      else {
        if(entry) 
          score = entry.score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');        
        else
          score = '100';
      }

      // for highlight yourself
      let hl = false;
      if (id == Server.user.getId()) {
        hl    = true;
        top10 = true;
      }

      // if(score != '') 
      //   score = score.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      if(slug == '') {
        rows.push(
          <div key={Math.random()} className="game-page-leaderboard-row">
            <div className="leaderboard-portrait-container">
              <img className="game-page-leaderboard-portrait" src={portrait} />
            </div>
            <div className="game-page-leaderboard-name">
              {name}
            </div>
            <div className="game-page-leaderboard-score" style={{textAlign: 'right'}}>
              {score}
            </div>
          </div>
        )
      }
      else {
        rows.push(
          <div key={Math.random()} className="game-page-leaderboard-row">
            <NavLink className='leaderboard-portrait-container' to={"/profile/" + slug}>
              <Portrait user={id} size={32/44} />
            </NavLink>
            <NavLink className={`game-page-leaderboard-name link highlight ${hl ? 'show' : ''}`} to={"/profile/" + slug}>
              {name}
            </NavLink>
            <div className={`game-page-leaderboard-score highlight ${hl ? 'show' : ''}`} style={{textAlign: 'right'}}>
              {score}
            </div>
          </div>
        );
      }
    }

    if (!top10 && this.state.bestScore != 0) {
      rows.push(
        <div key={Math.random()} className="game-page-leaderboard-row">
          <NavLink className='leaderboard-portrait-container' to={"/profile"}>
            <Portrait user={Server.user.getId()} size={32/44} />
          </NavLink>
          <NavLink className="game-page-leaderboard-name link highlight show" to={"/profile"}>
            {Server.user.getName()}
          </NavLink>
          <div className="game-page-leaderboard-score link highlight show" style={{textAlign: 'right'}}>{numberWithCommas(this.state.bestScore)}</div>
        </div>
      );
    }

    return rows;
  }

  onPlay() {
    if(!Server.account.isLoggedIn()) {
      this.setState({alert: 'You must be logged in to play games.'});
      return;
    }

    this.setState({navigate: '/play/' + this.state.game});
  }

  onContinue() {
    this.setState({continue: ''});
    this.onPlay();
  }

  onHelp() {
    this.setState({navigate: '/help/' + this.state.game});
  }

  onUpdateTimer() {
    let now = new Date();
    let reset = new Date();
    reset.setUTCHours(16, 0, 0, 0);
    if(now > reset)
      reset.setMonth(now.getMonth(), now.getDate() + 1);
    let timeLeft = (reset.getTime() - now.getTime()) / 3600000; 
    if(this.lastTimeLeft != 0 && this.lastTimeLeft < timeLeft && this.state.leaderboard.length > 0) {
      this.setState({leaderboard: [], bestScore: 0});
    }
    this.lastTimeLeft = timeLeft;
    this.setState({timeLeft});
  }

  onLeaderboard(period:string) {
    this.setState({period, showLeaderboards: false});
    this.refresh(this.state.game, period);
  }

  onRefresh() {
    this.setState({loading: true});
    setTimeout(() => {
      this.refresh(this.state.game, this.state.period);
    }, 250);
  }

  renderLeaderboardsModal() {
    return (
      <div className="modal open">
        <div className="modal-content" style={{width: '200px', maxHeight: '300px'}}>
          <div className="site-page-column">
            <div style={{fontSize: '1.1em', paddingBottom: '6px'}}>Select Leaderboard</div>
            <button onClick={()=>this.onLeaderboard('today')}>Today</button>
            <button onClick={()=>this.onLeaderboard('yesterday')}>Yesterday</button>
            <button onClick={()=>this.onLeaderboard('this-month')}>This Month</button>
            <button onClick={()=>this.onLeaderboard('last-month')}>Last Month</button>
            <button onClick={()=>this.onLeaderboard('')}>All Time</button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />

    if(this.state.game == '')
      return null;

    let game = Database.getGame(this.state.game);

    if(!game)
      return null;

    let rows = this.processLeaderboard(this.state.leaderboard);
    let content = this.state.loading ? <div style={{height: '365px'}}>Loading...</div> : rows;

    let allowPlay = true;

    let hours = Math.floor(this.state.timeLeft);
    let mins = (this.state.timeLeft - hours) * 60;
    let reset = `Resets in ${hours} hours and ${Math.floor(mins)} minutes`;
    let starts = Math.floor(mins-57);

    if(hours == 0 && mins >= 1)
      reset = `Resets in ${Math.floor(mins)} minutes`;
    else if((hours == 0 && Math.floor(mins) == 0)) {
      reset = `Resets in less than a minute`;
      allowPlay = false;
    }
    else if((hours> 0 && Math.floor(mins) == 0)) {
      reset = `Resets in ${hours} hours`;
    }
    else if((hours == 23 && starts > 0 && starts < 3)) {
      reset = `Processing for ${starts} minutes`;
      allowPlay = false;
    }

    let isDeveloper = Server.account.isLoggedIn() && Server.account.isDeveloper();
    let prevGame = Database.getPreviousGame(this.state.game, isDeveloper).id;
    let nextGame = Database.getNextGame(this.state.game, isDeveloper).id;

    let lbHeader = 'All Time';
    if(this.state.period == 'today')
      lbHeader = 'Daily Leaderboard';
    else if(this.state.period == 'yesterday')
      lbHeader = `Yesterday`;
    else if(this.state.period == 'this-month')
      lbHeader = `This Month`;
    else if(this.state.period == 'last-month')
      lbHeader = `Last Month`;

    let refreshButton = <div />
    if(this.state.period == 'today' || this.state.period == 'this-month' || this.state.period == '')
      refreshButton = <BsArrowClockwise 
        style={{fontSize: '1.5em', cursor: 'pointer', color: 'var(--panel-subtext-color)'}} 
        onClick={()=>this.onRefresh()} 
      />

    return (
      <div className="site-page">
        <PageEnvelope width="100%">
          <div className="game-page-container">
            <div className="site-page-column">
              <div className="site-page-panel">
                <div style={{display: 'grid', alignItems: 'center', gridTemplateColumns: 'min-content 1fr min-content'}}>
                  <Link className="game-page-arrow-button" style={{transform: 'translateX(-5px)'}} to={'/game/' + prevGame}>
                    <BsCaretLeftFill style={{fontSize: '1.5em'}} />
                  </Link>
                  <img style={{width: '100%', borderRadius: '10px'}} src={'/games/' + game.image} alt='' />
                  <Link className="game-page-arrow-button" style={{transform: 'translateX(5px)'}} to={'/game/' + nextGame}>
                    <BsCaretRightFill style={{fontSize: '1.5em'}} />
                  </Link>
                </div>

                <div style={{textAlign: 'center', padding: '0px 0px 5px 0px', color: 'var(--panel-subtext-color)'}}>
                  {game.description}
                </div>

                <div className="game-page-buttons">
                  <button style={{width: '100%', backgroundColor: '#007ff0'}} onClick={()=>this.onHelp()}>
                    <div className="site-page-row" style={{justifyContent: 'center', columnGap: '0px'}}>
                      <BsQuestion style={{fontSize: '1.3em'}} /> Help
                    </div>
                  </button>

                  <button disabled={!allowPlay} style={{width: '100%'}} onClick={()=>this.onPlay()}>
                    <div className="site-page-row" style={{justifyContent: 'center', columnGap: '0px'}}>
                      <BsFillPlayFill style={{fontSize: '1.3em'}} /> Play
                    </div>
                  </button>
                </div>
              </div>
            </div>

            <div>
              <div className="site-page-panel">
                <div style={{display: 'grid', gridTemplateColumns: '30px 1fr 30px'}}>
                  {refreshButton}
                  <div className="game-page-leaderboard-header">
                    {lbHeader}
                  </div>
                  <BsCaretDownFill 
                    style={{fontSize: '1.5em', cursor: 'pointer', color: 'var(--panel-subtext-color)'}} 
                    onClick={()=>this.setState({showLeaderboards: true})} 
                  />
                </div>
                {this.state.period == 'today' && 
                  <div className="site-page-subtext" style={{textAlign: 'center', marginTop: '-10px'}}>
                    <div>
                      {reset}
                    </div>
                  </div>
                }
                <div className="game-page-leaderboard">
                  {content}
                </div>
              </div>
            </div>
          </div>
        </PageEnvelope>
        {this.state.showLeaderboards && this.renderLeaderboardsModal()}
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
        <AlertModal message={this.state.continue} button="Continue" onClose={()=>this.onContinue()}/>
      </div>
    );
  }
}

export default GamePage;
