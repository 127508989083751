import { getAssetImage } from "../../app/util/assets";
import { Database } from "../../app/util/database";
import { UICanvas } from "./ui/uiCanvas";
import { UIDialog, UIDialogOptions } from "./ui/uiDialog";
import { UIImage } from "./ui/uiImage";
import { UIManager } from "./ui/uiManager";
import { UIPanel } from "./ui/uiPanel";
import { UIPanelButton } from "./ui/uiPanelButton";
import { UIText } from "./ui/uiText";

export interface WelcomeDialogOptions {
}

export class WelcomeDialog extends UIDialog {
  protected titleLabel: UIText;
  protected game: any;
  protected descriptionLabel: UIText;
  protected powerupsPanel: UIPanel;

  constructor(options: UIDialogOptions) {
    super(options);
    this.game = null;
  }

  public init(gameId:string) {
    this.game = Database.getGame(gameId);
  }

  public build() {
    this.popup = new UIPanel({
      size: {x: 340, y: 400},
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      position: {x: 0, y: 0},
      radius: this.options.panel.radius,
      color: this.options.panel.color,
      gradient: this.options.panel.gradient,
      borderColor: this.options.panel.borderColor,
      borderWidth: this.options.panel.borderWidth,
      shadow: this.options.panel.shadow,
      parent: this
    });

    this.titleLabel = new UIText({
      text: 'WELCOME',
      position: {x: 0, y: 15},
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      horizontalAlign: 'center',
      color: this.options.title.color,
      fontFamily: this.options.title.fontFamily,
      fontSize: this.options.title.fontSize,
      fontWeight: this.options.title.fontWeight,
      shadow: this.options.title.shadow,
      parent: this.popup
    });

    let objectiveLabel = new UIText({
      text: 'Objective',
      position: {x: 15, y: 50},
      color: this.options.message.color,
      fontFamily: this.options.message.fontFamily,
      fontSize: this.options.message.fontSize,
      fontWeight: this.options.message.fontWeight,
      parent: this.popup
    });

    this.descriptionLabel = new UIText({
      text: '',
      fitParent: {left: 15, right: 15, top: -1, bottom: -1},
      position: {x: 0, y: 75},
      wordWrap: true,
      color: this.options.subtext.color,
      fontFamily: this.options.subtext.fontFamily,
      fontSize: this.options.subtext.fontSize,
      fontWeight: this.options.subtext.fontWeight,
      parent: this.popup
    });

    let powerupsLabel = new UIText({
      text: 'Powerups',
      position: {x: 15, y: 130},
      color: this.options.message.color,
      fontFamily: this.options.message.fontFamily,
      fontSize: this.options.message.fontSize,
      fontWeight: this.options.message.fontWeight,
      parent: this.popup
    });

    this.powerupsPanel = new UIPanel({
      fitParent: {left: 15, right: 15, top: -1, bottom: -1},
      size: {x: 0, y: 200},
      position: {x: 15, y: 160},
      // color: '#00000080',
      parent: this.popup
    });

    let playButton = new UIPanelButton({
      text : 'Claim Free Powerups',
      anchor : {x : 0.5, y : 1},
      pivot : {x : 0.5, y : 1},
      size : {x : 200, y : 40},
      position : {x : 0, y : -20},
      callback : () => {
        this.onPlay();
      },
      panelColors : this.options.button.panelColors,
      radius : this.options.button.radius,
      borderWidth : this.options.button.borderWidth,
      borderColor : this.options.button.borderColor,
      textColors : this.options.button.textColors,
      fontFamily : this.options.button.fontFamily,
      fontSize : this.options.button.fontSize,
      textOffset: this.options.button.textOffset,
      parent : this.popup
    });
  }

  protected buildPowerupsPanel() {
    this.powerupsPanel.removeAllChildren();

    for(let i = 0; i < this.game.powerups.length; i++) {
      let asset = Database.getAsset(this.game.powerups[i]);

      let row = new UIPanel({
        fitParent: {left: 0, right: 0, top: -1, bottom: -1},
        size: {x: 0, y: 50},
        position: {x: 0, y: i * 60},
        parent: this.powerupsPanel
      });
  
      let image = new UIImage ({
        url: getAssetImage(asset),
        size: {x: 50, y: 50},
        stretch: true,
        radius: 10,
        borderColor: 'black',
        borderWidth: 2,
        parent: row
      });
  
      let description = new UIText({
        text: asset.description,
        fitParent: {left: 60, right: 0, top: -1, bottom: -1},
        // size: {x: 0, y: 30},
        // position: {x: 0, y: 0},
        wordWrap: true,
        // verticalAlign: 'center',
        autoHeight: true,
        anchor: {x: 0, y: 0.5},
        pivot: {x: 0, y: 0.5},
        color: this.options.subtext.color,
        fontFamily: this.options.subtext.fontFamily,
        fontSize: this.options.subtext.fontSize,
        fontWeight: this.options.subtext.fontWeight,
        parent: row
      });
    }
  }

  public onWake() {
    this.titleLabel.text = this.game.name;
    this.descriptionLabel.text = this.game.description;
    this.buildPowerupsPanel();
    this.popup.size.y = 230 + (this.game.powerups.length * 60);
    let py = (UICanvas.instance.height - this.popup.size.y) * 0.35;
    this.popup.position.y = Math.max(60, py);
  }

  protected onPlay() {
    this.hide();
    this.callback('play');
  }
}