import React from 'react';
import { Server } from '../../server/server';
import './BlogCard.css'
import { BsExclamationCircleFill } from 'react-icons/bs';

interface BlogCardProps {
  data: any;
  category?: string;
  onClick?: Function;
  onEdit?: Function;
  notify?: boolean;
}

class BlogCard extends React.Component<BlogCardProps, {}> {

  constructor(props: BlogCardProps) {
    super(props);
  }

  onClick() {
    if(this.props.onClick)
      this.props.onClick(this.props.data);
  }

  onEdit(event:any) {
    event.stopPropagation();
    if(this.props.onEdit)
      this.props.onEdit(this.props.data);
  }

  render() {
    let data   = this.props.data;
    let d      = new Date(data.date);
    let date   = d.toLocaleString(undefined, {month: "short", day: "numeric"});
    let image  = data.image ? data.image : '/blog.png';
    let author = Server.public.getProfile(data.author);

    if(image.indexOf('https://s3.us-west-2.amazonaws.com/media.playplace.io') != -1) 
      image = image.replace('https://s3.us-west-2.amazonaws.com/media.playplace.io', 'https://media.playplace.io');

    return (
      <div className="site-page-panel blog-card" onClick={()=>this.onClick()}>
        <img className="blog-card-image" src={image} />

        <div className="site-page-column" style={{rowGap: '5px'}}>
          <div>
            {data.title}
            {this.props.notify && <BsExclamationCircleFill style={{color: 'var(--notification-background-color)', fontSize: '0.9em', paddingLeft: '6px', transform: 'translateY(1px)'}}/>}
          </div>
          <div style={{color: 'var(--panel-subtext-color)'}}>{data.preview}</div>
          <div className="site-page-subtext">{author.name}&nbsp;&#x2022;&nbsp;{date}</div>
        </div>

        {this.props.category != 'community' && this.props.category != 'friends' && this.props.category != 'following' &&
          <div className="blog-card-state">{data.state}</div>
        }
      </div>
    )
  }
}

export default BlogCard;