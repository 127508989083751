import { UIDialog } from '../common/ui/uiDialog';
import { UIPanel } from '../common/ui/uiPanel';
import { UIPanelButton } from '../common/ui/uiPanelButton';
import { UIText } from '../common/ui/uiText';
import { Game } from './game';
import { GameStyle } from './gameStyle';

export class GameModeDialog extends UIDialog {

  public build() {
    let popup = new UIPanel({
      size: {x: 260, y: 410},
      anchor: {x: 0.5, y: 0.4},
      pivot: {x: 0.5, y: 0.5},
      position: {x: 0, y: 0},
      radius: this.options.panel.radius,
      color: this.options.panel.color,
      gradient: this.options.panel.gradient,
      borderColor: this.options.panel.borderColor,
      borderWidth: this.options.panel.borderWidth,
      shadow: this.options.panel.shadow,
      parent: this
    });

    let titleText = new UIText({
      text: 'SELECT MODE',
      position: {x: 0, y: 15},
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      horizontalAlign: 'center',
      color: this.options.title.color,
      fontFamily: this.options.title.fontFamily,
      fontSize: this.options.title.fontSize,
      fontWeight: this.options.title.fontWeight,
      shadow: this.options.title.shadow,
      parent: popup
    });

    let arcadeButton = new UIPanelButton({
      text : '',
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      position : {x : 0, y : 60},
      size : {x : 220, y : 80},
      callback : () => {
        this.hide();
        this.callback('arcade');
      },
      panelColors: this.options.button.panelColors,
      textColors: this.options.button.textColors,
      radius: this.options.button.radius,
      fontFamily: this.options.button.fontFamily,
      fontSize: this.options.button.fontSize,
      textOffset: this.options.button.textOffset,
      borderColor: this.options.button.borderColor,
      borderWidth: this.options.button.borderWidth,
      parent : popup
    });

    let arcadeModeLabel = new UIText({
      text: 'ARCADE',
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      position: {x: 0, y: 10},
      color: this.options.message.color,
      fontFamily: GameStyle.fonts.normal.family,
      fontSize: GameStyle.fonts.normal.size,
      parent: arcadeButton
    });

    let arcadeModeText = new UIText({
      text: 'Score as many points as you can in 5 minutes',
      // text: 'Score as many points as possible in 5 minutes with unlimited shuffles',
      // text: '5 minutes and unlimited shuffles to get a high score!',
      // text: 'Get the highest score you can in 5 minutes with unlimited shuffles',
      position: {x: 0, y: 33},
      fitParent: {left: 10, right: 10, top: -1, bottom: -1},
      horizontalAlign: 'center',
      wordWrap: true,
      autoWidth: false,
      color: '#ffffffa0',
      fontFamily: GameStyle.fonts.small.family,
      fontSize: GameStyle.fonts.small.size,
      parent: arcadeButton
    });

    let klondikeButton = new UIPanelButton({
      text : '',
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      position : {x : 0, y : 155},
      size : {x : 220, y : 80},
      callback : () => {
        this.hide();
        this.callback('klondike');
      },
      panelColors: this.options.button.panelColors,
      textColors: this.options.button.textColors,
      radius: this.options.button.radius,
      fontFamily: this.options.button.fontFamily,
      fontSize: this.options.button.fontSize,
      textOffset: this.options.button.textOffset,
      borderColor: this.options.button.borderColor,
      borderWidth: this.options.button.borderWidth,
      parent : popup
    });

    let klondikeModeLabel = new UIText({
      text: 'KLONDIKE',
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      position: {x: 0, y: 10},
      color: this.options.message.color,
      fontFamily: GameStyle.fonts.normal.family,
      fontSize: GameStyle.fonts.normal.size,
      parent: klondikeButton
    });

    let klondikeModeText = new UIText({
      text: 'Play the classic version of solitaire with standard rules',
      position: {x: 0, y: 33},
      fitParent: {left: 5, right: 5, top: -1, bottom: -1},
      horizontalAlign: 'center',
      wordWrap: true,
      autoWidth: false,
      color: '#ffffffa0',
      fontFamily: GameStyle.fonts.small.family,
      fontSize: GameStyle.fonts.small.size,
      parent: klondikeButton
    });

    let customButton = new UIPanelButton({
      text : '',
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      position : {x : 0, y : 250},
      size : {x : 220, y : 80},
      callback : () => {
        this.hide();
        this.callback('casual');
      },
      panelColors: this.options.button.panelColors,
      textColors: this.options.button.textColors,
      radius: this.options.button.radius,
      fontFamily: this.options.button.fontFamily,
      fontSize: this.options.button.fontSize,
      textOffset: this.options.button.textOffset,
      borderColor: this.options.button.borderColor,
      borderWidth: this.options.button.borderWidth,
      parent : popup
    });

    let customModeLabel = new UIText({
      text: 'CUSTOM',
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      position: {x: 0, y: 10},
      color: this.options.message.color,
      fontFamily: GameStyle.fonts.normal.family,
      fontSize: GameStyle.fonts.normal.size,
      parent: customButton
    });

    let customModeText = new UIText({
      text: 'Play casually with any options that you want',
      position: {x: 0, y: 33},
      fitParent: {left: 10, right: 10, top: -1, bottom: -1},
      horizontalAlign: 'center',
      wordWrap: true,
      autoWidth: false,
      color: '#ffffffa0',
      fontFamily: GameStyle.fonts.small.family,
      fontSize: GameStyle.fonts.small.size,
      parent: customButton
    });

    let quitButton = new UIPanelButton({
      text : 'Quit',
      anchor : {x : 0.5, y : 1},
      pivot : {x : 0.5, y : 1},
      position : {x : 0, y : -20},
      size : {x : 120, y : 40},
      callback : () => {
        this.onQuit();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      textOffset: GameStyle.dialog.button.textOffset,
      borderColor: GameStyle.dialog.button.borderColor,
      borderWidth: GameStyle.dialog.button.borderWidth,
      parent : popup
    });
  }

  public onWake() {
  }

  protected onQuit() {
    this.hide();
    Game.instance.askQuestion('Quit the game and\nreturn to Play Place?', (response:string)=>{
      if(response == 'Yes')
        Game.instance.quit();
      else
        this.showModal();
    });
  }
}