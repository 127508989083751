import { Server } from './server';
import { Service, ServiceResponse } from './service';

export class BlogsService extends Service {
  protected blogs: any[];

  constructor() {
    super();
    this.blogs = [];
  }

  // helpers
  protected async sendBlogsCommand(command:string, params:any, data?:string) {
    let response = await this.sendCommand('blogs', command, params);
    if(response.status != 200)
      return {success: false, message: response.body.message};
    let ret:any = {success: true};
    if(data)
      ret[data] = response.body[data];
    return ret;
  }

  // blogs
  public async createBlog(params:{slug: string, title: string, content: string, tags?:string, image?: string}) {
    return await this.sendBlogsCommand('create-blog', params, 'id');
  }

  public async editBlog(params:{id: string, slug?: string, title?: string, content?: string, tags?:string, image?: string}) {
    return await this.sendBlogsCommand('edit-blog', params);
  }

  public async archiveBlog(id:string) {
    return await this.sendBlogsCommand('archive-blog', {id});
  }

  public async restoreBlog(id:string) {
    return await this.sendBlogsCommand('restore-blog', {id});
  }

  public async publishBlog(id:string) {
    return await this.sendBlogsCommand('publish-blog', {id});
  }

  public async unpublishBlog(id:string) {
    return await this.sendBlogsCommand('unpublish-blog', {id});
  }

  public async deleteBlog(id:string) {
    return await this.sendBlogsCommand('delete-blog', {id});
  }

  public async getBlog(params:{id?:string, slug?:string}) {
    let response = await this.sendCommand('public', 'get-blog', params);

    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true, blog: response.body.blog};
  }

  public async getBlogComments(params:{id?:string, sort?:boolean, more?:any}) {
    let response = await this.sendCommand('public', 'get-blog-comments', params);

    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true, comments: response.body.comments, more: response.body.more};
  }

  public async getBlogs(params:{author?:string, filter?:string, state?:string, before?:any, after?:any} = {}) {
    let response = await this.sendCommand(Server.account.isLoggedIn() ? 'blogs' : 'public', 'get-blogs', params);

    if(response.status != 200)
      return {success: false, message: response.body.message};

    return {success: true, blogs: response.body.blogs};
  }

  // comments
  public async createComment(params:{id:string, content: string}) {
    return await this.sendBlogsCommand('create-comment', params, 'date');
  }

  public async editComment(params:{id:string, date:string, content:string, user?:string}) {
    return await this.sendBlogsCommand('edit-comment', params);
  }

  public async deleteComment(params:{id:string, date:string, user?:string}) {
    return await this.sendBlogsCommand('delete-comment', params);
  }

  // local cache
  public async checkForNewBlogs() {
    if(!Server.account.isLoggedIn()) 
      return;

    let params:any = {state: 'published'};
    if(this.blogs.length > 0)
      params.after = {id: this.blogs[0].id, date: this.blogs[0].date};

    let response = await this.getBlogs(params);
    if(response.success && response.blogs.length > 0) {
      for(let i = response.blogs.length-1; i >= 0; i--) 
        this.blogs.unshift(response.blogs[i]);
    }
  }

  public getUnreadBlogCount() {
    let lastReadBlogDateCookie = Server.user.getCookie('LastBlogsRead');
    let lastReadBlogDate = lastReadBlogDateCookie ? new Date(lastReadBlogDateCookie) : null;

    if(!lastReadBlogDateCookie)
      return this.blogs.length;

    let count = 0;
    for(let i = 0; i < this.blogs.length; i++) {
      if(this.blogs[i].author == Server.user.getId())
        continue;
      let d = new Date(this.blogs[i].date);
      if(d > lastReadBlogDate)
        count++;
    }

    return count;
  }

  public getLastReadBlogDate() {
    let lastReadBlogDateCookie = Server.user.getCookie('LastBlogsRead');
    return lastReadBlogDateCookie ? new Date(lastReadBlogDateCookie) : null;
  }

  public setLastReadBlogDate(date:string) {
    if(Server.account.isLoggedIn())
      Server.user.setCookie({key: 'LastBlogsRead', value: date});

  }

  public syncBlogCache(date:string) {
    if(this.blogs.length == 0)
      return;

    let recentDate = new Date(date);
    for(let i = 0; i < this.blogs.length; i++) {
      let cacheDate = new Date(this.blogs[i].date);
      if(cacheDate > recentDate) {
        console.warn('Removing blog from cache.');
        this.blogs.splice(i, 1);
        i--;
      }
    }
  }
}

