import { Database } from "./database";

export function getAssetImage(asset:any):string {
  if(!asset) return null;

  let dir = `/assets/${asset.collection}`;

  if(asset.dir)
    dir += '/' + asset.dir;

  if(asset.image)
    return `${dir}/${asset.image}.png`;

  return `${dir}/${asset.id}.png`;
}

export function getPortraitImage(profile:any):string {
  if(!profile || !profile.portrait)
    return '/portrait-default.png';
  let asset = Database.getAsset(profile.portrait);
  if(!asset)
    return '/portrait-default.png';
  return getAssetImage(asset);
}

export function getBannerImage(profile:any):string {
  if(!profile || !profile.banner)
    return '/banner-default.png';
  let asset = Database.getAsset(profile.banner);
  if(!asset)
    return '/banner-default.png';
  return getAssetImage(asset);
}


