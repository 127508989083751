import { Server } from "../../server/server";
import { GameFooter } from "../common/gameFooter";
import { GameHeader } from "../common/gameHeader";
import { UICanvas } from "../common/ui/uiCanvas";
import { UIImage } from "../common/ui/uiImage";
import { UIManager } from "../common/ui/uiManager";
import { UIPanel } from "../common/ui/uiPanel";
import { UIPanelButton } from "../common/ui/uiPanelButton";
import { UIScreen } from "../common/ui/uiScreen";
import { UIText } from "../common/ui/uiText";
import { getTimeString, numberWithCommas } from "../common/util";
import { Game } from "./game";
import { GameStyle } from "./gameStyle";

const BOARD_COLUMNS: number = 5;
const BOARD_ROWS: number = 6;

export class GameScreen extends UIScreen {
  protected tiles: any[];
  protected scoreLabel: UIText;
  protected score: number;
  protected gameOver: boolean;
  protected boardPos: any;
  protected boardWidth: number;
  protected tileWidth: number;
  protected nextTile: number;
  protected selectedColumn: number;
  protected selecting: boolean;
  protected shooting: boolean;
  protected shootTileY: number;
  protected shootRow: number;
  protected collapsing: boolean;
  protected collapsingTiles: any;
  protected collapsingPos: number;
  protected collapsingTile: number;
  protected collapsingLoc: any;
  protected falling: boolean;
  protected fallingTiles: any[];
  protected fallingPos: number;
  protected header: GameHeader;
  protected gameTime: number;
  protected boardComplete: number;
  protected footer: GameFooter;
  protected wildTimer: any;
  protected wildTile: boolean;
  protected noMoreMoves: boolean;

  public build() {
    let background = new UIPanel({
      fitParent: {left: 0, right: 0, top: 0, bottom: 0},
      // color: '#4c9ada',
      gradient: {
        startColor: '#428cd4',
        endColor: '#6ac2ea',
        type: 'vertical',
      },
      parent : this
    });

    this.header = new GameHeader({
      game: Game.instance,
      callback: (action:string)=>{
        if(action == 'restart')
          this.restart();
      },
      parent : this
    });

    this.footer = new GameFooter({
      game: Game.instance,
      callback: (id:string)=>{
        this.onPowerup(id);
      },
      parent : this
    });
  }

  public onWake() {
    super.onWake();

    this.mouseEvents = true;

    this.header.setLabels(['TILES', 'SCORE', 'TIME']);
    this.footer.loadPowerups();

    this.restart();

    Game.instance.pause();
    Game.instance.showWelcome(()=>{
      Game.instance.resume();
    });
  }

  protected restart() {
    this.stopWildTile();

    this.score = 0;
    this.gameOver = false;
    this.nextTile = 1;
    this.selectedColumn = -1;
    this.selecting = false;
    this.shooting = false;
    this.collapsing = false;
    this.falling = false;
    this.gameTime = 0;
    this.boardComplete = 0;
    this.wildTimer = 0;
    this.wildTile = false;
    this.noMoreMoves = false;

    this.tiles = [];
    for(let y = 0; y < BOARD_ROWS; y++) {
      let row = [];
      for(let x = 0; x < BOARD_COLUMNS; x++) 
        row.push(0);
      this.tiles.push(row);
    }

    this.footer.reset();
  }

  public update() {
    super.update();

    if(!Game.instance.isPaused())
      this.gameTime += this.deltaTime;

    this.header.setValue(0, `${this.boardComplete}/30`);
    this.header.setValue(1, numberWithCommas(this.score));
    this.header.setValue(2, getTimeString(this.gameTime));
    
    this.drawGame();

    if(this.shooting) {
      this.shootTileY -= (this.deltaTime * 2);
      let targetY = this.boardPos.y + (this.tileWidth * this.shootRow);
      if(this.shootTileY <= targetY) {
        this.shootTileY = targetY;
        this.placeTile();
      }
    }

    if(this.collapsing) {
      this.collapsingPos += (this.deltaTime * 0.65);
      if(this.collapsingPos >= this.tileWidth) {
        this.collapsingPos = this.tileWidth;
        this.finishCollapse();
      }
    }

    if(this.falling) {
      this.fallingPos += (this.deltaTime * 0.65);
      if(this.fallingPos >= this.tileWidth) {
        this.fallingPos = this.tileWidth;
        this.finishFalling();
      }
    }
  }

  protected getEmptyRowInColumn(col:number) {
    for(let y = 0; y < BOARD_ROWS; y++)
      if(this.tiles[y][col] == 0)
        return y;
    return -1;
  }

  protected getHighestTileOnBoard() {
    let highest = 0;
    for(let y = 0; y < BOARD_ROWS; y++) 
      for(let x = 0; x < BOARD_COLUMNS; x++) 
        if(this.tiles[y][x] > highest)
          highest = this.tiles[y][x];
    return highest;
  }

  protected isMatchingTile(tile:number, x:number, y:number) {
    if(x < 0) return false;
    if(x > BOARD_COLUMNS-1) return false;
    if(y < 0) return false;
    if(y > BOARD_ROWS-1) return false;

    if(this.tiles[y][x] == tile) 
      return true;

    return false;
  }

  protected clearTile(x:number, y:number) {
    if(x < 0) return;
    if(x > BOARD_COLUMNS-1) return;
    if(y < 0) return;
    if(y > BOARD_ROWS-1) return;
    this.tiles[y][x] = 0;
  }

  protected startWildTile() {
    this.wildTile = true;
    this.nextTile++;
    this.wildTimer = setInterval(()=>{
      let highest = Math.max(2, Math.min(8, this.getHighestTileOnBoard() + 1));
      this.nextTile++;
      if(this.nextTile > highest)
        this.nextTile = 1;
    }, 250)
  }

  protected stopWildTile() {
    clearInterval(this.wildTimer);
    this.wildTile = false;
  }

  protected shootTile() {
    this.stopWildTile();

    let emptyRow = this.getEmptyRowInColumn(this.selectedColumn);

    if(emptyRow >= 0) {
      this.shooting = true;
      this.shootTileY = this.boardPos.y + (this.tileWidth * BOARD_ROWS);
      this.shootRow = emptyRow;
    }
    else {
      let x = this.selectedColumn;
      let y = BOARD_ROWS-1;

      if(this.tiles[y][x] == this.nextTile || this.nextTile < 0) {
        this.collapsing = true;
        this.collapsingTiles = {up: false, down: true, left: false, right: false};
        this.collapsingPos = 0;
        this.collapsingTile = this.nextTile;
        this.collapsingLoc = {x, y};
        this.nextTile = 0;
      }

      this.selectedColumn = -1;
    }
  }

  protected placeTile() {
    this.tiles[this.shootRow][this.selectedColumn] = this.nextTile;

    this.startCollapse(this.selectedColumn, this.shootRow, true);

    if(this.nextTile < 0 && !this.collapsing)
      this.tiles[this.shootRow][this.selectedColumn] = 1;

    this.shooting = false;
    this.selectedColumn = -1;
    this.nextTile = 0;

    if(!this.collapsing)
      this.spawnNextTile();
  }

  protected startCollapse(column:number, row:number, first:boolean) {
    // console.log('startCollapse', column, row);

    let tile = this.tiles[row][column];
    if(tile == 0)
      return;

    this.collapsingTiles = {
      up: this.isMatchingTile(tile, column, row-1),
      down: this.isMatchingTile(tile, column, row+1),
      left: this.isMatchingTile(tile, column-1, row),
      right: this.isMatchingTile(tile, column+1, row)
    }

    if(tile < 0 && row > 0)
      this.collapsingTiles.up = true;

    let delay = first ? 0 : 75;

    if(this.collapsingTiles.up && !this.collapsingTiles.down && !this.collapsingTiles.left && !this.collapsingTiles.right) {
      row--;
      // delay = 0;

      this.collapsingTiles = {
        up: this.isMatchingTile(tile, column, row-1),
        down: this.isMatchingTile(tile, column, row+1),
        left: this.isMatchingTile(tile, column-1, row),
        right: this.isMatchingTile(tile, column+1, row)
      }
    }

    this.collapsing = (
      this.collapsingTiles.up || 
      this.collapsingTiles.down || 
      this.collapsingTiles.left || 
      this.collapsingTiles.right
    )

    if(this.collapsing) {
      this.collapsingPos = -delay;
      this.collapsingTile = tile;
      this.collapsingLoc = {x: column, y: row};
      if(this.collapsingTiles.up)
        this.clearTile(column, row-1);
      if(this.collapsingTiles.down)
        this.clearTile(column, row+1);
      if(this.collapsingTiles.left)
        this.clearTile(column-1, row);
      if(this.collapsingTiles.right)
        this.clearTile(column+1, row);
    }
  }

  protected finishCollapse() {
    // console.log('finishCollapse');
    // console.log(this.collapsingTiles);
    // console.log(this.collapsingLoc);

    this.collapsing = false;

    let count = 0;
    if(this.collapsingTiles.left) count++;
    if(this.collapsingTiles.right) count++;
    if(this.collapsingTiles.up) count++;
    if(this.collapsingTiles.down) count++;

    if(this.collapsingTile == -2) {
      if(this.tiles[this.collapsingLoc.y][this.collapsingLoc.x] > 0)
        count = -1;
      else
        count = 0;
    }

    let score = 0;
    if(this.collapsingTile == -3) {
      score = Math.pow(2, this.tiles[this.collapsingLoc.y][this.collapsingLoc.x]);
      this.tiles[this.collapsingLoc.y][this.collapsingLoc.x] = 0;
    }
    else {
      this.tiles[this.collapsingLoc.y][this.collapsingLoc.x] += count;
      score = Math.pow(2, this.tiles[this.collapsingLoc.y][this.collapsingLoc.x]);
    }

    this.score += score;

    this.updateBoardComplete();
    this.startFalling();

    if(!this.falling)
      this.finishFalling();
  }

  protected startFalling() {
    // console.log('startFalling');

    this.fallingTiles = [];
    for(let x = 0; x < BOARD_COLUMNS; x++) {
      let addTile = false;
      for(let y = 1; y < BOARD_ROWS; y++) {
        if(!addTile && this.tiles[y][x] > 0 && this.tiles[y-1][x] == 0) 
          addTile = true;
        // else if(addTile && this.tiles[y][x] == 0) {
        //   addTile = false;
        //   break;
        // }

        if(addTile && this.tiles[y][x] > 0)
          this.fallingTiles.push({x, y, value: this.tiles[y][x]});
      }
    }

    for(let i = 0; i < this.fallingTiles.length; i++) 
      this.clearTile(this.fallingTiles[i].x, this.fallingTiles[i].y);

    this.falling = this.fallingTiles.length > 0;
    this.fallingPos = 0;
  }

  protected finishFalling() {
    // console.log('finishFalling');

    this.falling = false;

    for(let i = 0; i < this.fallingTiles.length; i++) {
      let tile = this.fallingTiles[i];
      this.tiles[tile.y-1][tile.x] = tile.value;
    }

    for(let y = this.collapsingLoc.y; y >= 0; y--) {
      this.startCollapse(this.collapsingLoc.x, y, false);
      if(this.collapsing)
        return;
    }

    for(let y = 0; y < BOARD_ROWS; y++) {
      for(let x = 0; x < BOARD_COLUMNS; x++) {
        if(x == this.collapsingLoc.x) 
          continue;

        this.startCollapse(x, y, false);
        if(this.collapsing)
          return;
      }
    }

    this.spawnNextTile();
  }

  protected updateBoardComplete() {
    this.boardComplete = 0;
    for(let y = 0; y < BOARD_ROWS; y++) 
      for(let x = 0; x < BOARD_COLUMNS; x++) 
        if(this.tiles[y][x] > 0)
          this.boardComplete++;
  }

  protected spawnNextTile() {
    // console.log('spawnNextTile');

    let highest = this.getHighestTileOnBoard();
    let r = Math.random();

    highest -= 2;
    if(highest <= 0)
      highest = 1;
    // else if(r > 0.75)
    //   highest++;
    // else if(r > 0.85)
    //   highest += 2;
    
    this.nextTile = Math.floor(Math.random() * highest) + 1;

    this.updateBoardComplete();
    this.checkGameOver();
  }

  protected checkGameOver() {
    if(this.gameOver)
      return;

    this.gameOver = true;
    this.noMoreMoves = true;

    let y = BOARD_ROWS-1;
    for(let x = 0; x < BOARD_COLUMNS; x++) {
      if(this.tiles[y][x] == 0 || this.tiles[y][x] == this.nextTile) {
        this.gameOver = false;
        this.noMoreMoves = false;
      }
    }

    if(this.footer.isAnyPowerupUnused())
      this.gameOver = false;

    if(this.nextTile < 0 || this.wildTile)
      this.noMoreMoves = false;

    if(this.gameOver) {
      setTimeout(() => {
        this.showGameOver();
      }, 1000);
    }
  }

  protected showGameOver() {
    Game.instance.showGameOver(this.score, this.score, (action:string)=>{
      if(action == 'restart')
        this.restart();
    });
  }

  protected drawTile(tile:number, tx:number, ty:number, tw:number) {
    let colors = [
      '#881177', '#aa3355', '#cc6666', '#ee9944',
      '#eedd00', '#99dd55', '#44dd88', '#22ccbb',
      '#00bbcc', '#0099cc', '#3366bb', '#663399',
    ];

    let pw = tw - 6;
    let px = tx + 3;
    let py = ty + 3;
    let lw = 4;


    let color = 'white';
    if(tile > 0) {
      let ci = tile-1;
      while(ci > colors.length-1)
        ci -= colors.length;
      color = colors[ci];
    }

    let text = '';
    if(tile > 0) {
      let value = Math.pow(2, tile);
      text = value.toString();
    }
    else {
      if(tile == -1)
        text = 'x2';
      else if(tile == -2)
        text = '/2';
      else if(tile == -3)
        text = 'B';
    }

    let textx = px + (pw/2)
    let texty = py + (pw/2) + 2;
    let size = Math.floor(tw * (0.4 - (0.02 * text.length)));

    UIManager.drawPanel(px, py, pw, pw, color, tw * 0.2);   
    UIManager.drawPanel(px, py, pw, pw, '#00000060', tw * 0.2);   
    UIManager.drawPanel(px+lw, py+lw, pw-(lw*2), pw-(lw*2), color, tw * 0.15);   

    UIManager.ctx.save();
    UIManager.ctx.font = `${size}px Arial`;
    UIManager.ctx.fillStyle = 'white';
    UIManager.ctx.textAlign = 'center';
    UIManager.ctx.textBaseline = 'middle';
    UIManager.ctx.shadowColor = '#000000d0';
    UIManager.ctx.shadowBlur = 4;
    UIManager.ctx.fillText(text, textx, texty);
    UIManager.ctx.restore();
  }

  protected drawGame() {
    let cx = (this.width/2);
    let bw = Math.min(380, this.width-20);
    let bx = cx-(bw/2);
    let by = 90;
    let tw = bw / BOARD_COLUMNS;
    let bh = tw * (BOARD_ROWS + 1);

    if(UIManager.isMobile) 
      by = Math.max(60, Math.min(100, this.height - 625));

    this.boardWidth = bw;
    this.boardPos = {x: bx, y: by};
    this.tileWidth = tw;

    UIManager.drawPanel(bx, by, bw, bh, 'transparent', 10, '#00000080', 5);   

    // columns
    for(let i = 0; i < BOARD_COLUMNS; i++) {
      let color = ((i % 2) == 0) ? '#448dc7' : '#59aad8';
      let lx = bx+(tw*i);
      if(i == 0 || i == BOARD_COLUMNS-1) {
        UIManager.drawPanel(lx, by, tw, bh, color, 10);   
        let offset = i == 0 ? (tw/2) : 0;
        UIManager.drawFilledRect(lx+offset, by, tw/2, bh, color);   
      }
      else
        UIManager.drawFilledRect(lx, by, tw, bh, color);   
    }

    // selected column
    if(this.selectedColumn != -1) {
      let color = ((this.selectedColumn % 2) == 0) ? '#448dc7' : '#59aad8';
      let gradient = UIManager.ctx.createLinearGradient(bx+(tw*this.selectedColumn), by, bx+(tw*this.selectedColumn), by+bh);
      gradient.addColorStop(0, color);
      gradient.addColorStop(0.25, color);
      gradient.addColorStop(1, '#8ec9f4');
      UIManager.ctx.fillStyle = gradient;
      UIManager.ctx.fillRect(bx+(tw*this.selectedColumn), by, tw, bh-tw)
    }

    // bottom row
    UIManager.drawPanel(bx, by+(tw*BOARD_ROWS)-2, bw, 4, '#8ec9f4', 0)

    // collapsing tiles
    if(this.collapsing) {
      let pos = Math.max(0, this.collapsingPos);
      if(this.collapsingTiles.left) {
        let tx = bx + ((this.collapsingLoc.x-1) * tw) + pos;
        let ty = by + (this.collapsingLoc.y * tw);
        this.drawTile(this.collapsingTile, tx, ty, tw);
      }
      if(this.collapsingTiles.right) {
        let tx = bx + ((this.collapsingLoc.x+1) * tw) -  pos;
        let ty = by + (this.collapsingLoc.y * tw);
        this.drawTile(this.collapsingTile, tx, ty, tw);
      }
      if(this.collapsingTiles.up) {
        let tx = bx + (this.collapsingLoc.x * tw);
        let ty = by + ((this.collapsingLoc.y-1) * tw) + pos;
        this.drawTile(this.collapsingTile, tx, ty, tw);
      }
      if(this.collapsingTiles.down) {
        let tx = bx + (this.collapsingLoc.x * tw);
        let ty = by + ((this.collapsingLoc.y+1) * tw) - pos;
        this.drawTile(this.collapsingTile, tx, ty, tw);
      }
    }

    if(this.falling) {
      let pos = Math.max(0, this.fallingPos);
      for(let i = 0; i < this.fallingTiles.length; i++) {
        let tile = this.fallingTiles[i];
        let tx = bx + (tile.x * tw);
        let ty = by + (tile.y * tw) - pos;
        this.drawTile(tile.value, tx, ty, tw);
      }
    }

    // tiles
    for(let y = 0; y < BOARD_ROWS; y++) {
      for(let x = 0; x < BOARD_COLUMNS; x++) {
        if(this.tiles[y][x] == 0)
          continue;
        let tx = bx + (x * tw);
        let ty = by + (y * tw);
        this.drawTile(this.tiles[y][x], tx, ty, tw)
      }
    }

    // next tile
    if(this.nextTile != 0) {
      let col = this.selectedColumn;
      if(col == -1) 
        col = 2;
  
      let tx = bx + (col * tw);
      let ty = by + (BOARD_ROWS * tw);
  
      if(this.shooting)
        ty = this.shootTileY;
  
      this.drawTile(this.nextTile, tx, ty, tw);
    }

    // no more moves
    if(this.noMoreMoves && !this.gameOver) {
      let px = bx+(tw/2);
      let py = by+(tw/2);
      UIManager.drawPanel(px, py, bw-tw, 100, '#000000c0', 0);
      UIManager.drawRect(px, py, bw-tw, 100, 'white');
      UIManager.drawText('No More Moves!', bx + (bw/2), py + 25, 'verdana', 20, 'center', 'yellow');
      UIManager.drawText('Use a powerup or', bx + (bw/2), py + 55, 'verdana', 18, 'center', 'white');
      UIManager.drawText('tap here to end game.', bx + (bw/2), py + 77, 'verdana', 18, 'center', 'white');
    }
  }

  public onMouseDown(x:number, y:number) {
    if(this.gameOver || this.shooting || this.falling || this.collapsing || this.selecting)
      return;

    let bx = Math.floor((x - this.boardPos.x) / this.tileWidth);
    let by = Math.floor((y - this.boardPos.y) / this.tileWidth);

    if(this.noMoreMoves && bx >= 0 && bx < BOARD_COLUMNS && by >= 0 && (y - this.boardPos.y) <= 150) {
      this.gameOver = true;
      this.showGameOver();
      return;
    }

    if(bx >= 0 && bx < BOARD_COLUMNS && by >= 0 && by <= BOARD_ROWS) {
      this.selectedColumn = bx;
    }

    this.selecting = true;
  }

  public onMouseMove(x:number, y:number) {
    if(!this.selecting)
      return;

    let bx = Math.floor((x - this.boardPos.x) / this.tileWidth);
    let by = Math.floor((y - this.boardPos.y) / this.tileWidth);

    if(bx >= 0 && bx < BOARD_COLUMNS && by >= 0 && by <= BOARD_ROWS)
      this.selectedColumn = bx;
    else 
      this.selectedColumn = -1;
  }

  public onMouseUp(x:number, y:number) {
    if(this.gameOver || this.shooting || this.falling || this.collapsing)
      return;

    this.selecting = false;
    if(this.selectedColumn != -1) 
      this.shootTile();
  }

  protected onPowerup(id:string) {
    if(this.nextTile < 0 || this.wildTile)
      return;

    this.noMoreMoves = false;

    if(id == 'double-up-double') 
      this.nextTile = -1;
    else if(id == 'double-up-half') 
      this.nextTile = -2;
    else if(id == 'double-up-wild') 
      this.startWildTile();
    else if(id == 'double-up-bomb') 
      this.nextTile = -3;

    this.footer.usePowerup(id);
  }
}