import React from 'react';
import PageEnvelope from '../elements/PageEnvelope';
import { Navigate } from 'react-router-dom';
import { Database } from '../util/database';
import { Server } from '../../server/server';
import AssetPanel from '../elements/AssetPanel';
import './SitePage.css';
import './InventoryPage.css';
import { getAssetImage } from '../util/assets';
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill, BsFillXCircleFill, BsStar, BsStarFill, BsXLg } from 'react-icons/bs';
import MessageModal from '../modals/MessageModal';
import AlertModal from '../modals/AlertModal';
import Portrait from '../elements/Portrait';
import { AiFillCrown } from 'react-icons/ai';
import { BiQuestionMark } from 'react-icons/bi';
import CoinIcon from '../elements/CoinIcon';

const rarityColors = [
  '#aaaaaa',
  '#ADFF2F',
  '#00BFFF',
  '#FF1493',
  'orange',
  'yellow'
];

const rarityNames = [
  'Common',
  'Uncommon',
  'Rare',
  'Epic',
  'Legendary',
  'Celestial'
]

interface InventoryPageProps {
}

interface InventoryPageState {
  collection: string;
  sort: string;
  modal: string;
  asset: string;
  friend: string;
  quantity: number;
  alert: string;
  message: string;
  navigate: string;
}

class InventoryPage extends React.Component<InventoryPageProps, InventoryPageState> {
  protected lastAsset:string;

  constructor(props:InventoryPageProps) {
    super(props);

    this.state = {
      collection: 'all',
      sort: 'newest',
      modal: '',
      asset: '',
      friend: '',
      alert: '',
      message: '',
      navigate: '',
      quantity: 0
    };

    this.lastAsset = '';
  }

  componentDidMount() {
    let s = sessionStorage.getItem('InventoryViewAsset');
    
    if(s) {
      let asset = Database.getAsset(s);
      this.setState({modal: 'asset', asset: s, collection: asset.collection})
      sessionStorage.removeItem('InventoryViewAsset');
    }
    else {
      let collection = window.sessionStorage.getItem('InventoryCollection');
      if(collection && Database.getCollection(collection)) 
        this.setState({collection});
  
      let sort = window.sessionStorage.getItem('InventorySort');
      if(sort)
        this.setState({sort})
    }

    this.checkInventory();
  }

  checkInventory() {
    let inventory = Server.assets.getInventory();
    for(let i = 0; i < inventory.length; i++) {
      let asset = Database.getAsset(inventory[i].id);
      if(!asset) 
        console.warn('Iventory missing asset:', inventory[i].id);
    }
  }

  getInventoryForCollection(collection:string) {
    let ret = [];
    let inventory = Server.assets.getInventory();
    for(let i = 0; i < inventory.length; i++) {
      let asset = Database.getAsset(inventory[i].id);
      if(!asset) continue;
      if(collection == 'all' || asset.collection == collection)
        ret.push(inventory[i]);
    }
    return ret;
  }

  calculateCollectionBonus(): any {
    let inventory = Server.assets.getInventory();
    let collection = Database.getCollection(this.state.collection);

    if(!collection.bonus)
      return 0;

    let collectibles = [];
    for(let j = 0; j < collection.count; j++)
      collectibles.push(0);

    for(let j = 0; j < inventory.length; j++) {
      let asset = inventory[j];
      let parts = asset.id.split('-');
      if(parts[0] != collection.prefix)
        continue;
      let idx = +parts[parts.length-1];
      collectibles[idx-1] += asset.quantity;
    }

    let completed = 100000;
    for(let j = 0; j < collectibles.length; j++)
      if(completed > collectibles[j])
        completed = collectibles[j];

    return {completed, chance: (completed * collection.bonus)};
  }

  async purchaseAsset(assetId:string, quantity:number, packId:string = null) {
    let asset = Database.getAsset(assetId);
    let shopAsset = Database.getShopAsset(packId ? packId : assetId);

    let currencyQuantity = Server.assets.getInventoryQuantity(shopAsset.currency);
    if(shopAsset.price > currencyQuantity) {
      this.setState({alert: 'You do not have enough Coins to make this purchase.'});
      return;
    }

    this.setState({message: `Purchasing...`});
    
    setTimeout(async () => {
      let response = await Server.assets.purchaseAsset(assetId, quantity, packId);
      
      this.setState({message: ''});

      let assets = [];

      if(response.success) {
        if(response.assets != undefined)
          assets = response.assets;
        else
          assets = [{id: asset.id, quantity: quantity}];

        for(let i = 0; i < assets.length; i++)
          Server.assets.addAssetToInventory(assets[i].id, assets[i].quantity);

        Server.assets.removeAssetFromInventory(shopAsset.currency, shopAsset.price * quantity);

        Server.user.incrementCounter('purchase#' + asset.id, quantity);

        this.setState({alert: `Purchase successful!`});
      }
      else
        this.setState({alert: response.message});
    }, 500);
  }

  onCollectionChange(value:string) {
    this.setState({collection: value})
    window.sessionStorage.setItem('InventoryCollection', value);
  }

  onSortChange(value:string) {
    this.setState({sort: value})
    window.sessionStorage.setItem('InventorySort', value);
  }

  onAssetClick(asset:any) {
    this.setState({asset: asset.id, modal: 'asset'})
  }

  onSetBanner(asset:any) {
    this.setState({message: `Updating profile...`});
    
    setTimeout(async () => {
      let response = await Server.user.setProfile({banner: asset.id});
      this.setState({message: ''});
      if(response.success) {
        Server.user.setCachedBanner(asset.id);
        this.setState({navigate: '/profile'})
      }
      else
        this.setState({alert: response.message})
    }, 1000);
  }

  onSetPlaceBanner(asset:any) {
    this.setState({message: `Updating ${Server.places.getInfo().name}...`});
    
    setTimeout(async () => {
      await Server.places.setBanner(asset.id);
      this.setState({message: '', navigate: '/place/' + Server.user.getSlug()});
    }, 1000);
  }

  onSetPortrait(asset:any) {
    this.setState({message: `Updating profile...`});
    
    setTimeout(async () => {
      let response = await Server.user.setProfile({portrait: asset.id});
      this.setState({message: ''});
      if(response.success) {
        Server.user.setCachedPortrait(asset.id);
        this.setState({navigate: '/profile'})
      }
      else
        this.setState({alert: response.message})
    }, 1000);

  }

  onViewCollection(collection:any) {
    this.setState({collection: collection.id, modal: ''});
  }

  onSendGift() {
    if(!Server.account.isLoggedIn()) {
      this.setState({alert: 'Please login to continue.'});
      return;
    }

    this.setState({modal: 'friends'})
  }

  onSelectFriend(friend:string) {
    this.setState({modal: 'gift', friend, quantity: 1});
  }

  onDecreaseQuantity() {
    if(this.state.quantity > 1)
      this.setState({quantity: this.state.quantity - 1});
  }

  onIncreaseQuantity() {
    this.setState({quantity: this.state.quantity + 1});
  }

  onSend() {
    this.setState({modal: '', message: 'Sending gift...'});

    setTimeout(async () => {
      let response = await Server.assets.sendAsset(this.state.asset, this.state.quantity, this.state.friend);
      this.setState({message: ''});

      if(response.success) {
        Server.user.incrementCounter('gift#' + this.state.asset, this.state.quantity)
        Server.assets.removeAssetFromInventory(this.state.asset, this.state.quantity);
        Server.network.sendEvent('user:' + this.state.friend, {
          id: 'asset-sent', 
          asset: this.state.asset,
          quantity: this.state.quantity,
          user: Server.user.getId()
        });    
      }
      else
        this.setState({alert: response.message})
    }, 500);
  }

  onCraft() {
    this.setState({modal: 'craft'})
  }

  onCraftBack() {
    if(this.state.modal == 'upgrade')
      this.setState({asset: this.lastAsset});
    this.setState({modal: 'asset'});
  }

  onCraftAsset() {
    let asset = Database.getAsset(this.state.asset);
    let collection = Database.getCollection(asset.collection);
    let ingredients = Database.getRecipeIngredients(this.state.asset);
    let hasIngredients = Server.assets.hasRecipeIngredients(ingredients);

    if(!hasIngredients) {
      let noun = asset.noun ? asset.noun : collection.noun;
      this.setState({alert: `You do not have all of the ${noun.plural.toLowerCase()} needed to create ${asset.name}.`})
      return;
    }

    let label = this.state.modal == 'craft' ? 'Creating' : 'Upgrading';
    this.setState({message: `${label} ${asset.name}...`});

    setTimeout(async () => {
      let response = await Server.assets.craftAsset(this.state.asset, 1);
      this.setState({message: ''});
      if(response.success) {
        Server.assets.addAssetToInventory(this.state.asset, 1);
        for(let i = 0; i < ingredients.length; i++) 
          Server.assets.removeAssetFromInventory(ingredients[i].id, ingredients[i].quantity);

        Server.user.incrementCounter('craft#' + this.state.asset)

        if(this.state.modal == 'craft')
          this.setState({alert: `Successfully created ${asset.name}!`});
        else
          this.setState({alert: `Successfully upgraded ${asset.name} to ${rarityNames[asset.rarity]} quality!`});

        this.setState({modal: ''})
      }
      else 
        this.setState({alert: response.message})
    }, 250);
  }

  onUpgradeQuality() {
    this.lastAsset = this.state.asset;
    let asset = Database.getAsset(this.state.asset);
    this.setState({modal: 'upgrade', asset: asset.upgrade});
  }

  onBonus(asset:any) {
    this.setState({asset: asset.id, modal: 'asset'})
  }

  onPurchaseAsset() {
    let shopAsset = Database.getShopAsset(this.state.asset);
    let packId = null;
    if(!shopAsset)
      packId = Database.getPackWithAsset(this.state.asset);

    this.purchaseAsset(this.state.asset, this.state.quantity, packId)
  }

  hasCollectionBonusAsset(collection:any) {
    if(!collection.bonuses)
      return true;

    for(let i = 0; i < collection.bonuses.length; i++) 
      if(Server.assets.getInventoryAsset(collection.bonuses[i].id))
        return true;

    return false;
  }

  renderPurchaseModal() {
    let asset = Database.getAsset(this.state.asset);

    let packId = null;
    let shopAsset = Database.getShopAsset(this.state.asset);
    if(!shopAsset) {
      packId = Database.getPackWithAsset(this.state.asset);
      shopAsset = Database.getShopAsset(packId);
    }
 
    let total = shopAsset.price * this.state.quantity;

    let imageHeight = 'unset';
    let imageWidth = '130px';
    let borderRadius = '6px';
    let objectFit:any = 'unset';
    let imageMargin = '10px auto 10px auto'

    if(asset.collection == 'portraits')
      borderRadius = '50%';

    if(asset.collection == 'banners') {
      imageWidth = '100%';
      imageHeight = '120px';
      objectFit = 'cover';
      imageMargin = '0px';
    }

    let name = asset.name;
    if(name == '' || !name) {
      let collection = Database.getCollection(asset.collection);
      name = collection.noun ? collection.noun.singular : '';
    }

    return (
      <div className="modal open">
        <div className="modal-content" style={{paddingTop: '8px', maxWidth: '250px', position: 'relative'}}>
          <div className="site-page-column">
            <div>
              {name}
            </div>
            <div style={{backgroundColor: 'var(--body-background-color)', borderRadius: '10px', position: 'relative'}}>
              <img 
                style={{
                  height: imageHeight, 
                  width: imageWidth,
                  borderRadius, 
                  objectFit: objectFit,
                  margin: imageMargin,
                  display: 'block'
                }} 
                src={getAssetImage(asset)} 
              />
            </div>
            <div className="site-page-column" style={{rowGap: '5px', color: 'var(--panel-subtext-color)'}}>
              <div>How many do you want?</div>
              <div className="site-page-row" style={{justifyContent: 'center', alignItems:'center'}}>
                <div style={{transform: 'translateY(4px)'}} onClick={()=>this.onDecreaseQuantity()}><BsFillArrowLeftCircleFill size={25} /></div>
                <input style={{width: '80px', marginTop: '5px', textAlign: 'center'}} value={this.state.quantity} onChange={(e:any)=>this.setState({quantity: +e.currentTarget.value})}/>
                <div style={{transform: 'translateY(4px)'}} onClick={()=>this.onIncreaseQuantity()}><BsFillArrowRightCircleFill size={25} /></div>
              </div>
            </div>
            <div className="site-page-column" style={{rowGap: '5px', color: 'var(--panel-subtext-color)'}}>
              {this.state.quantity == 1 &&
                <div>Each costs <CoinIcon quantity={shopAsset.price} /></div>
              }
              {this.state.quantity > 1 &&
                <div>{this.state.quantity} will cost <CoinIcon quantity={total} /></div>
              }
              <div>You have <CoinIcon quantity={Server.assets.getInventoryQuantity('coin')} /></div>
            </div>
            <div style={{marginTop: '5px'}}>
              <button onClick={()=>this.onPurchaseAsset()}>Purchase</button>
            </div>
          </div>
          <BsFillXCircleFill className="site-page-header-close-button" onClick={()=>this.setState({modal: ''})}/>
          <BsFillArrowLeftCircleFill className="site-page-header-back-button" onClick={()=>this.setState({modal: 'asset'})}/>
        </div>
      </div>
    )
  }

  renderAssetModal() {
    let asset = Database.getAsset(this.state.asset);
    let quantity = Server.assets.getInventoryQuantity(this.state.asset);
    let collection = Database.getCollection(asset.collection);
    let game = Database.getGame(asset.game);

    let imageHeight = 'unset';
    let imageWidth = '130px';
    let borderRadius = '6px';
    let objectFit:any = 'unset';
    let imageMargin = '10px auto 10px auto'

    if(asset.collection == 'portraits')
      borderRadius = '50%';

    if(asset.collection == 'banners') {
      imageWidth = '100%';
      imageHeight = '120px';
      objectFit = 'cover';
      imageMargin = '0px';
    }

    let name = asset.name;
    if(name == '' || !name) 
      name = collection.noun ? collection.noun.singular : '';

    let description = asset.description;
    if(description == '' || !description) 
      description = collection.description;

    let noun = asset.noun ? asset.noun : collection.noun;
    let plural = noun ? ' ' + noun.plural.toLowerCase() : '';
    let quantityText = `You own ${quantity} of these${plural}.`;

    if(collection.bonuses) {
      let ba = collection.bonuses.find((a:any)=>a.id == asset.id);
      if(ba)
        description = `Owning this ${noun.singular.toLowerCase()} increases your chance to win a top-tier reward from any game by ${ba.bonus}%`
    }

    let showQuantity = (asset.rarity == undefined);

    let upgradeMsg = null;

    if(asset.upgrade && quantity > 0) {
      let ba = collection.bonuses.find((a:any)=>a.id == asset.id);
      if(ba) {
        let n = collection.bonuses.indexOf(ba);
        if(n != -1 && n < collection.bonuses.length-1) {
          ba = collection.bonuses[n+1];
          upgradeMsg = `Upgrade the quality of this ${noun.singular.toLowerCase()} to increase that chance to ${ba.bonus}%`;
        }
      }
    }

    let rarityDiv = null;
    let starsDiv = null;

    if(asset.rarity != undefined && quantity > 0) {
      rarityDiv = (
        <div className="site-page-row" style={{justifyContent: 'center', columnGap: '5px', backgroundColor: 'yellow', color: 'black', borderRadius: '10px'}}>
          <AiFillCrown style={{fontSize: '1.2em'}} /> {rarityNames[asset.rarity]} Quality
        </div>
      )

      let stars = [];
      for(let i = 0; i < 6; i++) 
        if(i <= asset.rarity)
          stars.push(<BsStarFill key={i} />)
        else
          stars.push(<BsStar key={i} />)

        starsDiv = (
          <div style={{backgroundColor: '#00000040', width: '100%', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px'}}>
            <div style={{color: 'yellow', display: 'flex', flexDirection: 'row', columnGap: '5px', justifyContent: 'center', alignItems: 'center', height: '30px', fontSize: '0.9em'}}>
              {stars}
            </div>
          </div>
        )
    }

    let shopAsset = Database.getShopAsset(this.state.asset);
    if(!shopAsset) {
      let pack = Database.getPackWithAsset(this.state.asset);
      if(pack)
        shopAsset = Database.getShopAsset(pack);
    }

    let price = asset.rarity == undefined && asset.collection != 'currencies' && shopAsset ? shopAsset.price : 0;

    return (
      <div className="modal open">
        <div className="modal-content" style={{paddingTop: '8px', maxWidth: '250px', position: 'relative'}}>
          <div className="site-page-column">
            <div>
              {name}
            </div>
            <div style={{backgroundColor: 'var(--body-background-color)', borderRadius: '10px', position: 'relative'}}>
              <img 
                style={{
                  height: imageHeight, 
                  width: imageWidth,
                  borderRadius, 
                  objectFit: objectFit,
                  margin: imageMargin,
                  display: 'block'
                }} 
                src={getAssetImage(asset)} 
              />
            {starsDiv}
            </div>
            <div className="site-page-column" style={{width: '96%', margin: '0px auto 5px auto'}}>
              <div className="site-page-column" style={{marginBottom: '5px'}}>
                {rarityDiv}
                {description && description != '' &&
                  <div style={{fontSize: '0.9em'}}>
                    {description}
                  </div>
                }
                {asset.recipe && quantity == 0 &&
                  <div className="site-page-subtext">{asset.name} is not available for purchase or as a game reward.  The only way to get this {noun.singular.toLowerCase()} is to create it.</div>
                }
                {upgradeMsg && 
                  <div className="site-page-subtext">{upgradeMsg}</div>
                }
                {showQuantity &&
                  <div className="site-page-subtext">{quantityText}</div>
                }
              </div>
              {price > 0 &&
                <button onClick={()=>this.setState({modal: 'purchase', quantity: 1})}>Purchase for <CoinIcon quantity={price} /></button>
              }
              {upgradeMsg &&
                <button onClick={()=>this.onUpgradeQuality()}>Upgrade Quality</button>
              }
              {game &&
                <button onClick={()=>this.setState({navigate: '/game/' + game.id})}>Play {game.name}</button>
              }
              {collection.collectible && this.state.collection != collection.id &&
                <button onClick={()=>this.onViewCollection(collection)}>View Collection</button>
              }
              {collection.portrait && quantity > 0 &&
                <button onClick={()=>this.onSetPortrait(asset)}>Set as Profile Portrait</button>
              }
              {asset.collection == 'banners' && 
                <button onClick={()=>this.onSetBanner(asset)}>Set as Profile Banner</button>
              }
              {asset.collection == 'banners' &&
                <button onClick={()=>this.onSetPlaceBanner(asset)}>Set as Place Banner</button>
              }
              {quantity > 0 && 
                <button onClick={()=>this.onSendGift()}>Send to Friend</button>
              }
              {asset.recipe && quantity == 0 &&
                <button onClick={()=>this.onCraft()}>Create {asset.name}</button>
              }
            </div>
          </div>
          <BsFillXCircleFill className="site-page-header-close-button" onClick={()=>this.setState({modal: ''})}/>
        </div>
      </div>
    )
  }

  renderIngredient(id:string) {
    let ingredientAsset = Database.getAsset(id);
    let quantity = Server.assets.getInventoryQuantity(id);
    return (
      <div key={Math.random()} style={{position: 'relative'}}>
        <img 
          src={getAssetImage(ingredientAsset)} 
          style={{
            display: 'block',
            width: '35px', 
            borderRadius: '6px',
            filter: `brightness(${quantity > 0 ? '100%' : '50%'}) contrast(${quantity > 0 ? '100%' : '50%'}) grayscale(${quantity > 0 ? 0 : 1})`, 
          }} 
        />
        {quantity == 0 &&
          <div style={{color: 'yellow', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -40%)', fontSize: '1.2em'}}>
            <BiQuestionMark />
          </div>
        }
      </div>
    )
  }

  renderCraftModal() {
    let asset = Database.getAsset(this.state.asset);
    let quantity = Server.assets.getInventoryQuantity(this.state.asset);
    let collection = Database.getCollection(asset.collection);
    let noun = asset.noun ? asset.noun : collection.noun;

    let imageHeight = 'unset';
    let imageWidth = '130px';
    let borderRadius = '6px';
    let objectFit:any = 'unset';
    let imageMargin = '10px auto 10px auto'

    if(asset.collection == 'portraits')
      borderRadius = '50%';

    if(asset.collection == 'banners') {
      imageWidth = '100%';
      imageHeight = '120px';
      objectFit = 'cover';
      imageMargin = '0px';
    }

    let name = asset.name;
    if(name == '' || !name) 
      name = collection.noun ? collection.noun.singular : '';

    let description = asset.description;
    if(description == '' || !description) 
      description = collection.description;

    if(collection.bonuses) {
      let ba = collection.bonuses.find((a:any)=>a.id == asset.id);
      if(ba)
        description = `Owning ${asset.name} will increase your chance to win a top-tier reward from any game by ${ba.bonus}%.`
    }

    let ingredients = Database.getRecipeIngredients(asset.id);
    let ingredientDivs = [];
    for(let i = 0; i < ingredients.length; i++) {
      if(ingredients[i].id == this.lastAsset) continue;
      ingredientDivs.push(this.renderIngredient(ingredients[i].id));
    }

    let rarityDiv = null;
    if(asset.rarity != undefined && ((this.state.modal == 'craft' && quantity > 0) || this.state.modal =='upgrade')) {
      rarityDiv = (
        <div className="site-page-row" style={{justifyContent: 'center', columnGap: '5px', backgroundColor: rarityColors[asset.rarity], color: 'black', borderRadius: '10px'}}>
          <AiFillCrown style={{fontSize: '1.2em'}} /> {rarityNames[asset.rarity]} Quality
        </div>
      )
    }

    let title = this.state.modal == 'craft' ? 'Create' : 'Upgrade';

    let ingredientMessage = null;
    if(this.state.modal == 'craft')
      ingredientMessage = `One of each other ${noun.singular.toLowerCase()} in the collection will be consumed to create ${asset.name}.`
    else
      ingredientMessage = `One of each other ${noun.singular.toLowerCase()} in the collection will be consumed when upgrading ${asset.name} to ${rarityNames[asset.rarity]} Quality.`

    return (
      <div className="modal open">
        <div className="modal-content" style={{paddingTop: '8px', maxWidth: '250px', position: 'relative'}}>
          <div className="site-page-column">
            <div>
              {title} {name}
            </div>
            <div style={{backgroundColor: 'var(--body-background-color)', borderRadius: '10px'}}>
              <img 
                style={{
                  height: imageHeight, 
                  width: imageWidth,
                  borderRadius, 
                  objectFit: objectFit,
                  margin: imageMargin,
                  display: 'block',
                }} 
                src={getAssetImage(asset)} 
              />
            </div>
            {rarityDiv &&
              <div>{rarityDiv}</div>
            }
            <div style={{fontSize: '0.9em'}}>
              {ingredientMessage}
            </div>
            <div style={{display: 'flex', flexDirection: 'row', rowGap: '6px', columnGap: '6px', flexWrap: 'wrap', padding: '5px', backgroundColor: 'var(--body-background-color)', borderRadius: '10px'}}>
              {ingredientDivs}
            </div>
            <button onClick={()=>this.onCraftAsset()}>
              {this.state.modal == 'craft' ? 'Create' : 'Upgrade'}
            </button>
          </div>
          <BsFillXCircleFill className="site-page-header-close-button" onClick={()=>this.setState({modal: ''})}/>
          <BsFillArrowLeftCircleFill className="site-page-header-back-button" onClick={()=>this.onCraftBack()}/>
        </div>
      </div>
    )
  }

  renderFriendsModal() {
    let friends = [...Server.user.getFriends()];

    friends.sort((a, b)=>{
      let aonline = a.presence && a.presence.online;
      let bonline = b.presence && b.presence.online;
      if(aonline && !bonline)
        return -1;
      else if(!aonline && bonline)
        return 1;

      if(a.name < b.name)
        return -1;
      else if(a.name > b.name)
        return 1;

      return 0;
    })

    let divs = [];
    for(let i = 0; i < friends.length; i++) {
      let friend = Server.public.getProfile(friends[i].id);
      divs.push(
        <div key={i} className="site-page-row" style={{cursor: 'pointer'}} onClick={()=>this.onSelectFriend(friends[i].id)}>
          <Portrait user={friends[i].id} />
          {friend.name}
        </div>
      )
    }

    return (
      <div className="modal open">
        <div className="modal-content" style={{paddingTop: '8px', maxWidth: '250px', position: 'relative'}}>
          <div className="site-page-column">
            <div>
              Select Friend
            </div>
            <div style={{overflow: 'auto', maxHeight: '300px', backgroundColor: 'var(--body-background-color)', borderRadius: '10px'}}>
              <div className="site-page-column" style={{rowGap: '5px', margin: '10px', width: '90%'}} >
                {divs}
              </div>
            </div>
          </div>
          <BsFillXCircleFill className="site-page-header-close-button" onClick={()=>this.setState({modal: ''})}/>
          <BsFillArrowLeftCircleFill className="site-page-header-back-button" onClick={()=>this.setState({modal: 'asset'})}/>
        </div>
      </div>
    )
  }

  renderSendGiftModal() {
    let asset = Database.getAsset(this.state.asset);
    let collection = Database.getCollection(asset.collection);
    let inventoryAsset = Server.assets.getInventoryAsset(this.state.asset);
    let friend = Server.public.getProfile(this.state.friend);

    let name = asset.name ? asset.name : collection.noun.plural;

    return (
      <div className="modal open">
        <div className="modal-content" style={{paddingTop: '8px', maxWidth: '250px', position: 'relative'}}>
          <div style={{marginBottom: '10px'}}>
            Send Gift
          </div>
          <div className="site-page-column" style={{textAlign: 'center', marginBottom: '20px', color: 'var(--panel-subtext-color)'}}>
            <div>How many <span style={{color: 'var(--panel-color)'}}>{name}</span> do you want to send to <span style={{color: 'var(--panel-color)'}}>{friend.name}</span>?</div>
            <div className="site-page-row" style={{justifyContent: 'center', alignItems:'center'}}>
              <div style={{transform: 'translateY(4px)'}} onClick={()=>this.onDecreaseQuantity()}><BsFillArrowLeftCircleFill size={25} /></div>
              <input style={{width: '80px', marginTop: '5px', textAlign: 'center'}} value={this.state.quantity} onChange={(e:any)=>this.setState({quantity: +e.currentTarget.value})}/>
              <div style={{transform: 'translateY(4px)'}} onClick={()=>this.onIncreaseQuantity()}><BsFillArrowRightCircleFill size={25} /></div>
            </div>
            <div className="site-page-subtext">You own {inventoryAsset.quantity} of these {collection.noun.plural.toLowerCase()}.</div>
            {this.state.quantity > inventoryAsset.quantity && 
              <div className="site-page-notification-panel">You do not own that many!</div>
            }
          </div>
          <div style={{textAlign: 'center'}} >
            <button disabled={this.state.quantity > inventoryAsset.quantity} onClick={()=>this.onSend()}>Send</button>
          </div>
          <BsFillXCircleFill className="site-page-header-close-button" onClick={()=>this.setState({modal: ''})}/>
          <BsFillArrowLeftCircleFill className="site-page-header-back-button" onClick={()=>this.setState({modal: 'friends'})}/>
        </div>
      </div>
    )
  }

  renderHeader() {
    let inventory = Server.assets.getInventory();

    let totalQuantity = 0;
    for(let i = 0; i < inventory.length; i++) {
      let asset = Database.getAsset(inventory[i].id);
      if(asset && asset.collection != 'currencies')
        totalQuantity += inventory[i].quantity;
    }

    let options = [<option key={-1} value="all">All ({totalQuantity})</option>];

    let collections:any = Database.getCollections();
    let cids = Object.keys(collections);

    for(let i = 0; i < cids.length; i++) {
      let collection = collections[cids[i]];

      if(!collection.filter)
          continue;

      let assets = Database.getAssets(cids[i]);
      inventory = this.getInventoryForCollection(cids[i]);

      let totalQuantity = 0;
      for(let i = 0; i < inventory.length; i++)
        totalQuantity += inventory[i].quantity;

      let quantity = totalQuantity > 0 ? ` (${totalQuantity})` : null;
      // if(collection.bonus)
      //   quantity = ` (${inventory.length} of ${assets.length})`;

      options.push(
        <option key={i} value={cids[i]}>{collection.name}{quantity}</option>
      )
    }

    return (
      <div className="site-page-panel" style={{display: 'grid', gridTemplateColumns: '5fr 2fr', columnGap: '10px'}}>
          <div>
            <select style={{width: '100%'}} value={this.state.collection} onChange={(e:any)=>this.onCollectionChange(e.currentTarget.value)}>
              {options}
            </select>
          </div>
          <div>
            <select value={this.state.sort} onChange={(e:any)=>this.onSortChange(e.currentTarget.value)} style={{width: '100%'}}>
              <option value="newest">Newest</option>
              <option value="oldest">Oldest</option>
              <option value="most">Most</option>
              <option value="least">Least</option>
              <option value="a-z">A-Z</option>
              <option value="z-a">Z-A</option>
            </select>
          </div>
      </div>
    )
  }

  renderAssets() {
    let assets = Database.getAssets(this.state.collection);
    let collection = Database.getCollection(this.state.collection);
    let divs = [];

    let sortedInventory = [];
    for(let i = 0; i < assets.length; i++) {
      if(assets[i].visible == false)
        continue;

      let ia = Server.assets.getInventoryAsset(assets[i].id);

      if(collection && collection.collectible && !ia && assets[i].rarity == undefined)
        ia = {id: assets[i].id, quantity: 0, date: ''};

      if(!ia)
        continue;

      sortedInventory.push({
        ...ia,
        asset: assets[i]
      })
    }

    if(collection && collection.bonuses && !this.hasCollectionBonusAsset(collection)) {
      sortedInventory.push({
        id: collection.bonuses[0].id, 
        quantity: 0, 
        date: '',
        asset: Database.getAsset(collection.bonuses[0].id)
      })
    }

    if(sortedInventory.length == 0)
      return(<div className="site-page-panel site-page-subtext">You do not have anything in your inventory yet.</div>);

    sortedInventory.sort((a, b)=>{
      if(this.state.sort == 'newest') {
        if(a.date > b.date)
          return -1;
        else if(a.date < b.date)
          return 1;

        if(a.asset.rarity == undefined && b.asset.rarity != undefined)
          return 1;
        else if(a.asset.rarity != undefined && b.asset.rarity == undefined)
          return -1;
  
        if(a.asset.name > b.asset.name)
          return 1;
        else if(a.asset.name < b.asset.name)
          return -1;
      }

      if(this.state.sort == 'oldest') {
        if(a.date < b.date)
          return -1;
        else if(a.date > b.date)
          return 1;

        if(a.asset.rarity == undefined && b.asset.rarity != undefined)
          return 1;
        else if(a.asset.rarity != undefined && b.asset.rarity == undefined)
          return -1;
  
        if(a.asset.name > b.asset.name)
          return 1;
        else if(a.asset.name < b.asset.name)
          return -1;
      }

      if(this.state.sort == 'a-z') {
        if(!a.asset.name && b.asset.name)
          return 1;
        else if(a.asset.name && !b.asset.name)
          return -1;

        if(a.asset.name < b.asset.name)
          return -1;
        else if(a.asset.name > b.asset.name)
          return 1;

        if(a.asset.id < b.asset.id)
          return -1;
        else if(a.asset.id > b.asset.id)
          return 1;
      }

      if(this.state.sort == 'z-a') {
        if(!a.asset.name && b.asset.name)
          return -1;
        else if(a.asset.name && !b.asset.name)
          return 1;

        if(a.asset.name > b.asset.name)
          return -1;
        else if(a.asset.name < b.asset.name)
          return 1;

        if(a.asset.id > b.asset.id)
          return -1;
        else if(a.asset.id < b.asset.id)
          return 1;
      }

      if(this.state.sort == 'least') {
        if(a.quantity < b.quantity)
          return -1;
        else if(a.quantity > b.quantity)
          return 1;

        if(a.asset.rarity == undefined && b.asset.rarity != undefined)
          return 1;
        else if(a.asset.rarity != undefined && b.asset.rarity == undefined)
          return -1;

        if(a.asset.name > b.asset.name)
          return 1;
        else if(a.asset.name < b.asset.name)
          return -1;
      }

      if(this.state.sort == 'most') {
        if(a.quantity > b.quantity)
          return -1;
        else if(a.quantity < b.quantity)
          return 1;

        if(a.asset.rarity == undefined && b.asset.rarity != undefined)
          return 1;
        else if(a.asset.rarity != undefined && b.asset.rarity == undefined)
          return -1;

        if(a.asset.name > b.asset.name)
          return 1;
        else if(a.asset.name < b.asset.name)
          return -1;
      }
    })

    for(let i = 0; i < sortedInventory.length; i++) {
      let asset = sortedInventory[i].asset;
      divs.push(
        <AssetPanel
          key={Math.random()}
          asset={asset}
          quantity={sortedInventory[i].quantity}
          showQuantity={collection && collection.bonus}
          navigate={true}
          onClick={()=>this.onAssetClick(asset)}
        />
      )
    }

    if(divs.length == 0) 
      return(<div className="site-page-panel site-page-subtext">You do not have any {collection.name} yet.</div>);

    return (
      <div className="inventory-page-grid">
        {divs}
      </div>      
    );
  }

  renderBonus() {
    if(this.state.collection == 'all')
      return null;

    let collection = Database.getCollection(this.state.collection);
    if(!collection.bonus)
      return null;

    if(!collection.bonuses)
      return null;

    // let msg = `Complete this collection for a ${collection.bonus}% bonus chance to earn a reward from playing any game!`;

    // let bonus = this.calculateCollectionBonus();
    // if(bonus.chance > 0) 
    //   msg = `You have completed this collection ${bonus.completed} time${bonus.completed > 1 ? 's' : ''} and increased your chances of earning a reward by ${bonus.chance}%.  Complete this collection again for an additional ${collection.bonus}%.`;

    // msg = 'Gort will improve your chance to win rewards from games but must be crafted first!  Tap him to learn more.'

    let ownedBonusAsset = null;
    for(let i = 0; i < collection.bonuses.length; i++) 
      if(Server.assets.getInventoryQuantity(collection.bonuses[i].id) > 0)
        ownedBonusAsset = Database.getAsset(collection.bonuses[i].id);   
      
    if(ownedBonusAsset && ownedBonusAsset.rarity == 5)
      return null;

    let startingBonusAsset = Database.getAsset(collection.bonuses[0].id);
    let bonusAsset = ownedBonusAsset ? ownedBonusAsset : startingBonusAsset;

    let msg = `${startingBonusAsset.name} will improve your chances at better game rewards!  Tap to learn more.`

    if(ownedBonusAsset && ownedBonusAsset.rarity < 5) 
      msg = `Upgrade the quality of ${startingBonusAsset.name} to improve your chances at better game rewards!`

    return (
      <div className="site-page-info-panel" style={{cursor: 'pointer'}} onClick={()=>this.onBonus(bonusAsset)}>
        <div className="site-page-row" style={{maxWidth: '540px'}}>
          <img src={getAssetImage(startingBonusAsset)} style={{width: '70px', borderRadius: '10px'}} />
          {msg}
        </div>
      </div>
    )
  }

  render() {
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />

    if(window.location.search != '')
      return <Navigate to="/inventory" replace={true} />

    return (
      <div className="site-page">
        <PageEnvelope width="660px">
          <div className="site-page-column">
            {this.renderHeader()}
            {this.renderBonus()}
            {this.renderAssets()}
          </div>
        </PageEnvelope>
        {this.state.modal == 'asset' && this.renderAssetModal()}
        {this.state.modal == 'friends' && this.renderFriendsModal()}
        {this.state.modal == 'gift' && this.renderSendGiftModal()}
        {this.state.modal == 'craft' && this.renderCraftModal()}
        {this.state.modal == 'upgrade' && this.renderCraftModal()}
        {this.state.modal == 'purchase' && this.renderPurchaseModal()}
        <MessageModal message={this.state.message}/>
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''}) }/>
      </div>
    )
  }
}

export default InventoryPage;
