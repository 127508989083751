import { UIImage } from './uiImage';
import { UIPanelButton, UIPanelButtonOptions } from './uiPanelButton';

export interface UICheckboxOptions extends UIPanelButtonOptions {
  checkmarkUrl: string;
}

export class UICheckbox extends UIPanelButton {
  public checked: boolean;

  protected image: UIImage;

  constructor(options: UICheckboxOptions) {
    options.toggle = true;
    options.textHorizontalAlign = 'left';

    super(options);

    this.checked = false;

    this.image = new UIImage({
      url: options.checkmarkUrl,
      anchor: {x: 0.5, y: 0.5},
      pivot: {x: 0.5, y: 0.5},
      visible: false,
      parent: this
    });
  }

  protected doCallback() {
    this.forcePressed(!this.checked);
    super.doCallback();
  }

  public forcePressed(b:boolean) {
    super.forcePressed(b);
    this.checked = b;
    this.image.visible = b;
  }

}