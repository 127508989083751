import React from 'react';
import { getAssetImage } from '../util/assets';
import { Database } from '../util/database';
import { BsFillStarFill, BsHammer, BsStar, BsStarFill, BsTools, BsWrench, BsWrenchAdjustable } from 'react-icons/bs';
import { BiChevronsUp, BiSolidCrown, BiSolidUpvote } from 'react-icons/bi';
import { Server } from '../../server/server';
import { AiFillCrown } from 'react-icons/ai';

interface AssetPanelProps {
  asset:any;
  quantity:number;
  showQuantity?:boolean;
  showName?:boolean;
  width?:string;
  onClick?: Function;
  navigate?: boolean;
}

class AssetPanel extends React.Component<AssetPanelProps, {}> {
  constructor(props:AssetPanelProps) {
    super(props);
  }

  onClick(e:any) {
    if(this.props.navigate != null && this.props.navigate == false) {
      e.stopPropagation();
      e.preventDefault();
    }
    
    if(this.props.onClick)
      this.props.onClick();
  }

  render() {
    if(!this.props.asset) 
      return null;

    let width = this.props.width ? this.props.width : 'unset';
    let showQuantity = true;
    if(this.props.showQuantity != null && this.props.showQuantity == false)
      showQuantity = false;
    else if(this.props.showQuantity == null && this.props.quantity <= 1)
      showQuantity = false;

    let borderRadius = '6px';
    if(this.props.asset.collection == 'portraits')
      borderRadius = '50%';

    let imageHeight = '100%';
    let imageWidth = '100%';

    if(this.props.asset.collection == 'portraits') {
      imageHeight = '85%';
      imageWidth = '85%';
    } 

    let imageMargin = '0px';
    if(this.props.asset.collection == 'portraits')
      imageMargin = '7.5%';

    let assetName = this.props.asset.name;
    if(!assetName) {
      let collection = Database.getCollection(this.props.asset.collection);
      if(collection.noun)
        assetName = collection.noun.singular;
    }

    let showName = this.props.showName != undefined ? this.props.showName : true;

    let backgroundColor = 'var(--body-background-color)';
    if(this.props.asset.collection == 'banners')
      backgroundColor = 'black';

    let rarityColors = [
      '#aaaaaa',
      '#ADFF2F',
      '#00BFFF',
      '#FF1493',
      'orange',
      'yellow'
    ];

    let rarityColor = 'white';
    let panelBorder = 'unset';
    if(this.props.asset.rarity != null) {
      rarityColor = rarityColors[this.props.asset.rarity];
      rarityColor = 'yellow';
      if(this.props.quantity == 0)
        rarityColor = 'var(--header-panel-background-color)';
      panelBorder = '2px solid ' + rarityColor;
    }

    let showCraftIcon = false;
    let showUpgradeIcon = false;
    let showRarity = false;

    if(this.props.asset.rarity != null) {
      if(this.props.quantity == 0) {
        showCraftIcon = true;
      }
      else if(this.props.asset.upgrade) {
        let ingredients = Database.getRecipeIngredients(this.props.asset.upgrade);
        showUpgradeIcon = Server.assets.hasRecipeIngredients(ingredients);
      }

      showRarity = (!showCraftIcon);
    }

    let rarityDiv = null;
    if(showRarity) {
      let stars = [];
      for(let i = 0; i < 6; i++) 
        if(i <= this.props.asset.rarity)
          stars.push(<BsStarFill key={i} />)
        else
          stars.push(<BsStar key={i} />)

        rarityDiv = (
        <div style={{backgroundColor: '#00000080', width: '100%', position: 'absolute', bottom: '0px', left: '0px', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px'}}>
          <div style={{color: 'yellow', display: 'flex', flexDirection: 'row', columnGap: '5px', justifyContent: 'center', alignItems: 'center', height: '20px', fontSize: '0.7em'}}>
            {stars}
          </div>
        </div>
      )
    } 

    return (
      <div className="site-page-panel" style={{width: width, cursor: 'pointer', textDecoration: 'none', padding: '5px', border: panelBorder}} onClick={(e:any)=>this.onClick(e)}>
        <div className="site-page-column" style={{rowGap: '6px', position: 'relative'}}>
          <div style={{width: '100%', aspectRatio: '1', backgroundColor, borderRadius: '6px', position: 'relative'}} >
            <img 
              style={{
                filter: `contrast(${this.props.quantity > 0 ? '100%' : '65%'}) grayscale(${this.props.quantity > 0 ? 0 : 1}) blur(${this.props.quantity > 0 ? '0' : '1px'})`, 
                height: imageHeight, 
                width: imageWidth,
                aspectRatio: '1', 
                borderRadius, 
                objectFit: 'cover', 
                position: 'absolute', 
                margin: imageMargin,
                top: '0px', 
                left: '0px'
              }} 
              src={getAssetImage(this.props.asset)} 
            />
            {showCraftIcon && 
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '30px', height: '30px', backgroundColor: '#000000a0', borderRadius: '50%', position: 'absolute', bottom: '3px', right: '3px'}}>
                <BsWrenchAdjustable style={{color: 'white', fontSize: '1.0em'}} />
              </div>
            }
            {showUpgradeIcon && 
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '30px', height: '30px', backgroundColor: '#000000a0', borderRadius: '50%', position: 'absolute', top: '3px', left: '3px'}}>
                <BiSolidUpvote style={{color: 'lightgreen', fontSize: '1.0em'}} />
              </div>
            }
            {rarityDiv}
          </div>
          {assetName && showName &&
            <div style={{textAlign: 'center', fontSize: '0.9em'}}>
              {assetName}
            </div>
          }
          {showQuantity &&
            <div style={{minWidth: '15px', textAlign: 'center', borderTopRightRadius: '6px', borderBottomLeftRadius: '6px', fontSize: '0.7em', position: 'absolute', top: '0px', right: '0px', backgroundColor: '#000000a0', padding: '3px'}}>
              {this.props.quantity}
            </div>
          }
        </div>
      </div>
    )
  }
}

export default AssetPanel;