import { Service } from './service';

export class GamesService extends Service {
  protected leaderboardCache: any[] = [];
  protected scoreCache: any[] = [];
  protected scoresCache: any[] = [];

  public clear() {
    this.clearCaches();
  }

  protected isCacheDateExpired(ds:string, seconds:number) {
    let now = new Date();
    let then = new Date(ds);
    let diff = now.getTime() - then.getTime();
    return (diff >= seconds * 1000);
  }

  public async submitScore(params: {game?:string, event?:string, score:number, reward: number, place:boolean}) {
    let response = await this.sendCommand('games', 'submit-score-2', params);

    if(response.status != 200)
      return {success: false, message: response.body.message};

    let ret = JSON.parse(JSON.stringify(response.body));
    ret.success = true;

    return ret;
  }

  public async getLeaderboard(game:string, period:string = '') {
    let existing = this.leaderboardCache.find((c)=>c.game == game && c.period == period);

    if(existing) {
      if(!this.isCacheDateExpired(existing.date, 60))
        return {success: true, leaderboard: existing.leaderboard};
      else {
        let i = this.leaderboardCache.indexOf(existing);
        this.leaderboardCache.splice(i, 1);
      }
    }

    let response = await this.sendCommand('public', 'get-leaderboard-2', {game, period});

    if(response.status != 200)
      return {success: false, message: response.body.message};

    let date = new Date().toISOString();
    this.leaderboardCache.push({date, game, period, leaderboard: response.body.leaderboard})

    return {success: true, leaderboard: response.body.leaderboard};
  }

  public async getScore(game:string, period:string = '') {
    let existing = this.scoreCache.find((c)=>c.game == game && c.period == period);
    if(existing) {
      if(!this.isCacheDateExpired(existing.date, 60))
        return {success: true, score: existing.score};
      else {
        let i = this.scoreCache.indexOf(existing);
        this.scoreCache.splice(i, 1);
      }
    }

    let response = await this.sendCommand('games', 'get-score', {game, period});

    if(response.status != 200)
      return {success: false, message: response.body.message};

    let date = new Date().toISOString();
    this.scoreCache.push({date, game, period, score: response.body.score});

    return {success: true, score: response.body.score};
  }

  public async getScores(user:string, period:string = '') {
    let existing = this.scoresCache.find((c)=>c.user == user && c.period == period);

    if(existing) {
      if(!this.isCacheDateExpired(existing.date, 60))
        return {success: true, scores: existing.scores};
      else {
        let i = this.scoresCache.indexOf(existing);
        this.scoresCache.splice(i, 1);
      }
    }

    let response = await this.sendCommand('games', 'get-scores', {user, period});

    if(response.status != 200)
      return {success: false, message: response.body.message};

    let date = new Date().toISOString();
    this.scoresCache.push({date, user, period, scores: response.body.scores});

    return {success: true, scores: response.body.scores};
  }

  public clearCaches(game?:string) {
    this.scoresCache = [];

    if(game) {
      for(let i = 0; i < this.scoreCache.length; i++) {
        if(this.scoreCache[i].game == game) {
          this.scoreCache.splice(i, 1);
          i--;
        }
      }
      for(let i = 0; i < this.leaderboardCache.length; i++) {
        if(this.leaderboardCache[i].game == game) {
          this.leaderboardCache.splice(i, 1);
          i--;
        }
      }
    }
    else {
      this.leaderboardCache = [];
      this.scoreCache = [];
    }
  }

  // recently played
  public getRecentlyPlayedGames() {
    let games = [];
    let s = localStorage.getItem('RecentGames');
    if (s) 
      games = JSON.parse(s);
    return games;
  }

  public addRecentlyPlayedGame(id:string) {
    let s = localStorage.getItem('RecentGames');
    let games = [];
    if(s) 
      games = JSON.parse(s);

    let i = games.indexOf(id);
    if(i != -1)
      games.splice(i, 1);

    games.unshift(id);

    localStorage.setItem('RecentGames', JSON.stringify(games));
  }

  // dailies
  public async claimDailyReward() {
    let response = await this.sendCommand('games', 'claim-daily-reward-2');
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true, reward: response.body.reward};
  }
}
