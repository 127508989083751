import React from 'react';
import { Server } from '../../server/server';
import MediaViewModal from '../modals/MediaViewModal';
import AlertModal from '../modals/AlertModal';
import MessageModal from '../modals/MessageModal';
import PageEnvelope from '../elements/PageEnvelope';
import './MediaPage.css'
import './SitePage.css'
import { Navigate } from 'react-router-dom';

interface MediaPageState {
  files: any;
  data: any;
  openView: boolean;
  message: string;
  alert: string;
}

class MediaPage extends React.Component<{}, MediaPageState> {

  allFiles: any;
  filterSelected = 0;

  constructor(props: any) {
    super(props);
    this.state = {
      files: '',
      data: null,
      openView: false,
      message: '',
      alert: '',
    }

    this.onClose = this.onClose.bind(this);
    this.onSelectFileChange = this.onSelectFileChange.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  componentDidMount() {
    this.getFiles();
  }

  async getFiles() {
    let response = await Server.media.getFiles();
    if (!response.success)
      return;

    this.allFiles = response.files;
    this.setState({ files: response.files });
    this.getFilesByFilter(this.filterSelected);
  }

  getFilesByFilter(filter: number) {
    let type = '';
    if (filter == 1)
      type = 'image'
    else if (filter == 2)
      type = 'audio'
    // else if (filter == 3)
    //   type = 'video'

    let files = this.allFiles.filter((item: any) => {
      return item.metadata.type.indexOf(type) == 0;
    });

    this.setState({ files: files });
  }

  onFilter(index: number) {
    this.filterSelected = index;
    this.getFilesByFilter(index);
  }

  //-----------------------------------------
  // Pick a media file from local machine

  onSelectFileChange(e: React.FormEvent<HTMLInputElement>): void {
    this.uploadFile(e.currentTarget.files[0]);
  };

  async uploadFile(file: any) {
    if (!file) return;

    if (file.size > 5242880) { // 5 MB
      this.setState({alert: 'File must be at most 5MB size.'});
      return;
    }

    if (file.type.indexOf('image') == -1) {
      this.setState({alert: 'Only images can be uploaded to the Media Library at this time.'});
      return;
    }

    this.setState({message: 'Uploading file...'})

    let response = await Server.media.uploadFile(file);

    if (response.success) {
      this.getFiles();
      this.setState({message: ''})
    }
    else
      this.setState({message: '', alert: response.message})
  }

  selectFile() {
    const fileElem = document.getElementById("MediaPageInput");
    if (fileElem)
      fileElem.click();
  }

  openView(data: any) {
    this.setState({ data: null });
    setTimeout(() => {
      this.setState({ openView: true, data: data });
    }, 100);
  }

  onClose(isDelete: boolean) {
    this.setState({openView: false});
    if (isDelete)
      this.getFiles();
  }

  renderFiles() {
    let files = this.state.files;
    if (files.length == 0)
      return (<div>No media files yet.</div>)

    let divs = [];
    for (let i = 0; i < files.length; i++) {
      let url  = Server.media.getFileUrl(files[i]);
      let data = {...files[i], ...{url: url}};

      divs.push(
        <div key={Math.random()}>
          <div className='media-page-media-container' onClick={()=>this.openView(data)}>
            <img className='media-page-image' src={data.url} />
          </div>
          <div className='media-page-media-name'>{data.name}</div>
        </div>
      )
    }

    return divs;
  }

  render() {
    if(!Server.account.isLoggedIn())
      return(<Navigate replace to="/" />);
      
    // if (!this.state.files)
    //   return (<div className="media-page">Loading...</div>);

    return (
      <div className="site-page">
        <PageEnvelope>
          <div style={{marginBottom: '10px', textAlign: 'right'}}>
            <input
              type="file"
              id="MediaPageInput"
              accept="image/*"
              className="media-page-file-select"
              onChange={this.onSelectFileChange}
            />
            <button onClick={() => this.selectFile()}>Add Image</button>
          </div>

          <div className='site-page-panel media-page-files'>
            {this.state.files && this.renderFiles()}
            {!this.state.files && <div>Loading</div>}
          </div>
        </PageEnvelope>

        {this.state.data &&
          <MediaViewModal open={this.state.openView} data={this.state.data} onClose={this.onClose} />
        }
        
        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert:''})}/>
      </div>
    );
  }
}

export default MediaPage;