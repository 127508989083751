import { Server } from './server';

export class NetworkService {
  protected socket: WebSocket;
  protected disconnecting: boolean;
  protected connected: boolean;
  protected listeners: any[];
  protected presence: string;
  protected url: string;
  protected reconnects: number;
  protected pingTime: number;
  protected awayTime: number;
  protected lastCheckNetworkTime: number;

  constructor() {
    this.socket = null;
    this.disconnecting = false;
    this.connected = false;
    this.listeners = [];
    this.presence = '';
    this.reconnects = 0;
    this.pingTime = 0;
    this.awayTime = 0;
    this.lastCheckNetworkTime = 0;
    this.onMessage = this.onMessage.bind(this);
    this.checkNetwork();
  }

  public configure(network:string) {
    this.url = 'wss://' + network;
  }

  public addEventListener(id:string, callback:Function) {
    this.listeners.push({id, callback});
  }

  public removeEventListener(id:string, callback:Function) {
    for(let i = 0; i < this.listeners.length; i++) {
      if(this.listeners[i].id == id && this.listeners[i].callback == callback) {
        this.listeners.splice(i, 1);
        return;
      }
    }
  }

  public async connect() {
    this.disconnecting = false;

    let accessToken = Server.account.getAccessToken();
    let isProduction = (window.location.href.indexOf('www.') != -1);

    return new Promise((resolve, reject) => {
      this.socket = new WebSocket(this.url + '?Authorization=' + accessToken);

      this.socket.onopen = (event)=>{
        if(!isProduction)
          console.log('--> Network Connect');
        this.connected = true;
        this.reconnects = 0;
        resolve({success: true});
      };
  
      this.socket.onerror = (event)=> {
        console.log('<-- Network Error!');
        console.log(event);
        this.socket = null;
        this.connected = false;
        resolve({success: false});
      };
  
      this.socket.onclose = (event)=>{
        if(!isProduction)
          console.log('<-- Network Disconnect');
        this.socket = null;
        this.connected = false;
        this.reconnects++;
        if(!this.disconnecting) {
          if(this.reconnects < 3)
            this.reconnect();
          else
            console.warn('Failed to reconnect to Network!')
        } 
      };
  
      this.socket.onmessage = this.onMessage;
    });
  }

  public disconnect() {
    if(this.socket) {
      this.disconnecting = true;
      this.socket.close();
      this.socket = null;
    }
  }

  public abnormalDisconnect() {
    if(this.socket)
      this.socket.close();
  }

  protected reconnect() {
    let isProduction = (window.location.href.indexOf('www.') != -1);
    if(!isProduction)
      console.log('Reconnecting to Network...');

    setTimeout(() => {
      if(Server.account.isLoggedIn() && !this.isConnected())
        this.connect();
    }, 1000);
  }

  public isConnected(): boolean {
    return (this.socket != null);
  }

  protected onMessage(message:any) {
    // console.log(event);
    let data = JSON.parse(message.data);

    let isProduction = (window.location.href.indexOf('www.') != -1);
    if(!isProduction)
      console.log('<--', data);

    for(let i = 0; i < this.listeners.length; i++) 
      if(this.listeners[i].id == data.id) 
        this.listeners[i].callback(data);
  }

  public async sendEvent(to:string, event:any) {
    if(!Server.account.isLoggedIn())
      return;
      
    if(!this.socket)
      await this.connect();

    if(!this.socket)
      return;

    let payload:any = {to, event};
    let body = JSON.stringify(payload);

    let isProduction = (window.location.href.indexOf('www.') != -1);
    if(!isProduction)
      console.log('-->', payload);

    try {
      this.socket.send(body);
    }
    catch(e) {
      console.error('Failed to send command!');
      console.log(e);
    }
  }

  public async setPresence(location:string) {
    if(this.presence != location) {
      this.presence = location;
      Server.network.sendEvent('friends', {
        id: 'set-presence', 
        location, 
        user: Server.user.getId()
      });    
    }
  }

  protected checkNetwork() {
    let now = performance.now();
    if(this.lastCheckNetworkTime == 0)
      this.lastCheckNetworkTime = now;
    let elapsed = (now - this.lastCheckNetworkTime) / 1000;
    this.lastCheckNetworkTime = now;

    if(this.isConnected()) {
      this.pingTime += elapsed;

      if(document.hasFocus()) 
        this.awayTime = 0;
      else
        this.awayTime += elapsed;

      let isProduction = (window.location.href.indexOf('www.') != -1);
      if(!isProduction)
        console.log('checkNetwork', this.pingTime.toFixed(0), this.awayTime.toFixed(0), new Date().toLocaleTimeString());

      if(this.awayTime >= 8 * 60 * 60) {
        console.warn('Disconnecting due to inactivity');
        this.disconnect();
      }
      else if(this.pingTime >= 180) {
        this.pingTime = 0;
        this.sendEvent('server', {id: 'ping'});
      }
    }
    else {
      this.pingTime = 0;
      this.awayTime = 0;
    }

    setTimeout(() => {
      this.checkNetwork();
    }, 60 * 1000);
  }
}