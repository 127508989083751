import React from 'react';
import { Navigate } from 'react-router-dom';
import { Server } from '../../server/server';
import { BsArrowDown, BsCaretDownFill, BsCaretUpFill, BsFillSkipBackwardFill, BsFillSkipEndFill, BsFillSkipForwardFill, BsFillSkipStartFill } from 'react-icons/bs';
import { HiArrowUturnDown } from "react-icons/hi2";
import AlertModal from '../modals/AlertModal';
import BadWords from '../util/badWords';
import PageEnvelope from '../elements/PageEnvelope';
import PageHeader from '../elements/PageHeader';
import ContentPanel from '../elements/ContentPanel';
import './SitePage.css'
import './ForumTopicPage.css'
import PostPanel from '../elements/PostPanel';
import { BiSolidSortAlt } from 'react-icons/bi';
import MessageModal from '../modals/MessageModal';

interface ForumTopicPageState {
  loading: boolean;
  posts: any[];
  forum: any;
  topic: any;
  alert: string;
  navigate: string;
  // page: string;
  sort: boolean;
  more: any;
  edit: string;
  message: string;
  loadingMore: boolean;
}

class ForumTopicPage extends React.Component<{}, ForumTopicPageState> {
  // postsPerPage: number;

  constructor(props: {}) {
    super(props);
    this.state = {
      loading: false,
      posts: [],
      topic: null,
      forum: null,
      alert: '',
      navigate: '',
      // page: 'first',
      sort: true,
      more: null,
      edit: '',
      message: '',
      loadingMore: false
    };

    // this.postsPerPage = 10;
  }

  componentDidMount() {
    if(Server.account.isLoggedIn())
      this.load();
  }

  getForumId() {
    let i1 = window.location.pathname.lastIndexOf('/');
    let i2 = window.location.pathname.lastIndexOf('/', i1-1);
    return window.location.pathname.substring(i1, i2+1);
  }

  getTopicId() {
    let i = window.location.pathname.lastIndexOf('/');
    return window.location.pathname.substring(i+1);
  }

  getProfilesInPosts(posts:any[]) {
    let profiles = [];
    for (let i = 0; i < posts.length; i++) {
      let post = posts[i];
      if(profiles.indexOf(post.author) == -1)
        profiles.push(post.author);
    }
    return profiles;
  }

  async load() {
    this.setState({loading: true});

    let response:any = await Server.forums.getForum(this.getForumId());
    if(!response.success) {
      this.setState({loading: false, alert: response.message});
      return false;
    }

    let forum = response.forum;

    response = await Server.forums.getTopic(this.getForumId(), this.getTopicId());
    if(!response.success) {
      this.setState({loading: false, alert: response.message});
      return false;
    }

    let topic = response.topic;
    topic.title = BadWords.clean(topic.title);

    this.setState({forum, topic});

    // let page = 'first';
    // let storedParams = window.sessionStorage.getItem('ForumTopicPageParams');
    // if(storedParams)
    //   page = storedParams;
    // window.sessionStorage.removeItem('ForumTopicPageParams');

    this.setState({loading: true});
    await this.loadPosts(this.state.sort);
  }

  async loadPosts(sort:boolean, more?:any) {
    let params:any = {sort};
    if(more)
      params.more = more;

    // if(page == 'next') {
    //   let post = this.state.posts[this.state.posts.length-1];
    //   params.after = {date: post.date, author: post.author};
    // }
    // else if(page == 'previous') {
    //   let post = this.state.posts[0];
    //   params.sort = 'descending';
    //   params.before = {date: post.date, author: post.author};
    // }
    // else if(page == 'last') {
    //   params.sort = 'descending';
    // }
    // else if(page == 'restore') {
    //   let storedParams = window.sessionStorage.getItem('ForumTopicPageGetPosts');
    //   if(storedParams) 
    //     params = JSON.parse(storedParams);
    // }

    // window.sessionStorage.setItem('ForumTopicPageGetPosts', JSON.stringify(params));

    let response = await Server.forums.getPosts(this.getForumId(), this.getTopicId(), params);

    if(!response.success) {
      this.setState({loading: false, alert: response.message});
      return;
    }

    // if(response.posts.length == 0) {
    //   this.setState({loading: false, page: page == 'next' ? 'last' : 'first'});
    //   return;
    // }

    // if(response.posts.length < this.postsPerPage && page == 'previous') {
    //   setTimeout(() => {
    //     this.loadPosts('first');
    //   }, 100);
    //   return;
    // }

    let profiles = this.getProfilesInPosts(response.posts);
    await Server.public.loadProfiles(profiles);

    let posts = [];

    if(more) {
      posts = JSON.parse(JSON.stringify(this.state.posts));
      for(let i = 0; i < response.posts.length; i++) {
        let existing = posts.find((p:any) => p.date == response.posts[i].date);
        if(!existing)
          posts.push(response.posts[i]);
      }
      this.sortPosts(posts, sort);
    }
    else
      posts = response.posts;

    this.setState({posts, more: response.more, loading: false});
  }

  sortPosts(posts:any[], sort:boolean) {
    let firstPost = posts[0];
    posts.splice(0, 1);

    posts.sort((a:any, b:any)=>{
      if(a.date > b.date)
        return sort ? 1 : -1;
      else if(a.date < b.date)
        return sort ? -1 : 1;
      return 0;
    })

    posts.unshift(firstPost);
  }

  onEditTopic() {
    this.setState({navigate: `/forum/${this.getForumId()}/${this.getTopicId()}/edit`})
  }

  async onCreatePost(content:string) {
    let now = new Date().toISOString();

    let newPost = {
      author: Server.user.getId(),
      content: content,
      date: now
    };
    
    this.state.posts.push(newPost);
    this.sortPosts(this.state.posts, this.state.sort);

    this.forceUpdate();

    let response = await Server.forums.createPost(this.getForumId(), this.getTopicId(), content);
    
    if(response.success) {
      Server.user.incrementCounter('forums#reply');
      newPost.date = response.date;
    }
    else
      this.setState({alert: response.message})
  }

  onEditPost(date:string) {
    this.setState({edit: date});
    // let forumId = this.getForumId();
    // let topicId = this.getTopicId();
    // let d = new Date(postDate);
    // let postId = d.getTime().toString();
    // let url = `/forum/${forumId}/${topicId}/${postId}/edit`;
    // this.setState({navigate: url})
  }

  async onSavePost(content:string) {
    this.setState({edit: null});

    if(content == null) 
      return;

    for(let i = 0; i < this.state.posts.length; i++) {
      if(this.state.posts[i].date == this.state.edit) {
        if(content == '')
          this.state.posts.splice(i, 1);
        else
          this.state.posts[i].content = content;
        break;
      }
    }

    let response = null;

    if(content == '') 
      response = await Server.forums.deletePost(this.getForumId(), this.getTopicId(), this.state.edit);
    else 
      response = await Server.forums.editPost(this.getForumId(), this.getTopicId(), this.state.edit, content);

    if (!response.success) 
      this.setState({alert: response.message})
  }

  onSort() {
    if(this.state.more)
      this.loadPosts(!this.state.sort);
    else 
      this.sortPosts(this.state.posts, !this.state.sort);

    this.setState({sort: !this.state.sort});
  }

  onJumpToReplies() {
    document.getElementById('RepliesHeader')?.scrollIntoView({behavior: 'smooth', block: 'center'});
  }

  onJumpToBottom() {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }

  async onLoadMore() {
    this.setState({loadingMore: true});
    await this.loadPosts(this.state.sort, this.state.more);
    this.setState({loadingMore: false});
  }

  renderRepliesHeader() {
    let count = this.state.posts.length;
    let label = (count > 2) ? `${count-1}${this.state.more ? '+' : ''} Replies` : '1 Reply';
    let headerId = 'RepliesHeader';
    
    return (
      <div className="site-page-header" id={headerId}>
        <div className="site-page-row" style={{justifyContent: 'space-between'}}>
          <div>
            {label}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', columnGap: '15px'}}>
            <div style={{cursor: 'pointer'}} onClick={()=>this.onJumpToBottom()}>
              Bottom&nbsp;<HiArrowUturnDown style={{fontSize: '0.9em', transform: 'translateY(2px)'}} />
            </div>
            <div style={{cursor: 'pointer'}} onClick={()=>this.onSort()}>
              Sort&nbsp;
              {this.state.sort ?
                <BsCaretUpFill style={{fontSize: '1.0em', transform: 'translateY(3px)'}} /> :
                <BsCaretDownFill style={{fontSize: '1.0em', transform: 'translateY(3px)'}} />
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderReplies() {
    let divs = [];

    for (let i = 1; i < this.state.posts.length; i++) {
      let post = this.state.posts[i];
      let title = (i == 0) ? this.state.topic.title : '';
      let action = post.author == Server.user.getId() && !this.state.topic.archived ? 'edit' : '';
      if(this.state.forum && this.state.forum.archived)
        action = '';

      if(this.state.edit == this.state.posts[i].date) {
        divs.push(
          <PostPanel 
            key={i}
            placeholder="Enter reply..."
            edit={this.state.posts[i].content}
            onPost={(content:string)=>this.onSavePost(content)}
            words={1000}
          />
        )
      }
      else {
        divs.push(
          <ContentPanel 
            key={i}
            author={post.author}
            date={post.date}
            content={post.content}
            title={title}
            reply={true}
            action={action}
            onAction={()=>this.onEditPost(post.date)}
          />
        );
      }
    }

    return divs;
  }

  renderLoading() {
    return (
      <div className="site-page">
        <PageEnvelope width="600px">
          <PageHeader text="Loading..." navigate="/forums" />
        </PageEnvelope>
      </div>
    )
  }

  render() {
    if(this.state.navigate != '') 
      return <Navigate to={this.state.navigate} state={{from:'forum-topic'}} />;

    if(!Server.account.isLoggedIn())
      return <Navigate to="/forums" />

    if(this.state.loading || !this.state.topic)
      return this.renderLoading();

    let action = this.state.topic.author == Server.user.getId() ? 'edit' : '';
    if(this.state.forum && this.state.forum.archived)
      action = '';

    let blockReply = (this.state.topic && this.state.topic.archived) || (this.state.forum && this.state.forum.archived);

    return (
      <div className="site-page">
        <PageEnvelope width="600px">
          <div className="site-page-column">

            <PageHeader text="View Topic" navigate={'/forum/' + this.getForumId()} force={true}>
              {this.state.posts.length > 1 &&
                <div style={{display: 'flex', cursor: 'pointer'}} onClick={()=>this.onJumpToReplies()}>
                  Replies&nbsp;<HiArrowUturnDown style={{fontSize: '0.9em', transform: 'translateY(4px)'}} />
                </div>
              }
            </PageHeader>

            {this.state.forum && this.state.forum.archived && 
              <div className="site-page-header" style={{justifyContent: 'center'}}>
                This forum has been archived.
              </div>
            }

            {this.state.topic && this.state.topic.archived && 
              <div className="site-page-header" style={{justifyContent: 'center'}}>
                This topic has been archived.
              </div>
            }

            <ContentPanel 
              author={this.state.posts[0].author}
              date={this.state.posts[0].date}
              content={this.state.posts[0].content}
              title={this.state.topic.title}
              action={action}
              onAction={()=>this.onEditTopic()}
            />

            {!blockReply && 
              <PostPanel 
                placeholder="Enter reply..."
                onPost={(content:string)=>this.onCreatePost(content)}
                words={1000}
              />
            }

            {this.state.posts.length > 1 &&
              this.renderRepliesHeader()
            }

            {!this.state.loading && this.state.posts.length > 1 &&
              <div className="site-page-column">
                {this.renderReplies()}
              </div>
            }

            {this.state.more &&
              <button onClick={()=>this.onLoadMore()} style={{backgroundColor: 'var(--page-background-color)', border: '1px solid var(--panel-background-color)'}}>
                {this.state.loadingMore ? 'Loading...' : 'Load More Replies'}
              </button>
            }

            {!blockReply && this.state.posts.length > 3 &&
              <PostPanel 
                placeholder="Enter reply..."
                onPost={(content:string)=>this.onCreatePost(content)}
                words={1000}
              />
            }
          </div>
        </PageEnvelope>

        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
      </div>
    );
  }
}

export default ForumTopicPage;