import React from 'react';
import AlertModal from '../modals/AlertModal';
import '../pages/SitePage.css';
import { BsCodeSlash, BsEnvelopeFill, BsFillImageFill, BsFillSendFill, BsFilm, BsGrid3X3GapFill } from 'react-icons/bs';
import MessageModal from '../modals/MessageModal';
import { Quill } from 'react-quill';
import { Server } from '../../server/server';
import SharedQuillEditor from './SharedQuillEditor';
import AddMediaModal from '../modals/AddMediaModal';
import AddEmbedModal from '../modals/AddEmbedModal';

let QuillBlock = Quill.import('blots/block');

interface PostPanelProps {
  placeholder?: string;
  words?: number;
  images?: number;
  button?: string;
  onPost: Function;
  mini?: boolean;
  edit?: string;
}

interface PostPanelState {
  alert: string;
  message: string;
  openAddMedia: boolean;
  openEmbed: boolean;
}

class PostPanel extends React.Component<PostPanelProps, PostPanelState> {
  protected quillRef: any;
  protected quillPosition: number;
  protected quillWordCount: number;

  constructor(props: PostPanelProps) {
    super(props);
    this.state = {
      alert: '',
      message: '',
      openAddMedia: false,
      openEmbed: false
    };

    this.quillRef = null;
    this.quillPosition = 0;
    this.quillWordCount = 0;
  }

  onMediaLibrary() {
    if(Server.account.isLoggedIn())
      this.setState({openAddMedia: true});
    else
      this.setState({alert: 'Please login to access your media.'});
  }

  onInsertMedia(data: any) {
    this.setState({openAddMedia: false});
    this.quillRef.insertEmbed(this.quillPosition, data.category, data.url);
    this.quillRef.setSelection(this.quillPosition+1, 0);
  }

  onEmbed() {
    if(Server.account.isLoggedIn())
      this.setState({openEmbed: true});
    else
      this.setState({alert: 'Please login to embed content from external websites.'});
  }

  onAddImage() {
    if(!Server.account.isLoggedIn()) {
      this.setState({alert: 'Please login to add images from your device.'});
      return;
    }
    
    const fileElem = document.getElementById("PostPanelInput");
    if (fileElem)
      fileElem.click();
  }

  async onUploadMedia(file: any) {
    if (!file) return;

    console.log(file);

    if (file.size > 5242880) { // 5 MB
      this.setState({alert: 'File must be at most 5MB size.'});
      return;
    }

    if (file.type.indexOf('image') == -1 && file.type.indexOf('audio') == -1) {
      this.setState({alert: 'Currently just image and audio are allowed.'});
      return;
    }

    this.setState({message: 'Saving image to your media library...'})
    let response = await Server.media.uploadFile(file);
    this.setState({message: ''})

    if (response.success) {
      let ext = file.type.split('/')[1];
      let url = Server.media.getFileUrl({id: response.id, metadata: {ext}});
      this.quillRef.insertEmbed(this.quillPosition, 'image', url);
      this.quillRef.setSelection(this.quillPosition+1, 0);
    }
  }

  validateContent(allowEmpty:boolean = false) {
    let content = this.quillRef.root.innerHTML;
    let contentText = this.quillRef.getText();
    let lines = this.quillRef.getLines();

    contentText = contentText.replace('\n', '');

    let blotCount = 0;
    for(let i = 0; i < lines.length; i++) {
      if(lines[i] instanceof QuillBlock)
        continue;
      blotCount++;
    }

    let imgBase64 = content.match(/src="data:image/gi);
    if (imgBase64 && imgBase64.length > 0) {
      this.setState({alert: 'No embedded images are allowed in posts.'});
      return null;
    }
  
    if (blotCount > 1) {
      this.setState({alert: 'You can only embed one resource in an activity post.'});
      return null;
    }
 
    if(blotCount == 0 && contentText.length == 0 && !allowEmpty) {
      console.warn('There is nothing to post.');
      this.setState({alert: 'There is nothing to post.'});
      return null;
    }

    if(contentText.length > this.props.words) {
      this.setState({alert: `Post must be at most ${this.props.words} characters.`});
      return null;
    }

    if(content.substring(content.length-11) == '<p><br></p>') 
      content = content.substring(0, content.length-11);

    return encodeURIComponent(content);
  }

  onPost() {
    if(!Server.account.isLoggedIn()) {
      this.setState({alert: `Please login to ${this.props.button ? this.props.button.toLowerCase() : 'post'}.`});
      return;
    }

    let content = this.validateContent();
    this.props.onPost(content);
    this.quillRef.setText('');
  }

  onCancel() {
    this.props.onPost(null);
  }

  onSave() {
    let content = this.validateContent(true);
    if(content != null)
      this.props.onPost(content);
  }

  onQuillKeyDown(e:any) {
    if(e.keyCode == 13 && !e.shiftKey) 
      this.onPost();
  }

  onCloseEmbed(embedded:boolean) {
    this.setState({openEmbed: false}); 
    if(embedded)
      this.quillRef.setSelection(this.quillPosition+1, 0);
  }

  renderPanel() {
    return (
      <div className="site-page-panel">
        <SharedQuillEditor 
          placeholder={this.props.placeholder ? this.props.placeholder : ' '}
          isActivity={true} 
          content={this.props.edit ? decodeURIComponent(this.props.edit) : null}
          onChange={(l:number)=>this.quillWordCount = l}
          onKeyDown={(e:any)=>this.onQuillKeyDown(e)}
          getPosition={(p:number) => this.quillPosition = p}
          getRef={(ref: any) => this.quillRef = ref} 
        />

        <div className="site-page-row" style={{justifyContent: 'space-between'}}>
          <div className="site-page-row">
            <button className="site-page-icon-button" onClick={()=>this.onAddImage()}><BsFillImageFill /></button>
            <button className="site-page-icon-button" onClick={()=>this.onEmbed()}><BsCodeSlash /></button>
            <button className="site-page-icon-button" onClick={()=>this.onMediaLibrary()}><BsGrid3X3GapFill /></button>
          </div>
          {!this.props.edit && 
            <button onClick={()=>this.onPost()}>
              {this.props.button ? this.props.button : 'Post'}
            </button>
          }
          {this.props.edit && 
            <div className="site-page-row" style={{justifyContent: 'right'}}>
              <button onClick={()=>this.onCancel()}>
                {this.props.button ? this.props.button : 'Cancel'}
              </button>
              <button onClick={()=>this.onSave()}>
                {this.props.button ? this.props.button : 'Save'}
              </button>
            </div>
          }
        </div>
      </div>
    )
  }

  renderMiniPanel() {
    return (
      <div style={{display: 'grid', gridTemplateColumns: '1fr min-content', columnGap: '10px'}}>
        <SharedQuillEditor 
          placeholder={this.props.placeholder ? this.props.placeholder : ' '}
          isActivity={true} 
          onChange={(l:number)=>this.quillWordCount = l}
          onKeyDown={(e:any)=>this.onQuillKeyDown(e)}
          getPosition={(p:number) => this.quillPosition = p}
          getRef={(ref: any) => this.quillRef = ref} 
        />
        <div onClick={()=>this.onPost()} style={{
          width: '50px', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          backgroundColor: 'var(--button-background-color)',
          borderRadius: '10px',
          cursor: 'pointer'
        }}>
          <BsFillSendFill />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>

        {this.props.mini ? this.renderMiniPanel() : this.renderPanel()}

        <input
          type="file"
          id="PostPanelInput"
          accept="image/*, video/*, audio/*"
          style={{display: 'none'}}
          onChange={(e:any)=>this.onUploadMedia(e.currentTarget.files[0])}
        />

        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>

        <AddMediaModal 
          open={this.state.openAddMedia} 
          onClose={()=>this.setState({openAddMedia: false})} 
          callback={(data:any)=>this.onInsertMedia(data)} 
        />

        <AddEmbedModal 
          open={this.state.openEmbed} 
          onClose={(embedded:boolean)=>this.onCloseEmbed(embedded)} 
          quillRef={this.quillRef}
          quillPosition={this.quillPosition}
        />
      </div>
    )
  }
}

export default PostPanel;