import React from 'react';
import { Navigate } from 'react-router-dom';
import { Server } from '../../server/server';
import BlogCard from '../elements/BlogCard';
import AlertModal from '../modals/AlertModal';
import PageEnvelope from '../elements/PageEnvelope';
import './SitePage.css'

interface BlogsPageState {
  category: string;
  blogs: any[];
  alert: string;
  navigate: string;
  loading: boolean;
  loadingMoreBlogs: boolean;
  noMoreBlogs: boolean;
}

class BlogsPage extends React.Component<{}, BlogsPageState> {

  constructor(props: any) {
    super(props);

    this.state = {
      category: 'community',
      blogs: [],
      alert: '',
      navigate: '',
      loading: false,
      loadingMoreBlogs: false,
      noMoreBlogs: false
    }

    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onBlogClick = this.onBlogClick.bind(this);
    this.onBlogEdit = this.onBlogEdit.bind(this);
  }

  isAuthorCategory(cat:string) {
    return (cat != 'community' && cat != 'friends' && cat != 'following');
  }

  getProfilesInBlogs(blogs:any[]) {
    let profiles = [];
    for (let i = 0; i < blogs.length; i++) {
      if(profiles.indexOf(blogs[i].author) == -1)
        profiles.push(blogs[i].author);
    }
    return profiles;
  }

  componentDidMount() {
    let category = this.state.category;
    if(Server.account.isLoggedIn()) {
      let citem = sessionStorage.getItem('BlogsPageFilter');
      if (citem) {
        category = citem;
        this.setState({category})
      }
    }

    this.loadBlogs(category);
  }

  async loadBlogs(category:string, more:boolean=false) {
    if(more)
      this.setState({loadingMoreBlogs: true});
    else
      this.setState({loading: true});

    let params:any = null;

    if(this.isAuthorCategory(category)) {
      params = {author: Server.user.getId()};
      if(category != 'user')
        params.state = category;
    }
    else {
      params = {state: 'published'};
      if(category == 'friends' || category == 'following')
        params.filter = category;
    }

    if(more) {
      let blog = this.state.blogs[this.state.blogs.length-1];
      params.before = {date: blog.date, id: blog.id};
    }

    let response = await Server.blogs.getBlogs(params);

    if (!response.success) {
      this.setState({loading: false, alert: response.message});
      return;
    }

    if(response.blogs.length == 0 && more) {
      this.setState({loading: false, noMoreBlogs: true});
      return;
    }

    let profiles = this.getProfilesInBlogs(response.blogs);
    await Server.public.loadProfiles(profiles);

    let blogs = [];
    if(more) {
      for(let i = 0; i < this.state.blogs.length; i++)
        blogs.push(this.state.blogs[i]);
    }

    for(let i = 0; i < response.blogs.length; i++) 
      blogs.push(response.blogs[i]);

    let noMoreBlogs = response.blogs.length < 10;

    // let cookie = Server.user.getCookie('LastBlogsRead');
    // if(blogs.length > 0 && cookie != blogs[0].date)
    //   Server.user.setCookie({key: 'LastBlogsRead', value: blogs[0].date});

    if(!more && blogs.length > 0)
      Server.blogs.syncBlogCache(blogs[0].date);

    this.setState({blogs, noMoreBlogs, loading: false, loadingMoreBlogs: false});
  }

  onCategoryChange(e: any) {
    sessionStorage.setItem('BlogsPageFilter', e.target.value);
    this.setState({category: e.target.value});

    let categoryLocked = !Server.account.isLoggedIn() && e.target.value != 'community';

    if(!categoryLocked) {
      setTimeout(() => {
        this.setState({blogs: []});
        this.loadBlogs(this.state.category);
      }, 10);
    }
  }

  isBlogNew(blog:any) {
    if(!Server.account.isLoggedIn())
      return false;

    if(Server.user.getId() == blog.author)
      return false;

    let cookie = Server.user.getCookie('LastBlogsRead');
    let cookieDate = cookie ? new Date(cookie) : null;
    let blogDate = new Date(blog.date);
    
    return (!cookieDate || blogDate > cookieDate);
  }

  onBlogClick(blog:any) {
    if(!Server.account.isLoggedIn()) {
      this.setState({alert: 'Please login to view blogs.'});
      return;
    }
    
    if(this.isBlogNew(blog))
      Server.blogs.setLastReadBlogDate(blog.date);

    this.setState({navigate: '/blog/' + blog.slug})
  }

  onBlogEdit(blog:any) {
    this.setState({navigate: '/blog/edit/' + blog.slug})
  }

  onLoadMore() {
    this.loadBlogs(this.state.category, true);
  }

  onNewBlog() {
    if(!Server.account.isLoggedIn()) {
      this.setState({alert: 'Please login to post blogs.'});
      return;
    }

    this.setState({navigate: '/blog/new'});
  }

  renderBlogs() {
    if(this.state.loading) 
      return (<div className="site-page-panel">Loading...</div>); 

    let blogs = this.state.blogs;

    let divs = [];
    for (let i = 0; i < blogs.length; i++) {
      let notify = this.isBlogNew(blogs[i]);
      divs.push(<BlogCard key={i} notify={notify} data={blogs[i]} category={this.state.category} onClick={this.onBlogClick} onEdit={this.onBlogEdit}/>);
    }

    return divs.length > 0 
      ? <div style={{display: 'flex', flexDirection: 'column', rowGap: '10px'}}>
          {divs}
          {!this.state.loading && !this.state.noMoreBlogs &&
            <div>
              <button
                onClick={()=>this.onLoadMore()}
                disabled={this.state.loadingMoreBlogs ? true : false}
              >
                {this.state.loadingMoreBlogs ? 'Loading...' : 'Load More'}
              </button>
            </div>
          }
        </div>
      : <div className="site-page-panel">There are no blogs yet.</div>
  }

  render() {
    if(this.state.navigate)
      return (<Navigate to={this.state.navigate} state={{from:'blogs'}} />);      

    let categoryLocked = !Server.account.isLoggedIn() && this.state.category != 'community';

    let lockMessages:any = {};
    lockMessages['friends'] = 'Please login to view blogs from your friends.';
    lockMessages['following'] = 'Please login to view blogs from the people you follow.';
    lockMessages['user'] = 'Please login to view your own blogs.';
    lockMessages['published'] = 'Please login to view your published blogs.';
    lockMessages['draft'] = 'Please login to view your draft blogs.';
    lockMessages['archived'] = 'Please login to view your archived blogs.';
    
    return (
      <div className="site-page">
        <PageEnvelope width="600px">
          <div className="site-page-column">
            <div className="site-page-row" style={{justifyContent: 'space-between'}}>
              {Server.account.isDeveloper() &&
                <select
                  style={{padding: '0 10px'}}
                  value={this.state.category} 
                  onChange={this.onCategoryChange}
                  disabled={this.state.loading}
                >
                  <option value="community">Community</option>
                  <option value="friends">Friends</option>
                  <option value="following">Following</option>
                  <option value="user">My Blogs</option>
                  <option value="published">My Published</option>
                  <option value="draft">My Drafts</option>
                  <option value="archived">My Archived</option>
                </select>
              }
              
              {Server.account.isDeveloper() &&
                <button onClick={()=>this.onNewBlog()} disabled={this.state.loading}>New Blog</button>
              }
            </div>

            {categoryLocked ? 
              <div className="site-page-panel">{lockMessages[this.state.category]}</div> 
              : 
              this.renderBlogs()
            }
          </div>
        </PageEnvelope>
        
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
      </div>
    )
  }
}

export default BlogsPage;