import React from 'react';
import { Navigate } from 'react-router-dom';
import { BsEnvelopePlus, BsExclamationCircleFill, BsFillArchiveFill, BsPencilSquare, BsPlus } from 'react-icons/bs';
import { Server } from '../../server/server';
import AlertModal from '../modals/AlertModal';
import BadWords from '../util/badWords';
import { formatTimestamp } from '../util/util';
import PageEnvelope from '../elements/PageEnvelope';
import PageHeader from '../elements/PageHeader';
import './SitePage.css'

interface ForumPageState {
  loading: boolean;
  forum: any;
  topics: any[];
  alert: string;
  navigate: string;
  noMoreTopics: boolean;
}

class ForumPage extends React.Component<{}, ForumPageState> {
  topicsPerPage: number;

  constructor(props: {}) {
    super(props);
    this.state = {
      loading: false,
      forum: null,
      topics: [],
      alert: '',
      navigate: '',
      noMoreTopics: false
    };

    this.topicsPerPage = 10;
  }

  componentDidMount() {
    if(Server.account.isLoggedIn())
      this.load();
  }

  getForumId() {
    let i = window.location.pathname.lastIndexOf('/');
    return window.location.pathname.substring(i+1);
  }

  async load() {
    this.setState({loading: true});

    let success = await this.loadForum();

    if(!success)
      return;

    await this.loadTopics();
  }

  async loadForum() {
    let forumResponse = await Server.forums.getForum(this.getForumId());
    
    if(!forumResponse.success) {
      this.setState({loading: false, alert: forumResponse.message});
      return false;
    }

    this.setState({forum: forumResponse.forum})

    return true;
  }

  getProfilesInTopics(topics:any[]) {
    let profiles = [];
    for (let i = 0; i < topics.length; i++) {
      let topic = topics[i];

      if(profiles.indexOf(topic.author) == -1)
        profiles.push(topic.author);

      if(topic.recent) 
        if(profiles.indexOf(topic.recent.author) == -1)
          profiles.push(topic.recent.author);
    }
    return profiles;
  }

  async loadTopics(more:boolean=false) {
    this.setState({loading: true});

    let params:any = {limit: this.topicsPerPage};

    if(more) {
      let post = this.state.topics[this.state.topics.length-1];
      params.before = {date: post.recent.date, author: post.recent.author};
    }

    let response = await Server.forums.getTopics(this.getForumId(), params);

    if(!response.success) {
      this.setState({loading: false, alert: response.message});
      return;
    }

    if(response.topics.length == 0 && more) {
      this.setState({loading: false, noMoreTopics: true});
      return;
    }

    // load profiles associated with topics
    let profiles = this.getProfilesInTopics(response.topics);
    await Server.public.loadProfiles(profiles);

    // build array of topics
    let topics = [];

    if(more) {
      for(let i = 0; i < this.state.topics.length; i++)
        topics.push(this.state.topics[i]);
    }

    for(let i = 0; i < response.topics.length; i++) {
      let topic = response.topics[i];
      topic.title = BadWords.clean(topic.title);
      topics.push(topic);
    }

    let noMoreTopics = response.topics.length < this.topicsPerPage;

    this.setState({topics, noMoreTopics, loading: false});
  }

  onViewTopic(topic:any) {
    // this.saveState();

    if(this.state.forum.title == 'Announcements' && topic.recent) 
      Server.forums.setLastReadDate(topic.recent.date)
 
    this.setState({navigate: `/forum/${this.state.forum.id}/${topic.id}`});
  }

  onNewTopic() {
    if(this.state.loading)
      return;

    if(!Server.account.isLoggedIn()) {
      this.setState({alert: 'Please login to start a new topic.'});
      return;
    }

    this.setState({navigate: `/forum/${this.state.forum.id}/new`});
  }

  onEditTopic(e:any, id:string) {
    e.stopPropagation();
    this.setState({navigate: `/forum/${this.state.forum.id}/${id}/edit`});
  }

  onLoadMore() {
    this.loadTopics(true);
  }

  renderTopics() {
    let now = new Date();
    let lastReadDate = Server.forums.getLastReadDate();

    let divs = [];

    for (let i = 0; i < this.state.topics.length; i++) {
      let topic = this.state.topics[i];
      let author = Server.public.getProfile(topic.author);
      if(!author) continue;
      let recentAuthor = '';
      let recentDate = '';
      let allowEdit = topic.author == Server.user.getId() && !this.state.forum.archived;

      if(topic.recent) {
        let a = Server.public.getProfile(topic.recent.author);
        if(!a) continue;
        recentAuthor = a.name;
        let d = new Date(topic.recent.date);
        recentDate = formatTimestamp(d.getTime() / 1000, true);
      }

      let notify = false;
      if(topic.recent && Server.account.isLoggedIn() && this.state.forum.title == 'Announcements') {
        let recentDate = new Date(topic.recent.date);
        notify = !lastReadDate || recentDate > lastReadDate;
      }

      divs.push(
        <div key={i} className="site-page-panel" style={{cursor: 'pointer', position: 'relative'}} onClick={()=>this.onViewTopic(topic)}>
          <div className="site-page-column" style={{rowGap: '5px'}}>
            <div className="site-page-row" style={{columnGap: '5px'}}>
              {topic.archived && <BsFillArchiveFill style={{color: 'var(--notification-background-color)', marginBottom: '2px'}} />}
              <div className="site-page-text">{topic.title}</div>
              {notify && <BsExclamationCircleFill style={{color: 'var(--notification-background-color)', fontSize: '0.9em', paddingRight: '5px'}}/>}
              </div>
            <div className="site-page-subtext">{recentAuthor}&nbsp;&#x2022;&nbsp;{recentDate}</div>
          </div>
          {allowEdit && 
            <div style={{position: 'absolute', top: '10px', right: '10px'}} onClick={(e)=>this.onEditTopic(e, topic.id)}>
              <BsPencilSquare size="1.2em"/>
            </div>
          }
        </div>
      );
    }

    return divs;
  }

  renderLoading() {
    return (
      <div className="site-page">
        <PageEnvelope width="600px">
          <PageHeader text="Loading..." navigate="/forums" />
        </PageEnvelope>
      </div>
    )
  }

  render() {
    if(this.state.navigate != '') 
      return <Navigate to={this.state.navigate} state={{from:'forum'}} />;

    if(!Server.account.isLoggedIn())
      return <Navigate to="/forums" />

    if(this.state.loading || !this.state.forum)
      return this.renderLoading();

    let allowNewTopic = !this.state.loading && !this.state.forum.archived;

    if(this.state.forum.title == 'Announcements' && allowNewTopic)
      allowNewTopic = Server.account.isAdmin();

    return (
      <div className="site-page">
        <PageEnvelope width="600px">
          <div className="site-page-column">

            <PageHeader text={this.state.forum.title + ' Forum'} navigate="/forums" force={true}>
              <div style={{whiteSpace: 'nowrap', cursor: 'pointer'}} onClick={()=>this.onNewTopic()}><BsPlus style={{transform: 'translateY(3px)'}} />New Topic</div>
            </PageHeader>

            {this.state.forum.archived && 
              <div className="site-page-header" style={{justifyContent: 'center', backgroundColor: 'var(--notification-background-color)'}}>
                This forum has been archived.
              </div>
            }

            <div className="site-page-column">
              {this.renderTopics()}
            </div>

            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              {this.state.noMoreTopics || (this.state.loading && this.state.topics.length == 0)
                ? <div>&nbsp;</div>
                : <button onClick={()=>this.onLoadMore()} disabled={this.state.loading}>
                    {this.state.loading && this.state.topics.length > 0 ? 'Loading...' : 'Load More'}
                  </button>
              }
              <button disabled={!allowNewTopic} onClick={()=>this.onNewTopic()}>New Topic</button>
            </div>

          </div>
        </PageEnvelope>

        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
      </div>
    );
  }
}

export default ForumPage;