import React from 'react';
import { Server } from '../../server/server';
import { Navigate, NavLink } from 'react-router-dom';
import PageEnvelope from '../elements/PageEnvelope';

interface AdminPageState {
  navigate: string;
}

class AdminPage extends React.Component<{}, AdminPageState> {
  constructor(props:{}) {
    super(props);
    this.state = {
      navigate: ''
    }
  }

  render() {
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />

    if(!Server.account.isLoggedIn() || !Server.account.isAdmin())
      return <Navigate to="/" replace={true} />

    return (
      <div className="site-page">
        <PageEnvelope width="300px">
          <div className="site-page-column">
            <button onClick={()=>this.setState({navigate: '/admin/stats'})}>Usage Stats</button>
            <button onClick={()=>this.setState({navigate: '/admin/beta'})}>Beta Requests</button>
            <button>Developer Tools</button>
          </div>
        </PageEnvelope>
      </div>
    )
  }
}

export default AdminPage;