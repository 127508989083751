import React from 'react';
import PageEnvelope from '../elements/PageEnvelope';
import './SitePage.css'
import PageHeader from '../elements/PageHeader';
import MessageModal from '../modals/MessageModal';
import AlertModal from '../modals/AlertModal';
import { Navigate } from 'react-router-dom';
import QuestionModal from '../modals/QuestionModal';
import { Server } from '../../server/server';

interface EditForumPageState {
  mode: string;
  id: string;
  name: string;
  description: string;
  archived: boolean;
  hidden: boolean;
  message: string;
  alert: string;
  navigate: string;
  loading: boolean;
  question: string;
  questionYes: Function;
}

class EditForumPage extends React.Component<{}, EditForumPageState> {
  constructor(props:{}) {
    super(props);
    this.state = {
      mode: '',
      id: '',
      name: '',
      description: '',
      archived: false,
      hidden: false,
      message: '',
      alert: '',
      navigate: '',
      loading: false,
      question: '',
      questionYes: null
    }
  }

  componentDidMount() {
    let parts = window.location.pathname.split('/');

    if(parts[2] == 'create') 
      this.setState({mode: 'create'});
    else {
      this.setState({mode: 'edit'});
      if(Server.account.isLoggedIn())
        this.loadForum(parts[2]);
    }
  }

  async loadForum(id:string) {
    this.setState({loading: true});

    let response = await Server.forums.getForum(id);

    if(!response.success) {
      this.setState({alert: response.message});
      return;
    }

    let forum = response.forum;

    this.setState({
      loading: false,
      id: forum.id,
      name: forum.title,
      description: forum.description,
      archived: forum.archived,
      hidden: forum.hidden
    })
  }

  onSave() {
    if(this.state.name == '') {
      this.setState({alert: 'The forum name cannot be blank.'});
      return;
    }

    let updatingForum = this.state.mode == 'edit';

    this.setState({message: (updatingForum ? 'Updating' : 'Creating') + ' forum...'})

    setTimeout(async () => {
      let response = null;
      if(updatingForum)
        response = await Server.forums.editForum(this.state.id, this.state.name, this.state.description);
      else
        response = await Server.forums.createForum(this.state.name, this.state.description);

      if(response.success) {
        await Server.forums.sync();
        this.setState({message: '', navigate: '/forums'});
      }
      else {
        this.setState({message: '', alert: response.message})
      }
    }, 500);
  }

  onArchiveRestore() {
    this.setState({
      question: `Are you sure that you want to ${this.state.archived ? 'restore' : 'archive'} this forum?`, 
      questionYes: ()=>this.onArchiveRestoreYes()
    });
  }

  onArchiveRestoreYes() {
    this.setState({message: this.state.archived ? 'Restoring forum...' : 'Archiving forum...', question: ''})

    setTimeout(async () => {
      let response = null;
      if(this.state.archived)
        response = await Server.forums.restoreForum(this.state.id);
      else
        response = await Server.forums.archiveForum(this.state.id);

      if(response.success) {
        await Server.forums.sync();
        this.setState({message: '', archived: !this.state.archived})
      }
      else {
        this.setState({message: '', alert: response.message})
      }
    }, 500);
  }

  onHideShow() {
    this.setState({
      question: `Are you sure that you want to ${this.state.hidden ? 'show' : 'hide'} this forum?`, 
      questionYes: ()=>this.onHideShowYes()
    });
  }

  onHideShowYes() {
    this.setState({message: this.state.hidden ? 'Showing forum...' : 'Hiding forum...', question: ''})

    setTimeout(async () => {
      let response = null;
      if(this.state.hidden)
        response = await Server.forums.showForum(this.state.id);
      else
        response = await Server.forums.hideForum(this.state.id);

      if(response.success) {
        await Server.forums.sync();
        this.setState({message: '', hidden: !this.state.hidden})
      }
      else {
        this.setState({message: '', alert: response.message})
      }
    }, 500);
  }


  renderLoading() {
    return (
      <div className="site-page">
        <PageEnvelope width="600px">
          <PageHeader text="Loading..." navigate="/forums" />
        </PageEnvelope>
      </div>
    )
  }

  render() {
    if(this.state.navigate != '')
      return (<Navigate to={this.state.navigate}/>)

    if(!Server.account.isLoggedIn())
      return <Navigate to="/forums" />

    if(this.state.loading)
      return this.renderLoading();

    return (
      <div className="site-page">
        <PageEnvelope width="600px">
          <div className="site-page-column">
            <PageHeader text={(this.state.mode == 'edit' ? 'Edit' : 'Create') + ' Forum'} navigate={'/forums'} />

            {this.state.archived &&
              <div className="site-page-header" style={{justifyContent: 'center', backgroundColor: 'var(--notification-background-color)'}}>
                This forum has been archived.
              </div>
            }

            <div className="site-page-panel">
              <div className="site-page-column">
                <div>Name:</div>
                <input value={this.state.name} onChange={(e)=>this.setState({name: e.currentTarget.value})} />
              </div>
              <div className="site-page-column">
                <div>Description:</div>
                <textarea
                  style={{height: '55px'}} 
                  value={this.state.description}
                  onChange={(e:any)=>this.setState({description: e.currentTarget.value})}
                />
              </div>

              <div className="site-page-row" style={{justifyContent: 'right'}}>
                <button onClick={()=>this.onSave()}>{this.state.mode == 'create' ? 'Create' : 'Save'}</button>
              </div>
            </div>

            {this.state.mode == 'edit' &&
              <div className="site-page-panel">
                <div className="site-page-row">
                  <div>
                    <button style={{width: '100px'}} onClick={()=>this.onHideShow()}>
                      {this.state.hidden ? 'Show' : 'Hide'}
                    </button>
                  </div>
                  <div style={{fontSize: '0.9em'}}>
                    {this.state.hidden ?
                      <div><b>This forum is hidden.</b>  Show the forum so the community can see it again.</div> :
                      <div>Hide the forum so that nobody in the community can see it.</div>
                    }
                  </div>
                </div>
              </div>
            }

            {this.state.mode == 'edit' &&
              <div className="site-page-panel">
                <div className="site-page-row">
                  <div>
                    <button style={{width: '100px'}} onClick={()=>this.onArchiveRestore()}>
                      {this.state.archived ? 'Restore' : 'Archive'}
                    </button>
                  </div>
                  <div style={{fontSize: '0.9em'}}>
                    {this.state.archived ?
                      <div><b>This forum is archived.</b>  Restore the Forum to allow modification by the community.</div> :
                      <div>Archiving the forum will leave it visible to the community but block any modification.</div>
                    }
                  </div>
                </div>
              </div>
            }

            {this.state.mode == 'edit' &&
              <div className="site-page-panel">
                <div className="site-page-row">
                  <div><button style={{width: '100px'}}>Delete</button></div>
                  <div style={{fontSize: '0.9em'}}>
                    <b><i>Deleting a forum is permanent!</i></b>&nbsp;&nbsp;All topics and posts associated with the Forum will also be deleted.
                  </div>
                </div>
              </div>
            }
          </div>
        </PageEnvelope>

        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} onClose={()=>this.setState({alert: ''})} />
        <QuestionModal message={this.state.question} onYes={this.state.questionYes} onNo={()=>this.setState({question: ''})} />
      </div>
    );
  }
}

export default EditForumPage;
