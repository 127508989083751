import {UIManager} from './ui/uiManager';

export class FloatingText {
  public startTime: number;
  public alpha: number;
  public startY: number;
  public scale: number;

  protected textWidth:number;
  protected textHeight:number;

  constructor(
      public text: string, public x: number, public y: number,
      public fillStyle: string, public duration: number = 1200,
      public fontSize: number = 36, public scaleIt = false) {
    this.startY = this.y;
    this.scale = 1;
    this.startTime = performance.now();
    this.textWidth = 100;
    this.textHeight = 30;
  }

  public update(): boolean {
    let elapsed = performance.now() - this.startTime;

    if (elapsed > this.duration) {
      return true;
    }

    // Fade out the text starting at halfway through duration
    this.alpha = 1;
    if (elapsed >= (this.duration / 2)) {
      this.alpha = 1 - ((elapsed - (this.duration / 2)) / (this.duration / 2));
    }

    // Just hacked this in for now to handle differences between Solitaire and
    // Match 3 (will probably make floating text fancier/cleaner in the future
    // with more settable parameters)
    // if (this.scaleIt) {
    //   this.scale = 1 + (elapsed * 0.5 / this.duration);
    // } else {
      this.y = this.startY - (elapsed / 20);
    // }

    return false;
  }

  public draw() {
    UIManager.ctx.save();
    UIManager.ctx.globalAlpha = this.alpha;

    let sx = this.x - (this.textWidth/2);
    let sy = this.y - (this.textHeight/2);

    let context = UIManager.ctx;

    context.font = `bold ${this.fontSize}px neue-haas-grotesk-text`;
    context.textAlign = 'center';
    context.textBaseline = 'middle';

    context.strokeStyle = '#000000f0';
    context.lineWidth = Math.floor(this.fontSize / 6);
    context.strokeText(this.text, sx + (this.textWidth / 2), sy + (this.textHeight / 2));

    context.fillStyle = this.fillStyle;
    context.fillText(this.text, sx + (this.textWidth / 2), sy + (this.textHeight / 2));

    UIManager.ctx.restore();
  }
}
