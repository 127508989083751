import React from 'react';
import './EditBlogPage.css'
import { BsFillXCircleFill, BsPencilFill } from 'react-icons/bs';
import { Navigate } from 'react-router-dom';
import QuestionModal from '../modals/QuestionModal';
import AlertModal from '../modals/AlertModal';
import MessageModal from '../modals/MessageModal';
import SharedQuillEditor from '../elements/SharedQuillEditor';
import { Server } from '../../server/server';
import BadWords from '../util/badWords';
import PageEnvelope from '../elements/PageEnvelope';
import PageHeader from '../elements/PageHeader';
import { Quill } from 'react-quill';

let QuillBlock = Quill.import('blots/block');

interface EditBlogPageState {
  title: string;
  slug: string;
  newSlug: string;
  tags: string;
  wordCount: number;
  openEditSlug: boolean;
  goBlogsPage: boolean;
  question: string;
  message: string;
  alert: string;
  editBlog: boolean;
  brandNew: boolean;
  slugEdited: boolean;
}

class EditBlogPage extends React.Component<{}, EditBlogPageState> {

  quillRef: any;
  blog: any = {id: '', state: ''};
  action: string;
  stateChanged: boolean;

  constructor(props: any) {
    super(props);
    this.quillRef = null;
    this.state = {
      title: '',
      slug: '',
      newSlug: '',
      tags: '',
      wordCount: 0,
      openEditSlug: false,
      goBlogsPage: false,
      question: '',
      message: '',
      alert: '',
      editBlog: true,
      brandNew: false,
      slugEdited: false,
    }

    this.stateChanged = false;

    this.onTitleChange = this.onTitleChange.bind(this);
    this.onTagsChange = this.onTagsChange.bind(this);
    this.onSlugChange = this.onSlugChange.bind(this);
    this.setNewSlug = this.setNewSlug.bind(this);
    this.onContentChange = this.onContentChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onQuestionYes = this.onQuestionYes.bind(this);
    this.onQuestionNo = this.onQuestionNo.bind(this);
  }

  componentDidMount () {
    let path = window.location.pathname;
    if (path.substring(6) == 'new')
      this.setState({editBlog: false, brandNew: true});
    else if(Server.account.isLoggedIn())
      this.getBlog(path.substring(11));
  }
  
  async getBlog(slug: string) {
    let response = await Server.blogs.getBlog({slug});
    if (!response.success)
      return

    this.blog = response.blog;
    this.setState({
      title: this.blog.title,
      slug: this.blog.slug,
      tags: this.blog.tags
    });

    let content = decodeURIComponent(this.blog.content);
    content += '<p><br></p>'
    this.quillRef.root.innerHTML = content;
  }
  
  async getBlogById(id: string) {
    let response = await Server.blogs.getBlog({id});
    if (!response.success)
      return

    this.blog = response.blog;
  }

  async onSave() {
    if (!this.checkBlogContent())
      return;

    if (!this.checkSlug(this.state.slug))
      return;

    this.action = '';

    let content = this.quillRef.root.innerHTML;

    // remove blank lines at the end
    if(content.substring(content.length-11) == '<p><br></p>') 
      content = content.substring(0, content.length-11);

    // find the first image in the blog
    let image   = '';
    let start   = content.indexOf('<img');
    if (start != -1) {
      start = content.indexOf('src=', start);
      let end = content.indexOf('>', start);
      if (end != -1)
        image = content.substring(start + 5, end - 1);
    }

    let lines = this.quillRef.getLines();
    let preview = ' ';
    for(let i = 0; i < lines.length; i++) {
      let validBlock = (lines[i] instanceof QuillBlock);
      if(validBlock && lines[i].children && lines[i].children.head && lines[i].children.head.text) {
        let words = lines[i].children.head.text.split(' ');
        let wordCount = words.length;
        words.splice(20);
        preview = words.join(' ') + ((wordCount > 20) ? '...' : '');
        break;
      }
    }

    let params = {
      slug:  this.state.slug,
      title: this.state.title,
      content: encodeURIComponent(content),
      tags:  this.state.tags,
      image,
      preview
    }

    let paramsEdit;
    if (this.state.editBlog)
      paramsEdit = {...{id: this.blog.id}, ...params}

    this.setState({message: 'Saving...'});

    let response = null;
    if (this.state.editBlog)
      response = await Server.blogs.editBlog(paramsEdit);
    else
      response = await Server.blogs.createBlog(params);

    if (response.success) {
      this.stateChanged = true;

      if (!this.state.editBlog) { // new blog
        Server.user.incrementCounter('blogs#post');
        this.getBlogById(response.id);
        sessionStorage.setItem('BlogsPageFilter', 'draft');
      }

      this.setState({message: '', alert: 'Saved!', editBlog: true, brandNew: false});
    }
    else
      this.setState({message: '', alert: response.message})
  }

  checkSlug(slug: string) {
    let message = '';
    slug = slug.trim();

    if(slug.length < 3) 
      message = 'Permalink must be at least 3 characters.';

    if(slug.length > 100) 
      message = 'Permalink must be at most 100 characters.';
  
    if(slug.indexOf('--') != -1) 
      message = 'Permalink cannot contain consecutive dash (-) characters.';
      
    let re = /^[a-zA-Z0-9-]+$/;
    if(!re.test(slug)) 
      message = 'Permalink may only contain a-z, A-Z, 0-9, and dash (-) characters.';

    if(slug.length == 0)
      message = 'Permalink can not be empty.';

    this.setState({ alert: message });

    if (message == '')
      return true;
    else
      return false;
  }

  onTitleChange(e: React.FormEvent<HTMLInputElement>): void {
    let value = e.currentTarget.value;

    let noSpecial = value.replace(/[^a-zA-Z0-9 ]/, '');
    let words = noSpecial.match(/\w+/g);
    let slug = words ? words.join('-') : '';

    this.setState({
      title: value,
      slug: this.state.slugEdited ? this.state.slug : slug.toLowerCase()
    });
  };

  onTagsChange(e: React.FormEvent<HTMLInputElement>): void {
    let value = e.currentTarget.value;
    this.setState({ tags: value });
  };

  onSlugChange(e: React.FormEvent<HTMLInputElement>): void {
    let value = e.currentTarget.value;
    let slug = value.replaceAll(' ', '-');
    this.setState({ newSlug: slug });
  };

  onContentChange(length: number) {
    this.setState({wordCount: length});
  };

  checkBlogContent() {
    let message = '';
    let title = this.state.title.trim();
    let lines = this.quillRef.getLines();

    if (title == '')
      message = 'Title can not be empty.';
    else if (title.length < 3)
      message = 'Title must be at least 3 characters.';
    else if (title.length > 100)
      message = 'Title must be at most 100 characters.';
    else if (BadWords.isBad(title))
      message = 'No bad words allowed in title.';
    // else if (this.state.tags.trim() == '')
    //   message = 'Tags can not be empty.';
    else if (lines.length == 1 && this.state.wordCount == 0)
      message = 'Blog content can not be empty.';

    this.setState({ alert: message });

    if (message == '')
      return true;
    else
      return false;
  }

  onClose() {
    this.setState({openEditSlug: false});
  }

  //-----------------------------
  // Edit blog slug modal functions

  openEditSlug() {
    this.setState({ openEditSlug: true, newSlug: this.state.slug });
  }

  setNewSlug() {
    if (!this.checkSlug(this.state.newSlug))
      return;

    this.onClose();
    this.setState({ slug: this.state.newSlug, slugEdited: true });
  }

  EditSlugModal = () => {
    if(!this.state.openEditSlug)
      return (<div></div>);

    return (
      <div className="modal open">
        <div className="modal-content">
          <button className="modal-close-button" onClick={this.onClose}>
            <BsFillXCircleFill />
          </button>

          <div className="site-page-column">
            <div style={{textAlign: 'center', marginBottom: '5px'}}>Edit Permalink</div>

            <input value={this.state.newSlug} onChange={this.onSlugChange} />

            <div className="site-page-row" style={{justifyContent: 'center'}}>
              <button onClick={this.setNewSlug}>Save</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onActions(action: string) {
    // if(action == 'Archive') {
    //   console.log(this.quillRef.root.innerHTML);
    //   let lines = this.quillRef.getLines();
    //   let blotCount = 0;
    //   for(let i = 0; i < lines.length; i++) {
    //     if(lines[i] instanceof QuillBlock)
    //       continue;
    //     blotCount++;
    //   }
    //   console.log(blotCount);
    //   return;
    // }

    this.action = action;

    let question = `Are you sure you want to ${action} this blog?`;
    if (action == 'Publish')
      question = 'Are you sure you want to publish this for everyone to see?';

    this.setState({ question: question });
  }

  onQuestionYes() {
    this.setState({question: '', message: `${this.action} blog...`});

    setTimeout(async () => {
      let id = this.blog.id;
      let response = null;

      if (this.action == 'Publish')
        response = await Server.blogs.publishBlog(id);
      else if (this.action == 'Unpublish')
        response = await Server.blogs.unpublishBlog(id);
      else if (this.action == 'Archive')
        response = await Server.blogs.archiveBlog(id);
      else if (this.action == 'Restore')
        response = await Server.blogs.restoreBlog(id);
      else if (this.action == 'Delete')
        response = await Server.blogs.deleteBlog(id);

      if (response.success) {
        this.stateChanged = true;

        if (this.action == 'Publish')
          sessionStorage.setItem('BlogsPageFilter', 'published');
        else if (this.action == 'Unpublish' || this.action == 'Restore')
          sessionStorage.setItem('BlogsPageFilter', 'draft');
        else if (this.action == 'Archive')
          sessionStorage.setItem('BlogsPageFilter', 'archived');

        if (this.action != 'Delete')
          this.getBlogById(id);

        this.setState({message: '', alert: `${this.action} done!`});
      }
      else
        this.setState({message: '', alert: response.message})
    }, 500);
  }

  onQuestionNo() {
    this.setState({question: ''});
  }

  onAlertClose() {
    this.setState({alert: ''});
    if (this.action == 'Publish' || this.action == 'Delete')
      this.setState({ goBlogsPage: true });
  }

  render() {
    if(!Server.account.isLoggedIn())
      return <Navigate to="/blogs" />;

    if (this.state.goBlogsPage)
      return (<Navigate to="/blogs" />);

    return (
      <div className="site-page">
        <PageEnvelope width="600px">
          <div className="site-page-column">

            <PageHeader 
              text={(this.state.editBlog ? 'Edit' : 'New') + ' Blog'} 
              navigate={`/blog/${this.state.slug}`} 
              force={this.stateChanged}
            />

            <div className="site-page-panel">
              <div className="site-page-column">
                <div>Title:</div>
                <input placeholder="Title" value={this.state.title} onChange={this.onTitleChange} />
              </div>
              <div className="site-page-row">
                <div className="site-page-subtext">
                  Permalink: /blog/{this.state.slug}
                </div>
                <div className="site-page-subtext" style={{cursor: 'pointer'}} onClick={()=>this.openEditSlug()}>
                  <BsPencilFill />
                </div>
              </div>
            </div>

            <SharedQuillEditor
              placeholder='Enter blog content...'
              hasFontSize={true} 
              onChange={this.onContentChange}
              getRef={(ref: any) => {this.quillRef = ref}} 
            />

            <div className="site-page-panel">
              <div className="site-page-row" style={{justifyContent: 'space-between'}}>
                <div>Word count: <b>{this.state.wordCount}</b></div>
                {(!this.state.editBlog || (this.state.editBlog && this.blog.state != '')) &&
                  <div className='edit-blog-page-state'>{this.state.editBlog ? this.blog.state : 'New'}</div>
                }
              </div>
            </div>

            <div className="site-page-row" style={{flexWrap: 'wrap', justifyContent: 'left', rowGap: '10px', marginTop: '5px'}}>
              <button onClick={()=>this.onSave()}>
                {this.state.brandNew ? 'Save Draft' : 'Save'}
              </button>
              
              {this.state.editBlog && this.blog.state == 'draft' &&
                <button onClick={()=>this.onActions('Publish')}>Publish</button>
              }

              {this.blog.state == 'published' &&
                <button onClick={()=>this.onActions('Unpublish')}>Unpublish</button>
              }

              { (this.blog.state == 'draft' || this.blog.state == 'published') &&
                <button onClick={()=>this.onActions('Archive')}>Archive</button>
              }

              {this.blog.state == 'archived' &&
                <button onClick={()=>this.onActions('Restore')}>Restore</button>
              }

              {this.state.editBlog &&
                <button onClick={()=>this.onActions('Delete')}>Delete</button>
              }
            </div>
          </div>
        </PageEnvelope>

        <this.EditSlugModal />
        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.onAlertClose()}/>
        <QuestionModal message={this.state.question} onYes={this.onQuestionYes} onNo={this.onQuestionNo} />
      </div>
    );
  }
}

export default EditBlogPage;