import React from 'react';
import { AiFillHeart, AiOutlineHeart, AiOutlineRetweet } from 'react-icons/ai';
import { numberWithCommas } from '../util/util';
import { BsChatDots } from 'react-icons/bs';
import '../pages/SitePage.css';
import './ActivityButtons.css';
import { Server } from '../../server/server';

interface ActivityButtonsProps {
  post: any;
  reply?: boolean;
  onUpdate?: Function;
  reposts: number;
  reposted: boolean;
  likes: number;
  liked: boolean;
}

interface ActivityButtonsState {
  message: string;
  alert: string;
  likes: number;
  liked: boolean;
  reposts: number;
  reposted: boolean;
}

class ActivityButtons extends React.Component<ActivityButtonsProps, ActivityButtonsState> {
  protected processing:boolean;

  constructor(props: ActivityButtonsProps) {
    super(props);
    this.state = {
      message: '',
      alert: '',
      likes: props.likes,
      liked: props.liked,
      reposts: props.reposts,
      reposted: props.reposted
    };
    this.processing = false;
  }

  componentDidMount(): void {
  }

  componentDidUpdate(prevProps: Readonly<ActivityButtonsProps>) {
    if(this.props.post.id != prevProps.post.id) {
      // console.warn(`post id changed from ${prevProps.post.id} to ${this.props.post.id}`)
      this.setState({
        likes: this.props.likes,
        liked: this.props.liked,
        reposts: this.props.reposts,
        reposted: this.props.reposted
      })
    }
    else {
      if(this.props.reposts != prevProps.reposts) {
        // console.warn(this.props.post.id, `reposts changed from ${prevProps.reposts} to ${this.props.reposts}`)
        this.setState({reposts: this.props.reposts})
      }
      if(this.props.reposted != prevProps.reposted) {
        // console.warn(this.props.post.id, `reposted changed from ${prevProps.reposted} to ${this.props.reposted}`)
        this.setState({reposted: this.props.reposted})
      }
      if(this.props.likes != prevProps.likes) {
        // console.warn(this.props.post.id, `likes changed from ${prevProps.likes} to ${this.props.likes}`)
        this.setState({likes: this.props.likes})
      }
      if(this.props.liked != prevProps.liked) {
        // console.warn(this.props.post.id, `liked changed from ${prevProps.liked} to ${this.props.liked}`)
        this.setState({liked: this.props.liked})
      }
    }
  }

  async onLike(e:any) {
    e.stopPropagation();

    if(this.processing)
      return;

    if(!Server.account.isLoggedIn()) 
      return;

    this.processing = true;

    let postId = this.props.post.id;

    let liked = !this.state.liked;
    let likes = this.state.likes + (liked ? 1 : -1);
    this.setState({likes, liked});

    let response = null;
    if (liked)
      response = await Server.activity.likePost(postId);
    else
      response = await Server.activity.unlikePost(postId);

    if (response.success) {
      if(liked)
        Server.user.incrementCounter('activity#like');
      Server.activity.likeCachedPost(postId, liked);
      if(this.props.onUpdate)
        this.props.onUpdate();
    }
    else {
      liked = !liked;
      likes = this.state.likes + (liked ? 1 : -1);
      this.setState({likes, liked});
    }

    this.processing = false;
  }

  async onRepost(e:any) {
    e.stopPropagation();

    if(this.processing)
      return;

    if(!Server.account.isLoggedIn()) {
      this.setState({alert: 'Please login to react to posts.'});
      return;
    }

    if(this.props.post.author == Server.user.getId()) {
      this.setState({alert: 'You cant repost your own posts.'})
      return;
    }

    this.processing = true;

    let reposted = !this.state.reposted;
    let reposts = this.state.reposts + (reposted ? 1 : -1);
    this.setState({reposts, reposted});

    let response = null;
    if (reposted)
      response = await Server.activity.repost(this.props.post.id);
    else
      response = await Server.activity.undoRepost(this.props.post.id);

    if (response.success) {
      if(reposted)
        Server.user.incrementCounter('activity#repost');
      Server.activity.repostCachedPost(this.props.post.id, reposted);
      if(this.props.onUpdate)
        this.props.onUpdate();
    }
    else {
      reposted = !reposted;
      reposts = this.state.reposts + (reposted ? 1 : -1);
      this.setState({reposts, reposted});
    }

    this.processing = false;
  }

  render() {
    // console.warn('render', this.props.post.id, this.props.post.reposts, this.state.reposts);

    let margin = this.props.reply ? 55 : 0;
    let likeColor = this.state.liked ? 'red' : 'inherit';
    let repostColor = this.state.reposted ? 'var(--notification-background-color)' : 'inherit';

    return (
      <div className='activity-buttons' style={{marginLeft: margin + 'px', marginTop: '5px'}}>
        <div className='activity-button' style={{color: likeColor}} onClick={(e)=>this.onLike(e)}>
          <div className='activity-button-icon'>
            {this.state.liked ? <AiFillHeart /> : <AiOutlineHeart />}
          </div>
          <div>
            {numberWithCommas(this.state.likes)}
          </div>
        </div>

        <div className='activity-button' style={{color: repostColor}} onClick={(e)=>this.onRepost(e)}>
          <div className='activity-button-icon'>
            <AiOutlineRetweet />
          </div>
          <div>
            {numberWithCommas(this.state.reposts)}
          </div>
        </div>

        <div className='activity-button'>
          <div className='activity-button-icon'>
            <BsChatDots />
          </div>
          <div>
            {typeof(this.props.post.replies) == 'number' 
              ? numberWithCommas(this.props.post.replies)
              : this.props.post.replies.length
            }
          </div>
        </div>
      </div>
    )
  }
}

export default ActivityButtons;