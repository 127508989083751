import React from 'react';
import { Server } from '../../server/server';
import AlertModal from '../modals/AlertModal';
import ChangeEmailModal from '../modals/ChangeEmailModal';
import MessageModal from '../modals/MessageModal';
import PageEnvelope from '../elements/PageEnvelope';
import { Navigate } from 'react-router-dom';
import './SitePage.css';

interface AccountPageState {
  name: string;
  slug: string;
  email: string;
  code: string;
  oldPassword: string;
  newPassword: string;
  message: string;
  alert: string;
  loading: boolean;
  verifyEmail: boolean;
}

class AccountPage extends React.Component<{}, AccountPageState> {
  constructor(props:{}) {
    super(props);

    this.state = {
      message: '',
      alert: '',
      email: '',
      code: '',
      oldPassword: '',
      newPassword: '',
      name: '',
      slug: '',
      loading: false,
      verifyEmail: false
    };
  }

  componentDidMount(): void {
    this.setState({
      name: Server.user.getName(),
      slug: Server.user.getSlug(),
      email: Server.account.getEmail()
    })
  }

  async onSavePassword() {
    if(this.state.newPassword.length == 0 || this.state.oldPassword.length == 0) {
      this.setState({alert: 'Password cannot be blank.'});
      return;
    }

    this.setState({message: 'Changing password...', loading: true});

    let response = await Server.account.changePassword(this.state.oldPassword, this.state.newPassword);

    this.setState({message: '', loading: false})
    if(response.success)
      this.setState({alert: 'Password successfully changed!', newPassword: '', oldPassword: ''})
    else
      this.setState({alert: response.message});
  }

  async onSaveProfile() {
    if(this.state.name.length == 0) {
      this.setState({alert: 'Name cannot be blank.'});
      return;
    }

    if(this.state.slug.length == 0) {
      this.setState({alert: 'Slug cannot be blank.'});
      return;
    }

    this.setState({message: 'Saving...', loading: true});

    let response = await Server.user.setProfile({name: this.state.name, slug: this.state.slug});

    if(response.success)
      this.setState({message: '', loading: false})
    else
      this.setState({message: '', alert: response.message, loading: false});
  }

  async onSaveEmail() {
    if(this.state.email.length == 0) {
      this.setState({alert: 'Email cannot be blank.'});
      return;
    }

    if(this.state.email == Server.account.getEmail()) {
      this.setState({alert: 'Enter a new email address for this account.'});
      return;
    }

    this.setState({message: 'Sending verification email...'});

    setTimeout(async () => {
      let response = await Server.account.changeEmail(this.state.email);
      this.setState({message: ''});
      if(response.success)
        this.setState({verifyEmail: true});
      else
        this.setState({alert: response.message})
    }, 1000);
  }

  onVerifyEmail() {
    if(this.state.code.length == 0) {
      this.setState({alert: 'Code cannot be blank.'});
      return;
    }

    this.setState({message: 'Verifying code...'});

    setTimeout(async () => {
      let response = await Server.account.verifyEmail(this.state.code);
      this.setState({message: ''});
      if(response.success) 
        this.setState({alert: 'Email address changed.', verifyEmail: false});
      else 
        this.setState({alert: response.message});
    }, 1000);
  }

  renderVerifyEmailModal() {
    return (
      <div className="modal open">
        <div className="modal-content" style={{paddingTop: '8px', maxWidth: '250px', position: 'relative'}}>
          <div className="site-page-column" style={{rowGap: '15px'}}>
            Enter the verification code from the email just sent to {this.state.email}:
            <input value={this.state.code} onChange={(e:any)=>this.setState({code: e.currentTarget.value})} />
            <div className="site-page-row" style={{justifyContent: 'space-between'}}>
              <button onClick={()=>this.setState({verifyEmail: false})}>Cancel</button>
              <button onClick={()=>this.onVerifyEmail()}>Confirm</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if(!Server.account.isLoggedIn())
      return(<Navigate replace to="/" />);

    return (
      <div className="site-page">
        <PageEnvelope width="500px">
          <div className="site-page-column">

            <div className="site-page-panel">
              <div className="site-page-column" style={{rowGap: '15px'}}>
                <div>Account Email</div>
                <div className="site-page-row">
                  <input 
                    style={{width: '100%'}} 
                    value={this.state.email} 
                    onChange={(e:any)=>this.setState({email: e.currentTarget.value})}
                  />
                </div>
                <div style={{textAlign: 'right'}}>
                  <button onClick={()=>this.onSaveEmail()} disabled={this.state.loading}>Save</button>                
                </div>
              </div>
            </div>

            <div className="site-page-panel">
              <div className="site-page-column" style={{rowGap: '15px'}}>
                <div>Account Password</div>
                <div className="site-page-row">
                  <div style={{width: '60px', color: 'var(--panel-subtext-color)'}}>Old</div>
                  <input 
                    style={{width: '100%'}} 
                    value={this.state.oldPassword} 
                    type="password"
                    onChange={(e:any)=>this.setState({oldPassword: e.currentTarget.value})}
                  />
                </div>
                <div className="site-page-row">
                  <div style={{width: '60px', color: 'var(--panel-subtext-color)'}}>New</div>
                  <input 
                    style={{width: '100%'}} 
                    value={this.state.newPassword} 
                    type="password"
                    onChange={(e:any)=>this.setState({newPassword: e.currentTarget.value})}
                  />
                </div>
                <div style={{textAlign: 'right'}}>
                  <button onClick={()=>this.onSavePassword()} disabled={this.state.loading}>Save</button>                
                </div>
              </div>
            </div>
          </div>
        </PageEnvelope>

        {this.state.verifyEmail && this.renderVerifyEmailModal()}

        <MessageModal message={this.state.message}/>
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
      </div>
    );
  }
}

export default AccountPage;
