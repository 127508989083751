import React from 'react';
import { Server } from '../../server/server';
import FriendPanel from './FriendPanel';

class FriendsPanel extends React.Component {
  constructor(props:{}) {
    super(props);
    this.onFriendUpdated = this.onFriendUpdated.bind(this);
  }

  componentDidMount() {
    Server.user.addEventListener('friend-updated', this.onFriendUpdated);
  }

  componentWillUnmount() {
    Server.user.removeEventListener('friend-updated', this.onFriendUpdated);
  }

  onFriendUpdated(data: any) {
    this.forceUpdate();
  }

  render() {
    let friends = Server.user.getFriends();

    if(friends.length == 0) {
      return( 
        <div className="site-page-info-panel">
          Ask your friends in real life for their handle (eg. @mrburns) and enter it above.
          You can also find the handle of any player on their public profile.
        </div>        
      )
    }

    let sortedFriends = [];
    for(let i = 0; i < friends.length; i++) {
      let profile  = Server.public.getProfile(friends[i].id);
      let friend:any = {...profile, ...friends[i]};
      sortedFriends.push(friend);
    }

    sortedFriends.sort((a, b)=>{
      if(a.state == 'invited' && b.state != 'invited')
        return 1;
      else if(a.state != 'invited' && b.state == 'invited')
        return -1;

      if(a.state == 'accepted' && b.state != 'accepted')
        return 1;
      else if(a.state != 'accepted' && b.state == 'accepted')
        return -1;

      if(a.state == 'pending' && b.state != 'pending')
        return 1;
      else if(a.state != 'pending' && b.state == 'pending')
        return -1;

      if((a.presence && a.presence.online) && (!b.presence || !b.presence.online))
        return 1;
      else if((!a.presence || !a.presence.online) && (b.presence && b.presence.online))
        return -1;
        
      if(a.name > b.name)
        return -1;
      else if(a.name < b.name)
        return 1;

      return 0;
    })

    let divs = [];
    for(let i = sortedFriends.length-1; i >= 0; i--) 
      divs.push(<FriendPanel key={i} metadata={sortedFriends[i]} onUpdate={this.onFriendUpdated} />);

    return (
      <div className="site-page-column">
        {divs}
      </div>      
    )
  }
}

export default FriendsPanel;