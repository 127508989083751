import { Server } from "./server";

export class PlaceManager {
  protected info: any;
  protected listeners: any[];
  protected chat: any[];
  protected medals: any[];
  protected leaderboard: any[];

  constructor() {
    this.listeners = [];
    this.info = null;
    this.chat = [];
    this.medals = [];
    this.leaderboard = [];

    Server.account.addEventListener('login', ()=>this.onLogin());
    Server.account.addEventListener('logout', ()=>this.onLogout());
    Server.network.addEventListener('place-user-join', (event:any)=>this.onUserJoin(event));
    Server.network.addEventListener('place-user-leave', (event:any)=>this.onUserLeave(event));
    Server.network.addEventListener('place-init', (event:any)=>this.onInit(event));
    Server.network.addEventListener('place-get-info', (event:any)=>this.onGetInfo(event));
    Server.network.addEventListener('place-info', (event:any)=>this.onInfo(event));
    Server.network.addEventListener('place-chat-message', (event:any)=>this.onChatMessage(event));
    Server.network.addEventListener('place-chat', (event:any)=>this.onChat(event));
    Server.network.addEventListener('place-get-leaderboard', (event:any)=>this.onGetLeaderboard(event));
    Server.network.addEventListener('place-leaderboard', (event:any)=>this.onLeaderboard(event));
    Server.network.addEventListener('place-score', (event:any)=>this.onScore(event));
    Server.network.addEventListener('place-user-state', (event:any)=>this.onUserState(event));
    Server.network.addEventListener('place-state', (event:any)=>this.onState(event));
    Server.network.addEventListener('place-medals', (event:any)=>this.onMedals(event));
  }

  public init() {
  }

  public disconnect() {
  }

  // event listeners
  public addEventListener(id:string, callback:Function) {
    this.listeners.push({id, callback});
  }

  public removeEventListener(id:string, callback:Function) {
    for(let i = 0; i < this.listeners.length; i++) {
      if(this.listeners[i].id == id && this.listeners[i].callback == callback) {
        this.listeners.splice(i, 1);
        return;
      }
    }
  }

  protected notifyListeners(eventId:string, eventData:any = {}) {
    for(let i = 0; i < this.listeners.length; i++) 
      if(this.listeners[i].id == eventId) 
        this.listeners[i].callback(eventData);
  }

  protected sendEventToOtherUsers(event:any, exclude?:string[]) {
    for(let i = 0; i < this.info.users.length; i++) {
      let uid = this.info.users[i].id;
      if(exclude && exclude.indexOf(uid) != -1)
        continue;
      if(uid == Server.user.getId())
        continue;
      Server.network.sendEvent('user:' + uid, event);
    }
  }

  protected sendEventToUser(user:string, event:any) {
    Server.network.sendEvent('user:' + user, event);
  }

  protected sendInfoToOtherUsers(exclude?:string[]) {
    this.sendEventToOtherUsers({id: 'place-info', info: this.info}, exclude);
  }

  protected sendInfoToUser(user:string) {
    Server.network.sendEvent('user:' + user, {id: 'place-info', info: this.info})
  }

  protected sendInfoToFriends() {
    let friends = Server.user.getFriends();
    for(let i = 0; i < friends.length; i++) {
      if(this.getUser(friends[i].id)) continue;
      if(!friends[i].presence || !friends[i].presence.online) continue;
      Server.network.sendEvent('user:' + friends[i].id, {id: 'place-info', info: this.info})
    }
  }


  // local user event handlers
  protected onLogin() {
    this.loadInfo();
    this.loadChat();
    this.loadLeaderboard();
    this.loadMedals();
  }

  protected onLogout() {
    console.log('onLogout');
  }

  // host info
  protected async saveInfo(saveSettings?:boolean) {
    if(saveSettings) {
      let settings = {
        name: this.info.name,
        description: this.info.description,
        banner: this.info.banner
      };

      await Server.user.setCookie({key: 'PlaceSettings', value: JSON.stringify(settings)});
    }

    window.sessionStorage.setItem('PlaceInfo', JSON.stringify(this.info));
  }

  protected loadInfo() {
    let s = window.sessionStorage.getItem('PlaceInfo');
    if(s) {
      this.info = JSON.parse(s);
    }
    else {
      let name =  Server.user.getName();
      if(name.substring(name.length-1) != 's')
        name += '\'s';
      name += ' Place';
  
      let description = 'A place to play games together!';
      let banner = '';
  
      this.info = {
        host: Server.user.getId(),
        name,
        description,
        banner,
        state: 'lobby',
        users: []
      }
    }
    
    let cookie = Server.user.getCookie('PlaceSettings');
    if(cookie) {
      let settings = JSON.parse(cookie);
      this.info.name = settings.name;
      this.info.description = settings.description;
      this.info.banner = settings.banner;
      this.info.medals = settings.medals;
    }
  }

  public getInfo(host?:string):any  {
    if(!host || host == Server.user.getId()) 
      return this.info;
    else
      Server.network.sendEvent('user:' + host, {id: 'place-get-info', user: Server.user.getId()});
  }

  protected onGetInfo(event:any) {
    this.sendInfoToUser(event.user);
  }

  protected onInfo(event:any) {
    this.notifyListeners('place-info', event.info)
  }

  public async setInfo(name:string, description: string) {
    this.info.name = name;
    this.info.description = description;
    this.sendInfoToOtherUsers();
    this.sendInfoToFriends();
    this.notifyListeners('place-info', this.info);
    await this.saveInfo(true);
  }

  public async setBanner(banner: string) {
    this.info.banner = banner;
    this.sendInfoToOtherUsers();
    this.sendInfoToFriends();
    this.notifyListeners('place-info', this.info);
    await this.saveInfo(true);
  }

  public getUser(id:string) {
    for(let i = 0; i < this.info.users.length; i++)
      if(this.info.users[i].id == id)
          return this.info.users[i];
    return null;
  }

  public getUsers() {
    return this.info.users;
  }

  protected removeUser(id:string) {
    let user = this.getUser(id);
    if(!user)
      return false;
      
    let n = this.info.users.indexOf(user);
    if(n == -1)
      return false;

    this.info.users.splice(n, 1);

    if(this.info.users.length == 0)
      this.info.state = 'lobby';

    return true;
  }

  // leaving and joining place
  public joinPlace(host:string) {
    if(host == Server.user.getId()) {
      let user = this.getUser(host);
      if(!user)
        this.info.users.push({id: host, state: 'lobby'});
      this.saveInfo();
      this.sendInfoToOtherUsers();
      this.sendInfoToFriends();
      this.notifyListeners('place-init', {
        info: this.info,
        chat: this.chat,
        leaderboard: this.leaderboard,
        medals: this.medals
      });
    }
    else
      Server.network.sendEvent('user:' + host, {id: 'place-user-join', user: Server.user.getId()});
  }

  protected onUserJoin(event:any) {
    let user = this.getUser(event.user);

    if(!user)
      this.info.users.push({id: event.user, state: 'lobby'});

    this.saveInfo();
    this.sendInfoToOtherUsers([event.user]);
    this.sendInfoToFriends();

    let data = {
      info: this.info,
      chat: this.chat,
      leaderboard: this.leaderboard,
      medals: this.medals
    };

    this.sendEventToUser(event.user, {id: 'place-init', data});
    this.notifyListeners('place-info', this.info);
  }

  protected onInit(event:any) {
    this.notifyListeners('place-init', event.data);
  }

  public leavePlace(host:string) {
    if(host == Server.user.getId()) {
      this.removeUser(host);
      this.saveInfo();
      this.sendInfoToOtherUsers();
      this.sendInfoToFriends();
    }
    else
      Server.network.sendEvent('user:' + host, {id: 'place-user-leave', user: Server.user.getId()});
  }

  public onUserLeave(event:any) {
    if(!this.removeUser(event.user))  
      return;

    this.saveInfo();
    this.sendInfoToOtherUsers([event.user]);
    this.sendInfoToFriends();
    this.notifyListeners('place-info', this.info);
  }

  // chat
  protected loadChat() {
    let s = window.sessionStorage.getItem('PlaceChat');
    if(s)
      this.chat = JSON.parse(s);
    else
      this.chat = [];
  }

  protected saveChat() {
    let s = JSON.stringify(this.chat);
    window.sessionStorage.setItem('PlaceChat', s);
    console.log('chat entries = ' + this.chat.length);
    console.log('chat length = ' + s.length);
  }

  protected addEntryToChat(entry:any) {
    if(this.chat.length > 9)
      this.chat.splice(0, this.chat.length - 9);

    this.chat.push(entry);
    this.saveChat();
  }

  public sendChatMessage(message:string, host:string) {
    let user = Server.user.getId();
    let event = {id: 'place-chat-message', message, user, forward: false};

    if(user == host) {
      this.addEntryToChat({message, user});
      this.sendEventToOtherUsers(event);
    }
    else {
      event.forward = true;
      Server.network.sendEvent('user:' + host, event);
    }
  }

  protected onChatMessage(event:any) {
    let entry = {message: event.message, user: event.user};

    if(event.forward) {
      this.addEntryToChat(entry);
      event.forward = false;
      this.sendEventToOtherUsers(event, [event.user]);
    }

    this.notifyListeners('place-chat-message', entry);
  }

  protected onChat(event:any) {
    this.notifyListeners('place-chat', event.chat);
  }

  public getChat() {
    return this.chat;
  }

  public clearChat() {
    this.chat = [];
    this.saveChat();
    this.sendEventToOtherUsers({id: 'place-chat', chat: []});
  }

  // game leaderboard
  protected saveLeaderboard() {
    window.sessionStorage.setItem('PlaceLeaderboard', JSON.stringify(this.leaderboard));
  }

  protected loadLeaderboard() {
    let s = window.sessionStorage.getItem('PlaceLeaderboard');
    if(s)
      this.leaderboard = JSON.parse(s);
    else
      this.leaderboard = [];
  }

  protected updateLeaderboard(user:string, score:number) {
    for(let i = 0; i < this.leaderboard.length; i++) {
      if(this.leaderboard[i].id == user) {
        this.leaderboard[i].score = score;
        return;
      }
    }

    this.leaderboard.push({id: user, score});
  }

  public resetLeaderboard() {
    this.leaderboard = [];
    let users = this.getUsers();
    for(let i = 0; i < users.length; i++) 
      this.leaderboard.push({id: users[i].id, score: 0});
    this.saveLeaderboard();
    this.sendEventToOtherUsers({id: 'place-leaderboard', leaderboard: this.leaderboard});
    this.notifyListeners('place-leaderboard', this.leaderboard);
  }

  public getLeaderboard(host?:string) {
    if(!host || host == Server.user.getId()) 
      return this.leaderboard;
    else {
      Server.network.sendEvent('user:' + host, {id: 'place-get-leaderboard', user: Server.user.getId()});
      return [];
    }
  }

  protected onGetLeaderboard(event:any) {
    this.sendEventToUser(event.user, {id: 'place-leaderboard', leaderboard: this.leaderboard});
  }

  protected onLeaderboard(event:any) {
    this.notifyListeners('place-leaderboard', event.leaderboard);
  }

  setScore(score:any, host:string) {
    let user = Server.user.getId();
    let event = {id: 'place-score', user, score, save: false};

    if(user == host) {
      this.updateLeaderboard(user, score);
      this.saveLeaderboard();
      this.sendEventToOtherUsers(event);
    }
    else {
      event.save = true;
      Server.network.sendEvent('user:' + host, event);
    }

    this.notifyListeners('place-score', {id: user, score});
  }

  onScore(event:any) {
    if(event.save) {
      this.updateLeaderboard(event.user, event.score);
      this.saveLeaderboard();
      event.save = false;
      this.sendEventToOtherUsers(event, [event.user]);
    }

    this.notifyListeners('place-score', {id: event.user, score: event.score});
  }

  // place state
  public setState(state:string) {
    this.info.state = state;
    this.sendEventToOtherUsers({id: 'place-state', state});
    this.notifyListeners('place-state', state);
    this.saveInfo();
  }

  public setStateForPlaceAndUsers(state:string) {
    this.info.state = state;
    for(let i = 0; i < this.info.users.length; i++) 
      this.info.users[i].state = state;
    this.sendInfoToOtherUsers();
    this.notifyListeners('place-info', this.info);
    this.saveInfo();
  }

  protected onState(event:any) {
    this.notifyListeners('place-state', event.state);
  }

  // user state
  public async setUserState(state:string, host:string) {
    // console.log('setUserState', state, host);

    let user = Server.user.getId();
    let event = {id: 'place-user-state', user, state, save: false};

    if(user == host) {
      let userInfo = this.getUser(user);
      userInfo.state = state;
      this.saveInfo();
      this.sendEventToOtherUsers(event);
    }
    else {
      event.save = true;
      Server.network.sendEvent('user:' + host, event);
    }

    this.notifyListeners('place-user-state', {id: user, state});
  }

  protected async onUserState(event:any) {
    // console.log('onUserState', event);

    if(event.save) {
      let userInfo = this.getUser(event.user);
      if(userInfo) {
        userInfo.state = event.state;
        this.saveInfo();
      }
      event.save = false;
      this.sendEventToOtherUsers(event, [event.user]);
    }

    this.notifyListeners('place-user-state', {id: event.user, state: event.state});
  }

  // medals

  protected loadMedals() {
    let s = Server.user.getCookie('PlaceMedals');
    if(s)
      this.medals = JSON.parse(s);
    else
      this.medals = [];
  }

  protected async saveMedals() {
    await Server.user.setCookie({key: 'PlaceMedals', value: JSON.stringify(this.medals)});
  }

  public getMedals() {
    return this.medals;
  }

  protected onMedals(event:any) {
    this.notifyListeners('place-medals', event.medals);
  }

  protected sendMedalsToOtherUsers() {
    this.sendEventToOtherUsers({id: 'place-medals', medals: this.medals});
  }
  
  public async awardMedals(users: any[]) {
    for(let i = 0; i < users.length; i++) {
      let user = null;
      for(let j = 0; j < this.medals.length; j++) 
        if(this.medals[j].id == users[i].id)
          user = this.medals[j];
  
      if(user)
        user.count += users[i].count;
      else {
        user = {...users[i]};
        this.medals.push(user);
      }
    }

    this.sendMedalsToOtherUsers();
    this.notifyListeners('place-medals', this.medals);
    await this.saveMedals();
  }

  public async resetMedals() {
    this.medals = [];
    this.sendMedalsToOtherUsers()
    this.notifyListeners('place-medals', this.medals);
    await this.saveMedals();
  }
}