/**
 * Cards Manager.
 *
 * Kevin Ryan
 */

import { RandomNumberGenerator } from '../common/randomNumberGenerator';
import { hashString } from '../common/util';
import { Game } from './game';
import { SpriteSheet, SsImage } from '../common/spriteSheetManager';

// 0-12:  Diamonds
// 13-25: Hearts
// 26-38: Clubs
// 39-51: Spades

export enum CardSuit {
  Diamonds,
  Hearts,
  Clubs,
  Spades
}

export enum CardColor {
  Red,
  Black
}

// Solitaire
export enum CardSource {
  Deck,
  Tableau,
  Waste,
  Foundation
}

// Poker
export enum PokerHandType {
  Void,
  HighCard,
  OnePair,
  TwoPair,
  ThreeOfKind,
  Straight,
  Flush,
  FullHouse,
  FourOfKind,
  StraightFlush,
  RoyalFlush
}

export interface CardOverInfo {
  card: Card, x: number, y: number, source: CardSource, sourceIndex: number, sourceSubIndex: number
}

export class Card {
  public suit: CardSuit;
  public color: CardColor;
  public faceUp: boolean;
  public canAutoPlay: boolean;
  public playedToFoundation: boolean;

  constructor(public type: number) {
    this.suit = Math.floor(type / 13);
    this.color = Math.floor(type / 26);
    this.faceUp = false;
    this.canAutoPlay = true;
    this.playedToFoundation = false;
  }

  /**
   * Clones this card.
   */
  clone(): Card {
    let card = new Card(this.type);
    card.faceUp = this.faceUp;
    card.canAutoPlay = this.canAutoPlay;

    return card;
  }

  /**
   * Draws this card.
   * @param x - Screen x loc
   * @param y - Screen y loc
   * @param w - Width
   * @param h - Height
   */
  draw(x: number, y: number, w: number, h: number) {
    let width = 0;  //Game.game.canvasWidth - 340;
    if (this.faceUp) {
      Game.instance.cardsManager.drawCard(this.type, x, y, w, h);
    }
    else {
      Game.instance.cardsManager.drawCardBack(x, y, w, h);
    }
  }
}

export class Deck {
  public seed: number;
  private seedAdd: number;
  public randGen: RandomNumberGenerator;

  public cards: Card[];

  constructor(deck: Deck = null) {
    this.seed = 0;
    this.seedAdd = 0;
    this.randGen = new RandomNumberGenerator(this.seed);

    if (deck === null) {
      this.create();
    }
    else {
      this.clone(deck);
    }
  }

  /**
   * Creates the array of 52 cards.
   */
  create() {
    this.cards = [];
    for (let i = 0; i < 52; i++) {
      this.cards[i] = new Card(i);
    }
  }

  /**
   * Clones the cards into this deck from the passed in deck.
   * @param deck - Deck to clone
   */
  clone(deck: Deck) {
    this.cards = [];
    for (let i = 0; i < deck.cards.length; i++) {
      this.cards[i] = deck.cards[i].clone();
    }
  }

  /**
   * Resets the deck to the passed in cards.
   * @param cards - Cards to reset the deck to
   */
  resetDeck(cards: Card[]) {
    for (let i = 0; i < 52; i++) {
      this.cards[i] = cards[i];
      this.cards[i].faceUp = false;
    }
  }

  /**
   * Updates the seed using the random number generator that was seeded with the current seed.
   */
  getUpdatedSeed() {
    return this.seed + this.randGen.getRandRange(0, 100000000000);
  }

  /**
   * Gets a seed using milliseconds since Jan 1, 1970.
   */
  getInitialSeed(): number {
    let d = new Date();
    let t = d.getTime();  // milliseconds since Jan 1, 1970
    let h = hashString(`${t}`);
    let seed = t + h + this.seedAdd;  // milliseconds since Jan 1, 1970
    return seed;
  }

  /**
   * Seeds the random number generator that is used to shuffle the deck of cards.
   * @param seed - Seed to use (0 means generate a random seed)
   */
  seedDeck(seed: number = 0): number {
    if (seed === 0) {
      seed = this.getInitialSeed();
      this.seedAdd = this.randGen.getRandRange(0, 100000000);
    }

    // Seed the random number generator that will be used to shuffle the deck
    this.seed = seed;
    this.randGen.seed(this.seed);

    return seed;
  }

  /**
   * Shuffles the deck of cards.
   */
  shuffle() {
    // Shuffles the deck
    // 1. Sets selectLength to number of cards in the deck.
    // 2. Picks a random card in the deck in range of first card to card at selectLength index.
    // 3. Exchanges this card with the last card in the deck.
    // 4. Reduces selectLength by 1.
    // 5. Goes back to step 1 until selectLength is 0.
    //
    // Note that this could also be done by removing a random card from the original deck and
    // placing it in a new deck until all cards are removed from the original deck. This code
    // just does the same thing as this, but it does it in place only using the original deck.
    // It's more efficient this way - not needing two arrays and not having to splice cards
    // out of the original array.
    let selectLength = this.cards.length - 1;
    while (selectLength > 0) {
      let index = this.randGen.getRandRange(0, selectLength);
      let card = this.cards[index];
      this.cards[index] = this.cards[selectLength];
      this.cards[selectLength] = card;
      selectLength--;
    }
  }

  /**
   * Returns the card at the end of the deck and removes it from the deck array.
   */
  dealCard(): Card {
    return this.cards.pop();
  }
}

export class CardsManager {
  public readyToGo: boolean;
  public useSmallCardArt: boolean;

  private loadingCardArt: boolean;
  private spriteSheet: SpriteSheet;
  private readonly ssImages: SsImage[];
  private cardBackImage: SsImage;
  private suitLetters: string[];

  constructor() {
    this.readyToGo = false;
    this.loadingCardArt = false;
    this.useSmallCardArt = false;
    this.ssImages = [];
    this.suitLetters = [ 'D', 'H', 'C', 'S' ];
  }

  isReadyToGo() {
    return this.readyToGo;
  }

  loadCardArt() {
    if (this.loadingCardArt) {
      return;
    }

    let width = window.outerWidth;
    this.useSmallCardArt = (width <= 450);

    let cardsArt = '';
    let cardsJson = '';

    if (this.useSmallCardArt) {
      cardsArt = require(`./assets/cardsSmall.png`);
      cardsJson = require(`./assets/cardsSmall.json`);
    }
    else {
      cardsArt = require(`./assets/cards.png`);
      cardsJson = require(`./assets/cards.json`);
    }

    this.loadingCardArt = true;
    this.spriteSheetCallback = this.spriteSheetCallback.bind(this);
    this.spriteSheet = Game.instance.spriteSheetManager.load(cardsArt, cardsJson, this.spriteSheetCallback);
  }

  /**
   * Callback when sprite sheet has been loaded.
   * @param ss - Sprite sheet that was loaded
   */
  spriteSheetCallback(ss: SpriteSheet) {
    this.readyToGo = true;

    for (let i = 0; i < 52; i++) {
      let cardIndex = i % 13;
      let suitIndex = Math.floor(i / 13);
      let suitLetter = this.suitLetters[suitIndex];
      let imageName = `card${cardIndex + 1}${suitLetter}.png`;
      this.ssImages[i] = this.spriteSheet.getSsImage(imageName);
    }

    this.cardBackImage = this.spriteSheet.getSsImage('cardBack.png');
  }

  /**
   * Creates a deck of cards.
   */
  createDeck() {
    return new Deck();
  }

  /**
   * Draws the card at the passed in location with the passed in width x height.
   * @param type - Card type
   * @param x - Screen x loc
   * @param y - Screen y loc
   * @param w - Card width
   * @param h - Card height
   */
  drawCard(type: number, x: number, y: number, w: number, h: number) {
    this.ssImages[type].draw(x, y, w, h);
  }

  /**
   * Draws the card back at the passed in location with the passed in width x height.
   * @param x - Screen x loc
   * @param y - Screen y loc
   * @param w - Card width
   * @param h - Card height
   */
  drawCardBack(x: number, y: number, w: number, h: number) {
    this.cardBackImage.draw(x, y, w, h);
  }
}

export class Combinations {
  public callback: Function;
  public callbackParams: any;

  constructor() {
  }

  /**
   * Generates all possible combinations of the passed in array of numbers and makes a call to
   * the callback function. Callback function and callback params are set in the class before
   * the call to this is made.
   * @param indexes - Array of numbers to be combined
   * @param curCombo - Current combo
   * @param start - Start index in indexes to copy from
   * @param end - End index in indexes to copy from
   * @param index - Current size of the curCombo array
   * @param targetSize - Size of array to contain combos
   */
  generateCombos(indexes: number[], curCombo: number[], start: number, end: number, index: number,
                 targetSize: number): number[] {
    if (index === targetSize) {
      this.callback(curCombo, this.callbackParams);
      return;
    }

    for (let i = start; i <= end; i++) {
      curCombo[index] = indexes[i];
      this.generateCombos(indexes, curCombo, i + 1, end, index + 1, targetSize);
    }
  }
}

class PokerHandCheck {
  public cards: Card[];
  public handType: PokerHandType;
  public value: number;

  constructor() {
    this.cards = [];
  }
}

export class PokerManager extends CardsManager {
  private readonly pokerHandCheck: PokerHandCheck;
  private readonly pokerHandNames: string[];
  private readonly combinations: Combinations;

  constructor() {
    super();

    this.pokerHandCheck = new PokerHandCheck();
    this.combinations = new Combinations();

    this.pokerHandNames = [
      'Void', 'High Card', 'One Pair', 'Two Pair', 'Three of a Kind', 'Straight', 'Flush', 'Full House',
      'Four of a Kind', 'Straight Flush', 'Royal Flush'
    ];

    this.comboCallback = this.comboCallback.bind(this);
  }

  /**
   * Returns name of the passed in poker hand type.
   * @param pokerHandType - Poker hand type.
   */
  getPokerHandName(pokerHandType: PokerHandType): string {
    return this.pokerHandNames[pokerHandType];
  }

  /**
   * Takes the 5 cards starting at the passed in index and returns the poker hand type for them.
   * @param cards - Array of Cards
   * @param startIndex - Index to start at in the cards array.
   */
  getPokerHandType(cards: Card[], startIndex: number = 0): PokerHandType {
    // Put 5 cards into a working array of types
    let initCardType = [];
    let testingCardType = [];
    let sortedCardType = [];
    for (let i = 0; i < 5; i++) {
      initCardType[i] = cards[i + startIndex].type;
      testingCardType[i] = initCardType[i] % 13;
      sortedCardType[i] = initCardType[i] % 13;
    }

    // Sort the card types - low to high values
    sortedCardType.sort((c1, c2) => {
      return c1 - c2;
    });

    // Flush and straight are useful for determining hand type
    let flush = true;
    let straight = true;
    let onePair = false;
    let twoPair = false;
    let pairType = -1;

    for (let i = 0; i < 4; i++) {
      // Check for flush
      if (Math.floor(initCardType[i] / 13) !== Math.floor(initCardType[i + 1] / 13)) {
        flush = false;
      }

      // An Ace can make a straight at both ends
      if (sortedCardType[i] + 1 !== sortedCardType[i + 1]) {
        if (i !== 0) {
          straight = false
        }
        else if (sortedCardType[0] !== 0 || sortedCardType[1] !== 9) {
          straight = false;
        }
      }

      // Checking for Pair and Two Pair
      if (sortedCardType[i] === sortedCardType[i + 1]) {
        if (onePair) {
          if (sortedCardType[i] !== pairType && pairType !== -1)
            twoPair = true;
        }
        else {
          onePair = true;
          pairType = sortedCardType[i];
        }
      }
    }

    // Royal Flush (10 is type 9 and Ace is type 0 )
    if (flush && straight && sortedCardType[0] === 0 && sortedCardType[1] === 9) {
      return PokerHandType.RoyalFlush;
    }

    // Straight Flush
    if (flush && straight) {
      return PokerHandType.StraightFlush;
    }

    // Four of a Kind
    if (sortedCardType[0] === sortedCardType[3] || sortedCardType[1] === sortedCardType[4]) {
      return PokerHandType.FourOfKind;
    }

    // Full House
    if (sortedCardType[2] === sortedCardType[1] || sortedCardType[2] === sortedCardType[3]) {
      if (sortedCardType[0] == sortedCardType[1] && sortedCardType[3] == sortedCardType[4])
        return PokerHandType.FullHouse;
    }

    // Flush
    if (flush) {
      return PokerHandType.Flush;
    }

    // Straight
    if (straight) {
      return PokerHandType.Straight;
    }

    // Three of a Kind
    if (sortedCardType[0] == sortedCardType[2] || sortedCardType[1] == sortedCardType[3] ||
        sortedCardType[2] == sortedCardType[4]) {
      return PokerHandType.ThreeOfKind;
    }

    if (twoPair) {
      return PokerHandType.TwoPair;
    }

    if (onePair) {
      return PokerHandType.OnePair;
    }

    return PokerHandType.HighCard;
  }

  /**
   * Callback from the combo function. Takes the 5 cards and computes the poker hand and
   * tracks if it is the best hand so far.
   * @param combo - Array of indexes into the info.cards array.
   * @param info
   */
  comboCallback(combo: number[], info: PokerHandCheck) {
    let handToCheck = [];
    for (let i = 0; i < 5; i++) {
      handToCheck[i] = info.cards[combo[i]];
    }

    let handType = this.getPokerHandType(handToCheck);
    if (handType > info.handType) {
      info.handType = handType;
    }
  }

  /**
   * Takes passed in array of 7 cards and returns the best hand that can be created from them.
   * @param cards - Array of 7 cards.
   */
  getBestPokerHandFromSevenCards(cards: Card[]): PokerHandType {
    let indexes = [];
    for (let i = 0; i < 7; i++) {
      indexes[i] = i;
    }

    let curCombo = [] as any;
    this.pokerHandCheck.cards = [];
    for (let i = 0; i < cards.length; i++) {
      this.pokerHandCheck.cards[i] = cards[i];
    }
    this.pokerHandCheck.handType = PokerHandType.Void;
    this.combinations.callback = this.comboCallback;
    this.combinations.callbackParams = this.pokerHandCheck;
    this.combinations.generateCombos(indexes, curCombo, 0, 6, 0, 5);

    return this.pokerHandCheck.handType;
  }
}