import { UIManager } from "./uiManager";
import { UIElement } from "./uiElement";
import { UIBounds } from "./uiBounds";
import { UIElementOptions } from "./uiElement";

export interface UIImageOptions extends UIElementOptions {
  url?: string;
  slice?: UIBounds;
  stretch?: boolean;
  repeat?: boolean;
  radius?: number;
  borderWidth?: number;
  borderColor?: string;
  flip?: boolean;
  callback?: Function;
  cover?: boolean;
}

class CachedImage {
  public url: string;
  public image: HTMLImageElement;
  public loaded: boolean;
  public locked: boolean;
}

export class UIImage extends UIElement {
  protected static cachedImages:Array<CachedImage> = new Array<CachedImage>();

  protected static findCachedImage(url: string): CachedImage {
    for(var i = 0; i < UIImage.cachedImages.length; i++)
      if(UIImage.cachedImages[i].url.indexOf(url) != -1)
        return UIImage.cachedImages[i];
    return null;
  }

  protected static cacheImage(url:string):CachedImage {
    var ci = new CachedImage();
    ci.url = url;
    ci.loaded = false;
    ci.image = new Image();
    ci.locked = false;
    UIImage.cachedImages.push(ci);
    return ci;
  }

  public static lockImageCache() {
    for(var i = 0; i < UIImage.cachedImages.length; i++)
      UIImage.cachedImages[i].locked = true;
  }

  public static cleanupImageCache() {
    for(var i = 0; i < UIImage.cachedImages.length; i++) {
      if(!UIImage.cachedImages[i].locked) {
        UIImage.cachedImages[i].image = null;
        UIImage.cachedImages.splice(i, 1);
        i--;
      }
    }
  }


  public stretch: boolean;
  public repeat: boolean;
  public slice: UIBounds;
  public loaded: boolean;
  public radius: number;
  public borderWidth: number;
  public borderColor: string;
  public flip: boolean;
  public cover: boolean;

  protected cachedImage: CachedImage;
  protected callback: Function;

  public get url():string {
    return this.cachedImage.url;
  }

  public set url(url:string) {
    this.loaded = false;
    this.cachedImage = UIImage.findCachedImage(url);

    if(this.cachedImage) {
      if(!this.cachedImage.loaded)
        this.cachedImage.image.addEventListener('load', () => this.onImageLoaded());
      else
        this.onImageLoaded();
    }
    else {
      // console.log('Loading ' + url);
      this.cachedImage = UIImage.cacheImage(url);
        this.cachedImage.image.addEventListener('load', () => this.onImageLoaded());
        this.cachedImage.image.src = url;
      }
    }

  public get imageWidth():number {
    return this.cachedImage.image.width * this.scale;
  }

  public get imageHeight():number {
    return this.cachedImage.image.height * this.scale;
  }

  constructor(options: UIImageOptions) {
    super(options);

    this.stretch = false;
    this.repeat = false;
    this.callback = null;
    this.radius = 0;
    this.borderWidth = 0;
    this.borderColor = '#00000000';
    this.cover = false;

    if(options.slice)
      this.slice = options.slice;

    if(options.stretch != undefined)
      this.stretch = options.stretch;

    if(options.repeat != undefined)
      this.repeat = options.repeat;

    if(options.flip != undefined)
      this.flip = options.flip;

    if(options.cover != undefined)
      this.cover = options.cover;

    if(options.radius != undefined)
      this.radius = options.radius;

    if(options.borderWidth)
      this.borderWidth = options.borderWidth;

    if(options.borderColor)
      this.borderColor = options.borderColor;

    if(options.callback)
      this.callback = options.callback;

    if(options.url)
      this.url = options.url;
  }

  public onImageLoaded() {
    this.loaded = true;

    var cachedImage = UIImage.findCachedImage(this.cachedImage.url);
        cachedImage.loaded = true;

    if(this.width == 0)
      this.size.x = this.imageWidth;

    if(this.height == 0)
      this.size.y = this.imageHeight;

    if(this.callback) {
    setTimeout(() => {
        this.callback();
    }, 10);
  }
    }

  public draw() {
    if(!this.cachedImage || !this.cachedImage.loaded)
      return;

    if(this.radius > 0) {
      UIManager.ctx.save();
      this.drawClipPath();
      UIManager.ctx.clip();
    }

    if(this.slice)
      this.drawSlice9();
    else if(this.stretch) {
      var sw = this.width * this.scale;
      var sx = (this.width - sw) / 2;
      var sh = this.height * this.scale;
      var sy = (this.height - sh) / 2;

      if(UIManager.isMobile) {
        UIManager.ctx.save();
        UIManager.ctx.imageSmoothingEnabled = true;
        UIManager.ctx.imageSmoothingQuality = 'high';
      }

      UIManager.ctx.drawImage(this.cachedImage.image, this.getScreenX() + sx, this.getScreenY() + sy, sw, sh);
      
      if(UIManager.isMobile)
        UIManager.ctx.restore();
    }
    else if(this.repeat)
    {
      var cx = this.width / this.imageWidth;
      var cy = this.height / this.imageHeight;

      for(var sy = 0; sy < cy; sy++)
      {
        for(var sx = 0; sx < cx; sx++)
        {
          UIManager.ctx.drawImage(this.cachedImage.image, this.getScreenX() + (sx * this.imageWidth), this.getScreenY() + (sy * this.imageHeight), this.imageWidth, this.imageHeight);
        }
      }
    }
    else if(this.cover) {
      let vr = (this.parent.height / this.imageHeight);
      let hr = (this.parent.width / this.imageWidth);
      let r = (vr > hr) ? vr : hr;
      let sw = this.imageWidth * r;
      let sh = this.imageHeight * r;
      var sx = (this.parent.width / 2) - (sw / 2);
      var sy = (this.parent.height / 2) - (sh / 2);

      if(UIManager.isMobile) {
        UIManager.ctx.save();
        UIManager.ctx.imageSmoothingEnabled = true;
        UIManager.ctx.imageSmoothingQuality = 'high';
      }

      UIManager.ctx.drawImage(this.cachedImage.image, this.getScreenX() + sx, this.getScreenY() + sy, sw, sh);
      
      if(UIManager.isMobile)
        UIManager.ctx.restore();
    }
    else
    {
      var sx = (this.width - this.imageWidth) / 2;
      var sy = (this.height - this.imageHeight) / 2;
      let dir = 1;
      UIManager.ctx.save();
      if(this.flip) {
        dir = -1;
        UIManager.ctx.scale(-1, 1);
        UIManager.ctx.translate(-this.imageWidth, 0);
      }
      UIManager.ctx.drawImage(this.cachedImage.image, (this.getScreenX() + sx) * dir, this.getScreenY() + sy, this.imageWidth, this.imageHeight);
      UIManager.ctx.restore();
    }

    if(this.radius > 0) {
      UIManager.ctx.restore();
    }

    // border
    if(this.borderWidth > 0) {
      sx = this.getScreenX();
      sy = this.getScreenY();
  
      var path = new Path2D();
      path.moveTo(sx + this.radius, sy);
      path.lineTo(sx + this.width - this.radius, sy);
      path.quadraticCurveTo(sx + this.width, sy, sx + this.width, sy + this.radius);
      path.lineTo(sx + this.width, sy + this.height - this.radius);
      path.quadraticCurveTo(sx + this.width, sy + this.height, sx + this.width - this.radius, sy + this.height);
      path.lineTo(sx + this.radius, sy + this.height);
      path.quadraticCurveTo(sx, sy + this.height, sx, sy + this.height - this.radius);
      path.lineTo(sx, sy + this.radius);
      path.quadraticCurveTo(sx, sy, sx + this.radius, sy);
      path.closePath();
  
      UIManager.ctx.lineWidth = this.borderWidth;
      UIManager.ctx.strokeStyle = this.borderColor;
      UIManager.ctx.stroke(path);
    }
  }

  public drawClipPath() {
    let sx = this.getScreenX();
    let sy = this.getScreenY();
    let sw = this.width;
    let sh = this.height;

    UIManager.ctx.beginPath();
    UIManager.ctx.moveTo(sx + this.radius, sy);
    UIManager.ctx.lineTo(sx + sw - this.radius, sy);
    UIManager.ctx.quadraticCurveTo(sx + sw, sy, sx + sw, sy + this.radius);
    UIManager.ctx.lineTo(sx + sw, sy + sh - this.radius);
    UIManager.ctx.quadraticCurveTo(sx + sw, sy + sh, sx + sw - this.radius, sy + sh);
    UIManager.ctx.lineTo(sx + this.radius, sy + sh);
    UIManager.ctx.quadraticCurveTo(sx, sy + sh, sx, sy + sh - this.radius);
    UIManager.ctx.lineTo(sx, sy + this.radius);
    UIManager.ctx.quadraticCurveTo(sx, sy, sx + this.radius, sy);
    UIManager.ctx.closePath();
  }

  protected drawSlice9() {
    // top left
    UIManager.ctx.drawImage(this.cachedImage.image,
      0, 0,
      this.slice.left, this.slice.top,
      this.getScreenX(), this.getScreenY(),
      this.slice.left, this.slice.top);

    // top right
    UIManager.ctx.drawImage(this.cachedImage.image,
      this.imageWidth - this.slice.right, 0,
      this.slice.right, this.slice.top,
      this.getScreenX() + this.width - this.slice.right, this.getScreenY(),
      this.slice.right, this.slice.top);

    // bottom left
    UIManager.ctx.drawImage(this.cachedImage.image,
      0, this.imageHeight - this.slice.bottom,
      this.slice.left, this.slice.bottom,
      this.getScreenX(), this.getScreenY() + this.height - this.slice.bottom,
      this.slice.left, this.slice.bottom);

    // bottom right
    UIManager.ctx.drawImage(this.cachedImage.image,
      this.imageWidth - this.slice.right, this.imageHeight - this.slice.bottom,
      this.slice.right, this.slice.bottom,
      this.getScreenX() + this.width - this.slice.right, this.getScreenY() + this.height - this.slice.bottom,
      this.slice.right, this.slice.bottom);

    // top center
    UIManager.ctx.drawImage(this.cachedImage.image,
      this.slice.left + 1, 0,
      2, this.slice.top,
      this.getScreenX() + this.slice.left, this.getScreenY(),
      this.width - this.slice.left - this.slice.right, this.slice.top);

    // bottom center
    UIManager.ctx.drawImage(this.cachedImage.image,
      this.slice.left + 1, this.imageHeight - this.slice.bottom,
      2, this.slice.bottom,
      this.getScreenX() + this.slice.left, this.getScreenY() + this.height - this.slice.bottom,
      this.width - this.slice.left - this.slice.right, this.slice.bottom);

    // left center
    UIManager.ctx.drawImage(this.cachedImage.image,
      0, this.slice.top + 1,
      this.slice.left, 2,
      this.getScreenX(), this.getScreenY() + this.slice.top,
      this.slice.left, this.height - this.slice.bottom - this.slice.top);

    // right center
    UIManager.ctx.drawImage(this.cachedImage.image,
      this.imageWidth - this.slice.right, this.slice.top + 1,
      this.slice.right, 2,
      this.getScreenX() + this.width - this.slice.right, this.getScreenY() + this.slice.top,
      this.slice.right, this.height - this.slice.bottom - this.slice.top);

    // middle
    UIManager.ctx.drawImage(this.cachedImage.image,
      this.slice.left + 1, this.slice.top + 1,
      2, 2,
      this.getScreenX() + this.slice.left, this.getScreenY() + this.slice.top,
      this.width - this.slice.left - this.slice.right, this.height - this.slice.bottom - this.slice.top);

  }

  public setFlip(b:boolean) {
    this.flip = b;
  }
}