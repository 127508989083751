import { UIDialog } from "../common/ui/uiDialog";
import { UIPanel } from "../common/ui/uiPanel";
import { UIPanelButton } from "../common/ui/uiPanelButton";
import { UIText } from "../common/ui/uiText";

export class PauseDialog extends UIDialog {
  protected quitButton: UIPanelButton;
  protected restartButton: UIPanelButton;
  multiplayer: any;

  public build() {
    var popup = new UIPanel({
      // size: {x: 340, y: 320},
      size: {x: 260, y: 320},
      anchor: {x: 0.5, y: 0.4},
      pivot: {x: 0.5, y: 0.5},
      position: {x: 0, y: 0},
      radius: this.options.panel.radius,
      color: this.options.panel.color,
      gradient: this.options.panel.gradient,
      borderColor: this.options.panel.borderColor,
      borderWidth: this.options.panel.borderWidth,
      shadow: this.options.panel.shadow,
      parent: this
    });

    let titleText = new UIText({
      text: 'PAUSED',
      position: {x: 0, y: 15},
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      horizontalAlign: 'center',
      color: this.options.title.color,
      fontFamily: this.options.title.fontFamily,
      fontSize: this.options.title.fontSize,
      fontWeight: this.options.title.fontWeight,
      shadow: this.options.title.shadow,
      parent: popup
    });

    let resumeButton = new UIPanelButton({
      text : 'Resume',
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      position : {x : 0, y : 70},
      size : {x : 160, y : 40},
      callback : () => {
        this.hide();
        this.callback('resume');
      },
      panelColors: this.options.button.panelColors,
      textColors: this.options.button.textColors,
      radius: this.options.button.radius,
      fontFamily: this.options.button.fontFamily,
      fontSize: this.options.button.fontSize,
      textOffset: this.options.button.textOffset,
      borderColor: this.options.button.borderColor,
      borderWidth: this.options.button.borderWidth,
      parent : popup
    });

    this.restartButton = new UIPanelButton({
      text : 'Restart',
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      position : {x : 0, y : 130},
      size : {x : 160, y : 40},
      callback : () => {
        this.hide();
        this.callback('restart');
      },
      panelColors: this.options.button.panelColors,
      textColors: this.options.button.textColors,
      radius: this.options.button.radius,
      fontFamily: this.options.button.fontFamily,
      fontSize: this.options.button.fontSize,
      textOffset: this.options.button.textOffset,
      borderColor: this.options.button.borderColor,
      borderWidth: this.options.button.borderWidth,
      parent : popup
    });

    let optionsButton = new UIPanelButton({
      text : 'Options',
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      position : {x : 0, y : 190},
      size : {x : 160, y : 40},
      callback : () => {
        this.hide();
        this.callback('options');
      },
      panelColors: this.options.button.panelColors,
      textColors: this.options.button.textColors,
      radius: this.options.button.radius,
      fontFamily: this.options.button.fontFamily,
      fontSize: this.options.button.fontSize,
      textOffset: this.options.button.textOffset,
      borderColor: this.options.button.borderColor,
      borderWidth: this.options.button.borderWidth,
      parent : popup
    });

    this.quitButton = new UIPanelButton({
      text : 'Quit',
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      position : {x : 0, y : 250},
      size : {x : 160, y : 40},
      callback : () => {
        this.hide();
        this.setQuitButtonText('Quit');
        this.callback('quit');
      },
      panelColors: this.options.button.panelColors,
      textColors: this.options.button.textColors,
      radius: this.options.button.radius,
      fontFamily: this.options.button.fontFamily,
      fontSize: this.options.button.fontSize,
      textOffset: this.options.button.textOffset,
      borderColor: this.options.button.borderColor,
      borderWidth: this.options.button.borderWidth,
      parent : popup
    });
  }

  public init(multiplayer:boolean) {
    this.multiplayer = multiplayer;
  }

  public onWake() {
    this.restartButton.disabled = this.multiplayer;
  }

  public setQuitButtonText(text:string) {
    this.quitButton.text = text;
  }
}