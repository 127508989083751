import React from 'react';
import { BsFillXCircleFill } from 'react-icons/bs';
import { Server } from '../../server/server';
import AlertModal from './AlertModal';
import MessageModal from './MessageModal';
import QuestionModal from './QuestionModal';
import './MediaViewModal.css'
import { numberWithCommas } from '../util/util';

interface MediaViewModalProps {
  open: boolean;
  data: any;
  onClose: Function;
}

interface MediaViewModalState {
  copyDone: boolean;
  message: string;
  question: string;
  alert: string;
  name: string;
  description: string;
}

class MediaViewModal extends React.Component<MediaViewModalProps, MediaViewModalState> {

  constructor(props: MediaViewModalProps) {
    super(props);
    let data = this.props.data;
    this.state = {
      copyDone: false,
      message: '',
      question: '',
      alert: '',
      name: data.name,
      description: data.description,
    }

    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onQuestionYes = this.onQuestionYes.bind(this);
    this.onQuestionNo = this.onQuestionNo.bind(this);
    this.onAlertClose = this.onAlertClose.bind(this);
  }

  componentDidMount() {
    this.setState({name: this.props.data.name});
  }

  onNameChange(e: any): void {
    this.setState({ name: e.currentTarget.value });
  };

  onDescriptionChange(e: any): void {
    this.setState({ description: e.currentTarget.value });
  };

  onOpenDelete() {
    this.setState({question: 'Are you sure you want to delete this file?'});
  }

  onQuestionYes() {
    this.setState({question: '', message: `Deleting file...`});

    setTimeout(async () => {
      let response = await Server.media.deleteFile(this.props.data.id);

      if (response.success) {
        this.setState({message: ''});
        this.onAlertClose();
      }
      else
        this.setState({message: '', alert: 'Failed to delete file!'})
    }, 500);
  }

  onQuestionNo() {
    this.setState({question: ''})
  }

  onAlertClose() {
    this.setState({alert: ''});
    this.props.onClose(true);
  }

  onSave() {
    if (!this.state.name.trim()) {
      this.setState({alert: 'Name is empty.'});
      return;
    }

    this.setState({message: `Updating media file...`});

    setTimeout(async () => {
      let params = {
        id: this.props.data.id,
        name: this.state.name,
        description: this.state.description,
      }

      let response = await Server.media.updateFile(params);

      if (response.success)
        this.setState({ message: '', alert: `Media file updated!` });
      else
        this.setState({message: '', alert: response.message})
    }, 500);
  }

  async copyUrl() {
    let url  = Server.media.getFileUrl(this.props.data);
    await navigator.clipboard.writeText(url);
    this.setState({ copyDone: true });
    setTimeout(() => {
      this.setState({ copyDone: false });
    }, 1000);
  }

  ModalContent = () => {
    let data = this.props.data;
    if (!data) return;
    
    let d    = new Date(data.date);
    let date = d.toLocaleString(undefined, {month: "short", day: "numeric", hour: "numeric", minute: "numeric"});
    let url  = Server.media.getFileUrl(data);

    return (
      <div className='media-view-modal-view'>
        <div className='media-view-modal-media-container'>
          {data.metadata.type.indexOf('image') == 0
            ? <img className='media-view-modal-media' src={url} />
            : data.metadata.type.indexOf('video') == 0
              ? <video className='media-view-modal-media' controls>
                  <source src={url} type="video/mp4" />
                </video>
              : <audio className='media-view-modal-media' src={url} controls />
          }
        </div>

        <div>
          <div>Name</div>
          <input
            className="media-view-modal-input"
            placeholder="Name"
            value={this.state.name}
            onChange={this.onNameChange}
          />

          <div>Description</div>
          <textarea
            className="media-view-modal-textarea"
            placeholder="Enter a description"
            value={this.state.description}
            onChange={this.onDescriptionChange}
          />

          {/* <div className='media-view-modal-title'>URL</div>
          <div className='media-view-modal-url'>
            {this.state.copyDone
              ? <BsCheck2 className='media-view-modal-copy' />
              : <BsFiles className='media-view-modal-copy' onClick={() => this.copyUrl()} />
            }
            {data.url}
          </div> */}

          {/* <div className='media-view-modal-title'>FILE NAME</div>
          <div className='media-view-modal-text'>{data.name}.{data.metadata.ext}</div> */}

          <div className='media-view-modal-row'>
            <div style={{width: '170px'}}>
              <div className='media-view-modal-title'>FILE TYPE</div>
              <div className='media-view-modal-text'>{data.metadata.type}</div>
            </div>
            <div>
              <div className='media-view-modal-title'>FILE SIZE (bytes)</div>
              <div className='media-view-modal-text'>{numberWithCommas(data.metadata.size)}</div>
            </div>
          </div>

          <div className='media-view-modal-row'>
            <div style={{width: '170px'}}>
              {data.metadata.type.indexOf('image') == 0
                ? <div>
                    <div className='media-view-modal-title'>DIMENSIONS</div>
                    <div className='media-view-modal-text'>{data.metadata.width} x {data.metadata.height}</div>
                  </div>
                : <div>
                    <div className='media-view-modal-title'>DURATION (Seconds)</div>
                    <div className='media-view-modal-text'>{data.metadata.duration}</div>
                  </div>
              }
            </div>
            <div>
              <div className='media-view-modal-title'>UPLOAD DATE</div>
              <div className='media-view-modal-text'>{date}</div>
            </div>
          </div>

          <div className='media-view-modal-action'>
            <button className="media-view-modal-button red" onClick={() => this.onOpenDelete()}>Delete</button>
            <button className="media-view-modal-button" onClick={() => this.onSave()}>Save</button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if(!this.props.open)
      return (<div></div>);

    if(this.state.question != '') 
      return (<QuestionModal message={this.state.question} onYes={this.onQuestionYes} onNo={this.onQuestionNo} />);

    if(this.state.alert != '') 
      return (<AlertModal message={this.state.alert} button="OK" onClose={this.onAlertClose}/>);

    if(this.state.message != '') 
      return (<MessageModal message={this.state.message} />);

    return (
      <div className="modal open">
        <div className="modal-content media-view-modal-content">
          <button className="modal-close-button" onClick={()=>this.props.onClose()}>
            <BsFillXCircleFill />
          </button>
          <div className="media-view-modal-header">Media View</div>
          <this.ModalContent />
        </div>
      </div>
    );
  }
}

export default MediaViewModal;