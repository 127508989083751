import { UIDialog } from '../common/ui/uiDialog';
import { UIText } from '../common/ui/uiText';
import { getTimeString, numberWithCommas } from '../common/util';
import { GameStyle } from './gameStyle';
// import { Site } from 'src/site/site';
import { Game, GameMode } from './game';
import { UIPanel } from '../common/ui/uiPanel';
import { UIPanelButton } from '../common/ui/uiPanelButton';
import { UIGrid } from '../common/ui/uiGrid';
import { TournamentType } from './solitaire';

export class GameOverDialog extends UIDialog {
  protected scoreText: UIText;
  protected lifetimeScoreText: UIText;
  protected continueButton: UIPanelButton;
  protected quitButton: UIPanelButton;
  protected playAgainButton: UIPanelButton;
  protected baseScoreText: UIText;
  protected winBonusText: UIText;
  protected timeBonusLabel: UIText;
  protected timeBonusText: UIText;
  protected totalScoreText: UIText;
  protected emptyAwardsText: UIText;
  protected awardsGrid: UIGrid;
  protected runningScoreText: UIText;
  protected winsLossesText: UIText;
  protected titleText: UIText;
  protected endButton: UIPanelButton;
  protected runningScoreLabel: UIText;
  protected winsLossesLabel: UIText;

  public build() {
    this.popup = new UIPanel({
      size: {x: 340, y: 405},
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      position: {x: 0, y: 80},
      radius: GameStyle.dialog.panel.radius,
      color: GameStyle.dialog.panel.color,
      gradient: GameStyle.dialog.panel.gradient,
      borderColor: GameStyle.dialog.panel.borderColor,
      borderWidth: GameStyle.dialog.panel.borderWidth,
      shadow: GameStyle.dialog.panel.shadow,
      parent: this
    });

    this.titleText = new UIText({
      text: 'GAME OVER!',
      position: {x: 0, y: 12},
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      horizontalAlign: 'center',
      color: GameStyle.dialog.title.color,
      fontFamily: GameStyle.dialog.title.fontFamily,
      fontSize: GameStyle.dialog.title.fontSize,
      fontWeight: GameStyle.dialog.title.fontWeight,
      shadow: GameStyle.dialog.title.shadow,
      parent: this.popup
    });

    this.buildScoreDetailPanel();
    this.buildRunningScorePanel();
    this.buildAwardsPanel();

    this.continueButton = new UIPanelButton({
      text : 'Continue',
      anchor : {x : 0.5, y : 1},
      pivot : {x : 0.5, y : 1},
      position : {x : 0, y : -20},
      size : {x : 120, y : 40},
      callback : () => {
        this.onContinue();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      textOffset: GameStyle.dialog.button.textOffset,
      borderColor: GameStyle.dialog.button.borderColor,
      borderWidth: GameStyle.dialog.button.borderWidth,
      parent : this.popup
    });

    this.endButton = new UIPanelButton({
      text : 'End',
      anchor : {x : 0, y : 1},
      pivot : {x : 0, y : 1},
      size : {x : 120, y : 40},
      position : {x : 20, y : -20},
      callback : () => {
        this.onEnd();
      },
      visible : false,
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      textOffset: GameStyle.dialog.button.textOffset,
      borderColor: GameStyle.dialog.button.borderColor,
      borderWidth: GameStyle.dialog.button.borderWidth,
      parent : this.popup
    });
  }

  protected buildScoreDetailPanel() {
    let scoreDetailPanel = new UIPanel({
      fitParent: {left: 15, right: 15, top: -1, bottom: -1},
      position : {x : 0, y : 50},
      size : {x : 0, y : 115},
      color : GameStyle.dialog.field.panel.color,
      radius : GameStyle.dialog.field.panel.radius,
      parent : this.popup
    });

    let baseScoreLabel = new UIText({
      text : 'Base Score',
      position : {x : 10, y : 10},
      color : GameStyle.dialog.message.color,
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize-2,
      parent : scoreDetailPanel
    });

    this.baseScoreText = new UIText({
      text : '12,345',
      position : {x : -10, y : 10},
      anchor : {x : 1, y : 0},
      pivot : {x : 1, y : 0},
      horizontalAlign : 'right',
      color : GameStyle.dialog.message.color,
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize-2,
      parent : scoreDetailPanel
    });

    let winBonusLabel = new UIText({
      text : 'Win Bonus',
      position : {x : 10, y : 35},
      color : GameStyle.dialog.message.color,
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize-2,
      parent : scoreDetailPanel
    });

    this.winBonusText = new UIText({
      text : '+1,000',
      position : {x : -10, y : 35},
      anchor : {x : 1, y : 0},
      pivot : {x : 1, y : 0},
      horizontalAlign : 'right',
      color : GameStyle.dialog.message.color,
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize-2,
      parent : scoreDetailPanel
    });

    this.timeBonusLabel = new UIText({
      text : 'Time Bonus',
      position : {x : 10, y : 60},
      color : GameStyle.dialog.message.color,
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize-2,
      parent : scoreDetailPanel
    });

    this.timeBonusText = new UIText({
      text : '+658',
      position : {x : -10, y : 60},
      anchor : {x : 1, y : 0},
      pivot : {x : 1, y : 0},
      horizontalAlign : 'right',
      color : GameStyle.dialog.message.color,
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize-2,
      parent : scoreDetailPanel
    });

    let totalScoreLabel = new UIText({
      text : 'Game Score',
      position : {x : 10, y : 85},
      color : GameStyle.dialog.message.color,
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize,
      parent : scoreDetailPanel
    });

    this.totalScoreText = new UIText({
      text : '17,900',
      position : {x : -10, y : 85},
      anchor : {x : 1, y : 0},
      pivot : {x : 1, y : 0},
      horizontalAlign : 'right',
      color : GameStyle.dialog.message.color,
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize,
      parent : scoreDetailPanel
    });
  }

  protected buildAwardsPanel() {
    let awardsLabel = new UIText({
      text : 'AWARDS',
      position : {x : 0, y : 230},
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      color : GameStyle.dialog.message.color,
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize,
      parent : this.popup
    });

    let awardsPanel = new UIPanel({
      fitParent: {left: 15, right: 15, top: -1, bottom: -1},
      position : {x : 0, y : 255},
      size : {x : 0, y : 70},
      color : GameStyle.dialog.field.panel.color,
      radius : GameStyle.dialog.field.panel.radius,
      parent : this.popup
    });

    this.emptyAwardsText = new UIText({
      text : 'Earn awards for skillful play.',
      anchor : {x : 0.5, y : 0.5},
      pivot : {x : 0.5, y : 0.5},
      color : GameStyle.dialog.message.color + 'a0',
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize-2,
      parent : awardsPanel
    });

    this.awardsGrid = new UIGrid({
      columns : 1,
      rows : 1,
      size : {x : 100, y : 60},
      position : {x : 0, y : 5},
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      spacing : {x : 10, y : 0},
      autoSizeChildren : true,
      parent : awardsPanel
    });
  }

  protected buildRunningScorePanel() {
    let runningScorePanel = new UIPanel({
      fitParent: {left: 15, right: 15, top: -1, bottom: -1},
      position : {x : 0, y : 175},
      size : {x : 0, y : 40},
      color : GameStyle.dialog.field.panel.color,
      radius : GameStyle.dialog.field.panel.radius,
      parent : this.popup
    });

    this.runningScoreLabel = new UIText({
      text : 'Running Score',
      position : {x : 10, y : 8},
      color : GameStyle.dialog.message.color,
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize,
      parent : runningScorePanel
    });

    this.runningScoreText = new UIText({
      text : '',
      position : {x : -10, y : 8},
      anchor : {x : 1, y : 0},
      pivot : {x : 1, y : 0},
      horizontalAlign : 'right',
      color : GameStyle.dialog.message.color,
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize,
      parent : runningScorePanel
    });

    this.winsLossesLabel = new UIText({
      text : 'Games',
      position : {x : 10, y : 36},
      color : GameStyle.dialog.message.color,
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize,
      visible: false,
      parent : runningScorePanel
    });

    this.winsLossesText = new UIText({
      text : '',
      position : {x : -10, y : 36},
      anchor : {x : 1, y : 0},
      pivot : {x : 1, y : 0},
      horizontalAlign : 'right',
      color : GameStyle.dialog.message.color,
      fontFamily : GameStyle.dialog.message.fontFamily,
      fontSize : GameStyle.dialog.message.fontSize,
      visible: false,
      parent : runningScorePanel
    });
  }

  protected buildAwards(cheeves: string[]) {
    this.awardsGrid.deleteAllChildren();

    this.emptyAwardsText.visible = (cheeves.length == 0);

    if (cheeves.length == 0)
      return;

    this.awardsGrid.columns = cheeves.length;
    this.awardsGrid.size.x =
        (this.awardsGrid.columns * 60) + ((this.awardsGrid.columns - 1) * this.awardsGrid.spacing.x);

    for (let i = 0; i < cheeves.length; i++) {
      let panel = new UIPanel({color : '#dddd00', radius : 32, parent : this.awardsGrid});

      let text = new UIText({
        text : cheeves[i],
        color : '#000000',
        fontFamily : 'neue-haas-grotesk-text',
        fontSize : 10,
        fontWeight : 'bold',
        horizontalAlign : 'center',
        anchor : {x : 0.5, y : 0.5},
        pivot : {x : 0.5, y : 0.5},
        parent : panel
      });
    }
  }

  public onWake() {
    let solitaire = Game.instance.solitaire;

    let wonGame = solitaire.getWonGame();
    let timeExpired = solitaire.getTournamentTimeExpired();

    if (solitaire.playingTournament /*&& Game.instance.gameMode !== GameMode.Arcade*/) {
      let count = solitaire.tournamentGamesPlayed;
      if (solitaire.tournamentTimeExpired)
        count++;
      this.titleText.text = 'GAME ' + count;
      if (solitaire.tournamentType === TournamentType.Games)
        this.titleText.text += ' of ' + solitaire.tournamentGamesToPlay;

      if (wonGame)
        this.titleText.text += ' - WIN!';
      else if (timeExpired)
        this.titleText.text += ' - EXPIRED';
      else
        this.titleText.text += ' - LOST';
    }
    else {
      if (wonGame)
        this.titleText.text = 'GAME WON!';
      else if (timeExpired)
        this.titleText.text = 'GAME EXPIRED';
      else
        this.titleText.text = 'GAME LOST';
    }

    let wins = solitaire.getTotalGamesWon();
    let losses = solitaire.getTotalGamesLost();

    this.timeBonusLabel.text = 'Time Bonus';

    this.baseScoreText.text = numberWithCommas(solitaire.baseScore);
    this.winBonusText.text = '+' + numberWithCommas(solitaire.wonGameBonus);
    this.timeBonusText.text = '+' + numberWithCommas(solitaire.timeBonus);
    this.totalScoreText.text = numberWithCommas(solitaire.totalScore);
    this.runningScoreLabel.text = 'Running Score';
    this.runningScoreText.text = numberWithCommas(solitaire.tournamentTotalScore);
    this.winsLossesLabel.text = `Games`;
    this.winsLossesText.text = `Won ${numberWithCommas(wins)}, Lost ${numberWithCommas(losses)}`;

    if (solitaire.timeBonus > 0) 
      this.timeBonusLabel.text = `Time Bonus (${getTimeString(solitaire.gameTime)})`;

    // end button
    let showEndButton = false;

    if (solitaire.playingTournament && !solitaire.getTournamentTimeExpired()) {
      if (solitaire.tournamentType === TournamentType.Timed)
        showEndButton = true;
      else if (solitaire.tournamentType === TournamentType.Games) {
        let count = solitaire.tournamentGamesPlayed;
        if (solitaire.tournamentTimeExpired)
          count++;
        showEndButton = (count < solitaire.tournamentGamesToPlay);
      }
    }

    if (showEndButton) {
      this.continueButton.anchor.x = 1;
      this.continueButton.pivot.x = 1;
      this.continueButton.position.x = -20;
      this.endButton.visible = true;
    }
    else {
      this.continueButton.anchor.x = 0.5;
      this.continueButton.pivot.x = 0.5;
      this.continueButton.position.x = 0;
      this.endButton.visible = false;
    }

    // This gets bonus points from awards won - and also the text to pass into buildAwards
    let cheeves = solitaire.getAwardsInfo();
    this.buildAwards(cheeves);

    if (solitaire.getAwardBazillion()) {
      Game.instance.showAlert(
          `Congratulations you have helped\nus find another Winning Deck!\n\n Only 8.0658175170944E+67 - 1 to go!`);
    }
  }

  protected handleTournamentEnded(): boolean {
    if (Game.instance.solitaire.playingTournament) {
      if (Game.instance.solitaire.isTournamentOver()) {
        return true;
      }

      return false;
    }

    return Game.instance.solitaire.playingTournament;
  }

  protected onContinue() {
    this.hide();
    Game.instance.started = true;

    if (Game.instance.solitaire.playingTournament && Game.instance.solitaire.isTournamentOver()) {
      let score = Game.instance.solitaire.tournamentTotalScore;
      Game.instance.showGameOver(score, score, (action:string)=>{
        if(action == 'restart') 
          Game.instance.arcadeMode();
      });
    }
    else if (Game.instance.solitaire.playingTournament)
      Game.instance.launchNextTournamentGame();
    else
      Game.instance.quit();
  }

  protected onEnd() {
    Game.instance.askQuestion('End the tournament?', (response:string)=>{
      if(response == 'Yes') {
        this.hide();
        Game.instance.quit();
      }
    });
  }
}