import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import { Server } from '../server/server';
import { Database } from './util/database';
import HomePage from './pages/HomePage';
import SitePage from './pages/SitePage';
import GamesPage from './pages/GamesPage';
import AccountPage from './pages/AccountPage';
import GamePage from './pages/GamePage';
import HelpPage from './pages/HelpPage';
import PlayGamePage from './pages/PlayGamePage';
import ProfilePage from './pages/ProfilePage';
import NotFoundPage from './pages/NotFoundPage';
import SplashPage from './pages/SplashPage';
import SecretPasswordPage from './pages/SecretPasswordPage';
import withRouter from './util/withRouter';
import UsersPage from './pages/UsersPage';
import NotificationsPage from './pages/NotificationsPage';
import FriendsPage from './pages/FriendsPage';
import InventoryPage from './pages/InventoryPage';
import ShopPage from './pages/ShopPage';
import ProfileAssetsPage from './pages/ProfileAssetsPage';
import ChatPage from './pages/ChatPage';
import MediaPage from './pages/MediaPage';
import ActivityPage from './pages/ActivityPage';
import ActivityPostPage from './pages/ActivityPostPage';
import PlacePage from './pages/PlacePage';
import PlacesPage from './pages/PlacesPage';
import MaintenancePage from './pages/MaintenancePage';
import ForumsPage from './pages/ForumsPage';
import EditForumPage from './pages/EditForumPage';
import ForumPage from './pages/ForumPage';
import ForumContentPage from './pages/ForumContentPage';
import ForumTopicPage from './pages/ForumTopicPage';
import './App.css';
import BlogsPage from './pages/BlogsPage';
import BlogPage from './pages/BlogPage';
import EditBlogPage from './pages/EditBlogPage';
import InvitePage from './pages/InvitePage';
import AdminPage from './pages/AdminPage';
import AdminBetaPage from './pages/AdminBetaPage';
import AdminToolsPage from './pages/AdminToolsPage';
import AdminStatsPage from './pages/AdminStatsPage';

const ProfilePageWithRouter = withRouter(ProfilePage);
const UsersPageWithRouter = withRouter(UsersPage);
const ProfileAssetsPageWithRouter = withRouter(ProfileAssetsPage);
const PlacePageWithRouter = withRouter(PlacePage);
const GamePageWithRouter = withRouter(GamePage);

interface AppState {
  initialized: boolean;
  maintenance: boolean;
};

class App extends React.Component<{}, AppState> {
  constructor(props = {}) {
    super(props);

    this.state = {
      initialized: false,
      maintenance: false
    }

    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    this.init();
    document.addEventListener('keydown', this.onKeyDown);
    if(!Server.hasAutoLogin())
      this.setState({initialized: true});
  }

  init() {
    Database.init();
    
    window.addEventListener("beforeunload", function (e) {
      Server.network.disconnect();
    });

    Server.account.addEventListener('login', ()=>{
      this.forceUpdate();
    });

    Server.account.addEventListener('logout', ()=>{
      this.forceUpdate();
      // this.setInitialized(false)
    });
  }

  setInitialized(i:boolean) {
    this.setState({initialized: i})
  }

  isSecretPasswordValid() {
    let sp = window.localStorage.getItem('SecretPassword');
    return (sp && sp.toLowerCase() == 'itchy');
  }

  async onKeyDown(event: KeyboardEvent) {
    if(this.state.maintenance && event.ctrlKey && event.key == '`') 
      this.setState({maintenance: false});
    
    // if(event.key == '`') 
    //   Server.network.abnormalDisconnect();
  }

  render() {
    if(this.state.maintenance) 
      return (<MaintenancePage />);

    // if(!this.isSecretPasswordValid()) 
    //   return (<SecretPasswordPage />);

    if(!this.state.initialized) 
      return (<SplashPage onInit={()=>this.setInitialized(true)}/>);

    if(!Server.account.isLoggedIn()) {
      return (
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<SitePage />}>
              <Route index element={<HomePage />} />
              <Route path='/invite' element={<InvitePage />} />
              {/* <Route path='/play/:id' element={<PlayGamePage />} /> */}
              <Route path="*" element={<HomePage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      );
    }
    
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<SitePage />}>
            <Route index element={<HomePage />} />
            <Route path='/games' element={<GamesPage />} />
            <Route path='/game/:id' element={<GamePageWithRouter />} />
            <Route path='/play/:id' element={<PlayGamePage />} />
            <Route path='/help/:id' element={<HelpPage />} />
            <Route path='/profile' element={<ProfilePageWithRouter />} />
            <Route path='/profile/:id' element={<ProfilePageWithRouter />} />
            <Route path='/profile/:id/followers' element={<UsersPageWithRouter />} />
            <Route path='/profile/:id/following' element={<UsersPageWithRouter />} />
            <Route path='/profile/banners' element={<ProfileAssetsPageWithRouter />} />
            <Route path='/profile/portraits' element={<ProfileAssetsPageWithRouter />} />
            <Route path='/notifications' element={<NotificationsPage />} />
            <Route path='/friends' element={<FriendsPage />} />
            <Route path='/account' element={<AccountPage />} />
            <Route path='/shop' element={<ShopPage />} />
            <Route path='/inventory' element={<InventoryPage />} />
            <Route path='/chat' element={<ChatPage />} />
            <Route path='/activity' element={<ActivityPage />} />
            <Route path='/activity/post/:id' element={<ActivityPostPage />} />
            <Route path='/media' element={<MediaPage />} />
            <Route path='/places' element={<PlacesPage />} />
            <Route path='/place/:id' element={<PlacePageWithRouter />} />
            <Route path='/place/:id/banners' element={<ProfileAssetsPageWithRouter />} />
            <Route path='/forums' element={<ForumsPage />} />
            <Route path='/forum/create' element={<EditForumPage />} />
            <Route path='/forum/:fid' element={<ForumPage />} />
            <Route path='/forum/:fid/edit' element={<EditForumPage />} />
            <Route path='/forum/:fid/new' element={<ForumContentPage />} />
            <Route path='/forum/:fid/:tid' element={<ForumTopicPage />} />
            <Route path='/forum/:fid/:tid/edit' element={<ForumContentPage />} />
            <Route path='/forum/:fid/:tid/new' element={<ForumContentPage />} />
            <Route path='/forum/:fid/:tid/:pid/edit' element={<ForumContentPage />} />
            <Route path='/blogs' element={<BlogsPage />} />
            <Route path='/blog/:id' element={<BlogPage />} />
            <Route path='/blog/new' element={<EditBlogPage />} />
            <Route path='/blog/edit/:id' element={<EditBlogPage />} />
            <Route path='/invite' element={<InvitePage />} />
            <Route path='/admin' element={<AdminPage />} />
            <Route path='/admin/beta' element={<AdminBetaPage />} />
            <Route path='/admin/stats' element={<AdminStatsPage />} />
            <Route path='/admin/tools' element={<AdminToolsPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
