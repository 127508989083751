import React from 'react';
import { Navigate } from 'react-router-dom';
import { Server } from '../../server/server';
import MessageModal from '../modals/MessageModal';
import AlertModal from '../modals/AlertModal';
import BadWords from '../util/badWords';
import SharedQuillEditor from '../elements/SharedQuillEditor';
import PageEnvelope from '../elements/PageEnvelope';
import PageHeader from '../elements/PageHeader';
import QuestionModal from '../modals/QuestionModal';
import './SitePage.css'

interface ForumContentPageState {
  loading: boolean,
  navigate: string;
  forum: any;
  topic: any;
  title: string;
  message: string;
  alert: string;
  mode: string;
  question: string;
  onQuestionYes: Function;
}

class ForumContentPage extends React.Component<{}, ForumContentPageState> {
  quillRef: any;
  path: any;

  constructor(props: {}) {
    super(props);
    this.state = {
      loading: false,
      navigate: '',
      forum: null,
      topic: null,
      title: '',
      message: '',
      alert: '',
      mode: '',
      question: '',
      onQuestionYes: null
    };

    this.path = null;
  }

  componentDidMount() { 
    this.parsePath();
    if(Server.account.isLoggedIn())
      this.load();
  }

  parsePath() {
    let parts = window.location.pathname.split('/');

    let forum = parts[2];
    let topic = '';
    let post = '';
    let mode = '';

    if(parts[3] == 'new')
      mode = 'new-topic';
    else if(parts[4] == 'edit') {
      mode = 'edit-topic';
      topic = parts[3];
    }
    else if(parts[4] == 'new') {
      mode = 'new-post';
      topic = parts[3];
    }
    else if(parts[5] == 'edit') {
      mode = 'edit-post';
      topic = parts[3];
      let d = new Date(+parts[4]);
      post = d.toISOString();
    }

    this.path = {forum, topic, post, mode};
  }

  async loadForum() {
    let response = await Server.forums.getForum(this.path.forum);
    if(response.success)
      return response.forum;
    return null;
  }

  async loadTopic() {
    let response = await Server.forums.getTopic(this.path.forum, this.path.topic);
    if(response.success)
      return response.topic;
    return null;
  }

  async loadPost(date:string) {
    let response = await Server.forums.getPost(this.path.forum, this.path.topic, date);
    if(response.success)
      return response.post;
    return null;
  }

  async load() {
    let post = null;
    let title = '';
    let content = '';
    let topic = null;

    let forum = await this.loadForum();

    if(this.path.mode == 'edit-topic') {
      this.setState({loading: true});
      topic = await this.loadTopic();
      title = topic.title;      
      post = await this.loadPost(topic.date);
    }

    if(this.path.mode == 'edit-post') {
      this.setState({loading: true});
      post = await this.loadPost(this.path.post);
    }

    if(post) {
      content = decodeURIComponent(post.content);
      setTimeout(() => {
        this.quillRef.root.innerHTML = content;
      }, 100);
    }

    this.setState({loading: false, forum, topic, title, mode: this.path.mode});
  }

  nextPage() {
    if(this.state.loading)
      return;

    let page = 'restore';
    if(this.path.mode == 'new-post')
      page = 'last';
    else if(this.path.mode == 'new-topic')
      page = 'first';

    window.sessionStorage.setItem('ForumTopicPageParams', page)

    if(this.path.mode == 'new-topic')
      this.setState({navigate: `/forum/${this.path.forum}`});
    else
      this.setState({navigate: `/forum/${this.path.forum}/${this.path.topic}`});
  }

  onSave() {
    let quillText    = this.quillRef.getText().trim();
    let quillContent = this.quillRef.root.innerHTML;
    let lines = this.quillRef.getLines();

    if((this.path.mode == 'new-topic' || this.path.mode == 'edit-topic') && this.state.title == '') {
      this.setState({alert: 'Title can not be empty.'});
      return;
    }

    if(this.path.mode != 'edit-topic' && quillText == '' && lines.length == 1) {
      this.setState({alert: 'Content can not be empty.'});
      return;
    }

    if(quillText.length > 2048) {
      this.setState({alert: 'Posts are limited to 2048 characters.  You currently have ' + quillText.length + '.  Please reduce the post length.'});
      return;
    }

    if(BadWords.isBad(this.state.title)) {
      this.setState({alert: 'No bad words allowed in title.'});
      return;
    }

    // remove blank lines at the end
    if(quillContent.substring(quillContent.length-11) == '<p><br></p>') 
      quillContent = quillContent.substring(0, quillContent.length-11);

    this.setState({message: 'Saving...'});

    setTimeout(async () => {
      let content = encodeURIComponent(quillContent);
      let notify = (this.state.forum.title != 'Announcements');

      let response = null;
      if(this.path.mode == 'new-post')
        response = await Server.forums.createPost(this.path.forum, this.path.topic, content);
      else if(this.path.mode == 'edit-post')
        response = await Server.forums.editPost(this.path.forum, this.path.topic, this.path.post, content);
      else if(this.path.mode == 'new-topic') 
        response = await Server.forums.createTopic(this.path.forum, this.state.title, content, notify);
      else if(this.path.mode == 'edit-topic') {
        response = await Server.forums.editTopic(this.path.forum, this.path.topic, this.state.title);
        if(!response.success) {
          this.setState({message: '', alert: response.message});
          return;
        }
        response = await Server.forums.editPost(this.path.forum, this.path.topic, this.state.topic.date, content);
      }

      if(response.success) {
        if(this.path.mode == 'new-topic')
          Server.user.incrementCounter('forums#post');
        else if(this.path.mode == 'new-post')
          Server.user.incrementCounter('forums#reply');
        this.setState({message: ''});
        this.quillRef.setText('');
        this.nextPage();
      }
      else
        this.setState({message: '', alert: response.message});
    }, 500);
  }

  onArchiveRestore(archive:boolean) {
    this.setState({message: archive ? 'Archiving...' : 'Restoring...'});

    setTimeout(async () => {
      let response = null;
      if(archive)
        response = await Server.forums.archiveTopic(this.path.forum, this.path.topic);
      else
        response = await Server.forums.restoreTopic(this.path.forum, this.path.topic);

      if(response.success) {
        let topic = {...this.state.topic};
        topic.archived = archive;
        this.setState({message: '', topic});
      }
      else
        this.setState({message: '', alert: response.message});
    }, 500);

  }

  onDeletePost() {
    this.setState({
      question: 'Are you sure you want to delete this post?', 
      onQuestionYes: ()=>this.onDeletePostYes()
    });
  }

  onDeletePostYes() {
    this.setState({question: '', message: 'Deleting post...'});

    setTimeout(async () => {
      let response = await Server.forums.deletePost(this.path.forum, this.path.topic, this.path.post);

      if(response.success) 
        this.setState({message: '', navigate: `/forum/${this.path.forum}/${this.path.topic}`})
      else 
        this.setState({message: '', alert: response.message})
    }, 500);
  }

  render() {
    if(this.state.navigate != '') 
      return <Navigate to={this.state.navigate} />;

    if(!Server.account.isLoggedIn())
      return <Navigate to="/forums" />

    let modeParts = this.state.mode.split('-');

    let header = 'Loading...';
    if(!this.state.loading) {
      header = (modeParts[0] == 'edit') ? 'Edit' : 'New';
      header += (modeParts[1] == 'topic') ? ' Topic' : ' Post';
    }

    let forumArchived = (this.state.forum && this.state.forum.archived);
    let blockSave = (this.state.topic && this.state.topic.archived) || (forumArchived)

    let actionButton = <div>&nbsp;</div>;
    if(this.state.mode == 'edit-topic' && !this.state.topic.archived)
      actionButton = <button disabled={forumArchived} onClick={()=>this.onArchiveRestore(true)}>Archive</button>
    else if(this.state.mode == 'edit-topic' && this.state.topic.archived)
      actionButton = <button disabled={forumArchived} onClick={()=>this.onArchiveRestore(false)}>Restore</button>
    else if(this.state.mode == 'edit-post')
      actionButton = <button disabled={forumArchived} onClick={()=>this.onDeletePost()}>Delete</button>

    let backNavigate = this.path ? `/forum/${this.path.forum}/${this.path.topic}` : '/forums';

    return (
      <div className="site-page">
        <PageEnvelope width="600px">
          <div className="site-page-column">

            <PageHeader text={header} navigate={backNavigate} />

            {this.state.forum && this.state.forum.archived &&
              <div className="site-page-header" style={{justifyContent: 'center', backgroundColor: 'var(--notification-background-color)'}}>
                This forum has been archived.
              </div>
            }

            {this.state.topic && this.state.topic.archived &&
              <div className="site-page-header" style={{justifyContent: 'center', backgroundColor: 'var(--notification-background-color)'}}>
                This topic has been archived.
              </div>
            }

            {this.state.mode.indexOf('-topic') != -1 &&
              <div className="site-page-panel">
                <div>Title:</div>
                <input 
                  type="text" 
                  maxLength={100} 
                  value={this.state.title} 
                  onChange={(e)=>this.setState({title: e.currentTarget.value})} 
                />
              </div>
            }

            <SharedQuillEditor getRef={(ref: any) => {this.quillRef = ref}} />

            <div className="site-page-row" style={{justifyContent: 'space-between'}}>
              {actionButton}
              <button disabled={blockSave} onClick={()=>this.onSave()}>Save</button>
            </div>
          </div>
        </PageEnvelope>

        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
        <QuestionModal message={this.state.question} onYes={this.state.onQuestionYes} onNo={()=>this.setState({question:''})} />
      </div>
    );
  }
}

export default ForumContentPage;