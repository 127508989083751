import { UIManager } from "./uiManager";
import { UIElement } from "./uiElement";
import { UIElementOptions } from "./uiElement";
import { UICanvas } from "./uiCanvas";

export interface UIScrollBarOptions extends UIElementOptions {
  color?: string;
  thumbColor?: string
  radius?: number;
  element: UIElement;
}

export class UIScrollBar extends UIElement
{
  public color: string;
  public thumbColor: string;
  public radius: number;
  public element: UIElement;

  protected thumbSize: number;
  protected thumbDragging:boolean;
  protected thumbDragY:number;

  constructor(options: UIScrollBarOptions)
  {
    super(options);

    this.color = '#00000000';
    this.thumbColor = '#00000000';
    this.radius = 0;
    this.thumbSize = 50;
    this.mouseEvents = true;
    this.thumbDragging = false;
    this.thumbDragY = 0;

    if(options.color)
      this.color = options.color;

    if(options.thumbColor)
      this.thumbColor = options.thumbColor;

    if(options.radius)
      this.radius = options.radius;

    this.element = options.element;
  }

  protected getScrollRatio():number {
    var scrollRatio =  (this.element.height / this.element.getChild(0).height);
    if(scrollRatio > 1)
      scrollRatio = 1;
    return scrollRatio;
  }

  public onMouseDown(x:number, y: number): void {
    super.onMouseDown(x, y);

    var scrollRatio =  this.getScrollRatio();

    if(scrollRatio == 1)
      return;

    var thumbHeight = scrollRatio * (this.height);
    var thumbY = this.getScreenY() - (this.element.scrollPosition.y * scrollRatio);

    if(y >= thumbY && y < thumbY + thumbHeight) {
      this.thumbDragging = true;
      this.thumbDragY = y;
      UICanvas.instance.lockMouse(this);
    }
    else {
      var ratio = (y - this.getScreenY()) / this.height;
      this.element.scrollTo(0, ratio);
    }
  }

  public onMouseMove(x:number, y: number): void {
    super.onMouseMove(x, y);
    if(this.thumbDragging) {
      var scrollRatio =  this.getScrollRatio();
      this.element.adjustScrollPosition(0, 0 - ((y - this.thumbDragY) / scrollRatio));
      this.thumbDragY = y;
    }
  }

  public onMouseUp(x:number, y: number): void {
    super.onMouseUp(x, y);
    if(this.thumbDragging) {
      this.thumbDragging = false;
      UICanvas.instance.unlockMouse(this);
    }
  }

  public onMouseWheelUp(x:number, y: number): void {
    this.element.onMouseWheelUp(x, y);
  }

  public onMouseWheelDown(x:number, y: number): void {
    this.element.onMouseWheelDown(x, y);
  }

  public draw()
  {
    var sx = this.getScreenX();
    var sy = this.getScreenY();
    var radius = this.radius;

    var path = new Path2D();
    path.moveTo(sx + radius, sy);
    path.lineTo(sx + this.width - radius, sy);
    path.quadraticCurveTo(sx + this.width, sy, sx + this.width, sy + radius);
    path.lineTo(sx + this.width, sy + this.height - radius);
    path.quadraticCurveTo(sx + this.width, sy + this.height, sx + this.width - radius, sy + this.height);
    path.lineTo(sx + radius, sy + this.height);
    path.quadraticCurveTo(sx, sy + this.height, sx, sy + this.height - radius);
    path.lineTo(sx, sy + radius);
    path.quadraticCurveTo(sx, sy, sx + radius, sy);
    path.closePath();

    UIManager.ctx.fillStyle = this.color;
    UIManager.ctx.fill(path);

    this.element.adjustScrollPosition(0, 0);

    var scrollRatio =  this.getScrollRatio();

    sx = this.getScreenX();
    sy = this.getScreenY() - (this.element.scrollPosition.y * scrollRatio);

    var thumbWidth = this.width;
    var thumbHeight = scrollRatio * (this.height);

    thumbWidth -= 4;
    thumbHeight -= 4;
    sx += 2;
    sy += 2;
    radius -= 2;

    var path = new Path2D();
    path.moveTo(sx + radius, sy);
    path.lineTo(sx + thumbWidth - radius, sy);
    path.quadraticCurveTo(sx + thumbWidth, sy, sx + thumbWidth, sy + radius);
    path.lineTo(sx + thumbWidth, sy + thumbHeight - radius);
    path.quadraticCurveTo(sx + thumbWidth, sy + thumbHeight, sx + thumbWidth - radius, sy + thumbHeight);
    path.lineTo(sx + radius, sy + thumbHeight);
    path.quadraticCurveTo(sx, sy + thumbHeight, sx, sy + thumbHeight - radius);
    path.lineTo(sx, sy + radius);
    path.quadraticCurveTo(sx, sy, sx + radius, sy);
    path.closePath();

    UIManager.ctx.fillStyle = this.thumbColor;
    UIManager.ctx.fill(path);
  }

  public resetPosition() {
    this.element.scrollPosition.x = 0;
    this.element.scrollPosition.y = 0;
  }

  public getScrollPositionY():number {
    return this.element.scrollPosition.y;
  }

  public setScrollPositionY(y:number) {
    this.element.scrollPosition.y = y;
  }
}