import { UIManager } from "../common/ui/uiManager";
import { UIPanel, UIPanelOptions } from "../common/ui/uiPanel";

export class DicePanel extends UIPanel {
  protected value: number;
  protected locked: boolean;
  protected rolling: boolean;
  protected rollTime: number;
  protected rollCount: number;

  constructor(options: UIPanelOptions = {}) {
    options.color = 'white';
    options.radius = 10;
    options.mouseEvents = true;
    options.borderColor = '#bbbbbb';
    options.borderWidth = 5;

    super(options);

    this.value = -1;
    this.locked = false;
    this.rolling = false;
    this.rollTime = 0;
    this.rollCount = 0;
  }

  public setValue(v:number) {
    this.value = v;
  }

  public getValue() {
    return this.value;
  }

  public update() {
    super.update();

    if(this.rolling) {
      this.rollTime -= this.deltaTime;

      if(this.rollTime <= 0) {
        this.value = 1 + Math.floor(Math.random() * 6);
        this.rollTime = 75;
        this.rollCount++;
        if(this.rollCount == 6)
          this.rolling = false;
      }
    }
  }

  public draw() {
    super.draw();

    let sx = this.getScreenX();
    let sy = this.getScreenY();
    let w1 = this.width/4;
    let w2 = this.width/2;
    let w3 = w1 + w2;
    let h1 = this.height/4;
    let h2 = this.height/2;
    let h3 = h1 + h2;
    let r1 = this.width/10;
    if(r1 < 2)
      r1 = 2;

    let positions = [];

    if(this.value == 1 || this.value == 3 || this.value == 5) {
      positions.push({x: sx + w2, y: sy + h2});
    }

    if(this.value == 2 || this.value == 3) {
      positions.push({x: sx + w1, y: sy + h3});
      positions.push({x: sx + w3, y: sy + h1});
    }

    if(this.value == 4 || this.value == 5 || this.value == 6) {
      positions.push({x: sx + w1, y: sy + h1});
      positions.push({x: sx + w3, y: sy + h1});
      positions.push({x: sx + w3, y: sy + h3});
      positions.push({x: sx + w1, y: sy + h3});
    }

    if(this.value == 6) {
      positions.push({x: sx + w1, y: sy + h2});
      positions.push({x: sx + w3, y: sy + h2});
    }

    UIManager.ctx.fillStyle = 'black';
    for(let i = 0; i < positions.length; i++) {
      UIManager.ctx.beginPath();
      UIManager.ctx.ellipse(positions[i].x, positions[i].y, r1, r1, 0, 0, Math.PI*2);
      UIManager.ctx.fill();    
    }
  }

  public roll() {
    if(this.locked) return;
    this.rolling = true;
    this.rollTime = 0;
    this.rollCount = 0;
  }

  public lock() {
    this.locked = true;
    this.borderColor = 'orange';
  }

  public unlock() {
    this.locked = false;
    this.borderColor = '#bbbbbb';
  }

  public isRolling() {
    return this.rolling;
  }

  public clear() {
    this.value = -1;
    this.unlock();
  }

  public onMouseDown(x:number, y: number): void {
    if(this.value == -1)
      return;
    
    if(this.locked)
      this.unlock();
    else
      this.lock();
  }
}