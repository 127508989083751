import React from 'react';
import { Server } from '../../server/server';
import { getBannerImage, getPortraitImage } from '../util/assets';
import { BsCalendarWeek, BsChatDotsFill, BsFillEyeFill, BsFillEyeSlashFill, BsFillPersonPlusFill, BsGiftFill, BsPencilFill, BsPencilSquare } from 'react-icons/bs';
import MessageModal from '../modals/MessageModal';
import AlertModal from '../modals/AlertModal';
import { NavLink, Navigate } from 'react-router-dom';
import QuestionModal from '../modals/QuestionModal';
import { WithRouterProps } from '../util/withRouter';
import PageEnvelope from '../elements/PageEnvelope';
import ContentPanel from '../elements/ContentPanel';
import { Database } from '../util/database';
import { numberWithCommas } from '../util/util';
import AssetPanel from '../elements/AssetPanel';
import GamesPanel from '../elements/GamesPanel';
import './SitePage.css'
import './InventoryPage.css'
import AssetsPanel from '../elements/AssetsPanel';

interface ProfilePageProps {
  router: WithRouterProps;
}

interface ProfilePageState {
  profile: any;
  profileError: string;
  followers: any[];
  following: any[];
  message: string;
  alert: string;
  question: string;
  navigate: string;
  openEditProfile: boolean;
  posts: any[];
  loading: boolean;
  loadingMore: boolean;
  filter: string;
  name: string;
  slug: string;
  bio: string;
  content: string;
  loadingContent: boolean;
  loadingBoosters: boolean;
  inventory: any[];
}

class ProfilePage extends React.Component<ProfilePageProps, ProfilePageState> {
  author = '';
  posLodMore = 0;
  isActivity = true;

  constructor(props: ProfilePageProps) {
    super(props);

    this.state = {
      profile: null,
      profileError: '',
      followers: [],
      following: [],
      message: '',
      alert: '',
      question: '',
      navigate: '',
      openEditProfile: false,
      posts: [],
      loading: false,
      loadingMore: false,
      filter: 'activity',
      name: '',
      slug: '',
      bio: '',
      content: 'activity',
      loadingContent: false,
      loadingBoosters: false,
      inventory: []
    };

    this.onFriendUpdated = this.onFriendUpdated.bind(this);
    this.onQuestionYes = this.onQuestionYes.bind(this);
    this.onQuestionNo = this.onQuestionNo.bind(this);
    this.openEditProfile = this.openEditProfile.bind(this);
    this.onCloseEditProfile = this.onCloseEditProfile.bind(this);
  }

  componentDidMount() {
    let content = localStorage.getItem('ProfileContentFilter');
    if(content)
      this.setState({content});
    else
      content = this.state.content;

    this.loadProfile(content);
    
    Server.user.addEventListener('friend-updated', this.onFriendUpdated);
  }
    
  componentDidUpdate(prevProps: Readonly<ProfilePageProps>) {
    if(prevProps.router.location.pathname != this.props.router.location.pathname) 
      this.loadProfile(this.state.content);
  }
  
  componentWillUnmount() {
    Server.user.removeEventListener('friend-updated', this.onFriendUpdated);
  }

  onFriendUpdated(event:any) {
    this.forceUpdate();
  }

  async loadProfile(content:string) {
    let profile = Server.public.getProfile(Server.user.getId());

    if(this.props.router.params.id) {
      let response = await Server.public.loadProfileFromSlug(this.props.router.params.id);

      if(!response.success) {
        this.setState({profile: null, profileError: response.message});
        return;
      }

      profile = response.profile;

      if(Server.user.isFriend(profile.id) && (!profile.followers || !profile.following)) {
        let followsResponse = await Server.user.getFriendFollows(profile.id);

        if(followsResponse.success) {
          profile.followers = followsResponse.followers;
          profile.following = followsResponse.following;
          Server.public.addProfileToCache(profile);
        }
      }
    }

    this.setState({profile});

    if(content == 'activity')
      this.loadActivity(profile.id);
    else if(content == 'boosters')
      this.loadBoosters(profile.id);
  }

  async loadActivity(author:string) {
    this.setState({loadingContent: true});
    let response = await Server.activity.getPosts({author});
    this.setState({loadingContent: false});
    if(response.success)
      this.setState({posts: response.posts});
  }

  async loadBoosters(user:string) {
    if(user == Server.user.getId()) {
      this.setState({inventory: Server.assets.getInventory()});
      return;
    }

    this.setState({loadingBoosters: true});
    let response = await Server.assets.getFriendsInventory(user);
    this.setState({loadingBoosters: false});
    if(response.success)
      this.setState({inventory: response.inventory});
  }

  async onFollow() {
    if(!Server.account.isLoggedIn()) {
      this.setState({alert: 'Please login to follow this users activity.'});
      return;
    }

    let isFollowing = Server.user.isFollowing(this.state.profile.id);
    let response:any = null;
    if (isFollowing) {
      this.setState({ message: 'Unfollowing...' });
      response = await Server.user.unfollow(this.state.profile.id);
    }
    else {
      this.setState({ message: 'Following...' });
      response = await Server.user.follow(this.state.profile.id);
    }

    setTimeout(() => {
    
      if (response.success) {
        this.setState({message: ''});
      }
      else 
        this.setState({message: ''});
    }, 500);
  }

  async onInvite() {
    if(!Server.account.isLoggedIn()) {
      this.setState({alert: 'Please login to invite this user to be your friend.'});
      return;
    }

    let friend = Server.user.getFriend(this.state.profile.id);
    if(!friend)
      this.setState({question: `Invite ${this.state.profile.name} to be your friend?`});
    else
      this.setState({alert: `You have already sent ${this.state.profile.name} an invite.`})
  }

  onQuestionYes() {
    this.setState({question: '', message: 'Sending invite...'});
    setTimeout(async () => {
      let response = await Server.user.inviteFriend(this.state.profile.slug);
      if(response.success)
        await Server.user.incrementCounter('friends#invite');
      this.setState({message: '', alert: `Invite sent!`});
    }, 500);
  }

  onQuestionNo() {
    this.setState({question: ''});
  }

  openEditProfile() {
    this.setState({
      openEditProfile: true,
      name: this.state.profile.name,
      slug: this.state.profile.slug,
      bio: this.state.profile.bio
    });
  }

  async onCloseEditProfile() {
    let profile = Server.public.getProfile(Server.user.getId());
    this.setState({profile, openEditProfile: false});
  }

  onSaveProfile() {
    let dirty = false;
    if(this.state.name != this.state.profile.name) dirty = true;
    if(this.state.bio != this.state.profile.bio) dirty = true;
    if(this.state.slug != this.state.profile.slug) dirty = true;

    if(!dirty) {
      this.setState({openEditProfile: false});
      return;
    }

    let errorMsg = '';

    if(this.state.name.length < 3) 
      errorMsg = 'Name must be at least 3 characters.';

    if(this.state.slug.length < 3) 
      errorMsg = 'Handle must be at least 3 characters.';

    if(this.state.slug.length > 32) 
      errorMsg = 'Handle must be at most 32 characters.';
  
    if(this.state.slug.indexOf('--') != -1) 
      errorMsg = 'Handle cannot contain consecutive dash (-) characters.';
      
    let re = /^[a-zA-Z0-9-]+$/;
    if(!re.test(this.state.slug)) 
      errorMsg = 'Handle may only contain a-z, A-Z, 0-9, and dash (-) characters.';

    if(errorMsg != '') {
      this.setState({alert: errorMsg});        
      return;
    }

    this.setState({message: 'Saving profile...'});

    setTimeout(async () => {
      let profile:any = {};
      if(this.state.name != this.state.profile.name) 
        profile.name = this.state.name;
      if(this.state.bio != this.state.profile.bio) 
        profile.bio = this.state.bio;
      if(this.state.slug != this.state.profile.slug) 
        profile.slug = this.state.slug;
  
      let response = await Server.user.setProfile(profile);
      
      if(response.success) {
        if(this.props.router.params.id && profile.slug)
          window.location.href = window.location.origin + '/profile/' + profile.slug;
        else
          this.setState({message: '', openEditProfile: false});
      }
      else {
        this.setState({message: '', alert: response.message});
      }
    }, 500);
  }

  onEditPortrait() {
    if(this.state.profile.id == Server.user.getId()) 
      this.setState({navigate: '/profile/portraits'});
  }

  onEditBanner() {
    if(this.state.profile.id == Server.user.getId()) 
      this.setState({navigate: '/profile/banners'});
  }

  onSendMessage() {
    let chat = Server.chat.getDirectChat(this.state.profile.id);

    if(chat) {
      window.sessionStorage.setItem('ChatPageId', chat.id);
      this.setState({navigate: '/chat'});
      return;
    }

    this.setState({message: 'Starting chat...'});

    setTimeout(async () => {
      let response = await Server.chat.startDirectChat(this.state.profile.id);

      if(response.success) {
        chat = Server.chat.getDirectChat(this.state.profile.id);
        window.sessionStorage.setItem('ChatPageId', chat.id);
        this.setState({navigate: '/chat'});
      }
      else {
        this.setState({alert: response.message, message: ''})
      }
    }, 500);   
  }

  onGift() {
    this.setState({navigate: '/inventory'});
  }

  onContentChange(content:string) {
    this.setState({content});

    localStorage.setItem('ProfileContentFilter', content);

    if(content == 'activity')
      this.loadActivity(this.state.profile.id);
    else if(content == 'boosters')
      this.loadBoosters(this.state.profile.id);
  }

  onPostClick(id:string) {
    this.setState({navigate: '/activity/post/' + id});
  }

  onGameClick(id:string) {
    this.setState({navigate: '/game/' + id});
  }

  renderActionButtons() {
    if(this.state.profile.slug == Server.user.getSlug()) 
      return <div style={{height: '35px'}} />;

    let isFriend = Server.account.isLoggedIn() && Server.user.isFriend(this.state.profile.id);
    let isFollowing = Server.account.isLoggedIn() && Server.user.isFollowing(this.state.profile.id);

    return (
      <div className="site-page-row" style={{justifyContent: 'right'}}>
        {isFriend &&
          <button className="site-page-icon-button" style={{color: 'white'}} onClick={()=>this.onSendMessage()}><BsChatDotsFill size="1.2em"/></button>
        }

        {isFriend && 
          <button className="site-page-icon-button" style={{color: 'white'}} onClick={()=>this.onGift()}><BsGiftFill/></button>
        }

        {!isFriend &&   
          <button className="site-page-icon-button" style={{color: 'white'}} onClick={() => this.onInvite()}><BsFillPersonPlusFill size="1.2em"/></button>
        }

        {!isFriend && !isFollowing &&  
          <button className="site-page-icon-button" style={{color: 'white'}} onClick={() => this.onFollow()}><BsFillEyeFill size="1.2em"/></button>
        }

        {!isFriend && isFollowing && 
          <button className="site-page-icon-button" style={{color: 'white'}} onClick={() => this.onFollow()}><BsFillEyeSlashFill size="1.2em"/></button>
        }
      </div>
    )
  }

  renderEditProfile() {
    return (
      <div className="site-page-column" style={{marginTop: '0px'}}>
        <input value={this.state.name} onChange={(e)=>this.setState({name: e.currentTarget.value})} />
        <input value={this.state.slug} onChange={(e)=>this.setState({slug: e.currentTarget.value})} />
        <textarea value={this.state.bio} onChange={(e)=>this.setState({bio: e.currentTarget.value})} />
        <div className="site-page-row" style={{justifyContent: 'right'}}>
          <button onClick={()=>this.setState({openEditProfile: false})}>Cancel</button>
          <button onClick={()=>this.onSaveProfile()}>Save</button>
        </div>
      </div>
    )
  }

  renderActivity() {
    if(this.state.loadingContent)
      return (<div className="site-page-panel">Loading...</div>);

    let divs:any[] = [];
    for(let i = 0; i < this.state.posts.length; i++) {
      let post = this.state.posts[i];
      divs.push(
        <ContentPanel 
          key={i}
          author={post.author}
          date={post.date}
          content={post.content}
          reply={true}
          summary={true}
          onClick={()=>this.onPostClick(post.id)}
        />
      )
    }

    if(divs.length == 0)
      return (<div className="site-page-panel">No activity posts yet.</div>);

    return (
      <div className="site-page-column">
        {divs}
      </div>
    )
  }

  renderBoosters() {
    if(this.state.loadingBoosters)
      return (<div className="site-page-panel">Loading...</div>);

    let inventory = [];
    for(let i = 0; i < this.state.inventory.length; i++) {
      let asset = Database.getAsset(this.state.inventory[i].id);
      if(!asset) continue;
      if(asset.rarity != undefined)
        inventory.push(this.state.inventory[i]);
    }

    return <AssetsPanel inventory={inventory} />;
  }

  renderTabs() {
    let normalStyle = {
      backgroundColor: '#00000060',
      color: 'var(--panel-subtext-color)',
      padding: '10px 15px 5px 15px',
      display: 'flex',
      // borderRadius: 'var(--panel-border-radius)',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      cursor: 'pointer'
    }

    let selectedStyle = JSON.parse(JSON.stringify(normalStyle));
    selectedStyle.backgroundColor = 'var(--panel-background-color)';
    selectedStyle.color = 'var(--panel-text-color)';

    let activityStyle = this.state.content == 'activity' ? selectedStyle : normalStyle;
    let scoresStyle = this.state.content == 'scores' ? selectedStyle : normalStyle;
    let boostersStyle = this.state.content == 'boosters' ? selectedStyle : normalStyle;

    let barStyle = {
      backgroundColor: 'var(--panel-background-color)',
      borderRadius: 'var(--panel-border-radius)',
      height: '10px'
    }

    let viewBoosters = this.state.profile.id == Server.user.getId() || Server.user.isFriend(this.state.profile.id);

    return (
      <div>
        <div className="site-page-row" style={{columnGap: '5px'}}>
          <div style={activityStyle} onClick={()=>this.onContentChange('activity')}>Activity</div>
          <div style={scoresStyle} onClick={()=>this.onContentChange('scores')}>Scores</div>
          {viewBoosters &&
            <div style={boostersStyle} onClick={()=>this.onContentChange('boosters')}>Boosters</div>
          }
        </div>
        <div style={barStyle} />
      </div>
    )
  }

  render() {
    let parts = window.location.pathname.split('/');
    if(parts.length == 2 && !Server.account.isLoggedIn())
      return(<Navigate replace to="/" />);

    if(this.state.navigate != '') 
      return <Navigate to={this.state.navigate} />;

    if (this.state.profile == null) {
      return (
        <div className="site-page">
          <PageEnvelope width="600px">
            <div className="site-page-panel">
              {this.state.profileError == '' ?
                <div>Loading...</div> :
                <div>{this.state.profileError}</div>
              }
            </div>
          </PageEnvelope>
        </div>
      )
    }
      
    let joined = new Date(this.state.profile.joined).toLocaleDateString();
    let isFriend    = Server.account.isLoggedIn() && Server.user.isFriend(this.state.profile.id);
    let isCurrent   = Server.account.isLoggedIn() && Server.user.getId() == this.state.profile.id;

    let followers = this.state.profile.followers ? this.state.profile.followers.length : 0;
    let following = this.state.profile.following ? this.state.profile.following.length : 0;
    let friends = this.state.profile.friends ? this.state.profile.friends.length : 0;
    followers += friends;
    following += friends;
    
    return (
      <div className="site-page">
        <PageEnvelope width="600px">
          <div className="site-page-column">

            <div className="site-page-column" style={{rowGap: '0px'}}>
              <div className="site-page-row" style={{position: 'relative'}}>
                <img 
                  className="site-portrait-banner" 
                  style={{cursor: isCurrent ? 'pointer' : 'default'}}
                  onClick={()=>this.onEditBanner()}
                  src={getBannerImage(this.state.profile)} 
                />
                <img 
                  className="site-page-portrait" 
                  style={{
                    position: 'absolute', 
                    bottom: '0px', 
                    left: '10px',
                    transform: 'translateY(48%)',
                    width: '100px',
                    height: '100px',
                    cursor: isCurrent ? 'pointer' : 'default'
                  }} 
                  onClick={()=>this.onEditPortrait()}
                  src={getPortraitImage(this.state.profile)} 
                />
                {isCurrent && 
                  <div style={{color: '#ffffff80', position: 'absolute', top: '10px', right: '10px', pointerEvents: 'none'}}>
                    <BsPencilSquare />
                  </div>
                }
              </div>

              <div className="site-page-panel" style={{padding: '15px', paddingTop: '25px', borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}>
                <div style={{marginTop: '-5px', marginRight: '-5px'}}>
                  {this.renderActionButtons()}
                </div>

                {this.state.openEditProfile && this.renderEditProfile()}

                {!this.state.openEditProfile && 
                  <div className="site-page-column">
                    <div className="site-page-row" style={{alignItems: 'normal', marginTop: '-10px'}}>
                      <div className="site-page-title-text">{this.state.profile.name}</div>
                      <div className="site-page-subtext" style={{transform: 'translateY(2px)'}}>@{this.state.profile.slug}</div>

                      {this.state.profile.slug == Server.user.getSlug() && 
                        <div style={{transform: 'translateY(3px)', cursor: 'pointer'}} onClick={this.openEditProfile}>
                          <BsPencilFill />
                        </div>
                      }

                    </div>

                    {this.state.profile.bio != '' &&
                      <div>{this.state.profile.bio}</div>
                    }
                  </div>                  
                }

                <div className="site-page-row" style={{marginTop: '5px'}}>
                  <BsCalendarWeek style={{color: 'var(--panel-subtext-color)'}} />
                  <div className="site-page-subtext">Joined {joined}</div>
                </div>


                {(isCurrent || isFriend) && 
                  <div className="site-page-row site-page-subtext" style={{marginTop: '5px', columnGap: '15px'}}>
                    <NavLink to={`/profile/${this.state.profile.slug}/followers`}>
                      {followers} Followers
                    </NavLink>
                    <NavLink to={`/profile/${this.state.profile.slug}/following`}>
                    {following} Following
                    </NavLink>
                  </div>
                }
              </div>

            </div>

            <div style={{marginTop: '10px'}}>
              {this.renderTabs()}
            </div>

            <div className="site-page-column" style={{rowGap: '0px'}}>
              {this.state.content == 'activity' && this.renderActivity()}
              {this.state.content == 'scores' && <GamesPanel user={this.state.profile.id} stats={false} all={false} />}
              {this.state.content == 'boosters' && this.renderBoosters()}
            </div>

          </div>
        </PageEnvelope>

        {/* <EditProfileModal open={this.state.openEditProfile} onClose={this.onCloseEditProfile} /> */}
        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
        <QuestionModal message={this.state.question} onYes={this.onQuestionYes} onNo={this.onQuestionNo} />
      </div>
    );
  }
}

export default ProfilePage;
