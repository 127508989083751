import { UIGrid } from '../common/ui/uiGrid';
import { UIImage } from '../common/ui/uiImage';
import { UIPanel } from '../common/ui/uiPanel';
import { UIScreen } from '../common/ui/uiScreen';
import { UIText } from '../common/ui/uiText';
import { Game, GameMode } from './game';
import { GameStyle } from './gameStyle';
import { MatchThreePanel } from './matchThreePanel';
import { UICanvas } from '../common/ui/uiCanvas';
import { GemColors } from './matchThree';
import { LeaderboardPanel } from '../common/leaderboardPanel';
import { GameHeader } from '../common/gameHeader';
import { getTimeString, numberWithCommas } from '../common/util';
import { GameFooter } from '../common/gameFooter';
import { Server } from '../../server/server';

export class GameScreen extends UIScreen {
  protected smallGemLockedImage: string;
  protected smallGemImages: string[];
  protected goalsPanel: UIPanel;
  protected goalsGrid: UIGrid;
  protected gamePanel: MatchThreePanel;
  protected readyGoText: UIText;
  protected leaderboard: LeaderboardPanel;
  protected header: GameHeader;
  protected footer: GameFooter;
  protected toastReadyTimer:any;

  public build() {
    let background = new UIPanel({
      color : GameStyle.backgroundColors.game.inner,
      fitParent : {left : 0, top : 0, right : 0, bottom : 0},
      parent : this
    });

    this.header = new GameHeader({
      game: Game.instance,
      callback: (action:string)=>{
        if(action == 'restart')
          this.restart();
        else if(action == 'quit')
          this.onQuit();
      },
      handleQuit: (Game.instance.gameMode != GameMode.Adventure),
      parent : this
    });

    this.footer = new GameFooter({
      game: Game.instance,
      callback: (id:string)=>{
        this.onPowerup(id);
      },
      parent : this
    });

    this.gamePanel = new MatchThreePanel({
      fitParent : {left : 10, top : 120, right : 10, bottom : 110},
      maxSize: {x: 600, y: 0},
      pivot: {x: 0.5, y: 0},
      anchor: {x: 0.5, y: 0},
      parent : this
    });

    this.leaderboard = new LeaderboardPanel({
      fitParent: {left: 0, right: 0, top: -1, bottom: -1},
      size: {x: 0, y: 40},
      anchor: {x: 0, y: 0},
      pivot: {x: 0, y: 0},
      position: {x: 0, y: 60},
      color: '#00000020',
      parent : this
    });

    this.goalsPanel = new UIPanel({
      fitParent: {left: 0, right: 0, top: -1, bottom: -1},
      size : {x : 200, y : 40},
      position : {x : 0, y : 60},
      color: '#00000020',
      parent : this
    });

    this.goalsGrid = new UIGrid({
      columns : 4,
      rows : 1,
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      spacing : {x : 5, y : 0},
      fitParent : {left : -1, right : -1, top : 0, bottom : 0},
      // color: '#ffffff',
      parent : this.goalsPanel
    });

    this.readyGoText = new UIText({
      text : '',
      fontFamily : 'verdana',
      fontSize : 40,
      fontWeight: '800',
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      position: {x: 0, y: 180},
      strokeLineWidth: 5,
      strokeColor: '#000000',
      color: '#FFF200',
      parent : this
    })
  }

  protected buildGoals() {
    this.goalsGrid.deleteAllChildren();

    let goals = Game.instance.matchThree.getGoals();
    if(!goals)
      return;

    this.goalsGrid.columns = goals.length;
    this.goalsGrid.size.x = this.goalsGrid.columns * 90;

    let smallGemLockedImage = require('./assets/goalGemLocked.png');
    let smallGemFrozenImage = require('./assets/goalGemFrozen.png');

    let smallGemImages = [
      require('./assets/goalGem0.png'), require('./assets/goalGem1.png'),
      require('./assets/goalGem2.png'), require('./assets/goalGem3.png'),
      require('./assets/goalGem4.png'), require('./assets/goalGem5.png')
    ];

    for(let i = 0; i < this.goalsGrid.columns; i++) {
      let url = null;

      if(goals[i].gemType == 'frozen')
       url = smallGemFrozenImage;
      else if(goals[i].gemType == 'locked')
        url = smallGemLockedImage;
      else {
        let goalGemType = GemColors.indexOf(goals[i].gemType);
        if(goalGemType != -1)
          url = smallGemImages[goalGemType];
      }

      if(!url)
        continue;

      let panel = new UIPanel({
        // color: '#00000020',
        parent : this.goalsGrid
      });

      let goalImage = new UIImage({
        url : url,
        anchor: {x: 0.5, y: 0.5},
        pivot: {x: 1, y: 0.5},
        position : {x : -5, y : 0},
        size : {x : 30, y : 30},
        stretch : true,
        parent : panel
      });

      let goalAmountText = new UIText({
        text : '0',
        anchor: {x: 0.5, y: 0.5},
        pivot: {x: 0, y: 0.5},
        position : {x : 5, y : 0},
        fontFamily: GameStyle.header.value.fontFamily,
        fontSize: GameStyle.header.value.fontSize,
        color: GameStyle.header.value.color,
        parent : panel
      });
    }
  }

  public onWake() {
    this.goalsPanel.visible = false;
    this.gamePanel.fitParent.top = 70;
    this.readyGoText.visible = false;

    if(Game.instance.gameMode == GameMode.Arcade)
      this.header.setLabels(['TIME', 'SCORE', '']);
    else
      this.header.setLabels(['MOVES', 'SCORE', 'LEVEL']);

    this.footer.loadPowerups();
  
    if(Game.instance.isMultiplayer())
      this.leaderboard.connect(Game.instance.getPlaceHost());

    Game.instance.matchThree.setFinishCallback(()=>this.onFinishGame());
  }

  public onSleep() {
    this.leaderboard.disconnect();
    super.onSleep();
  }

  public pause() {
    if(this.readyGoText.visible) 
      this.cancelToastReadyGo();
    Game.instance.matchThree.pause();
  }

  public resume() {
    Game.instance.matchThree.resume();
  }

  public update() {
    super.update();

    let matchThree = Game.instance.matchThree;
    if(!matchThree)
      return;

    if(matchThree.score == undefined)
      return;

    this.header.setValue(1, numberWithCommas(matchThree.score));

    if(Game.instance.gameMode == GameMode.Arcade) 
      this.header.setValue(0, getTimeString(matchThree.timeLeft));
    else
      this.header.setValue(0, matchThree.movesLeft.toString());

    if(Game.instance.gameMode != GameMode.Arcade) 
      this.header.setValue(2, Game.instance.levelSelected.toString());

    if (Game.instance.gameMode == GameMode.Adventure || Game.instance.gameMode == GameMode.Daily) {
      let goals = matchThree.getGoals();
      for(let i = 0; i < goals.length; i++) {
        let countText = this.goalsGrid.getChild(i).getChild(1) as UIText;
        countText.text = goals[i].amount.toString();
      }
    }
  }

  public startGame() {
    this.footer.reset();

    if (Game.instance.gameMode == GameMode.Adventure || Game.instance.gameMode == GameMode.Daily) {
      this.goalsPanel.visible = true;
      this.gamePanel.fitParent.top = 120;
      this.gamePanel.fitParent.bottom = 90;
      this.leaderboard.visible = false;
      this.buildGoals();
      Game.instance.showWelcome();
    }
    else {
      this.goalsPanel.visible = false;
      this.gamePanel.fitParent.top = Game.instance.isMultiplayer() ? 120 : 80;
      this.gamePanel.fitParent.bottom = 90;
      this.leaderboard.visible = Game.instance.isMultiplayer();
      this.pause();
      Game.instance.showWelcome(()=>{
        this.resume();
        Game.instance.matchThree.pause();
        this.toastReadyGo(()=>{
          Game.instance.matchThree.resume();
        });
      });
    }
  }

  public async onFinishGame() {
    Game.instance.finish();

    let score = Game.instance.matchThree.score;
    let rewardScore = score;

    if(Game.instance.gameMode == GameMode.Adventure) {
      rewardScore = Game.instance.levelSelected;
    }

    Game.instance.showGameOver(score, rewardScore, (action:string)=>{
      if(action == 'quit')
        this.quit();
      else if(action == 'restart')
        this.restart();
      else if(action == 'success' && Game.instance.gameMode == GameMode.Adventure)
        this.saveAdventureScore();
    }, false);
  }

  protected async saveAdventureScore() {
    let level = Game.instance.levelSelected;
    let score = Game.instance.matchThree.score;

    Game.instance.disableGameOverButtons(true);

    await Game.instance.saveAdventureScore(level, score, Game.instance.matchThree.isGameWon());  
    
    let best = Game.instance.getAdventureScore(level);
    let leaderboard = Game.instance.mapScreen.leaderboards[level-1];
    let place = (leaderboard && leaderboard.length > 0) ? 1 : -1;

    let existing = leaderboard.find((e:any)=>e.user == Server.user.getId());
    if(existing) 
      existing.score = best;
    else 
      leaderboard.push({user: Server.user.getId(), score: best});

    leaderboard.sort((a, b)=>{
      if(a.score > b.score)
        return -1;
      else if(a.score < b.score)
        return 1;
      return 0;
    });

    for(let i = 0; i < leaderboard.length; i++) {
      if(leaderboard[i].user == Server.user.getId())
        continue;
      if(leaderboard[i].score > best)
        place++;
    }

    Game.instance.setGameOverBestScore(best, best == score, place);
    Game.instance.disableGameOverButtons(false);
  }

  protected restart() {
    Game.instance.start();
  }

  public toastReadyGo(callback:Function) {
    let y = this.readyGoText.position.y;
    let duration = 200;
    let delay = 800;
    let halfWidth = (UICanvas.instance.width/2);

    this.toastReadyTimer = setTimeout(() => {
      this.readyGoText.text = 'Ready...';
      this.readyGoText.visible = true;
      this.readyGoText.slide(0 + halfWidth + this.readyGoText.width, y, 0, y, duration);

      this.toastReadyTimer = setTimeout(() => {
        this.readyGoText.slide(0, y, 0 - halfWidth - this.readyGoText.width, y, duration, ()=>{
          this.readyGoText.text = 'Set...';
          this.readyGoText.slide(0 + halfWidth + this.readyGoText.width, y, 0, y, duration);

          this.toastReadyTimer = setTimeout(() => {
            this.readyGoText.slide(0, y, 0 - halfWidth - this.readyGoText.width, y, duration, ()=>{
              this.readyGoText.text = 'GO!';
              this.readyGoText.slide(0 + halfWidth + this.readyGoText.width, y, 0, y, duration);

              this.toastReadyTimer = setTimeout(() => {
                this.readyGoText.slide(0, y, 0 - halfWidth - this.readyGoText.width, y, duration, ()=>{
                  this.readyGoText.visible = false;
                  callback();
                });
              }, delay);
            });
          }, delay);
        });
      }, delay);
    }, 350);
  }

  public cancelToastReadyGo() {
    clearTimeout(this.toastReadyTimer);    
    this.readyGoText.cancelSlide();
    this.readyGoText.visible = false; 
  }

  protected onPowerup(id:string) {
    if(!Game.instance.matchThree.isPlayerInputState())
      return;

    Game.instance.matchThree.startPowerUp(id);
    this.footer.usePowerup(id);
  }

  protected onQuit() {
    Game.instance.askQuestion('Quit the game and\nreturn to the map?', (response:string)=>{
      if(response == 'Yes')
        this.quit();
    });
  }

  protected quit() {
    if(Game.instance.gameMode == GameMode.Adventure) {
      Game.instance.mapScreen.show();
      let best = Game.instance.getAdventureScore(Game.instance.levelSelected);
      if(best == Game.instance.matchThree.score)
        Game.instance.mapScreen.scrollToCurrentNode();

      let level = Game.instance.levelSelected;
      let leaderboard = Game.instance.mapScreen.leaderboards[level-1];

      if(leaderboard.length > 1)
        Game.instance.mapScreen.showLevelDialog(Game.instance.levelSelected);
    }
    else {
      Game.instance.quit();
    }
  }
}
