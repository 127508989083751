import React from 'react';
import { Server } from '../../server/server';
import { Navigate, NavLink } from 'react-router-dom';
import PageEnvelope from '../elements/PageEnvelope';
import { Database } from '../util/database';

const rarityNames = [
  'Common',
  'Uncommon',
  'Rare',
  'Epic',
  'Legendary',
  'Celestial'
]

interface AdminStatsPageState {
  counters: {},
  dates: string[],
  filter: string;
}

class AdminStatsPage extends React.Component<{}, AdminStatsPageState> {
  constructor(props:{}) {
    super(props);
    this.state = {
      counters: null,
      dates: [],
      filter: 'users'
    }
  }

  componentDidMount(): void {
    let filter = localStorage.getItem('AdminStatsPageFilter');
    if(filter)
      this.setState({filter});

    this.load();  
  }

  async load() {
    let start = new Date();
    let end = new Date();
    start.setHours(start.getHours() - (24*6));
    end.setHours(end.getHours() + (24*1));

    let dates = [];
    let d = new Date(start);
    for(let i = 0; i < 7; i++) {
      let s = d.toISOString().split('T')[0];
      dates.push(s);
      d.setHours(d.getHours() + 24);
    }

    let response = await Server.user.getCounters();

    let counters = {};
    for(let i = 0; i < response.counters.length; i++) {
      let id = response.counters[i].id;
      if(!counters[id])
        counters[id] = [0,0,0,0,0,0,0];
      let n = dates.indexOf(response.counters[i].date);
      if(n != -1)
        counters[id][n] = response.counters[i].value;
    }

    this.setState({counters, dates});
  }

  getAssetName(id:string, prefix:string) {
    let asset = Database.getAsset(id.split('#')[1]);
    if(asset) {
      let name = Database.getAssetName(id.split('#')[1]);
      let quality = (asset.rarity == null) ? '' : `(${rarityNames[asset.rarity]})`;
      return `${prefix} ${name} ${quality}`;
    }
    return id;
  }

  getCounterName(id:string) {
    if(id == 'activity#like') return 'Activity Like';
    if(id == 'activity#post') return 'Activity Post';
    if(id == 'activity#reply') return 'Activity Reply';
    if(id == 'activity#repost') return 'Activity Repost';
    if(id == 'forums#post') return 'Forum Post';
    if(id == 'forums#reply') return 'Forum Reply';
    if(id == 'blogs#post') return 'Blog Post';
    if(id == 'blogs#reply') return 'Blog Reply';
    if(id == 'chat#message') return 'Send Chat Message';
    if(id == 'chat#new#direct') return 'Start Direct Chat';
    if(id == 'chat#new#group') return 'Start Group Chat';
    if(id == 'friends#accept') return 'Accept Friend Invite';
    if(id == 'friends#decline') return 'Decline Friend Invite';
    if(id == 'friends#invite') return 'Send Friend Invite';
    if(id == 'friends#remove') return 'Remove Friend';
    if(id == 'login#new') return 'New Users';
    if(id == 'login#first') return 'Daily Active Users';
    if(id == 'login#first#mobile') return 'Daily Active Users (Mobile)';
    if(id == 'login#return') return 'Returning Login';
    if(id == 'place#enter') return 'Places Entered';
    if(id == 'place#play') return 'Place Games Played';
    if(id == 'place#watch') return 'Place Videos Watched';

    if(id.indexOf('craft#') == 0) return this.getAssetName(id, 'Craft');
    if(id.indexOf('gift#') == 0) return this.getAssetName(id, 'Gift');
    if(id.indexOf('purchase#') == 0) return this.getAssetName(id, 'Purchase');
    if(id.indexOf('reward#') == 0) return this.getAssetName(id, 'Reward');

    if(id.indexOf('game#') == 0) {
      let game = Database.getGame(id.split('#')[1]);
      let restarted = id.split('#')[2] == 'restart';
      if(game) 
        return `${game.name} ${restarted ? 'Restart' : ''}`;
    }

    return id;
  }

  isCounterFiltered(id:string) {
    if(this.state.filter == 'all')
      return false;

    if(this.state.filter == 'users') {
      if(id.indexOf('login#') == 0) return false;
    }

    if(this.state.filter == 'games') {
      if(id.indexOf('game#') == 0) return false;
    }

    if(this.state.filter == 'community') {
      if(id.indexOf('activity#') == 0) return false;
      if(id.indexOf('forums#') == 0) return false;
      if(id.indexOf('blogs#') == 0) return false;
      if(id.indexOf('place#') == 0) return false;
    }

    if(this.state.filter == 'friends') {
      if(id.indexOf('friends#') == 0) return false;
      if(id.indexOf('chat#') == 0) return false;
    }

    if(this.state.filter == 'inventory') {
      if(id.indexOf('gift#') == 0) return false;
      if(id.indexOf('craft#') == 0) return false;
    }

    if(this.state.filter == 'shop') {
      if(id.indexOf('purchase#') == 0) return false;
    }

    if(this.state.filter == 'rewards') {
      if(id.indexOf('reward#') == 0) return false;
    }

    return true;
  }

  onRefresh() {
    this.load();
  }

  onFilterChange(e:any) {
    this.setState({filter: e.currentTarget.value});
    localStorage.setItem('AdminStatsPageFilter', e.currentTarget.value);
  }

  renderStackedBar(series:any, colors:any, max:number) {
    let keys = Object.keys(series);

    let divs = [];
    for(let i = 0; i < keys.length; i++) {
      let height = 100 * (series[keys[i]] / max);
      let color = colors[keys[i]];
      let style = {
        height: height + '%', 
        width: '80%', 
        backgroundColor: color, 
        fontSize: '0.7em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black'
      }
      divs.push(
        <div style={style}>
          {series[keys[i]]}
        </div>
      )
    }

    let style:any = {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
      // backgroundColor: 'purple'
    }

    return (
      <div style={style}>
        {divs}
      </div>
    )
  }

  renderTable() {
    if(!this.state.counters)
      return null;
    
    let rowStyle = {
      display: 'grid',
      gridTemplateColumns: '1fr 50px 50px 50px 50px 50px 50px 50px',
      padding: '5px 10px 5px 10px'
    }

    let headerStyle = {
      ...rowStyle, 
      backgroundColor: 'var(--panel-background-color)', 
      color: 'var(--panel-subtext-color)',
      borderRadius: '6px', 
      marginBottom: '5px',
      fontSize: '0.9em'
    };

    let cellStyle:any = {textAlign: 'center'};

    let headerCells = [];
    headerCells.push(<div key={Math.random()}>Counter</div>);
    for(let i = 0; i < this.state.dates.length; i++) {
      let parts = this.state.dates[i].split('-');
      parts.splice(0, 1);
      let month = parts[0];
      let day = parts[1];
      if(month.substring(0, 1) == '0')
        month = month.substring(1);
      if(day.substring(0, 1) == '0')
        day = day.substring(1);
      let s= month + '/' + day;

      headerCells.push(<div key={Math.random()} style={cellStyle}>{s}</div>);
    }

    let keys = Object.keys(this.state.counters);
    keys.sort();

    let rows = [];
    for(let i = 0; i < keys.length; i++) {
      // if(this.state.filter != 'all' && keys[i].indexOf(this.state.filter) != 0)
      //   continue;
      if(this.isCounterFiltered(keys[i]))
        continue;

      let values = this.state.counters[keys[i]];

      let rowCells = [];
      rowCells.push(<div key={Math.random()}>{this.getCounterName(keys[i])}</div>);
      for(let j = 0; j < values.length; j++)
        rowCells.push(<div key={Math.random()} style={cellStyle}>{values[j]}</div>);
  
      rows.push(
        <div key={Math.random()} style={rowStyle}>
          {rowCells}
        </div>
      )
    }

    return (
      <div>
        <div style={headerStyle}>
          {headerCells}
        </div>
        {rows}
      </div>
    )
  }

  renderTable2() {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td>login#new</td>
              <td>12</td>
              <td>12</td>
              <td>12</td>
              <td>12</td>
              <td>12</td>
              <td>12</td>
              <td>12</td>
            </tr>
            <tr>
              <td>login#new</td>
              <td>12</td>
              <td>12</td>
              <td>12</td>
              <td>12</td>
              <td>12</td>
              <td>12</td>
              <td>12</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    if(!Server.account.isLoggedIn() || !Server.account.isAdmin())
      return <Navigate to="/" replace={true} />

    let now = new Date().toUTCString();

    return (
      <div className="site-page">
        <PageEnvelope width="700px">
          <div className="site-page-column">
            <div className="site-page-row" style={{justifyContent: 'space-between'}}>
              <select value={this.state.filter} onChange={(e:any)=>this.onFilterChange(e)} style={{width: '130px'}}>
                <option value="community">Community</option>
                <option value="friends">Friends</option>
                <option value="games">Games</option>
                <option value="inventory">Inventory</option>
                <option value="rewards">Rewards</option>
                <option value="shop">Shop</option>
                <option value="users">Users</option>
              </select>
              <div style={{color: 'var(--panel-subtext-color)'}}>{now}</div>
              <button onClick={()=>this.onRefresh()}>Refresh</button>
            </div>
            {this.renderTable()}
          </div>
        </PageEnvelope>
      </div>
    )
  }
}

export default AdminStatsPage;