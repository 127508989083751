import { UIDialog } from "./ui/uiDialog";
import { UIPanel } from "./ui/uiPanel";
import { UIPanelButton } from "./ui/uiPanelButton";
import { UIImage } from "./ui/uiImage";

const Menu = require('../../data/menu.json');

export class PlayPlaceMenu extends UIDialog {

  public build() {
    this.mouseEvents = true;

    this.popup = new UIPanel({
      size: {x: 140, y: 305},
      position: {x: 10, y: 50},
      radius: 8,
      color: this.options.panel.color,
      gradient: this.options.panel.gradient,
      borderColor: this.options.panel.borderColor,
      borderWidth: this.options.panel.borderWidth,
      shadow: this.options.panel.shadow,
      parent: this
    });

    let menu = Menu.site;

    let posY = 5;
    for(let i = 0; i < menu.length; i++) {
      if(menu[i].header) continue;
      this.buildMenuItem(menu[i], posY, null);
      posY += 32;
    }

    this.onChatUpdated = this.onChatUpdated.bind(this);
    this.onFriendUpdated = this.onFriendUpdated.bind(this);

    // Server.chat.addEventListener('chat-started', this.onChatUpdated);
    // Server.chat.addEventListener('chat-updated', this.onChatUpdated);
    // Server.user.addEventListener('friend-updated', this.onFriendUpdated);
  }

  protected buildMenuItem(menu: any, posY: number, iconUrl: string) {
    let menuItem = new UIPanelButton({
      name: menu.path,
      text : menu.name,
      position : {x : 10, y : posY},
      fitParent: {left: 0, right: 0, top: -1, bottom: -1},
      size : {x : 0, y : 40},
      callback : () => {
        this.hide();
        this.callback(menu.path);
      },
      panelColors: {normal: ''},
      fontFamily: this.options.message.fontFamily,
      fontSize: this.options.message.fontSize,
      textColors: {normal: this.options.message.color},
      textOffset: {x: -55, y: 0},
      textHorizontalAlign: 'left',
      parent : this.popup
    });

    // let menuIcon = new UIImage({
    //   url : iconUrl,
    //   scale: 0.6,
    //   position : {x : 10, y : 0},
    //   anchor: {x: 0, y: 0.5},
    //   pivot: {x: 0, y: 0.5},
    //   parent : menuItem
    // });
  }

  public onChatUpdated() {
    let dot = new UIPanel({
      size: {x: 10, y: 10},
      position: {x: 170, y: 180},
      radius: 5,
      color: 'red',
      parent : this.popup
    });
  }

  public onFriendUpdated(data: any) {
    if(data.action == 'connect' || data.action == 'disconnect' || data.action == 'presence')
      return;

    // let dot = new UIPanel({
    //   size: {x: 10, y: 10},
    //   position: {x: 170, y: 220},
    //   radius: 5,
    //   color: 'red',
    //   parent : this.popup
    // });
  }

  public onWake() {
  }

  public onMouseDown(x:number, y:number) {
    let child = this.findChildFromPoint(x, y);
    if(child == this) {
      this.hide();
      this.callback();
    }
  }
}