import {FloatingText} from '../common/floatingText';
import {RandomNumberGenerator} from '../common/randomNumberGenerator';
import {SpriteSheet, SsImage} from '../common/spriteSheetManager';
import {SoundManager} from '../common/audio/soundManager';
import {UIManager} from '../common/ui/uiManager';
import {Game, GameMode} from './game';
import { Server } from '../../server/server';
import { Database } from '../../app/util/database';

//const GemColor = new Map([
//  [0, "Blue"], [1, "White"], [2, "Purple"], [3, "Red"], [4, "Yellow"], [5, "Green"]
//]);

// clang-format off

const boardRenderScale = 2;

const GemColors = ['blue', 'white', 'purple', 'red', 'yellow', 'green'];

enum MatchThreeGameType {
  Moves,
  Timed
}

enum PowerUpType {
  Tornado,
  ScatterBomb,
  DwarfMiner,
  SnowCreature,
  TimeFreeze,
  ExtraTurns,
  PrecisionBlast,
  TransformGem,
  SkipMatchCheck,
  LockGem,
  FreezeGem
}

enum MatchThreeState {
  Limbo,
  PlayerInput,
  PowerUp,
  NoMovesScramble,
  Exchanging,
  ReverseExchanging,
  Falling,
  Scoring,
  BlastingType,
  BlastingSquare,
  BlastingRow,
  BlastingColumn,
  BlastingNwSe,
  BlastingNeSw,
  BlastingNwSeNeSw,
  EndingGame,
  GameOver
}

// DoubleMatch is a 'L' or 'T' shape match
enum MatchType {
  None,
  Angles,
  Three,
  Four,
  FourHorz,
  FourVert,
  Double,
  Five,
}

enum GemState {
  None,
  Moving,
  Scoring
}

enum BoostType {
  None,
  GemType,
  Column,
  Row,
  Square,
  NwSe,
  NeSw,
  NwSeNeSw
}

enum MatchId {
  TWO_BY_TWO_SQUARE = 15
}


class MatchPatternDef {
  name: string;
  id: number;
  priority: number;
  pattern: string[];
  createBoostScore: number;
  matchType: MatchType;
  boostType: BoostType;
}

let matchPatternDefs: MatchPatternDef[] = [
  // Match 5 Horizontal and Vertical
  {
    name: '5 Horizontal',
    id: 0,
    priority: 100,
    pattern: ['#####'],
    createBoostScore: 2000,
    matchType: MatchType.Five,
    boostType: BoostType.GemType
  },
  {
    name: '5 Vertical',
    id: 1,
    priority: 100,
    pattern: [
      '#',
      '#',
      '#',
      '#',
      '#'
    ],
    createBoostScore: 2000,
    matchType: MatchType.Five,
    boostType: BoostType.GemType
  },
  {
    name: 'Both Diagonals',
    id: 2,
    priority: 90,
    pattern: [
      '#.#',
      '.#.',
      '#.#',
    ],
    createBoostScore: 1000,
    matchType: MatchType.Angles,
    boostType: BoostType.NwSeNeSw
  },

  // L Shapes
  {
    name: 'L Shape - NW',
    id: 3,
    priority: 80,
    pattern: [
      '###',
      '#..',
      '#..'
    ],
    createBoostScore: 400,
    matchType: MatchType.Double,
    boostType: BoostType.Square
  },
  {
    name: 'L Shape - NE',
    id: 4,
    priority: 80,
    pattern: [
      '###',
      '..#',
      '..#'
    ],
    createBoostScore: 400,
    matchType: MatchType.Double,
    boostType: BoostType.Square
  },
  {
    name: 'L Shape - SW',
    id: 5,
    priority: 80,
    pattern: [
      '#..',
      '#..',
      '###'
    ],
    createBoostScore: 400,
    matchType: MatchType.Double,
    boostType: BoostType.Square
  },
  {
    name: 'L Shape - SE',
    id: 6,
    priority: 80,
    pattern: [
      '..#',
      '..#',
      '###'
    ],
    createBoostScore: 400,
    matchType: MatchType.Double,
    boostType: BoostType.Square
  },
  // T Shapes
  {
    name: 'T Shape - N',
    id: 7,
    priority: 80,
    pattern: [
      '###',
      '.#.',
      '.#.'
    ],
    createBoostScore: 400,
    matchType: MatchType.Double,
    boostType: BoostType.Square
  },
  {
    name: 'T Shape - S',
    id: 8,
    priority: 80,
    pattern: [
      '.#.',
      '.#.',
      '###'
    ],
    createBoostScore: 400,
    matchType: MatchType.Double,
    boostType: BoostType.Square
  },
  {
    name: 'T Shape - E',
    id: 9,
    priority: 80,
    pattern: [
      '..#',
      '###',
      '..#'
    ],
    createBoostScore: 400,
    matchType: MatchType.Double,
    boostType: BoostType.Square
  },
  {
    name: 'T Shape - W',
    id:10,
    priority: 80,
    pattern: [
      '#..',
      '###',
      '#..'
    ],
    createBoostScore: 400,
    matchType: MatchType.Double,
    boostType: BoostType.Square
  },

  // Match 4 Horizontal and Vertical
  {
    name: '4 Horizontal',
    id: 11,
    priority: 40,
    pattern: ['####'],
    createBoostScore: 300,
    matchType: MatchType.FourHorz,
    boostType: BoostType.Row
  },
  {
    name: '4 Vertical',
    id: 12,
    priority: 40,
    pattern: [
      '#',
      '#',
      '#',
      '#'
    ],
    createBoostScore: 300,
    matchType: MatchType.FourVert,
    boostType: BoostType.Column
  },

  // Square
  {
    name: '2x2 Square',
    id: MatchId.TWO_BY_TWO_SQUARE,
    priority: 60,
    pattern: [
      '##',
      '##'
    ],
    createBoostScore: 200,
    matchType: MatchType.Angles,
    boostType: BoostType.NeSw
  },

  // Match 3 Horizontal and Vertical
  {
    name: '3 Horizontal',
    id: 13,
    priority: 20,
    pattern: ['###'],
    createBoostScore: 100,
    matchType: MatchType.Three,
    boostType: BoostType.None
  },
  {
    name: '3 Vertical',
    id: 14,
    priority: 20,
    pattern: [
      '#',
      '#',
      '#'
    ],
    createBoostScore: 100,
    matchType: MatchType.Three,
    boostType: BoostType.None
  }

];

let matchPatterns: MatchPattern[] = null;


class MatchPattern {
  name: string;
  id: number;
  priority: number;
  w: number;
  h: number;
  offsets: any[];
  createBoostScore: number;
  matchType: MatchType;
  boostType: BoostType;

  constructor(def: MatchPatternDef) {
    this.name = def.name;
    this.id = def.id;
    this.priority = def.priority;
    this.createBoostScore = def.createBoostScore;
    this.matchType = def.matchType;
    this.boostType = def.boostType;

    let pattern = def.pattern;
    this.w = 0;
    this.h = pattern.length;
    this.offsets = [];

    for (let y = 0; y < pattern.length; y++) {
      let line = pattern[y];
      this.w = Math.max(this.w, line.length);

      for (let x = 0; x < line.length; x++) {
        if (line.charAt(x) == '#') {
          this.offsets.push({ x, y });
        }
      }
    }


  }
}

class Gem {
  state: GemState;
  active: Boolean;
  type: number;
  fixedType: boolean;
  boost: BoostType;
  locked: boolean;
  frozen: boolean;

  matchType: number;
  matchPriority: number;
  usedForBoostMatchBonus: boolean;

  movingTo: any;
  didAngleCheck: boolean;
  countDown: number;
  speed: number;
  canBounce: boolean;
  bounceCount: number;

  constructor(public x: number, public y: number) {
    this.init();
  }

  init() {
    this.state = GemState.None;
    this.active = false;
    this.boost = BoostType.None;
    this.locked = false;
    this.frozen = false;
    this.matchType = MatchType.None;
    this.matchPriority = -1;
    this.usedForBoostMatchBonus = false;
    this.countDown = 0;
    this.speed = 0;
    this.canBounce = false;
    this.bounceCount = 0;
  }
}

export class MatchThreeExperimental {
  // Canvas and Context
  private readonly boardCanvas: HTMLCanvasElement;
  private readonly boardCtx: CanvasRenderingContext2D;
  private readonly boardBackgroundCanvas: HTMLCanvasElement;
  private readonly boardBackgroundCtx: CanvasRenderingContext2D;

  // for the new json format
  public boardInfo: any;

  // Random number generator
  private readonly randGen = new RandomNumberGenerator();
  private seed: number;

  //Actions
  private actions: any[];

  // Updated by 1 each time update() is called
  private tickNumber = 0;
  private tickDelta = 0;

  // Using these to step through frames for testing
  public paused = false;
  private doStep = false;

  // Match 3 game state
  private state: MatchThreeState;

  // Timed or Moves game
  public gameType: MatchThreeGameType;

  // 9x9 Board
  public board: Gem[][];

  // New gems that come in from the top
  public topGems: Gem[];

  // The top y location of each column (used to bring in new gems)
  public minBoardY = [] as any;

  // Used to get neighbor's around a tile
  private readonly xDirectionOffsets = [ -1, 1, 0, 0 ];
  private readonly yDirectionOffsets = [0, 0, -1, 1];

  private bestCreateBoostScore: any = {};
  private bestGemScore: any = {};
  private bestComboScore = 0;
  private startComboScore: number;

  // Scoring
  private readonly scoringGem = 100;
  private readonly scoringGemBoosted = 200;
  private readonly scoringGemDoubleBoosted = 400;
  private readonly scoringGemSuper = 1000;

  // Scoring when exchanging boosted gems with each other
  private readonly scoringBoostToBoost = 800;
  private readonly scoringBoostToDoubleBoosted = 1500;
  private readonly scoringDoubleBoostedToDoubleBoosted = 2500;
  private readonly scoringSuperToNoBoost = 2000;
  private readonly scoringSuperToBoost = 3000;
  private readonly scoringSuperToDoubleBoosted = 5000;
  private readonly scoringSuperToSuper = 10000;

  // Scoring when the match contains all boosted gems
  private readonly scoringMatchingSingleBoost = 4000;
  private readonly scoringMatchingDoubleBoost = 8000;
  private readonly scoringMatchingSuperGem = 20000;

  // boardX and boardY are the upper left location of where the gems are drawn
  public boardX = 0;
  public boardY = 0;
  public gemWidth = 64;
  public gemHeight = 64;

  // Defining some useful gem types to be used in the code
  private readonly GEM_TYPE_NONE = -1;
  private readonly GEM_TYPE_SUPER = 6;

  // How long certain events last in 1000ths of seconds
  private readonly exchangeCountdownLength = 96;
  private readonly scoringCountdownLength = 32;
  private readonly fallCountdownLength = 64;

  private readonly blastingTypeCountdownLength = 32;
  private readonly tornadoCountdownLength = 48;

  private readonly scatterBombCountdown = 100;
  private readonly wanderingCharCountdown = 400;

  private readonly gemScoringCountdownLength = 80;

  private readonly hintCountdownLength = 3200;
  private readonly scrambleCountdownLength = 1200;
  private readonly gameOverCountdownLength = 1500;

  // How many gems the Scatter Bomb power up takes out
  private readonly numScatterBombs = 20;

  // Floating Text
  private floatingText: FloatingText[] = [];
  private readonly goodTextColor = '#00aa00';
  private readonly badTextColor = '#eb521a';
  private readonly neutralTextColor = '#ffffff';

  // Images
  private gemSpriteSheet: SpriteSheet;
  private gemSsImages: SsImage[];
  private gemParticleSsImages: SsImage[];
  private gemBoostSsImages: SsImage[];
  private gemDoubleBoostSsImages: SsImage[];

  private gemOverlaySsImages: SsImage[];

  private gemSelectedSsImage: SsImage;
  private gemLockedSsImage: SsImage;
  private gemFrozenSsImage: SsImage;
  private gemParticleLockedSsImage: SsImage;
  private gemParticleFrozenSsImage: SsImage;
  private dwarfSsImage: SsImage;
  private snowCreatureSsImage: SsImage;

  private gemBackgroundSpriteSheet: SpriteSheet;
  private gemBackgroundSsImages: SsImage[];
  private gapBackgroundSsImages: SsImage[];

  // Will move these into sprite sheet images
  private readonly noMovesScramblingMessageImage = new Image();
  private readonly tornadogMessageImage = new Image();
  private readonly scatterBombMessageImage = new Image();
  private readonly dwarfMinerMessageImage = new Image();
  private readonly snowCreatureMessageImage = new Image();
  private readonly victoryMessageImage = new Image();
  private readonly noMovesLeftMessageImage = new Image();
  private readonly timesUpMessageImage = new Image();

  private readonly skipMatchCheckActiveMessageImage = new Image();
  private readonly precisionBlastActiveMessageImage = new Image();
  private readonly lockGemActiveMessageImage = new Image();
  private readonly freezeGemActiveMessageImage = new Image();

  // Sounds
  private sfxGemBounce: number;
  private sfxGemExplosion: number;
  private sfxSuperGemCreated: number;
  private sfxApplause: number;

  // The types of gems that are on the current board
  private boardGemTypes: number[];
  private numBoardGemTypes: number;

  // Goals to win this board
  public haveGoals: boolean;
  private goalsMet: boolean;
  private goals: any;
  private goalGemsNeeded: number;

  // Scoring
  public baseScoreMultiplier: number;
  public scoreMultiplier: number;
  public score: number;
  public totalScore: number = 0;
  public wonGameBonus: number;
  public timeOrMovesBonus: number;

  // Timing and number of passes
  public movesAllowed: number;
  public movesLeft: number;
  public secondsAllowed: number;
  public timeLeft: number;

  // Stats (will go here)
  public gamesPlayed = 0;

  // Gem that is being dragged by the player
  private gemOver: Gem;
  private canSelectGem: boolean;
  private gemSelected: Gem;
  private gemHint: Gem;
  private gemHintSwap: Gem;

  // Used by the various states in various ways specific to the state
  private stateCountdownLength: number;
  private stateCountdown: number;

  // Used when a power up is active
  private powerUpType: PowerUpType;
  private powerUpStep: number = 0;
  public timeFreezeCountdown: number = 0;
  private skipMatchCheckCountdown: number = 0;

  private doingPrecisionBlast: boolean = false;
  private doingLockGem: boolean = false;
  private doingFreezeGem: boolean = false;

  // Used by wandering character power ups
  private powerUpWanderingCharfDirectionIndex: number;
  private powerUpWanderingCharChangeDirChance: number;
  private powerUpWanderingCharX: number;
  private powerUpWanderingCharY: number;
  private powerUpWanderingEdgeCounts: number;

  // Some more countdown values
  private hintCountdown: number = 0;
  private scrambleCountdown: number;
  private gameOverCountdown: number = 0;

  // Set to the two gems that are being exchanged
  private exchangingGems: Gem[] = [ null, null ];

  // Used when the various boosts are triggered or active
  private boostArray: any[] = [];
  private gemBlastLocX: number;
  private gemBlastLocY: number;
  private gemBlastRange: any;
  private gemBlastStep: number;
  private gemTypeToBlast: number;

  // Mouse location info - used when dragging gem
  private initialMouseX: number;
  private initialMouseY: number;
  private lastMouseX: number;
  public lastMouseY: number;

  // control start/pause (Kevin Z)
  protected started: boolean;

  // whether showing hint
  public showHint: boolean;

  // auto play settings
  public autoPlay: boolean = false;
  public autoPlayGames: number = 0;
  public autoPlayMoves: number = 0;
  public autoPlayScore: number = 0;
  public autoPlayWins: number = 0;

  // hopper
  public hopper: any[];
  public boardMargin: {left: 0, right: 0, top: 0, bottom: 0};

  public finishCallback: Function;

  constructor() {
    this.loadArt();

    // Our canvas and context
    this.boardCanvas = document.createElement('canvas');
    this.boardCanvas.width = this.gemWidth * 9 * boardRenderScale;
    this.boardCanvas.height = this.gemHeight * 9 * boardRenderScale;
    this.boardCtx = this.boardCanvas.getContext('2d');

    this.boardBackgroundCanvas = document.createElement('canvas');
    this.boardBackgroundCanvas.width = this.gemWidth * 9;
    this.boardBackgroundCanvas.height = this.gemHeight * 9;
    this.boardBackgroundCtx = this.boardBackgroundCanvas.getContext('2d');

    this.initMatchPatterns();

    // Download info on all the board and then allocate space
    this.allocateBoard();

    // Now let's get all the art and sound effects
    //this.loadArt();
    // this.loadSfx();

    this.state = MatchThreeState.Limbo;

    // Kevin Z
    this.started  = false;
    this.showHint = true;

    this.hopper = [];
  }

  setFinishCallback(callback:Function) {
    this.finishCallback = callback;
  }

  private getPatternOverrides(): MatchPattern[] {
    let patterns: MatchPattern[] = [];

    // Downloaded varying patterns are added here - these can vary by day
    let patternDefs = [
      {
        name: 'T Shape - N',
        id: 7,
        priority: 80,
        pattern: [
          '###',
          '.#.',
          '.#.'
        ],
        createBoostScore: 777,
        matchType: MatchType.Double,
        boostType: BoostType.Square
      }
    ];

    for (let i = 0; i < patternDefs.length; i++) {
      let pattern = new MatchPattern(patternDefs[i]);
      patterns.push(pattern);
    }

    return patterns;

  }

  private initMatchPatterns() {
    // Only need to do this once
    if (matchPatterns != null) {
      return;
    }

    matchPatterns = [];

    // Get any new or override patterns (allows us to download special ones that are only for a day or so)
    let overridePatterns = this.getPatternOverrides();
    for (let i = 0; i < overridePatterns.length; i++) {
      matchPatterns.push(overridePatterns[i]);
    }

    // Load all the match patterns
    for (let i = 0; i < matchPatternDefs.length; i++) {
      let foundMatch = false;
      for (let j = 0; !foundMatch && j < overridePatterns.length; j++) {
        foundMatch = (matchPatternDefs[i].id == overridePatterns[j].id);

      }

      if (!foundMatch) {
        let matchPattern = new MatchPattern(matchPatternDefs[i]);
        matchPatterns.push(matchPattern);
      }
    }

    // Sort by priority
    matchPatterns.sort((a, b) => {
      if (a.priority > b.priority)
        return -1;
      else if (a.priority < b.priority)
        return 1;
      return 0;
    });
  }

  setGemSize(size:number) {
    this.gemWidth = size;
    this.gemHeight = size;
    this.boardCanvas.width = this.gemWidth * 9 * boardRenderScale;
    this.boardCanvas.height = this.gemHeight * 9 * boardRenderScale;
    this.boardBackgroundCanvas.width = this.gemWidth * 9;
    this.boardBackgroundCanvas.height = this.gemHeight * 9;
    this.setBoardBackgroundImages();
  }

  /**
   * Allocates the board and topGems array.
   */
  allocateBoard() {
    this.board = [];
    for (let x = 0; x < 9; x++) {
      this.board[x] = [];
      for (let y = 0; y < 9; y++) {
        this.board[x][y] = new Gem(x, y);
      }
    }

    this.topGems = [];
    for (let x = 0; x < 9; x++) {
      this.topGems[x] = new Gem(x, -1);
      this.topGems[x].active = true;
    }
  }

  /**
   * Load all the artwork.
   */
  loadArt() {
    // HMTL Image Elements
    this.noMovesScramblingMessageImage.src = require('./assets/noMovesScramblingMessage.png');
    this.tornadogMessageImage.src = require('./assets/tornadoMessage.png');
    this.scatterBombMessageImage.src = require('./assets/scatterBombMessage.png');
    this.dwarfMinerMessageImage.src = require('./assets/dwarfMinerMessage.png');
    this.snowCreatureMessageImage.src = require('./assets/snowCreatureMessage.png');
    this.victoryMessageImage.src = require('./assets/victoryMessage.png');
    this.noMovesLeftMessageImage.src = require('./assets/noMovesLeftMessage.png');
    this.timesUpMessageImage.src = require('./assets/timesUpMessage.png');

    this.skipMatchCheckActiveMessageImage.src = require('./assets/skipMatchCheckActiveMessage.png');
    this.precisionBlastActiveMessageImage.src = require('./assets/precisionBlastActiveMessage.png');
    this.lockGemActiveMessageImage.src = require('./assets/lockGemActiveMessage.png');
    this.freezeGemActiveMessageImage.src = require('./assets/freezeGemActiveMessage.png');

    // Gem sprite sheet
    this.gemSpriteSheetCallback = this.gemSpriteSheetCallback.bind(this);
    let gemArt = require(`./assets/gems.png`);
    let gemJson = require(`./assets/gems.json`);
    this.gemSpriteSheet = Game.instance.spriteSheetManager.load(gemArt, gemJson, this.gemSpriteSheetCallback);

    // Backgrounds sprite sheet
    this.gemBackgroundSpriteSheetCallback = this.gemBackgroundSpriteSheetCallback.bind(this);
    let gemBackgroundArt = require(`./assets/backgrounds.png`);
    let gemBackgroundJson = require(`./assets/backgrounds.json`);

    this.gemBackgroundSpriteSheet =
        Game.instance.spriteSheetManager.load(gemBackgroundArt, gemBackgroundJson, this.gemBackgroundSpriteSheetCallback);
  }

  /**
   * Callback when gem sprite sheet has been loaded.
   * @param ss - Sprite sheet that was loaded
   */
  gemSpriteSheetCallback(ss: SpriteSheet) {
    let gemImageNames = [ 'gem0.png', 'gem1.png', 'gem2.png', 'gem3.png', 'gem4.png', 'gem5.png', 'gemSuper.png' ];
    let gemParticleImageNames = [
      'gemParticleBlue.png', 'gemParticleWhite.png', 'gemParticlePurple.png', 'gemParticleRed.png',
      'gemParticleYellow.png', 'gemParticleGreen.png', 'gemParticleSuper.png'
    ];

    let gemBoostImageNames = [
      'gem0Boost.png', 'gem1Boost.png', 'gem2Boost.png', 'gem3Boost.png', 'gem4Boost.png', 'gem5Boost.png',
      'gemSuperBoost.png'
    ];
    let gemDoubleBoostImageNames = [
      'gem0DoubleBoost.png', 'gem1DoubleBoost.png', 'gem2DoubleBoost.png', 'gem3DoubleBoost.png', 'gem4DoubleBoost.png',
      'gem5DoubleBoost.png', 'gemSuperDoubleBoost.png'
    ];

    let gemOverlayImageNames = [
      "boostEw.png", "boostNs.png", "boostSquare3x3.png", "boostNwSe.png", "boostNeSw.png", "boostNwSeNeSw.png"
    ];

    // Now we can get each of the individual images
    this.gemSsImages = [];
    this.gemParticleSsImages = [];
    this.gemBoostSsImages = [];
    this.gemDoubleBoostSsImages = [];
    for (let i = 0; i < gemImageNames.length; i++) {
      this.gemSsImages[i] = this.gemSpriteSheet.getSsImage(gemImageNames[i]);
      this.gemParticleSsImages[i] = this.gemSpriteSheet.getSsImage(gemParticleImageNames[i]);
      this.gemBoostSsImages[i] = this.gemSpriteSheet.getSsImage(gemBoostImageNames[i]);
      this.gemDoubleBoostSsImages[i] = this.gemSpriteSheet.getSsImage(gemDoubleBoostImageNames[i]);
    }

    this.gemOverlaySsImages = [];
    for (let i = 0; i < gemOverlayImageNames.length; i++) {
      this.gemOverlaySsImages[i] = this.gemSpriteSheet.getSsImage(gemOverlayImageNames[i]);
    }

    this.gemSelectedSsImage = this.gemSpriteSheet.getSsImage('gemSelected.png');
    this.gemLockedSsImage = this.gemSpriteSheet.getSsImage('gemLocked.png');
    this.gemFrozenSsImage = this.gemSpriteSheet.getSsImage('gemFrozen.png');
    this.gemParticleLockedSsImage = this.gemSpriteSheet.getSsImage('gemParticleLocked.png');
    this.gemParticleFrozenSsImage = this.gemSpriteSheet.getSsImage('gemParticleFrozen.png');

    this.dwarfSsImage = this.gemSpriteSheet.getSsImage('dwarf.png');
    this.snowCreatureSsImage = this.gemSpriteSheet.getSsImage('snowCreature.png');
  }

  /**
   * Called when the background sprite sheets have been loaded.
   * @param ss - Sprite sheet
   */
  gemBackgroundSpriteSheetCallback(ss: SpriteSheet) {
    let gemBackgroundSsImageNames = [
      'gemBackground0.png', 'gemBackground1.png', 'gemBackground2.png', 'gemBackground3.png', 'gemBackground4.png',
      'gemBackground5.png', 'gemBackground6.png', 'gemBackground7.png', 'gemBackground8.png', 'gemBackground9.png',
      'gemBackground10.png', 'gemBackground11.png', 'gemBackground12.png', 'gemBackground13.png', 'gemBackground14.png',
      'gemBackground15.png'
    ];
    let gapBackgroundSsImageNames = [
      null, 'gapBackground1.png', 'gapBackground2.png', 'gapBackground3.png', 'gapBackground4.png',
      'gapBackground5.png', 'gapBackground6.png', 'gapBackground7.png', 'gapBackground8.png', 'gapBackground9.png',
      'gapBackground10.png', 'gapBackground11.png', 'gapBackground12.png', 'gapBackground13.png', 'gapBackground14.png',
      'gapBackground15.png'
    ];

    // Now we can get each of the individual images
    this.gemBackgroundSsImages = [];
    this.gapBackgroundSsImages = [];
    for (let i = 0; i < 16; i++) {
      this.gemBackgroundSsImages[i] = this.gemBackgroundSpriteSheet.getSsImage(gemBackgroundSsImageNames[i]);
      this.gapBackgroundSsImages[i] = (gapBackgroundSsImageNames[i] === null)
                                          ? null
                                          : this.gemBackgroundSpriteSheet.getSsImage(gapBackgroundSsImageNames[i]);
    }
  }

  public getIsReadyToStart() {
    return (this.gemSsImages != undefined && this.gemBackgroundSsImages != undefined);
  }

  /**
   * Load all the sound effects.
   */
  loadSfx() {
    this.sfxGemBounce = SoundManager.instance.loadSound({src : [ require('./assets/gemBounce.mp3') ], volume : 0.1});
    this.sfxGemExplosion =
        SoundManager.instance.loadSound({src : [ require('./assets/gemExplosion.mp3') ], volume : 0.05});
    this.sfxSuperGemCreated =
        SoundManager.instance.loadSound({src : [ require('./assets/superGemCreated.mp3') ], volume : 0.15});
    this.sfxApplause = SoundManager.instance.loadSound({src : [ require('./assets/applause.mp3') ], volume : 0.2});
  }

  /**
   * Toggles whether sound effects between on and off.
   */
  public toggleSfxOn() {
    SoundManager.instance.sfxOn = !SoundManager.instance.sfxOn;
    // this.saveSettingsToCookie();
  }

  /**
   * Toggles whether hint between on and off.
   */
  public toggleHint() {
    this.showHint = !this.showHint;
    // this.saveSettingsToCookie();
  }

  /**
   * Returns true if this is a multi-player game.
   */
  // public isMultiplayer() {
  //   return Site.room.isActive();
  // }

  /**
   * Returns true if this is a timed game.
   */
  public isTimedGame() {
    return (this.gameType === MatchThreeGameType.Timed);
  }

  private storeAction(action: any) {
    action.tickNumber = this.tickNumber;
    action.timeLeft = this.timeLeft;
    this.actions.push(action);
  }

  /**
   * Called from Game Class when a game is starting
   * @param boardInfo - Board Info for this game.
   */
  public startGame(boardInfo: any) {
    this.started = true;

    this.seed = Game.instance.getInitialSeed();

    this.randGen.seed(this.seed);

    // Store all game actions here
    this.actions = [];
    this.storeAction({ type: 'rand-seed', seed: this.seed });

    this.boardInfo = boardInfo;

    if (Game.instance.gameMode == GameMode.Arcade) {
      this.gameType = MatchThreeGameType.Timed;
      this.timeLeft = boardInfo.minutes * 60000;
      this.secondsAllowed = Math.floor(this.timeLeft / 1000);
      this.movesAllowed = 0;
    }
    else if (Game.instance.gameMode == GameMode.Adventure) {
      this.gameType = MatchThreeGameType.Moves;
      this.movesLeft = boardInfo.moves;
      //if(this.autoPlay)
      //  this.movesLeft = 50;
      this.secondsAllowed = 0;
      this.movesAllowed = this.movesLeft;
    }
    else if (Game.instance.gameMode == GameMode.Experimental) {
      this.gameType = MatchThreeGameType.Moves; // MatchThreeGameType.Timed;
      //this.timeLeft = boardInfo.minutes * 60000;
      this.secondsAllowed = 0;  //Math.floor(this.timeLeft / 1000);
      this.movesLeft = 30;
      this.movesAllowed = this.movesLeft;
    }
    else if (Game.instance.gameMode == GameMode.Daily) {
      this.gameType = MatchThreeGameType.Moves;
      this.movesLeft = 20; //boardInfo.moves;
      this.secondsAllowed = 0;
      this.movesAllowed = this.movesLeft;
    }

    // Final initializations before starting a game
    this.initVars();
    this.convertBoardInfo(boardInfo);
    // this.initGoals(result);
    this.initBoard(boardInfo);

    if (Game.instance.gameMode == GameMode.Experimental) {
      this.testingSetup();
    }

    // if (Site.room.isActive())
    //   Site.room.setLocalPlayerState('score', 0);
  }

  private convertBoardInfo(boardInfo: any) {
    // First store off all the types of gems that can be on this board
    this.boardGemTypes = [];

    if(boardInfo.goals) {
      let keys = Object.keys(boardInfo.goals);
      for(let i = 0; i < keys.length; i++) {
        let gt = GemColors.indexOf(keys[i]);
        if(gt != -1)
          this.boardGemTypes.push(gt);
      }
    }

    while(this.boardGemTypes.length < boardInfo.colors) {
      let color = this.randGen.getRandRange(0, 5);
      while (this.boardGemTypes.includes(color))
        color = this.randGen.getRandRange(0, 5);

      this.boardGemTypes.push(color);
    }

    this.numBoardGemTypes = this.boardGemTypes.length;

    // convert goals info
    // let goalColor: number[] = [];
    // let goalTypes: any[] = [];

    // for (let i = 0; i < boardInfo.goals.length; i++) {
    //   let goals = boardInfo.goals[i].charAt(0);

    //   if (goals == '+') {
    //     let color = this.randomInteger(0, 5);
    //     while (!this.boardGemTypes.includes(color) || goalColor.includes(color)) {
    //       color = this.randomInteger(0, 5);
    //     }

    //     goalColor.push(color);
    //     let val = {type: GemColor.get(color), amount: boardInfo.goals[i].substring(1)};
    //     goalTypes.push(val);
    //   }
    //   else {
    //     let val = {type: "Locked", amount: boardInfo.goals[i].substring(1)};
    //     goalTypes.push(val);
    //   }
    // }

    // return { goals: goalTypes }
  }

  /**
   * Called whenever a new game of Match 3 is started to init various vars.
   */
  public initVars() {
    this.score = 0;
    this.baseScoreMultiplier = 1;
    this.scoreMultiplier = this.baseScoreMultiplier;

    this.bestCreateBoostScore.score = 0;
    this.bestGemScore.score = 0;
    this.bestComboScore = 0;

    this.timeFreezeCountdown = 0;
    this.skipMatchCheckCountdown = 0;

    this.doingPrecisionBlast = false;
    this.doingLockGem = false;
    this.doingFreezeGem = false;

    this.gemOver = null;
    this.gemSelected = null;
    this.gemHint = null;
    this.gemHintSwap = null;

    this.lastMouseX = 0;
    this.lastMouseY = 0;

    this.state = MatchThreeState.Limbo;
  }

  public finish() {
    this.setState(MatchThreeState.Limbo);
  }

  /**
   * Returns a random gem type from the types of gems that are on this board.
   */
  getRandomGemType(): number {
    return this.boardGemTypes[this.randGen.getRandMod(this.numBoardGemTypes)];
  }

  getGemTypeFromHopper(): number {
    if(this.hopper.length == 0 || this.isGameWon())
      return this.boardGemTypes[this.randGen.getRandMod(this.numBoardGemTypes)];

    let i = Math.floor(Math.random() * this.hopper.length);
    return this.hopper[i];
  }

  /**
   * Builds the background image for this board based upon which gem cells are
   * active.
   */
  setBoardBackgroundImages() {
    this.boardBackgroundCtx.clearRect(0, 0, this.boardBackgroundCanvas.width, this.boardBackgroundCanvas.height);

    for (let x = 0; x < 9; x++) {
      for (let y = 0; y < 9; y++) {
        // Gem location we are setting background for

        // State of the gems around this gem
        // Brute force - oh well
        let gemNW = this.getGem(x - 1, y - 1);
        let gemN = this.getGem(x, y - 1);
        let gemNE = this.getGem(x + 1, y - 1);
        let gemE = this.getGem(x + 1, y);
        let gemSE = this.getGem(x + 1, y + 1);
        let gemS = this.getGem(x, y + 1);
        let gemSW = this.getGem(x - 1, y + 1);
        let gemW = this.getGem(x - 1, y);

        let gemNClear = (gemN === null || !gemN.active);
        let gemEClear = (gemE === null || !gemE.active);
        let gemSClear = (gemS === null || !gemS.active);
        let gemWClear = (gemW === null || !gemW.active);

        let gem = this.board[x][y];
        if (!gem.active) {
          let gapBackgroundIndex = 0;
          // bit 0
          if (gemNW !== null && gemNW.active) {
            if (!gemWClear && !gemNClear) {
              gapBackgroundIndex |= 1;
            }
          }
          // bit 1
          if (gemNE !== null && gemNE.active) {
            if (!gemNClear && !gemEClear) {
              gapBackgroundIndex |= 2;
            }
          }
          // bit 2
          if (gemSE !== null && gemSE.active) {
            if (!gemEClear && !gemSClear) {
              gapBackgroundIndex |= 4;
            }
          }
          // bit 3
          if (gemSW !== null && gemSW.active) {
            if (!gemSClear && !gemWClear) {
              gapBackgroundIndex |= 8;
            }
          }

          if (gapBackgroundIndex !== 0) {
            let ssImage = this.gapBackgroundSsImages[gapBackgroundIndex];
            ssImage.drawToCtx(this.boardBackgroundCtx, x * this.gemWidth, y * this.gemHeight, this.gemWidth,
                              this.gemHeight);
          }
        }
        else {
          let gemBackgroundIndex = 0;
          // bit 0
          if (gemWClear && gemNClear) {
            gemBackgroundIndex |= 1;
          }
          // bit 1
          if (gemNClear && gemEClear) {
            gemBackgroundIndex |= 2;
          }
          // bit 2
          if (gemEClear && gemSClear) {
            gemBackgroundIndex |= 4;
          }
          // bit 3
          if (gemSClear && gemWClear) {
            gemBackgroundIndex |= 8;
          }

          // Got it
          let ssImage = this.gemBackgroundSsImages[gemBackgroundIndex];

          ssImage.drawToCtx(this.boardBackgroundCtx, x * this.gemWidth, y * this.gemHeight, this.gemWidth,
                            this.gemHeight);
        }
      }
    }
  }

  /**
   * Sets the minimun y location for each row in the board.
   */
  setMinBoardY() {
    for (let x = 0; x < 9; x++) {
      for (let y = 0; y < 9; y++) {
        if (this.board[x][y].active) {
          this.minBoardY[x] = y;
          this.topGems[x].y = y - 1;
          break;
        }
      }
    }
  }

  isRowEmpty(row:number) {
    for (let x = 0; x < 9; x++)
      if (this.board[x][row].active)
        return false;
    return true;
  }

  isColumnEmpty(col:number) {
    for (let y = 0; y < 9; y++)
      if (this.board[col][y].active)
        return false;
    return true;
  }

  setBoardMargins() {
    this.boardMargin = {left: 0, right: 0, top: 0, bottom: 0};

    for (let y = 0; y < 9; y++) {
      if(this.isRowEmpty(y))
        this.boardMargin.top++;
      else
        break;
    }

    for (let y = 8; y >= 0; y--) {
      if(this.isRowEmpty(y))
        this.boardMargin.bottom++;
      else
        break;
    }

    for (let x = 0; x < 9; x++) {
      if(this.isColumnEmpty(x))
        this.boardMargin.left++;
      else
        break;
    }

    for (let x = 8; x >= 0; x--) {
      if(this.isColumnEmpty(x))
        this.boardMargin.right++;
      else
        break;
    }

    this.boardMargin.left *= this.gemWidth;
    this.boardMargin.right *= this.gemWidth;
    this.boardMargin.top *= this.gemWidth;
    this.boardMargin.bottom *= this.gemWidth;
  }

  /**
   * Initializes the board based upon the passed in board info.
   * @param boardInfo - Board info
   */
  initBoard(boardInfo: any) {
    // For testing - just seeing how long board creation takes
    let prevTick = performance.now();

    // Now go through each of the cell locations setting the values based upon
    // the char at each location
    let activeGemCount = 0;
    let board = boardInfo.board;
    for (let x = 0; x < 9; x++) {
      for (let y = 0; y < 9; y++) {
        let char = board[y].charAt(x);
        let gem = this.board[x][y];
        gem.init();

        gem.active = (char !== '.');

        if(gem.active)
          activeGemCount++;

        if (gem.active) {
          switch (char) {
            case '~':
            case '#':
              // Empty or Frozen
              gem.type = this.GEM_TYPE_NONE;
              gem.fixedType = true;
              break;

            case 'B':
            case 'b':
              // Blue
              gem.type = 0;
              gem.fixedType = true;
              gem.locked = (char === 'B');
              break;

            case 'W':
            case 'w':
              // White
              gem.type = 1;
              gem.fixedType = true;
              gem.locked = (char === 'W');
              break;

            case 'P':
            case 'p':
              // Purple
              gem.type = 2;
              gem.fixedType = true;
              gem.locked = (char === 'P');
              break;

            case 'R':
            case 'r':
              // Red
              gem.type = 3;
              gem.fixedType = true;
              gem.locked = (char === 'R');
              break;

            case 'Y':
            case 'y':
              // Yellow
              gem.type = 4;
              gem.fixedType = true;
              gem.locked = (char === 'Y');
              break;

            case 'G':
            case 'g':
              // Green
              gem.type = 5;
              gem.fixedType = true;
              gem.locked = (char === 'G');
              break;

            case 'S':
            case 's':
              // Super
              gem.type = this.GEM_TYPE_SUPER;
              gem.fixedType = true;
              gem.locked = (char === 'S');
              break;

            default:
              // Random gem type
              gem.type = this.getRandomGemType();
              gem.fixedType = false;
              break;
          }

          // Set whether gem is locked or frozen
          gem.locked = gem.locked || (char === 'X');
          gem.frozen = gem.frozen || (char === '#')
          gem.matchType = MatchType.None;
          gem.matchPriority = -1;
        }
      }
    }

    // build goals
    if (Game.instance.gameMode == GameMode.Adventure || Game.instance.gameMode == GameMode.Daily) {
      this.goals = [];
      this.goalsMet = false;
      this.haveGoals = true;
      this.goalGemsNeeded = 0;

      let keys = Object.keys(boardInfo.goals);
      for(let i = 0; i < keys.length; i++) {
        let goal = {gemType: keys[i], amount: boardInfo.goals[keys[i]]};
        this.goalGemsNeeded += goal.amount;
        this.goals.push(goal);
      }
    }

    // Now we can set the minimum y locations and the background image
    this.setMinBoardY();
    this.setBoardMargins();
    this.setBoardBackgroundImages();

    // Make sure board starts with no matches
    let retries = 0;
    let badBoard = true;
    while (badBoard) {
      let foundMatches = this.findMatches(false);

      this.getHintGem();

      // Board is bad if there is a match or no possible move to get a match
      badBoard = (foundMatches || this.gemHint === null)

      // We will keep trying until we get a good board.
      if (badBoard) {
        retries++;
        for (let x = 0; x < 9; x++) {
          for (let y = 0; y < 9; y++) {
            let gem = this.board[x][y];
            gem.boost = BoostType.None;
            if (gem.active) {
              gem.matchType = MatchType.None;
              gem.matchPriority = -1;
              if (!gem.fixedType) {
                // Randomly change non-fixed gem types
                gem.type = this.getRandomGemType();
              }
            }
          }
        }
      }
    }

    // build the hopper
    this.hopper = [];

    if (Game.instance.gameMode == GameMode.Adventure || Game.instance.gameMode == GameMode.Daily) {
      for(let i  = 0; i < this.boardGemTypes.length; i++)
        this.hopper.push(this.boardGemTypes[i]);

      for(let i = 0; i < this.goals.length; i++) {
        let gemType = GemColors.indexOf(this.goals[i].gemType);
        if(gemType != -1) {
          this.hopper.push(gemType);
          this.hopper.push(gemType);
        }
      }

      for(let i = 0; i < 100; i++) {
        let r1 = Math.floor(Math.random() * this.hopper.length);
        let r2 = Math.floor(Math.random() * this.hopper.length);
        let n = this.hopper[r1];
        this.hopper[r1] = this.hopper[r2];
        this.hopper[r2] = n;
      }

      // console.log('Hopper = ', JSON.stringify(this.hopper));
    }

    // We are now all set to go
    this.setState(MatchThreeState.PlayerInput);
  }

  // This is for debugging - setting up various scenarios on the board
  private testingSetup() {

    //this.board[1][1].type = this.GEM_TYPE_SUPER;
    //this.board[2][1].type = this.GEM_TYPE_SUPER;
    //this.board[3][0].type = this.GEM_TYPE_SUPER;
    //this.board[3][2].type = this.GEM_TYPE_SUPER;
    //this.board[3][3].type = this.GEM_TYPE_SUPER;

    //this.board[1][1].boost = BoostType.Square;
    //this.board[2][1].boost = BoostType.Column;
    //this.board[3][0].boost = BoostType.Row;

    return;


    // L shape
    this.board[1][1].type = 0;
    this.board[2][1].type = 0;
    this.board[3][1].type = 5;
    this.board[3][2].type = 0;
    this.board[3][3].type = 0;
    this.board[3][0].type = 0;

    this.board[4][0].type = 1;
    this.board[4][1].type = 4;
    this.board[5][1].type = 1;
    this.board[6][1].type = 1;
    this.board[4][2].type = 1;
    this.board[4][3].type = 1;

    return;
    this.board[1][1].type = 6;
    this.board[2][2].type = 4;
    this.board[2][0].type = 6;
    this.board[3][0].type = 6;

    this.board[2][0].type = 5;

    //
    this.board[3][1].type = 1;
    this.board[4][1].type = 3;
    this.board[5][1].type = 3;
    this.board[5][2].type = 3;
    this.board[6][3].type = 3;

    this.board[3][0].type = 3;
  }

  /**
   * Returns the goals array.
   */
  public getGoals(): any[] {
    return this.goals;
  }

  /**
   * Remove all the floating text from the screen.
   */
  removeAllFloatingText() {
    this.floatingText = [];
  }

  /**
   * Adds the passed in value to the base score and creates a floating text of
   * the amount to display.
   * @param amount - Amount to add
   */
  public addToScore(amount: number, x: number, y: number) {
    this.score += amount;

    let floatingText = (amount >= 0) ? `+${amount}` : `${amount}`;
    let fillStyle = (amount >= 0) ? this.neutralTextColor : this.badTextColor;
    let fontSize = Math.floor(this.gemWidth / 2.5);
    let ft = new FloatingText(floatingText, x, y, fillStyle, 900, fontSize, true);
    this.floatingText.push(ft);

    // if (Site.room.isActive())
    //   Site.room.setLocalPlayerState('score', this.score);
  }

  /**
   * Returns true if the player has won the game.
   */
  isGameWon(): boolean {
    return (this.haveGoals && this.goalsMet);
  }

  /**
   * Called when a game is lost.
   */
  handleLoss() {
    this.wonGameBonus = 0;
    this.timeOrMovesBonus = 0;
    this.totalScore += this.score;
  }

  /**
   * Called from the Game Results screen when a game is won.
   */
  handleWin() {
    this.wonGameBonus = this.goalGemsNeeded * 16;
    this.timeOrMovesBonus = this.isTimedGame() ? Math.floor(this.timeLeft / 10) : this.movesLeft * 20;
    this.totalScore += this.score + this.wonGameBonus + this.timeOrMovesBonus;
    SoundManager.instance.playSound(this.sfxApplause);
  }

  /**
   * Goes through the board and scores the gems that have matched.
   */
  processBoardForScore(): boolean {
    for (let x = 0; x < 9; x++) {
      for (let y = this.minBoardY[x]; y < 9; y++) {
        let gem = this.board[x][y];
        if (gem.active && gem.matchType !== MatchType.None) {
          this.scoreGem(gem);
          this.setStateCountdown(this.scoringCountdownLength);

          return true;
        }
      }
    }
    return false;
  }

  /**
   * Set the the state countdown and also how long this countdown is.
   * @param stateCountdown - State countdown value to set
   */
  setStateCountdown(stateCountdown: number) {
    this.stateCountdown = stateCountdown;
    this.stateCountdownLength = this.stateCountdown;
  }

  /**
   * Adds the passed in boost to the boost array.
   * @param boostInfo - Boost
   */
  addToBoostArray(boostInfo: any) {
    this.boostArray.push(boostInfo);
  }

  /**
   * Starts the Tornado power up.
   */
  startPowerUpTornado() {
    this.setStateCountdown(0);
    this.powerUpStep = this.randGen.getRandRange(40, 50);
  }

  /**
   * Starts the Scatter Bomb power up.
   */
  startPowerUpScatterBomb() {
    this.setStateCountdown(this.scatterBombCountdown);
    this.powerUpStep = this.numScatterBombs;
  }

  /**
   * Starts the wandering character power up. Currently used by Dwarf Miner and
   * Snow Creature.
   */
  startPowerUpWanderingChar() {
    let foundLoc = false;
    while (!foundLoc) {
      let x = 2 + this.randGen.getRandMod(5);
      let y = 2 + this.randGen.getRandMod(5);
      let gem = this.getActiveGem(x, y);
      if (gem !== null && gem.type !== this.GEM_TYPE_NONE) {
        foundLoc = true;
        this.powerUpWanderingCharX = x;
        this.powerUpWanderingCharY = y;
        if (this.powerUpType === PowerUpType.DwarfMiner) {
          this.powerUpWanderingCharChangeDirChance = 15;
          this.scoreGem(gem, false);
        }
        else if (this.powerUpType === PowerUpType.SnowCreature) {
          this.powerUpWanderingCharChangeDirChance = 5;
          gem.frozen = true;
        }
      }
    }

    // Choosing a random starting direction index
    this.powerUpWanderingEdgeCounts = 0;
    this.powerUpWanderingCharfDirectionIndex = this.randGen.getRandMod(4);
    this.setStateCountdown(this.wanderingCharCountdown);
  }

  /**
   * Starts the Time Freeze power up.
   */
  startPowerUpTimeFreeze() {
    this.timeLeft += 30000;
    this.setState(MatchThreeState.PlayerInput);
  }

  /**
   * Starts the Extra Turns power up.
   */
  startPowerUpExtraTurns() {
    let asset = Database.getAsset('matcha-extra-turns');
    this.movesLeft += asset.moves;
    this.setStateCountdown(0);
    this.setState(MatchThreeState.PlayerInput);
  }

  private startPowerUpPrecisionBlast() {
    this.doingPrecisionBlast = true;
    this.setState(MatchThreeState.PlayerInput);
  }

  private startPowerUpTransformGem() {

  }

  private startPowerUpSkipMatchCheck() {
    this.skipMatchCheckCountdown++;
    this.setState(MatchThreeState.PlayerInput);
  }

  private startPowerUpLockGem() {
    this.doingLockGem = true;
    this.setState(MatchThreeState.PlayerInput);
  }

  private startPowerUpFreezeGem() {
    this.doingFreezeGem = true;
    this.setState(MatchThreeState.PlayerInput);
  }

  /**
   * Called to start the passed in power up.
   * @param powerUpType - Power up to start
   */
  startPowerUp(id: string) {
    // Only one of these can be active
    if (this.skipMatchCheckCountdown > 0 || this.doingPrecisionBlast || this.doingLockGem || this.doingFreezeGem) {
      return;
    }

    this.scoreMultiplier = this.baseScoreMultiplier;

    this.storeAction({
      type : 'startPowerUp',
      id: id
    });

    this.setState(MatchThreeState.PowerUp);

    switch (id) {
      case 'matcha-tornado':
        this.powerUpType = PowerUpType.Tornado;
        this.startPowerUpTornado();
        break;

      case 'matcha-scatter-bomb':
        this.powerUpType = PowerUpType.ScatterBomb;
        this.startPowerUpScatterBomb();
        break;

      case 'matcha-dwarf-miner':
        this.powerUpType = PowerUpType.DwarfMiner;
        this.startPowerUpWanderingChar();
        break;

      case 'matcha-time-freeze':
        this.powerUpType = PowerUpType.TimeFreeze;
        this.startPowerUpTimeFreeze();
        break;

      case 'matcha-precision-blast':
        this.powerUpType = PowerUpType.PrecisionBlast;
        this.startPowerUpPrecisionBlast();
        break;

      case 'matcha-transform-gem':
        this.powerUpType = PowerUpType.TransformGem;
        this.startPowerUpTransformGem();
        break;

      case 'matcha-skip-match-check':
        this.powerUpType = PowerUpType.SkipMatchCheck;
        this.startPowerUpSkipMatchCheck();
        break;

      case 'matcha-lock-gem':
        this.powerUpType = PowerUpType.LockGem;
        this.startPowerUpLockGem();
        break;

      case 'matcha-freeze-gem':
        this.powerUpType = PowerUpType.FreezeGem;
        this.startPowerUpFreezeGem();
        break;

        // case 'matcha-snow-creatures':
        //   this.powerUpType = PowerUpType.SnowCreature;
        //   this.startPowerUpWanderingChar();
        //   break;

        // case PowerUpType.ExtraTurns:
        //   this.startPowerUpExtraTurns();
        //   break;
    }
  }

  /**
   * Called when a power up button is pressed.
   * @param buttonNumber - Button number pressed
   * @param x - Which slot is pressed
   */

  isPlayerInputState():boolean {
    return (this.state == MatchThreeState.PlayerInput);
  }

  /**
   * Called to start scramble of board. Called when no moves are available.
   */
  startScramble() {
    this.setStateCountdown(0);
    this.scrambleCountdown = this.scrambleCountdownLength;
    this.setState(MatchThreeState.NoMovesScramble);
  }

  private getMostFrequentGemType(): number {
    let count:number[] = [0,0,0,0,0,0,0];

    for (let x = 0; x < 9; x++) {
      for (let y = 0; y < 9; y++) {
        let gem = this.board[x][y];
        if (gem.type != this.GEM_TYPE_NONE) {
          count[gem.type]++;
        }
      }
    }

    let gemType = 0;
    let maxCount = count[0];
    for (let i = 1; i <= 5; i++) {
      if (count[i] > maxCount) {
        gemType = i;
        maxCount = count[i];
      }
    }

    return gemType;
  }

  /**
   * Called to start boosting.
   */
  startBoosting(): boolean {
    // If boost array is empty then nothing to do
    if (this.boostArray.length === 0) {
      return false;
    }

    // Get first boost from the boost array and start it
    let boostInfo = this.boostArray.shift();
    switch (boostInfo.boostType) {
      case BoostType.GemType:
        let gemType = this.getMostFrequentGemType();
        //this.startBlastingGemType(boostInfo.gemType);
        this.startBlastingGemType(gemType);
        break;

      case BoostType.Row:
        this.startBlastingRow(boostInfo.y, boostInfo.range);
        break;

      case BoostType.Column:
        this.startBlastingColumn(boostInfo.x, boostInfo.range);
        break;

      case BoostType.Square:
        this.startBlastingSquare(boostInfo.x, boostInfo.y, boostInfo.range);
        break;

      case BoostType.NwSe:
        this.startBlastingNwSe(boostInfo.x, boostInfo.y, boostInfo.range);
        break;

      case BoostType.NeSw:
        this.startBlastingNeSw(boostInfo.x, boostInfo.y, boostInfo.range);
        break;

      case BoostType.NwSeNeSw:
        this.startBlastingNwSeNeSw(boostInfo.x, boostInfo.y, boostInfo.range);
    }

    return true;
  }

  /**
   * Start blasting by gem type.
   * @param gemType - Gem type to blast
   */
  startBlastingGemType(gemType: number) {
    this.setState(MatchThreeState.BlastingType);
    this.gemTypeToBlast = gemType;
    this.setStateCountdown(this.blastingTypeCountdownLength);
  }

  /**
   * Starts blasting a row.
   * @param y - Row to blast
   * @param range - Range to blast (0 just one row, 1 - three rows, ect.)
   */
  startBlastingRow(y: number, range: number) {
    this.setState(MatchThreeState.BlastingRow);
    this.gemBlastLocY = y;
    this.gemBlastRange = range;
    this.gemBlastStep = 0;
    this.setStateCountdown(this.blastingTypeCountdownLength);
  }

  /**
   * Starts blasting a column.
   * @param x - Column to blast.
   * @param range - Range to blast (0 just one column, 1 - three columns, etc.)
   */
  startBlastingColumn(x: number, range: number) {
    this.setState(MatchThreeState.BlastingColumn);
    this.gemBlastLocX = x;
    this.gemBlastRange = range;
    this.gemBlastStep = 0;
    this.setStateCountdown(this.blastingTypeCountdownLength);
  }

  /**
   * Starts blasting a square area.
   * @param x - X location of center of blast
   * @param y - Y location of center of blast
   * @param range - Range to blast (0 - 1x1 area, 1 - 3x3 area, etc.)
   */
  startBlastingSquare(x: number, y: number, range: number) {
    this.setState(MatchThreeState.BlastingSquare);
    this.gemBlastLocX = x;
    this.gemBlastLocY = y;
    this.gemBlastRange = range;
    this.gemBlastStep = 0;
    this.setStateCountdown(this.blastingTypeCountdownLength);
  }


  /**
   * Starts blasting a diagonal NW to SE.
   * @param x - X location of center of blast
   * @param y - Y location of center of blast
   * @param range - Range to blast (0 - 1 gem, 1 - 3 gems, etc.)
   */
  startBlastingNwSe(x: number, y: number, range: number) {
    this.setState(MatchThreeState.BlastingNwSe);
    this.gemBlastLocX = x;
    this.gemBlastLocY = y;
    this.gemBlastRange = range;
    this.gemBlastStep = 0;
    this.setStateCountdown(this.blastingTypeCountdownLength);
  }


    /**
   * Starts blasting a diagonal NW to SE.
   * @param x - X location of center of blast
   * @param y - Y location of center of blast
   * @param range - Range to blast (0 - 1 gem, 1 - 3 gems, etc.)
   */
  startBlastingNeSw(x: number, y: number, range: number) {
    this.setState(MatchThreeState.BlastingNeSw);
    this.gemBlastLocX = x;
    this.gemBlastLocY = y;
    this.gemBlastRange = range;
    this.gemBlastStep = 0;
    this.setStateCountdown(this.blastingTypeCountdownLength);
  }


      /**
   * Starts blasting a diagonal NW to SE.
   * @param x - X location of center of blast
   * @param y - Y location of center of blast
   * @param range - Range to blast (0 - 1 gem, 1 - 3 gems, etc.)
   */
  startBlastingNwSeNeSw(x: number, y: number, range: number) {
    this.setState(MatchThreeState.BlastingNwSeNeSw);
    this.gemBlastLocX = x;
    this.gemBlastLocY = y;
    this.gemBlastRange = range;
    this.gemBlastStep = 0;
    this.setStateCountdown(this.blastingTypeCountdownLength);
  }

  /**
   * Starts scoring matching gems.
   */
  startScoring(): boolean {
    // Check for a match
    if (this.findMatches(true)) {
      // Found a match so move on to scoring
      this.setState(MatchThreeState.Scoring);
      this.setStateCountdown(this.scoringCountdownLength);

      return true;
    }

    return false;
  }

  /**
   * Starts vertical falling of gems.
   * @param gemsToCheck - Set to gems that will need additional testing.
   */
  startRegularFalling(gemsToCheck: Gem[]): boolean {
    let anythingFalling = false;
    for (let y = 8; y >= 0; y--) {
      for (let x = 0; x < 9; x++) {
        if (y >= this.minBoardY[x]) {
          let gem = this.board[x][y];
          if (gem.active && !gem.frozen && (gem.type === this.GEM_TYPE_NONE || gem.state === GemState.Moving)) {
            let atTop = (y === this.minBoardY[x])
            let movingGem = atTop ? this.topGems[x] : this.board[x][y - 1];
            if (atTop) {
              movingGem.type = this.getGemTypeFromHopper();
            }

            if (movingGem.active && movingGem.type !== this.GEM_TYPE_NONE) {
              anythingFalling = true;
              movingGem.matchType = MatchType.None;
              movingGem.state = GemState.Moving;
              movingGem.canBounce = true;
              movingGem.movingTo = {x, y};
            }
            else if (!movingGem.active || movingGem.frozen) {
              gemsToCheck.push(gem);
            }
          }
        }
      }
    }

    return anythingFalling;
  }

  /**
   * Starts angled falling of gems.
   * @param gemsToCheck - Gems that need to be checked for angled falling.
   */
  startAngleFalling(gemsToCheck: Gem[]): boolean {
    let anythingFalling = false;
    for (let i = 0; i < gemsToCheck.length; i++) {
      let gem = gemsToCheck[i];
      if (!gem.didAngleCheck) {
        while (gem !== null) {
          gem.didAngleCheck = true;
          let xOffset = (gem.x < 5) ? -1 : 1;
          let x = gem.x + xOffset;
          for (let i = 0; i < 2; i++) {
            if (x >= 0 && x < 9) {
              if (gem.y - 1 >= this.minBoardY[x]) {
                let movingGem = this.board[x][gem.y - 1];
                if (movingGem.active && movingGem.type !== this.GEM_TYPE_NONE && movingGem.state !== GemState.Moving) {
                  anythingFalling = true;
                  movingGem.matchType = MatchType.None;
                  movingGem.state = GemState.Moving;
                  movingGem.canBounce = true;
                  movingGem.movingTo = {x : gem.x, y : gem.y};
                  gem = null;
                  break;
                }
              }
            }
            x = gem.x - xOffset;
          }

          // Didn't find it so try the gem to the south
          if (gem !== null) {
            gem = (gem.state !== GemState.Moving && gem.y < 8) ? this.board[gem.x][gem.y + 1] : null;
            if (gem === null || !gem.active || gem.type !== this.GEM_TYPE_NONE || gem.frozen) {
              gem = null;
            }
          }
        }
      }
    }

    return anythingFalling;
  }

  /**
   * Starts the falling of gems - vertical and angled.
   */
  startFalling(): boolean {
    let anythingFalling = false;

    // gemsToCheck will be set to the gems that will also need an angled falling
    // check
    let gemsToCheck = [] as any;
    if (this.startRegularFalling(gemsToCheck)) {
      anythingFalling = true;
    }

    // Set that we haven't yet done angled checks
    for (let i = 0; i < gemsToCheck.length; i++) {
      gemsToCheck[i].didAngleCheck = false;
    }

    // Now do the angled falling check
    // let needAngleCheck = true;
    // while (needAngleCheck) {
    //  needAngleCheck = false;

    if (this.startAngleFalling(gemsToCheck)) {
      anythingFalling = true;
    }
    //}

    // If anything is falling then we are in Falling state
    if (anythingFalling) {
      this.setState(MatchThreeState.Falling);
      this.setStateCountdown(this.fallCountdownLength);
    }

    return anythingFalling;
  }

  /**
   * Complete falling of gems and move them into their new board cell.
   */
  completeFalling() {
    // Go through the board and take care of all the falling gems
    for (let y = 7; y >= 0; y--) {
      for (let x = 0; x < 9; x++) {
        if (y >= this.minBoardY[x]) {
          let gem = this.board[x][y];
          if (gem.state === GemState.Moving) {
            this.exchangeGems(gem, this.board[gem.movingTo.x][gem.movingTo.y]);
            gem.state = GemState.None;
          }
        }
      }
    }

    // Bring in the top gems too
    for (let x = 0; x < 9; x++) {
      let topGem = this.topGems[x];
      if (topGem.state === GemState.Moving) {
        this.exchangeWithTopGem(topGem, this.board[topGem.movingTo.x][topGem.movingTo.y]);
        topGem.state = GemState.None;
      }
    }
  }

  /**
   * Game is over so start clearing out and scoring the boosted gems.
   */
  startEndingGame() {
    for (let x = 0; x < 9; x++) {
      for (let y = this.minBoardY[x]; y < 9; y++) {
        let gem = this.board[x][y];
        if (gem.type === this.GEM_TYPE_SUPER || gem.boost !== BoostType.None) {
          this.scoreGem(gem, false);
        }
      }
    }

    this.scoreMultiplier = (Game.instance.gameMode == GameMode.Adventure || Game.instance.gameMode == GameMode.Daily) ? 1.0 : 0.5;
    if (!this.startBoosting()) {
      this.setState(MatchThreeState.GameOver);
    }
  }

  /**
   * Handle the Scramble power up.
   * @param deltaTick - How much time has passed
   */
  handlePowerUpScramble(deltaTick: number) {
    this.handleStateScramble(deltaTick);
  }

  /**
   * Handle the Tornado power up.
   */
  handlePowerUpTornado(): boolean {
    if (this.powerUpStep === 0) {
      return false;
    }
    this.powerUpStep--;
    this.setStateCountdown(this.tornadoCountdownLength);

    // The gems move in a spiral pattern around the board
    let xByLeg = [ 0, 1, 0, -1 ];
    let yByLeg = [ 1, 0, -1, 0 ];
    let minVal = 3;
    let maxVal = 5;
    while (minVal >= 0) {
      let leg = 0;
      let x = minVal;
      let y = minVal;
      let prevX = minVal;
      let prevY = minVal;
      let firstGem = this.getActiveGem(x, y);
      let gem = null;
      let legCount = maxVal - minVal;
      while (leg !== 4) {
        while (gem === null && leg !== 4) {
          if ((y === maxVal && leg === 0) || (x === maxVal && leg === 1) || (y === minVal && leg === 2) ||
              (x === (minVal + 1) && leg === 3)) {
            leg++;
            legCount = maxVal - minVal;
          }

          if (leg !== 4) {
            legCount--;
            x += xByLeg[leg];
            y += yByLeg[leg];
            legCount--;
            gem = this.getActiveGem(x, y);
          }
          else {
            gem = firstGem;
          }
        }

        if (firstGem === null) {
          firstGem = gem;
        }
        else {
          this.board[prevX][prevY] = gem;
          gem.x = prevX;
          gem.y = prevY;
        }
        gem = null;
        prevX = x;
        prevY = y;
      }

      minVal--;
      maxVal++;
    }

    return true;
  }

  /**
   * Handle the Scatter Bomb power up.
   */
  handlePowerUpScatterBomb(): boolean {
    if (this.powerUpStep === 0) {
      return false;
    }
    this.powerUpStep--;
    this.setStateCountdown(this.scatterBombCountdown);

    let foundGem = false;
    while (!foundGem) {
      let x = this.randGen.getRandMod(9);
      let y = this.randGen.getRandMod(9);
      let gem = this.getActiveGem(x, y);
      if (gem !== null && (gem.frozen || gem.type !== this.GEM_TYPE_NONE)) {
        if (gem.frozen) {
          this.clearLockedAndFrozen(gem.x, gem.y, false);
          SoundManager.instance.playSound(this.sfxGemExplosion);
        }
        else {
          this.scoreGem(gem, false);
          gem.matchType = MatchType.None;
        }
        foundGem = true;
      }
    }

    return true;
  }

  /**
   * Handle the Wandering Character power up.
   */
  turnMiner() {
    let prevDirection = this.powerUpWanderingCharfDirectionIndex;

    let reverse = 0;
    if(prevDirection == 0) reverse = 1;
    if(prevDirection == 1) reverse = 0;
    if(prevDirection == 2) reverse = 3;
    if(prevDirection == 3) reverse = 2;

    let directions = [];
    for(let i = 0; i < 4; i++) {
      if(i == prevDirection) continue;
      if(i == reverse) continue;
      let px = this.powerUpWanderingCharX + this.xDirectionOffsets[i];
      let py = this.powerUpWanderingCharY + this.yDirectionOffsets[i];
      let nextGem = this.getActiveGem(px, py);
      if(nextGem)
        directions.push(i);
    }

    if(directions.length == 0) {
      this.powerUpWanderingCharfDirectionIndex = reverse;
    }
    else {
      let r = this.randGen.getRandMod(directions.length);
      this.powerUpWanderingCharfDirectionIndex = directions[r];
    }
  }

  handlePowerUpWanderingChar(): boolean {
    this.powerUpWanderingCharX += this.xDirectionOffsets[this.powerUpWanderingCharfDirectionIndex];
    this.powerUpWanderingCharY += this.yDirectionOffsets[this.powerUpWanderingCharfDirectionIndex];

    let gem = this.getActiveGem(this.powerUpWanderingCharX, this.powerUpWanderingCharY);
    if (gem !== null) {
      if (this.powerUpType === PowerUpType.SnowCreature) {
        gem.type = this.GEM_TYPE_NONE;
        gem.frozen = true;
      }
      else {
        if (gem.frozen) {
          this.clearLockedAndFrozen(gem.x, gem.y, false);
          SoundManager.instance.playSound(this.sfxGemExplosion);
        }
        else if (gem.type !== this.GEM_TYPE_NONE) {
          this.scoreGem(gem, false);
        }
      }

      this.setStateCountdown(this.wanderingCharCountdown);

      let randomTurn = (this.randGen.getRandMod(100) < this.powerUpWanderingCharChangeDirChance);

      let px = this.powerUpWanderingCharX + this.xDirectionOffsets[this.powerUpWanderingCharfDirectionIndex];
      let py = this.powerUpWanderingCharY + this.yDirectionOffsets[this.powerUpWanderingCharfDirectionIndex];
      let nextGem = this.getActiveGem(px, py);
      if(!nextGem || randomTurn) {
        this.powerUpWanderingEdgeCounts++;
        if(this.powerUpWanderingEdgeCounts < 3)
          this.turnMiner();
      }

      return true;
    }

    return false;
  }

  /**
   * Called to handle the currently active power up.
   * @param deltaTick - How much time has passed
   */
  handleStatePowerUp(deltaTick: number) {
    switch (this.powerUpType) {
      case PowerUpType.Tornado:
        if (!this.handlePowerUpTornado()) {
          if (!this.startFalling()) {
            if (!this.startScoring()) {
              this.setState(MatchThreeState.PlayerInput);
            }
          }
        }
        break;

      case PowerUpType.ScatterBomb:
        if (!this.handlePowerUpScatterBomb()) {
          if (!this.startFalling()) {
            if (!this.startScoring()) {
              this.setState(MatchThreeState.PlayerInput);
            }
          }
        }
        break;

      case PowerUpType.DwarfMiner:
      case PowerUpType.SnowCreature:
        if (!this.handlePowerUpWanderingChar()) {
          if (!this.startFalling()) {
            if (!this.startScoring()) {
              this.setState(MatchThreeState.PlayerInput);
            }
          }
        }
        break;
    }
  }

  /**
   * Handle the Scramble state.
   * @param deltaTick - How much time has passed
   */
  handleStateScramble(deltaTick: number) {
    this.scrambleCountdown -= deltaTick;
    if (this.scrambleCountdown < 0) {
      this.scrambleCountdown = 0;
      if (!this.startScoring()) {
        this.setState(MatchThreeState.PlayerInput);
      }
      return;
    }

    for (let x1 = 0; x1 < 9; x1++) {
      for (let y1 = this.minBoardY[x1]; y1 < 9; y1++) {
        let gem1 = this.getActiveGem(x1, y1);
        if (gem1 !== null && gem1.type !== this.GEM_TYPE_NONE) {
          let gem2 = null;
          let tries = 6;
          while (gem2 === null && tries !== 0) {
            tries--;
            let x2 = this.randGen.getRandMod(9);
            let y2 = this.randGen.getRandRange(this.minBoardY[x1], 9);
            gem2 = this.getActiveGem(x2, y2);
            if (gem2 !== null) {
              if (gem2.type === this.GEM_TYPE_NONE) {
                gem2 = null;
              }
            }
            if (gem2 != null) {
              this.board[x1][y1] = gem2;
              gem2.x = x1;
              gem2.y = y1;
              let temp = gem2.locked;
              gem2.locked = gem1.locked;
              this.board[x2][y2] = gem1;
              gem1.x = x2;
              gem1.y = y2;
              gem1.locked = temp;
            }
          }
        }
      }
    }
  }

  /**
   * Handle the exchanging one or two Super gems.
   */
  handleStateExchangingSuper() {
    let gem0 = this.exchangingGems[0];
    let gem1 = this.exchangingGems[1];

    let scoreToAdd = this.scoringSuperToNoBoost;
    let name = '';
    if (gem0.type === this.GEM_TYPE_SUPER && gem1.type === this.GEM_TYPE_SUPER) {
      scoreToAdd = this.scoringSuperToSuper;
      name = 'Super Gem to Super Gem';
    }
    else if (gem0.boost === BoostType.Square || gem1.boost === BoostType.Square) {
      scoreToAdd = this.scoringSuperToDoubleBoosted;
      name = 'Super Gem to Boosted Gem';
    }
    else if (gem0.boost !== BoostType.None || gem1.boost !== BoostType.None) {
      scoreToAdd = this.scoringSuperToBoost;
      name = 'Super Gem to Regular Gem';
    }

    // Add points for this exchange type
    this.addScoreAtGemLoc(gem0, scoreToAdd, false);
    this.addScoreAtGemLoc(gem1, scoreToAdd, false);

    this.checkBestGemScore(scoreToAdd, name);

    // Setting the type of gem to blast
    let blastType =
        (this.exchangingGems[0].type === this.GEM_TYPE_SUPER) ? this.exchangingGems[1].type : this.exchangingGems[0].type;
    this.startBlastingGemType(blastType);

    // Reset the exchanging gems
    for (let i = 0; i < 2; i++) {
      let gem = this.exchangingGems[i];
      if (gem.type === this.GEM_TYPE_SUPER) {
        gem.type = this.GEM_TYPE_NONE;
        gem.matchType = MatchType.None;
      }
      this.setGemStateNone(this.exchangingGems[i]);
    }
    this.exchangingGems = [ null, null ];

    // Blast the 1st one right away
    this.blastGemType(blastType);
  }

  /**
   * Handle exchanging of boosted gems to each other.
   */
  handleStateExchangingBoostToBoost() {
    let gemIsDouble = [];
    let gems = []
    for (let i = 0; i < 2; i++) {
      gems[i] = this.exchangingGems[i];
      gemIsDouble[i] = this.getIsDoubleBoost(gems[i]);
    }

    let bothDouble = (gemIsDouble[0] && gemIsDouble[1]);
    let anyDouble = (gemIsDouble[0] || gemIsDouble[1]);
    let sameBoosts = (gems[0].boost == gems[1].boost);

    let scoreToAdd = this.scoringBoostToBoost;
    if (bothDouble) {
      scoreToAdd = this.scoringDoubleBoostedToDoubleBoosted;
    }
    else if (anyDouble) {
      scoreToAdd = this.scoringBoostToDoubleBoosted;
    }

    // Compute the range of the boost
    let range = 0;
    if (bothDouble) {
      range = 2;
    }
    else if (anyDouble) {
      range = 1;
    }

    for (let i = 0; i < 2; i++) {
      // Add points for this exchange type
      this.addScoreAtGemLoc(gems[i], scoreToAdd, true);

      // Now set up for the boosts the gems make happen
      let rangeAdj = (gems[i].boost == BoostType.Square) ? 1 : 0;
      this.addToBoostArray({boostType: gems[i].boost, x: gems[i].x, y : gems[i].y, range: range + rangeAdj});
    }

    this.checkBestGemScore(scoreToAdd, 'Boosted Gem to Boosted Gem');

    this.resetExchangingGems();

    // Switch to boosting state
    this.startBoosting();
  }

  private resetExchangingGems() {
    // Reset the exchanging gems
    for (let i = 0; i < 2; i++) {
      let gem = this.exchangingGems[i];
      gem.type = this.GEM_TYPE_NONE;
      gem.matchType = MatchType.None;
      gem.boost = BoostType.None;
    }
    this.exchangingGems = [ null, null ];
  }

  /**
   * Handle the Exchanging Gems state.
   */
  handleStateExchanging() {
    // Exchange gems' position on board
    this.scoreMultiplier = this.baseScoreMultiplier;

    // For tracking combo scoring
    this.startComboScore = this.score;

    let gem0 = this.exchangingGems[0];
    let gem1 = this.exchangingGems[1];

    let gem0Type = gem0.type;
    let gem1Type = gem1.type;

    this.exchangeGems(gem0, gem1);

    // Special case for the super gem
    //if (gem0.type === this.GEM_TYPE_SUPER || gem1.type === this.GEM_TYPE_SUPER) {
    //  if (this.skipMatchCheckCountdown > 0) {
    //    this.skipMatchCheckCountdown--;
    //  }
    //  else {
    //    this.handleStateExchangingSuper();
    //    this.movesLeft--;
    //  }

    //  return;
    //}

    // Now check if exchanging boosted gems with each other
    //if (gem0.boost !== BoostType.None && gem1.boost !== BoostType.None) {
    //  if (this.skipMatchCheckCountdown > 0) {
    //    this.skipMatchCheckCountdown--;
    //  }
    //  else {
    //    this.handleStateExchangingBoostToBoost();
    //    this.movesLeft--;
    //  }

    //  return;
    //}

    if (this.skipMatchCheckCountdown > 0) {
      this.skipMatchCheckCountdown--;

      this.setGemStateNone(this.exchangingGems[0]);
      this.setGemStateNone(this.exchangingGems[1]);

      this.exchangingGems = [null, null];

      this.setState(MatchThreeState.PlayerInput);

      return;
    }

    // Check for a matches
    let gotMatch = (this.startScoring());

    if (gotMatch) {
    //  this.setGemStateNone(this.exchangingGems[0]);
    //  this.setGemStateNone(this.exchangingGems[1]);

      //this.exchangingGems = [ null, null ];
      //this.movesLeft--;
    }

    //else {
      // Special case for the super gem
    if (gem0Type === this.GEM_TYPE_SUPER || gem1Type === this.GEM_TYPE_SUPER) {
        gotMatch = true;
        this.handleStateExchangingSuper();
        //this.movesLeft--;

        //return;
      }

      // Now check if exchanging boosted gems with each other
    else if (gem0.boost !== BoostType.None && gem1.boost !== BoostType.None) {
      gotMatch = true;
        this.handleStateExchangingBoostToBoost();
        //this.movesLeft--;

        //return;
      }

    // No match so start the reverse of the exchange
    if (gotMatch) {
      this.setGemStateNone(gem0);
      this.setGemStateNone(gem1);

      this.exchangingGems = [ null, null ];
      this.movesLeft--;
    }
    else {

      this.startReverseExchange();
    }

    //}
  }

  /**
   * Handle the Reverse Exchanging state. Active when an exchange to trigger
   * that doesn't cause a match.
   */
  handleStateReverseExchanging() {
    // Exchange the gems' back to their original position on board
    this.exchangeGems(this.exchangingGems[1], this.exchangingGems[0]);

    // Turn off gems moving state
    for (let i = 0; i < 2; i++) {
      this.setGemStateNone(this.exchangingGems[i]);
    }

    // Don't want gem hint to change in this specific case
    let gemHint = this.gemHint;
    let gemHintSwap = this.gemHintSwap;

    this.setState(MatchThreeState.PlayerInput);

    this.gemHint = gemHint;
    this.gemHintSwap = gemHintSwap;
  }

  /**
   * Handles the Scoring state.
   */
  handleStateScoring() {
    if (!this.processBoardForScore()) {
      if (!this.startBoosting()) {
        if (!this.startFalling()) {
          //if (!this.processBoardForScore()) {
          this.checkBestComboScore(this.score - this.startComboScore);
            this.setState(MatchThreeState.PlayerInput);
          }
          //else {
          //  this.setStateCountdown(this.scoringCountdownLength);
          //}
        }
      }
    //}
    else {
      this.setStateCountdown(this.scoringCountdownLength);
    }
  }

  /**
   * Handles the Falling state.
   */
  handleStateFalling() {
    this.completeFalling();
    if (!this.startFalling()) {
      // Score multiplier increases for each chain reaction
      if (this.scoreMultiplier < 1.50)
        this.scoreMultiplier = (this.scoreMultiplier == 1.0) ? 1.05 : this.scoreMultiplier + 0.05;

      //console.log(`  **** Bonus Mult: ${this.scoreMultiplier}`);


      if (this.boostArray.length !== 0) {
        this.startBoosting();
      }
      else if (!this.startScoring()) {
        this.checkBestComboScore(this.score - this.startComboScore);
        this.setState(MatchThreeState.PlayerInput);
      }
    }
  }

  /**
   * Called when a boost has ended.
   */
  handleEndOfBoost() {
    //if (this.state == MatchThreeState.BlastingType) {
      if (!this.startFalling()) {
          if (!this.startBoosting()) {
            this.setState(MatchThreeState.PlayerInput);
          }
      }

      return;
    //}

    if (!this.startBoosting()) {
      if (!this.startFalling()) {
        this.setState(MatchThreeState.PlayerInput);
      }
    }
  }

  /**
   * Handles Blast Gem Type state.
   */
  handleStateBlastGemType() {
    if (this.blastGemType(this.gemTypeToBlast)) {
      this.handleEndOfBoost();
    }
    else {
      this.setStateCountdown(this.blastingTypeCountdownLength);
    }
  }

  /**
   * Handles Blast Row state.
   */
  handleStateBlastRow() {
    if (this.blastRow(this.gemBlastLocY, this.gemBlastRange)) {
      this.handleEndOfBoost();
    }
    else {
      this.setStateCountdown(this.blastingTypeCountdownLength);
    }
  }

  /**
   * Handles Blast Column state.
   */
  handleStateBlastColumn() {
    if (this.blastColumn(this.gemBlastLocX, this.gemBlastRange)) {
      this.handleEndOfBoost();
    }
    else {
      this.setStateCountdown(this.blastingTypeCountdownLength);
    }
  }

  /**
   * Handles Blast Square state.
   */
  handleStateBlastSquare() {
    if (this.blastSquare(this.gemBlastLocX, this.gemBlastLocY, this.gemBlastRange)) {
      this.handleEndOfBoost();
    }
    else {
      this.setStateCountdown(this.blastingTypeCountdownLength);
    }
  }

  /**
   * Handles Blast Diagonal NW to SE state.
   */
  handleStateBlastNwSe() {
    if (this.blastNwSe(this.gemBlastLocX, this.gemBlastLocY, this.gemBlastRange)) {
      this.handleEndOfBoost();
    }
    else {
      this.setStateCountdown(this.blastingTypeCountdownLength);
    }
  }

  /**
   * Handles Blast Diagonal NE to SW state.
   */
  handleStateBlastNeSw() {
    if (this.blastNeSw(this.gemBlastLocX, this.gemBlastLocY, this.gemBlastRange)) {
      this.handleEndOfBoost();
    }
    else {
      this.setStateCountdown(this.blastingTypeCountdownLength);
    }
  }

    /**
   * Handles Blast Diagonal NE to SW state.
   */
  handleStateBlastNwSeNeSw() {
    if (this.blastNwSeNeSw(this.gemBlastLocX, this.gemBlastLocY, this.gemBlastRange)) {
      this.handleEndOfBoost();
    }
    else {
      this.setStateCountdown(this.blastingTypeCountdownLength);
    }
  }

  /**
   * Routes calls to handle the current state.
   * @param deltaTick - How much time has passed.
   */
  handleStates(deltaTick: number) {
    // Makes next call to handle state when state countdown is zero
    this.stateCountdown -= deltaTick;
    if (this.stateCountdown > 0) {
      return;
    }
    this.stateCountdown = 0;

    // Switch to call the correct state (todo - function jump table here)
    switch (this.state) {
      case MatchThreeState.PowerUp:
        this.handleStatePowerUp(deltaTick);
        break;

      case MatchThreeState.NoMovesScramble:
        this.handleStateScramble(deltaTick);
        break;

      case MatchThreeState.Exchanging:
        this.handleStateExchanging();
        break;

      case MatchThreeState.ReverseExchanging:
        this.handleStateReverseExchanging();
        break;

      case MatchThreeState.Scoring:
        this.handleStateScoring();
        break;

      case MatchThreeState.Falling:
        this.handleStateFalling();
        break;

      case MatchThreeState.BlastingType:
        this.handleStateBlastGemType();
        break;

      case MatchThreeState.BlastingRow:
        this.handleStateBlastRow();
        break;

      case MatchThreeState.BlastingColumn:
        this.handleStateBlastColumn();
        break;

      case MatchThreeState.BlastingSquare:
        this.handleStateBlastSquare();
        break;

      case MatchThreeState.BlastingNwSe:
        this.handleStateBlastNwSe();
        break;

      case MatchThreeState.BlastingNeSw:
        this.handleStateBlastNeSw();
        break;

      case MatchThreeState.BlastingNwSeNeSw:
        this.handleStateBlastNwSeNeSw();
        break;
    }
  }

  /**
   * Sets state to the passed in value.
   * @param state - State to set
   */
  setState(state: MatchThreeState) {
    if (this.state !== state) {
      if (state === MatchThreeState.PlayerInput) {
        if(Game.instance.isMultiplayer())
          Server.places.setScore(this.score, Game.instance.getPlaceHost());

        this.getHintGem();

        if (this.gemHint === null) {
          this.startScramble();
        }
        else {
          if(this.autoPlay)
            this.hintCountdown = 500;
          else
            this.hintCountdown = this.hintCountdownLength;
          this.state = state;
        }
      }
      else {
        if (state !== MatchThreeState.Exchanging && state !== MatchThreeState.ReverseExchanging) {
          this.gemHint = null;
          this.gemHintSwap = null;
        }
        this.state = state;
      }
    }
  }

  /**
   * Handles gem bounce. Just adjusts drawing loc to make gems look like they
   * have a little bounce when they finish falling.
   */
  private handleBounce() {
    for (let x = 0; x < 9; x++) {
      for (let y = this.minBoardY[x]; y < 9; y++) {
        let gem = this.board[x][y];
        if (gem.state === GemState.Moving && this.state !== MatchThreeState.Exchanging &&
            this.state !== MatchThreeState.ReverseExchanging) {
          let belowGem = this.getGem(gem.x, gem.y + 2);
          let canBounce = (belowGem === null);
          if (!canBounce) {
            canBounce = (belowGem.frozen || belowGem.type !== this.GEM_TYPE_NONE) &&
                        (belowGem.state !== GemState.Moving && gem.canBounce);
          }
          if (canBounce) {
            gem.bounceCount = 12;
          }
          else {
            gem.bounceCount = 0;
          }
        }
      }
    }
  }

  /**
   * Updates the hint countdown.
   * @param deltaTick - How much time has passed.
   */
  private handleHint(deltaTick: number) {
    if (this.hintCountdown >= 0) {
      let hc = this.hintCountdown;

      if (this.showHint)
        hc -= deltaTick;

      if (hc < 0)
        hc = 0;

      if(this.hintCountdown > 0 && hc == 0 && this.autoPlay) {
        setTimeout(() => {
          this.autoSwap();
        }, 500);
      }

      this.hintCountdown = hc;
    }
  }

  protected autoSwap() {
    if(this.state != MatchThreeState.PlayerInput || !this.gemHint || !this.gemHintSwap)
      return;

    this.startExchange(this.gemHint, this.gemHintSwap);
  }

  /**
   * Updates the time left value.
   * @param deltaTick - How much time has passed.
   */
  private handleTimeLeft(deltaTick: number) {
    // Stop counting down when the game is won
    if (this.isGameWon()) {
      return;
    }

    // Freeze power up pauses the time left countdown
    if (this.timeFreezeCountdown > 0 && this.powerUpStep == 0) {
      this.timeFreezeCountdown -= deltaTick;
      if (this.timeFreezeCountdown < 0) {
        this.timeFreezeCountdown = 0;
      }
    }

    let pauseTimer = 
      (this.powerUpStep > 0 && this.stateCountdown > 0) || 
      (this.timeFreezeCountdown > 0) ||
      (this.powerUpType === PowerUpType.DwarfMiner && this.stateCountdown > 0);

    if (!pauseTimer) {
      if (this.state !== MatchThreeState.Limbo && this.state !== MatchThreeState.GameOver && this.timeLeft !== 0) {
        this.timeLeft -= deltaTick;
        if (this.timeLeft < 0) {
          this.timeLeft = 0;
        }
      }
    }
  }

  /**
   * Handles the floating text.
   */
  private handleFloatingText() {
    for (let i = this.floatingText.length - 1; i >= 0; i--) {
      if (this.floatingText[i].update()) {
        this.floatingText.splice(i, 1);
      }
    }
  }

  /**
   * Handles the end of game stuff.
   * @param deltaTick - How much time has passed.
   */
  private handleGameOver(deltaTick: number) {
    if (this.floatingText.length === 0 && this.state === MatchThreeState.GameOver) {
    if (this.gameOverCountdown > 0) {
        this.gameOverCountdown -= deltaTick;
      if (this.gameOverCountdown <= 0) {

        // Best scores to Console
        if (this.bestGemScore.score > 0) {
          console.log(` Best Gem Score: ${this.bestGemScore.score}  Type: ${this.bestGemScore.name}`);
        }
        if (this.bestCreateBoostScore.score > 0) {
          console.log(` Best Boost Score: ${this.bestCreateBoostScore.score}  Type: ${this.bestCreateBoostScore.name}`);
        }
        if (this.bestComboScore > 0) {
          console.log(` Best Combo Score: ${this.bestComboScore}`);
        }


          this.gameOverCountdown = 0;
          this.setState(MatchThreeState.Limbo);
          if(this.autoPlay) {
            if(this.isGameWon()){
              this.autoPlayWins++;
              }
            let totalMoves = this.movesAllowed - this.movesLeft;
            this.autoPlayGames++;
            this.autoPlayMoves += totalMoves;
            this.autoPlayScore += this.score;
            let avgMoves = this.autoPlayMoves / this.autoPlayGames;
            let avgScore = this.autoPlayScore / this.autoPlayGames;
            let avgWin = this.autoPlayWins / this.autoPlayGames;
            // console.log(`GAME ${this.autoPlayGames}: ${totalMoves} ${this.score}  (${avgMoves.toFixed(1)} ${avgScore.toFixed(0)} ${(avgWin*100).toFixed(0)}%)`)
            setTimeout(() => {
              Game.instance.startGame(this.boardInfo);
            }, 2000);
          }
          else if(Game.instance.gameMode == GameMode.Adventure && !this.isGameWon())
            this.askToContinue();
          else
            this.finishAndShowResults();
        }
      }
      else {
        this.gameOverCountdown = this.gameOverCountdownLength;
      }
    }
  }

  protected askToContinue() {
    let coins = Server.assets.getInventoryQuantity('coin');
    let pups = 0; //Server.assets.getInventoryQuantity('matcha-extra-turns');
    let asset = Database.getAsset('matcha-extra-turns');
    let shopAsset = Database.getShopAsset('matcha-extra-turns');

    if(pups > 0) {
      Game.instance.askQuestion('Use your Extra Moves Powerup\nfor 10 additional moves?', (response:string)=>{
        if(response == 'Yes')
          this.useExtraTurnsPowerupAndContinue();
        else
          this.finishAndShowResults();
      })
    }
    else if(coins > shopAsset.price) {
      Game.instance.askQuestion(`Purchase ${asset.moves} extra moves for ${shopAsset.price} coins?\nYou have ${coins} coins.`, (response:string)=>{
        if(response == 'Yes')
          this.purchaseExtraTurnsPowerupAndContinue();
        else
          this.finishAndShowResults();
      })
    }
    else
      this.finishAndShowResults();
  }

  protected finishAndShowResults() {
    this.finishCallback();
  }

  protected async useExtraTurnsPowerupAndContinue() {
    Game.instance.showMessage('Adding moves...');
    let response = await Server.assets.consumeAsset('matcha-extra-turns', 1);
    Game.instance.hideMessage();

    if(response.success)
      this.startPowerUpExtraTurns();
    else
      Game.instance.showAlert(response.message, ()=>{
        this.finishAndShowResults();
      });
  }

  protected async purchaseExtraTurnsPowerupAndContinue() {
    Game.instance.showMessage('Purchasing moves...');
    let response = await Server.assets.purchaseAsset('matcha-extra-turns', 1);
    Game.instance.hideMessage();

    if(response.success) {
      await Server.user.incrementCounter('purchase#matcha-extra-turns', 1);
      await Server.assets.consumeAsset('matcha-extra-turns', 1);
      let price = Database.getShopAsset('matcha-extra-turns').price;
      Server.assets.removeAssetFromInventory('coin', price);
      this.startPowerUpExtraTurns();
    }
    else {
      Game.instance.showAlert(response.message, ()=>{
        this.finishAndShowResults();
      });
    }
  }


  /**
   * Called from game tick.
   * @param deltaTick - How much time has passed since last call
   */
  public update(deltaTick: number) {
    if (!this.started)
      return;

    // Using pause and doStep to allow single step of frames for testing
    if (this.paused && !this.doStep && this.state !== MatchThreeState.PlayerInput) {
      return;
    }
    this.doStep = false;

    this.tickDelta += deltaTick;
    this.tickNumber = Math.floor(this.tickDelta / 20);

    Game.instance.particleManager.update(deltaTick);

    if (this.state !== MatchThreeState.PlayerInput) {
      this.handleStates(deltaTick);
    }
    else if (this.isGameWon()) {
      this.startEndingGame();

      return;
    }
    else {
      // console.log(this.isTimedGame(), this.timeLeft, this.movesLeft);
      let gameOver = ((this.isTimedGame() && this.timeLeft === 0) || (!this.isTimedGame() && this.movesLeft === 0));
      if (gameOver) {
        if (Game.instance.gameMode != GameMode.Adventure && Game.instance.gameMode != GameMode.Daily) {
          this.startEndingGame();
        }
        else {
          this.setState(MatchThreeState.GameOver);
        }
        return;
      }
    }

    this.handleBounce();
    this.handleHint(deltaTick);
    this.handleTimeLeft(deltaTick);
    this.handleFloatingText();

    this.handleGameOver(deltaTick);
  }

  /**
   * Draw everything on the Solitaire play area.
   */
  public drawAll() {
    this.drawBoard();
    Game.instance.particleManager.draw();
    this.drawFloatingText();
    this.drawMessages();
  }

  /**
   * Returns the location that the gem should be drawn at.
   * @param gem - Gem
   */
  getGemDrawLoc(gem: Gem): any {
    // This gem's draw location
    let x = this.gemWidth * gem.x;
    let y = this.gemHeight * gem.y;

    // Adjust the gem's draw location if it is moving
    if (gem.state === GemState.Moving) {
      let otherX = this.gemWidth * gem.movingTo.x;
      let otherY = this.gemHeight * gem.movingTo.y;

      let percent = 1 - this.stateCountdown / this.stateCountdownLength;
      x += percent * (otherX - x);
      y += percent * (otherY - y);
    }
    else if (gem.bounceCount !== 0) {
      gem.bounceCount--;

      let overPercent = Math.sin(gem.bounceCount * Math.PI / 15) * 0.1;

      y -= overPercent * this.gemHeight;
    }

    return {x, y};
  }

  /**
   * Draws the passed in gem at the size percentage.
   * @param gem - Gem to draw
   * @param sizePercent - Percentage size to draw gem
   */
  drawGem(gem: Gem, sizePercent: number) {
    if (gem.active && gem.type !== this.GEM_TYPE_NONE) {
      // Get the correct gem image to draw
      let ssImage = this.gemSsImages[gem.type];
      if (this.getIsSingleBoost(gem)) {
        ssImage = this.gemBoostSsImages[gem.type];
      }
      else if (this.getIsDoubleBoost(gem)) {
        ssImage = this.gemDoubleBoostSsImages[gem.type];
      }

      let scrn = this.getGemDrawLoc(gem);
      let width = this.gemWidth * sizePercent;
      let height = this.gemHeight * sizePercent;

      // Adjust draw location to take into account size changes from base size
      let adjScrnX = scrn.x - (width - this.gemWidth) / 2;
      let adjScrnY = scrn.y - (height - this.gemHeight) / 2;
      ssImage.drawToCtx(this.boardCtx, adjScrnX, adjScrnY, width, height);

      // Draw a boost overlay if needed
      if (gem.boost == BoostType.Row) {
        this.gemOverlaySsImages[0].drawToCtx(this.boardCtx, adjScrnX, adjScrnY, width, height);
      }
      else if (gem.boost == BoostType.Column) {
        this.gemOverlaySsImages[1].drawToCtx(this.boardCtx, adjScrnX, adjScrnY, width, height);
      }
      else if (gem.boost == BoostType.Square) {
        this.gemOverlaySsImages[2].drawToCtx(this.boardCtx, adjScrnX, adjScrnY, width, height);
      }
      else if (gem.boost == BoostType.NwSe) {
        this.gemOverlaySsImages[3].drawToCtx(this.boardCtx, adjScrnX, adjScrnY, width, height);
      }
      else if (gem.boost == BoostType.NeSw) {
        this.gemOverlaySsImages[4].drawToCtx(this.boardCtx, adjScrnX, adjScrnY, width, height);
      }
      else if (gem.boost == BoostType.NwSeNeSw) {
        this.gemOverlaySsImages[5].drawToCtx(this.boardCtx, adjScrnX, adjScrnY, width, height);
      }

      if (this.gemSelected === gem) {
        this.gemSelectedSsImage.drawToCtx(this.boardCtx, scrn.x, scrn.y, this.gemWidth, this.gemHeight);
      }
    }
  }

  /**
   * Draws the passed in message image. Just a quick hack for now to get needed
   * info on screen for user.
   * @param image - Message image to draw
   * @param baseSizeX - Image's base width
   * @param baseSizeY - Image's base height
   * @param yOffset - yOffset in gem heights (ie 3 means gemHeight * 3)
   */
  drawMessageImage(image: HTMLImageElement, baseSizeX: number, baseSizeY: number, yOffset: number = 3) {
    let sizePercent = 1; // + Math.abs(60 - (this.tickNumber % 120)) / 580;
    let sizeX = baseSizeX * sizePercent;
    let sizeY = baseSizeY * sizePercent;
    let scrnX = this.boardX + (this.gemWidth * 4.5) - (sizeX / 2);
    let scrnY = this.boardY + (this.gemHeight * yOffset) - (sizeY / 2);
    UIManager.ctx.drawImage(image, scrnX, scrnY, sizeX, sizeY);
  }

  /**
   * Draws text message of the currently active power up.
   */
  drawMessagePowerUp() {
    switch (this.powerUpType) {
      case PowerUpType.Tornado:
        this.drawMessageImage(this.tornadogMessageImage, 256, 64);
        break;

      case PowerUpType.ScatterBomb:
        this.drawMessageImage(this.scatterBombMessageImage, 256, 96);
        break;

      case PowerUpType.DwarfMiner:
        this.drawMessageImage(this.dwarfMinerMessageImage, 256, 64);
        break;

      case PowerUpType.SnowCreature:
        this.drawMessageImage(this.snowCreatureMessageImage, 256, 96);
        break;
    }
  }

  /**
   * Draws needed message depending upon state or current game status.
   */
  drawMessages() {
    if (this.state === MatchThreeState.PowerUp) {
      this.drawMessagePowerUp();
    }
    else if (this.state === MatchThreeState.NoMovesScramble) {
      this.drawMessageImage(this.noMovesLeftMessageImage, 256, 96);
    }
    else if (this.skipMatchCheckCountdown > 0) {
      this.drawMessageImage(this.skipMatchCheckActiveMessageImage, 354, 34, -0.1);
    }
    else if (this.doingPrecisionBlast) {
      this.drawMessageImage(this.precisionBlastActiveMessageImage, 384, 48, -0.1);
    }
    else if (this.doingLockGem) {
      this.drawMessageImage(this.lockGemActiveMessageImage, 384, 48, -0.1);
    }
    else if (this.doingFreezeGem) {
      this.drawMessageImage(this.freezeGemActiveMessageImage, 400, 48, -0.1);
    }
    else if (this.isGameWon()) {
      this.drawMessageImage(this.victoryMessageImage, 256, 64);
    }
    else if (this.isTimedGame()) {
      if (this.timeLeft === 0) {
        this.drawMessageImage(this.timesUpMessageImage, 256, 64);
      }
    }
    else if (this.movesLeft === 0) {
      this.drawMessageImage(this.noMovesLeftMessageImage, 256, 64);
    }
  }

  /**
   * Draws the board.
   */
  public drawBoard() {
    // Board background
    UIManager.ctx.drawImage(this.boardBackgroundCanvas, this.boardX, this.boardY, this.gemWidth * 9,
                            this.gemHeight * 9);

    this.boardCtx.clearRect(0, 0, this.boardCanvas.width, this.boardCanvas.height);

    this.gemWidth *= boardRenderScale;
    this.gemHeight *= boardRenderScale;

    // New gems coming in through the top - need to clip the gem draw to top of
    // board
    if (this.state === MatchThreeState.Falling) {
      for (let x = 0; x < 9; x++) {
        if (this.topGems[x].state === GemState.Moving) {
          let gem = this.topGems[x];
          this.boardCtx.save();
          this.boardCtx.rect(x * this.gemWidth,
                             (gem.y + 1) * this.gemHeight,
                             this.gemWidth,
                             this.gemHeight);
          this.boardCtx.clip();
          this.drawGem(this.topGems[x], 1);
          this.boardCtx.restore();
        }
      }
    }

    // Using this to animate sizes of Hint gem bitmaps
    let sizePercent = 1 + Math.abs(15 - (this.tickNumber % 30)) / 110;

    // The gems currently on the board
    let shouldPlayBounceSound = false;
    for (let x = 0; x < 9; x++) {
      for (let y = this.minBoardY[x]; y < 9; y++) {
        let gem = this.board[x][y];
        let percentToUse = (this.hintCountdown === 0 && gem === this.gemHint) ? sizePercent : 1;
        this.drawGem(gem, percentToUse);
        if (gem.state !== GemState.Moving && gem.bounceCount === 11) {
          shouldPlayBounceSound = true;
        }
      }
    }

    // Probably not the best place for this
    if (shouldPlayBounceSound) {
      SoundManager.instance.playSound(this.sfxGemBounce);
    }

    // Locked image over gems
    for (let x = 0; x < 9; x++) {
      for (let y = this.minBoardY[x]; y < 9; y++) {
        let gem = this.board[x][y];
        if (gem.active) {
          let scrnX = this.gemWidth * x;
          let scrnY = this.gemHeight * y;
          if (gem.locked) {
            this.gemLockedSsImage.drawToCtx(this.boardCtx, scrnX, scrnY, this.gemWidth, this.gemHeight);
          }
          else if (gem.frozen) {
            this.gemFrozenSsImage.drawToCtx(this.boardCtx, scrnX, scrnY, this.gemWidth, this.gemHeight);
          }
        }
      }
    }

    // Power Up Wandering Creature
    if (this.state == MatchThreeState.PowerUp &&
        (this.powerUpType === PowerUpType.DwarfMiner || this.powerUpType === PowerUpType.SnowCreature)) {
      let scrnX = this.gemWidth * this.powerUpWanderingCharX;
      let scrnY = this.gemHeight * this.powerUpWanderingCharY;

      let nextDwarfX = this.powerUpWanderingCharX + this.xDirectionOffsets[this.powerUpWanderingCharfDirectionIndex];
      let nextDwarfY = this.powerUpWanderingCharY + this.yDirectionOffsets[this.powerUpWanderingCharfDirectionIndex];

      let needToClip = (this.getActiveGem(nextDwarfX, nextDwarfY) === null);

      let otherX = this.gemWidth * nextDwarfX;
      let otherY = this.gemHeight * nextDwarfY;

      let percent = 1 - this.stateCountdown / this.stateCountdownLength;
      scrnX += percent * (otherX - scrnX);
      scrnY += percent * (otherY - scrnY);

      if (needToClip) {
        UIManager.ctx.save();
        UIManager.ctx.rect(this.powerUpWanderingCharX * this.gemWidth,
                           this.powerUpWanderingCharY * this.gemHeight, this.gemWidth, this.gemHeight);
        UIManager.ctx.clip();
        if (this.powerUpType === PowerUpType.DwarfMiner) {
          this.dwarfSsImage.drawToCtx(this.boardCtx, scrnX, scrnY, this.gemWidth, this.gemHeight);
        }
        else {
          this.snowCreatureSsImage.drawToCtx(this.boardCtx, scrnX, scrnY, this.gemWidth, this.gemHeight);
        }

        UIManager.ctx.restore();
      }
      else {
        if (this.powerUpType === PowerUpType.DwarfMiner) {
          this.dwarfSsImage.drawToCtx(this.boardCtx, scrnX, scrnY, this.gemWidth, this.gemHeight);
        }
        else {
          this.snowCreatureSsImage.drawToCtx(this.boardCtx, scrnX, scrnY, this.gemWidth, this.gemHeight);
        }
      }
    }

    UIManager.ctx.save();

    UIManager.ctx.scale(1/boardRenderScale, 1/boardRenderScale);

    if(UIManager.isMobile) {
      UIManager.ctx.imageSmoothingEnabled = true;
      UIManager.ctx.imageSmoothingQuality = 'high';
    }
    UIManager.ctx.drawImage(this.boardCanvas, this.boardX * boardRenderScale, this.boardY * boardRenderScale, this.boardCanvas.width, this.boardCanvas.height);

    UIManager.ctx.restore();

    this.gemWidth /= boardRenderScale;
    this.gemHeight /= boardRenderScale;
  }

  /**
   * Draws the score toasts that pop off cards (aka floating text)
   */
  public drawFloatingText() {
    this.floatingText.forEach((ft) => { ft.draw(); });

    // UIManager.ctx.fillStyle = this.goodTextColor;
    // UIManager.ctx.fillRect(0, 210 + (600-this.cardHeight), 972, 2);
  }

  /**
   * Returns the gem at the passed in board location.
   * @param x - Board x location
   * @param y - Board y location
   */
  private getGem(x: number, y: number): Gem {
    if (x >= 0 && x < 9) {
      if (y >= this.minBoardY[x] && y < 9) {
        return this.board[x][y];
      }
    }

    return null;
  }

  /**
   * Returns true if gems match for hint.
   * @param gem - Gem to match
   * @param x - Board x location of other gem
   * @param y - Board y location of other gem
   */
  private doGemsMatchForHint(gem: Gem, x: number, y: number) {
    let otherGem = this.getActiveMoveableGem(x, y);
    return (otherGem !== null && gem.type === otherGem.type);
  }

  /**
   * Returns gem at location if it is active.
   * @param x - Board x location
   * @param y - Board y location
   */
  private getActiveGem(x: number, y: number) {
    let gem = this.getGem(x, y);
    if (gem !== null && gem.active) {
      return gem;
    }

    return null;
  }

  /**
   * Returns gem at location if it is active and also moveable.
   * @param x - Board x location
   * @param y - Board y location
   */
  private getActiveMoveableGem(x: number, y: number) {
    let gem = this.getGem(x, y);
    if (gem !== null && gem.active && gem.type !== this.GEM_TYPE_NONE && !gem.locked && !gem.frozen) {
      return gem;
    }

    return null;
  }

  getMatchScore(gem1:any, gem2:any) {
    let x1 = gem1.x;
    let y1 = gem1.y;
    let x2 = gem2.x;
    let y2 = gem2.y;

    this.board[x1][y1] = gem2;
    this.board[x2][y2] = gem1;

    this.findMatches(false);

    let score = 0;
    for (let y = 0; y < 9; y++)
      for (let x = 0; x < 9; x++)
        if(score < this.board[x][y].matchType)
          score = this.board[x][y].matchType;

    this.board[x1][y1] = gem1;
    this.board[x2][y2] = gem2;

    for (let x = 0; x < 9; x++)
      for (let y = 0; y < 9; y++)
        if (this.board[x][y].active)
          this.board[x][y].matchType = MatchType.None;

    return score;
  }

  /**
   *  Returns a neighboring gem if any neighbors are an active gem. Use by Super
   * gem for checking matching.
   * @param x - Board x location
   * @param y - Board y location
   */
  private getActiveNeighborGem(x: number, y: number) {
    for (let i = 0; i < 4; i++) {
      let gem = this.getActiveGem(x + this.xDirectionOffsets[i], y + this.yDirectionOffsets[i]);
      if (gem !== null) {
        return gem;
      }
    }

    return null;
  }

  /**
   * Returns a hint gem. This is a gem that can be matched by player.
   */
  private getHintGem() {
    let gemsFound = [];
    let swapGemsFound = [];

    for (let x = 0; x < 9; x++) {
      for (let y = this.minBoardY[x]; y < 9; y++) {
        let gem = this.getActiveMoveableGem(x, y);  // this.board[x][y];
        if (gem !== null) {
          // Super gems always match as long as they have a neighbor
          if (gem.type === this.GEM_TYPE_SUPER) {
            let otherGem = this.getActiveNeighborGem(gem.x, gem.y);
            if (otherGem !== null) {
              gemsFound.push(gem);
              swapGemsFound.push(otherGem);
            }
            break;
          }

          // Boost to boost
          if (gem.boost !== BoostType.None) {
            let otherGem = this.getActiveMoveableGem(gem.x - 1, gem.y);
            if (otherGem !== null && otherGem.boost !== BoostType.None) {
              gemsFound.push(gem);
              swapGemsFound.push(otherGem);
              break;
            }
            otherGem = this.getActiveMoveableGem(gem.x + 1, gem.y);
            if (otherGem !== null && otherGem.boost !== BoostType.None) {
              gemsFound.push(gem);
              swapGemsFound.push(otherGem);
              break;
            }
            otherGem = this.getActiveMoveableGem(gem.x, gem.y - 1);
            if (otherGem !== null && otherGem.boost !== BoostType.None) {
              gemsFound.push(gem);
              swapGemsFound.push(otherGem);
              break;
            }
            otherGem = this.getActiveMoveableGem(gem.x, gem.y + 1);
            if (otherGem !== null && otherGem.boost !== BoostType.None) {
              gemsFound.push(gem);
              swapGemsFound.push(otherGem);
              break;
            }
          }

          // Checking for horizonal match of pattern of 'XX.' or '.XX'
          let nextXGem = this.getActiveMoveableGem(gem.x + 1, gem.y);
          if (this.doGemsMatchForHint(gem, gem.x + 1, gem.y)) {
            // Checking 'XX.'
            let otherGem = this.getActiveMoveableGem(gem.x + 2, gem.y);
            if (otherGem !== null) {
              if (this.doGemsMatchForHint(gem, otherGem.x, otherGem.y - 1)) {
                gemsFound.push(this.getGem(otherGem.x, otherGem.y - 1));
                swapGemsFound.push(otherGem);
              }
              if (this.doGemsMatchForHint(gem, otherGem.x, otherGem.y + 1)) {
                gemsFound.push(this.getGem(otherGem.x, otherGem.y + 1));
                swapGemsFound.push(otherGem);
              }
              if (this.doGemsMatchForHint(gem, otherGem.x + 1, otherGem.y)) {
                gemsFound.push(this.getGem(otherGem.x + 1, otherGem.y));
                swapGemsFound.push(otherGem);
              }
            }
            // Checking 'XX.'
            otherGem = this.getActiveMoveableGem(gem.x - 1, gem.y);
            if (otherGem !== null) {
              if (this.doGemsMatchForHint(gem, otherGem.x, otherGem.y - 1)) {
                gemsFound.push(this.getGem(otherGem.x, otherGem.y - 1));
                swapGemsFound.push(otherGem);
              }
              if (this.doGemsMatchForHint(gem, otherGem.x, otherGem.y + 1)) {
                gemsFound.push(this.getGem(otherGem.x, otherGem.y + 1));
                swapGemsFound.push(otherGem);
              }
              if (this.doGemsMatchForHint(gem, otherGem.x - 1, otherGem.y)) {
                gemsFound.push(this.getGem(otherGem.x - 1, otherGem.y));
                swapGemsFound.push(otherGem);
              }
            }
          }
          // Checking for horizonal match of pattern 'X.X'
          else if (nextXGem !== null && this.doGemsMatchForHint(gem, gem.x + 2, gem.y)) {
            if (this.doGemsMatchForHint(gem, gem.x + 1, gem.y - 1)) {
              gemsFound.push(this.getGem(gem.x + 1, gem.y - 1));
              swapGemsFound.push(nextXGem);
            }
            if (this.doGemsMatchForHint(gem, gem.x + 1, gem.y + 1)) {
              gemsFound.push(this.getGem(gem.x + 1, gem.y + 1));
              swapGemsFound.push(nextXGem);
            }
          }

          // Checking for vertical match of pattern of 'XX.' or '.XX'
          let nextYGem = this.getActiveMoveableGem(gem.x, gem.y + 1);
          if (this.doGemsMatchForHint(gem, gem.x, gem.y + 1)) {
            // Checking 'XX.'
            let otherGem = this.getActiveMoveableGem(gem.x, gem.y + 2);
            if (otherGem !== null) {
              if (this.doGemsMatchForHint(gem, otherGem.x - 1, otherGem.y)) {
                gemsFound.push(this.getGem(otherGem.x - 1, otherGem.y));
                swapGemsFound.push(otherGem);
              }
              if (this.doGemsMatchForHint(gem, otherGem.x + 1, otherGem.y)) {
                gemsFound.push(this.getGem(otherGem.x + 1, otherGem.y));
                swapGemsFound.push(otherGem);
              }
              if (this.doGemsMatchForHint(gem, otherGem.x, otherGem.y + 1)) {
                gemsFound.push(this.getGem(otherGem.x, otherGem.y + 1));
                swapGemsFound.push(otherGem);
              }
            }
            // Checking '.XX'
            otherGem = this.getActiveMoveableGem(gem.x, gem.y - 1);
            if (otherGem !== null) {
              if (this.doGemsMatchForHint(gem, otherGem.x - 1, otherGem.y)) {
                gemsFound.push(this.getGem(otherGem.x - 1, otherGem.y));
                swapGemsFound.push(otherGem);
              }
              if (this.doGemsMatchForHint(gem, otherGem.x + 1, otherGem.y)) {
                gemsFound.push(this.getGem(otherGem.x + 1, otherGem.y));
                swapGemsFound.push(otherGem);
              }
              if (this.doGemsMatchForHint(gem, otherGem.x, otherGem.y - 1)) {
                gemsFound.push(this.getGem(otherGem.x, otherGem.y - 1));
                swapGemsFound.push(otherGem);
              }
            }
          }
          // Checking for vertical match of pattern 'X.X'
          else if (nextYGem !== null && this.doGemsMatchForHint(gem, gem.x, gem.y + 2)) {
            if (this.doGemsMatchForHint(gem, gem.x - 1, gem.y + 1)) {
              gemsFound.push(this.getGem(gem.x - 1, gem.y + 1));
              swapGemsFound.push(nextYGem);
            }
            if (this.doGemsMatchForHint(gem, gem.x + 1, gem.y + 1)) {
              gemsFound.push(this.getGem(gem.x + 1, gem.y + 1));
              swapGemsFound.push(nextYGem);
            }
          }
        }
      }
    }

    if (gemsFound.length === 0) {
      this.gemHint = null;
      this.gemHintSwap = null;
      return;
    }

    // We have an array of all possible hint gems - return a random one of them
    let index = this.randGen.getRandMod(gemsFound.length);

    if(this.autoPlay) {
      let scores:any[] = [];

      for(let i = 0; i < gemsFound.length; i++) {
        let score = 0;

        let superMatch = (gemsFound[i].type == this.GEM_TYPE_SUPER) ;

        let goalMatch = false;
        let goalSuperMatch = false;
        for(let j = 0; j < this.goals.length; j++) {
          let gemType = GemColors.indexOf(this.goals[j].gemType);
          if(this.goals[j].amount > 0) {
            if(gemsFound[i].type == this.GEM_TYPE_SUPER && gemType == swapGemsFound[i].type)
              goalSuperMatch = true;
            if(gemType == gemsFound[i].type)
              goalMatch = true;
          }
        }

        score = this.getMatchScore(gemsFound[i], swapGemsFound[i]) * 10;
        score += (gemsFound[i].y / 10);

        if(goalSuperMatch)
          score += 100;
        else if(superMatch)
          score += 50;
        else if(goalMatch)
          score += 5;

        scores.push({index: i, score})
      }

      scores.sort((a, b)=>{
        if(a.score > b.score)
          return -1;
        else if(a.score < b.score)
          return 1;
        return 0;
      })

      let bestScore = scores[0].score;
      let likeScores = [];
      for(let i = 0; i < scores.length; i++)
        if(scores[i].score == bestScore)
          likeScores.push(scores[i]);

      let r = this.randGen.getRandMod(likeScores.length);
      index = likeScores[r].index;
    }

    this.gemHint = gemsFound[index];
    this.gemHintSwap = swapGemsFound[index];
  }

  private isExchangeGem(gem: Gem) {
    return (gem == this.exchangingGems[0] || gem == this.exchangingGems[1]);
  }

  private createBoost(gemToBoostInfo:any) {
    let gem = gemToBoostInfo.gem;
    let matchPattern = gemToBoostInfo.matchPattern;
    let scoreToAdd = gemToBoostInfo.score;

    // Do special stuff for the Super Gem
    if (gem.type == this.GEM_TYPE_SUPER) {
      scoreToAdd = Math.floor(this.scoringMatchingSuperGem * this.scoreMultiplier);
      this.checkBestCreateBoostScore(scoreToAdd,matchPattern.name)
      this.addScoreAtGemLoc(gem, scoreToAdd, true);

      this.addToBoostArray({ boostType: BoostType.GemType });

      gem.matchType = MatchType.None;
      gem.type = this.GEM_TYPE_NONE;
      gem.boost = BoostType.None;

      return;
    }

    gem.boost = gemToBoostInfo.boostType; // matchPattern.boostType;

    if (scoreToAdd == 0) {
      scoreToAdd = matchPattern.createBoostScore;
      if (this.getIsSingleBoost(gem)) {
        scoreToAdd *= 1.25;
      }
      else if (this.getIsDoubleBoost(gem)) {
        scoreToAdd *= 1.5;
      }
    }

    scoreToAdd = Math.floor(scoreToAdd * this.scoreMultiplier);
    this.checkBestCreateBoostScore(scoreToAdd,matchPattern.name)
    this.addScoreAtGemLoc(gem, scoreToAdd, false);

    switch (gem.matchType) {
      case MatchType.Five:
        gem.type = this.GEM_TYPE_SUPER;
        gem.matchType = MatchType.None;
        SoundManager.instance.playSound(this.sfxSuperGemCreated);
        break;

      case MatchType.Double:
      case MatchType.FourHorz:
      case MatchType.FourVert:
      case MatchType.Angles:
        gem.matchType = MatchType.None;
        break;
    }
  }

  private checkBestCreateBoostScore(score: number, name: string) {
    if (score > this.bestCreateBoostScore.score) {
      this.bestCreateBoostScore.score = score;
      this.bestCreateBoostScore.name = name;
    }
  }

   private checkBestGemScore(score: number, name: string) {
    if (score > this.bestGemScore.score) {
      this.bestGemScore.score = score;
      this.bestGemScore.name = name;
    }
  }

  private checkBestComboScore(score: number) {
    if (score > this.bestComboScore) {
      this.bestComboScore = score;
    }
  }

  private checkPattern(matchPattern: MatchPattern, gemsToBoostInfo: any[]): boolean {
    let offsets = matchPattern.offsets;
    let canBoost = (gemsToBoostInfo != null && matchPattern.boostType != BoostType.None);

    let foundMatch = false;
    for (let y = 0; y < 10 - matchPattern.h; y++) {
      for (let x = 0; x < 10 - matchPattern.w; x++) {
        let foundMismatch = false;
        let gemType = null;
        let matchedGems = [];

        let allBoosted = true;
        let allSingleBoost = true;
        let allDoubleBoost = true;
        let allAreNotUsedForBoostMatchBonus = true;

        for (let i = 0; !foundMismatch && i < offsets.length; i++) {
          let gem = this.board[x + offsets[i].x][y + offsets[i].y];
          if (!gem.active || gem.frozen || gem.locked) {
            foundMismatch = true;
          }
          else {
            let isSingleBoost = this.getIsSingleBoost(gem);
            allSingleBoost = isSingleBoost;
            let isDoubleBoost = this.getIsDoubleBoost(gem);
            allDoubleBoost = isDoubleBoost;
            allBoosted = allBoosted && (isSingleBoost || isDoubleBoost);

            allAreNotUsedForBoostMatchBonus = !gem.usedForBoostMatchBonus && allAreNotUsedForBoostMatchBonus;

            foundMismatch = (gem.type == this.GEM_TYPE_NONE || (gemType != null && gemType != gem.type));
            if (!foundMismatch) {
              gemType = gem.type;
            }
          }
        }

        if (!foundMismatch) {
          foundMatch = true;

          let boostScore = 0;
          for (let i = 0; i < offsets.length; i++) {
            let gem = this.board[x + offsets[i].x][y + offsets[i].y];

            if (allBoosted && allAreNotUsedForBoostMatchBonus) {
              gem.usedForBoostMatchBonus = true;
              if (this.getIsSingleBoost(gem)) {
                boostScore += this.scoringMatchingSingleBoost;
              }
              else if (this.getIsDoubleBoost(gem)) {
                boostScore += this.scoringMatchingDoubleBoost;
              }
            }

            if (gem.matchPriority < matchPattern.priority) {
              gem.matchPriority = matchPattern.priority;
              gem.matchType = matchPattern.matchType;
              matchedGems.push(gem);
            }
          }

          //console.log(`x: ${x}  y: ${y}  score: ${boostScore}  name: ${matchPattern.name}`);
          let boostType = matchPattern.boostType;

          // Only need to do something if we've matched a pattern
          if (gemsToBoostInfo !== null && matchedGems.length > 0) {
            if (matchedGems[0].type == this.GEM_TYPE_SUPER) {
              // Doing special stuff if matching Super Gems
              for (let i = 0; i < matchedGems.length; i++) {
                gemsToBoostInfo.push({gem : matchedGems[i], matchPattern});
              }
            }
            else if (canBoost || allBoosted) {
              let gemToBoost = null;
              for (let i = 0; gemToBoost == null && i < matchedGems.length; i++) {
                let gem = matchedGems[i];

                if (gem.boost == BoostType.None && gemToBoost == null) {
                  // Choose the exchanged gem as the one to boost
                  if (this.isExchangeGem(gem)) {
                    gemToBoost = gem;
                  }
                }
              }

              // Choose a random gem to boost
              if (gemToBoost == null) {
                let randIndex = this.randGen.getRandRange(0, matchedGems.length - 1);
                gemToBoost = matchedGems[randIndex].boost == BoostType.None ? matchedGems[randIndex] : null;
              }

              // 2x2 square creates angled boost based upon which corner of the sqaure is the boosted gem
              if (gemToBoost !== null) {

                if (matchPattern.id == MatchId.TWO_BY_TWO_SQUARE) {
                  let dx = gemToBoost.x - x;
                  let dy = gemToBoost.y - y;
                  boostType = (dx + dy == 1) ? BoostType.NeSw : BoostType.NwSe;
                }

                gemsToBoostInfo.push({gem : gemToBoost, boostType, matchPattern, score: boostScore});
              }
            }
          }
          else if (boostScore != 0) {
            let randIndex = this.randGen.getRandRange(0, offsets.length - 1);
            let gemToBoost = this.board[x + offsets[randIndex].x][y + offsets[randIndex].y];
            gemsToBoostInfo.push({gem : gemToBoost, boostType, matchPattern, score : boostScore});
          }
        }
      }
    }

    return foundMatch;
  }

  /**
  * Goes through the boards and finds all the matches.
  * @param createBoosts - Whether boosts should be created
  */
  private findMatches(createBoosts: boolean): boolean {
    //console.log('\n============== findMatches ===============');
    // Reset board matching info before checking
    for (let y = 0; y < 9; y++) {
      for (let x = 0; x < 9; x++) {
        this.board[x][y].matchType = MatchType.None;
        this.board[x][y].matchPriority = -1;
        this.board[x][y].usedForBoostMatchBonus = false;
      }
    }

    let gemsToBoostInfo: any[] = createBoosts ? [] : null;
    let foundMatch = false;

    for (let i = 0; i < matchPatterns.length; i++) {
      foundMatch = this.checkPattern(matchPatterns[i], gemsToBoostInfo) || foundMatch;
    }

    if (gemsToBoostInfo != null) {
      let bumpBaseScoreMultiplier = false;
      for (let i = 0; i < gemsToBoostInfo.length; i++) {
        bumpBaseScoreMultiplier = bumpBaseScoreMultiplier || (gemsToBoostInfo[1] == this.GEM_TYPE_SUPER);
        this.createBoost(gemsToBoostInfo[i]);
      }

      // Matching Super Gems sets the base score multiplier higher for the rest of this game
      if (bumpBaseScoreMultiplier) {
        if (this.baseScoreMultiplier < 1.2)
           this.baseScoreMultiplier += 0.05;

        if (this.scoreMultiplier < 1.5)
          this.scoreMultiplier = (this.scoreMultiplier == 1.0) ? 1.05 : this.scoreMultiplier + 0.05;

        //console.log(`  ======= Bonus Mult: ${this.scoreMultiplier}`);
      }
    }

    return foundMatch;
  }


    /**
     * Gets gem at passed in location and clears locked or frozen on it.
     * @param x - Board x location
     * @param y - Board y location
     */
    clearGemLockedAndFrozen(x: number, y: number) {
      let gem = this.getActiveGem(x, y);

      if (!gem || (!gem.frozen && !gem.locked)) return;

      this.updateGoalTotals(gem);

      if (gem.frozen) {
        gem.frozen = false;

        let scrnX = this.boardX + gem.x * this.gemWidth + this.gemWidth / 2;
        let scrnY = this.boardY + gem.y * this.gemHeight + this.gemHeight / 2;
        let particleSize = 48;
        Game.instance.particleManager.addExplosion(
            this.gemParticleFrozenSsImage, scrnX - particleSize / 2,
            scrnY - particleSize / 2, particleSize);
      } else if (gem.locked) {
        gem.locked = false;

        let scrnX = this.boardX + gem.x * this.gemWidth + this.gemWidth / 2;
        let scrnY = this.boardY + gem.y * this.gemHeight + this.gemHeight / 2;
        let particleSize = 48;
        Game.instance.particleManager.addExplosion(
            this.gemParticleLockedSsImage, scrnX - particleSize / 2,
            scrnY - particleSize / 2, particleSize);
      }
    }

    /**
     * Clears locked/frozen of the passed in gem and also its neighbors if
     * clearNeighbors is true.
     * @param x - Board x location
     * @param y - Board y location
     * @param clearNeighbors - Whether neighbors should be cleared.
     */
    clearLockedAndFrozen(x: number, y: number, clearNeighbors: boolean) {
      this.clearGemLockedAndFrozen(x, y);
      if (clearNeighbors) {
        for (let i = 0; i < 4; i++) {
          this.clearGemLockedAndFrozen(
              x + this.xDirectionOffsets[i], y + this.yDirectionOffsets[i]);
        }
      }
    }

    /**
     * Updates goal count for passed in gem type. Sets goalsMet to true if all
     * goals have been met.
     * @param gemType - Gem type
     */
    updateGoalTotals(gem: any) {
      if (this.goals == null || this.goals == undefined) {
        return;
      }

      this.goalsMet = true;
      for (let i = 0; i < this.goals.length; i++) {
        let goal = this.goals[i];
        if (goal.gemType == 'frozen') {
          if (gem.frozen) goal.amount--;
        } else if (goal.gemType == 'locked') {
          if (gem.locked) goal.amount--;
        } else {
          let goalGemType = GemColors.indexOf(goal.gemType);
          if (goalGemType == gem.type && goal.amount > 0) goal.amount--;
        }
        goal.amount = Math.max(0, goal.amount);
        this.goalsMet = this.goalsMet && (goal.amount === 0);
      }
    }

    /**
     * Adds boost (if any) to boost array for the passed in gem.
     * @param gem - Gem to add to boost
     */
    addGemToBoost(gem: Gem) {
      if (gem.type === this.GEM_TYPE_SUPER) {
        //let gemTypeIndex = this.randGen.getRandMod(this.numBoardGemTypes);
        //let gemType = this.boardGemTypes[gemTypeIndex];
        //this.addToBoostArray({boostType: BoostType.GemType, gemType: gemType});

        // Now when this boost happens it'll choose the gem that is most frequent on the board
        this.addToBoostArray({boostType: BoostType.GemType});
      }
      else if (gem.boost == BoostType.Square) {
        this.addToBoostArray( {boostType: BoostType.Square, x: gem.x, y: gem.y, range: 1});
      }
      else if (gem.boost === BoostType.Row) {
        this.addToBoostArray({boostType: BoostType.Row, y: gem.y, range: 0});
      }
      else if (gem.boost === BoostType.Column) {
        this.addToBoostArray({boostType: BoostType.Column, x: gem.x, range: 0});
      }
      else if (gem.boost == BoostType.NwSe) {
        this.addToBoostArray({ boostType: BoostType.NwSe, x: gem.x, y: gem.y, range: 0 });
      }
      else if (gem.boost == BoostType.NeSw) {
        this.addToBoostArray({ boostType: BoostType.NeSw, x: gem.x, y: gem.y, range: 0 });
      }
      else if (gem.boost == BoostType.NwSeNeSw) {
        this.addToBoostArray({ boostType: BoostType.NwSeNeSw, x: gem.x, y: gem.y, range: 0 });
      }
    }

    /**
     * Adds score at gem's location and also possible particle explosion.
     * @param gem - Gem that scored
     * @param scoreToAdd - Amount of score
     * @param doExplosion - Whether particle exposion should happen
     */
    addScoreAtGemLoc(gem: Gem, scoreToAdd: number, doExplosion: boolean) {
      let scrnX = this.boardX + gem.x * this.gemWidth + this.gemWidth / 2;
      let scrnY = this.boardY + gem.y * this.gemHeight + this.gemHeight / 2;
      this.addToScore(scoreToAdd, scrnX, scrnY);

      if (doExplosion) {
        let particleSize = 48;
        Game.instance.particleManager.addExplosion(
            this.gemParticleSsImages[gem.type], scrnX - particleSize / 2,
            scrnY - particleSize / 2, particleSize);
        SoundManager.instance.playSound(this.sfxGemExplosion);
      }
  }

  private getIsDoubleBoost(gem: Gem): boolean {
    return (gem.boost == BoostType.Square || gem.boost == BoostType.NwSe ||
      gem.boost == BoostType.NeSw || gem.boost == BoostType.NwSeNeSw);
  }

  private getIsSingleBoost(gem: Gem): boolean {
    return (gem.boost == BoostType.Row || gem.boost == BoostType.Column);
  }

    /**
     * Scores the passed in gem and adds any boosts and clears neighbor if
     * needed.
     * @param gem - Gem to score
     * @param clearNeighbors - Whether neighbors frozen/locked should be cleard.
     */
    scoreGem(gem: Gem, clearNeighbors: boolean = true): boolean {
      if (gem.active && gem.type !== this.GEM_TYPE_NONE) {
        let name = 'Regular Gem';
        let score = this.scoringGem;
        if (gem.type === this.GEM_TYPE_SUPER) {
          score = this.scoringGemSuper;
          name = 'Super Gem';
        }
        else if (this.getIsDoubleBoost(gem)) {
          score = this.scoringGemDoubleBoosted;
          name = 'Double Boosted Gem';
        }
        else if (this.getIsSingleBoost(gem)) {
          score = this.scoringGemBoosted;
          name = 'Boosted Game';
        }

        let scoreToAdd = Math.floor(score * this.scoreMultiplier);
        this.checkBestGemScore(scoreToAdd, name);
        this.addScoreAtGemLoc(gem, scoreToAdd, true);

        this.addGemToBoost(gem);

        if (Game.instance.gameMode == GameMode.Adventure || Game.instance.gameMode == GameMode.Daily)
          if (!gem.frozen && !gem.locked)
            this.updateGoalTotals(gem);

        gem.matchType = MatchType.None;
        gem.type = this.GEM_TYPE_NONE;
        gem.boost = BoostType.None;
        this.setGemStateScoring(gem);
        this.clearLockedAndFrozen(gem.x, gem.y, clearNeighbors);
      }

      return false;
    }

    /**
     * Blasts the passed in row. Does it over time - left to right.
     * @param y - Row to blast
     * @param range - Range to blast
     */
    blastRow(y: number, range: number): boolean {
      let x = this.gemBlastStep % 9;
      for (let yOffset = -range; yOffset <= range; yOffset++) {
        let adjY = y + yOffset;
        if (adjY >= 0 && adjY < 9) {
          let gem = this.board[x][adjY];
          this.scoreGem(gem);
        }
      }

      this.gemBlastStep++;

      return (this.gemBlastStep === 9);
    }

    /**
     * Blasts the passed in column. Does it over time - top to bottom.
     * @param x - Column to blast
     * @param range - Range to blast
     */
    blastColumn(x: number, range: number): boolean {
      let y = (this.gemBlastStep % 9);
      for (let xOffset = -range; xOffset <= range; xOffset++) {
        let adjX = x + xOffset;
        if (adjX >= 0 && adjX < 9) {
          let gem = this.board[adjX][y];
          this.scoreGem(gem);
        }
      }

      this.gemBlastStep++;

      return (this.gemBlastStep === 9);
    }

    /**
     * Blasts the passed in square - all at once.
     * @param x - Board x location
     * @param y - Board y location
     * @param range - Range to blast
     */
    blastSquare(x: number, y: number, range: number): boolean {
      let fullRange = range + range + 1;
      while (this.gemBlastStep < (fullRange * fullRange)) {
        let xOffset = (this.gemBlastStep % fullRange) - range;
        let yOffset = Math.floor(this.gemBlastStep / fullRange) - range;
        let adjX = x + xOffset;
        if (adjX >= 0 && adjX < 9) {
          let adjY = y + yOffset;
          if (adjY >= 0 && adjY < 9) {
            let gem = this.board[adjX][adjY];
            this.scoreGem(gem);
          }
        }

        this.gemBlastStep++;
      }

      return true;
  }

    /**
     * Blasts the passed in row. Does it over time - left to right.
     * @param y - Row to blast
     * @param range - Range to blast
     */
  blastNwSe(x: number, y: number, range: number): boolean {
    let adjY = 0 + this.gemBlastStep;
    if (adjY >= 0 && adjY < 9) {
      for (let xOffset = -range; xOffset <= range; xOffset++) {
        let adjX = x - (y - adjY) + xOffset;
        if (adjX >= 0 && adjX < 9) {
          this.scoreGem(this.board[adjX][adjY]);
        }
      }
    }

    this.gemBlastStep++;

    return (adjY === 8);
  }


  /**
  * Blasts the passed in row. Does it over time - left to right.
  * @param y - Row to blast
  * @param range - Range to blast
  */
  blastNeSw(x: number, y: number, range: number): boolean {
    let adjY = 0 + this.gemBlastStep;
    if (adjY >= 0 && adjY < 9) {
      for (let xOffset = -range; xOffset <= range; xOffset++) {
        let adjX = x + (y - adjY) + xOffset;
        if (adjX >= 0 && adjX < 9) {
          this.scoreGem(this.board[adjX][adjY]);
        }
      }
    }

    this.gemBlastStep++;

    return (adjY == 8);
  }


    /**
  * Blasts the passed in row. Does it over time - left to right.
  * @param y - Row to blast
  * @param range - Range to blast
  */
  blastNwSeNeSw(x: number, y: number, range: number): boolean {
    let adjY = 0 + this.gemBlastStep;
    if (adjY >= 0 && adjY < 9) {
      for (let xOffset = -range; xOffset <= range; xOffset++) {

        let adjX = x + (y - adjY) + xOffset;
        if (adjX >= 0 && adjX < 9) {
          this.scoreGem(this.board[adjX][adjY]);
        }

        adjX = x - (y - adjY) + xOffset;
        if (adjX >= 0 && adjX < 9) {
          this.scoreGem(this.board[adjX][adjY]);
        }
      }
    }

    this.gemBlastStep++;

    return (adjY == 8);
  }

    /**
     * Blasts the passed in gem type. Does it over time - one gem at a time.
     * @param gemType - Gem type to blast.
     */
    blastGemType(gemType: number): boolean {
      let isSuper = (gemType === this.GEM_TYPE_SUPER);

      for (let x = 0; x < 9; x++) {
        for (let y = this.minBoardY[x]; y < 9; y++) {
          let gem = this.board[x][y];
          if (gem.active && gem.type !== this.GEM_TYPE_NONE &&
              (isSuper || gem.type === gemType)) {
            this.scoreGem(gem);
            gem.matchType = MatchType.None;

            return false;
          }
        }
      }

      // If doing a super to super then board is empty so clear out the boost
      // array
      if (isSuper) {
        this.boostArray = [];
      }

      return true;
    }

    /**
     * Sets the passed in gem's state to None.
     * @param gem - Gem to set
     */
    setGemStateNone(gem: Gem) {
      gem.state = GemState.None;
    }

    /**
     * Set's the passed in gem's state to Moving and also the location it is
     * moving to.
     * @param gem - Gem to set
     * @param gemMoveTo - Gem to move to
     */
    setGemStateMoving(gem: Gem, gemMoveTo: Gem) {
      gem.state = GemState.Moving;
      gem.movingTo = {x: gemMoveTo.x, y: gemMoveTo.y};
    }

    /**
     * Sets the gem's state to Score. NOT CURRENTLY USED. (may not need)
     * @param gem - Gem to set
     */
    setGemStateScoring(gem: Gem) {
      gem.state = GemState.Scoring;
      gem.countDown = this.gemScoringCountdownLength;
    }

    /**
     * Exchanges some info of the two passed in gems.
     * @param gem1 - Gem to exchange
     * @param gem2 - Gem to exchange
     */
    exchangeGemStuff(gem1: Gem, gem2: Gem) {
      let tempNumber = gem1.x;
      gem1.x = gem2.x;
      gem2.x = tempNumber;
      tempNumber = gem1.y;
      gem1.y = gem2.y;
      gem2.y = tempNumber;
      let tempBoolean = gem1.locked;
      gem1.locked = gem2.locked;
      gem2.locked = tempBoolean
    }

    /**
     * Exchanges a board gem with a top gem.
     * @param topGem - Top gem
     * @param boardGem - Board gem
     */
    exchangeWithTopGem(topGem: Gem, boardGem: Gem) {
      this.topGems[topGem.x] = boardGem;
      this.board[boardGem.x][boardGem.y] = topGem;
      this.exchangeGemStuff(topGem, boardGem);
    }

    /**
     * Exchanges the two passed in gems.
     * @param gem1 - Gem to exchange
     * @param gem2 - Gem to exchange
     */
    exchangeGems(gem1: Gem, gem2: Gem) {
      this.board[gem1.x][gem1.y] = gem2;
      this.board[gem2.x][gem2.y] = gem1;
      this.exchangeGemStuff(gem1, gem2);
    }

    /**
     * Starts the process of exchanging the two passed in gems. They start
     * moving to their new locations.
     * @param gem1 - Gem to exchange
     * @param gem2 - Gem to exchange
     */
    startExchange(gem1: Gem, gem2: Gem) {
      this.setState(MatchThreeState.Exchanging);
      this.setStateCountdown(this.exchangeCountdownLength);
      this.exchangingGems = [gem1, gem2];

      this.setGemStateMoving(gem1, gem2);
      this.setGemStateMoving(gem2, gem1);
    }

    /**
     * Reverses the process of two exchanging gems. They'll go back to their
     * original location. Used when a player does an exchange that doesn't
     * result in a match.
     */
    startReverseExchange() {
      this.setState(MatchThreeState.ReverseExchanging);
      this.setStateCountdown(this.exchangeCountdownLength);

      this.setGemStateMoving(this.exchangingGems[0], this.exchangingGems[1]);
      this.setGemStateMoving(this.exchangingGems[1], this.exchangingGems[0]);
      this.exchangingGems[0].canBounce = false;
      this.exchangingGems[1].canBounce = false;
    }

    /**
     * Returns the gem at the passed in screen location.
     * @param x - Screen x location
     * @param y - Screen y location
     */
    public getGemOver(x: number, y: number): Gem {
      let xIndex = Math.floor((x - this.boardX) / this.gemWidth);
      let yIndex = Math.floor((y - this.boardY) / this.gemHeight);
      if (xIndex >= 0 && xIndex < 9 && yIndex >= 0 && yIndex < 9) {
        if (this.board[xIndex][yIndex].active) {
          return this.board[xIndex][yIndex];
        }
      }

      // Over nothing
      return null;
    }

    /**
     * Called when a key is pressed. Right now SPACE toggles pause and '+' steps
     * a frame when paused.
     * @param event - key event
     */
    public onKeyDown(event: KeyboardEvent) {
      // For stepping through action frame by frame to test that things are
      // working correctly
      if (event.key === ' ') {
        this.paused = !this.paused;
      } else if ((event.key === '+' || event.key == '=') && this.paused) {
        this.doStep = true;
      }
    }

    /**
     * Called when mouse/touch is pressed.
     * @param mouseX - Mouse/touch x location
     * @param mouseY - Mouse/touch y location
     */
  public onMouseDown(mouseX: number, mouseY: number) {
    if (this.doingPrecisionBlast || this.doingLockGem || this.doingFreezeGem) {
      let gemOver = this.getGemOver(mouseX, mouseY);
      if (gemOver != null && gemOver.active) {

        let type: string;
        if (this.doingPrecisionBlast) {
          type = 'doingPowerUpPrecisionBlast';
          if(gemOver.frozen)
            this.updateGoalTotals(gemOver);
          gemOver.frozen = false;
          gemOver.locked = false;
          this.scoreGem(gemOver);
          this.startFalling();
        }
        else if (this.doingLockGem) {
          if (gemOver.locked || gemOver.frozen) {
            return;
          }

          type = 'doingPowerUpLockGem';
          gemOver.locked = true;
          gemOver.frozen = false;
        }
        else {
          if (gemOver.locked || gemOver.frozen) {
            return;
          }

          type = 'doingPowerUpFreezeGem';
          gemOver.frozen = true;
          gemOver.locked = false;
          gemOver.type = this.GEM_TYPE_NONE;
        }

        this.storeAction({
          type : type,
          gemX : gemOver.x,
          gemY : gemOver.y
        });

        this.doingPrecisionBlast = false;
        this.doingLockGem = false;
        this.doingFreezeGem = false;
      }

      return;
    }

      if (this.state !== MatchThreeState.PlayerInput || !this.started) {
        this.lastMouseX = mouseX;
        this.lastMouseY = mouseY;
        return;
      }

      // Get the gem over info using the mouse location
      let gemOver = this.getGemOver(mouseX, mouseY);
      if (gemOver !== null) {
        if (this.gemSelected !== null) {
          let delta = Math.abs(gemOver.x - this.gemSelected.x) + Math.abs(gemOver.y - this.gemSelected.y);
          if (delta === 1 && gemOver.active && !gemOver.locked! && !gemOver.frozen && gemOver.type !== this.GEM_TYPE_NONE) {
            this.startExchange(this.gemSelected, gemOver);
            this.storeAction({type : 'startExchange', selectedX : this.gemSelected.x, selectedY: this.gemSelected.y, overX: gemOver.x, overY: gemOver.y});
          }
          this.gemSelected = null;
        }
        else if (!gemOver.locked && !gemOver.frozen) {
          this.gemOver = gemOver;
          this.canSelectGem = true;
        }

        this.initialMouseX = mouseX;
        this.initialMouseY = mouseY;
        this.lastMouseX = mouseX;
        this.lastMouseY = mouseY;
      }
    }

    /**
     * Called when mouse/touch is release.
     * @param x - Mouse/touch x location
     * @param y - Mouse/touch y location
     */
    public onMouseUp(mouseX: number, mouseY: number) {
      if (this.state !== MatchThreeState.PlayerInput || !this.started) {
        this.lastMouseX = mouseX;
        this.lastMouseY = mouseY;
        return;
      }

      if (this.gemOver !== null && this.gemOver.type != this.GEM_TYPE_NONE) {
        if (this.canSelectGem) {
          this.gemSelected = this.gemOver;
        }
        this.gemOver = null;
      }
    }

    /**
     * Called when mouse/touch is moved.
     * @param mouseX - Mouse/touch x location
     * @param mouseY - Mouse/touch y location
     */
    public onMouseMove(mouseX: number, mouseY: number) {
      if (this.state !== MatchThreeState.PlayerInput || !this.started) {
        this.lastMouseX = mouseX;
        this.lastMouseY = mouseY;
        return;
      }

      this.lastMouseX = mouseX;
      this.lastMouseY = mouseY;

      if (this.gemOver !== null) {
        let dx = mouseX - this.initialMouseX;
        let dy = mouseY - this.initialMouseY;

        let xIndex = this.gemOver.x;
        let yIndex = this.gemOver.y;

        let exchangeGem = null;
        if (dx <= -this.gemWidth / 2) {
          if (this.gemOver.x !== 0) {
            exchangeGem = this.board[xIndex - 1][yIndex];
          }
        } else if (dx >= this.gemWidth / 2) {
          if (this.gemOver.x !== 8) {
            exchangeGem = this.board[xIndex + 1][yIndex];
          }
        } else if (dy <= -this.gemHeight / 2) {
          if (this.gemOver.y !== 0) {
            exchangeGem = this.board[xIndex][yIndex - 1];
          }
        } else if (dy >= this.gemHeight / 2) {
          if (this.gemOver.y !== 8) {
            exchangeGem = this.board[xIndex][yIndex + 1];
          }
        }

        if (exchangeGem !== null) {
          if (exchangeGem.active && !exchangeGem.locked && !exchangeGem.frozen &&
              exchangeGem.type !== this.GEM_TYPE_NONE && this.gemOver.type != this.GEM_TYPE_NONE) {
            this.startExchange(this.gemOver, exchangeGem);
            this.gemOver = null;
          }
        } else if (this.gemOver !== null) {
          let gemOver = this.getGemOver(mouseX, mouseY);
          this.canSelectGem = (gemOver === this.gemOver);
        }
      }

      // Keep track of last moved location
      this.lastMouseX = mouseX;
      this.lastMouseY = mouseY;
    }

    public pause() {
      this.started = false;
    }

    public resume() {
      this.started = true;
    }

    public isGameOver() {
      return this.state == MatchThreeState.GameOver;
    }
  }
