import React from 'react';
import PageEnvelope from '../elements/PageEnvelope';
import { NavLink, Navigate } from 'react-router-dom';
import Portrait from '../elements/Portrait';
import PageHeader from '../elements/PageHeader';
import { WithRouterProps } from '../util/withRouter';
import './SitePage.css';
import { BsSearch } from 'react-icons/bs';
import AlertModal from '../modals/AlertModal';
import { Server } from '../../server/server';

interface UsersPageProps {
  router: WithRouterProps;
}

interface UsersPageState {
  error: string;
  users: any[];
  loading: boolean;
  slug: string;
  mode: string;
  header: string;
  navigate: string;
  alert: string;
}

class UsersPage extends React.Component<UsersPageProps , UsersPageState> {
  constructor(props:UsersPageProps) {
    super(props);
    this.state = {
      error: '',
      users: [],
      loading: false,
      slug: '',
      mode: '',
      header: '',
      navigate: '',
      alert: ''
    }
  }

  componentDidMount(): void {
    this.load();
  }

  componentDidUpdate(prevProps: Readonly<UsersPageProps>) {
    if(prevProps.router.location.pathname != this.props.router.location.pathname) 
      this.load();
  }

  async load() {
    let parts = window.location.pathname.split('/');

    let users = [];
    let header = '';
    let slug = '';
    let mode = '';

    slug = parts[2];
    mode = parts[3];

    this.setState({loading: true});
    let response = await Server.public.loadProfileFromSlug(slug);
    this.setState({loading: false});

    if(!response.success) {
      this.setState({error: response.message});
      return;
    }

    let profile = response.profile;

    if(profile.id != Server.user.getId() && !Server.user.isFriend(profile.id)) {
      this.setState({error: 'You can only view followers of your friends.'});
      return;
    }

    if(profile.id != Server.user.getId()) {
      this.setState({loading: true});
      let followsResponse = await Server.user.getFriendFollows(profile.id);
      this.setState({loading: false});

      if(followsResponse.success) {
        profile.followers = followsResponse.followers;
        profile.following = followsResponse.following;
        Server.public.addProfileToCache(profile);
      }
    }

    let userIds = (mode == 'following') ? profile.following : profile.followers;

    this.setState({loading: true});
    await Server.public.loadProfiles(userIds);
    this.setState({loading: false});

    for(let i = 0 ; i < userIds.length; i++) 
      users.push(Server.public.getProfile(userIds[i]));

    if(profile.id == Server.user.getId()) 
      for(let i = 0 ; i < profile.friends.length; i++) 
        users.push(Server.public.getProfile(profile.friends[i].id));

    if(mode == 'following')
      header = profile.name + ' Follows';
    else if(mode == 'followers')
      header = 'Following ' + profile.name;

    this.setState({users, slug, mode, header})
  }

  onSearch() {
    this.setState({alert: 'Coming soon!'})
  }

  renderUsers() {
    let divs = [];
    for(let i = 0; i < this.state.users.length; i++) {
      let user = this.state.users[i];
      let activity = user.activity ? new Date(user.activity) : null;

      divs.push(
        <NavLink key={i} to={'/profile/' + user.slug} style={{textDecoration: 'none'}}>
          <div className="site-page-row">

            <Portrait user={user.id} />

            <div className="site-page-column" style={{rowGap: '3px'}}>
              <div className="site-page-title-text">{user.name}</div>
              {activity && 
                <div className="site-page-subtext">
                  Last login {activity.toLocaleString(undefined, {month: "short", day: "numeric", hour: "numeric", minute: "numeric"})}
                </div>
              }
            </div>
          </div>
        </NavLink>
      )
    }

    return (
      <div className="site-page-panel" style={{padding: '10px'}}>
        {divs.length == 0 ? 'There are no users in this list.' : divs}
      </div>
    )
  }

  render() {
    if(this.state.navigate != '')
      return (<Navigate to={this.state.navigate} />);
      
    return (
      <div className="site-page">
        <PageEnvelope width="500px">
          <div className="site-page-column">
            {this.state.loading &&
              <PageHeader text="Loading.." navigate={`/profile/${this.state.slug}`} />
            }
            {!this.state.loading && this.state.error != '' &&
              <PageHeader text={this.state.error} navigate={`/profile/${this.state.slug}`} />
            }
            {!this.state.loading && this.state.error == '' && 
              <div className="site-page-column">
                {this.state.header != '' &&
                  <PageHeader text={this.state.header} navigate={`/profile/${this.state.slug}`} />
                }
                {this.renderUsers()}
              </div>
            }
          </div>
        </PageEnvelope>
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
      </div>
    )
  }
}

export default UsersPage;