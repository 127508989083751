import { GameBase } from "../common/gameBase";
import { GameScreen } from "./gameScreen";
import { GameStyle } from "./gameStyle";

export class Game extends GameBase {
  public static instance:Game;

  public gameScreen: GameScreen;

  constructor(canvas:HTMLCanvasElement, callback:Function) {
    super(canvas, callback, 'block-buster');
    this.dialogStyle = GameStyle.dialog;
  }

  public load() {
    super.load();

    Game.instance = this;

    this.gameScreen = new GameScreen();
    this.gameScreen.build();
    this.gameScreen.show();

    this.callback('loaded');
  }

  public unload() {
    this.gameScreen = null;
    Game.instance = null;
    super.unload();
  }
}