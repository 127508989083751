import React from 'react';

class NotFoundPage extends React.Component {
  render() {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <img src="/coming-soon.png" style={{width: '250px', marginTop: '50px'}}></img>
      </div>
    )
  }
}

export default NotFoundPage;