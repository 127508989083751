import React from 'react';
import { Server } from '../../server/server';
import MessageModal from '../modals/MessageModal';
import AlertModal from '../modals/AlertModal';
import PageEnvelope from '../elements/PageEnvelope';
import ContentPanel from '../elements/ContentPanel';
import ActivityButtons from '../elements/ActivityButtons';
import { Navigate } from 'react-router-dom';
import PostPanel from '../elements/PostPanel';
import './SitePage.css';

interface ActivityPageState {
  posts: any[];
  message: string;
  alert: string;
  category: string;
  loading: boolean;
  hasNewPosts: boolean;
  fixedBar: boolean;
  loadingMore: boolean;
  navigate: string;
}

class ActivityPage extends React.Component<{}, ActivityPageState> {
  timerNewPosts: any;
  newPosts: any[] = [];
  posLodMore = 0;

  constructor(props: {}) {
    super(props);
    
    this.state = {
      posts: [],
      message: '',
      alert: '',
      category: 'community',
      loading: true,
      hasNewPosts: false,
      fixedBar: false,
      loadingMore: false,
      navigate: ''
    };

    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onActivityUpdated = this.onActivityUpdated.bind(this);
  }

  componentDidMount() {
    Server.activity.addEventListener('activity-updated', this.onActivityUpdated);
    if(Server.activity.getFilter() != '')
      this.setState({category: Server.activity.getFilter()});

    this.loadActivity();

    if(Server.account.isLoggedIn())
      Server.activity.markActivityAsRead();
  }

  componentWillUnmount(): void {
    Server.activity.removeEventListener('activity-updated', this.onActivityUpdated);
    clearInterval(this.timerNewPosts);
  }

  async loadActivity() {
    let cachedPosts = Server.activity.getPostsFromCache();
    let posts = [];
    for(let i = 0; i < cachedPosts.length; i++)
      posts.push(cachedPosts[i]);

    let profiles = [];
    for (let i = 0; i < posts.length; i++) {
      if(profiles.indexOf(posts[i].author) == -1)
        profiles.push(posts[i].author);

      if (posts[i].repost)
        if(profiles.indexOf(posts[i].repost.author) == -1)
          profiles.push(posts[i].repost.author);
    }

    await Server.public.loadProfiles(profiles);

    this.setState({ posts, loading: false, loadingMore: false, hasNewPosts: false });
  }

  syncPosts() {
    let posts = [...Server.activity.getPostsFromCache()];
    this.setState({posts});
  }

  onActivityUpdated() {
    this.setState({hasNewPosts: true})
  }

  async onCategoryChange(e: any) {
    this.setState({category: e.target.value, loading: true});
    Server.activity.setFilter(e.target.value);
    await Server.activity.updatePosts();
    Server.activity.markActivityAsRead();
    this.posLodMore = 0;
    this.loadActivity();
  }

  loadNewPosts() {
    this.loadActivity();
    Server.activity.markActivityAsRead();
  }

  async onPost(content:string) {
    this.setState({message: 'Posting...'});

    let response = await Server.activity.createPost(content);

    if (response.success) {
      Server.activity.markActivityAsRead(response.date);
      await Server.user.incrementCounter('activity#post');
      await Server.activity.checkPosts();
      this.setState({loading: true});
      await this.loadActivity();
      this.setState({message: ''});
    }
    else
      this.setState({message: '', alert: response.message})
  }

  async onLoadMore() {
    if(!Server.account.isLoggedIn()) {
      this.setState({alert: 'Please login to view more community activity.'});
      return;
    }

    this.setState({ loadingMore: true })
    await Server.activity.loadMore();
    this.loadActivity();
    this.setState({ loadingMore: false })
  }

  onPostClick(id:string) {
    this.setState({navigate: "/activity/post/" + id});    
  }

  renderPosts() {
    if(this.state.loading) 
      return (<div className="site-page-panel">Loading...</div>); 

    let divs = [];
    for (let i = 0; i < this.state.posts.length; i++) {
      let post = this.state.posts[i];
      let repost = null;
      if(post.repost) {
        repost = post.author;
        post = post.repost;
      }
      divs.push(
        <ContentPanel 
          key={i}
          author={post.author}
          date={post.date}
          content={post.content}
          reply={true}
          repost={repost}
          summary={true}
          onClick={()=>this.onPostClick(post.id)}
        >
          <ActivityButtons 
            reposts={post.reposts} 
            reposted={post.reposted} 
            likes={post.likes} 
            liked={post.liked} 
            post={post} 
            reply={true} 
            onUpdate={()=>this.syncPosts()} 
          />
        </ContentPanel>
      )
    }

    return divs.length > 0 ? divs : <div className="site-page-panel" style={{marginTop: '10px'}}>No posts yet.</div>
  }

  render() {
    if(this.state.navigate != '') 
      return <Navigate to={this.state.navigate} />;

    return (
      <div className="site-page">
        <PageEnvelope width="600px">
          <div className="site-page-column">

          <PostPanel 
              placeholder="Whats happening?"
              onPost={(content:string)=>this.onPost(content)}
            />

          {Server.account.isLoggedIn() &&
            <div style={{textAlign: 'left'}}>
              <select
                style={{width: '150px', padding: '0 10px'}}
                value={this.state.category} 
                onChange={this.onCategoryChange}
              >
                <option value="community">Community</option>
                <option value="friends">Friends</option>
                <option value="following">Following</option>
              </select>
            </div>
          }

          {this.state.hasNewPosts &&
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <button 
                style={{
                  zIndex: '10', 
                  position: 'fixed', 
                  top: '70px', 
                  boxShadow: '0 8px 8px 0 rgba(0,0,0,0.2)',
                  backgroundColor: 'var(--notification-background-color)',
                  color: 'var(--notification-color)'
                }}
                onClick={()=>this.loadNewPosts()}
              >
                Show New Posts
              </button>
            </div>
          }

          {this.renderPosts()}

          {!this.state.loading && this.state.posts.length > 0 && 
            <div>
              <button 
                onClick={()=>this.onLoadMore()} 
                disabled={this.state.loadingMore}
              >
                {this.state.loadingMore ? 'Loading...' : 'Load More'}
              </button>
            </div>
          }
        
          </div>

        </PageEnvelope>

        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
      </div>
    );
  }
}

export default ActivityPage;