/**
 * Particle manager.
 *
 * Kevin Ryan
 */

import { UIManager } from './ui/uiManager';
import { RandomNumberGenerator } from './randomNumberGenerator';
import { SsImage } from './spriteSheetManager';

class Particle {
  velX: number;
  velY: number;
  lifeCountStart: number;
  alpha: number;

  constructor(public ssImage: SsImage, public x: number, public y: number, public size: number,
              public lifeCount: number) {
    this.lifeCountStart = this.lifeCount;
    this.alpha = 1;
  }
}

export class ParticleManager {
  public particles: Particle[];

  protected randGen: RandomNumberGenerator;

  constructor() {
    this.particles = [];
    this.randGen = new RandomNumberGenerator();
  }

  clear() {
    this.particles = [];
  }

  update(deltaTime: number) {
    for (let i = this.particles.length - 1; i >= 0; i--) {
      let particle = this.particles[i];
      particle.lifeCount -= deltaTime;
      if (particle.lifeCount <= 0) {
        this.particles.pop();
      }
      else {
        particle.velY += (deltaTime * 0.625);
        particle.x += (particle.velX / 10);
        particle.y += (particle.velY / 10);

        //Fade out the text starting at halfway through duration
        particle.alpha = 1;
        let elapsed = particle.lifeCountStart - particle.lifeCount;
        if (elapsed >= (particle.lifeCountStart / 2)) {
          particle.alpha = 1 - ((elapsed - (particle.lifeCountStart / 2)) / (particle.lifeCountStart / 2));
        }
      }
    }
  }

  draw() {
    let ctx = UIManager.ctx;
    for (let i = 0; i < this.particles.length; i++) {
      let particle = this.particles[i];
      ctx.globalAlpha = particle.alpha;
      particle.ssImage.draw(particle.x, particle.y, particle.size, particle.size);
      ctx.globalAlpha = 1;
    }
  }

  addExplosion(ssImage: SsImage, x: number, y: number, size: number) {
    for (let i = 0; i < 5; i++) {
      let size = this.randGen.getRandRange(20, 64);
      let lifeCount = this.randGen.getRandRange(280, 840);
      let particle = new Particle(ssImage, x, y, size, lifeCount);
      let vel = this.randGen.getRandRange(20, 64);
      let angleRadians = this.randGen.getRandMod(360) * Math.PI / 180;
      particle.velX = vel * Math.cos(angleRadians);
      particle.velY = vel * Math.sin(angleRadians) - 96;
      this.particles.unshift(particle);
    }
  }
}