import React from 'react';
import '../pages/SitePage.css';
import { BsFillXCircleFill } from 'react-icons/bs';

interface InfoPanelProps {
  message: string;
  item: string;
}

interface InfoPanelState {
  hide: boolean;
}

class InfoPanel extends React.Component<InfoPanelProps, InfoPanelState> {
  static lastInvite:any = null;

  constructor(props: InfoPanelProps) {
    super(props);
    this.state = {
      hide: false
    }
  }

  componentDidMount(): void {
    let s = localStorage.getItem(this.props.item);
    this.setState({hide: (s != null)});
  }

  onHide() {
    localStorage.setItem(this.props.item, '1');
    this.setState({hide: true});    
  }

  render() {
    if(this.state.hide)
      return null;

    return (
      <div className="site-page-panel" style={{alignItems: 'center', columnGap: '5px',  display: 'grid', gridTemplateColumns: '1fr min-content', backgroundColor: 'var(--header-panel-background-color)'}}>
        <span>
          {this.props.message}
        </span>
        <div style={{cursor: 'pointer'}} onClick={()=>this.onHide()}>
          <BsFillXCircleFill style={{display: 'block', fontSize: '1.1em', marginLeft: '5px'}} />
        </div>
      </div>
    )
  }
}

export default InfoPanel;