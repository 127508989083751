import React from 'react';
import { Database } from '../util/database';
import './HelpPage.css'

class HelpPage extends React.Component {
  render() {
    let gameId = window.location.pathname.substring(6);
    let game = Database.getGame(gameId);

    let pageName = 'Help';
    if(game)
      pageName = game.name + ' Help';

    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <img src="/coming-soon.png" style={{width: '280px', marginTop: '30px'}}></img>
      </div>
    );
  }
}

export default HelpPage;
