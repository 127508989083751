
import { sendDeviceNotification } from '../app/util/notifications';
import { Server } from './server';
import { Service, ServiceResponse } from './service';

export class ChatService extends Service {
  protected chats:any[];

  constructor() {
    super();
    this.chats = [];
    this.onChatMessage = this.onChatMessage.bind(this);
    this.onChatStarted = this.onChatStarted.bind(this);
    this.onChatLeft = this.onChatLeft.bind(this);
    this.onChatUpdated = this.onChatUpdated.bind(this);
  }

  public async init(): Promise<ServiceResponse> {
    Server.network.addEventListener('chat-message', this.onChatMessage);
    Server.network.addEventListener('chat-started', this.onChatStarted);
    Server.network.addEventListener('chat-left', this.onChatLeft);
    Server.network.addEventListener('chat-updated', this.onChatUpdated);
    return {success: true};
  }

  public async sync(): Promise<ServiceResponse> {
    this.chats = [];

    await this.loadChats();

    this.chats.push({
      date: '',
      id: 'community',
      type: 'public',
      members: []
    });

    return {success: true};
  }

  public clear() {
    this.chats = [];
  }

  protected async loadChats() {
    let response = await this.sendCommand('chat', 'get-chats');
    if(response.status == 200)
      this.chats = response.body.chats;
  }

  protected async loadChat(id:string) {
    let response = await this.sendCommand('chat', 'get-chat', {id});
    if(response.status == 200) {
      for(let i = 0; i < this.chats.length; i++)
        if(this.chats[i].id == id)
          this.chats.splice(i, 1);
      this.chats.push(response.body.chat);
    }
  }

  public getChats() {
    return this.chats;
  }

  public getUsersFromChats() {
    let users = [];
    for(let i = 0; i < this.chats.length; i++) {
      for(let j = 0; j < this.chats[i].members.length; j++) {
        let uid = this.chats[i].members[j];
        if(users.indexOf(uid) == -1)
          users.push(uid);
      }
    }
    return users;
  }

  public getUsersFromChatMessages(id:string) {
    let chat = this.getChat(id);
    if(!chat) return [];

    let users = [];
    for(let i = 0; i < chat.messages.length; i++) {
      let uid = chat.messages[i].user;
      if(users.indexOf(uid) == -1)
        users.push(uid);
    }
    return users;
  }

  public getChat(id:string) {
    for(let i = 0; i < this.chats.length; i++)
      if(this.chats[i].id == id)
        return this.chats[i];
    return null;
  }

  public getDirectChat(friendId:string) {
    for(let i = 0; i < this.chats.length; i++)
      if(this.chats[i].type == 'direct' && this.chats[i].user == friendId)
        return this.chats[i];
    return null;
  }

  public async getChatMessages(id:any) {
    let chat = this.getChat(id);
    if(!chat)
      return {success: false, message: 'Chat not found.'};

    if(!chat.messages) {
      let response = await this.sendCommand('chat', 'get-chat-messages', {id, type: chat.type});
      if(response.status != 200)
        return {success: false, message: response.body.message};

      chat.messages = response.body.messages;
      
      for(let i = 0; i < chat.messages.length; i++) 
        chat.messages[i].user = response.body.users[chat.messages[i].user];
    }

    return {success: true, messages: chat.messages};
  }

  public async startDirectChat(friendId:string) {
    return await this.startChat({type: 'direct', members: [friendId], user: friendId});
  }

  public async startGroupChat(name:string, members:string[]) {
    return await this.startChat({type: 'group', members, name});
  }

  public async startChat(params: {type:string, members:string[], name?:string, user?:string}) {
    let response = await this.sendCommand('chat', 'start-chat', params);
    if(response.status != 200)
      return {success: false, message: response.body.message};

    this.chats.push({...params, id: response.body.id, date: ''});

    Server.network.sendEvent('chat:' + response.body.id, {id: 'chat-started', chat: response.body.id});    

    return {success: true, id: response.body.id};
  }

  public async updateGroupChat(id:string, name:string, members:string[]) {
    let response = await this.sendCommand('chat', 'update-chat', {type: 'group', id, members, name});
    if(response.status != 200)
      return {success: false, message: response.body.message};

    let chat = this.getChat(id);

    if(chat) {
      chat.members = members;
      chat.name = name;
    }

    Server.network.sendEvent('chat:' + id, {id: 'chat-updated', chat: id});    

    return {success: true};
  }

  public async leaveChat(id:string) {
    let chat = this.getChat(id);
    if(!chat)
      return {success: false, message: 'Chat not found!'};

    let response = await this.sendCommand('chat', 'leave-chat', {id, type: chat.type});
    if(response.status != 200)
      return {success: false, message: response.body.message};

    Server.network.sendEvent('chat:' + id, {id: 'chat-left', chat: id, user: Server.account.getUserId()});    

    for(let i = 0; i < this.chats.length; i++) 
      if(this.chats[i].id == id)
        this.chats.splice(i, 1);
    
    return {success: true};
  }

  public async sendChatMessage(id:string, message:string) {
    let now = new Date();
    let chat = this.getChat(id);
    if(!chat) return;

    if(chat.messages == undefined)
      chat.messages = [];

    chat.messages.push({
      date: now.toISOString(),
      message: message,
      user: Server.user.getId()
    })
  
    let response = await this.sendCommand('chat', 'send-chat-message', {id, message});
    
    if(response.status != 200) {
      chat.messages[chat.messages.length-1].failed = true;
      return {success: false, message: response.body.message};
    }

    chat.date = response.body.date;

    let to = (id == 'community' ? 'online' : 'chat:' + id);
    Server.network.sendEvent(to, {
      id: 'chat-message', 
      chat: id, 
      date: chat.date, 
      message, user: Server.user.getId()
    });    

    return {success: true};
  }

  protected async onChatStarted(event:any) {
    let chat = this.getChat(event.chat);

    if(chat) 
      chat.members.push(event.user);
    else 
      await this.loadChat(event.chat);      

    this.notifyListeners('chat-started', {chat: event.chat});
  }

  protected onChatLeft(event:any) {
    let chat = this.getChat(event.chat);
    if(chat) {
      let i = chat.members.indexOf(event.user);
      if(i != -1)
        chat.members.splice(i, 1);
    }

    this.notifyListeners('chat-left', {chat: event.chat});
  }

  protected async onChatUpdated(event:any) {
    await this.loadChat(event.chat);      
    let chat = this.getChat(event.chat);
    await Server.public.loadProfiles(chat.members);
    this.notifyListeners('chat-updated', {chat: event.chat});
  }

  protected async onChatMessage(event:any) {
    let chat = this.getChat(event.chat);

    if(!chat) {
      console.log('Unable to find chat: ' + event.chat);
      console.log(event);
      return;
    }

    chat.date = event.date;

    if(chat.messages) {
      chat.messages.push({
        date: event.date,
        message: event.message,
        user: event.user
      });
    }

    if(event.user) {
      if(event.user == Server.user.getId()) {
        this.updateLastRead(event.chat);
      }
      else {
        await Server.public.loadProfiles([event.user]);
        let profile = Server.public.getProfile(event.user);
        if(chat.id != 'community')
          sendDeviceNotification(profile.name + ' sent you a message.', 'DeviceNotificationsDirect');
      }
    }

    this.notifyListeners('chat-updated', {chat: event.chat});
  }

  public isNewChatMessages(chatId:string) {
    let chat = Server.chat.getChat(chatId);
    if(!chat) return false;
    if(chat.date == '') return false;

    let chatDate = new Date(chat.date);
    let lastReadCookie = Server.user.getCookie(`LastChatRead-${chat.id}`);
    let lastReadDate = lastReadCookie ? new Date(lastReadCookie) : null;

    if(!lastReadDate || chatDate > lastReadDate)
      return true;

    return false;
  }

  public updateLastRead(chatId:string) {
    let chat = this.getChat(chatId);
    if(!chat)
      return;

    let key = `LastChatRead-${chat.id}`;
    let cookie = Server.user.getCookie(key);

    if(chat.date == undefined || cookie != chat.date) {
      let chatDate = chat.date == undefined ? new Date().toISOString() : chat.date;
      let value = chatDate;
      Server.user.setCookie({key, value});
    }
  }
}
