// import { Site } from 'src/site/site';
import { SoundManager } from '../common/audio/soundManager';
import { UIManager } from '../common/ui/uiManager';
import { Card, CardOverInfo, CardSource, Deck } from './cardsManager';
import { Game } from './game';
import { FloatingText } from '../common/floatingText';
import { numberWithCommas } from '../common/util';
import { Server } from '../../server/server';

/**
 * Dealing - Not used yet, will be used if we show cards quickly being dealt at game start.
 * Playing - State when playing the game.
 * ChooseDirection - State when player is prompted to choose whether card should go to Tableau or Foundation.
 * Traveling - State when card or group of cards are moving from one location to another.
 * Victory - State when the game has been won.
 */
export enum SolitaireState {
  Dealing,
  Playing,
  ChooseDirection,
  Traveling,
  Victory
}

/**
 * Bits set for various game types
 * Bit 0 - Whether to flip 1 or 3 cards.
 * Bit 1 - Whether there are unlimited passes or only 3 passes through the deck.
 * Bit 2 - Whether auto-play is on or off.
 * Bit 3 - Whether a timed game is being played or not.
 */
export enum GameTypeFlags {
  Flip1 = 1,
  UnlimitedPasses = 2,
  Autoplay = 4,
  Timed = 8
}

/**
 * What type of tournament - if a tournament is being played.
 * Timed - A tournament that lasts for a set amount of time.
 * Games - A tournament that lasts for a set number of games.
 */
export enum TournamentType {
  Timed,
  Games
}

/**
 * This is used when a card or group of cards are traveling between on location on the screen and another.
 */
interface TravelingInfo {
  cards: Card[];
  initX: number;
  initY: number;
  finalX: number;
  finalY: number;
  x: number;
  y: number;
  tick: number;
  tickLen: number;
}

class CardsState {
  public deck: Deck;
  public tableau: Card[][];
  public foundation: Card[][];
  public waste: Card[];

  public undoCost: number;
  public passes: number;

  constructor(cs: CardsState = null) {
    let deck = (cs === null) ? null : cs.deck;
    this.deck = new Deck(deck);

    this.initVars();
    if (cs !== null) {
      this.clone(cs)
    }
  }

  /**
   * Initializes the starting values.
   */
  initVars() {
    this.tableau = [];
    for (let i = 0; i < 7; i++) {
      this.tableau[i] = [];
    }

    this.foundation = [];
    for (let i = 0; i < 4; i++) {
      this.foundation[i] = [];
    }

    this.waste = [];

    this.undoCost = 0;
    this.passes = 0;
  }

  /**
   * Clones the the passed in cards state into this card state.
   * @param cs - Cards state to clone.
   */
  clone(cs: CardsState) {
    for (let i = 0; i < 7; i++) {
      let t1 = this.tableau[i];
      let t2 = cs.tableau[i];
      for (let i = 0; i < t2.length; i++) {
        t1[i] = t2[i].clone();
      }
    }

    for (let i = 0; i < 4; i++) {
      let f1 = this.foundation[i];
      let f2 = cs.foundation[i];
      for (let i = 0; i < f2.length; i++) {
        f1[i] = f2[i].clone();
      }
    }

    for (let i = 0; i < cs.waste.length; i++) {
      this.waste[i] = cs.waste[i].clone();
    }

    this.undoCost = cs.undoCost;
    this.passes = cs.passes;
  }
}

export class Solitaire {
  // Retrieved Seeds
  private seedsToUse: number[][];
  private seedsRetrieved: boolean;

  // This controls shuffle result parameters that must be met based upon
  // difficulty variable. 0 is easiest game and 10 is hardest
  // 11 means a downloaded seed was used
  private deckMovesNeededTable = [ 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0, 0 ];
  private tableauMovesNeededTable = [ 4, 4, 3, 3, 2, 2, 2, 1, 1, 1, 0, 0 ];
  private acesPlayableNeededTable = [ 4, 3, 3, 2, 2, 2, 2, 1, 1, 1, 0, 0 ];

  // Debugging
  public giSeed: number;

  // Game parameters
  private seed: number;
  private gameSeed: number;
  private cardsPerFlip: number;
  public passesAllowed: number;
  public useAutoPlay: boolean;
  public timedGame: boolean;
  public scoring: boolean;

  // Set true when any card is played or flipped over from deck
  public firstMoveMade: boolean;
  private skippedLoss: boolean;

  // Used to choose whether shuffled deck will be used
  public handDifficulty: number;
  private numDeckMovesNeeded: number;
  private numTableauMovesNeeded: number;
  private numAcesPlayableNeeded: number;

  // Used to adjust speed that cards are autoplayed - played faster once victory is assured
  private anyTableauCardsFaceDown: boolean;

  // Tournament
  public playingTournament: boolean;
  public tournamentStarted: boolean;
  public tournamentType: TournamentType;
  public tournamentGamesToPlay: number;
  public tournamentTimeToPlay: number;

  public tournamentGamesPlayed: number;
  public tournamentTimeLeft: number;
  public tournamentTimeExpired: boolean;

  public tournamentGamesWon: number;
  public tournamentTotalScore: number;
  public tournamentGameScores: number[];
  public tournamentTotalBaseScore: number;
  public tournamentTotalTimeBonus: number;
  public tournamentTotalWinBonus: number;

  // Floating Text
  private floatingText: FloatingText[];
  private readonly goodTextColor: string;
  private readonly badTextColor: string;
  private readonly neutralTextColor: string;

  // Images
  private readonly nextPassImage: HTMLImageElement;
  private readonly noMorePassesImage: HTMLImageElement;
  private readonly chooseDirectionDownImage: HTMLImageElement;
  private readonly chooseDirectionRightImage: HTMLImageElement;
  private readonly chooseDirectionDownLeftImage: HTMLImageElement;
  private readonly chooseDirectionLeftImage: HTMLImageElement;

  // Kings and Aces have some special rules
  private readonly aceType: number;
  private readonly kingType: number;

  // The size of the drawn cards are slightly different than the click size for them
  private readonly cardWidth: number;
  private readonly cardHeight: number;
  private readonly cardClickWidth: number;
  private readonly cardClickHeight: number;

  // Controls layout of deck, waste, and foundation for left/right handed play
  private shouldFlipLayout: boolean;

  // Deck
  private deckOffset: number;
  private deckSpacing: number;

  // Waste
  private wasteOffset: number;
  private wasteSpacing: number;

  // Choose direction image
  private chooseDirectionOffset: number;

  // Used for spacing cards in the Tableau
  private readonly defaultFaceDownSpacing: number;
  private readonly defaultVertSpacing: number;

  // Foundation
  private foundationOffset: number;
  private foundationSpacing: number;

  // Sounds
  private sfxCardSwish: number;
  private sfxApplause: number;

  // Solitaire game state
  private state: SolitaireState;

  // Scoring
  public baseScore: number;
  public totalScore: number;
  public wonGameBonus: number;
  public timeBonus: number;

  // Previous base score is used by Undo to compute undo cost
  private prevBaseScore: number;

  // Timing and number of passes
  public passes: number;
  public gameTime: number;
  private nextTimeCheck: number;
  private nextTimeSubCheck: number;

  // Game type and various vars that control how scoring works
  private gameType: number;
  private pointsNonTimed: number;
  private readonly pointsCountdownStartGame: number;
  private readonly pointsCountdownStart: number;
  private readonly pointsCountdownMax: number;
  private pointsCountdown: number;
  private pointsCountdownSpeed: number;
  private pointsUndoPenalty: number;
  private pointsUndoCost: number;
  private noAutoPlaceTimePenalty: number;

  // Stats
  public gamesPlayedAllTypes: number;
  public gamesPlayed: number[];
  public gamesWon: number[];
  public wonTotalScore: number[];
  public highScore: number[];
  public bestTime: number[];

  // Awards
  private awardFuncs: Function[];

  private cardPlayedEver: boolean;
  private cardPlayedThisPass: boolean;
  public consecutiveWins: number;
  public consecutiveLosses: number;

  // Current card's state and initial state
  public cs: CardsState;
  private initialCs: CardsState;

  // Previous states of the cards - used for undo
  private cardsStates: CardsState[];

  // The spacing of the 7 tableau rows can be different depending upon how many cards are
  // in them. This is to keep cards from going off the bottom of the screen on rows that
  // have a lot of cards in them.
  private tableauVertSpacing: number[];

  // Card or group of cards that are being dragged by the player
  private drag: Card[];
  private dragCardOverInfo: CardOverInfo;
  private dragX: number;
  private dragY: number;
  private dragSpacing: number;
  private dragMoved: boolean;

  // Info on the cards that are currently traveling
  private traveling: TravelingInfo[];
  private travelSpeed: number;

  // Mouse location info - used when dragging cards
  private initialMouseX: number;
  private initialMouseY: number;
  private lastMouseX: number;
  public lastMouseY: number;

  constructor() {
    // Awards
    this.getAwardOnePass = this.getAwardOnePass.bind(this);
    this.getAwardWins = this.getAwardWins.bind(this);
    this.getAwardSpeedy = this.getAwardSpeedy.bind(this);
    this.getAwardRocket = this.getAwardRocket.bind(this);
    this.getAwardShutout = this.getAwardShutout.bind(this);
    this.getAwardSkunked = this.getAwardSkunked.bind(this);
    this.getAwardJustOne = this.getAwardJustOne.bind(this);
    this.getAwardBazillion = this.getAwardBazillion.bind(this);
    this.awardFuncs = [
      this.getAwardWins, this.getAwardOnePass, this.getAwardSpeedy, this.getAwardRocket, this.getAwardShutout,
      this.getAwardSkunked, this.getAwardJustOne, this.getAwardBazillion
    ];

    // Setting seeds
    this.setSeedsToUseArray();

    // Debugging
    this.giSeed = 0;

    // Default values
    this.seed = 0;
    this.gameSeed = 0;
    this.cardsPerFlip = 3;
    this.passesAllowed = 0;
    this.useAutoPlay = true;
    this.timedGame = true;

    this.aceType = 0;
    this.kingType = 12;
    this.cardWidth = 130;
    this.cardHeight = 186;
    this.cardClickWidth = 114;
    this.cardClickHeight = 170;

    this.defaultFaceDownSpacing = 16;
    this.defaultVertSpacing = 58;

    this.pointsCountdownMax = 100;
    this.pointsCountdownStartGame = this.pointsCountdownMax + 30;
    this.pointsCountdownStart = this.pointsCountdownMax + 2;
    this.pointsCountdownSpeed = 500;

    this.gamesPlayedAllTypes = 0;
    this.gamesPlayed = [];
    this.gamesWon = [];
    this.wonTotalScore = [];
    this.highScore = [];
    this.bestTime = [];
    for (let i = 0; i < 16; i++) {
      this.gamesPlayed[i] = 0;
      this.gamesWon[i] = 0;
      this.highScore[i] = 0;
      this.wonTotalScore[i] = 0;
      this.bestTime[i] = Infinity;
    }
    this.wonGameBonus = 0;
    this.timeBonus = 0;
    this.totalScore = 0;

    this.consecutiveWins = 0;
    this.consecutiveLosses = 0;

    this.floatingText = [];
    this.goodTextColor = '#00aa00';
    this.badTextColor = '#eb521a';
    this.neutralTextColor = '#ffff00';

    this.nextPassImage = new Image();
    this.nextPassImage.src = require('./assets/nextPass.png');

    this.noMorePassesImage = new Image();
    this.noMorePassesImage.src = require('./assets/noMorePasses.png');

    this.chooseDirectionDownImage = new Image();
    this.chooseDirectionDownImage.src = require('./assets/chooseDirectionDown.png');

    this.chooseDirectionRightImage = new Image();
    this.chooseDirectionRightImage.src = require('./assets/chooseDirectionRight.png');

    this.chooseDirectionDownLeftImage = new Image();
    this.chooseDirectionDownLeftImage.src = require('./assets/chooseDirectionDownLeft.png');

    this.chooseDirectionLeftImage = new Image();
    this.chooseDirectionLeftImage.src = require('./assets/chooseDirectionLeft.png');

    this.sfxCardSwish =
        SoundManager.instance.loadSound({src : [ require('./assets/cardSwish.mp3') ], volume : 0.05});
    this.sfxApplause =
        SoundManager.instance.loadSound({src : [ require('./assets/applause.mp3') ], volume : 0.8});

    this.initialCs = new CardsState();
    this.cs = this.initialCs;

    this.travelSpeed = 200;

    this.playingTournament = false;
    this.tournamentStarted = false;
    this.tournamentType = TournamentType.Games;
    this.tournamentTimeToPlay = 6 * 60000;
    this.tournamentGamesToPlay = 3;

    this.shouldFlipLayout = true;
    this.retrieveSettingsFromCookie();
    this.setupForLayoutFlipSettings();

    this.skippedLoss = false;

    this.initVars();
    this.state = SolitaireState.Dealing;
  }

  getTotalGamesWon(): number {
    let totalGamesWon = 0;
    for (let i = 0; i < 16; i++) {
      totalGamesWon += this.gamesWon[i];
    }

    return totalGamesWon;
  }

  getTotalGamesPlayed(): number {
    let totalGamesPlayed = 0;
    for (let i = 0; i < 16; i++) {
      totalGamesPlayed += this.gamesPlayed[i];
    }

    return totalGamesPlayed;
  }

  getTotalGamesLost(): number {
    return this.getTotalGamesPlayed() - this.getTotalGamesWon();
  }

  /**
   * Retrieve ths Solitaire settings from a cookie.
   */
  retrieveSettingsFromCookie() {
    // let settingsString = Site.cookies.get('SolitaireSettings');
    // if (settingsString !== null) {
    //   let settingsSplit = settingsString.split(' ');
    //   this.shouldFlipLayout = (settingsSplit[0] === 'true') ? true : false;
    //   SoundManager.instance.sfxOn = (settingsSplit[1] === 'true') ? true : false;
    // }
  }

  /**
   * Save Solitaire settings to a cookie.
   */
  saveSettingsToCookie() {
    // let settingsString = `${this.shouldFlipLayout} ${SoundManager.instance.sfxOn}`;
    // Site.cookies.set('SolitaireSettings', settingsString);
  }

  /**
   * Toggles layout between left and right
   */
  public toggleFlipLayout() {
    this.shouldFlipLayout = !this.shouldFlipLayout;
    this.setupForLayoutFlipSettings();
    this.saveSettingsToCookie();
  }

  /**
   * Toggles whether sound effects between on and off.
   */
  public toggleSfxOn() {
    SoundManager.instance.sfxOn = !SoundManager.instance.sfxOn;
    this.saveSettingsToCookie();
  }

  /**
   * Toggles whether sound effects between on and off.
   */
  public toggleAutoplay() {
    this.useAutoPlay = !this.useAutoPlay;
    // PlayPlace.instance.broadcastGameEvent('SetAutoplay', this.useAutoPlay);
    // let onOff = this.useAutoPlay ? 'on' : 'off';
    // PlayPlace.instance.sendToast(`Autoplay turned ${onOff}.`, 640, 1500, 42);
  }

  public setAutoplay(useAutoplay: boolean) {
    this.useAutoPlay = useAutoplay;
  }

  /**
   * Sets up the variables to layout cards for left or right one hand play.
   */
  setupForLayoutFlipSettings() {
    if (this.shouldFlipLayout) {
      this.deckOffset = 830;
      this.deckSpacing = 4;

      if (this.cardsPerFlip === 1) {
        this.wasteOffset = 690;
        this.wasteSpacing = 0;
      }
      else {
        this.wasteOffset = Game.instance.cardsManager.useSmallCardArt ? 588 : 650;
        this.wasteSpacing = Game.instance.cardsManager.useSmallCardArt ? 50 : 26;
      }

      this.chooseDirectionOffset = 544;

      this.foundationOffset = 5;
      this.foundationSpacing = 140;
    }
    else {
      this.deckOffset = 5;
      this.deckSpacing = 4;

      if (this.cardsPerFlip === 1) {
        this.wasteOffset = 156;
        this.wasteSpacing = 0;
      }
      else {
        this.wasteOffset = Game.instance.cardsManager.useSmallCardArt ? 146 : 156;
        this.wasteSpacing = Game.instance.cardsManager.useSmallCardArt ? 50 : 26;
      }

      this.chooseDirectionOffset = 278;

      this.foundationOffset = 425;
      this.foundationSpacing = 140;
    }
  }

  /**
   * Returns true when everything need by this class is ready to go.
   */
  isReadyToGo() {
    return this.seedsRetrieved;
  }

  setFirstMoveMade() {
    if (!this.firstMoveMade) {
      this.firstMoveMade = true;
      this.trackGameStart();
    }
  }

  /**
   * Array of know winnable seeds.
   */
  setSeedsToUseArray() {
    this.seedsToUse = [];

    // Flip 1 - Unlimited Passes
    this.seedsToUse[0] = [
      403091817884481, 400082531522572, 399314754711102, 275928412107118, 256186069555745, 242851457165098,
      208621007165800, 207591803434073, 205752418291534, 193932897748494, 143876844924653, 131721255445553,
      111989361556855, 109513592372707, 103383549948344, 98374359371277,  98282797772082,  90520867991799,
      79226473094658,  77998426357849,  74636638992245,  74538188718963,  74050807474459,  48500388802272,
      47962575055565,  47696534951098,  44939340462399,  44502832371135,  43434152725683,  43428357683257,
      43384730680125,  41621609303698,  39455401722166,  33238602714792,  33129633560795,  31655593495770,
      29860388162662,  24078438865372,  23569046214018,  21385569069773,  19667463213026,  14953192213225,
      14543649250149,  14246907126414,  13796317802587,  12523038200919,  10838960901936,  10642814469560,
      9810960905486,   9278241205616,   8116912366912,   7496409468761,   6547478689223,   6223659994426,
      6037236352797,   5508953539021,   5326414787968,   5311787441048,   5223436158775,   4910570311077,
      4642870129139,   4455284759649,   4359224820952,   4313900675158,   4194178211144,   4044638160706,
      3978641160560,   3918987448335,   3916706159665,   3849218409341,   3813404626442,   3796924177938,
      3667181496725,   3653788412494,   3649722445127,   3521715948493,   3510754127969,   3320559256236,
      3293794335088,   3284065011312,   3271728736214,   3237606059701,   3230269116096,   3145912041615,
      3088170017820,   3063811101877,   3054260061956,   3049477515579,   3036858462809,   2985937028236,
      2916266949006,   2905265659721,   2857465022417,   2836746406977,   2835502118922,   2826228233668,
      2792364659071,   2778958965654,   2774159362829,   2768123650187,   2760819593081,   2746716987963,
      2705638046929,   2678275269399,   2655139356173,   2652419087654,   2643488905814,   2635536409898,
      2629439089023,   2626943011807,   2609444869711,   2599949430092,   2531236395988,   2530914430164,
      2519590226779,   2474603977429,   2469790530063,   2447186773483,   2412959964212,   2412682919619,
      2384242247555,   2368750212544,   2336255787885,   2331123350486,   2325527794921,   2321434996425,
      2316633878941,   2291666211868,   2290594976061,   2290132778392,   2289922751711,   2285040537736,
      2283265420470,   2257025363282,   2250335498822,   2248851420821,   2238834065876,   2229436866456,
      2229042320139,   2226980261201,   2220821123152,   2217612782355,   2207105378188,   2206442840990,
      2203016689532,   2197900098783,   2196876756042,   2193923049033,   2189895244160,   2175977561744,
      2175578212106,   2167970961290,   2157433678149,   2153878344821,   2150911308202,   2135021814260,
      2132846234005,   2127579421689,   2126135031399,   2123067679493,   2111618004686,   2111306172226,
      2106137978364,   2100292679393,   2084473927980,   2083126715564,   2074588948012,   2070368205653,
      2067133549204,   2064183897498,   2063189138175,   2062990394399,   2062667784836,   2055987585693,
      2055801475218,   2052444828340,   2051658781989,   2046511131029,   2044418326784,   2043755193967,
      2042596305046,   2042087298699,   2039699845000,   2037331460776,   2031038419913,   2030977935686,
      2029416566528,   2028024058241,   2025827907763,   2024361398848,   2023615304278,   2021860319627,
      2014004028314,   2013387869994,   2012873674290,   2012540999502,   2010978975349,   2010795693138,
      2010135393375,   2008980101145,   2005822322817,   2005643751572,   1997203794218,   1996087172187,
      1995906423583,   1994154342239,   1987895745080,   1986505837497,   1985946165621,   1985096510273,
      1983110125149,   1982476055972,   1981878919019,   1981188326679,   1978931344936,   1974069087587,
      1973050325965,   1972618062373,   1972241542618,   1971480047039,   1971401618384,   1969927250139,
      1967071336426,   1966823157424,   1966586238019,   1962742340581,   1962492925887,   1960241877238,
      1959308253247,   1959231396659,   1955816428279,   1954609936210,   1953841292916,   1951428145067,
      1950511458848,   1949572782461,   1946387053889,   1945825753022,   1945575178039,   1944767625727,
      1943551469163,   1943332042814,   1939191606931,   1938778428973,   1937822860490,   1936509168930,
      1935652326472,   1934559868523,   1932621091920,   1931738439953,   1931027178808,   1928492611391,
      1926641115415,   1926582487346,   1926080939407,   1924534311807,   1922192858317,   1921160358837,
      1920876549916,   1919387690544,   1916325795390,   1916148992335,   1911730513382,   1909945796830,
      1906180330421,   1905963940557,   1905888881799,   1905226056172,   1904313947679,   1903652225141,
      1902925416953,   1902573872019,   1900554571272,   1900453337155,   1899220742335,   1897525762906,
      1896613591343,   1896543230350,   1895034498599,   1894434406590,   1894131854503,   1893893939036,
      1892398120105,   1888133115119,   1887152112557,   1885205335276,   1884855470717,   1884242639237,
      1884046798123,   1883693984623,   1882140545895,   1879336020260,   1879193490398,   1875608684249,
      1875230038575,   1873944212594,   1872681873057,   1872568311947,   1872013921769,   1867948172689,
      1867396423573,   1867298060325,   1866272253931,   1866193303486,   1865884488493,   1865408785346,
      1865314975991,   1864967565119,   1864553148490,   1864492520739,   1863979301542,   1863343528494,
      1862702560916,   1862560174359,   1862437363272,   1862157885071,   1861992211754,   1861799610668,
      1861321339880,   1860994116956,   1860438083702,   1859848702338,   1859223804609,   1859032174042,
      1859026878487,   1858690933962,   1858669851660,   1858579719916,   1858017499396,   1857672370531,
      1857670230983,   1857198689692,   1856880193545,   1856344772546,   1856226411933,   1855924995127,
      1855678486665,   1855593448868,   1855031050520,   1854195367187,   1854124547473,   1853885287223,
      1853561147933,   1853552383808,   1853493850191,   1853388497168,   1852810067675,   1852368217770,
      1852318613356,   1851889471555,   1851771192165,   1851666324016,   1851537973516,   1851134740734,
      1850949438110,   1850753801870,   1849772972841,   1849334762729,   1849105167967,   1848976150857,
      1848619456726,   1848575928578,   1848511249030,   1848390162933,   1848116700831,   1848041431658,
      1847235816146,   1847145918642,   1847101045999,   1846833443117,   1846662298881,   1846632723560,
      1845001318256,   1844836094420,   1844340040254,   1844219411490,   1844068041649,   1843630556250,
      1843408924254,   1842639514053,   1842520959752,   1842373106625,   1841919785620,   1841730959771,
      1841637309304,   1841042325522,   1840885711446,   1840681988965,   1840502768627,   1840094340758,
      1840072731688,   1839549613696,   1839151054671,   1838785803124,   1838725724732,   1837503752086,
      1837485298356,   1836762555564,   1834607529785,   1834503378835,   1834312486403,   1833720391263,
      1833176224534,   1832373180119,   1831703242600,   1830873426562,   1830525819800,   1830496723448,
      1830112638011,   1829897526757,   1829886251874,   1829505541342,   1829438695193,   1829024859321,
      1828931442766,   1828415658360,   1828275794784,   1827971900303,   1826772215418,   1826629799066,
      1826539046495,   1826294211867,   1825670311119,   1825522710822,   1825274736097,   1824289129706,
      1823960347853,   1822899866393,   1822773687221,   1822729654404,   1821962620854,   1820676801869,
      1820663809210,   1819947500639,   1819435115332,   1819393561346,   1819382460695,   1819098151237,
      1818948109417,   1818747614108,   1818328584061,   1818134889643,   1818082344238,   1818070828389,
      1817125698141,   1816967939224,   1816367252997,   1816081438943,   1815442735227,   1814128352456,
      1813638887844,   1813560244206,   1812747291266,   1812575159654,   1812102691979,   1812062048050,
      1811633447283,   1811196191629,   1810804378186,   1810678641554,   1810546990827,   1809929425219,
      1809668968493,   1809295923793,   1808754773887,   1808344639531,   1807980443957,   1806959655066,
      1806871671526,   1806567760578,   1806319891500,   1806208333953,   1806165794272,   1806013202548,
      1805674754503,   1804953660981,   1804911677476,   1804414292167,   1804408473732,   1804029137848,
      1803588986602,   1803227360842,   1803111280369,   1802978648663,   1802568241606,   1802471206413,
      1801718276740,   1801680291562,   1801395003732,   1800937288445,   1800553981076,   1800025857361,
      1799982219055,   1799143720259,   1798730472365,   1798294924184,   1798202601337,   1797766003992,
      1797346769276,   1796198150061,   1795919843408,   1795241127043,   1794965198151,   1794485678634,
      1793991826780,   1793917321708,   1793684604186,   1793542525117,   1793421295248,   1793405052556,
      1793131723109,   1792597922491,   1791868357941,   1791571312461,   1791167602803,   1790344531666,
      1789478905411,   1789366834641,   1789213932281,   1789104116474,   1788369651905,   1788174738111,
      1788014410847,   1785631979642,   1785361034625,   1785165520127,   1784727435453,   1784722543686,
      1783873004399,   1783787489175,   1783452164838,   1783420516930,   1783237072278,   1783190084757,
      1783172041715,   1782991006751,   1782806261604,   1782443937697,   1782097924970,   1781238533258,
      1780964001572,   1780710100081,   1780289951671,   1780067983761,   1780065445979,   1779716774970,
      1779697180619,   1778905656993,   1778815403879,   1777735961923,   1776204888354,   1776081647613,
      1775543546231,   1775065635828,   1774305475606,   1774100873098,   1773983360039,   1773915690143,
      1773458115612,   1773214750062,   1772604276640,   1772509587931,   1772460413685,   1772212032302,
      1771434624047,   1771170634113,   1770246133183,   1770213871776,   1770008921198,   1769952534894,
      1769534102279,   1769331131049,   1769307405817,   1769119376219,   1768728901833,   1768468226077,
      1768108054619,   1768093940687,   1767486262162,   1766775304465,   1766425435453,   1765956060222,
      1765723110201,   1765179439175,   1764626857058,   1764590284565,   1764280979783,   1763557443957,
      1763398629026,   1763390042313,   1762960094919,   1762835441562,   1762375583698,   1762135087748,
      1761935792458,   1761640093948,   1761561526194,   1761066209924,   1760251473214,   1759683227373,
      1758836941144,   1757962421909,   1757448301699,   1757264293931,   1756835939499,   1756483533292,
      1756408173630,   1754648897343,   1754033283999,   1753945975488,   1753886447581,   1753788287295,
      1753116562913,   1752821472634,   1752734842084,   1752362506242,   1752229931879,   1751417414592,
      1751313112294,   1750944104037,   1750668736030,   1750576590226,   1749746406394,   1749480872345,
      1749235661951,   1749148298397,   1748974825659,   1748771513958,   1748614976284,   1748383480515,
      1747370774912,   1746709607216,   1746469635327,   1746140699535,   1745910181945,   1745613117334,
      1745324059416,   1744772744842,   1744133832086,   1742915818948,   1742903384009,   1742650341340,
      1742244021079,   1742128121215,   1742087372573,   1741940005688,   1741843952715,   1741414808789,
      1741026321797,   1740859297288,   1740773955369,   1740692885991,   1740365941763,   1739946857348,
      1739929283627,   1739889898063,   1739215597313,   1738951997912,   1738448486361,   1738310570760,
      1737646932719,   1737508576260,   1737093982121,   1736720165194,   1735838436499,   1735267128354,
      1735251354524,   1735189828558,   1734974202290,   1733407598117,   1733387880341,   1733099699140,
      1733080899253,   1733067882562,   1732859114242,   1732758260980,   1732756319366,   1732146896785,
      1732039825250,   1731759478845,   1730992955439,   1730826163772,   1730591348979,   1730228567661,
      1729229064293,   1729166374437,   1728912112191,   1728788487149,   1728614553775,   1728224985540,
      1727943938262,   1727028200358,   1726966499541,   1726800070506,   1726220727639,   1726031710979,
      1726030004858,   1725666920091,   1725606084417,   1725103848435,   1724908635637,   1724861101900,
      1724833099788,   1724786120873,   1724710798931,   1724663494776,   1724498182891,   1724370591670,
      1724337056860,   1723937168788,   1723858923516,   1723397908907,   1723343944575,   1723093128055,
      1722913147372,   1722737405324,   1722348155263,   1721796013700,   1721638245158,   1721107692507,
      1720640412026,   1718185793983,   1717917076802,   1717858987367,   1716539065395,   1716303204709,
      1716213379190,   1716164980355,   1715834710542,   1715728863076,   1715607286048,   1715206876527,
      1714148642772,   1713710720198,   1713206055113,   1712691448591,   1712469473513,   1712358244530,
      1712141099610,   1711981644521,   1711937190340,   1711413604679,   1710599302850,   1709932125249,
      1709531279359,   1709482906568,   1709424706344,   1709057971682,   1708338156065,   1708156056862,
      1708062752138,   1708043704606,   1707943206182,   1707630814194,   1705605490761,   1704949641094,
      1704659590540,   1704533935619,   1704042614530,   1702990021697,   1702371553870,   1702173609742,
      1701145494673,   1699787432050,   1699744636292,   1699514938013,   1699197142568,   1699003250846,
      1698522012893,   1697933102891,   1697190037968,   1696770013427,   1696638671031,   1696480152607,
      1696386948951,   1696290837661,   1696061203122,   1696011011811,   1695944141126,   1695828457966,
      1694589169586,   1694421915395,   1694413852769,   1694234029465,   1694075877451,   1691758199946,
      1691719612237,   1691549498691,   1691315435744,   1691214755103,   1690826822050,   1690489691463,
      1690320878833,   1690027205561,   1689573122653,   1688957214605,   1688910755922,   1688870716113,
      1688638806464,   1687683914388,   1687549364060,   1686499951032,   1686001207622,   1685881841188,
      1685863934553,   1685131248050,   1685029198934,   1684873898071,   1684601208260,   1684378629041,
      1684364173292,   1684160097854,   1683858314921,   1683478903946,   1683459236074,   1682691493900,
      1682680132777,   1682351111316,   1681902849981,   1681668922700,   1681071041069,   1680141526386,
      1679382896961,   1678519640705,   1678370272611,   1677798108344,   1677605691353,   1677464425061,
      1676075213286,   1675509690861,   1675309237806,   1674423077926,   1673446607783,   1673416274328,
      1673361316652,   1672989111541,   1672840533783,   1672438292705,   1671640611982,   1671393906487,
      1670739050499,   1670642693671,   1670399602815,   1670148440117,   1669706813354,   1669404247784,
      1668915951803,   1667842982734,   1667568189651,   1666535043124,   1666417366556,   1666315125191,
      1666311647721,   1666264399951,   1666198589884,   1666110501018,   1665612351161,   1665457107322,
      1665225441520,   1664871854201,   1664496152160,   1664339171247,   1663949366459,   1663796779859,
      1662691637123,   1662570339588,   1662525498233,   1661267124994,   1660482402660,   1659683617452,
      1659042529983,   1658801659276,   1658755759186,   1658656284116,   1657155356501,   1656958440368,
      1656806818505,   1656318229509,   1654658978259,   1654554504444,   1654468212091,   1654165485145,
      1654000036653,   1652575213897,   1652408580348,   1651948922823,   1651515091778,   1651269576907,
      1651230205324,   1651022701447,   1650034668835,   1649519665782,   1649417743395,   1648375451098,
      1647310803224,   1647170816558,   1646540916120,   1646364232200,   1646093943905,   1645888432811,
      1645557938206,   1645533497643,   1644978461589,   1644819741606,   1644083053965,   1643927343895,
      1643911155946,   1643599746594,   1643570656943,   1643268332976,   1642363821143,   1641403587357,
      1641288423514,   1641106094198,   1641077564372,   1640804517427,   1640551431051,   1639987881236,
      1639641568134,   1639601281208,   1638619205134,   1638522224827,   1638354070345,   1638214913680,
      1638177538831,   1637627292455,   1637391368453,   1637218939456,   1637046575765,   1636114652146,
      1635735666200,   1635531397875,   1635276305384,   1634773502931,   1634584563172,   1634489812216,
      1633858962501,   1633844546241,   1633824826972,   1633805186824,   1633430281700,   1633420426468,
      1633402913785,   1633364131953,   1632838062705,   1632194634132,   1631764996289,   1631742347638,
      1631606154897,   1630637975032,   1630627047997,   1629770173321,   1629472389434,   1629321224637,
      1628950424847,   1628767424598,   1626866381893,   1625864568185,   1625732973551,   1625446573060,
      1625302960768,   1623843380829,   1623650844365,   1623214246406,   1622689935811,   1622279775446,
      1621981527405,   1621426388980,   1621294932552,   1621121974456,   1621091522583,   1619912430534,
      1619649067722,   1619575746513,   1619378614013,   1619151915264,   1618284084344,   1618230601936,
      1618021477131,   1616821287100,   1616812231385,   1616444412057,   1616140463052,   1615394847843,
      1615263104276,   1615190188348,   1614661407530,   1614253853573,   1614213234040,   1613235648383,
      1613154621913,   1612689091565,   1611783377351,   1611483636739,   1611413121428,   1611390386910,
      1611128680943,   1611066472916,   1610966432262,   1610933848067,   1610591852425,   1610427298578,
      1610120484161,   1609191159100,   1608647327541,   1608620940157,   1608467836641,   1607868751180,
      1607853904242,   1607644751915,   1607613583849,   1607557302016,   1607267418778,   1607019242274,
      1606385468737,   1605978776943,   1605805900040,   1602992801271,   1602787749382,   1602546480388,
      1601887164427,   1601192483403,   1600795720127,   1599690810245,   1597869174110,   1597407160582,
      1594602870171,   1594443782509,   1594201056512,   1594064337274,   1592690265493,
    ];

    // Flip 1 - 3 Passes
    this.seedsToUse[1] = [
      82203853530527,
      6674822276642,
      2393279534768,
      1948273197294,
      1819674468355,
      1800561358576,
      1772518248260,
      1636530329410,
      1619170376255,
      1594057531824,
    ];

    // Flip 3 - Unlimited Passes
    this.seedsToUse[2] = [
      3142804887700,
      2286781662490,
      1830007569085,
    ];

    // Flip 3 - 3 Passes
    this.seedsToUse[3] = [];

    this.seedsRetrieved = true;
  }

  /**
   * Displays the passed in toast.
   * @param toast - Toast to show
   */
  public showToast(text: string, yLoc: number, duration: number, fontSize: number) {
    if (Game.instance.playing) {
      for (let i = 0; i < text.length; i++) {
        let ft = new FloatingText(text[i], 475, yLoc, this.goodTextColor, duration, fontSize);
        this.floatingText.push(ft);
        yLoc += fontSize + 10;
        duration += 500;
      }
    }
  }

  /**
   * Remove all the floating text from the screen.
   */
  removeAllFloatingText() {
    this.floatingText = [];
  }

  /**
   * Returns a value between 0 and 15 which is the game type.
   * @param cardsPerFlip - 1 or 3
   * @param passesAllowed - 0 or 3 (0 means infinity)
   * @param useAutoPlay - Whether to use auto play
   * @param timedGame - Wether this is a timed game
   */
  getGameType(cardsPerFlip: number, passesAllowed: number, useAutoPlay: boolean, timedGame: boolean) {
    // Using bits to set game type - value is from 0 to 15
    let gameType = 0;
    if (cardsPerFlip === 1) {
      gameType = gameType | GameTypeFlags.Flip1;
    }
    if (passesAllowed === 0) {
      gameType = gameType | GameTypeFlags.UnlimitedPasses;
    }
    if (useAutoPlay) {
      gameType = gameType | GameTypeFlags.Autoplay;
    }
    if (timedGame) {
      gameType = gameType | GameTypeFlags.Timed;
    }

    return gameType;
  }

  /**
   * Called whenever a new game of solitaire is started.
   */
  public initVars() {
    this.gameType = this.getGameType(this.cardsPerFlip, this.passesAllowed, this.useAutoPlay, this.timedGame);

    this.setupForLayoutFlipSettings();

    // Tourney Games always count as started
    this.firstMoveMade = false;
    if (this.playingTournament) {
      this.setFirstMoveMade();
    }

    this.anyTableauCardsFaceDown = true;

    this.state = SolitaireState.Playing;
    this.baseScore = 0;
    this.passes = 1;
    this.gameTime = 0;
    this.nextTimeCheck = 1;

    this.prevBaseScore = 0;

    this.pointsNonTimed = 100;
    this.pointsCountdown = this.pointsCountdownStartGame;

    this.pointsUndoPenalty = 50;

    this.cardPlayedEver = false;
    this.cardPlayedThisPass = false;

    // Cards state used for undo
    this.cs.initVars();
    this.cardsStates = [];

    this.tableauVertSpacing = [];
    for (let i = 0; i < 7; i++) {
      this.tableauVertSpacing[i] = this.defaultVertSpacing;
    }

    this.noAutoPlaceTimePenalty = 0;

    this.drag = [];
    this.traveling = [];

    this.lastMouseX = 0;
    this.lastMouseY = 0;
  }

  /**
   * Inits some vars when the first game in a tournament is started.
   */
  initTournamentVars() {
    if (!this.tournamentStarted) {
      this.tournamentStarted = true;
      this.tournamentTimeLeft = this.tournamentTimeToPlay;
      this.tournamentTimeExpired = false;
      this.tournamentGamesPlayed = 0;
      this.tournamentGamesWon = 0;
      this.tournamentTotalScore = 0;
      this.tournamentTotalBaseScore = 0;
      this.tournamentTotalTimeBonus = 0;
      this.tournamentTotalWinBonus = 0;
      this.tournamentGameScores = [];
    }
  }

  /**
   * Sets the seed that'll be used.
   * @param seed - Seed value to use
   */
  setSeed(seed: number) {
    this.seed = seed;
  }

  /**
   * Returns a random seed from the array of seeds to use.
   */
  getKnownSeed(): number {
    let seedsToUseIndex = (this.cardsPerFlip === 3) ? 2 : 0;
    seedsToUseIndex += (this.passesAllowed === 3) ? 1 : 0;
    if (this.seedsToUse[seedsToUseIndex].length === 0) {
      return 0;
    }

    let index = this.cs.deck.randGen.getRandMod(this.seedsToUse[seedsToUseIndex].length);
    return this.seedsToUse[seedsToUseIndex][index];
  }

  /**
   * Called to start the next tournament game. Uses the existing game parameters that were
   * set when the first tournament game was started.
   */
  public startNextTournamentGame() {
    this.startGame(this.cardsPerFlip, this.passesAllowed, this.useAutoPlay, this.timedGame, this.scoring);
  }

  /**
   * First decides on hand difficulty and then uses that to set acceptable shuffle results.
   */
  private setShuffleParameters() {
    // let numPlayers = Site.room.isActive() ? Site.room.players.length : 1;
    let numPlayers = 1;

    if (numPlayers === 1) {
      let randVal = this.cs.deck.randGen.getRandMod(100);

      // This is a single player game/tourney
      if (this.skippedLoss) {
        // If skippedLoss in last game then take whatever next hand is dealt
        this.handDifficulty = 10;
        this.skippedLoss = false;
      }
      else if (this.consecutiveLosses > 1 || randVal < 5) {
        // 5% chance of using a known winnable seed or will use if player has 2 losses in a row
        let newSeed = this.getKnownSeed();
        if (newSeed === 0) {
          // Fallback in case we have no known seeds
          this.handDifficulty = this.cs.deck.randGen.getRandMod(4);
        }
        else {
          this.seed = newSeed;
          this.handDifficulty = 11;
        }
      }
      else if (this.cs.deck.randGen.getRandMod(100) < 40) {
        // 35% chance of just using whatever was dealt
        this.handDifficulty = 10;
      }
      else {
        // Otherwise just using a random difficulty weighted towards middle values
        this.handDifficulty = this.cs.deck.randGen.getRandMod(6) + this.cs.deck.randGen.getRandMod(6);
      }
    }
    else {
      // This is a multi-player game/tourney
      let gamesPlayed = (this.playingTournament) ? this.tournamentGamesPlayed : this.gamesPlayed[this.gameType];
      if (!this.playingTournament || this.tournamentType === TournamentType.Games) {
        gamesPlayed = gamesPlayed % 5;
      }

      if (gamesPlayed === 0) {
        // In first game just use whatever was dealt
        this.handDifficulty = 10;
      }
      else if (gamesPlayed === 1) {
        // In second game played use a known winnable seed
        let newSeed = this.getKnownSeed();
        if (newSeed === 0) {
          // Fallback in case we have no known seeds
          this.handDifficulty = this.cs.deck.randGen.getRandMod(10);
        }
        else {
          this.seed = newSeed;
          this.handDifficulty = 11;
        }
      }
      else {
        // After third game generate two random 0-5 values to get difficulty
        let rollBase = 0;
        let roll1Mod = 6;
        let roll2Mod = 6;

        if (gamesPlayed === 2) {
          // In third game use roll that is biased too slightly easier
          rollBase = -1;
          roll1Mod = 5;
          roll2Mod = 6;
        }

        // Now compute hand difficulty to use
        let difficulty =
            rollBase + this.cs.deck.randGen.getRandMod(roll1Mod) + this.cs.deck.randGen.getRandMod(roll2Mod);
        this.handDifficulty = Math.max(0, Math.min(10, difficulty));
      }
    }

    //Use difficulty to set parameters needed to accept a shuffle result
    this.numDeckMovesNeeded = this.deckMovesNeededTable[this.handDifficulty];
    this.numTableauMovesNeeded = this.tableauMovesNeededTable[this.handDifficulty];
    this.numAcesPlayableNeeded = this.acesPlayableNeededTable[this.handDifficulty];
  }

  /**
   * Starts up a game with the passed in parameters.
   * @param cardsPerFlip - 1 or 3
   * @param passesAllowed - 3 or Unlimited
   * @param useAutoPlay - true or false
   * @param timedGame - true or false
   */
  public startGame(cardsPerFlip: number, passesAllowed: number, useAutoPlay: boolean, timedGame: boolean, scoring: boolean, flipLayout:boolean = true) {
    this.cardsPerFlip = cardsPerFlip;
    this.passesAllowed = passesAllowed;
    this.useAutoPlay = useAutoPlay;
    this.timedGame = timedGame;
    this.scoring = scoring;
    this.shouldFlipLayout = flipLayout;

    this.initVars();
    this.initTournamentVars();

    this.cs.deck.create();

    this.cs.deck.seedDeck(this.seed);
    this.setShuffleParameters();

    let seed = this.seed;

    this.gamesPlayedAllTypes++;
    this.gamesPlayed[this.gameType]++;
    if (this.playingTournament) {
      this.tournamentGamesPlayed++;
    }

    // We will loop through until we find a shuffle that matches what we need
    let done = false;
    let count = 1;
    while (!done) {
      this.cs.deck.seedDeck(seed);

      // Need to save off our deck before dealing in case we don't use this shuffle
      let storedCards = [];
      for (let i = 0; i < 52; i++) {
        storedCards[i] = this.cs.deck.cards[i];
      }

      // Shuffle and deal
      this.cs.deck.shuffle();
      this.deal();

      // Check if all our conditions are met to accept this shuffle
      let numValidDeckMoves = this.getNumValidMovesFromDeck();
      let numValidTabMoves = this.getNumValidMovesFromTableau();
      let numAcesPlayable = this.getNumAcesPlayable();

      if (this.handDifficulty === 11) {
        // Using a winnable seed - make so doesn't look too easy
        //done = (numValidTabMoves < 3 && numValidDeckMoves < 7);
        // console.log(` seed ${seed}`);
        done = true;
      }
      else {
        done = (numValidDeckMoves >= this.numDeckMovesNeeded && numValidTabMoves >= this.numTableauMovesNeeded &&
                numAcesPlayable >= this.numAcesPlayableNeeded);
      }

      // Make sure we aren't stuck in this loop forever
      done = done || (count > 8000);
      if (!done) {
        // Not yet done set up for checking the next shuffle result
        count++;
        this.cs.deck.resetDeck(storedCards);
        if (this.handDifficulty === 11) {
          seed = this.getKnownSeed();
          console.log(`---Next known seed ${seed}`);
        }
        else {
          seed = this.cs.deck.getUpdatedSeed();
        }
      }
      else {
        // Got what we need to start this game/round
        this.cs.deck.seed = seed;
        this.gameSeed = seed;
        // console.log(`D: ${this.handDifficulty}`);
      }
    }

    // Seed for next game (only matters if in tourney that doesn't return to StartGame dialog)
    this.seed = this.cs.deck.getUpdatedSeed();

    // For undo
    this.saveCardsState();

    // start off with zero score in PP
    if(this.playingTournament) {
      let firstGame = false;
      if(this.tournamentType == TournamentType.Timed)
        firstGame = (this.tournamentTimeLeft == this.tournamentTimeToPlay);
      else
        firstGame = (this.tournamentGamesPlayed == 1);

      if(firstGame)
        this.synchForPlayPlace(0);
    }
  }

  public clear() {
    this.timedGame = false;
    this.initVars();
    this.initTournamentVars();
    this.cs.deck.create();
  }

  /**
   * Returns the points used by the countdown bonus.
   */
  public getCountdownPoints(): number {
    let pointsToShow = this.pointsNonTimed;
    if (this.timedGame) {
      pointsToShow = Math.min(this.pointsCountdownMax, this.pointsCountdown);
    }

    return pointsToShow;
  }

  /**
   * Returns true if there is an undo card state available.
   */
  public canUndo(): boolean {
    return (this.cardsStates.length > 1);
  }

  /**
   * Saves the current cards state to the array that is used for undo.
   */
  saveCardsState() {
    this.pointsUndoCost = this.baseScore - this.prevBaseScore;
    let cardsState = new CardsState(this.cs);
    cardsState.undoCost = this.pointsUndoCost;
    cardsState.passes = this.passes;
    this.cardsStates.push(cardsState);

    this.prevBaseScore = this.baseScore;
  }

  /**
   * For testing.
   */
  dumpCardsStates() {
    for (let i = 0; i < this.cardsStates.length; i++) {
      console.log(` ${i}: $ {
        this.cardsStates[i].undoCost
      }
      `);
    }
  }

  /**
   * Returns to the previous cards state if any.
   */
  undo() {
    if (this.cardsStates.length > 1) {
      this.cardsStates.pop();
      this.cs = new CardsState(this.cardsStates[this.cardsStates.length - 1]);

      this.verifyBottomRow();

      // Removes points earned from this move that is being undone and also
      // takes off a few more points as a penalty
      let scoreChange = -(this.pointsUndoCost + this.pointsUndoPenalty);
      if (scoreChange !== 0) {
        //this.addToScore(scoreChange, (this.cardWidth / 2), 30 + (this.cardClickHeight / 2));

        // Close enough
        let y = 520; //GameScreen.instance.gameElement.localToGameY(520);
        this.addToScore(scoreChange, 920, y);
        this.prevBaseScore = this.baseScore;
      }

      this.passes = this.cs.passes;

      // Keeping Undo penalty constant to see how it feels - commenting out following code:

      /*
      // Undo penalty rises by 5 points for every undo up to a maximum 50 point penalty
      this.pointsUndoCost = this.cs.undoCost;
      if (this.pointsUndoPenalty < 25) {
        this.pointsUndoPenalty += 5;
      }*/
    }
  }

  /**
   * Updates the passed in score to PlayPlace so it can be passed on to the other players.
   * @param score - Score to set
   */
  synchForPlayPlace(score: number) {
    // if(Site.room.isActive())
    //   Site.room.setLocalPlayerState('score', score);
    if(Game.instance.isMultiplayer())
      Server.places.setScore(score, Game.instance.getPlaceHost());
  }

  /**
   * Adds the passed in milliSeconds to the game time and creates a floating text of time addition.
   * @param milliSeconds - Time in milliseconds to add
   */
  public addToTime(milliSeconds: number, x: number, y: number) {
    this.gameTime += milliSeconds;
    let seconds = Math.floor(milliSeconds / 1000);
    let floatingText = (seconds === 1) ? `+${seconds} Second` : ` + ${seconds} Seconds`;
    let ft = new FloatingText(floatingText, x, y, this.badTextColor, 1200, 24);
    this.floatingText.push(ft);
  }

  /**
   * Called when game is won, lost, or time has expired.
   * @param amount - Amount to add to tournament score
   */
  addToTournamentScore(baseScore: number, timeBonus: number, winBonus: number) {
    let gameScore = (baseScore + timeBonus + winBonus);
    this.tournamentGameScores.push(gameScore);
    this.tournamentTotalBaseScore += baseScore;
    this.tournamentTotalTimeBonus += timeBonus;
    this.tournamentTotalWinBonus += winBonus;
    this.tournamentTotalScore += gameScore;
    this.synchForPlayPlace(this.tournamentTotalScore);
  }

  /**
   * Adds the passed in value to the base score
   * @param amount - Amount to add
   */
  public addToBaseScore(amount: number) {
    this.baseScore += amount;
    if (this.baseScore < 0) {
      this.baseScore = 0;
    }

    let scoreForPlayPlace = this.baseScore;
    if (this.playingTournament) {
      scoreForPlayPlace += this.tournamentTotalScore;
    }

    this.synchForPlayPlace(scoreForPlayPlace);
  }

  /**
   * Adds the passed in value to the base score and creates a floating text of the amount
   * to display.
   * @param amount - Amount to add
   */
  public addToScore(amount: number, x: number, y: number) {
    if(!this.scoring)
      return;
    this.addToBaseScore(amount);
    let floatingText = (amount >= 0) ? `+${amount}` : `${amount}`;
    let fillStyle = (amount >= 0) ? this.neutralTextColor : this.badTextColor;

    let ft = new FloatingText(floatingText, x, y, fillStyle);
    this.floatingText.push(ft);
  }

  /**
   * Adds points from the countdown points timer and the resets it back to
   * its start amount.
   */
  public addPointsCountdown(x: number, y: number, multiplier: number = 1) {
    let pointsToAdd = multiplier * this.pointsNonTimed;
    if (this.timedGame) {
      pointsToAdd = multiplier * Math.min(this.pointsCountdownMax, this.pointsCountdown);
    }
    this.addToScore(pointsToAdd, x, y);
    this.pointsCountdown = this.pointsCountdownStart;
  }

  /**
   * Uses source and dest to compute how many points to add to the score.
   * @param source - Where card came from
   * @param dest - Where card went
   */
  public computeAndAddToScore(source: CardSource, dest: CardSource, x: number, y: number) {
    if (source === CardSource.Waste) {
      if (dest === CardSource.Tableau) {
        this.addPointsCountdown(x, y);
      }
      else if (dest === CardSource.Foundation) {
        // Double points for this because it is bypassing tableau
        this.addPointsCountdown(x, y, 2);
      }
    }
    else if (source === CardSource.Tableau) {
      if (dest === CardSource.Foundation) {
        this.addPointsCountdown(x, y);
      }
    }
  }

  /**
   * Called when the deck end is reached and the player clicks to go back
   * to the start of the deck.
   */
  handleScoreDeckReset() {
    // In non-timed games the points earned are less for each pass through the deck
    if (this.pointsNonTimed > 40) {
      this.pointsNonTimed -= 20;
    }
    else if (this.pointsNonTimed > 20) {
      this.pointsNonTimed -= 10;
    }
    else if (this.pointsNonTimed > 10) {
      this.pointsNonTimed -= 2;
    }
  }

  /**
   * Computes how much the time bonus is after a game is completed.
   */
  getTimeBonusScore(): number {
    if (this.timedGame) {
      // Curve fitting roughly this:
      // 10:00 (or longer):    100 points
      //              5:00:    500 points
      //              3:30:  1,000 points
      //              3:00:  1,800 points
      //              2:30:  3,000 points
      //              2:00:  4,000 points
      //              1:45:  6,000 points
      //              1:00: 20,000 points
      //        under 1:00: 20,000 points + (some extra based on time)
      if (this.gameTime > 600000) {
        return 100;
      }

      // This is the curve fitting formula
      let extra = this.gameTime < 60000 ? (60000 - this.gameTime) * 4 : 0;
      let time = Math.max(this.gameTime, 60000);
      let pow = Math.pow(time, -2.206721);
      let bonus = 700318700000000 * pow + extra;
      return Math.floor(bonus);
    }

    // No time bonus if not a timed game
    return 0;
  }

  /**
   * Returns array of strings containing awards won.
   */
  getAwardsWon(): string[] {
    let awardsWon = [];
    if (!this.getTournamentTimeExpired()) {
      for (let i = 0; i < this.awardFuncs.length; i++) {
        let result = this.awardFuncs[i]();
        if (result !== null) {
          awardsWon.push(result.awardText);
        }
      }
    }

    return awardsWon;
  }

  /**
   * Returns array of strings containing awards won and bonuses for those awards.
   */
  getAwardsInfo(): string[] {
    let info = [];
    if (!this.getTournamentTimeExpired()) {
      for (let i = 0; i < this.awardFuncs.length; i++) {
        let result = this.awardFuncs[i]();
        if (result !== null) {
          let bonusStr = '';
          if (result && result.awardBonus > 0)
            bonusStr = '\n+' + numberWithCommas(result.awardBonus);
          info.push(result.awardText + bonusStr);
        }
      }
    }

    return info;
  }

  /**
   * Returns the total number of points earned from awards.
   */
  public getAwardPoints(): number {
    let awardPoints = 0;
    if (!this.getTournamentTimeExpired()) {
      for (let i = 0; i < this.awardFuncs.length; i++) {
        let result = this.awardFuncs[i]();
        if (result !== null) {
          awardPoints += result.awardBonus;
        }
      }
    }

    return awardPoints;
  }

  /**
   * Returns the bonus amount for the passed in consecutive wins.
   * @param consecutiveWins - Number of consecutive wins
   */
  getWinsAwardBonus(consecutiveWins: number): number {
    if (consecutiveWins < 2) {
      return 0;
    }

    if (consecutiveWins === 2) {
      return 2500;
    }

    if (consecutiveWins === 3) {
      return 5000;
    }

    // Sum of geometric series
    let n = consecutiveWins - 2;
    let a1 = 5000;
    let r = 1.95;
    let rn = Math.pow(r, n);
    let s = 1000 * Math.floor((a1 * (1 - rn) / (1 - r)) / 1000);

    // Cap bonus at 20 million
    if (s > 20000000) {
      s = 20000000;
    }

    return s;
  }

  /**
   * Returns bonus and text for 2 or more consecutive wins.
   */
  getAwardWins(): any {
    if (this.consecutiveWins < 2) {
      return null;
    }

    let awardText = '';
    let awardBonus = this.getWinsAwardBonus(this.consecutiveWins);
    if (this.consecutiveWins === 2) {
      awardText = 'DOUBLE';
    }
    else if (this.consecutiveWins === 3) {
      awardText = 'TURKEY';
    }
    else if (this.consecutiveWins === 4) {
      awardText = 'QUATRO';
    }
    else if (this.consecutiveWins === 5) {
      awardText = 'PENTA';
    }
    else if (this.consecutiveWins >= 6) {
      awardText = `WIN ${this.consecutiveWins}`;
    }

    return {awardText, awardBonus};
  }

  /**
   * The Speedy Award is when there the game is won in less than 2 minutes.
   */
  getAwardSpeedy(): any {
    if (!this.getWonGame()) {
      return null;
    }

    if (this.timedGame && this.gameTime < 120000 && this.gameTime >= 90000) {
      return {awardText : 'SPEEDY', awardBonus : 10000};  // 50000
    }

    return null;
  }

  /**
   * The Rocket Award is when there the game is won in less than 2 minutes.
   */
  getAwardRocket(): any {
    if (!this.getWonGame()) {
      return null;
    }

    if (this.timedGame && this.gameTime < 90000) {
      return {awardText : 'ROCKET', awardBonus : 25000};   // 1000000 
    }

    return null;
  }

  /**
   * The Shutout Award is when the player isn't able to play one card anywhere.
   */
  getAwardShutout(): any {
    if (!this.checkGameOver() || this.cardPlayedEver) {
      return null;
    }

    return {awardText : 'SHUTOUT', awardBonus : 0};
  }

  /**
   * The Skunked Award is when no cards where played to the foundation.
   */
  getAwardSkunked(): any {
    if (!this.checkGameOver()) {
      return null;
    }

    for (let f = 0; f < 4; f++) {
      if (this.cs.foundation[f].length !== 0) {
        return null;
      }
    }

    return {awardText : 'SKUNKED', awardBonus : 0};
  }

  /**
   * The Just One Award is when there is only one face-down card on the tableau.
   * Note: This is really impossible to have happen and not be able win.
   */
  getAwardJustOne(): any {
    if (!this.checkGameOver()) {
      return null;
    }

    let faceDownCount = 0;
    for (let t = 0; t < 7; t++) {
      let tableau = this.cs.tableau[t];
      for (let i = 0; i < tableau.length; i++) {
        if (!tableau[i].faceUp) {
          faceDownCount++;
        }
      }
    }

    if (faceDownCount !== 1) {
      return null;
    }

    return {awardText : 'JUST ONE', awardBonus : 0};
  }

  /**
   * The One Pass Award is when the player wins the game with only one pass through the deck.
   */
  getAwardOnePass(): any {
    if (this.getWonGame() && this.passes === 1) {
      return {awardText : 'ONE PASS', awardBonus : 10000};  // 500000
    }

    return null;
  }

  getAwardBazillion(): any {
    // if (this.getWonGame() && this.handDifficulty !== 11) {
    //   return {awardText : 'ONE IN\nBAZILLION', awardBonus : 20000};
    // }

    return null;
  }

  /**
   * Returns true if the player has won the game.
   */
  getWonGame(): boolean {
    return (this.state === SolitaireState.Victory);
  }

  /**
   * Returns true if the tournament time has expired.
   */
  getTournamentTimeExpired(): boolean {
    return this.playingTournament && this.tournamentType === TournamentType.Timed && this.tournamentTimeExpired;
  }

  /**
   * Returns the stack trace as a string.
   */
  getStackTrace(): string {
    var err = new Error();
    return err.stack;
  }

  /**
   * Called when a game ends because the tournament time has expired.
   */
  handleTimeExpired() {
    this.wonGameBonus = 0;
    this.timeBonus = 0;
    this.totalScore = this.baseScore;

    this.addToTournamentScore(this.totalScore, this.timeBonus, this.wonGameBonus);

    this.saveGameToPlayerStats(false, this.totalScore, this.gameTime);

    this.trackGameComplete('Fail');
  }

  /**
   * Called from the Game Results screen when a game is lost.
   */
  handleLoss() {
    // Can't lose if 1st move was never made
    if (!this.firstMoveMade) {
      this.skippedLoss = true;
      return;
    }

    this.consecutiveWins = 0;
    this.consecutiveLosses++;

    this.wonGameBonus = 0;
    this.timeBonus = 0;
    this.totalScore = this.baseScore + this.getAwardPoints();

    this.addToTournamentScore(this.totalScore, this.timeBonus, this.wonGameBonus);

    this.saveGameToPlayerStats(false, this.totalScore, this.gameTime);

    this.trackGameComplete('Fail');
  }

  /**
   * Called from the Game Results screen when a game is won.
   */
  handleWin() {
    SoundManager.instance.playSound(this.sfxApplause);

    this.consecutiveWins++;
    this.consecutiveLosses = 0;

    this.gamesWon[this.gameType]++;
    this.tournamentGamesWon++;

    this.wonGameBonus = Math.floor(this.baseScore * 0.2);
    this.timeBonus = this.getTimeBonusScore();
    this.totalScore = this.baseScore + this.wonGameBonus + this.timeBonus + this.getAwardPoints();
    this.wonTotalScore[this.gameType] += this.totalScore;

    this.addToTournamentScore(this.totalScore, this.timeBonus, this.wonGameBonus);

    if (this.totalScore > this.highScore[this.gameType]) {
      this.highScore[this.gameType] = this.totalScore;
    }

    if (this.gameTime < this.bestTime[this.gameType]) {
      this.bestTime[this.gameType] = this.gameTime;
    }

    this.saveGameToPlayerStats(true, this.totalScore, this.gameTime);

    this.trackGameComplete('Complete');
  }

  /**
   * Deals the cards out from the deck to the 7 rows of the tableau.
   */
  public deal() {
    for (let t = 0; t < 7; t++) {
      this.cs.tableau[t] = [];
      for (let i = 0; i <= t; i++) {
        let card = this.cs.deck.dealCard();
        this.cs.tableau[t].push(card);
        card.faceUp = (i === t);
      }
    }

    this.verifyBottomRow();
  }

  /**
   * Computes and returns how many valid moves can be made from the Deck.
   */
  getNumValidMovesFromDeck(): number {
    let numValidMoves = 0;

    let cards = this.cs.deck.cards;
    // Can only have moves from the Deck if there are cards
    if (cards.length > 0) {
      let wLength = cards.length
      let wIndex = Math.min(this.cardsPerFlip - 1, wLength - 1);

      // Step through all cards in the deck
      let done = false;
      while (!done) {
        let card = cards[wIndex];

        // Check Foundation first
        if (this.validToPlaceFoundation(card)) {
          numValidMoves++;
        }

        // Now check if card can go to any of the 7 Tableau rows
        for (let t = 0; t < 7; t++) {
          let tableau = this.cs.tableau[t];
          let tableauCard = (tableau.length !== 0) ? tableau[tableau.length - 1] : null;
          if (this.validToPlaceTableau(tableauCard, card)) {
            numValidMoves++;
          }
        }

        // Step to the next card to check in the deck
        if (wIndex === wLength - 1) {
          done = true;
        }
        else {
          wIndex = Math.min(wIndex + this.cardsPerFlip, wLength - 1);
        }
      }
    }

    return numValidMoves;
  }

  /**
   * Computes and returns how many valid moves can be made from the Waste.
   */
  getNumValidMovesFromWaste(): number {
    let numValidMoves = 0;

    // Can only have moves from Waste if there are cards
    if (this.cs.waste.length > 0) {
      let wLength = this.cs.waste.length
      let wIndex = Math.min(this.cardsPerFlip - 1, wLength - 1);

      // Step through all cards in the Waste
      let done = false;
      while (!done) {
        let card = this.cs.waste[wIndex];

        // Check Foundation first
        if (this.validToPlaceFoundation(card)) {
          numValidMoves++;
        }

        // Now check if card can go to any of the 7 Tableau rows
        for (let t = 0; t < 7; t++) {
          let tableau = this.cs.tableau[t];
          let tableauCard = (tableau.length !== 0) ? tableau[tableau.length - 1] : null;
          if (this.validToPlaceTableau(tableauCard, card)) {
            numValidMoves++;
          }
        }

        // Step to the next card to check in the Waste
        if (wIndex === wLength - 1) {
          done = true;
        }
        else {
          wIndex = Math.min(wIndex + this.cardsPerFlip, wLength - 1);
        }
      }
    }

    return numValidMoves;
  }

  /**
   * Computes and returns how many valid moves can be made from the Tableau.
   */
  getNumValidMovesFromTableau(): number {
    let numValidMoves = 0;

    // Now check all 7 rows of the Tableau
    for (let t = 0; t < 7; t++) {
      let tableau = this.cs.tableau[t];

      // Only need to check if there are cards in this Tableau row
      if (tableau.length > 0) {
        // The face-up card is the last card in Tableau array
        let tableauCard = tableau[tableau.length - 1];

        let foundMove = false;

        // If it can go the the Foundation we are done.
        if (this.validToPlaceFoundation(tableauCard)) {
          foundMove = true;
        }
        else {
          // Now check if it can go to any other Tableau row
          for (let t1 = 0; t1 < 7; t1++) {
            // Don't want to check against our own row
            if (t !== t1) {
              let tableauToMove = this.cs.tableau[t1];

              // Can only move card/cards from a Tableau row that already has cards in it
              if (tableauToMove.length > 0) {
                let tableauCardToMove = null;

                // Find the first face-up card in this Tableau row
                let foundIndex = 0;
                for (let i = 0; i < tableauToMove.length; i++) {
                  tableauCardToMove = tableauToMove[i];
                  if (tableauCardToMove.faceUp) {
                    tableauCardToMove = tableauToMove[i];
                    foundIndex = i;
                    break;
                  }
                }

                if (foundIndex === 0) {
                  // Moving a king from a Tableau row with no face-down cards to another empty
                  // Tableau row doesn't count as a real move.
                  if ((tableauCardToMove.type % 13) !== this.kingType) {
                    if (this.validToPlaceTableau(tableauCard, tableauCardToMove)) {
                      foundMove = true;
                    }
                  }
                }
                else {
                  if (this.validToPlaceTableau(tableauCard, tableauCardToMove)) {
                    foundMove = true;
                  }
                }
              }
            }
          }
        }

        if (foundMove) {
          numValidMoves++;
        }
      }
    }

    return numValidMoves;
  }

  /**
   * Return the number of playable aces. This will be 4 minus the number of aces that are
   * face-down in the Tableau.
   */
  getNumAcesPlayable(): number {
    let numFaceDownAces = 0;

    for (let t = 1; t < 7; t++) {
      let tableau = this.cs.tableau[t];
      for (let i = 0; i < tableau.length; i++) {
        if ((tableau[i].type % 13) === this.aceType) {
          numFaceDownAces++;
        }
      }
    }

    return (4 - numFaceDownAces);
  }

  /**
   * Returns true if no moves are left. Doesn't check for moves from the Foundation
   * back to the Tableau.
   */
  checkGameOver(): boolean {
    if (this.getNumValidMovesFromDeck() !== 0) {
      return false;
    }

    if (this.getNumValidMovesFromWaste() !== 0) {
      return false;
    }

    if (this.getNumValidMovesFromTableau() !== 0) {
      return false;
    }

    return true;
  }

  /**
   * Called from game tick.
   * @param deltaTick - How much time has passed since last call
   */
  public update(deltaTick: number) {

    if (this.state === SolitaireState.Playing || this.state === SolitaireState.Traveling ||
        this.state === SolitaireState.ChooseDirection) {
      this.gameTime += deltaTick;
      this.updateTime();
    }

    // Handle floating text
    for (let i = this.floatingText.length - 1; i >= 0; i--) {
      if (this.floatingText[i].update()) {
        this.floatingText.splice(i, 1);
      }
    }

    // Handle all the traveling cards
    for (let i = this.traveling.length - 1; i >= 0; i--) {
      let travel = this.traveling[i];
      travel.tick += deltaTick;

      if (travel.tick > travel.tickLen) {
        // Travel is done - remove from travel array and place the traveling card/cards
        this.traveling.pop();
        this.drag = travel.cards;
        this.dragX = travel.finalX;
        this.dragY = travel.finalY;
        this.tryToPlace();
      }
      else {
        // Update the location along the travel path
        travel.x = travel.initX + (travel.finalX - travel.initX) * (travel.tick / travel.tickLen);
        travel.y = travel.initY + (travel.finalY - travel.initY) * (travel.tick / travel.tickLen);
      }
    }

    // Return to Playing state if there are no more cards traveling
    if (this.state === SolitaireState.Traveling && this.traveling.length === 0) {
      this.state = SolitaireState.Playing;
    }
  }

  /**
   * Draw everything on the Solitaire play area.
   */
  public drawAll() {
    this.drawFoundation();
    if (this.state !== SolitaireState.Victory) {
      this.drawNextPass();
      this.drawDeck();
      this.drawWaste();
      this.drawTableau();
      this.drawDrag();
      this.drawTraveling();
      if (this.state === SolitaireState.ChooseDirection) {
        this.drawChooseDirection();
      }
    }
    this.drawFloatingText();
  }

  /**
   * Draws "Next Pass" or "No More Passes" if deck is empty.
   */
  public drawNextPass() {
    if (this.cs.deck.cards.length === 0) {
      let offsetY = 2;
      let sizeAdjX = Game.instance.cardsManager.useSmallCardArt ? 0 : 12;
      let sizeAdjY = Game.instance.cardsManager.useSmallCardArt ? 0 : 8;

      if (this.passesAllowed === 0 || this.passes < this.passesAllowed) {
        UIManager.ctx.drawImage(this.nextPassImage, this.deckOffset, 6 + offsetY, this.cardWidth - sizeAdjX,
                                this.cardHeight - sizeAdjY);
      }
      else {
        UIManager.ctx.drawImage(this.noMorePassesImage, this.deckOffset, 6 + offsetY, this.cardWidth - sizeAdjX,
                                this.cardHeight - sizeAdjY);
      }
    }
  }

  /**
   * Draws the Deck.
   */
  public drawDeck() {
    let x = this.deckOffset;
    let index = this.cs.deck.cards.length - 3;

    for (let i = 0; i < 3; i++) {
      if (index >= 0 && index < this.cs.deck.cards.length) {
        this.cs.deck.cards[index].draw(x, 6, this.cardWidth, this.cardHeight);
        x += this.deckSpacing;
      }
      index++;
    }

    if (this.cs.deck.cards.length > 0 && this.handDifficulty === 11) {
      let path = new Path2D();
      path.arc(this.deckOffset + this.cardWidth - 11, 26, 8, 0, 2 * Math.PI);
      UIManager.ctx.fillStyle = '#ffff00';
      UIManager.ctx.fill(path);
    }
  }

  /**
   * Draws an empty card pile
   */
  public drawEmptyPile(cx: number, cy: number, cw: number, ch: number) {
    cx += 4;
    cw -= Game.instance.cardsManager.useSmallCardArt ? 6 : 18;

    cy += 4;
    ch -= Game.instance.cardsManager.useSmallCardArt ? 8 : 12;

    let radius = 10;
    let path = new Path2D();
    path.moveTo(cx + radius, cy);
    path.lineTo(cx + cw - radius, cy);
    path.quadraticCurveTo(cx + cw, cy, cx + cw, cy + radius);
    path.lineTo(cx + cw, cy + ch - radius);
    path.quadraticCurveTo(cx + cw, cy + ch, cx + cw - radius, cy + ch);
    path.lineTo(cx + radius, cy + ch);
    path.quadraticCurveTo(cx, cy + ch, cx, cy + ch - radius);
    path.lineTo(cx, cy + radius);
    path.quadraticCurveTo(cx, cy, cx + radius, cy);
    path.closePath();

    // Game.game.ctx.fillStyle = '#00000040';
    // Game.game.ctx.fill(path);

    // if(this.borderWidth > 0) {
    UIManager.ctx.lineWidth = 4;
    UIManager.ctx.strokeStyle = '#00000040';
    UIManager.ctx.stroke(path);
    // }
  }

  /**
   * Draws the Waste.
   */
  public drawWaste() {
    if (this.cs.waste.length > 0) {
      let startIndex = this.cs.waste.length < 3 ? 0 : this.cs.waste.length - 3;
      for (let i = startIndex; i < this.cs.waste.length; i++) {
        this.cs.waste[i].draw(this.wasteOffset + (i - startIndex) * this.wasteSpacing, 6, this.cardWidth,
                              this.cardHeight)
      }
    }
    else {
      this.drawEmptyPile(this.wasteOffset, 6, this.cardWidth, this.cardHeight);
    }
  }

  /**
   * Draws the Tableau.
   */
  public drawTableau() {
    for (let t = 0; t < 7; t++) {
      let tableau = this.cs.tableau[t];
      let tvs = this.tableauVertSpacing[t];

      // (x,y) loc of the top card
      let x = 5 + t * 140;
      let y = 208;
      let foundFaceUp = false;
      for (let i = 0; i < tableau.length; i++) {
        tableau[i].draw(x, y, this.cardWidth, this.cardHeight);

        if (!tableau[i].faceUp) {
          y += this.defaultFaceDownSpacing;
        }
        // Make sure the 1st face up card has enough room to be seen by using a different vertical spacing for this one card
        else if (tvs < this.defaultVertSpacing && !foundFaceUp) {
          y += this.defaultVertSpacing;
        }
        else {
          y += tvs;
        }
        foundFaceUp = tableau[i].faceUp;
      }
    }
  }

  /**
   * Draw the Foundation.
   */
  public drawFoundation() {
    for (let i = 0; i < 4; i++) {
      let foundation = this.cs.foundation[i];
      if (foundation.length > 0) {
        foundation[foundation.length - 1].draw(this.foundationOffset + i * this.foundationSpacing, 6, this.cardWidth,
                                               this.cardHeight);
      }
      else {
        this.drawEmptyPile(this.foundationOffset + i * this.foundationSpacing, 6, this.cardWidth, this.cardHeight);
      }
    }
  }

  /**
   * Draw the card or group of cards that are being dragged.
   */
  public drawDrag() {
    for (let i = 0; i < this.drag.length; i++) {
      this.drag[i].draw(this.dragX, this.dragY + i * this.dragSpacing, this.cardWidth, this.cardHeight);
    }
  }

  /**
   * Draw the cards or group of cards that are traveling.
   */
  public drawTraveling() {
    for (let t = 0; t < this.traveling.length; t++) {
      let travel = this.traveling[t];
      for (let i = 0; i < travel.cards.length; i++) {
        travel.cards[i].draw(travel.x, travel.y + i * this.defaultVertSpacing, this.cardWidth, this.cardHeight);
      }
    }
  }

  /**
   * Draws the choose direction arrows.
   */
  public drawChooseDirection() {
    let adjX = this.shouldFlipLayout ? this.chooseDirectionOffset + 124 - this.lastMouseX
                                     : this.lastMouseX - this.chooseDirectionOffset;
    let adjY = this.lastMouseY - 50;
    if (adjX > adjY) {
      if (this.shouldFlipLayout) {
        UIManager.ctx.drawImage(this.chooseDirectionLeftImage, this.chooseDirectionOffset, 50, 160, 160);
      }
      else {
        UIManager.ctx.drawImage(this.chooseDirectionRightImage, this.chooseDirectionOffset, 50, 160, 160);
      }
    }
    else {
      if (this.shouldFlipLayout) {
        UIManager.ctx.drawImage(this.chooseDirectionDownLeftImage, this.chooseDirectionOffset, 50, 160, 160);
      }
      else {
        UIManager.ctx.drawImage(this.chooseDirectionDownImage, this.chooseDirectionOffset, 50, 160, 160);
      }
    }
  }

  /**
   * Draws the score toasts that pop off cards (aka floating text)
   */
  public drawFloatingText() {
    this.floatingText.forEach((ft) => {
      ft.draw();
    });

    // Game.game.ctx.fillStyle = this.goodTextColor;
    // Game.game.ctx.fillRect(0, 210 + (600-this.cardHeight), 972, 2);
  }

  /**
   * Updates tournament time left and returns true if time just ran out.
   * @param deltaTick - How much time has passed since last call.
   */
  public updateTournamentTime(deltaTick: number): boolean {
    if (this.playingTournament && this.tournamentType === TournamentType.Timed) {
      if (this.tournamentTimeLeft > 0) {
        this.tournamentTimeLeft -= deltaTick;
        if (this.tournamentTimeLeft < 0) {
          this.tournamentTimeLeft = 0;
          this.tournamentGamesPlayed--;
          this.gamesPlayedAllTypes++;
          this.gamesPlayed[this.gameType]++;
        }
      }

      this.tournamentTimeExpired = (this.tournamentTimeLeft === 0);

      return this.tournamentTimeExpired;
    }

    return false;
  }

  /**
   * Updates the time and points for a scoring move in timed games.
   */
  public updateTime() {
    if (this.timedGame) {
      // This stuff only matters in a timed game
      let checkAmount = Math.floor(this.gameTime / this.pointsCountdownSpeed);
      if (checkAmount > this.nextTimeCheck) {
        this.nextTimeCheck++;
        // Check if bonus points has gone down to the minimum value of 1
        if (this.pointsCountdown > 1) {
          // Reduce bonus points by 1
          this.pointsCountdown--;
          if (this.pointsCountdown === 1) {
            this.nextTimeSubCheck = 10;
          }
        }
        else {
          // Once the pointsCountdown has reached 0 then the score loses 1 point every few seconds
          this.nextTimeSubCheck--;
          if (this.nextTimeSubCheck === 0) {
            this.nextTimeSubCheck = 10;
            if (this.baseScore > 0) {
              this.addToBaseScore(-1);
              this.prevBaseScore--;
            }
          }
        }
      }
    }
  }

  /**
   * Fills in the CardOverInfo based upon which card the passed in location is over.
   * @param x - Screen x location
   * @param y - Screen y location
   */
  public getCardOverInfo(x: number, y: number): CardOverInfo {
    if (y >= 6 && y <= 15 + this.cardClickHeight) {
      // Over top row
      //if (x >= 8 && (x - 16) < this.cardClickWidth) {
      if (x >= this.deckOffset && (x - 16) < this.deckOffset + this.cardClickWidth) {
        // Return Deck over info
        return {
          card : this.cs.deck.cards[this.cs.deck.cards.length - 1],
          x : this.deckOffset,
          y : 6,
          source : CardSource.Deck,
          sourceIndex : -1,
          sourceSubIndex : -1
        };
      }
      else if (x >= this.foundationOffset &&
               x < (this.foundationOffset + 3 * this.foundationSpacing + this.cardWidth)) {
        // Over Foundation
        let card = null;
        let foundationIndex = -1;
        if (((x - this.foundationOffset) % this.foundationSpacing) < this.cardClickWidth + 4) {
          foundationIndex = Math.floor((x - this.foundationOffset) / this.foundationSpacing);
          if (foundationIndex >= 0 && foundationIndex < 4) {
            let foundation = this.cs.foundation[foundationIndex];
            if (foundation.length > 0) {
              card = foundation[foundation.length - 1];
            }
          }
          else {
            foundationIndex = -1;
          }
        }

        // Return Foundation over info
        return {
          card : card,
          x : this.foundationOffset + foundationIndex * this.foundationSpacing,
          y : 6,
          source : CardSource.Foundation,
          sourceIndex : foundationIndex,
          sourceSubIndex : -1
        };
      }
      else if (this.cs.waste.length > 0) {
        let wLength = (this.cs.waste.length < 3) ? this.cs.waste.length - 1 : 2;
        let wasteX = this.wasteOffset + wLength * this.wasteSpacing;
        if (x >= wasteX && x < wasteX + this.cardClickWidth + 4) {
          // Return Waste over info
          return {
            card : this.cs.waste[this.cs.waste.length - 1],
            x : wasteX - 2,
            y : 6,
            source : CardSource.Waste,
            sourceIndex : -1,
            sourceSubIndex : -1
          };
        }
      }
    }
    else if (y >= 180) {
      // Our y location is great enough to be over the Tableau

      // Now check if our x location could be over a Tableau row
      if (((x - 8) % 140) < this.cardClickWidth + 4) {
        // Compute which of the 7 Tableau rows we could be over
        let tableauIndex = Math.floor((x - 8) / 140);
        if (tableauIndex >= 0 && tableauIndex < 7) {
          // We got the Tableau row that we are over, make sure there are cards in it
          let tableau = this.cs.tableau[tableauIndex];
          if (tableau.length > 0) {
            let firstFaceDownIndex = this.getFirstFaceDownIndex(tableau);
            let numFaceUpCards = tableau.length - firstFaceDownIndex - 1;

            // Figure out the bottom y location of this row and make sure we are less than that
            let tableauVertSpacing = this.tableauVertSpacing[tableauIndex];
            let bottomY = 211 + firstFaceDownIndex * this.defaultFaceDownSpacing + numFaceUpCards * tableauVertSpacing +
                          this.cardClickHeight + 4;
            if (y < bottomY) {
              // Okay we are over a Tableau card
              let cardY = 208;
              let prevCardY = 208;
              let tableauVertIndex = tableau.length - 1;
              let tvs = tableauVertSpacing;
              let foundFaceUp = false;

              // Go through all the cards in the row and find the one we are over
              for (let i = 0; i < tableau.length; i++) {
                // Take into account special spacing that may be happening to the first face up card.
                prevCardY = cardY;
                if (!tableau[i].faceUp) {
                  cardY += this.defaultFaceDownSpacing;
                }
                //else if (tvs < this.defaultVertSpacing && !foundFaceUp && tableau[i].faceUp) {
                else if (tvs < this.defaultVertSpacing && !foundFaceUp) {
                  cardY += this.defaultVertSpacing;
                }
                else {
                  cardY += tableauVertSpacing;
                }
                foundFaceUp = tableau[i].faceUp;
                if (y < cardY) {
                  tableauVertIndex = i;
                  break;
                }
              }

              // We got the Tableau card we are over, so return the info
              return {
                card : tableau[tableauVertIndex],
                x : 5 + tableauIndex * 140,
                y : prevCardY,  //208 + tableauVertIndex * tableauVertSpacing,
                source : CardSource.Tableau,
                sourceIndex : tableauIndex,
                sourceSubIndex : tableauVertIndex
              };
            }
          }
          else {
            // Over an empty row
            if (y < 211 + this.cardClickHeight + 4) {
              // y loc is where the first card would be on an empty row, so return that
              // info because it is needed if a King is going to be placed.
              return {
                card : null,
                x : 5 + tableauIndex * 140,
                y : 208,
                source : CardSource.Tableau,
                sourceIndex : tableauIndex,
                sourceSubIndex : 0
              };
            }
          }
        }
      }
    }

    // Over nothing
    return null;
  }

  /**
   * Return index of first face down card. -1 if all cards are face up.
   * @param tableau - tableau to check
   */
  getFirstFaceDownIndex(tableau: Card[]) {
    let faceDownIndex = -1;
    for (let i = 0; i < tableau.length; i++) {
      if (tableau[i].faceUp) {
        break;
      }
      faceDownIndex = i;
    }

    return faceDownIndex;
  }

  /**
   * Returns true if the passed in card could be placed on the Foundation
   * @param cardToPlace - Card to place
   */
  public validToPlaceFoundation(cardToPlace: Card): boolean {
    let foundation = this.cs.foundation[cardToPlace.suit];
    let card = (foundation.length !== 0) ? foundation[foundation.length - 1] : null;

    if (card === null) {
      // Aces can be placed on empty Foundation
      return ((cardToPlace.type % 13) === this.aceType);
    }

    // Make sure card is next in line to be placed
    return ((card.type % 13) === (cardToPlace.type % 13) - 1);
  }

  /**
   * Returns true if card to place can be placed on the Tableau card.
   * @param tableauCard - Tableau card
   * @param cardToPlace - Card to be placed on the Tableau card
   */
  public validToPlaceTableau(tableauCard: Card, cardToPlace: Card): boolean {
    if (tableauCard === null) {
      // Only Kings can be placed on empty Tableau rows
      return ((cardToPlace.type % 13) === this.kingType);
    }

    // Can't place aces or place card on aces on the tableau
    if ((cardToPlace.type % 13) === this.aceType || (tableauCard.type % 13) === this.aceType) {
      return false;
    }

    // Make sure card is next in line and also of the opposite color
    return ((tableauCard.type % 13) === (cardToPlace.type % 13) + 1 && tableauCard.color !== cardToPlace.color);
  }

  /**
   * Make sure bottom card of each tableau row is face up and also adjusts the vert spacing if necessary
   */
  public verifyBottomRow() {
    let bottomMax = 600 - this.cardHeight;

    // Go through each row
    for (let tableauIndex = 0; tableauIndex < 7; tableauIndex++) {
      let tableau = this.cs.tableau[tableauIndex];
      if (tableau.length > 0) {
        // Make sure the last card in this row is face up
        if (!tableau[tableau.length - 1].faceUp) {
          // Flip this card and add points
          tableau[tableau.length - 1].faceUp = true;

          // Called when a face-down card on the Tableau is flipped over.
          let x = 8 + (tableauIndex * 140) + (this.cardWidth / 2) - 6;

          let firstFaceDownIndex = this.getFirstFaceDownIndex(tableau);
          let y = 211 + ((firstFaceDownIndex + 1) * this.defaultFaceDownSpacing) + (this.cardClickHeight / 2);

          this.addPointsCountdown(x, y);

          this.cardPlayedThisPass = true;
          this.cardPlayedEver = true;
        }

        // Adjust the vertical spacing so cards don't go past bottom of the screen
        if (tableau.length > 1) {
          let vertSpacing = Math.floor(bottomMax / (tableau.length - 1));
          if (vertSpacing > this.defaultVertSpacing) {
            vertSpacing = this.defaultVertSpacing;
          }
          this.tableauVertSpacing[tableauIndex] = vertSpacing;
        }
      }
    }
  }

  /**
   * Goes through Tableau and sets whether there are any facedown cards in it. Useful
   * for detecting that a game only need to place remaining cards for victory.
   */
  setAnyTableauCardsFaceDown() {
    // See if there are any face-down cards in the Tableau
    if (this.anyTableauCardsFaceDown) {
      let anyCardsFaceDown = false;
      for (let t = 0; t < 7; t++) {
        if (this.cs.tableau[t].length > 0) {
          anyCardsFaceDown = anyCardsFaceDown || !this.cs.tableau[t][0].faceUp;
        }
      }
      this.anyTableauCardsFaceDown = anyCardsFaceDown;
    }
  }

  /**
   * Attempts to do an auto-play. Returns true is auto-play was done.
   */
  public autoPlay(): boolean {
    if (!this.useAutoPlay) {
      return false;
    }

    this.setAnyTableauCardsFaceDown();

    // Travel speed is much faster in there are no face-down cards - ie can now win.
    this.travelSpeed = (this.anyTableauCardsFaceDown) ? 200 : 50;

    // Check Tableau First
    for (let t = 0; t < 7; t++) {
      let tableau = this.cs.tableau[t];
      if (tableau.length > 0) {
        let ti = tableau.length - 1;
        let tvs = this.tableauVertSpacing[ti];
        let firstFaceDownIndex = this.getFirstFaceDownIndex(tableau);
        let numFaceUpCards = tableau.length - firstFaceDownIndex - 1;
        let yOffset = (firstFaceDownIndex + 1) * this.defaultFaceDownSpacing + (numFaceUpCards - 1) * tvs;

        this.drag = [ tableau[ti] ];
        this.dragX = 5 + t * 140;
        this.dragY = 208 + yOffset;
        if (this.placeFoundation(true, true)) {
          this.popSource(CardSource.Tableau, t, ti);
          this.dragCardOverInfo.source = CardSource.Tableau;
          this.drag = [];
          this.state = SolitaireState.Traveling;
          SoundManager.instance.playSound(this.sfxCardSwish);
          return true;
        }
      }
    }

    // No auto-place possible
    this.drag = [];
    return false;
  }

  /**
   * Called when place clicks and release on a card without dragging it. Tries to
   * auto-place the card.
   */
  public autoPlace(): boolean {
    this.setAnyTableauCardsFaceDown();

    // First check if Waste has 2 possible destinations for cards 3 and above
    if (this.anyTableauCardsFaceDown) {
      let coi = this.dragCardOverInfo;
      let cardToPlace = this.drag[0];
      if (coi.source === CardSource.Waste && (cardToPlace.type % 13) > 1) {
        if (this.validToPlaceFoundation(cardToPlace)) {
          // Can place on Foundation - now check if can place on Tableau too
          for (let t = 0; t < 7; t++) {
            let tableau = this.cs.tableau[t];
            let tableauCard = (tableau.length !== 0) ? tableau[tableau.length - 1] : null;
            if (this.validToPlaceTableau(tableauCard, cardToPlace)) {
              // Can also place on Tableau - so set up to ask player which one to use
              this.state = SolitaireState.ChooseDirection;

              return true;
            }
          }
        }
      }
    }

    // Default travel speed
    this.travelSpeed = 200;

    // Foundation check first
    if (this.placeFoundation(true, false)) {
      this.drag = [];
      this.state = SolitaireState.Traveling;
      SoundManager.instance.playSound(this.sfxCardSwish);
      return true;
    }

    // Now check Tableau
    for (let i = 0; i < 7; i++) {
      if (this.dragCardOverInfo.sourceIndex !== i) {
        if (this.placeTableau(i, true)) {
          this.drag = [];
          this.state = SolitaireState.Traveling;
          SoundManager.instance.playSound(this.sfxCardSwish);
          return true;
        }
      }
    }

    // Called when the player clicks to auto-play a card, but there is no auto-place
    // move. Need to remove points when player does this for game balance reasons.
    if (this.dragCardOverInfo.source === CardSource.Waste) {
      let scoreAdjust = Math.min(this.baseScore, 20);
      let xLoc = (this.shouldFlipLayout ? 693 : 158) + (this.cardWidth / 2) - 6;
      let yLoc = 8 + (this.cardClickHeight / 2);
      if (scoreAdjust > 0) {
        this.addToScore(-scoreAdjust, xLoc, yLoc);
        this.prevBaseScore -= scoreAdjust;
      }

      // Need a slight time penalty too to keep users from gaming the scoring
      if (this.timedGame) {
        if (this.noAutoPlaceTimePenalty !== 0) {
          this.addToTime(this.noAutoPlaceTimePenalty, xLoc, yLoc + 38);
        }
        if (this.noAutoPlaceTimePenalty < 4000) {
          this.noAutoPlaceTimePenalty += 1000;
        }
      }
    }

    return false;
  }

  /**
   * Add the passed in traveling info the traveling info array.
   * @param travelingInfo - Traveling info to add
   */
  addToTraveling(travelingInfo: TravelingInfo) {
    this.traveling.push(travelingInfo);
  }

  /**
   * Returns true if a card was placed on the Foundation or added to the traveling array to
   * go to the Foundation.
   * @param shouldTravel - true if drag card/cards should be added to the traveling array
   * @param fromAutoPlay - true if this was called from auto-play
   */
  public placeFoundation(shouldTravel: boolean, fromAutoPlay: boolean): boolean {
    // Empty drag array means there is nothing to place
    if (this.drag.length === 1) {
      // First card in drag array is the one that we need to see if can be placed
      let cardToPlace = this.drag[0];
      if ((cardToPlace.canAutoPlay || !fromAutoPlay || !this.anyTableauCardsFaceDown) &&
          this.validToPlaceFoundation(cardToPlace)) {
        if (shouldTravel) {
          // Add the drag array to the traveling array
          let travelingInfo = {
            cards : this.drag,
            initX : this.dragX,
            initY : this.dragY,
            finalX : this.foundationOffset + cardToPlace.suit * this.foundationSpacing,
            finalY : 6,
            x : this.dragX,
            y : this.dragY,
            tick : 0,
            tickLen : this.travelSpeed
          };
          this.addToTraveling(travelingInfo);
          return true;
        }
        else {
          // If not traveling than immediately place on destination
          this.cs.foundation[cardToPlace.suit].push(cardToPlace);
          this.setFirstMoveMade();
          this.cardPlayedThisPass = true;
          this.cardPlayedEver = true;
          this.drag = [];
          let sx = 2 + this.foundationOffset + (cardToPlace.suit * this.foundationSpacing) + (this.cardWidth / 2) - 6;
          let sy = 8 + (this.cardClickHeight / 2);
          if (!cardToPlace.playedToFoundation) {
            this.computeAndAddToScore(this.dragCardOverInfo.source, CardSource.Foundation, sx, sy);
            cardToPlace.playedToFoundation = true;
          }
          this.verifyBottomRow();

          // Save state for undo
          this.saveCardsState();

          // If the number of cards in any of the 4 Foundation piles are less than 13 then no victory
          for (let i = 0; i < 4; i++) {
            if (this.cs.foundation[i].length !== 13) {
              this.autoPlay();
              return true;
            }
          }
        }

        // Victory if we made it here
        this.state = SolitaireState.Victory;
        this.removeAllFloatingText();
        Game.instance.finish();

        return true;
      }
    }

    // Not placed
    return false;
  }

  /**
   * Returns true if a card was placed on the Tableau or added to the traveling array to
   * go to the Tableau.
   * @param tableauIndex - Index of the Tableau to place on
   * @param shouldTravel - true if drag card/cards should be added to the traveling array
   */
  public placeTableau(tableauIndex: number, shouldTravel: boolean): boolean {
    let tableau = this.cs.tableau[tableauIndex];

    // First card in drag array and last card in Tableau row are the ones to check against each other
    let tableauCard = (tableau.length !== 0) ? tableau[tableau.length - 1] : null;
    if (this.validToPlaceTableau(tableauCard, this.drag[0])) {
      let tvs = this.tableauVertSpacing[tableauIndex];
      let firstFaceDownIndex = this.getFirstFaceDownIndex(tableau);
      let numFaceUpCards = tableau.length - firstFaceDownIndex - 1;
      let yOffset = (firstFaceDownIndex + 1) * this.defaultFaceDownSpacing + numFaceUpCards * tvs;
      if (shouldTravel) {
        // Add the drag array to the traveling array
        let travelingInfo = {
          cards : this.drag,
          initX : this.dragX,
          initY : this.dragY,
          finalX : 5 + tableauIndex * 140,
          finalY : 208 + yOffset,
          x : this.dragX,
          y : this.dragY,
          tick : 0,
          tickLen : this.travelSpeed
        };
        this.addToTraveling(travelingInfo);
      }
      else {
        // If not traveling then immediately place on destination
        this.cs.tableau[tableauIndex] = tableau.concat(this.drag);
        this.setFirstMoveMade();

        this.drag = [];
        let x = 8 + (tableauIndex * 140) + (this.cardWidth / 2) - 6;
        let y = 211 + yOffset + (this.cardClickHeight / 2);
        this.computeAndAddToScore(this.dragCardOverInfo.source, CardSource.Tableau, x, y);
        this.verifyBottomRow();

        // Save state for undo
        this.saveCardsState();

        if (this.dragCardOverInfo.source !== CardSource.Foundation) {
          this.autoPlay();
        }
        if (this.dragCardOverInfo.source === CardSource.Waste) {
          this.cardPlayedThisPass = true;
          this.cardPlayedEver = true;
        }
      }
      return true;
    }

    // Not placed
    return false;
  }

  /**
   * If possible will use the card over info to place on the Tableau or Foundation. Returns true
   * if it was placed.
   */
  public tryToPlace(): boolean {
    let cardOverInfo = this.getCardOverInfo(this.dragX + this.cardClickWidth / 2, this.dragY + this.cardClickWidth / 4);
    if (cardOverInfo) {
      if (cardOverInfo.source === CardSource.Tableau) {
        if (this.placeTableau(cardOverInfo.sourceIndex, false)) {
          return true;
        }
      }
      else if (cardOverInfo.source === CardSource.Foundation) {
        if (this.placeFoundation(false, false)) {
          return true;
        }
      }
    }

    // Not placed
    return false;
  }

  /**
   * Called from onMouseUp. If can't place the dragged cards then returns them to their
   * previous location.
   */
  public tryToPlaceCard() {
    // Check for auto-place because we haven't dragged card. We just clicked and release over a card.
    // But don't try to auto-place card coming from foundation.
    let coi = this.dragCardOverInfo;
    if (!this.dragMoved && coi.source !== CardSource.Foundation) {
      if (this.autoPlace()) {
        return;
      }
    }

    // See if we can place this card
    if (this.tryToPlace()) {
      return;
    }

    // Not placed so return to previous location
    if (coi.source === CardSource.Waste) {
      this.cs.waste.push(this.drag[0]);
    }
    else if (coi.source === CardSource.Foundation) {
      this.cs.foundation[coi.sourceIndex].push(this.drag[0]);
    }
    else {
      this.cs.tableau[coi.sourceIndex] = this.cs.tableau[coi.sourceIndex].concat(this.drag);
    }

    // Not dragging anything anymore
    this.drag = [];
  }

  /**
   * Deals cards from the Deck to the Waste.
   */
  public dealCards() {
    if (this.cs.deck.cards.length > 0) {
      let cardsToDeal = this.cardsPerFlip;

      while (cardsToDeal > 0) {
        if (this.cs.deck.cards.length > 0) {
          let card = this.cs.deck.dealCard();
          card.faceUp = true;
          this.cs.waste.push(card);
        }
        cardsToDeal--;
      }

      this.setFirstMoveMade();

      // Save state for undo
      this.saveCardsState();
    }
  }

  /**
   * Pops a card off of the source.
   * @param source - Where the card is coming from
   * @param sourceIndex - Index of the source (could be Foundation or Tableau)
   * @param sourceSubIndex - Sub-index of the source (Tableau)
   */
  popSource(source: CardSource, sourceIndex: number = 0, sourceSubIndex: number = 0) {
    if (source === CardSource.Waste) {
      this.cs.waste.pop();
    }
    else if (source === CardSource.Foundation) {
      this.cs.foundation[sourceIndex].pop();
    }
    else if (source === CardSource.Tableau) {
      this.drag = this.cs.tableau[sourceIndex].splice(sourceSubIndex);
    }
  }

  /**
   * Called when mouse/touch is pressed.
   * @param x - Mouse/touch x location
   * @param y - Mouse/touch y location
   */
  public onMouseDown(x: number, y: number) {
    if(!Game.instance.started)
      return;

    if (this.state !== SolitaireState.Playing) {
      if (this.state === SolitaireState.ChooseDirection) {
        this.lastMouseX = x;
        this.lastMouseY = y;
      }
      return;
    }

    // Get the card over info using the mouse location
    let coi = this.getCardOverInfo(x, y);
    if (coi !== null) {
      this.dragCardOverInfo = coi;
      this.dragX = coi.x;
      this.dragY = coi.y;
      this.dragMoved = false;

      this.initialMouseX = x;
      this.initialMouseY = y;
      this.lastMouseX = x;
      this.lastMouseY = y;

      // Can only drag cards from the Waste, Foundation or the Tableau
      if (coi.source === CardSource.Waste && coi.card !== null) {
        this.drag = [ coi.card ];
        this.dragSpacing = this.defaultVertSpacing;
        this.popSource(coi.source);
      }
      else if (coi.source === CardSource.Foundation && coi.card !== null) {
        this.drag = [ coi.card ];
        this.dragSpacing = this.defaultVertSpacing;
        this.popSource(coi.source, coi.sourceIndex);
      }
      else if (coi.source === CardSource.Tableau && coi.card !== null) {
        if (coi.card.faceUp) {
          this.popSource(coi.source, coi.sourceIndex, coi.sourceSubIndex);
          this.dragSpacing = this.tableauVertSpacing[coi.sourceIndex];
          if (this.dragSpacing < this.defaultVertSpacing) {
            this.dragSpacing = this.defaultVertSpacing;
          }
        }
      }
    }
  }

  /**
   * Called when mouse/touch is release.
   * @param x - Mouse/touch x location
   * @param y - Mouse/touch y location
   */
  public onMouseUp(x: number, y: number) {
    if(!Game.instance.started)
      return;

    if (this.state === SolitaireState.ChooseDirection) {
      // If the player is choosing direction then send card to selected direction
      let adjX = this.shouldFlipLayout ? this.chooseDirectionOffset + 124 - this.lastMouseX
                                       : this.lastMouseX - this.chooseDirectionOffset;
      let adjY = this.lastMouseY - 50;
      if (adjX > adjY) {
        // Send to the Foundation
        if (this.placeFoundation(true, true)) {
          this.drag = [];
          this.state = SolitaireState.Traveling;
          SoundManager.instance.playSound(this.sfxCardSwish);
          return true;
        }
      }
      else {
        // Send to the Tableau
        for (let t = 0; t < 7; t++) {
          // Now check Tableau
          let cardToPlace = this.dragCardOverInfo.card;
          if (this.dragCardOverInfo.sourceIndex !== t) {
            if (this.placeTableau(t, true)) {
              cardToPlace.canAutoPlay = false;
              this.drag = [];
              this.state = SolitaireState.Traveling;
              SoundManager.instance.playSound(this.sfxCardSwish);
              return true;
            }
          }
        }
      }

      // Should never get here
      this.state = SolitaireState.Playing;
      return;
    }

    // Just exit if not playing
    if (this.state !== SolitaireState.Playing) {
      return;
    }

    if (this.drag.length > 0) {
      // If player is dragging any cards then try place them
      this.tryToPlaceCard();
    }
    else {
      // Find out what we are over
      let cardOverInfo = this.getCardOverInfo(x, y);
      if (cardOverInfo !== null && cardOverInfo.source === CardSource.Deck) {
        if (this.cs.deck.cards.length > 0) {
          // Over the deck which still has cards in it so deal them
          this.dealCards();
        }
        else if (this.passesAllowed === 0 || this.passes < this.passesAllowed) {
          // No cards left in deck and still have more passes through deck available
          if (!this.cardPlayedThisPass && this.checkGameOver()) {
            // Recognized a lost game condition - ask player if wants to end game
            Game.instance.askGameLost();
          }
          else if (this.cardsPerFlip === 1) {
            // Points are lost on deck reset when flipping 1 card at a time
            this.handleScoreDeckReset();
          }

          // The player is making another pass through the deck
          this.passes++;

          // Transfer waste back to deck
          while (this.cs.waste.length > 0) {
            let card = this.cs.waste.pop();
            card.faceUp = false;
            this.cs.deck.cards.push(card);
          }
          this.cardPlayedThisPass = false;
          this.dealCards();
        }
      }
    }
  }

  /**
   * Called when mouse/touch is moved.
   * @param x - Mouse/touch x location
   * @param y - Mouse/touch y location
   */
  public onMouseMove(x: number, y: number) {
    if(!Game.instance.started)
      return;

    // If not playing then just exit
    if (this.state !== SolitaireState.Playing) {
      this.lastMouseX = x;
      this.lastMouseY = y;
      return;
    }

    if (this.drag.length > 0) {
      // Dragging cards so update their location
      if (!this.dragMoved) {
        // This is used by onMouseUp to decide if player is doing a press and release
        // which means to try to do an auto-place. If mouse has moved far enough then
        // the release will be treated as a drag and release.
        let dx = Math.abs(x - this.initialMouseX);
        let dy = Math.abs(y - this.initialMouseY);
        this.dragMoved = (dx > 10) || (dy > 10);
      }
      this.dragX += (x - this.lastMouseX);
      this.dragY += (y - this.lastMouseY);
    }

    // Keep track of last moved location
    this.lastMouseX = x;
    this.lastMouseY = y;
  }

  public isTournamentOver(): boolean {
    if (!this.playingTournament)
      return true;

    if (this.tournamentType === TournamentType.Timed && this.tournamentTimeLeft === 0)
      return true;

    if (this.tournamentType === TournamentType.Games && this.tournamentGamesPlayed === this.tournamentGamesToPlay)
      return true;

    return false;
  }

  public setPlayerStats() {
    // let stats = PlayPlace.instance.getPlayerStats();

    // let saveStats = false;

    // let oldScore: number = 0;
    // if (stats['score'] != undefined) {
    //   oldScore = stats.score;
    //   stats = {};
    //   saveStats = true;
    // }

    // if (stats['total'] == undefined) {
    //   saveStats = true;
    //   stats.total = { score : oldScore, time : 0, wins : 0, losses : 0 }
    // }

    // if (saveStats)
    //   PlayPlace.instance.savePlayerStats(stats);
  }

  protected saveGameToPlayerStats(win: boolean, score: number, time: number) {
    // if (!PlayPlace.instance.isActive())
    //   return;

    // let stats = PlayPlace.instance.getPlayerStats();

    // stats.total.score += score;
    // stats.total.time += Math.floor(time / 1000);

    // if (win)
    //   stats.total.wins++;
    // else
    //   stats.total.losses++;

    // PlayPlace.instance.savePlayerStats(stats);
  }

  protected getTrackGameType(): string {
    let gameType = 'Standard';
    if (this.playingTournament) {
      if (this.tournamentType === TournamentType.Games)
        gameType = 'TournamentGames';
      else
        gameType = 'TournamentTimed';
    }
    else if (this.timedGame) {
      gameType = 'StandardTimed';
    }
    return gameType;
  }

  protected trackGameStart() {
    // PlayPlace.instance.trackGameEvent(this.getTrackGameType(), 'Start');
  }

  protected trackGameComplete(eventType: string) {
    // PlayPlace.instance.trackGameEvent(this.getTrackGameType(), eventType, this.totalScore);
    // PlayPlace.instance.trackGameTime(this.getTrackGameType(), (this.gameTime / 1000 / 60 / 60));
    // PlayPlace.instance.trackAwards(this.getAwardsWon());
  }

  public getCardsPerFlip(): number {
    return this.cardsPerFlip;
  }

  public getLayoutFlipped(): boolean {
    return this.shouldFlipLayout;    
  }

  public getFirstMoveMade(): boolean {
    return this.firstMoveMade;
  }

  public getUseAutoPlay(): boolean { 
    return this.useAutoPlay;
  }

  public toggleCardsPerFlip() {
    this.cardsPerFlip = this.cardsPerFlip == 1 ? 3 : 1;
    this.setupForLayoutFlipSettings();
  }
}