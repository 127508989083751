import { getAssetImage } from "../../app/util/assets";
import { Database } from "../../app/util/database";
import { Server } from "../../server/server";
import { GameBase } from "./gameBase";
import { UICanvas } from "./ui/uiCanvas";
import { UIImage } from "./ui/uiImage";
import { UIPanel, UIPanelOptions } from "./ui/uiPanel";
import { UIPanelButton } from "./ui/uiPanelButton";
import { UIText } from "./ui/uiText";
import { numberWithCommas } from "./util";

export interface GameFooterOptions extends UIPanelOptions {
  game: GameBase;
  callback: Function;
}

export class GameFooter extends UIPanel {
  protected callback: Function;
  protected game: GameBase;
  protected timers: any[];
  protected pups: any[];
  protected cooldowns: any[];
  protected used: boolean[];

  // constructor
  constructor(options: GameFooterOptions) {
    options.fitParent = {left: 0, right: 0, top: -1, bottom: -1};
    options.size = {x: 0, y: 60};
    options.anchor = {x:0, y: 1};
    options.pivot = {x:0, y: 1};
    // options.color = '#00000080';

    super(options);

    this.callback = options.callback;
    this.game = options.game;
    this.timers = [];
    this.pups = [];
    this.cooldowns = [];
    this.used = [];
    this.mouseEvents = true;
  }

  public loadPowerups() {
    let game = Database.getGame(this.game.getGameId());
    this.pups = [];
    for(let i = 0; i < game.powerups.length; i++) 
      this.pups.push({id: game.powerups[i]});

    this.reset();

    this.removeAllChildren();

    for(let i = 0; i < this.pups.length; i++)  {
      let asset = Database.getAsset(this.pups[i].id);
      let owned = Server.assets.getInventoryQuantity(this.pups[i].id);
      let ownedText = '';
      if(owned > 0)
        ownedText = owned > 9 ? '9' : owned.toString();
      
      let button = new UIPanelButton({
        name: this.pups[i].id,
        panelColors : {normal : '#000000'},
        radius : 10,
        textColors : {normal : '#ffffff'},
        callback : (button:UIPanelButton) => {
          this.onPowerup(button.name);
        },
        anchor : {x : 0, y : 0},
        pivot : {x : 0, y : 0},
        size : {x : 50, y : 50},
        position : {x : 10, y : 10},
        parent : this
      });

      let image = new UIImage({
        name: 'image',
        url : getAssetImage(asset),
        anchor : {x : 0.5, y : 0.5},
        pivot : {x : 0.5, y : 0.5},
        position : {x : 0, y : 0},
        size : {x : 50, y : 50},
        radius : 10,
        borderWidth : 2,
        borderColor : '#000000',
        stretch : true,
        parent : button
      })

      let text = new UIText({
        name: 'text',
        text: ownedText,
        position: {x: -5, y: -3},
        anchor: {x: 1, y: 1},
        pivot: {x: 1, y: 1},
        horizontalAlign: 'center',
        fontFamily: 'verdana',
        fontSize: 10,
        parent: button
      });

      let cooldown = new UIText({
        name: 'cooldown',
        text: '',
        position: {x: 0, y: 0},
        anchor: {x: 0.5, y: 0.5},
        pivot: {x: 0.5, y: 0.5},
        horizontalAlign: 'center',
        fontFamily: 'verdana',
        fontSize: 18,
        color: '#FFFFFF',
        parent: button
      });
    }
  }

  public setPowerupDurations(durations:number[]) {
    for(let i = 0; i < durations.length; i++) 
      this.pups[i].duration = durations[i];
  }

  protected onPowerup(id:string) {
    let owned = Server.assets.getInventoryQuantity(id);
    let coins = Server.assets.getInventoryQuantity('coin');
    let price = Database.getShopAssetPrice(id);
    let developer = Database.getGame(this.game.getGameId()).developer;

    if(owned == 0 && price > 0 && !developer) {
      let pup = Database.getAsset(id);
      this.game.pause();
      this.game.askQuestion(`You do not have any\n${pup.name} powerups.\n\nDo you want to buy one for ${price} Coins?\n\nYou have ${numberWithCommas(coins)} Coins.`, (response:string)=>{
        if(response == 'Yes') 
          this.purchasePowerup(id);
        else
          this.game.resume();
      });
      return;
    }

    this.callback(id);
  }

  protected async purchasePowerup(id:string) {
    let asset = Database.getAsset(id);
    this.game.showMessage(`Purchasing ${asset.name}...`);

    setTimeout(async () => {
      let response = await Server.assets.purchaseAsset(id, 1);
      this.game.hideMessage();
  
      if(response.success) {
        let price = Database.getShopAssetPrice(id);
        Server.assets.addAssetToInventory(id, 1);
        Server.assets.removeAssetFromInventory('coin', price);
        Server.user.incrementCounter('purchase#' + id, 1);
        this.updatePowerupCount(id);
        this.game.resume();
        this.callback(id);
      }
      else {
        this.game.showAlert(response.message, ()=>{
          this.game.resume();
        });
      }
    }, 500);
  }

  public usePowerup(id:string) {
    let developer = Database.getGame(this.game.getGameId()).developer;

    let slot = -1;
    for(let i = 0; i < this.pups.length; i++)
      if(this.pups[i].id == id)
        slot = i;

    let button = this.getChild(slot) as UIPanelButton;
    button.disabled = true;
    let image = button.getChildByName('image');
    image.setAlpha(0.4);

    this.used[slot] = true;

    if(!developer) {
      Server.assets.consumeAsset(id, 1);
      Server.assets.removeAssetFromInventory(id, 1);
      this.updatePowerupCount(id);
    }

    if(this.pups[slot].duration > 0)
      this.startTimer(slot, this.pups[slot].duration);
  }

  protected updatePowerupCount(id:string) {
    let slot = -1;
    for(let i = 0; i < this.pups.length; i++)
      if(this.pups[i].id == id)
        slot = i;

    let owned = Server.assets.getInventoryQuantity(id);
    let ownedText = '';
    if(owned > 0)
      ownedText = owned > 9 ? '9' : owned.toString();

    let button = this.getChild(slot) as UIPanelButton;
    let countLabel = button.getChildByName('text') as UIText;
    countLabel.text = ownedText;
  }

  protected startTimer(slot:number, value:number) {
    this.cooldowns[slot] = value;

    this.timers[slot] = setInterval(()=>{
      this.cooldowns[slot]--;
      if(this.cooldowns[slot] == 0) {
        clearInterval(this.timers[slot]);
        this.timers[slot] = null;
      }
    }, 1000)
  }

  public update() {
    super.update();

    let gap = Math.min(50, ((this.size.x - 20) - (50 * this.pups.length)) / (this.pups.length - 1));
    let pw = (50 * this.pups.length) + (gap * (this.pups.length-1));
    let bx = (this.size.x/2) - (pw/2);

    for(let i = 0; i < this.pups.length; i++) {
      let button = this.getChild(i);
      button.position.x = bx + (i * (50 + gap));  
      button.position.y = 0;
      let cooldown = button.getChildByName('cooldown') as UIText;
      cooldown.text = this.cooldowns[i] > 0 ? `${this.cooldowns[i]}s` : '';

      let owned = Server.assets.getInventoryQuantity(this.pups[i].id);
      let ownedText = '';
      if(owned > 0)
        ownedText = owned > 9 ? '9' : owned.toString();

      let countLabel = button.getChildByName('text') as UIText;
      countLabel.text = ownedText;
    }
  }

  public isPowerupActive(id:string) {
    let slot = -1;
    for(let i = 0; i < this.pups.length; i++)
      if(this.pups[i].id == id)
        slot = i;

    if(slot == -1) return false;
    return this.cooldowns[slot] > 0;
  }

  public isPowerupUsed(id:string) {
    let slot = -1;
    for(let i = 0; i < this.pups.length; i++)
      if(this.pups[i].id == id)
        slot = i;

    if(slot == -1) return false;
    return this.used[slot];
  }

  public isAnyPowerupUnused() {
    for(let i = 0; i < this.pups.length; i++)
      if(!this.used[i])
        return true;
    return false;
  }

  protected clearTimers() {
    for(let i = 0; i < this.timers.length; i++) {
      clearInterval(this.timers[i]);
      this.timers[i] = null;
    }
  }

  public pause() {
    this.clearTimers();
  }

  public resume() {
    for(let i = 0; i < this.cooldowns.length; i++) 
      if(this.cooldowns[i] > 0)
        this.startTimer(i, this.cooldowns[i]);
  }

  public reset() {
    this.clearTimers();
    this.timers = [];
    this.cooldowns = [];
    this.used = [];
    for(let i = 0; i < this.pups.length; i++) {
      this.timers.push(null);
      this.cooldowns.push(0);      
      this.used.push(false);
      this.resetPowerup(this.pups[i].id);
    }
  }

  public resetPowerup(id:string) {
    let p = this.pups.find((p)=>p.id == id);
    if(!p) return;
    let i = this.pups.indexOf(p);
    if(i == -1) return;
    let button = this.getChild(i) as UIPanelButton;
    if(button) {
      button.disabled = false;
      let image = button.getChildByName('image');
      image.setAlpha(1);
    }
  }

  public resetPowerups() {
    for(let i = 0; i < this.pups.length; i++) 
      this.resetPowerup(this.pups[i].id);
  }

  public onMouseDown(x:number, y:number) {
    UICanvas.instance.stopEventPropogation();
  }
}