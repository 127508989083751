import { UICanvas } from '../common/ui/uiCanvas';
import { GameBase } from '../common/gameBase';
import { SpriteSheetManager } from '../common/spriteSheetManager';
import { fitInRange, getDeviceType } from '../common/util';
import { CardsManager } from './cardsManager';
import { Solitaire, TournamentType } from './solitaire';
import { GameStyle } from './gameStyle';
import { SplashScreen } from './splashScreen';
import { GameScreen } from './gameScreen';
import { GameOverDialog } from './gameOverDialog';
import { GameSettingsDialog } from './gameSettingsDialog';
import { GameModeDialog } from './gameModeDialog';
import { GameOptionsDialog } from './gameOptionsDialog';

export enum GameMode {
  Arcade,
  Klondike,
  Casual
}

export class Game extends GameBase {
  public static instance:Game = null;

  public splashScreen: SplashScreen;
  public gameScreen: GameScreen;
  public gameOverDialog: GameOverDialog;
  public gameModeDialog: GameModeDialog;
  public gameOptionsDialog: GameOptionsDialog;

  public spriteSheetManager: SpriteSheetManager;
  public cardsManager: CardsManager;
  public solitaire: Solitaire;

  public playing: boolean;
  public gameLaunched: boolean;
  public version: string;
  public deviceInfo: string;

  // control start/pause
  public started: boolean;
  public gameMode: GameMode;

  constructor(canvas:HTMLCanvasElement, callback:Function, gameMode:GameMode) {
    let gid = 'solitaire';

    super(canvas, callback, gid);

    this.playing = false;
    this.gameLaunched = false;
    this.version = '0.5.27.25';
    this.deviceInfo = `**${getDeviceType()}** - ${navigator.platform} - ${navigator.appVersion}`;
    this.solitaire = null;
    this.cardsManager = null;
    this.spriteSheetManager = null;
    this.dialogStyle = GameStyle.dialog;
    this.started = false;
    this.gameMode = gameMode;
  }

  public load() {
    super.load();

    Game.instance = this;

    this.spriteSheetManager = new SpriteSheetManager();
    this.cardsManager = new CardsManager();
    this.solitaire = new Solitaire();

    // this.roomDialog = new RoomDialog(GameStyle.dialog);
    // this.inviteFriendsDialog = new InviteFriendsDialog(GameStyle.dialog, GameStyle.inviteFriendsDialog);

    this.settingsDialog = new GameSettingsDialog(GameStyle.dialog);
    this.settingsDialog.build();
    
    this.gameOptionsDialog = new GameOptionsDialog(GameStyle.dialog);
    this.gameOptionsDialog.build();

    this.splashScreen = new SplashScreen();
    this.splashScreen.build();

    this.gameScreen = new GameScreen();
    this.gameScreen.build();

    this.gameOverDialog = new GameOverDialog();
    this.gameOverDialog.build();

    this.gameModeDialog = new GameModeDialog(GameStyle.dialog);
    this.gameModeDialog.build();

    this.splashScreen.show();
  };

  public unload() {
    // this.roomDialog = null;
    // this.inviteFriendsDialog = null;
    this.settingsDialog = null;
    this.gameOverDialog = null;
    this.gameModeDialog = null;
    this.gameOptionsDialog = null;

    this.gameScreen = null;
    this.splashScreen = null;

    this.solitaire = null;
    this.cardsManager = null;
    this.spriteSheetManager = null;

    Game.instance = null;
    super.unload();
  };

  //-----------------------------
  // The Start Game Workflow
  public start() {
    if (this.gameMode == GameMode.Arcade) 
      this.arcadeMode();
    else if (this.gameMode == GameMode.Klondike)
      this.klondikeMode();
    else if (this.gameMode == GameMode.Casual) {
      let settings = this.settingsDialog.getSettings();
      this.startGame(settings);
    }
    else
      this.selectGameMode();
  }

  public selectGameMode() {
    this.gameModeDialog.showModal((action: string)=>{
      if (action == 'arcade')
        this.arcadeMode();
      else if (action == 'klondike')
        this.klondikeMode();
      else
        this.casualMode();
    });
  }

  public klondikeMode(cardsPerFlip:number = 0) {
    this.gameMode = GameMode.Klondike;
    
    if(cardsPerFlip == 0) {
      let s = window.localStorage.getItem('KlondikeCardsPerFlip');
      if(s)
        cardsPerFlip = +s;
      else
        cardsPerFlip = 1;
    }

    this.startGame({
      cardsPerFlip: cardsPerFlip,
      passesAllowed: 0,
      timedGame: false,
      playingTournamentTimed: false,
      playingTournamentGames: false,
      scoring: false
    });
  }

  public arcadeMode() {
    this.gameMode = GameMode.Arcade;

    this.startGame({
      cardsPerFlip: 1,
      passesAllowed: 0,
      timedGame: true,
      tournamentTime: 5,
      playingTournamentTimed: true,
      playingTournamentGames: false,
      scoring: true
    });
  }
  
  public casualMode() {
    this.gameMode = GameMode.Casual;
    this.showSettings((gameStarted:boolean)=>{
      if(!gameStarted)
        this.selectGameMode();
    });
  }

  public async startGame(settings: any) {
    if(this.gameMode === GameMode.Arcade) {
      if(this.isMultiplayer())
        settings.seed = this.getPlaceSeed();
      this.startGame2(settings);
    }
    else
      this.startGame2(settings);
  }

  protected startGame2(settings:any) {
    if(settings.seed == null)
      settings.seed = this.solitaire.cs.deck.getInitialSeed();

    super.start(settings);

    this.solitaire.playingTournament = (settings.playingTournamentTimed || settings.playingTournamentGames);

    if (this.solitaire.playingTournament) {
      this.solitaire.tournamentStarted = false;
      this.solitaire.consecutiveLosses = 0;
      this.solitaire.consecutiveWins = 0;

      if (settings.playingTournamentTimed) {
        this.solitaire.tournamentType = TournamentType.Timed;
        let minutes = fitInRange(settings.tournamentTime, 0, 30, 10);
        this.solitaire.tournamentTimeToPlay = minutes * 60 * 1000;
      }
      else {
        this.solitaire.tournamentType = TournamentType.Games;
        let gamesToPlay = fitInRange(settings.tournamentGames, 2, 40, 6);
        this.solitaire.tournamentGamesToPlay = gamesToPlay;
      }
    }

    this.solitaire.giSeed = settings.seed;
    this.solitaire.setSeed(settings.seed);

    let autoPlay = true; 
    let s = localStorage.getItem('KlondikeAutoPlay');
    if(s)
      autoPlay = (s == '1');

    let flipLayout = true;
    s = localStorage.getItem('KlondikeFlipLayout');
    if(s)
      flipLayout = (s == '1');

    this.solitaire.startGame(settings.cardsPerFlip, settings.passesAllowed, autoPlay, settings.timedGame, settings.scoring, flipLayout);

    this.gameLaunched = true;
    this.playing = true;

    this.started = true;
  }

  public tick(deltaTime: number) {

    if (!this.started) return;

    if(!this.playing) {
      this.solitaire.updateTournamentTime(deltaTime);
      return;
    }

    let gameOver = this.solitaire.updateTournamentTime(deltaTime);

    if(gameOver)
      this.finish();
    else
      this.solitaire.update(deltaTime);
  }

  public finish() {
    this.playing = false;

    if (this.solitaire.getWonGame())
      this.solitaire.handleWin();
    else if (this.solitaire.getTournamentTimeExpired()) 
      this.solitaire.handleTimeExpired();
    else
      this.solitaire.handleLoss();

    if(this.gameMode === GameMode.Klondike) {
      if(this.solitaire.getWonGame()) { 
        Game.instance.incrementGameCounter('finish');
        this.askQuestion('Well done!  How about another?', (response:string)=>{
          if(response == 'Yes') {
            this.started = true;
            this.launchNextTournamentGame();
          }
          else
            Game.instance.quit();
        });
      }
      else {
        this.started = true;
        this.launchNextTournamentGame();
      }
    }
    else {
      this.started = false;
      UICanvas.instance.hideAllDialogs();
      this.gameOverDialog.showModal();
    }
  }

  protected stringifySettings(settings:any):string {
    let s = `${settings.cardsPerFlip}~${settings.passesAllowed}~${settings.timedGame}~${settings.playingTournamentTimed}~${settings.playingTournamentGames}~${settings.tournamentTime}~${settings.tournamentGames}~${settings.seed}`;
    return s;
  }

  protected parseSettings(s:string):any {
    let parts = s.split('~');
    let settings = {
      cardsPerFlip: parseInt(parts[0]),
      passesAllowed: parseInt(parts[1]),
      timedGame: (parts[2] == 'true') ? true : false,
      playingTournamentTimed: (parts[3] == 'true') ? true : false,
      playingTournamentGames: (parts[4] == 'true') ? true : false,
      tournamentTime: parseInt(parts[5]),
      tournamentGames: parseInt(parts[6]),
      seed: parseInt(parts[7])
    };
    return settings;
  }

  public launchNextTournamentGame() {
    this.solitaire.startNextTournamentGame();
    this.gameLaunched = true;
    this.playing = true;
  }

  public askGameLost() {
    this.askQuestion('No moves left.  End this game?', (response:string)=>{
      if(response == 'Yes') {
        if(Game.instance.gameMode == GameMode.Klondike)
          Game.instance.incrementGameCounter('restart');
        this.finish();
      }
    });
  }
}
