import { UICanvas } from '../common/ui/uiCanvas';
import { UIImage } from '../common/ui/uiImage';
import { UIPanel, UIPanelOptions } from '../common/ui/uiPanel';
import { randomNum } from '../common/util';
import { Game } from './game';

const POS_Y = 100;
const SPEED = new Map([[1, 250], [2, 500], [3, 1000]]);

export interface DistractorPanelOptions extends UIPanelOptions {
  callback: Function
}

export class DistractorPanel extends UIPanel
{
  public hidden:boolean;
  public paused:boolean;

  protected distractor: UIImage;
  protected scoreStarTime: number;
  protected clickableDuration: number;
  protected scored: boolean;
  protected callback: Function;

  /**
   * moving direction of the distractor
   * -- 0: left to right  1: right to left
   */
  protected direction: number;

  protected speed: number;
  protected widthOfWave: number;
  protected heightOfWave: number;

  constructor(options: DistractorPanelOptions) {
    options.mouseEvents = true;

    super(options);

    this.hidden = true;
    this.paused = true;
    this.clickableDuration = 1000;
    this.scored = false;
    this.callback = options.callback;

    this.distractor = new UIImage({
      url : require('./assets/ufo.png'),
      mouseEvents: true,
      position: {x: -100, y: POS_Y},
      parent : this
    });
  }

  public show() {
    this.scored = false;
    this.hidden = false;
    this.paused = false;

    this.direction = randomNum(0, 1);
    if (this.direction === 0) {
      this.distractor.position = {x: -100, y: POS_Y};
      this.distractor.flip = false;
    } else {
      this.distractor.position = {x: UICanvas.instance.width, y: POS_Y};
      this.distractor.flip = true;
    }
    
    let level = Math.min(5, Game.instance.level);
    this.speed = 0.2 + ((level-1) * 0.02);
    this.widthOfWave = 200 + Math.floor((level-1) * 20); 
    this.heightOfWave = 5 + Math.floor((level-1) * 3); 
  }

  public pause() {
    this.paused = true;
  }

  public resume() {
    this.paused = false;
  }

  public reset() {
    if(!this.hidden)
      this.hide();
  }

  public update() {
    super.update();

    if (this.hidden || this.paused) return;

    let x = this.distractor.position.x;
    let y = (this.size.y / 2) - (this.distractor.height / 2);
    let xinc = this.speed * this.deltaTime;

    if (this.direction === 0) {
      x += xinc;
    } else {
      x -= xinc;
    }
    
    y += Math.floor(Math.sin(x / this.widthOfWave * 2 * Math.PI) * this.heightOfWave);
    // console.log('[ control ]', this.speed, this.widthOfWave, this.heightOfWave);

    this.distractor.position = {x: x, y: y};

    if (this.direction === 0) {
      if (x > UICanvas.instance.width) {
        this.hidden = true;
      }
    } else {
      if (x < -110) {
        this.hidden = true;
      }
    }
  }

  public hide() {
    this.hidden = true;
    const x = this.distractor.position.x;
    const y = this.distractor.position.y;

    this.distractor.slide(x, y, x, -100, 500, ()=>{
      this.distractor.position = {x: -100, y: 0};
    });
  }

  public onMouseDown(x: number, y: number): void {
    let score = 0;
    
    if (!this.hidden && !this.scored) {
      let child = this.findChildFromPoint(x, y);
      if (child === this.distractor) 
        score = Game.instance.level * 200;
      else
        score = -10;
      this.scored = true;
      this.hide();
  } 

    this.callback(score, this, x, y);
  }
}