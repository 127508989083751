import React from 'react';
import { BsChatDotsFill, BsFillCheckSquareFill, BsGiftFill, BsXSquareFill } from 'react-icons/bs';
import { NavLink, Navigate } from 'react-router-dom';
import { Server } from '../../server/server';
import AlertModal from '../modals/AlertModal';
import MessageModal from '../modals/MessageModal';
import QuestionModal from '../modals/QuestionModal';
import '../pages/SitePage.css';
import './FriendPanel.css';
import Portrait from './Portrait';

interface FriendPanelProps {
  metadata: any;
  onUpdate: Function;
}

interface FriendPanelState {
  message: string;
  question: string;
  alert: string;
  navigate: string;
  friend: string;
}

class FriendPanel extends React.Component<FriendPanelProps, FriendPanelState> {

  constructor(props: FriendPanelProps) {
    super(props);
    this.state = {
      message: '',
      question: '',
      alert: '',
      navigate: '',
      friend: ''
    };

    this.onRemoveFriend = this.onRemoveFriend.bind(this);
    this.onAcceptFriend = this.onAcceptFriend.bind(this);
    this.onSendMessage = this.onSendMessage.bind(this);
    this.onViewProfile = this.onViewProfile.bind(this);
    this.onQuestionYes = this.onQuestionYes.bind(this);
    this.onQuestionNo = this.onQuestionNo.bind(this);
  }

  // new handlers
  onRemoveFriend() {
    let data = this.props.metadata;
    if(data.state == 'invited')
      this.setState({question: 'Decline the friend invite from ' + this.props.metadata.name + '?'});
    else if(data.state == 'pending')
      this.setState({question: 'Cancel the friend invitation to ' + this.props.metadata.name + '?'});
    else
      this.setState({question: 'Remove ' + this.props.metadata.name + ' from your friends list?'});
  }

  onQuestionYes() {
    let data = this.props.metadata;

    if(data.state == 'invited')
      this.setState({question: '', message: 'Declining invite...'});
    else if(data.state == 'pending')
      this.setState({question: '', message: 'Cancelling invite...'});
    else
      this.setState({question: '', message: 'Removing friend...'});

    setTimeout(async () => {
      let response = null;
      if(data.state == 'invited')
        response = await Server.user.declineFriend(data.id);
      else
        response = await Server.user.removeFriend(data.id);

      if (response.success) {
        if(data.state == 'invited')
          Server.user.incrementCounter('friends#decline');
        else
          Server.user.incrementCounter('friends#remove');
        this.setState({message: ''});
        this.props.onUpdate();
      }
      else 
        this.setState({alert: response.message, message: ''})
    }, 500);
  }

  onQuestionNo() {
    this.setState({question: ''});
  }

  onAcceptFriend() {
    this.setState({question: '', message: 'Accepting friend invite...'});

    setTimeout(async () => {
      let response = await Server.user.acceptFriend(this.props.metadata.id);

      if (response.success) {
        Server.user.incrementCounter('friends#accept');
        this.setState({message: ''});
        this.props.onUpdate();
      }
      else 
        this.setState({alert: response.message, message: ''})
    }, 500);
  }

  onSendMessage(e:any) {
    e.stopPropagation();

    let data = this.props.metadata;

    let chat = Server.chat.getDirectChat(data.id);

    if(chat) {
      window.sessionStorage.setItem('ChatPageId', chat.id);
      this.setState({navigate: '/chat'});
      return;
    }

    this.setState({message: 'Starting chat...'});

    setTimeout(async () => {
      let response = await Server.chat.startDirectChat(data.id);

      if(response.success) {
        chat = Server.chat.getDirectChat(data.id);
        window.sessionStorage.setItem('ChatPageId', chat.id);
        this.setState({navigate: '/chat'});
      }
      else {
        this.setState({alert: response.message, message: ''})
      }
    }, 500);
  }

  onGift() {
    this.setState({navigate: '/inventory'})
  }

  onViewProfile() {
    this.setState({navigate: '/profile/' + this.props.metadata.slug})
  }

  render() {
    let data = this.props.metadata;

    let presence = 'Offline';
    if(data.state == 'pending')
      presence = 'Friend invitation sent';
    else if(data.state == 'declined')
      presence = 'Declined your friend invitation';
    else if(data.state == 'invited')
      presence = 'Wants to be friends';
    else {
      if(data.presence && data.presence.online) 
        presence = 'Online';
    }

    return (
      <div>
        <div className="site-page-panel" id={data.id}>
          <div className="site-page-row">

          <NavLink to={'/profile/' + data.slug} style={{textDecoration: 'none'}}>
            <Portrait user={data.id} />
          </NavLink>

          <div style={{flexGrow: '1', cursor: 'pointer'}} onClick={this.onViewProfile}>
            <div className="site-page-title-text">{data.name}</div>
            <div className="site-page-subtext">{presence}</div>
          </div>

          {data.state == 'invited' &&
            <div className="friend-panel-button" onClick={this.onAcceptFriend}><BsFillCheckSquareFill/></div>
          }

          {data.state == 'invited' &&
            <div className="friend-panel-button" onClick={this.onRemoveFriend}><BsXSquareFill/></div>
          }

          {data.state == 'accepted' &&
            <div className="friend-panel-button" onClick={this.onSendMessage}><BsChatDotsFill/></div>
          }

          {data.state == 'accepted' &&
            <div className="friend-panel-button" onClick={()=>this.onGift()}><BsGiftFill/></div>
          }

          {data.state != 'invited' && 
            <div className="friend-panel-button" onClick={this.onRemoveFriend}><BsXSquareFill/></div>
          }
          </div>
        </div>

        {this.state.navigate != '' &&
          <Navigate to={this.state.navigate} />
        }

        <QuestionModal message={this.state.question} onYes={this.onQuestionYes} onNo={this.onQuestionNo} />
        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
      </div>
    )
  }
}

export default FriendPanel;