import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import HeaderBar from '../elements/HeaderBar';
import LoginModal from '../modals/LoginModal';
import MessageModal from '../modals/MessageModal';
import { Server } from '../../server/server';
import { Database } from '../util/database';

interface SitePageState {
  login: boolean;
  message: string;
  disconnected: boolean;
  version: boolean;
  maintenance: boolean;
  navigate: string;
}

class SitePage extends React.Component<{}, SitePageState> {
  protected disconnectedCount:number;

  constructor(props: {}) {
    super(props);
    this.state = {
      login: false,
      message: '',
      disconnected: false,
      version: false,
      maintenance: false,
      navigate: ''
    }

    this.disconnectedCount = 0;
  }

  componentDidMount() {
    this.checkForDisconnect();
    setTimeout(() => {
      this.checkStatus();
    }, 60 * 1000);
  }

  checkForDisconnect() {
    // console.log('checkForDisconnect', new Date().toLocaleTimeString());

    if(!Server.network.isConnected() && Server.account.isLoggedIn()) {
      this.disconnectedCount++;
      if(this.disconnectedCount > 5) {
        this.setState({disconnected: true})
        return;
      }
    }
    else
      this.disconnectedCount = 0;

    setTimeout(() => {
      this.checkForDisconnect();
    }, 1000);
  }

  async checkStatus() {
    if(!Server.network.isConnected())
      return;

    let isProduction = (window.location.href.indexOf('www.') != -1);
    if(!isProduction)
      console.log('checkStatus', new Date().toLocaleTimeString());
    
    let response:any = await Server.public.getStatus();

    if(!response.success)
      return;

    let playingGame = window.location.pathname.indexOf('/play/') == 0;

    if(response.status.maintenance) {
      Server.network.disconnect();
      this.setState({maintenance: true});
      if(playingGame) 
        window.history.back();
    }
    else if(Database.app.version < response.status.version && !playingGame) {
      Server.network.disconnect();
      this.setState({version: true});
    }
    else {
      setTimeout(() => {
        this.checkStatus();
      }, 5 * 60 * 1000);
    }
  }

  onLogin() {
    this.setState({login: true})
  }

  onLogout() {
    this.setState({message: 'Logging out...'});

    setTimeout(async () => {
      await Server.signOut();
      this.setState({message: ''});
    }, 1000);
  }

  renderModal(message:string) {
    return (
      <div className="modal open">
        <div className="modal-content" style={{width: '300px', textAlign: 'center', rowGap: '15px'}}>
          {message}
          <div><button onClick={()=>window.location.reload()}>Refresh</button></div>
        </div>
      </div>
    )
  }

  render() {
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />
      
    let modal = null;
    if(this.state.maintenance)
      modal = this.renderModal('The site is down for maintenance.  Please refresh the browser.');
    else if(this.state.version)
      modal = this.renderModal('There is a new version available!  Please refresh the browser to get the latest updates.');
    // else if(this.state.disconnected)    
    //   modal = this.renderModal('You have been disconnected due to inactivity.  Please refresh the browser to reconnect.');

    return (
      <div id="id-app-page">
        <HeaderBar onLogin={()=>this.onLogin()} onLogout={()=>this.onLogout()}/>
        <Outlet />
        <MessageModal message={this.state.message} />
        <LoginModal mode={this.state.login ? 'login' : ''} onClose={()=>this.setState({login: false})}/>
        {modal}
      </div>
    )
  }
}

export default SitePage;
