import React from 'react';
import { Navigate } from 'react-router-dom';
import { Server } from '../../server/server';
import AlertModal from '../modals/AlertModal';
import MessageModal from '../modals/MessageModal';
import PageEnvelope from '../elements/PageEnvelope';
import ContentPanel from '../elements/ContentPanel';
import PostPanel from '../elements/PostPanel';
import PageHeader from '../elements/PageHeader';
import './SitePage.css'
import { BsArrowDown, BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';
import { BiSolidSortAlt } from 'react-icons/bi';
import { HiArrowUturnDown } from 'react-icons/hi2';

interface BlogPageState {
  blog: any;
  message: string;
  alert: string;
  navigate: string;
  date: string;
  header: string;
  sort: boolean;
  more: any;
  edit: string;
  loadingMore: boolean;
}

class BlogPage extends React.Component<{}, BlogPageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      blog: null,
      message: '',
      alert: '',
      navigate: '',
      date: '',
      header: 'Loading...',
      sort: true,
      more: null,
      edit: '',
      loadingMore: false
    }
  }

  componentDidMount() {
    if(Server.account.isLoggedIn())
      this.getBlog();
  }

  async getBlog() {
    let slug = window.location.pathname.substring(6);

    let response:any = await Server.blogs.getBlog({slug});
    if (!response.success) {
      this.setState({header: response.message});
      return;
    }

    // cache profile of blog author
    let profiles = [];
    let blog = response.blog;
    profiles.push(blog.author);

    // get comments on blog
    response = await Server.blogs.getBlogComments({id: blog.id, sort: true, more: null});
    if (!response.success) {
      this.setState({header: response.message});
      return;
    }

    blog.comments = response.comments;

    for (let i = 0; i < blog.comments.length; i++) {
      if(profiles.indexOf(blog.comments[i].user) == -1)
        profiles.push(blog.comments[i].user);
    }

    // look up profiles
    await Server.public.loadProfiles(profiles);
    
    this.setState({blog, more: response.more});
  }

  async getComments(sort:boolean, more?:any) {
    let params:any = {id: this.state.blog.id, sort};
    if(more)
      params.more = more;

    let response = await Server.blogs.getBlogComments(params);

    if(!response.success)
      return;

    let blog = JSON.parse(JSON.stringify(this.state.blog));

    if(more) {
      for(let i = 0; i < response.comments.length; i++) {
        let exists = false;
        for(let j = 0; j < blog.comments.length; j++) 
          if(response.comments[i].date == blog.comments[j].date)
            exists = true;

        if(!exists)
          blog.comments.push(response.comments[i]);
      }
      this.sortComments(blog.comments, sort);
    }
    else
      blog.comments = response.comments;

    let profiles = [];
    for (let i = 0; i < blog.comments.length; i++) 
      if(profiles.indexOf(blog.comments[i].user) == -1)
        profiles.push(blog.comments[i].user);

    // look up profiles
    await Server.public.loadProfiles(profiles);
    
    this.setState({blog, more: response.more});
  }

  sortComments(comments:any[], sort:boolean) {
    comments.sort((a:any, b:any)=>{
      if(a.date > b.date)
        return sort ? 1 : -1;
      else if(a.date < b.date)
        return sort ? -1 : 1;
      return 0;
    })
  }

  async onCreateComment(content:string) {
    if(content == null)
      return;

    let now = new Date().toISOString();

    let newComment = {
      user: Server.user.getId(),
      content: content,
      date: now
    };

    this.state.blog.comments.push(newComment);
    this.sortComments(this.state.blog.comments, this.state.sort);

    this.forceUpdate();

    let params = {
      id: this.state.blog.id,
      content: content
    };

    let response = await Server.blogs.createComment(params);

    if(response.success) {
      Server.user.incrementCounter('blogs#reply');
      newComment.date = response.date;
    }
    else
      this.setState({alert: response.message})
  }

  onEditComment(date:string) {
    this.setState({edit: date});
  }

  async onSaveComment(content:string) {
    this.setState({edit: null});

    if(content == null) 
      return;

    for(let i = 0; i < this.state.blog.comments.length; i++) {
      if(this.state.blog.comments[i].date == this.state.edit) {
        if(content == '')
          this.state.blog.comments.splice(i, 1);
        else
          this.state.blog.comments[i].content = content;
        break;
      }
    }

    let response = null;

    if(content == '') {
      response = await Server.blogs.deleteComment({
        id: this.state.blog.id, 
        date: this.state.edit
      });
    }
    else {
      response = await Server.blogs.editComment({
        id: this.state.blog.id,
        date: this.state.edit,
        content: content
      });
    }
  
    if (!response.success) 
      this.setState({alert: response.message})
  }

  onEditBlog() {
    this.setState({navigate: '/blog/edit/' + this.state.blog.slug})
  }

  onJumpToComments() {
    document.getElementById('CommentsHeader')?.scrollIntoView({behavior: 'smooth', block: 'center'});
  }

  onJumpToBottom() {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }

  onSort() {
    if(this.state.more) 
      this.getComments(!this.state.sort);
    else 
      this.sortComments(this.state.blog.comments, !this.state.sort);

    this.setState({sort: !this.state.sort});
  }

  async onLoadMore() {
    this.setState({loadingMore: true});
    await this.getComments(this.state.sort, this.state.more);
    this.setState({loadingMore: false});
  }

  renderCommentsHeader() {
    let count = this.state.blog.comments.length;
    let label = `${count}${this.state.more ? '+' : ''} Comment${count > 1 ? 's' : ''}`;
    let headerId = 'CommentsHeader';

    return (
      <div className="site-page-header" id={headerId}>
        <div className="site-page-row" style={{justifyContent: 'space-between'}}>
          <div>
            {label}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', columnGap: '15px'}}>
            <div style={{cursor: 'pointer'}} onClick={()=>this.onJumpToBottom()}>
              Bottom&nbsp;<HiArrowUturnDown style={{fontSize: '0.9em', transform: 'translateY(2px)'}} />
            </div>
            <div style={{cursor: 'pointer'}} onClick={()=>this.onSort()}>
              Sort&nbsp;
              {this.state.sort ?
                <BsCaretUpFill style={{fontSize: '1.0em', transform: 'translateY(3px)'}} /> :
                <BsCaretDownFill style={{fontSize: '1.0em', transform: 'translateY(3px)'}} />
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderComments() {
    let divs = [];

    for (let i = 0; i < this.state.blog.comments.length; i++) {
      let comment = this.state.blog.comments[i];
      let action = comment.user == Server.user.getId() ? 'edit' : '';

      if(this.state.blog.comments[i].date == this.state.edit) {
        divs.push(
          <PostPanel 
            key={i}
            placeholder="Enter comment..."
            edit={comment.content}
            onPost={(content:string)=>this.onSaveComment(content)}
            words={1000}
          />
        )
      }
      else {
        divs.push(
          <ContentPanel 
            key={i}
            author={comment.user}
            date={comment.date}
            content={comment.content}
            reply={true}
            action={action}
            onAction={()=>this.onEditComment(comment.date)}
          />
        )
      }
    }

    return divs;
  }

  render() {
    if(!Server.account.isLoggedIn())
      return <Navigate to="/blogs" />;

    if(this.state.navigate != '') 
      return <Navigate to={this.state.navigate} />;

    let blog = this.state.blog;
    if (!blog) {
      return (
        <div className="site-page">
          <PageEnvelope width="600px">
            <PageHeader text={this.state.header} navigate="/blogs" />
          </PageEnvelope>
        </div>
      )
    }

    let action = (blog.author == Server.user.getId()) ? 'edit' : ''

    return (
      <div className="site-page">
        <PageEnvelope width="600px">
          <div className="site-page-column">

            <PageHeader text="View Blog" navigate="/blogs">
              {this.state.blog.comments.length > 0 &&
                <div style={{display: 'flex', cursor: 'pointer'}} onClick={()=>this.onJumpToComments()}>
                  Comments&nbsp;<HiArrowUturnDown style={{fontSize: '0.9em', transform: 'translateY(4px)'}} />
                </div>
              }
            </PageHeader>

            <ContentPanel
              author={blog.author}
              date={blog.date}
              title={blog.title}
              content={blog.content}
              action={action}
              onAction={()=>this.onEditBlog()}
            />

            <PostPanel 
              placeholder="Enter comment..."
              onPost={(content:string)=>this.onCreateComment(content)}
              words={1000}
            />

            {this.state.blog.comments.length > 0 &&
              this.renderCommentsHeader()
            }

            {this.renderComments()}

            {this.state.more &&
              <button onClick={()=>this.onLoadMore()} style={{backgroundColor: 'var(--page-background-color)', border: '1px solid var(--panel-background-color)'}}>
                {this.state.loadingMore ? 'Loading...' : 'Load More Replies'}
              </button>
            }

            {this.state.blog.comments.length > 2 &&
              <PostPanel 
                placeholder="Enter comment..."
                onPost={(content:string)=>this.onCreateComment(content)}
                words={1000}
              />
            }
          </div>
        </PageEnvelope>

        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
      </div>
    )
  }
}

export default BlogPage;