import { UIImage } from '../common/ui/uiImage';
import { UIPanel, UIPanelOptions } from '../common/ui/uiPanel';

const SLOW_UP   = 1500; // 1.5s
const SLOW_DOWN = 600;  // 0.6s
const ORI_UP    = 250;  // 0.25s
const ORI_DOWN  = 100;  // 0.1s
const CLICK_DUR = 1000; // 1s

export interface MolePanelOptions extends UIPanelOptions {
  callback: Function
}

export class MolePanel extends UIPanel
{
  public hidden:boolean;

  protected mole: UIImage;
  protected scoreStarTime: number;
  protected clickableDuration: number;
  protected scored: boolean;
  protected callback: Function;
  
  // For powerups functionality
  protected slideUpDuration: number;
  protected slideDownDuration: number;
  protected slowMotionActive: boolean;
  protected isSlideDown: boolean;

  constructor(options: MolePanelOptions) {
    options.mouseEvents = true;

    super(options);

    this.hidden = true;
    this.clickableDuration = CLICK_DUR;
    this.scored = false;
    this.callback = options.callback;
    
    // For powerups functionality
    this.slideUpDuration   = ORI_UP;
    this.slideDownDuration = ORI_DOWN;

    var panel = new UIPanel({
      size: {x: 100, y: 80},
      // color: '#ffffff80',
      clipChildren: true,
      parent : this
    });

    let moundBack = new UIImage({
      url : require('./assets/mound-back.png'),
      size: {x: 100, y: 100},
      stretch: true,
      position: {x: 0, y: 0},
      parent : panel
    });

    this.mole = new UIImage({
      url : require('./assets/mole.png'),
      size: {x: 100, y: 100},
      stretch: true,
      position: {x: 0, y: 80},
      parent : panel
    });

    let mound = new UIImage({
      url : require('./assets/mound.png'),
      size: {x: 100, y: 100},
      stretch: true,
      position: {x: 0, y: 0},
      parent : this
    });

  }

  // public show(slowMotion: boolean, spawnRate: number) {
  public show(slowMotion: boolean, spawnRate: number) {
    this.scored = false;
    this.hidden = false;
    this.clickableDuration = spawnRate * 2;
    this.slowMotionActive = slowMotion;
    this.slideUpDuration   = ORI_UP;
    this.slideDownDuration = ORI_DOWN;

    // Slow motion is active
    if (this.slowMotionActive) {
      this.slideUpDuration *= 3;
      this.slideDownDuration *= 3;
    } 

    // console.log('[ mole state ]', this.slideUpDuration, this.slideDownDuration, this.clickableDuration)

    this.mole.slide(0, 80, 0, 0, this.slideUpDuration, ()=>{
      this.scoreStarTime = performance.now();
      this.waitFor(this.clickableDuration, ()=>{
        if(!this.hidden && !this.isSlideDown)
          this.hide(true);
      });
    });
  }

  public hide(isMissed: boolean) {
    this.mole.cancelSlide();
    this.isSlideDown = true;
    this.mole.slide(0, this.mole.position.y, 0, 80, this.slideDownDuration * ((80-this.mole.position.y)/80), ()=>{
      this.waitFor(100, ()=>{
        this.hidden = true;
        this.isSlideDown = false;
        // if (isMissed) this.callback(-10, this, true);
      });
    });
  }

  public pause() {
    this.cancelSlide();
    this.cancelWaitFor();
    this.hide(false);
  }

  public resume() {

  }

  public onMouseDown(x:number, y: number): void {
    let score = 0;

    // Slow motion is active
    if (this.slowMotionActive) {
      if (!this.hidden && !this.scored) {
        this.slideDownDuration = ORI_DOWN;
        
        if (this.mole.isSliding()) {
          if (this.isSlideDown) {
            score = -10;
          } else {
            score = 100;
            this.scored = true;
            this.hide(false);
          }
        } else {
          score = this.calcScore();
        }
      } else {
        score = -10;
      }

    } else { // Slow motion is not active
      if (!this.hidden && !this.mole.isSliding() && !this.scored) {
        score = this.calcScore();
      } else {
        score = -10;
      }
    }

    this.callback(score, this, false);
  }

  private calcScore() {
    let score   = 0;
    let elapsed = performance.now() - this.scoreStarTime;
    let percent = ((this.clickableDuration - elapsed) / this.clickableDuration);

    if(percent >= 0.7) {
      score = 100;
    } else {
      score = (100 - Math.round(100 * ((0.7 - percent) / 0.7)));
    }

    this.cancelWaitFor();
    this.hide(false);
    this.scored = true;

    return score;
  }
}