import { UIManager } from "./uiManager";
import { UIButton } from "./uiButton";
import { UIButtonOptions } from "./uiButton";
import { UIPanel } from "./uiPanel";

export interface UIPanelButtonOptions extends UIButtonOptions {
  panelColors?: {
    normal: string,
    hover?: string,
    pressed?: string,
    disabled?: string
  };
  panelGradients?: {
    normal: any,
    hover?: any,
    pressed?: any,
    disabled?: any
  };
  radius?: number;
  borderWidth?: number;
  borderColor?: string;
  shadow?: {
    color?: string,
    blur?: number,
    offset?: {
      x?: number,
      y?: number
    }
  }
}

export class UIPanelButton extends UIButton {

  constructor(options: UIPanelButtonOptions) {
    if(options.panelColors == undefined)
      options.panelColors = {normal: '#ffffff'};

    var normalColor = options.panelColors.normal;
    var hoverColor = options.panelColors.hover ? options.panelColors.hover : normalColor;
    var pressedColor = options.panelColors.pressed ? options.panelColors.pressed : normalColor;
    var disabledColor = options.panelColors.disabled ? options.panelColors.disabled : normalColor;

    if(options.panelGradients == undefined)
      options.panelGradients = {normal: null};

    var normalGradient = options.panelGradients.normal;
    var hoverGradient = options.panelGradients.hover ? options.panelGradients.hover : normalGradient;
    var pressedGradient = options.panelGradients.pressed ? options.panelGradients.pressed : normalGradient;
    var disabledGradient = options.panelGradients.disabled ? options.panelGradients.disabled : normalGradient;

    let radius = 10;
    if(options.radius != undefined)
      radius = options.radius;

    let borderWidth = 0;
    if(options.borderWidth != undefined)
      borderWidth = options.borderWidth;

    let borderColor = '#00000000';
    if(options.borderColor != undefined)
      borderColor = options.borderColor;

    let shadow = null;
    if(options.shadow != undefined)
      shadow = options.shadow;

    options.elements = {
      normal: new UIPanel({
        color: normalColor,
        gradient: normalGradient,
        fitParent: {left: 0, top: 0, right: 0, bottom: 0},
        borderWidth: borderWidth,
        borderColor: borderColor,
        radius: radius,
        shadow: shadow
      }),
      hover: new UIPanel({
        color: hoverColor,
        gradient: hoverGradient,
        fitParent: {left: 0, top: 0, right: 0, bottom: 0},
        borderWidth: borderWidth,
        borderColor: borderColor,
        radius: radius,
        shadow: shadow
      }),
      pressed: new UIPanel({
        color: pressedColor,
        gradient: pressedGradient,
        fitParent: {left: 0, top: 0, right: 0, bottom: 0},
        borderWidth: borderWidth,
        borderColor: borderColor,
        radius: radius,
        shadow: shadow
      }),
      disabled: new UIPanel({
        color: disabledColor,
        gradient: disabledGradient,
        fitParent: {left: 0, top: 0, right: 0, bottom: 0},
        borderWidth: borderWidth,
        borderColor: borderColor,
        radius: radius,
        shadow: shadow
      })
    }

    super(options);
  }

  public setDisabledColors(panelColor:string, textColor:string) {
    (this.elements[3] as UIPanel).color = panelColor;
    this.updateElements();
    this.textColors[3] = textColor;
    this.updateTextColor();
  }

  public setBorderColor(c:string) {
    this.elements.forEach((element)=>{
      let panel = element as UIPanel;
      panel.borderColor = c;
    });
  }
}