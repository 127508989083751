import React from 'react';
import { NavLink } from 'react-router-dom';
import { BsArrowDown, BsBoxArrowDown, BsCaretDownFill, BsCaretLeftFill, BsCaretRightFill, BsExclamationCircleFill, BsList } from 'react-icons/bs';
import { Server } from '../../server/server';
import { getPortraitImage } from '../util/assets';
import './HeaderBar.css';
import { Database } from '../util/database';
import { getIcon } from '../util/icon';

const Menu = require('../../data/menu.json');

interface HeaderBarProps {
  onLogin: Function
  onLogout: Function
}

interface HeaderBarState {
  openSiteMenu: boolean;
  openUserMenu: boolean;
  openSocialMenu: string;
  navigate: string;
  activity: number;
  forums: number;
  blogs: number;
  chat: number;
  friends: number;
  notifications: number;
}

class HeaderBar extends React.Component<HeaderBarProps, HeaderBarState> {
  protected updateTimer:any;
  protected lastIcon: string;
  protected lastPortrait: string;
  protected lastLogo: string;
  protected lastActivity: number;
  protected lastForums: number;
  protected lastBlogs: number;
  protected lastNotifications: number;
  protected processingNotifications: boolean;
  protected lastBadgeCount: number;
  protected lastPostCheckTime: number;
  protected checkingForPosts: boolean;

  constructor(props: HeaderBarProps) {
    super(props);
    this.state = {
      openSiteMenu: false,
      openUserMenu: false,
      openSocialMenu: '',
      navigate: '',
      activity: 0,
      forums: 0,
      blogs: 0,
      chat: 0,
      friends: 0,
      notifications: 0
    };

    this.updateTimer = null;
    this.lastIcon = '';
    this.lastPortrait = '';
    this.lastLogo = '';
    this.lastActivity = -1;
    this.lastForums = -1;
    this.lastBlogs = -1;
    this.lastNotifications = -1;
    this.processingNotifications = false;
    this.lastBadgeCount = -1;
    this.lastPostCheckTime = 0;
    this.checkingForPosts = false;
  }

  componentDidMount() {
    this.updateNotification();
    this.checkForNewPosts();
    this.updateTimer = setInterval(()=>{
      this.updateNotification();
      this.updatePortrait();
      this.checkForNewPosts();
    }, 250)
  }

  componentWillUnmount() {
    clearInterval(this.updateTimer);
  }

  updateNotification() {
    let state = Server.getServiceState();

    let total =  0;
    total += (state.notifications > 0) ? 1 : 0;
    total += (state.friends > 0) ? 1 : 0;
    total += (state.chat > 0) ? 1 : 0;
    total += (state.blogs > 0) ? 1 : 0;
    total += (state.forums > 0) ? 1 : 0;
    total += (state.activity > 0) ? 1 : 0;

    let title = Database.app.title;
    if(total > 0)
      title = `(${total}) ` + title;
    document.title = title;

    if(this.state.activity != state.activity)
      this.setState({activity: state.activity});

    if(this.state.forums != state.forums) 
      this.setState({forums: state.forums});

    if(this.state.blogs != state.blogs)
      this.setState({blogs: state.blogs});

    if(this.state.chat != state.chat)
      this.setState({chat: state.chat});

    if(this.state.friends != state.friends)
      this.setState({friends: state.friends});

    if(this.state.notifications != state.notifications)
      this.setState({notifications: state.notifications});

    // if(this.lastActivity < state.activity) {
    //   if(this.lastActivity != -1)
    //     sendDeviceNotification('There are new activity posts!', 'DeviceNotificationsActivity');
    //   this.lastActivity = state.activity;
    // }

    // if(this.lastForums < state.forums) {
    //   if(this.lastForums != -1)
    //     sendDeviceNotification('There are new forum posts!', 'DeviceNotificationsForums');
    //   this.lastForums = state.forums;
    // }

    // if(this.lastBlogs < state.blogs) {
    //   if(this.lastBlogs != -1)
    //     sendDeviceNotification('There are new blog posts!', 'DeviceNotificationsBlogs');
    //   this.lastBlogs = state.blogs;
    // }

    if(this.lastNotifications < state.notifications && !this.processingNotifications) {
      if(this.lastNotifications != -1) 
        this.processNotifications(state);
      this.lastNotifications = state.notifications;
    }

    // let badgeCount = state.activity + state.forums + state.blogs + state.notifications + state.chat + state.friends;
    // if(this.lastBadgeCount != badgeCount) {
    //   setDeviceBadgeCount(badgeCount);
    //   this.lastBadgeCount = badgeCount;
    // }
  }

  async checkForNewPosts() {
    if(!Server.account.isLoggedIn() || !Server.network.isConnected()) {
      this.lastPostCheckTime = 0;
      return;
    }

    if(this.checkingForPosts)
      return;

    let now = performance.now();

    if(this.lastPostCheckTime > 0) {
      let elapsed = now - this.lastPostCheckTime;
      let seconds = document.hasFocus() ? 60 : 300;
      if(elapsed < (seconds * 1000))
        return;
    }

    let isProduction = (window.location.href.indexOf('www.') != -1);
    if(!isProduction)
      console.log('checkForNewPosts', new Date().toLocaleTimeString());

    this.checkingForPosts = true;

    await Server.activity.checkPosts();
    await Server.user.getNotifications();
    await Server.forums.checkPosts();
    await Server.blogs.checkForNewBlogs();

    this.checkingForPosts = false;
    this.lastPostCheckTime = now;
  }

  async processNotifications(state:any) {
    this.processingNotifications = true;

    let count = state.notifications - this.lastNotifications;
    let notifications = Server.user.getNotificationsFromCache();
    
    for(let i = 0; i < count; i++) {
      let nmess = '';
      let nstorage = '';

      switch(notifications[i].type) {
        case 'post-like':
          nmess = 'liked your post!';
          nstorage = 'DeviceNotificationsLikes';
          break;
        case 'post-reply':
          nmess = 'replied to your post!';
          nstorage = 'DeviceNotificationsReplies';
          break;
        case 'post-reply-reply':
          nmess = 'replied to a post you also replied to!';
          nstorage = 'DeviceNotificationsReplies';
          break;
        case 're-post':
          nmess = 'reposted your activity!';
          nstorage = 'DeviceNotificationsReplies';
          break;
        case 'forum-post':
          nmess = 'posted in your forum topic!';
          nstorage = 'DeviceNotificationsReplies';
          break;
        case 'forum-new-topic':
          nmess = 'posted a new forum topic!';
          nstorage = 'DeviceNotificationsReplies';
          break;
        case 'blog-comment':
          nmess = 'commented on your blog!';
          nstorage = 'DeviceNotificationsReplies';
          break;
        case 'friend-accept':
          break;
        case 'friend-decline':
          break;
        case 'friend-remove':
          break;
        case 'follow':
          break;
        case 'unfollow':
          break;
        case 'user-joined':
          break;
        case 'site-invite-accept':
          break;
      }

      if(nmess == '')
        continue;

      await Server.public.loadProfiles([notifications[i].user]);
      let profile = Server.public.getProfile(notifications[i].user);

      // sendDeviceNotification(profile.name + ' ' + nmess, nstorage);
    }

    this.processingNotifications = false;
  }

  updatePortrait() {
    if(!Server.account.isLoggedIn() || Server.synchronizing)
      return;

    if(Server.user.getPortrait() != this.lastPortrait) {
      this.lastPortrait = Server.user.getPortrait();
      this.forceUpdate();
    }
  }

  onLogoClick(e:any) {
    let hamburger = document.getElementById('hamburger') as HTMLDivElement;
    let display = window.getComputedStyle(hamburger, null).display;

    if(display != 'none') {
      this.setState({openSiteMenu: !this.state.openSiteMenu});
      e.preventDefault();
      e.stopPropagation();
    }
  }

  onLogout() {
    this.setState({openUserMenu: false});
    this.props.onLogout();
  }

  getMenuNotify(id:string) {
    let menu = Menu[id];
    if(!menu)
      return false;

    let serviceState = Server.getServiceState();
    let notify = false;

    for(let i = 0; i < menu.length; i++) {
      if(!menu[i].notify) continue;
      notify = serviceState[menu[i].notify] > 0;
      if(notify) break;
    }

    return notify;
  }

  renderSiteMenu() {
    if(!this.state.openSiteMenu)
      return null;

    let serviceState = Server.getServiceState();

    let buttons = [];
    let menu = Menu.site;
    for(let i = 0; i < menu.length; i++) {
      // if(menu[i].guest != undefined && menu[i].guest == Server.account.isLoggedIn())
      //   continue;

      // if(menu[i].role != undefined && menu[i].role != Server.user.getRole())
      //   continue;

      let icon = null;
      if(menu[i].icon)
        icon = React.createElement(getIcon(menu[i].icon.id));

      let notify = false;
      if(menu[i].path) 
        notify = menu[i].notify && serviceState[menu[i].notify] > 0;
      else if(menu[i].submenu) 
        notify = this.getMenuNotify(menu[i].submenu);

      if(notify)
        icon = <div style={{color: 'var(--notification-background-color)'}}><BsExclamationCircleFill size="1.0em" /></div>

      if(menu[i].header) {
        buttons.push(
          <div key={i} style={{padding: '0px', justifyContent: 'center', backgroundColor: 'var(--panel-background-color)'}}>
            - {menu[i].header} -
          </div>
        )
      }
      else if(menu[i].path) {
        buttons.push(
          <NavLink key={i} to={menu[i].path} onClick={()=>this.setState({openSiteMenu: false})}>
            <div className="site-page-row" style={{justifyContent: 'space-between', marginRight: '5px'}}>
              <div className="site-page-row" style={{columnGap: '7px'}}>
                <div style={{fontSize: '1.1em', transform: 'translateY(2px)'}}>{icon}</div>
                <div>{menu[i].name}</div>
              </div>
            </div>
          </NavLink>
        )  
      }
      else if(menu[i].submenu) {
        buttons.push(
          <div key={i} onClick={()=>this.setState({openSocialMenu: 'mobile', openSiteMenu: false})}>
            <div className="site-page-row" style={{justifyContent: 'space-between', marginRight: '5px'}}>
              <div className="site-page-row" style={{columnGap: '7px'}}>
                <div className="site-page-dropdown-menu-icon">{icon}</div>
                <div>{menu[i].name}</div>
                <div style={{width: '100%', textAlign: 'right'}}><BsCaretRightFill size="1.1em" style={{transform: 'translateY(3px)'}} /></div>
              </div>
            </div>
          </div>
        )
      }
    }

    return (
      <div>
        <div className="site-page-dropdown-menu">
          {buttons}
        </div>
        <div className="site-page-dropdown-modal" onClick={()=>this.setState({openSiteMenu: false})} />
      </div>
    )
  }

  renderUserMenu() {
    if(!this.state.openUserMenu)
      return null;

    let serviceState = Server.getServiceState();

    let buttons = [];
    let menu = Menu.user;
    for(let i = 0; i < menu.length; i++) {
      let icon = React.createElement(getIcon(menu[i].icon.id));
      let notify = menu[i].notify && serviceState[menu[i].notify] > 0;
      if(notify)
        icon = <div style={{color: 'var(--notification-background-color)'}}><BsExclamationCircleFill size="1.0em" /></div>

      if(menu[i].admin && !Server.account.isAdmin())
        continue;

      buttons.push(
        <NavLink key={i} to={menu[i].path} onClick={()=>this.setState({openUserMenu: false})}>
          <div className="site-page-row" style={{justifyContent: 'space-between', marginRight: '5px'}}>
            <div className="site-page-row" style={{columnGap: '7px'}}>
              <div className="site-page-dropdown-menu-icon">{icon}</div>
              <div>{menu[i].name}</div>
            </div>
          </div>
        </NavLink>
      )  
    }

    // logout
    buttons.push(
      <div key={menu.length} onClick={()=>this.onLogout()}>
        <div className="site-page-dropdown-menu-icon"><BsBoxArrowDown /></div>
        <div>Logout</div>
      </div>
    )  
  
    return (
      <div>
        <div className="site-page-dropdown-menu" style={{right: '0px', left: 'unset'}}>
          {buttons}
        </div>
        <div className="site-page-dropdown-modal" onClick={()=>this.setState({openUserMenu: false})} />
      </div>
    )
  }

  renderSocialMenu() {
    if(this.state.openSocialMenu == '')
      return null;

    let serviceState = Server.getServiceState();

    let buttons = [];
    let menu = Menu.social;
    for(let i = 0; i < menu.length; i++) {
      let icon = React.createElement(getIcon(menu[i].icon.id));
      let notify = menu[i].notify && serviceState[menu[i].notify] > 0;
      if(notify)
        icon = <div style={{color: 'var(--notification-background-color)'}}><BsExclamationCircleFill size="1.0em" /></div>

      // if(menu[i].role != undefined && menu[i].role != Server.user.getRole())
      //   continue;

      buttons.push(
        <NavLink key={i} to={menu[i].path} onClick={()=>this.setState({openSocialMenu: ''})}>
          <div className="site-page-row" style={{justifyContent: 'space-between', marginRight: '5px'}}>
            <div className="site-page-row" style={{columnGap: '7px'}}>
              <div className="site-page-dropdown-menu-icon">{icon}</div>
              <div>{menu[i].name}</div>
            </div>
          </div>
        </NavLink>
      )  
    }

    let left = '0px';
    if(this.state.openSocialMenu == 'pc')
      left = '350px';

    return (
      <div>
        <div className="site-page-dropdown-menu" style={{left}}>
          {buttons}
        </div>
        <div className="site-page-dropdown-modal" onClick={()=>this.setState({openSocialMenu: ''})} />
      </div>
    )
  }

  renderPortrait() {
    let profile = Server.user.getProfile();
    let portrait = getPortraitImage(profile);
    let notify = this.getMenuNotify('user');

    return (
      <div style={{position: 'relative', transform: 'translateY(1px)'}}>
        <img 
          className="site-page-portrait" 
          src={portrait} 
          onClick={()=>this.setState({openUserMenu: !this.state.openUserMenu})}
        />
        {notify &&
          <div className="site-page-notify-circle" style={{position: 'absolute', right: '-6px', top: '-3px'}}>
            <BsExclamationCircleFill size="1.2em" />
          </div>
        }
      </div>
    )
  }

  renderHeader() {
    let serviceState = Server.getServiceState();

    let menu = Menu.header;
    let links = [];
    for(let i = 0; i < menu.length; i++) {
      // if(menu[i].guest != undefined && menu[i].guest == Server.account.isLoggedIn())
      //   continue;
      // if(menu[i].role != undefined && menu[i].role != Server.user.getRole())
      //   continue;

      if(menu[i].path) {
        let notify = menu[i].notify && serviceState[menu[i].notify] > 0;
        links.push(
          <NavLink key={i} to={menu[i].path}>
            <div className="site-page-row" style={{columnGap: '3px'}}>
              <div>{menu[i].name}</div>
              {notify && 
                <div className="site-page-notify-circle" style={{transform: 'translate(2px, 1px)'}}>
                  <BsExclamationCircleFill size="1.1em" />
                </div>
              }
  
            </div>
          </NavLink>
        )
      }
      else if(menu[i].submenu) {
        let notify = this.getMenuNotify(menu[i].submenu);
        let state = menu[i].submenu ? {openSocialMenu: 'pc'} : null;

        links.push(
          <div key={i} className="site-page-row" style={{columnGap: '3px', cursor: 'pointer'}} onClick={()=>this.setState(state)}>
            <div>{menu[i].name}</div>
            <div style={{transform: 'translate(0px, 4px)'}}>
              <BsCaretDownFill size="0.9em" />
            </div>
            {notify && 
                <div className="site-page-notify-circle" style={{transform: 'translate(2px, 1px)'}}>
                  <BsExclamationCircleFill size="1.1em" />
                </div>
              }
          </div>
        )
      }
    }

    let siteNotifyMobile = this.getMenuNotify('site');

    if(!Server.account.isLoggedIn())
      links = [];

    let hamburger = null;
    if(Server.account.isLoggedIn()) {
      hamburger = (
        <div id="hamburger" className="headerbar-hamburger" onClick={()=>this.setState({openSiteMenu: !this.state.openSiteMenu})}>
          <BsList />
        </div>
      )
    }

    return (
      <div className="headerbar-contents">
        <div className="headerbar-left" style={{position: 'relative'}}>
          <div>
            {hamburger}
          </div>
          <NavLink to="/" onClick={(e:any)=>this.onLogoClick(e)}>
            <img className="headerbar-logo" src="/logo2.png"/>
          </NavLink>
          <div className="headerbar-menu">
            {links}
          </div>
        </div>
        {siteNotifyMobile && 
          <div className="site-page-notify-circle headerbar-notify-circle-mobile">
            <BsExclamationCircleFill size="1.4em" />
          </div>
        }
        {Server.account.isLoggedIn() ?
          this.renderPortrait() :
          <button className="headerbar-login-button" onClick={()=>this.props.onLogin()}>Login</button>
        }
      </div>
    )
  }

  render() {
    return (
      <div className="headerbar">
        {this.renderHeader()}
        {this.renderSiteMenu()}
        {this.renderUserMenu()}
        {this.renderSocialMenu()}
      </div>
    )
  }
}

export default HeaderBar;
