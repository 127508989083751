import React from 'react';
import { Database } from '../util/database';
import PageEnvelope from '../elements/PageEnvelope';
import { Server } from '../../server/server';
import { Navigate } from 'react-router-dom';
import GamesPanel from '../elements/GamesPanel';

interface GamesPageState {
  play: boolean;
  navigate: string;
}

class GamesPage extends React.Component<{}, GamesPageState> {
  constructor(props:{}) {
    super(props);

    this.state = {
      play: false,
      navigate: ''
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />
      
    return (
      <div className="site-page">
        <PageEnvelope width="600px">
          <GamesPanel all={true} stats={false} />
        </PageEnvelope>
      </div>
    );
  }
}

export default GamesPage;
