import { GameBase } from "./gameBase";
import { UIGrid } from "./ui/uiGrid";
import { UIImage } from "./ui/uiImage";
import { UIPanel, UIPanelOptions } from "./ui/uiPanel";
import { UIPanelButton } from "./ui/uiPanelButton";
import { UIText } from "./ui/uiText";

export interface GameHeaderOptions extends UIPanelOptions {
  game: GameBase;
  callback: Function;
  handleQuit?: boolean;
}

export class GameHeader extends UIPanel {
  protected callback: Function;
  protected game: GameBase;
  protected labels: UIText[];
  protected values: UIText[];
  protected panelGrid: UIGrid;
  protected playPlaceButton: UIPanelButton;
  protected menuButton: UIPanelButton;
  protected handleQuit: boolean;

  // constructor
  constructor(options: GameHeaderOptions) {
    options.fitParent = {left: 0, right: 0, top: -1, bottom: -1};
    // options.maxSize = {x: 600, y: 0};
    options.size = {x: 0, y: 50};
    // options.anchor = {x: 0.5, y: 0};
    // options.pivot = {x: 0.5, y: 0};
    // options.color = 'green';

    super(options);

    this.callback = options.callback;
    this.game = options.game;
    this.values = [];
    this.labels = [];
    this.handleQuit = options.handleQuit != undefined ? options.handleQuit : true;

    this.playPlaceButton = new UIPanelButton({
      size: {x: 40, y: 40},
      position: {x: 5, y: 5},
      callback : (btn:UIPanelButton) => {
        this.game.showPlayPlaceMenu();
      },
      panelColors: {normal: 'transparent'},
      textColors: {normal: 'white'},
      parent : this
    });

    let playPlaceIcon = new UIImage({
      url : '/playplace.png',
      parent : this.playPlaceButton
    });

    this.menuButton = new UIPanelButton({
      size: {x: 40, y: 40},
      anchor: {x: 1, y: 0},
      pivot: {x: 1, y: 0},
      position: {x: -5, y: 5},
      callback : (btn:UIPanelButton) => {
        this.onMenu();
      },
      panelColors: {normal: 'transparent'},
      textColors: {normal: 'white'},
      parent : this
    });

    let menuIcon = new UIImage({
      url : require('../common/assets/menu.png'),
      parent : this.menuButton
    });

    this.panelGrid = new UIGrid({
      columns: 3,
      rows: 1,
      spacing: {x: 5, y: 0},
      fitParent: {left: 50, right: 50, top: -1, bottom: -1},
      size: {x: 0, y: 45},
      position : {x : 0, y : 5},
      // color: 'green',
      parent : this
    });

    for(let i = 0; i < 3; i++) {
      let panel = new UIPanel({
        // color: 'green',
        parent : this.panelGrid
      });
      
      let labelText = new UIText({
        text : 'SCORE',
        fontFamily : 'verdana',
        fontSize : 10,
        anchor: {x: 0.5, y: 0},
        pivot: {x: 0.5, y: 0},
        position: {x: 0, y: 0},
        color: '#ffffff80',
        parent : panel
      });

      let valueText = new UIText({
        text : '12345',
        fontFamily : 'verdana',
        fontSize : (i == 1) ? 24 : 18,
        fontWeight: (i == 1) ? 'bold' : 'normal',
        anchor: {x: 0.5, y: 0},
        pivot: {x: 0.5, y: 0},
        position: {x: 0, y: (i == 1) ? 12 : 14},
        color: (i == 1) ? 'white' : '#ffffffc0',
        parent : panel
      });

      this.labels.push(labelText);
      this.values.push(valueText);
    }

  }

  public setLabels(labels:string[]) {
    for(let i = 0; i < 3; i++) {
      if(labels[i]) {
        this.labels[i].text = labels[i];
        this.values[i].text = '';
      }
      else {
        this.labels[i].text = '';
        this.values[i].text = '';
      }
    }
  }

  public setValue(i:number, value:string) {
    this.values[i].text = value;
  }

  public getPanel(i:number): UIPanel {
    return this.panelGrid.getChild(i) as UIPanel;
  }

  public disable() {
    this.playPlaceButton.disabled = true;
    this.menuButton.disabled = true;
  }

  public enable() {
    this.playPlaceButton.disabled = false;
    this.menuButton.disabled = false;
  }

  protected onMenu() {
    this.game.pause();
    this.game.showPauseDialog((action:string)=>{
      this.game.resume();
      if(action == 'restart') 
        this.callback('restart');
      else if(action == 'quit') 
        this.callback('quit');
      else if(action == 'options') {
        // what
      }
    }, this.handleQuit);
  }
}