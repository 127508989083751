import React from 'react';
import { Server } from '../../server/server';
import PageEnvelope from '../elements/PageEnvelope';
// import './SitePage.css';

interface SplacePageProps {
  onInit: Function;
}

interface SplashPageState {
  loading: boolean;
}

class SplashPage extends React.Component<SplacePageProps, SplashPageState> {
  constructor(props: SplacePageProps) {
    super(props);
    this.state = {
      loading: true
    }
  }

  componentDidMount(): void {
      this.loadPage();
  }

  async loadPage() {
    await Server.init();
    this.autoLogin();
  }

  async autoLogin() {
    // let response = await Server.autoLogin();
    this.props.onInit();
  }

  render() {
    return (
      <div className="site-page">
        <PageEnvelope width="450px">
          <div className="site-page-column">
            <div className="site-page-row" style={{justifyContent: 'center'}}>
              <div className="site-page-panel" style={{width: '350px', padding: '20px', rowGap: '0px', textAlign: 'center'}}>
                <div><img style={{width: '280px'}} src="/logo.png" /></div>
                <div><img src="/loading.gif" style={{width: '50px', marginTop: '20px'}}></img></div>
              </div>
            </div>
          </div>
        </PageEnvelope>
      </div>
    )
  }
}

export default SplashPage;

