import { UICanvas } from '../common/ui/uiCanvas';
import { UIElement, UIElementOptions } from '../common/ui/uiElement';
import { UIManager } from '../common/ui/uiManager';
import { UIText } from '../common/ui/uiText';
import { GameStyle } from './gameStyle';

export class CountdownPanel extends UIElement
{
  protected countdownText: UIText;
  protected started: boolean;
  protected fadingIn: boolean;

  constructor(options: UIElementOptions) {
    super(options);

    this.started = false;

    this.countdownText = new UIText({
      text: '',
      color: GameStyle.dialog.title.color,
      fontFamily: GameStyle.dialog.title.fontFamily,
      fontSize: GameStyle.dialog.title.fontSize,
      fontWeight: GameStyle.dialog.title.fontWeight,
      shadow: GameStyle.dialog.title.shadow,
      position: {x: 0, y: 0},
      anchor: {x: 0.5, y: 0.5},
      pivot: {x: 0.5, y: 0.5},
      horizontalAlign: 'center',
      visible: false,
      parent: this
    });
  }

  public start(callback:Function) {
    this.started = true;
    this.setAlpha(0);
    this.fadeIn(250);
    this.countdownText.text = 'READY...';
    this.countdownText.visible = true;
    this.countdownText.slide(0 - (UICanvas.instance.width/2) - this.countdownText.width, 0, 0, 0, 250);
    this.waitFor(1000, ()=>{
      this.countdownText.text = 'SET...';
      this.waitFor(1000, ()=>{
        this.countdownText.text = 'GO!';
        this.waitFor(500, ()=>{
          this.countdownText.slide(0, 0, (UICanvas.instance.width/2) + this.countdownText.width, 0, 250);
          this.fadeOut(250);
          this.waitFor(250, ()=>{
            callback();
          });
        });
      });
    });
  }

  public draw() {
    if(!this.started)
      return;

    // let gradient = UIManager.ctx.createLinearGradient(this.getScreenX(), this.getScreenY(), this.getScreenX(), this.getScreenY() + this.height);
    // gradient.addColorStop(0, '#00000000');
    // gradient.addColorStop(0.3, '#00000040');
    // gradient.addColorStop(0.7, '#00000040');
    // gradient.addColorStop(1, '#00000000');
    // UIManager.ctx.fillStyle = gradient;

    UIManager.ctx.fillStyle = '#00000060';
    UIManager.ctx.fillRect(this.getScreenX(), this.getScreenY(), this.width, this.height);
  }
}