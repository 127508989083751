import { GameFooter } from "../common/gameFooter";
import { GameHeader } from "../common/gameHeader";
import { UIGrid } from "../common/ui/uiGrid";
import { UIImage } from "../common/ui/uiImage";
import { UIManager } from "../common/ui/uiManager";
import { UIPanel } from "../common/ui/uiPanel";
import { UIPanelButton } from "../common/ui/uiPanelButton";
import { UIScreen } from "../common/ui/uiScreen";
import { UIText } from "../common/ui/uiText";
import { getTimeString, numberWithCommas } from "../common/util";
import { DicePanel } from "./dicePanel";
import { Game } from "./game";
import { GameStyle } from "./gameStyle";

export class GameScreen extends UIScreen {
  protected diceGrid: UIGrid;
  protected rollButton: UIPanelButton;
  protected bottomPanel: UIPanel;
  protected dicePanels: DicePanel[];
  protected submitButton: UIPanelButton;
  protected categoryPanels: UIPanel[];
  protected rollsLeft: number;
  protected selectedCategory: number;
  protected scoreLabels: UIText[];
  protected scoreLabel: UIText;
  protected score: number;
  protected categoryScores: number[];
  protected categoryLocked: boolean[];
  protected categoryBonus: number[];
  protected header: GameHeader;
  protected footer: GameFooter;
  protected topPanel: UIPanel;
  protected gameTime: number;

  public build() {
    this.mouseEvents = true;

    let topPanelHeight = UIManager.isMobile ? 360 : 430;
    let topPanelRowHeight = UIManager.isMobile ? 40 : 50;

    let background = new UIPanel({
      fitParent: {left: 0, right: 0, top: 0, bottom: 0},
      color: '#5e8643',
      parent : this
    });

    this.header = new GameHeader({
      game: Game.instance,
      callback: (action:string)=>{
        if(action == 'restart')
          this.initGame();
      },
      parent : this
    });

    this.topPanel = new UIPanel({
      fitParent: {left: 10, right: 10, top: -1, bottom: -1},
      maxSize: {x: 440, y: -1},
      minSize: {x: 320, y: -1},
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      size: {x: 0, y: topPanelHeight},
      position: {x: 0, y: UIManager.isMobile ? 60 : 80},
      radius: 10,
      color: '#284f41',
      parent : this
    });

    let topPanelGrid = new UIGrid({
      columns: 2,
      rows: 1,
      fitParent: {left: 10, right: 10, top: 10, bottom: 10},
      spacing: {x: 10, y: 0},
      parent : this.topPanel
    });

    let topLeftPanel = new UIPanel({
      // color: 'orange',
      parent : topPanelGrid
    });

    let topRightPanel = new UIPanel({
      // color: 'orange',
      parent : topPanelGrid
    });

    let decriptions = [
      'Ones', 'Twos', 'Threes', 'Fours', 'Fives', 'Sixes', 'Anything',
      'Two Pair', '3 of a Kind', '4 of a Kind', 'Full House', 'Small Straight', 'Large Straight', '5 of a Kind'
    ]

    // if(UIManager.isMobile) {
    //   decriptions[8] = 'Three of a\nKind';
    //   decriptions[9] = 'Four of a\nKind';
    //   decriptions[11] = 'Small\nStraight';
    //   decriptions[12] = 'Large\nStraight';
    //   decriptions[13] = 'Five of a\nKind';
    // }

    let rowGap = UIManager.isMobile ? 10 : 10;
    let rowHeightPlusGap = topPanelRowHeight + rowGap;

    this.categoryPanels = [];
    this.scoreLabels = [];

    for(let i = 0; i < 14; i++) {
      let parent = (i < 7) ? topLeftPanel : topRightPanel;
      let sy = (i < 7) ? (i * rowHeightPlusGap) : ((i-7)*rowHeightPlusGap);

      let row = new UIPanel({
        fitParent: {left: 0, right: 0, top: -1, bottom: -1},
        size: {x: 0, y: topPanelRowHeight},
        position: {x: 0, y: sy},
        // borderWidth: 1,
        // borderColor: '#ffffff80',
        radius: 6,
        parent : parent
      });

      let scorePanel = new UIPanel({
        size: {x: topPanelRowHeight, y: topPanelRowHeight},
        position: {x: 0, y: 0},
        color: 'white',
        radius: 6,
        // borderColor: '#222222',
        // borderWidth: 2,
        parent : row
      });

      let scoreText = new UIText({
        text : '',
        fontFamily : 'verdana',
        fontSize : 18,
        fontWeight: 'bold',
        anchor: {x: 0.5, y: 0.5},
        pivot: {x: 0.5, y: 0.5},
        position: {x: -1, y: 0},
        color: 'black',
        parent : scorePanel
      })

      let description = new UIText({
        text : decriptions[i],
        fontFamily : 'verdana',
        fontSize : 14,
        anchor: {x: 0, y: 0.5},
        pivot: {x: 0, y: 0.5},
        position: {x: rowHeightPlusGap, y: 0},
        color: 'white',
        parent : row
      });

      let button = new UIPanelButton({
        name: i.toString(),
        text : '',
        callback: (btn:UIPanelButton) => {
          this.onCategory(+btn.name);
        },
        fitParent: {left: 0, right: 0, top: 0, bottom: 0},
        panelColors: {normal: 'transparent'},
        parent : row
      });

      let bonusLabel = new UIText({
        text : '',
        fontFamily : 'verdana',
        fontSize : 11,
        fontWeight: 'bold',
        anchor: {x: 1, y: 0},
        pivot: {x: 1, y: 0},
        position: {x: -4, y: 0},
        horizontalAlign: 'right',
        color: 'yellow',
        parent : row
      })

      this.categoryPanels.push(row);
      this.scoreLabels.push(scoreText);
    }

    this.bottomPanel = new UIPanel({
      fitParent: {left: 10, right: 10, top: -1, bottom: -1},
      maxSize: {x: 440, y: -1},
      minSize: {x: 300, y: -1},
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      size: {x: 0, y: 110},
      position: {x: 0, y: this.topPanel.position.y + this.topPanel.size.y + 10},
      radius: 10,
      color: '#284f41',
      parent : this
    });

    this.diceGrid = new UIGrid({
      columns: 6,
      rows: 1,
      // spacing: {x: 5, y: 0},
      fitParent: {left: 5, right: 5, top: -1, bottom: -1},
      size: {x: 0, y: 0},
      position: {x: 0, y: 5},
      // anchor: {x: 0.5, y: 0},
      // pivot: {x: 0.5, y: 0},
      // color: 'orange',
      parent : this.bottomPanel
    });

    this.dicePanels = [];
    for(let i = 0; i < this.diceGrid.columns; i++) {
      let panel = new UIPanel({
        parent : this.diceGrid
      });

      let dice = new DicePanel({
        fitParent: {left: 5, right: 5, top: 5, bottom: 5},
        parent : panel
      });

      this.dicePanels.push(dice);
    }

    this.rollButton = new UIPanelButton({
      text : 'Roll',
      callback : () => {
        this.onRoll();
      },
      position : {x : 10, y : 50},
      size : {x : 120, y : 40},
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      textOffset: {x: 0, y: 0},
      parent : this.bottomPanel
    });

    this.submitButton = new UIPanelButton({
      text : 'Save',
      callback : () => {
        this.onSubmit();
      },
      anchor : {x : 1, y : 1},
      pivot : {x : 1, y : 1},
      position : {x : -10, y : -10},
      size : {x : 120, y : 40},
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      textOffset: {x: 0, y: 0},
      parent : this.bottomPanel
    });

    this.footer = new GameFooter({
      game: Game.instance,
      callback: (id:string)=>{
        this.onPowerup(id);
      },
      parent : this
    });
  }

  public onWake() {
    super.onWake();

    this.header.setLabels(['MATCHES', 'SCORE', 'TIME']);
    this.footer.loadPowerups();

    this.initGame();    

    Game.instance.pause();
    Game.instance.showWelcome(()=>{
      Game.instance.resume();
    });
  }

  protected initGame() {
    this.score = 0;
    this.rollsLeft = 3;
    this.selectedCategory = -1;
    this.gameTime = 0;

    for(let i = 0; i < this.dicePanels.length; i++) 
      this.dicePanels[i].clear();

    this.categoryScores = [];
    this.categoryLocked = [];
    this.categoryBonus = [];
    for(let i = 0; i < 14; i++) {
      this.categoryScores.push(-1);
      this.categoryLocked.push(false);
      this.categoryBonus.push(0);
      this.scoreLabels[i].text = '';
      this.categoryPanels[i].color = '#000000aa';
      this.setBonus(i, 1);
    }

    let r = Math.floor(Math.random() * 6);
    this.setBonus(r, 2);

    this.diceGrid.columns = 5;

    this.footer.reset();
  }

  public update() {
    super.update();

    for(let i = 0; i < this.diceGrid.childCount; i++) 
      this.diceGrid.getChild(i).visible = (i < this.diceGrid.columns);

    this.diceGrid.size.y = (this.diceGrid.size.x - 10) / this.diceGrid.columns;
    this.rollButton.position.y = this.diceGrid.position.y + this.diceGrid.size.y + 5;
    // this.submitButton.position.y = this.diceGrid.position.y + this.diceGrid.size.y + 5;
    this.bottomPanel.size.y = 20 + this.diceGrid.size.y + this.rollButton.size.y;
    this.rollButton.text = `Roll (${this.rollsLeft})`;

    let gameY = Math.max(60, Math.min(100, this.height - 700));

    this.topPanel.position.y = gameY;
    this.bottomPanel.position.y = gameY + this.topPanel.size.y + 10;

    if(!Game.instance.isPaused())
      this.gameTime += this.deltaTime;

    let categories = 0;
    for(let i = 0; i < this.categoryScores.length; i++)
      if(this.categoryScores[i] != -1)
        categories++;

    this.header.setValue(0, categories.toString() + '/' + this.categoryScores.length);
    this.header.setValue(1, numberWithCommas(this.score));
    this.header.setValue(2, getTimeString(this.gameTime));
  }

  protected clearSelectedCategory() {
    if(this.selectedCategory != -1) {
      this.categoryPanels[this.selectedCategory].color = '#000000aa';
      this.categoryPanels[this.selectedCategory].borderWidth = 0;
      this.categoryScores[this.selectedCategory] -= this.getScoreForCategory(this.selectedCategory);
      if(this.categoryScores[this.selectedCategory] == 0)
        this.categoryScores[this.selectedCategory] = -1;
      this.updateCategoryScore(this.selectedCategory);
      this.categoryLocked[this.selectedCategory] = false;
    }
    this.selectedCategory = -1;
  }

  protected setBonus(idx:number, value:number) {
    if(this.selectedCategory == idx)
      this.categoryScores[idx] -= this.getScoreForCategory(idx);

    this.categoryBonus[idx] = value; 

    if(this.selectedCategory == idx)
      this.categoryScores[idx] += this.getScoreForCategory(idx);

    let bonusLabel = this.categoryPanels[idx].getChild(3) as UIText;
    bonusLabel.text = value == 1 ? '' : 'x' + value;

    if(this.selectedCategory == idx)
      this.updateCategoryScore(idx);
  }

  protected isRolling() {
    for(let i = 0; i < this.diceGrid.columns; i++) 
      if(this.dicePanels[i].isRolling())
        return true;
    return false;
  }

  protected getDiceScoreForValue(val:number) {
    let total = 0;
    for(let i = 0; i < this.diceGrid.columns; i++) {
      let v = this.dicePanels[i].getValue();
      if(v == val || val == -1)
        total += v;
    }
    return total;
  }

  protected getDiceScore() {
    return this.getDiceScoreForValue(-1);
  }

  protected countDiceValues() {
    let values = [0, 0, 0, 0, 0, 0];
    for(let i = 0; i < this.diceGrid.columns; i++) {
      let v = this.dicePanels[i].getValue();
      values[v-1]++;
    }
    return values;
  }

  protected getDiceValuePattern() {
    let values = this.countDiceValues();
    let pattern = '';
    for(let i = 0; i < values.length; i++) 
      pattern += (values[i] == 0) ? '-' : '*';
    return pattern;
  }

  protected isFullHouse() {
    let values = this.countDiceValues();

    let foundThree = -1;

    for(let i = 0; i < values.length; i++) 
      if(values[i] >= 3)
        foundThree = i;

    if(foundThree == -1)
      return false;

    for(let i = 0; i < values.length; i++) 
      if(i != foundThree && values[i] >= 2)
        return true;
    
    return false;
  }

  protected isSmallStraight() {
    let pattern = this.getDiceValuePattern();
    return (pattern.indexOf('****') != -1);
  }

  protected isLargeStraight() {
    let pattern = this.getDiceValuePattern();
    return (pattern.indexOf('*****') != -1);
  }

  protected isOfAKind(count:number) {
    let values = this.countDiceValues();
    return values.some((v)=>v >= count);
  }

  protected isTwoPair() {
    let values = this.countDiceValues();

    let pairCount = 0;
    for(let i = 0; i < values.length; i++) 
      if(values[i] >= 2)
        pairCount++;

    if(pairCount == 2)
      return true;

    return this.isOfAKind(4);
  }

  protected getScoreForCategory(i:number) {
    let bonus = this.categoryBonus[i];

    if(i >= 0 && i <= 5)
      return this.getDiceScoreForValue(i+1) * bonus;

    if(i == 6)
      return this.getDiceScore() * bonus;

    if(i == 7 && this.isTwoPair())
      return this.getDiceScore() * bonus;

    if(i == 8 && (this.isOfAKind(3) || this.isOfAKind(4) || this.isOfAKind(5)))
      return this.getDiceScore() * bonus;

    if(i == 9 && (this.isOfAKind(4) || this.isOfAKind(5)))
      return this.getDiceScore() * bonus;

    if(i == 10 && this.isFullHouse())
      return 25 * bonus;

    if(i == 11 && this.isSmallStraight())
      return 30 * bonus;

    if(i == 12 && this.isLargeStraight())
      return 40 * bonus;

    if(i == 13 && this.isOfAKind(5))
      return 50 * bonus;

    return 0;
  }

  protected onCategory(idx:number) {
    if(this.isRolling())
      return;

    if(this.categoryLocked[idx])
      return;

    if(this.selectedCategory == idx) {
      this.clearSelectedCategory();
      return;
    }

    if(this.getDiceScore() <= 0)
      return;

    this.clearSelectedCategory();

    // this.categoryPanels[idx].color = '#6ecadf80';
    this.categoryPanels[idx].borderColor = 'orange';
    this.categoryPanels[idx].borderWidth = 2;

    if(this.categoryScores[idx] == -1)
      this.categoryScores[idx]++;
    this.categoryScores[idx] += this.getScoreForCategory(idx);
    
    this.updateCategoryScore(idx);

    this.selectedCategory = idx;
  }

  protected updateCategoryScore(idx:number) {
    if(this.categoryScores[idx] == -1)
      this.scoreLabels[idx].text = '';
    else {
      let s = this.categoryScores[idx].toString();
      this.scoreLabels[idx].text = s;
      this.scoreLabels[idx].fontSize = s.length < 3 ? 18 : 15;
    }
  }

  protected onRoll() {
    if(this.isRolling())
      return;

    if(this.rollsLeft == 0)
      return;

    this.clearSelectedCategory();

    this.rollsLeft--;

    for(let i = 0; i < this.diceGrid.columns; i++) 
      this.dicePanels[i].roll();

    // this.dicePanels[0].setValue(4);
    // this.dicePanels[1].setValue(4);
    // this.dicePanels[2].setValue(4);
    // this.dicePanels[3].setValue(4);
    // this.dicePanels[4].setValue(4);
    // this.dicePanels[5].setValue(4);
  }

  protected onSubmit() {
    if(this.isRolling())
      return;

    if(this.selectedCategory == -1) {
      Game.instance.showAlert('Please select a category for these dice.');
      return;
    }

    let score = this.getScoreForCategory(this.selectedCategory);
    this.score += (score * 10);

    if(this.selectedCategory < this.categoryPanels.length-1 || score == 0) {
      this.categoryPanels[this.selectedCategory].color = '#ffffff40';
      this.categoryPanels[this.selectedCategory].borderWidth = 0;
      this.categoryLocked[this.selectedCategory] = true;
    }
    else {
      this.categoryPanels[this.selectedCategory].color = '#000000aa';
      this.categoryPanels[this.selectedCategory].borderWidth = 0;
    }

    this.selectedCategory = -1;
    this.rollsLeft = 3;

    this.diceGrid.columns = 5;

    for(let i = 0; i < this.dicePanels.length; i++) 
      this.dicePanels[i].clear();

    let gameOver = !this.categoryLocked.includes(false);

    if(gameOver) 
      this.showGameOver();
  }

  protected unlockCategory() {
    let indexes = [];
    for(let i = 0; i < this.categoryLocked.length; i++) 
      if(this.categoryLocked[i]) 
        indexes.push(i);

    if(indexes.length == 0) {
      Game.instance.showAlert('There are no locked categories!');
      return false;
    }

    let r = Math.floor(Math.random() * indexes.length);
    let i = indexes[r];

    this.categoryPanels[i].color = '#000000aa';
    this.categoryLocked[i] = false;

    return true;
  }

  protected addBonus() {
    let indexes = [];
    for(let i = 0; i < this.categoryLocked.length-1; i++) 
      if(!this.categoryLocked[i] && this.categoryBonus[i] == 1) 
        indexes.push(i);

    if(indexes.length == 0) {
      Game.instance.showAlert('There are no places for a bonus!');
      return false;
    }

    let r = Math.floor(Math.random() * indexes.length);
    let i = indexes[r];
    this.setBonus(i, 2);

    return true;
  }

  protected onPowerup(id:string) {
    if(id == 'yahtzee-extra-roll') 
      this.rollsLeft++;
    else if(id == 'yahtzee-extra-dice') {
      this.diceGrid.columns = 6;
      if(this.dicePanels[0].getValue() != -1)
        this.dicePanels[5].roll();
    }
    else if(id == 'yahtzee-double-points') {
      if(!this.addBonus())
        return;
    }
    else if(id == 'yahtzee-unlock') {
      if(!this.unlockCategory())
        return;
    }

    this.footer.usePowerup(id);
  }

  protected showGameOver() {
    Game.instance.showGameOver(this.score, this.score, (action:string)=>{
      if(action == 'restart')
        this.initGame();
    });
  }
}