import { Server } from "../../server/server";
import { UIImage } from "../common/ui/uiImage";
import { UIManager } from "../common/ui/uiManager";
import { UIPanel } from "../common/ui/uiPanel";
import { UIPanelButton } from "../common/ui/uiPanelButton";
import { UIScreen } from "../common/ui/uiScreen";
import { UIText } from "../common/ui/uiText";
import { getTimeString, numberWithCommas } from "../common/util";
import { Game } from "./game";
import { GameStyle } from "./gameStyle";

// https://lukerissacher.com/battleships/HEUBFVAAIl-QA

export class GameScreen extends UIScreen {
  protected board: any;
  protected tiles: any[];
  protected timeLabel: UIText;
  protected time: number;
  protected gameOver: boolean;
  protected boardPos: any;
  protected boardWidth: number;
  protected tileWidth: number;
  protected draggingTile: boolean;
  protected dragTile: number;
  protected resetButton: UIPanelButton;
  protected shipsPlaced: any[];
  protected showMistakes: boolean;
  protected showMistakesButton: UIPanelButton;
  protected showMistakesLeft: number;
  protected generations: number;

  public build() {
    let background = new UIPanel({
      fitParent: {left: 0, right: 0, top: 0, bottom: 0},
      color: '#333333',
      parent : this
    });

    this.buildHeader();

    this.resetButton = new UIPanelButton({
      text : 'Reset',
      callback : () => {
        this.onReset();
      },
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      position : {x : 0, y : 520},
      size : {x : 120, y : 40},
      panelColors: {normal: 'blue'},
      textColors: {normal: 'white'},
      fontFamily: 'arial',
      fontSize: 18,
      textOffset: {x: 0, y: 0},
      parent : this
    });

    this.showMistakesButton = new UIPanelButton({
      text : 'Show Mistakes',
      callback : () => {
        this.onShowMistakes();
      },
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      position : {x : 0, y : 520},
      size : {x : 170, y : 40},
      panelColors: {normal: 'blue'},
      textColors: {normal: 'white'},
      fontFamily: 'arial',
      fontSize: 18,
      textOffset: {x: 0, y: 0},
      parent : this
    });
  }

  protected buildHeader() {
    let header = new UIPanel({
      fitParent: {left: 0, right: 0, top: -1, bottom: -1},
      size: {x: 0, y: 60},
      color: '#222222',
      parent : this
    });

    let playPlaceButton = new UIPanelButton({
      size: {x: 40, y: 40},
      position: {x: 10, y: 10},
      callback : (btn:UIPanelButton) => {
        Game.instance.showPlayPlaceMenu();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      borderWidth: GameStyle.dialog.button.borderWidth,
      borderColor: GameStyle.dialog.button.borderColor,
      parent : header
    });

    let playPlaceIcon = new UIImage({
      url : '/playplace.png',
      parent : playPlaceButton
    });

    let pauseButton = new UIPanelButton({
      anchor: {x: 1, y: 0},
      pivot: {x: 1, y: 0},
      size: {x: 40, y: 40},
      position: {x: -10, y: 10},
      callback : (btn:UIPanelButton) => {
        this.onPause();
      },
      panelColors: GameStyle.dialog.button.panelColors,
      textColors: GameStyle.dialog.button.textColors,
      radius: GameStyle.dialog.button.radius,
      fontFamily: GameStyle.dialog.button.fontFamily,
      fontSize: GameStyle.dialog.button.fontSize,
      borderWidth: GameStyle.dialog.button.borderWidth,
      borderColor: GameStyle.dialog.button.borderColor,
      parent : header
    });

    let menuIcon = new UIImage({
      url : require('../common/assets/menu.png'),
      position: {x: 0, y: 1},
      parent : pauseButton
    });

    let scoreLabel = new UIText({
      text : 'TIME',
      fontFamily : 'verdana',
      fontSize : 14,
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      position: {x: 2, y: 5},
      color: 'gray',
      parent : header
    })

    this.timeLabel = new UIText({
      text : '',
      fontFamily : 'verdana',
      fontSize : 28,
      fontWeight: 'bold',
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      position: {x: 0, y: 22},
      color: 'white',
      parent : header
    })
  }

  public onWake() {
    super.onWake();
    this.mouseEvents = true;
    this.restart();
  }

  public update() {
    super.update();

    if(!this.gameOver)
      this.time += this.deltaTime;

    this.timeLabel.text = getTimeString(this.time);

    this.drawGame();
    
    this.resetButton.position.y = this.boardPos.y + this.boardWidth + 20;
    this.resetButton.visible = !this.gameOver;

    this.showMistakesButton.position.y = this.resetButton.position.y + 60;
    this.showMistakesButton.visible = !this.gameOver;
    this.showMistakesButton.text = `Show Mistakes (${this.showMistakesLeft})`;
  }

  protected restart() {
    this.time = 0;
    this.gameOver = false;
    this.draggingTile = false;
    this.dragTile = -1;
    this.showMistakes = false;
    this.showMistakesLeft = 2;

    this.board = {
      tiles: [
        [0, 0, 0, 1, 0, 0, 1],
        [1, 0, 0, 0, 0, 0, 0],
        [1, 0, 0, 1, 1, 1, 1],
        [1, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [1, 0, 0, 1, 0, 1, 1],
        [1, 0, 0, 0, 0, 0, 0],
      ],
      rows: [2, 1, 5, 1, 0, 4, 1],
      columns: [5, 0, 0, 3, 1, 2, 3]
    }

    this.generateBoard();

    // for(let i = 0; i < 100000; i++) {
    //   this.generations = 0;
    //   this.generateBoard();
    //   if(this.generations > 3)
    //     console.log('generations', this.generations);
    // }

    this.resetTiles();
  }

  protected resetTiles() {
    this.shipsPlaced = [0, 0, 0, 0];
    this.tiles = [
      [-1, -1, -1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1, -1, -1]
    ];

    for(let i = 0; i < this.board.uncovered.length; i++) {
      let ux = this.board.uncovered[i].x;
      let uy = this.board.uncovered[i].y;
      this.tiles[uy][ux] = this.board.tiles[uy][ux];
    }
  }
  
  protected isUncoveredTile(x:number, y:number) {
    return this.board.uncovered.find((u:any)=>u.x == x && u.y == y);    
  }

  protected placeShip(tx:number, ty:number, size:number, vertical:boolean) {
    for(let i = 0; i < size; i++) {
      let ix = tx+(vertical ? 0 : i);
      let iy = ty+(vertical ? i : 0);
      this.tiles[iy][ix] = 1;
    }
  }

  protected placeRow(row:number) {
    let count = this.countPartsInRow(row);
    if(count != this.board.rows[row])
      return;

    for(let x = 0; x < 7; x++) 
      if(this.tiles[row][x] == -1)
        this.tiles[row][x] = 0;
  }

  protected placeColumn(column:number) {
    let count = this.countPartsInColumn(column);
    if(count != this.board.columns[column])
      return;

    for(let y = 0; y < 7; y++) 
      if(this.tiles[y][column] == -1)
        this.tiles[y][column] = 0;
  }

  protected generateBoard() {
    this.generations++;

    this.shipsPlaced = [0, 0, 0, 0];

    this.tiles = [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0]
    ];

    let sizes = [4, 3, 2, 2, 1, 1, 1];
    let ships = [];

    for(let s = 0; s < sizes.length; s++) {
      // console.log('size', sizes[s]);

      let locations = [];
      for(let y = 0; y < 7; y++) {
        for(let x = 0; x < 7; x++) {
          for(let v = 0; v < 2; v++) {
            if(this.doesShipFit(x, y, sizes[s], v == 0))
              locations.push({x, y, size: sizes[s], vertical: v == 0});
          }
        }
      }

      if(locations.length == 0) {
        // console.error('Failed to find a place for ship!')
        this.generateBoard();
        return;
      }

      // console.log(locations);

      let r = Math.floor(Math.random() * locations.length);
      let loc = locations[r];
      this.placeShip(loc.x, loc.y, sizes[s], loc.vertical);
      ships.push({x: loc.x, y: loc.y, size: sizes[s], vertical: loc.vertical})
    }

    let rows = [0, 0, 0, 0, 0, 0, 0];
    for(let y = 0; y < 7; y++) {
      rows[y] = 0;
      for(let x = 0; x < 7; x++) 
        if(this.tiles[y][x] == 1)
          rows[y]++;
    }

    let columns = [0, 0, 0, 0, 0, 0, 0];
    for(let x = 0; x < 7; x++) {
      columns[x] = 0;
      for(let y = 0; y < 7; y++) 
        if(this.tiles[y][x] == 1)
          columns[x]++;
    }

    let c = (Math.random() <= 0.50) ? 2 : 1;

    let uncovered = [];
    for(let i = 0; i < c; i++) {
      let r = Math.floor(Math.random() * ships.length);
      let ship = ships[r];
      ships.splice(r, 1);
      r = Math.floor(Math.random() * ship.size);
      let sx = ship.x + (ship.vertical ? 0 : r);
      let sy = ship.y + (ship.vertical ? r : 0);
      uncovered.push({x: sx, y: sy});
    }

    if(c < 3 && Math.random() > 0.75) {
      let waterLocations = [];
      for(let y = 0; y < 7; y++) 
        for(let x = 0; x < 7; x++) 
          if(this.tiles[y][x] == 0)
            waterLocations.push({x, y});
      let r = Math.floor(Math.random() * waterLocations.length);
      uncovered.push(waterLocations[r]);
    }

    this.board = {
      tiles: JSON.parse(JSON.stringify(this.tiles)),
      rows,
      columns,
      uncovered
    }

    // console.log(this.tiles);
  }

  protected countPartsInRow(y:number) {
    let count = 0;
    for(let x = 0; x < 7; x++)
      if(this.tiles[y][x] == 1)
        count++;
    return count;
  }

  protected countPartsInColumn(x:number) {
    let count = 0;
    for(let y = 0; y < 7; y++)
      if(this.tiles[y][x] == 1)
        count++;
    return count;
  }

  protected isRowFull(y:number) {
    for(let x = 0; x < 7; x++)
      if(this.tiles[y][x] == -1)
        return false;
    return true;
  }

  protected isColumnFull(x:number) {
    for(let y = 0; y < 7; y++)
      if(this.tiles[y][x] == -1)
        return false;
    return true;
  }

  protected getTile(x:number, y:number) {
    if(x >= 0 && x <= 6 && y >= 0 && y <=6) 
      return this.tiles[y][x];
    return -2;
  }

  protected isShipAt(tx:number, ty:number, size:number, vertical:boolean) {
    // console.log('isShipAt', tx, ty, size, vertical);

    // console.log('ship')
    for(let i = 0; i < size; i++) {
      let ix = tx + (vertical ? 0 : i);
      let iy = ty + (vertical ? i : 0);
      // console.log(ix, iy, this.getTile(ix, iy));
      if(this.getTile(ix, iy) != 1)
        return false;
    }

    // console.log('around')
    for(let i = 0; i < size; i++) {
      let ix = tx + (vertical ? 0 : i);
      let iy = ty + (vertical ? i : 0);

      let bx = ix - (vertical ? 1 : 0);
      let by = iy - (vertical ? 0 : 1);
      let bt = this.getTile(bx, by)
      // console.log(bx, by, bt);

      if(bt != -2 && bt != 0)
        return false;

      let ax = ix + (vertical ? 1 : 0);
      let ay = iy + (vertical ? 0 : 1);
      let at = this.getTile(ax, ay);
      // console.log(ax, ay, at);

      if(at != -2 && at != 0)
        return false;
    }

    // console.log('caps');
    let cx1 = tx - (vertical ? 0 : 1);
    let cy1 = ty - (vertical ? 1 : 0);
    let ct1 = this.getTile(cx1, cy1)
    // console.log(cx1, cy1, ct1);
    if(ct1 != -2 && ct1 != 0)
      return false;

    let cx2 = tx + (vertical ? 0 : size);
    let cy2 = ty + (vertical ? size : 0);
    let ct2 = this.getTile(cx2, cy2)
    // console.log(cx2, cy2, ct2);
    if(ct2 != -2 && ct2 != 0)
      return false;

    // console.log('TRUE')
    return true;
  }

  protected doesShipFit(tx:number, ty:number, size:number, vertical:boolean) {
    // console.log('ship')
    for(let i = 0; i < size; i++) {
      let ix = tx + (vertical ? 0 : i);
      let iy = ty + (vertical ? i : 0);
      // console.log(ix, iy, this.getTile(ix, iy));
      if(this.getTile(ix, iy) != 0)
        return false;
    }

        // console.log('around')
    for(let i = -1; i < size+1; i++) {
      let ix = tx + (vertical ? 0 : i);
      let iy = ty + (vertical ? i : 0);

      let bx = ix - (vertical ? 1 : 0);
      let by = iy - (vertical ? 0 : 1);
      let bt = this.getTile(bx, by)
      // console.log(bx, by, bt);

      if(bt != -2 && bt != 0)
        return false;

      let ax = ix + (vertical ? 1 : 0);
      let ay = iy + (vertical ? 0 : 1);
      let at = this.getTile(ax, ay);
      // console.log(ax, ay, at);

      if(at != -2 && at != 0)
        return false;
    }

    // console.log('caps');
    let cx1 = tx - (vertical ? 0 : 1);
    let cy1 = ty - (vertical ? 1 : 0);
    let ct1 = this.getTile(cx1, cy1)
    // console.log(cx1, cy1, ct1);
    if(ct1 != -2 && ct1 != 0)
      return false;

    let cx2 = tx + (vertical ? 0 : size);
    let cy2 = ty + (vertical ? size : 0);
    let ct2 = this.getTile(cx2, cy2)
    // console.log(cx2, cy2, ct2);
    if(ct2 != -2 && ct2 != 0)
      return false;

    // console.log('TRUE')
    return true;
  }

  protected checkGameOver() {
    if(this.gameOver)
      return;

    if(JSON.stringify(this.shipsPlaced) != JSON.stringify([3, 2, 1, 1]))
      return;

    for(let y = 0; y < 7; y++) {
      if(!this.isRowFull(y)) 
        return;
      if(this.countPartsInRow(y) != this.board.rows[y])
        return;
    }

    for(let x = 0; x < 7; x++) {
      if(!this.isColumnFull(x)) 
        return;
      if(this.countPartsInColumn(x) != this.board.columns[x])
        return;
    }

    this.gameOver = true;

    setTimeout(() => {
      this.showGameOver();
    }, 1500);
  }

  protected showGameOver() {
    let score = Math.floor((60*60*1000) - this.time);
    // score = Math.floor((60*60*1000) - (7.5*60*1000));
    Game.instance.showGameOver(score, score, (action:string)=>{
      if(action == 'restart')
        this.restart();
    });
  }

  protected updateShipsPlaced() {
    // search for single ships
    let counts = [0, 0, 0, 0];
    for(let y = 0; y < 7; y++) {
      for(let x = 0; x < 7; x++) {
        for(let i = 0; i < 4; i++) {
          if(this.isShipAt(x, y, i+1, false) || this.isShipAt(x, y, i+1, true))
            counts[i]++;
        }
      }
    }

    this.shipsPlaced = counts;
  }

  protected drawWaterTile(x:number, y:number) {
    let color = this.isUncoveredTile(x, y) ? 'lightskyblue' : 'lightblue';
    if(this.gameOver)
      color = 'lightgreen';
    else if(this.showMistakes && this.tiles[y][x] != -1 && this.tiles[y][x] != this.board.tiles[y][x])
      color = 'lightcoral';

    UIManager.ctx.fillStyle = color;

    UIManager.ctx.fillRect(
      this.boardPos.x + (this.tileWidth * (x + 1)), 
      this.boardPos.y + (this.tileWidth * (y + 1)), 
      this.tileWidth, 
      this.tileWidth
    );    
  }

  protected drawEmptyTile(x:number, y:number) {
    UIManager.ctx.fillStyle = 'black';
    UIManager.ctx.fillRect(
      this.boardPos.x + (this.tileWidth * (x + 1)), 
      this.boardPos.y + (this.tileWidth * (y + 1)), 
      this.tileWidth, 
      this.tileWidth
    );    
  }

  protected drawShip(sx:number, sy:number, parts:number, highlight:boolean) {
    let px = sx;
    let py = sy;
    let tw = 20;
    let color = highlight ? 'green' : 'black';

    for(let i = 0; i < parts; i++) {
      if(i == 0) {
        UIManager.drawFilledCircle(px+(tw/2), py+(tw/2), tw/2, color);
        if(parts > 1)
          UIManager.drawFilledRect(px+(tw/2), py, tw/2, tw, color);
      }
      else if(i == parts-1) {
        UIManager.drawFilledCircle(px+(tw/2), py+(tw/2), tw/2, color);
        UIManager.drawFilledRect(px, py, tw/2, tw, color);
      }
      else {
        UIManager.drawFilledRect(px, py, tw, tw, color);
      }

      px += tw;
    }

    px = sx;
    for(let i = 1; i < parts; i++) 
      UIManager.drawLine(px+(i*tw), py, px+(i*tw), py+tw, '#333333', 1);
  }

  protected drawTile(x:number, y:number) {
    let tile = this.tiles[y][x];

    if(tile == -1) {
      this.drawEmptyTile(x, y);
      return;
    }

    this.drawWaterTile(x, y);

    if(tile == 0)
      return;
    
    let bx = this.boardPos.x;
    let by = this.boardPos.y;
    let tw = this.tileWidth;

    let tiles = this.tiles;
    if(this.isUncoveredTile(x, y))
      tiles = this.board.tiles;
    
    let up = (y == 0) ? null : tiles[y-1][x];
    let down = (y == 6) ? null : tiles[y+1][x];
    let left = (x == 0) ? null : tiles[y][x-1];
    let right = (x == 6) ? null : tiles[y][x+1];
    let color = '#666666';

    let px = bx + (tw * (x + 1)) + 2;
    let py = by + (tw * (y + 1)) + 2;
    let pw = tw - 4;
    let pcx = px + (pw/2);
    let pcy = py + (pw/2);

    let type = '';
    if((up == 1 && down == 1) || (left == 1 && right == 1)) 
      type = 'square';
    else if((!up || up == 0) && (!down || down == 0) && (!left || left == 0) && (!right || right == 0))
      type = 'circle';
    else if(up == 1 && (!down || down == 0)) 
      type = 'bump-down';
    else if((!up || up == 0) && down == 1) 
      type = 'bump-up';
    else if((!left || left == 0) && right == 1) 
      type = 'bump-left';
    else if(left == 1 && (!right || right == 0)) 
      type = 'bump-right';

    if(type == 'bump-down') {
      UIManager.drawFilledRect(px, py, pw, pw/2, color);
      UIManager.drawFilledCircle(pcx, pcy, pw/2, color);
    }
    else if(type == 'bump-up') {
      UIManager.drawFilledRect(px, py+(pw/2), pw, pw/2, color);
      UIManager.drawFilledCircle(pcx, pcy, pw/2, color);
    }
    else if(type == 'bump-right') {
      UIManager.drawFilledRect(px, py, pw/2, pw, color);
      UIManager.drawFilledCircle(pcx, pcy, pw/2, color);
    }
    else if(type == 'bump-left') {
      UIManager.drawFilledRect(px+(pw/2), py, pw/2, pw, color);
      UIManager.drawFilledCircle(pcx, pcy, pw/2, color);
    }
    else if(type == 'square') {
      UIManager.drawFilledRect(px, py, pw, pw, color);
    }
    else if(type == 'circle') {
      UIManager.drawFilledCircle(pcx, pcy, pw/2, color);
    }
    else
      UIManager.drawPanel(px, py, pw, pw, color, 14);
  }

  protected drawGame() {
    let cx = (this.width/2);
    let bw = Math.min(400, this.width-20);
    let bx = cx-(bw/2) - 10;
    let by = UIManager.isMobile ? 140 : 150;
    let tw = bw/8;

    this.boardWidth = bw;
    this.boardPos = {x: bx, y: by};
    this.tileWidth = tw;

    UIManager.ctx.fillStyle = '#333333';
    UIManager.ctx.fillRect(bx, by, bw, bw);   
    
    // ships
    let sx = cx-(90);
    let sy = by - 60;
    this.drawShip(sx+15, sy, 4, this.shipsPlaced[3] == 1);
    this.drawShip(sx+105, sy, 3, this.shipsPlaced[2] == 1);
    this.drawShip(sx, sy+30, 2, this.shipsPlaced[1] >= 1);
    this.drawShip(sx+50, sy+30, 2, this.shipsPlaced[1] >= 2);
    this.drawShip(sx+100, sy+30, 1, this.shipsPlaced[0] >= 1);
    this.drawShip(sx+130, sy+30, 1, this.shipsPlaced[0] >= 2);
    this.drawShip(sx+160, sy+30, 1, this.shipsPlaced[0] >= 3);

    // tiles
    for(let y = 0; y < 7; y++) 
      for(let x = 0; x < 7; x++) 
        this.drawTile(x, y);

    // grid lines
    for(let i = 0; i < 9; i++) {
      let lx = bx + (tw*i);
      let ly = by + (tw*i);
      UIManager.drawLine(bx+tw, ly, bx+bw, ly, '#333333', 1);
      UIManager.drawLine(lx, by+tw, lx, by+bw, '#333333', 1);
    }

    // row and column labels
    for(let i = 0; i < 7; i++) {
      let rowCount = this.countPartsInRow(i);
      let rowColor = 'transparent';
      if(this.isRowFull(i)) 
        rowColor = (rowCount == this.board.rows[i]) ? 'green' : 'red';

      UIManager.drawRect(bx+(tw/4), by+(tw*(i+1))+(tw/4), tw/2, tw/2, rowColor, 1);
      UIManager.drawText(this.board.rows[i], bx+(tw/2), by+(tw*(i+1))+(tw/2)+1, 'verdana', 16, 'center', 'white');
      
      let colCount = this.countPartsInColumn(i);
      let colColor = 'transparent';
      if(this.isColumnFull(i)) 
        colColor = (colCount == this.board.columns[i]) ? 'green' : 'red';
      
      UIManager.drawRect(bx+(tw*(i+1))+(tw/4), by+(tw/4), tw/2, tw/2, colColor, 1);
      UIManager.drawText(this.board.columns[i], bx+(tw*(i+1))+(tw/2), by+(tw/2)+1, 'verdana', 16, 'center', 'white');
    }

    if(this.gameOver) {
      UIManager.drawPanel(cx-75, by+bw+25, 150, 30, 'green', 10);
      UIManager.drawText('SOLVED!', cx, by+bw+40+2, 'verdana', 20, 'center', 'white');
    }
  }

  public onMouseDown(x:number, y:number) {
    if(this.gameOver)
      return;

    let bx = Math.floor((x - this.boardPos.x) / this.tileWidth) - 1;
    let by = Math.floor((y - this.boardPos.y) / this.tileWidth) - 1;

    if(bx == -1) {
      this.placeRow(by);
      return;
    }

    if(by == -1) {
      this.placeColumn(bx);
      return;
    }

    if(bx < 0 || bx > 6 || by < 0 || by > 6)
      return;

    let tile = this.tiles[by][bx];

    if(!this.isUncoveredTile(bx, by)) {
      tile++;
      if(tile > 1)
        tile = -1;
      
      this.tiles[by][bx] = tile;

      this.updateShipsPlaced();
    }

    this.draggingTile = true;
    this.dragTile = tile;
    this.showMistakes = false;
  }

  public onMouseMove(x:number, y:number) {
    if(!this.draggingTile || this.gameOver)
      return;

    let bx = Math.floor((x - this.boardPos.x) / this.tileWidth) - 1;
    let by = Math.floor((y - this.boardPos.y) / this.tileWidth) - 1;

    if(bx < 0 || bx > 6 || by < 0 || by > 6)
      return;

    if(this.tiles[by][bx] != this.dragTile && !this.isUncoveredTile(bx, by)) {
      this.tiles[by][bx] = this.dragTile;
      this.updateShipsPlaced();
    }
  }

  public onMouseUp(x:number, y:number) {
    this.draggingTile = false;
    this.checkGameOver();
  }

  protected onReset() {
    this.resetTiles();
  }

  protected onShowMistakes() {
    let placed = 0;
    for(let y = 0; y < 7; y++) {
      for(let x = 0; x < 7; x++) {
        if(this.tiles[y][x] != -1 && !this.isUncoveredTile(x, y)) {
          placed++;
        }
      }
    }

    if(placed == 0) {
      Game.instance.showAlert('No mistakes found!');
      return;
    }

    if(this.showMistakesLeft == 0) {
      Game.instance.showAlert('You have checked for mistakes\nenough this game.');
      return;
    }

    this.showMistakesLeft--;

    let mistakes = 0;
    for(let y = 0; y < 7; y++) 
      for(let x = 0; x < 7; x++) 
        if(this.tiles[y][x] != -1 && this.tiles[y][x] != this.board.tiles[y][x])
          mistakes++;

    if(mistakes == 0) {
      Game.instance.showAlert('No mistakes found!');
      return;
    }

    this.showMistakes = true;
  }

  protected onPause() {
    Game.instance.showPauseDialog((action:string)=>{
      if(action == 'restart') 
        this.restart();
      else if(action == 'options') {
        // what
      }
    });
  }
}