import { UIManager } from "./uiManager";
import { UIElement } from "./uiElement";
import { UIElementOptions } from "./uiElement";

export interface UIPanelOptions extends UIElementOptions {
  color?: string;
  radius?: number;
  borderWidth?: number;
  borderColor?: string;
  gradient?: {
    startColor: string,
    endColor: string,
    type: string,
    radius?: number
  };
  shadow?: {
    color?: string,
    blur?: number,
    offset?: {
      x?: number,
      y?: number
    }
  }
}

export class UIPanel extends UIElement {
  public color: string;
  public radius: number;
  public borderWidth: number;
  public borderColor: string;
  public gradient: any;
  public shadow: any;

  constructor(options: UIPanelOptions) {
    super(options);

    this.color = '#00000000';
    this.radius = 0;
    this.borderWidth = 0;
    this.borderColor = '#00000000';
    this.gradient = null;
    this.shadow = null;

    if(options.color)
      this.color = options.color;

    if(options.radius)
      this.radius = options.radius;

    if(options.borderWidth)
      this.borderWidth = options.borderWidth;

    if(options.borderColor)
      this.borderColor = options.borderColor;

    if(options.gradient)
      this.gradient = options.gradient;

    if(options.shadow)
      this.shadow = options.shadow;
  }

  public draw() {
    var sx: number = this.getScreenX();
    var sy: number = this.getScreenY();

    var path = new Path2D();
    path.moveTo(sx + this.radius, sy);
    path.lineTo(sx + this.width - this.radius, sy);
    path.quadraticCurveTo(sx + this.width, sy, sx + this.width, sy + this.radius);
    path.lineTo(sx + this.width, sy + this.height - this.radius);
    path.quadraticCurveTo(sx + this.width, sy + this.height, sx + this.width - this.radius, sy + this.height);
    path.lineTo(sx + this.radius, sy + this.height);
    path.quadraticCurveTo(sx, sy + this.height, sx, sy + this.height - this.radius);
    path.lineTo(sx, sy + this.radius);
    path.quadraticCurveTo(sx, sy, sx + this.radius, sy);
    path.closePath();

    UIManager.ctx.fillStyle = this.color;

    if(this.gradient) {
      if(this.gradient.type == 'radial') {
        let cx = this.getScreenX() + (this.width/2);
        let cy = this.getScreenY() + (this.height/2);
        let radius = this.width * this.gradient.radius;
        let gradient = UIManager.ctx.createRadialGradient(cx, cy, 1, cx, cy, radius);
        gradient.addColorStop(0, this.gradient.startColor);
        gradient.addColorStop(1, this.gradient.endColor);
        UIManager.ctx.fillStyle = gradient;
      }
      else if(this.gradient.type == 'horizontal') {
        let gradient = UIManager.ctx.createLinearGradient(this.getScreenX(), this.getScreenY(), this.getScreenX() + this.width, this.getScreenY());
        gradient.addColorStop(0, this.gradient.startColor);
        gradient.addColorStop(1, this.gradient.endColor);
        UIManager.ctx.fillStyle = gradient;
      }
      else if(this.gradient.type == 'vertical') {
        let gradient = UIManager.ctx.createLinearGradient(this.getScreenX(), this.getScreenY(), this.getScreenX(), this.getScreenY() + this.height);
        gradient.addColorStop(0, this.gradient.startColor);
        gradient.addColorStop(1, this.gradient.endColor);
        UIManager.ctx.fillStyle = gradient;
      }
    }

    if(this.shadow) {
      UIManager.ctx.save();
      UIManager.ctx.shadowColor = this.shadow.color;
      UIManager.ctx.shadowBlur = this.shadow.blur;
      UIManager.ctx.shadowOffsetX = this.shadow.offset.x;
      UIManager.ctx.shadowOffsetY = this.shadow.offset.y;
    }

    UIManager.ctx.fill(path);

    if(this.shadow)
      UIManager.ctx.restore();

    if(this.borderWidth > 0) {
      UIManager.ctx.lineWidth = this.borderWidth;
      UIManager.ctx.strokeStyle = this.borderColor;
      UIManager.ctx.stroke(path);
    }
  }

  public drawClipPath() {
    let sx = this.getScreenX();
    let sy = this.getScreenY();
    let sw = this.width;
    let sh = this.height;

    UIManager.ctx.beginPath();
    UIManager.ctx.moveTo(sx + this.radius, sy);
    UIManager.ctx.lineTo(sx + sw - this.radius, sy);
    UIManager.ctx.quadraticCurveTo(sx + sw, sy, sx + sw, sy + this.radius);
    UIManager.ctx.lineTo(sx + sw, sy + sh - this.radius);
    UIManager.ctx.quadraticCurveTo(sx + sw, sy + sh, sx + sw - this.radius, sy + sh);
    UIManager.ctx.lineTo(sx + this.radius, sy + sh);
    UIManager.ctx.quadraticCurveTo(sx, sy + sh, sx, sy + sh - this.radius);
    UIManager.ctx.lineTo(sx, sy + this.radius);
    UIManager.ctx.quadraticCurveTo(sx, sy, sx + this.radius, sy);
    UIManager.ctx.closePath();
  }
}