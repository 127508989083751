import { UIScreen } from '../common/ui/uiScreen';
import { UIPanel } from '../common/ui/uiPanel';
import { GameStyle } from './gameStyle';
import { Game } from './game';

export class SplashScreen extends UIScreen {
  protected fontsLoaded: boolean;

  public build() {
    let background = new UIPanel({
      color : GameStyle.backgroundColors.splash,
      fitParent : {left : 0, top : 0, right : 0, bottom : 0},
      parent : this
    });
  }

  public onWake() {
    let webFont = require('webfontloader');

    this.fontsLoaded = false;
    webFont.load({
      google: {families: ['Roboto']},
      active: ()=>{
        this.fontsLoaded = true;
      },
      inactive: ()=>{
        this.fontsLoaded = true;
      }
    });

    Game.instance.cardsManager.loadCardArt();

    this.checkLoadingState();
  }

  protected checkLoadingState() {
    let ready = Game.instance.cardsManager.isReadyToGo() && this.fontsLoaded;

    if(ready) {
      Game.instance.gameScreen.show();
      Game.instance.callback('loaded');
      return;
    }

    setTimeout(() => {
      this.checkLoadingState();
    }, 100);
  }
}