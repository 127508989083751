import { getAssetImage } from '../../app/util/assets';
import { Database } from '../../app/util/database';
import { Server } from '../../server/server';
import { UIDialog } from '../common/ui/uiDialog';
import { UIPanel } from '../common/ui/uiPanel';
import { UIButton } from './ui/uiButton';
import { UIImage } from './ui/uiImage';
import { UIPanelButton } from './ui/uiPanelButton';
import { UIText } from './ui/uiText';
import { getTimeString, numberWithCommas } from './util';

export class RewardDialog extends UIDialog {
  chanceToWinText: UIText;
  boosterImages: UIImage[] = [];
  boosterText: UIText;
  improveChancesText: UIText;
  reward: any;
  boostersPanel: UIPanel;

  public setReward(reward:any) {
    this.reward = reward;
  }

  public build() {
    this.popup = new UIPanel({
      size: {x: 340, y: 430},
      anchor : {x : 0.5, y : 0},
      pivot : {x : 0.5, y : 0},
      position: {x: 0, y: 80},
      radius: this.options.panel.radius,
      color: this.options.panel.color,
      gradient: this.options.panel.gradient,
      borderColor: this.options.panel.borderColor,
      borderWidth: this.options.panel.borderWidth,
      shadow: this.options.panel.shadow,
      parent: this
    });

    let titleText = new UIText({
      text: 'Chance To Win',
      position: {x: 0, y: 12},
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      horizontalAlign: 'center',
      color: this.options.title.color,
      fontFamily: this.options.title.fontFamily,
      fontSize: this.options.title.fontSize,
      fontWeight: this.options.title.fontWeight,
      // shadow: this.options.title.shadow,
      parent: this.popup
    });

    let backButton = new UIPanelButton({
      panelColors: {normal: this.options.subtext.color},
      textColors: {normal: this.options.panel.color},
      radius: 13,
      fontFamily: 'arial',
      fontSize: 24,
      text: '<',
      textOffset: {x: -1, y: -2},
      callback : () => {
        this.hide();
      },
      position: {x: 15, y: 16},
      size : {x : 26, y : 26},
      parent : this.popup
    });

    this.chanceToWinText = new UIText({
      text: '',
      fitParent: {left: 15, right: 15, top: -1, bottom: -1},
      position: {x: 0, y: 45},
      wordWrap: true,
      autoWidth: false,
      autoHeight: false,
      color : this.options.subtext.color,
      fontFamily : this.options.message.fontFamily,
      fontSize : this.options.message.fontSize,
      horizontalAlign: 'center',
      // verticalAlign: 'center',
      parent: this.popup
    });

    let improveChancePanel = new UIPanel({
      fitParent: {left: 15, right: 15, top: -1, bottom: -1},
      position : {x : 0, y : 100},
      size : {x : 0, y : 75},
      color : this.options.field.panel.color,
      radius : this.options.field.panel.radius,
      parent : this.popup
    });
  
    this.improveChancesText = new UIText({
      text: '',
      fitParent: {left: 10, right: 10, top: 0, bottom: 0},
      wordWrap: true,
      autoWidth: false,
      autoHeight: false,
      color : this.options.message.color,
      fontFamily : this.options.message.fontFamily,
      fontSize : this.options.message.fontSize-2,
      horizontalAlign: 'center',
      verticalAlign: 'center',
      parent: improveChancePanel
    });

    let boostersLabel = new UIText({
      text: 'Reward Boosters',
      position: {x: 0, y: 185},
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      color: this.options.title.color,
      fontFamily: this.options.title.fontFamily,
      fontSize: this.options.title.fontSize,
      fontWeight: this.options.title.fontWeight,
      // shadow: this.options.title.shadow,
      parent: this.popup
    });

    this.boosterText = new UIText({
      text: '',
      fitParent: {left: 20, right: 20, top: -1, bottom: -1},
      // position: {x: 0, y: 325},
      position: {x: 0, y: 217},
      wordWrap: true,
      autoWidth: false,
      autoHeight: false,
      color : this.options.subtext.color,
      fontFamily : this.options.message.fontFamily,
      fontSize : this.options.message.fontSize,
      horizontalAlign: 'center',
      // verticalAlign: 'center',
      parent: this.popup
    });

    this.boostersPanel = new UIPanel({
      fitParent: {left: 15, right: 15, top: -1, bottom: -1},
      position : {x : 0, y : 295},
      size : {x : 0, y : 120},
      color : this.options.field.panel.color,
      radius : this.options.field.panel.radius,
      parent : this.popup
    });

    this.buildBooster(10);
    this.buildBooster(85);
    this.buildBooster(160);
    this.buildBooster(235);

  }

  public buildBooster(sx:number) {
    let image = new UIImage ({
      // position: {x: sx, y: 215},
      position: {x: sx, y: 10},
      size: {x: 65, y: 65},
      stretch: true,
      radius: 10,
      parent: this.boostersPanel
    });

    let rarity = new UIText({
      text: 'LEGENDARY',
      position: {x: 0, y: 2},
      anchor: {x: 0.5, y: 1},
      pivot: {x: 0.5, y: 0},
      color : this.options.message.color,
      fontFamily : this.options.message.fontFamily,
      fontSize : this.options.message.fontSize-6,
      parent: image
    });

    let label = new UIText({
      text: '+150%',
      position: {x: 0, y: 16},
      anchor: {x: 0.5, y: 1},
      pivot: {x: 0.5, y: 0},
      color : this.options.message.color,
      fontFamily : this.options.message.fontFamily,
      fontSize : this.options.message.fontSize,
      parent: image
    });

    this.boosterImages.push(image);
  }

  protected getCollectionBonus() {
    let collections = Database.getCollections();
    let cids = Object.keys(collections);

    let bonus = {
      total: 0,
      boosters: []
    }

    for(let i = 0; i < cids.length; i++) {
      let collection = collections[cids[i]];
  
      if(!collection.bonuses)
        continue;
  
      let collectionBonus = 0;
      let bestBoosterId = collection.bonuses[0].id;

      for(let j = collection.bonuses.length-1; j >= 0; j--) {
        let existing = Server.assets.getInventoryAsset(collection.bonuses[j].id);
        if(existing) {
          collectionBonus = collection.bonuses[j].bonus;
          bestBoosterId = collection.bonuses[j].id;
          break;
        }
      }
  
      bonus.total += collectionBonus;
      bonus.boosters.push({id: bestBoosterId, bonus: collectionBonus});
    }

    return bonus;
  }

  public onWake() {
    super.onWake();

    let rewardType = Database.getRewardType(this.reward.game);

    if(this.reward.chances.win == 0) {
      let chance = Database.getBaseRewardChance();
      let score = Database.getMinRewardScore(this.reward.game);
      if(rewardType == 'level') {
        this.chanceToWinText.text = `You did not get past level ${score-1} and therefore did not win a reward.`;
        this.improveChancesText.text = `Reach level ${score} for a ${chance}% chance to win.`;
      }
      else if(rewardType == 'time') {
        let time = (60*60*1000) - this.reward.score;
        this.chanceToWinText.text = `A time of ${getTimeString(time)} is too low\nfor a reward.`;
        time = (60*60*1000) - score;
        this.improveChancesText.text = `Finish in ${getTimeString(time)} for a ${chance}% chance to win.`;
      }
      else {
        this.chanceToWinText.text = `A score of ${numberWithCommas(this.reward.score)} is too low\nfor a reward.`;
        this.improveChancesText.text = `Score at least ${numberWithCommas(score)} points for a ${chance}% chance to win a reward.`;
      }
    }
    else if(rewardType == 'level') {
      let level = Database.getMaxRewardScore(this.reward.game);
      this.chanceToWinText.text = `Reaching level ${this.reward.score} gave you a ${this.reward.chances.win}% chance to win a reward.`;
      
      this.improveChancesText.text = '';
      if(this.reward.chances.win < 100)
        this.improveChancesText.text = `Reaching a higher level will improve your chances.  `;
      this.improveChancesText.text += `A reward is guaranteed at level ${level} and higher.`;
    }
    else if(rewardType == 'time') {
      let time = (60*60*1000) - Database.getMaxRewardScore(this.reward.game);
      this.chanceToWinText.text = `Finishing with a time of ${getTimeString(time)} gave you a ${this.reward.chances.win}% chance to win a reward.`;
      
      this.improveChancesText.text = '';
      if(this.reward.chances.win < 100)
        this.improveChancesText.text = `Finishing with a better time will improve your chances.  `;
      this.improveChancesText.text += `A reward is guaranteed with a time of ${getTimeString(time)} or less.`;
    }
    else {
      let score = Database.getMaxRewardScore(this.reward.game);
      this.chanceToWinText.text = `A score of ${numberWithCommas(this.reward.score)} gave you a ${this.reward.chances.win}% chance to win a reward.`;
      
      this.improveChancesText.text = '';
      if(this.reward.chances.win < 100)
        this.improveChancesText.text = `A higher score will improve your chances.  `;
      this.improveChancesText.text += `A reward is guaranteed at ${numberWithCommas(score)} points or more.`;
    }

    let bonus = this.getCollectionBonus();

    let tier = Math.floor(bonus.total / 100);
    let tierChance = bonus.total - (tier * 100);

    if(tierChance == 0 && tier > 0) {
      tier--;
      tierChance = 100;
    }

    if(tier > 1) {
      tier = 1;
      tierChance = 100;
    }

    if(tier == 0 && tierChance == 0)
      this.boosterText.text = `Create boosters in your inventory to improve the chances of getting higher tier rewards.`;
    else
      this.boosterText.text = `When you win, your boosters give the reward a ${tierChance}% chance of coming\nfrom Tier ${tier+2}.`;

    let rarityNames = [
      'Common',
      'Uncommon',
      'Rare',
      'Epic',
      'Legendary',
      'Celestial'
    ]

    for(let i = 0; i < bonus.boosters.length; i++) {
      let asset = Database.getAsset(bonus.boosters[i].id);
      this.boosterImages[i].url = getAssetImage(asset);  
      this.boosterImages[i].setAlpha((bonus.boosters[i].bonus == 0) ? 0.5 : 1.0);
      let rarityText = this.boosterImages[i].getChild(0) as UIText;
      rarityText.text = (bonus.boosters[i].bonus == 0) ? 'UNOWNED' : rarityNames[asset.rarity];
      let bonusText = this.boosterImages[i].getChild(1) as UIText;
      bonusText.text = (bonus.boosters[i].bonus == 0) ? '' : `+${bonus.boosters[i].bonus}%`
    }
  }
}