import React from 'react';

interface CoinIconProps {
  quantity: number;
}

class CoinIcon extends React.Component<CoinIconProps, {}> {
  constructor(props:CoinIconProps) {
    super(props);
  }

  render() {
    return (
      <span style={{color: 'var(--panel-color)', whiteSpace: 'nowrap'}}>{this.props.quantity} <img style={{width:'20px', transform: 'translateY(3px)'}} src="/icon/coin.png"/></span>
    )
  }
}

export default CoinIcon;