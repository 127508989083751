import React from 'react';
import PageEnvelope from '../elements/PageEnvelope';
import { Server } from '../../server/server';
import LoginModal from '../modals/LoginModal';
import { Navigate } from 'react-router-dom';
import AlertModal from '../modals/AlertModal';
import './HomePage.css';

interface InvitePageState {
  loading: boolean;
  valid: boolean;
  signup: boolean;
  alert: string
}

class InvitePage extends React.Component<{}, InvitePageState> {
  constructor(props:{}) {
    super(props);
    this.state = {
      loading: true,
      valid: false,
      signup: false,
      alert: ''
    }
  }

  componentDidMount(): void {
    this.checkInvite();
  }

  async checkInvite() {
    let id = window.location.search.substring(1);
    let response = await Server.public.checkInvite(id);
    this.setState({loading: false})
    if(response.success)
      this.setState({valid: true});
  }

  onCreateAccount() {
    if(this.state.valid)
      this.setState({signup: true})
    else if(this.state.loading)
      this.setState({alert: 'Please try again.'})
    else
      this.setState({alert: 'This invite has expired.'});
  }

  async onCreateAccountFinished() {
    let id = window.location.search.substring(1);
    if(Server.account.isLoggedIn())
      Server.user.useInvite(id);
    this.setState({signup: false})    
  }

  render() {
    if(Server.account.isLoggedIn())
      return <Navigate to="/" />
      
    return (
      <div className="home-page">
        <PageEnvelope width="800px">
          <div className="home-page-guest">
            <div className="home-page-guest-text">
              <div style={{fontSize: '2em', lineHeight: '1.2em', fontWeight: 'bold'}}>
                You have been<br/>invited to join the<br/><span style={{color: '#17B9FF'}}>Private Beta!</span>
              </div>
              <div className="site-page-row" style={{justifyContent: 'center'}}>
                <div style={{fontSize: '0.9em', fontWeight: 'thin', padding: '5px', maxWidth: '350px'}}>
                  Your feedback is very important and we appreciate your time helping us improve the platform and games!
                </div>
              </div>
              <div style={{paddingLeft: '6px'}}>
                <button style={{borderRadius: '5px'}} onClick={()=>this.onCreateAccount()}>Create Account</button>
              </div>
            </div>
            <div style={{backgroundColor: 'transparent', textAlign: 'center'}}>
              <img src="/phones.png" style={{width: '100%', maxWidth: '450px', backgroundColor:'transparent'}} />
            </div>
          </div>
        </PageEnvelope>
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
        <LoginModal mode={this.state.signup ? 'signup' : ''} onClose={()=>this.onCreateAccountFinished()}/>
      </div>
    );
  }
}

export default InvitePage;

