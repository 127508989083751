import React from 'react';
import { Server } from '../../server/server';
import { Navigate } from 'react-router-dom';
import PageEnvelope from '../elements/PageEnvelope';

interface AdminToolPageState {
  loading: boolean;
  key: string;
}

class AdminToolsPage extends React.Component<{}, AdminToolPageState> {
  constructor(props:{}) {
    super(props);
    this.state = {
      loading: false,
      key: ''
    }
  }
  async onDeleteCookie(key:string) {
    this.setState({loading: true});
    await Server.user.deleteCookie({key});
    this.setState({loading: false});
  }

  async onResetAdventure() {
    this.setState({loading: true});
    await Server.user.deleteCookie({key: 'MatchaAdventureLevel'});
    await Server.user.deleteCookie({key: 'MatchaAdventureLastLevel'});
    await Server.user.deleteCookie({key: 'MatchaAdventureScores'});
    this.setState({loading: false});
  }

  render() {
    if(!Server.account.isLoggedIn() || !Server.account.isAdmin())
      return <Navigate to="/" replace={true} />

    return (
      <div className="site-page">
        <PageEnvelope width="300px">
          <div className="site-page-column">
            <select value={this.state.key} onChange={(e:any)=>this.setState({key: e.currentTarget.value})}>
              <option value="LastActivityRead">LastActivityRead</option>
              <option value="LastNotificationRead">LastNotificationRead</option>
              <option value="MatchaAdventureLastLevel">MatchaAdventureLastLevel</option>
              <option value="MatchaAdventureLevel">MatchaAdventureLevel</option>
              <option value="MatchaAdventureScores">MatchaAdventureScores</option>
              <option value="Welcome">Welcome</option>
              <option value="Welcome-whacka">Welcome-whacka</option>
            </select>
            <button disabled={this.state.loading} onClick={()=>this.onDeleteCookie(this.state.key)}>Delete Cookie</button>
          </div>
        </PageEnvelope>
      </div>
    )
  }
}

export default AdminToolsPage;