import { UIDialog } from "./ui/uiDialog";
import { UIGrid } from "./ui/uiGrid";
import { UIPanel } from "./ui/uiPanel";
import { UIPanelButton } from "./ui/uiPanelButton";
import { UIText } from "./ui/uiText";

export class QuestionDialog extends UIDialog {
  protected messageText:UIText;
  protected buttonGrid: UIGrid;
  protected panel: UIPanel;

  public build() {
    this.panel = new UIPanel({
      size: {x: 340, y: 130},
      anchor: {x: 0.5, y: 0.35},
      pivot: {x: 0.5, y: 0.5},
      position: {x: 0, y: 0},
      radius: this.options.panel.radius,
      color: this.options.panel.color,
      gradient: this.options.panel.gradient,
      borderColor: this.options.panel.borderColor,
      borderWidth: this.options.panel.borderWidth,
      shadow: this.options.panel.shadow,
      parent: this
    });

    this.messageText = new UIText({
      text: '',
      color: this.options.message.color,
      fontFamily: this.options.message.fontFamily,
      fontSize: this.options.message.fontSize,
      position: {x: 0, y: 20},
      anchor: {x: 0.5, y: 0},
      pivot: {x: 0.5, y: 0},
      horizontalAlign: 'center',
      parent: this.panel
    });

    let cols = 3;
    let spacing = 30;
    let height = 50;

    this.buttonGrid = new UIGrid({
      columns: 1,
      rows: 1,
      spacing: {x: 30, y: 0},
      size: {x: 120, y: 40},
      anchor : {x : 0.5, y : 1},
      pivot : {x : 0.5, y : 1},
      position : {x : 0, y : -20},
      parent : this.panel
    });
  }

  public setMessage(message:string) {
    this.messageText.text = message;

    let lines = message.split('\n');
    this.panel.size.y = 130 + ((lines.length - 1) * (this.options.message.fontSize * 1.2));
  }

  public setButtons(labels:string[]) {
    let buttonWidth = 100;

    this.buttonGrid.deleteAllChildren();
    this.buttonGrid.columns = labels.length;
    this.buttonGrid.size.x = (this.buttonGrid.columns * buttonWidth) + ((this.buttonGrid.columns-1) * this.buttonGrid.spacing.x);

    for(let i = 0; i < labels.length; i++) {
      let btn = new UIPanelButton({
        text : labels[i],
        panelColors: this.options.button.panelColors,
        panelGradients: this.options.button.panelGradients,
        textColors: this.options.button.textColors,
        fontFamily: this.options.button.fontFamily,
        fontSize: this.options.button.fontSize,
        borderColor: this.options.button.borderColor,
        borderWidth: this.options.button.borderWidth,
        callback : (btn:UIPanelButton) => {
          this.hide();
          if(this.callback)
            this.callback(btn.text);
        },
        parent : this.buttonGrid
      });
    }
  }
}
