// import { InviteFriendsDialogOptions } from 'src/common/inviteFriendsDialog';
// import { LeaderboardPanelOptions } from 'src/common/leaderboardPanel';
import { UICheckboxOptions } from '../common/ui/uiCheckbox';
import { UIDialogOptions } from '../common/ui/uiDialog';
import { UIImage } from '../common/ui/uiImage';
import { UIPanelButtonOptions } from '../common/ui/uiPanelButton';

export class GameStyle {
  public static fonts = {
    small: {
      family: 'roboto',
      size: 14,
      textOffset: {x: 0, y: 0}
    },
    normal: {
      family: 'roboto',
      size: 18,
      textOffset: {x: 0, y: 0}
    },
    large: {
      family: 'roboto',
      size: 22,
      textOffset: {x: 0, y: 0}
    }
  };

  public static backgroundColors = {
    splash: '#558c3b',
    game: {
      outer: '#558c3b',
      inner: '#9cd95f'
    }
  }

  public static header = {
    panel: {
      color: '#254037',
      radius: 10,
    },
    label: {
      fontFamily: GameStyle.fonts.small.family,
      fontSize: GameStyle.fonts.small.size,
      color: '#ffffff80'
    },
    value: {
      fontFamily: GameStyle.fonts.normal.family,
      fontSize: GameStyle.fonts.normal.size,
      color: '#ffffff'
    }
  }

  public static dialog: UIDialogOptions = {
    panel: {
      radius: 20,
      color: '#558c3b',
      borderColor: '#254037',
      borderWidth: 3,
      shadow: {
        color: '#00000080',
        blur: 6,
        offset: {x: 4, y: 4}
      }
    },
    title: {
      text: '',
      color: '#ffffff',
      fontFamily: GameStyle.fonts.large.family,
      fontSize: GameStyle.fonts.large.size,
      fontWeight: '700',
      shadow: {
        color: '#000000',
        blur: 2,
        offset: {x: 2, y: 2}
      }
    },
    message: {
      text: '',
      color: '#ffffff',
      fontFamily: GameStyle.fonts.normal.family,
      fontSize: GameStyle.fonts.normal.size
    },
    subtext: {
      text: '',
      color: '#ffffffa0',
      fontFamily: GameStyle.fonts.normal.family,
      fontSize: GameStyle.fonts.normal.size-2
    },
    button: {
      radius: 10,
      panelColors: {normal: '#eb521a', pressed: '#820303', disabled: '#820303'},
      textColors: {normal: '#ffffff'},
      textOffset: {x: 0, y: -1},
      borderColor: '#820303',
      borderWidth: 3,
      fontFamily: GameStyle.fonts.normal.family,
      fontSize: GameStyle.fonts.normal.size
    },
    scrollBar: {
      element: null,
      color : '#00000040',
      thumbColor : '#254037',
      radius : 5
    },
    field: {
      panel: {
        color: '#254037',
        radius: 10
      },
      input: {
        color: '#ffffff',
        fontFamily: GameStyle.fonts.normal.family,
        fontSize: GameStyle.fonts.normal.size
      }
    }
  };

  public static playWithFriends: UIPanelButtonOptions = {
    panelColors : {
      normal : '#3679a4',
      hover : '#3679a4',
      pressed : '#0f1925'
    },
    borderColor: '#0f1925',
    borderWidth: 3,
    textOffset: GameStyle.fonts.small.textOffset,
    fontFamily: GameStyle.fonts.small.family,
    fontSize: GameStyle.fonts.small.size,
    radius: 10,
  };

  // public static inviteFriendsDialog: InviteFriendsDialogOptions = {
  //   copyButton: {
  //     elements: {
  //       normal: new UIImage({
  //         url : require('./assets/button-copy.png')
  //       })
  //     }
  //   }
  // }

  public static checkbox: UICheckboxOptions = {
    checkmarkUrl : require('./assets/checkmark.png'),
    radius: 10,
    panelColors: {normal: '#eb521a', pressed: '#820303', disabled: '#820303'},
    textColors: {normal: '#ffffff'},
    borderColor: '#820303',
    borderWidth: 3,
    textOffset : {x : 30, y : 0},
    fontFamily : GameStyle.fonts.normal.family,
    fontSize : GameStyle.fonts.normal.size
  }

  // public static leaderboard:LeaderboardPanelOptions = {
  //   fontFamily: GameStyle.fonts.small.family,
  //   fontSize: GameStyle.fonts.small.size,
  //   nameColor: '#FFFFFF80',
  //   localNameColor: '#FFFF00',
  //   scoreColor: '#FFFFFF',
  //   panelColor: '#254037',
  //   panelRadius: 10
  // }
}