import { isLocalHost } from '../games/common/util';
import { Server } from './server';
import { Service, ServiceResponse } from './service';

export class UserService extends Service {
  protected user: any;
  protected notifications: any[];

  constructor() {
    super();
    this.user = null;
    this.notifications = [];
    this.onFriendConnect = this.onFriendConnect.bind(this);
    this.onFriendDisconnect = this.onFriendDisconnect.bind(this);
    this.onFriendPresence = this.onFriendPresence.bind(this);
    this.onFriendInvited = this.onFriendInvited.bind(this);
    this.onFriendAccepted = this.onFriendAccepted.bind(this);
    this.onFriendDeclined = this.onFriendDeclined.bind(this);
    this.onFriendRemoved = this.onFriendRemoved.bind(this);
    this.onFriendPortrait = this.onFriendPortrait.bind(this);
    this.onCloneSetCookie = this.onCloneSetCookie.bind(this);
  }

  public async init(): Promise<ServiceResponse> {
    Server.network.addEventListener('friend-connect', this.onFriendConnect);
    Server.network.addEventListener('friend-disconnect', this.onFriendDisconnect);
    Server.network.addEventListener('friend-presence', this.onFriendPresence);
    Server.network.addEventListener('friend-invited', this.onFriendInvited);
    Server.network.addEventListener('friend-accepted', this.onFriendAccepted);
    Server.network.addEventListener('friend-declined', this.onFriendDeclined);
    Server.network.addEventListener('friend-removed', this.onFriendRemoved);
    Server.network.addEventListener('friend-portrait', this.onFriendPortrait);
    Server.network.addEventListener('clone-set-cookie', this.onCloneSetCookie);
    return {success: true};
  }
  
  public async sync(): Promise<ServiceResponse> {
    let response = await this.sendCommand('user', 'get-user');

    if(response.status == 404) {
      await this.sendCommand('user', 'create-user');
      response = await this.sendCommand('user', 'get-user');
    }
    
    if(response.status != 200)
      return {success: false};

    this.user = response.body.user;

    let now = new Date();
    this.user.presence = {online: true, location: 'site', date: now.toISOString()};

    // cache profiles
    Server.public.addProfileToCache(this.user);

    let friendIds = [];
    for (let i = 0; i < this.user.friends.length; i++) 
      friendIds.push(this.user.friends[i].id);
    
    await Server.public.loadProfiles(friendIds);

    // friend presence
    for(let i = 0; i < this.user.friends.length; i++) 
      this.user.friends[i].presence = {online: false};

    this.updateFriendsOnline();

    // convert cookies
    let dailyStats = this.parseCookie('DailyStats');
    if(dailyStats) {
      let lastClaimDate = (dailyStats.dates && dailyStats.dates.claim) ? dailyStats.dates.claim : '';
      if(lastClaimDate) 
        await this.setCookie({key: 'DailyRewardClaim', value: lastClaimDate});
      await this.deleteCookie({key: 'DailyStats'})
      console.warn('Converted DailyStats to DailyRewardClaim')
    }

    // if(this.getCookie('DailyRewardClaim')) {
    //   await this.deleteCookie({key: 'DailyRewardClaim'});
    //   console.warn('Deleted DailyRewardClaim')
    // }

    return {success: true};
  }

  public clear() {
    this.user = null;
    this.notifications = [];
  }

  public async ping() {
    await this.sendCommand('user', 'ping');
  }

  public async getUser() {
    let response = await this.sendCommand('user', 'get-user');
    return response.body.user;
  }

  public async createUser() {
    await this.sendCommand('user', 'create-user');
  }

  // profile
  public getId(): string {
    if(!this.user)
      return '';
    return this.user.id;
  }

  public getSlug(): string {
    if(!this.user)
      return '';
    return this.user.slug;
  }

  public getName(): string {
    if(!this.user)
      return '';
    return this.user.name;
  }

  public getProfile():any {
    if(!this.user)
      return null;
      
    return {
      id: this.user.id,
      name: this.user.name,
      slug: this.user.slug,
      banner: this.user.banner,
      portrait: this.user.portrait,
      bio: this.user.bio
    }
  }

  public getPortrait(): string {
    if(!this.user || !this.user.portrait)
      return '';
    return this.user.portrait;
  }

  public async setProfile(profile: {slug?:string, name?:string, bio?:string, banner?:string, portrait?:string}) {
    let response = await this.sendCommand('user', 'set-profile', profile);
    
    if(response.status != 200)
      return {success: false, message: response.body.message};

    if(profile.slug)
      this.user.slug = profile.slug;

    if(profile.name)
      this.user.name = profile.name;

    if(profile.bio)
      this.user.bio = profile.bio;

    if(profile.banner)
      this.user.banner = profile.banner;

    if(profile.portrait)
      this.user.portrait = profile.portrait;

    this.notifyListeners('user-profile-updated');

    return {success: true};
  }

  // cookies
  public findCookie(key:string) {
    for(let i = 0; i < this.user.cookies.length; i++)
      if(this.user.cookies[i].key == key)
        return this.user.cookies[i];
    return null;
  }

  public async setCookie(params:{key:string, value:string}) {
    let cookie = this.findCookie(params.key);

    let originalValue = null;
    if(cookie) {
      originalValue = cookie.value;
      cookie.value = params.value;
    }
    else
      this.user.cookies.push({key: params.key, value: params.value});

    let response = await this.sendCommand('user', 'set-cookie', params);

    if(response.status == 200) {
      Server.network.sendEvent('clones', {id: 'clone-set-cookie', key: params.key, value: params.value})
    }
    else {
      if(cookie)
        cookie.value = originalValue;
      else
        this.user.cookies.splice(this.user.cookies.length-1, 1);
      
      return {success: false, message: response.body.message};
    }

    return {success: true};
  }

  public async deleteCookie(params:{user?:string, key:string}) {
    let response = await this.sendCommand('user', 'delete-cookie', params);
    if(response.status != 200)
      return {success: false, message: response.body.message};

    if(!params.user) {
      let cookie = this.findCookie(params.key);
      if(cookie) {
        let i = this.user.cookies.indexOf(cookie);
        this.user.cookies.splice(i, 1);
      }
    }

    return {success: true};
  }

  public getCookies(): any[] {
    return this.user.cookies;
  }

  public getCookie(key:string): string {
    if(!this.user)
      return null;

    for(let i = 0; i < this.user.cookies.length; i++)
      if(this.user.cookies[i].key == key)
        return this.user.cookies[i].value;
    
    return null;
  }

  public parseCookie(key:string): any {
    let s = this.getCookie(key);
    if(!s) return null;
    return JSON.parse(s);
  }

  public addCookieToCache(key:string, value:string) {
    if(!this.user) return;
    let existing = this.user.cookies.find((c)=>c.key == key);
    if(existing)
      existing.value = value;
    else
      this.user.cookies.push({key, value});
  }

  // lifetime stats
  public setLifetimeGameStats(game:string, stats:any) {
    let lifetimeStats = this.parseCookie('LifetimeStats');
    if(!lifetimeStats) 
      lifetimeStats = {};
    lifetimeStats[game] = stats;
    this.addCookieToCache('LifetimeStats', JSON.stringify(lifetimeStats));
  }

  // follows
  public async follow(id:string) {
    let response = await this.sendCommand('user', 'follow', {id});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    this.user.following.push(id);
    return {success: true};
  }

  public async unfollow(id:string) {
    let response = await this.sendCommand('user', 'unfollow', {id});
    if(response.status != 200)
      return {success: false, message: response.body.message};

    let i = this.user.following.indexOf(id);
    if(i != -1)
      this.user.following.splice(i, 1);

    return {success: true};
  }

  public isFollowing(id:string) {
    return this.user.following.indexOf(id) != -1;
  }

  protected getFollows(users:any[]) {
    let follows = [];

    for(let i = 0; i < this.user.friends.length; i++)
      follows.push(this.user.friends[i].id);

    for(let i = 0; i < users.length; i++)
      if(follows.indexOf(users[i]) == -1)
        follows.push(users[i]);
        
    return follows;
  }

  public getFollowing() {
    return this.getFollows(this.user.following);
  }

  public getFollowers() {
    return this.getFollows(this.user.followers);
  }

  // friends
  public async inviteFriend(slug:string) {
    let response = await this.sendCommand('user', 'invite-friend', {slug});
    if(response.status != 200)
      return {success: false, message: response.body.message};

    let now = new Date();
    let friendId = response.body.id;

    this.user.friends.push({
      id: friendId, 
      date: now.toISOString(), 
      state: 'pending'
    });

    Server.network.sendEvent('user:' + friendId, {id: 'friend-invited', user: Server.account.getUserId()});

    return {success: true};
  }

  public async removeFriend(id:string) {
    let response = await this.sendCommand('user', 'remove-friend', {id});
    if(response.status != 200)
      return {success: false, message: response.body.message};

    let friend = this.getFriend(id);

    if(friend) {
      let i = this.user.friends.indexOf(friend);
      if(i != -1)
        this.user.friends.splice(i, 1);

      if(friend.state != 'declined')
        Server.network.sendEvent('user:' + id, {id: 'friend-removed', user: Server.account.getUserId()});
    }
  
    return {success: true};
  }

  public async acceptFriend(id:string) {
    let response = await this.sendCommand('user', 'accept-friend', {id});
    if(response.status != 200)
      return {success: false, message: response.body.message};

    let friend = this.getFriend(id);
    if(friend)
      friend.state = 'accepted';

    Server.network.sendEvent('user:' + id, {id: 'friend-accepted', user: Server.account.getUserId()});

    return {success: true};
  }

  public async declineFriend(id:string) {
    let response = await this.sendCommand('user', 'decline-friend', {id});
    if(response.status != 200)
      return {success: false, message: response.body.message};

    let friend = this.getFriend(id);
    if(friend) {
      let i = this.user.friends.indexOf(friend);
      if(i != -1)
        this.user.friends.splice(i, 1);
    }
    
    Server.network.sendEvent('user:' + id, {id: 'friend-declined', user: Server.account.getUserId()});

    return {success: true};
  }

  public getFriends() {
    if(!this.user)
      return [];
    return this.user.friends;
  }

  public getFriendIds() {
    let ids = [];
    let friends = this.getFriends();
    for(let i = 0; i < friends.length; i++)
      ids.push(friends[i].id);
    return ids;
  }

  public isFriend(id:string):boolean {
    for(let i = 0; i < this.user.friends.length; i++) 
      if(this.user.friends[i].id == id)
        return (this.user.friends[i].state == 'accepted');
    return false;
  }

  public getFriend(id:string):any {
    for(let i = 0; i < this.user.friends.length; i++) 
      if(this.user.friends[i].id == id)
        return this.user.friends[i];
    return null;
  }

  public getFriendBySlug(slug:string):any {
    for(let i = 0; i < this.user.friends.length; i++) 
      if(this.user.friends[i].slug == slug)
        return this.user.friends[i];
    return null;
  }

  public async getFriendFollows(id:string) {
    let response = await this.sendCommand('user', 'get-friend-follows', {id});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true, following: response.body.following, followers: response.body.followers};
  }

  public async getFriendsOnline() {
    let response = await this.sendCommand('user', 'get-friends-online');
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true, friends: response.body.friends};
  }

  public async updateFriendsOnline() {
    let response = await this.getFriendsOnline();
  
    if(!response.success)
      return;

    for(let i = 0; i < response.friends.length; i++) {
      let onlineFriend = response.friends[i];
      let friend = this.getFriend(onlineFriend.id);
      if(!friend) continue;
      friend.presence = {
        date: onlineFriend.date,
        online: true
      }
      this.notifyListeners('friend-updated', {friend: friend.id, action: 'presence'})      
    }
  }

  protected friendsCookieCache:any[] = [];

  protected isCacheDateExpired(ds:string, seconds:number) {
    let now = new Date();
    let then = new Date(ds);
    let diff = now.getTime() - then.getTime();
    return (diff >= seconds * 1000);
  }

  public async getFriendsCookie(key:string) {
    let existing = this.friendsCookieCache.find((c)=>c.key == key);

    if(existing) {
      if(!this.isCacheDateExpired(existing.date, 10 * 60))
        return {success: true, cookies: existing.cookies};
      else {
        let i = this.friendsCookieCache.indexOf(existing);
        this.friendsCookieCache.splice(i, 1);
      }
    }

    let response = await this.sendCommand('user', 'get-friends-cookie', {key});
    
    if(response.status != 200)
      return {success: false, message: response.body.message};

    let date = new Date().toISOString();
    this.friendsCookieCache.push({date, key, cookies: response.body.cookies})

    return {success: true, cookies: response.body.cookies};
  }

  // notifications
  public async getNotifications(more:boolean=false) {
    let params:any = {};
    if(this.notifications.length > 0) {
      if(more)
        params.before = this.notifications[this.notifications.length-1].date;
      else
        params.after = this.notifications[0].date;
    }

    let response = await this.sendCommand('user', 'get-notifications', params);
    if(response.status != 200)
      return {success: false, message: response.body.message};

    let notifications = response.body.notifications;

    if(params.after) 
      this.notifications = notifications.concat(this.notifications);
    else if(params.before) 
      this.notifications = this.notifications.concat(notifications);
    else
      this.notifications = notifications;

    return {success: true, notifications: this.notifications};
  }

  public getLastNotificationRead():string {
    return this.getCookie('LastNotificationRead');
  }

  public setLastNotificationRead(date:string) {
    let lastRead = Server.user.getCookie('LastNotificationRead');
    if(lastRead != date) {
      let key = 'LastNotificationRead';
      let value = date;
      Server.user.setCookie({key, value});
    }
  }

  public getUnreadNotificationsCount():number {
    let lastReadCookie = this.getCookie('LastNotificationRead');
    let lastRead = lastReadCookie ? new Date(lastReadCookie) : null;
    
    let count = 0;
    for(let i = 0; i < this.notifications.length; i++) {
      let msgDate = new Date(this.notifications[i].date);
      let unread = msgDate > lastRead;
      if(unread)
        count++;
    }

    return count;
  }

  public getNotificationsFromCache() {
    return this.notifications;
  }

  // network event handlers
  protected onFriendConnect(event:any) {
    let friend = this.getFriend(event.user);
    if(!friend) return;

    friend.presence = {
      date: new Date().toISOString(),
      location: 'site',
      online: true
    };

    this.notifyFriendUpdated(event);
  }

  protected onFriendDisconnect(event:any) {
    let friend = this.getFriend(event.user);
    if(!friend) return;

    friend.presence = {online: false};
    
    this.notifyFriendUpdated(event);
  }

  protected onFriendPresence(event:any) {
    let friend = this.getFriend(event.user);
    if(!friend) return;

    friend.presence = {
      date: new Date().toISOString(),
      online: true
    };

    this.notifyFriendUpdated(event);
  }

  protected async onFriendInvited(event:any) {
    let friend = this.getFriend(event.user);

    if(!friend) {
      friend = {
        id: event.user,
        state: 'invited'
      };
      this.user.friends.push(friend);
    }
    else
      friend.state = 'invited';

    friend.presence = {
      online: true,
      location: 'site',
      date: new Date().toISOString()
    }

    await Server.public.loadProfiles([event.user]);

    this.notifyFriendUpdated(event);
  }

  protected onFriendAccepted(event:any) {
    let friend = this.getFriend(event.user);
    if(!friend)
      return;

    friend.state = 'accepted';

    friend.presence = {
      online: true,
      location: 'site',
      date: new Date().toISOString()
    }

    this.notifyFriendUpdated(event);
  }

  protected onFriendDeclined(event:any) {
    let friend = this.getFriend(event.user);
    if(!friend)
      return;

    friend.state = 'declined';

    friend.presence = {
      online: false
    }

    this.notifyFriendUpdated(event);
  }

  protected onFriendRemoved(event:any) {
    for(let i = 0; i < this.user.friends.length; i++) {
      if(this.user.friends[i].id == event.user) {
        this.user.friends.splice(i, 1);
        this.notifyFriendUpdated(event);
        return;
      }
    }
  }

  protected onFriendPortrait(event:any) {
    for(let i = 0; i < this.user.friends.length; i++) {
      if(this.user.friends[i].id == event.user) {
        let profile = Server.public.getProfile(this.user.friends[i].id);
        profile.portrait = event.portrait;
        Server.public.addProfileToCache(profile);
        this.notifyFriendUpdated(event);
        return;
      }
    }
  }

  protected notifyFriendUpdated(event:any) {
    this.notifyListeners('friend-updated', {friend: event.user, action: event.id.substring(7)});
  }

  // clones
  protected onCloneSetCookie(event:any) {
    let cookie = this.findCookie(event.key);

    if(cookie) 
      cookie.value = event.value;
    else
      this.user.cookies.push({key: event.key, value: event.value});
  } 

  // cache
  public setCachedPortrait(id:string) {
    this.user.portrait = id;
    this.notifyListeners('user-profile-updated');
  }

  public setCachedBanner(id:string) {
    this.user.banner = id;
  }

  // invites
  public async getInvite() {
    let response = await this.sendCommand('user', 'get-invite');
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true, invite: response.body.invite};
  }

  public async useInvite(id:string) {
    let response = await this.sendCommand('user', 'use-invite', {id});
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true};
  }

  // counters
  public async incrementCounter(id:string, amount?:number) {
    if(isLocalHost())
      return;
    
    let params:any = {id};
    if(amount)
      params.amount = amount;

    let response = await this.sendCommand('user', 'increment-counter', params);

    if(response.status != 200)
      return {success: false, message: response.body.message};
    
    return {success: true};
  }
  
  public async getCounters() {
    let response = await this.sendCommand('user', 'get-counters');
    if(response.status != 200)
      return {success: false, message: response.body.message};
    return {success: true, counters: response.body.counters};
  }
}
