import React from 'react';
import { BsCaretRightFill, BsExclamationCircleFill, BsFillArrowLeftCircleFill, BsFillGearFill, BsFillXCircleFill } from 'react-icons/bs';
import { Server } from '../../server/server';
import PageEnvelope from '../elements/PageEnvelope';
import Portrait from '../elements/Portrait';
import MessageModal from '../modals/MessageModal';
import AlertModal from '../modals/AlertModal';
import QuestionModal from '../modals/QuestionModal';
import './SitePage.css'
import './ChatPage.css'
import ContentDiv from '../elements/ContentDiv';
import { getPortraitImage } from '../util/assets';
import PostPanel from '../elements/PostPanel';
import { Navigate } from 'react-router-dom';
import { formatDate } from '../util/util';

interface ChatPageProps {
}

interface ChatPageState {
  id: string;
  loading: boolean;
  message: string;
  alert: string;
  question: string;
  scrollToBottom: boolean;
  unreadMessages: boolean;
  solo: boolean;
  showNewChatModal: boolean;
  showFriendsModal: boolean;
  showGroupModal: boolean;
  groupName: string;
  groupMembers: any[];
  groupNew: boolean;
  selectFriendCallback: Function;
  selectFriends: any[];
}

class ChatPage extends React.Component<ChatPageProps, ChatPageState> {
  protected messagesDiv: React.RefObject<HTMLDivElement>;
  protected updatingChats: boolean;
  protected imgUrl: string;
  protected timer: any;

  constructor(props:ChatPageProps) {
    super(props);
    this.state = {
      id: '',
      loading: false,
      message: '',
      alert: '',
      question: '',
      scrollToBottom: false,
      unreadMessages: false,
      solo: false,
      showNewChatModal: false,
      showFriendsModal: false,
      showGroupModal: false,
      groupName: '',
      groupMembers: [],
      groupNew: false,
      selectFriendCallback: null,
      selectFriends: []
    }

    this.updatingChats = false;

    this.messagesDiv = React.createRef();
    this.onChatUpdated = this.onChatUpdated.bind(this);
    this.onChatStarted = this.onChatStarted.bind(this);
    this.onChatLeft = this.onChatLeft.bind(this);
  }

  componentDidMount(): void {
    Server.chat.addEventListener('chat-updated', this.onChatUpdated);
    Server.chat.addEventListener('chat-started', this.onChatStarted);
    Server.chat.addEventListener('chat-left', this.onChatLeft);

    this.loadPage();
    this.timer = setInterval(() => {
      this.checkScrollPosition();
    }, 100);
  }

  componentDidUpdate(prevProps: Readonly<ChatPageProps>, prevState: Readonly<ChatPageState>): void {
    if(prevState == this.state && this.isMobile()) {
      this.setState({id: ''});
      return;
    }
    
    if(this.messagesDiv.current) {
      if(prevState.loading && !this.state.loading)
        this.scrollToBottom();

      if(prevState.id == this.state.id && !prevState.scrollToBottom && this.state.scrollToBottom) {
        this.scrollToBottom();
        this.setState({scrollToBottom: false})
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    Server.chat.removeEventListener('chat-updated', this.onChatUpdated);
    Server.chat.removeEventListener('chat-started', this.onChatStarted);
    Server.chat.removeEventListener('chat-left', this.onChatLeft);
  }

  scrollToBottom() {
    if(this.messagesDiv.current)
      this.messagesDiv.current.scrollTop = this.messagesDiv.current.scrollHeight;
  }

  getChats() {
    let chats = [...Server.chat.getChats()];

    chats.sort((a, b)=>{
      if(a.id == 'community')
        return -1;
      else if(b.id == 'community')
        return 1;

      if(a.date > b.date)
        return -1;
      else if(a.date < b.date)
        return 1;

      return 0;
    })

    return chats;
  }

  isMobile() {
    return window.matchMedia('(max-width: 600px)').matches;
  }

  async loadPage() {
    if (this.isMobile()) 
      this.setState({id: ''});
    else {
      let id = 'community';

      let savedId = window.sessionStorage.getItem('ChatPageId');
      if(savedId)
        id = savedId;

      this.setState({id});
      await this.loadChat(id);
    }

    let users = Server.chat.getUsersFromChats();
    await Server.public.loadProfiles(users);

    this.setState({scrollToBottom: true});
  }

  async loadChat(id:string) {
    this.setState({id, loading: true, solo: false});
    
    await Server.chat.getChatMessages(id);

    let chat = Server.chat.getChat(id);

    if(!chat) {
      this.setState({id: '', loading: false});
      return;
    }

    let users = Server.chat.getUsersFromChatMessages(id);
    await Server.public.loadProfiles(users);
    
    this.setState({loading: false});

    Server.chat.updateLastRead(id);
    window.sessionStorage.setItem('ChatPageId', id);

    if(chat)
      this.setState({solo: (chat.members.length == 0)})
  }

  checkScrollPosition() {
    let div = this.messagesDiv.current;
    if(!div) return;

    let scrolledToBottom = (div.scrollTop == (div.scrollHeight - div.offsetHeight));

    if(scrolledToBottom && this.state.unreadMessages) {
      Server.chat.updateLastRead(this.state.id);
      this.setState({unreadMessages: false});
    }
  }

  onChatUpdated(data:any) {
    if(this.state.id == data.chat) {
      let div = this.messagesDiv.current;
      let scrolledToBottom = (div.scrollTop == (div.scrollHeight - div.offsetHeight));
      if(scrolledToBottom) {
        Server.chat.updateLastRead(this.state.id);
        this.setState({scrollToBottom: true});
      }
      else
        this.setState({unreadMessages: true});
    }
    else
      this.forceUpdate();
  }

  onChatStarted(data:any) {
    if(this.state.id == data.chat)
      this.loadChat(data.chat);
    else
      this.forceUpdate();
  }

  onChatLeft(data:any) {
    if(this.state.id == data.chat) {
      let chat = Server.chat.getChat(this.state.id);
      this.setState({solo: (chat.members.length == 0)});
    }
  }

  async onSend(content:string) {
    let promise = Server.chat.sendChatMessage(this.state.id, content);

    Server.chat.updateLastRead(this.state.id);

    this.forceUpdate();
    this.setState({scrollToBottom: true});

    await Promise.all([promise]);

    Server.chat.updateLastRead(this.state.id);

    Server.user.incrementCounter('chat#message');

    this.forceUpdate();
    this.setState({scrollToBottom: true});
  }

  onChat(id:string) {
    this.loadChat(id);
  }

  onBack() {
    this.setState({id: ''});
  }

  onNewChat() {
    if(!Server.account.isLoggedIn()) {
      this.setState({alert: 'Please login to start a new chat with a friend.'});
      return;
    }

    this.setState({showNewChatModal: true});
  }

  getFriends(filter:string = '') {
    let userFriends = Server.user.getFriends();
   
    let friends = [];
    for(let i = 0; i < userFriends.length; i++) {
      if(filter == 'direct' && Server.chat.getDirectChat(userFriends[i].id)) 
        continue;
      if(filter == 'member') {
        let member = this.state.groupMembers.find(u => u.id == userFriends[i].id);
        if(member)
          continue;
      } 
      friends.push(Server.public.getProfile(userFriends[i].id));
    }

    friends.sort((a, b)=>{
      if(a.name > b.name)
        return 1;
      else if(a.name < b.name)
        return -1;
      return 0;
    })

    return friends;
  }

  onDirectChat() {
    let friends = this.getFriends('direct');

    if(friends.length == 0) {
      this.setState({alert: 'You are already chatting with all of your friends.'});
      return;
    }

    this.setState({
      showNewChatModal: false, 
      showFriendsModal: true, 
      selectFriends: friends,
      selectFriendCallback: (friend:any)=>{this.onDirectFriend(friend)} 
    });
  }

  onDirectFriend(friend:any) {
    this.setState({showFriendsModal: false});

    if(friend)
      this.startDirectChat(friend.id);
    else
      this.setState({showNewChatModal: true})
  }

  onGroupChat() {
    this.setState({
      showNewChatModal: false,
      showGroupModal: true, 
      groupName: '',
      groupMembers: [],
      groupNew: true
    });
  }

  onAddFriend() {
    let friends = this.getFriends('member');

    if(friends.length == 0) {
      this.setState({alert: 'All of your friends are already in this group chat.'});
      return;
    }

    this.setState({
      showGroupModal: false, 
      showFriendsModal: true,
      selectFriends: friends,
      selectFriendCallback: (friend:any)=>{this.onGroupFriend(friend)} 
    });
  }

  onGroupFriend(friend:any) {
    let members = [...this.state.groupMembers];

    if(friend)
      members.push(friend);
    
    this.setState({
      showFriendsModal: false,
      showGroupModal: true,
      groupMembers: members
    })
  }

  onCreateGroup() {
    if(this.state.groupName == '') {
      this.setState({alert: 'Please name the group.'});
      return;
    }

    if(this.state.groupMembers.length == 0) {
      this.setState({alert: 'Please add friends to the group.'});
      return;
    }

    this.startGroupChat();
  }

  onGroupSettings() {
    let chat = Server.chat.getChat(this.state.id);

    let members = [];
    for(let i = 0; i < chat.members.length; i++)
      members.push(Server.public.getProfile(chat.members[i]))

    this.setState({
      showNewChatModal: false,
      showGroupModal: true, 
      groupName: chat.name,
      groupMembers: members,
      groupNew: false
    });
  }

  onSaveGroup() {
    if(this.state.groupName == '') {
      this.setState({alert: 'Please name the group.'});
      return;
    }

    if(this.state.groupMembers.length == 0) {
      this.setState({alert: 'Please add friends to the group.'});
      return;
    }

    this.updateGroupChat();
  }

  startDirectChat(friendId:string) {
    this.setState({message: 'Starting chat...'});

    setTimeout(async () => {
      let response = await Server.chat.startDirectChat(friendId);
      this.setState({message: ''});

      if(response.success) {
        await Server.user.incrementCounter('chat#new#direct')
        this.loadChat(response.id);
      }
      else
        this.setState({alert: response.message})
    }, 500);
  }

  startGroupChat() {
    this.setState({message: 'Starting chat...'});

    setTimeout(async () => {
      let members = [];
      for(let i = 0; i < this.state.groupMembers.length; i++)
        members.push(this.state.groupMembers[i].id);

      let response = await Server.chat.startGroupChat(this.state.groupName, members);
      this.setState({message: ''});

      if(response.success) {
        await Server.user.incrementCounter('chat#new#group')
        this.setState({showGroupModal: false});
        this.loadChat(response.id);
      }
      else
        this.setState({alert: response.message})
    }, 500);
  }

  updateGroupChat() {
    this.setState({message: 'Saving settings...'});

    setTimeout(async () => {
      let members = [];
      for(let i = 0; i < this.state.groupMembers.length; i++)
        members.push(this.state.groupMembers[i].id);

      let response = await Server.chat.updateGroupChat(this.state.id, this.state.groupName, members);
      this.setState({message: ''});

      if(response.success) {
        this.setState({showGroupModal: false});
        this.forceUpdate();
      }
      else
        this.setState({alert: response.message})
    }, 500);
  }

  onLeaveChat() {
    this.setState({question: 'Do you want to leave this chat?'})
  }

  onQuestionYes() {
    this.setState({question: '', message: 'Leaving chat...'});

    setTimeout(async () => {
      let response = await Server.chat.leaveChat(this.state.id);
      this.setState({message: ''});
      if(response.success) 
        this.loadChat('community');
      else
        this.setState({alert: response.message});
    }, 500);
  }

  renderChats() {
    let chats = this.getChats();

    let divs = [];
    for(let i = 0; i < chats.length; i++) {
      let notify = false;
      let name = '';
      let image = null;

      if(chats[i].id == 'community') {
        name = 'Community';
        image = <img src="playplace.png" style={{width: '40px'}}/>
      }
      else if(chats[i].type == 'direct') {
        let profile  = Server.public.getProfile(chats[i].user);
        if(!profile) {
          console.warn('Missing profile in renderChats!', chats[i].user);
          continue;
        }
        name = profile.name;
        image = <Portrait user={chats[i].user} />
        notify = Server.chat.isNewChatMessages(chats[i].id) && (chats[i].id != this.state.id);
      }
      else if(chats[i].type == 'group') {
        name = chats[i].name;
        image = <Portrait user={Server.user.getId()} />
        notify = Server.chat.isNewChatMessages(chats[i].id) && (chats[i].id != this.state.id);
      }

      divs.push(
        <div key={i} className="site-page-panel" style={{cursor:'pointer'}} onClick={()=>this.onChat(chats[i].id)}>
          <div className="site-page-row">
            {image}
            <div className="site-page-title-text" style={{flexGrow: '1'}}>{name}</div>
            {notify &&
              <div className="site-page-notify-circle">
                <BsExclamationCircleFill size="1.4em" />
              </div>
            }
            <div style={{fontSize: '1.4em', marginTop: '5px'}}><BsCaretRightFill /></div>
          </div>
        </div>
      )
    }

    return (
      <div className="site-page">
        <PageEnvelope width="500px">
          <div className="site-page-column">
            {divs}
          </div>
        </PageEnvelope>
      </div>
    );
  }

  renderSidebar() {
    let chatDivs = [];
    let chats = this.getChats();

    for(let i = 0; i < chats.length; i++) {
      let name = '';
      let image = null;

      if(chats[i].id == 'community') {
        name = 'Community';
        image = <img src="playplace.png" style={{width: '40px'}}/>
      }
      else if(chats[i].type == 'direct') {
        let profile  = Server.public.getProfile(chats[i].user);
        if(!profile) 
          continue;
        name = profile.name;
        image = <Portrait user={chats[i].user} />
      }
      else if(chats[i].type == 'group') {
        name = chats[i].name;
        let images = [];
        let members = [...chats[i].members];
        members.unshift(Server.user.getId());
        let gap = Math.min(25, (100 - 44) / (members.length-1));
        for(let j = 0; j < members.length; j++) {
          let left = (j * gap) + 'px';
          let profile = Server.public.getProfile(members[j]);
          if(!profile) 
            continue;
          images.push(<img key={j} className="site-page-portrait" style={{position: 'absolute', left}} src={getPortraitImage(profile)} />)
        }
        let width = (44 + (gap * (members.length - 1))) + 'px';
        image = <div style={{position: 'relative', height: '44px', width}}>{images}</div>
      }

      let className = 'chat-page-chat-panel';
      if(chats[i].id == this.state.id)
        className += ' active';

      let notify = Server.chat.isNewChatMessages(chats[i].id);

      chatDivs.push(
        <div key={i} className={className} onClick={()=>this.onChat(chats[i].id)}>
          {image}
          <div className="chat-page-chat-name">{name}</div>
          {notify &&
            <div className="chat-page-notify-circle">
              <BsExclamationCircleFill size="1.4em" />
            </div>
          }
        </div>
      )
    }

    chatDivs.splice(1, 0, 
      <button key={chats.length} className="chat-page-new-chat-button" style={{minHeight: '30px', fontSize: '1.0em !important'}} onClick={()=>this.onNewChat()}>
        + New Chat
      </button>    
    )

    return chatDivs;
  }

  renderMessages(chat:any) {
    let messages:any[] = [];
    if(!chat || !chat.messages)
      return messages;

    let combinedMessages = [];
    for(let i = 0; i < chat.messages.length; i++) {
      let entry = chat.messages[i];
      let combined = false;

      if(combinedMessages.length > 0) {
        let lastCombinedMessage = combinedMessages[combinedMessages.length-1];
        if(lastCombinedMessage.user == entry.user && !entry.failed) {
          let thisDate = new Date(entry.date);
          let prevDate = new Date(lastCombinedMessage.date);
          let diff = (thisDate.getTime() - prevDate.getTime()) / 1000 / 60;
          if(diff < 10) {
            lastCombinedMessage.messages.push(entry.message);
            combined = true;
          }
        }
      }

      if(!combined) {
        combinedMessages.push({
          date: entry.date,
          user: entry.user,
          messages: [entry.message],
          failed: entry.failed ? true : false
        });
      }
    }

    // for checking an image URL
    for (let i = 0; i < combinedMessages.length; i++) {
      let msg = combinedMessages[i];
      let profile = Server.public.getProfile(msg.user);

      let timeDiv = null;
      if(msg.failed)
        timeDiv = <div style={{fontSize: '0.75em', color: 'var(--notification-background-color)'}}>FAILED TO SEND</div>
      else
        timeDiv = <div style={{fontSize: '0.75em', color: 'var(--panel-background-color)'}}>{formatDate(msg.date)}</div>

      let contentDivs = [];
      for(let j = 0; j < msg.messages.length; j++) 
        contentDivs.push(<ContentDiv key={j} content={msg.messages[j]} onImageLoad={()=>{this.scrollToBottom()}} />)

      messages.push(
        <div key={i} className="chat-message-row">
          <div><img className="site-page-portrait chat-message-portrait" src={getPortraitImage(profile)} /></div>
          <div className="site-page-column" style={{rowGap: '4px', marginTop: '3px'}}>
            <div className="site-page-row" style={{columnGap: '6px'}}>
              <div><b>{profile.name}</b></div>
              {timeDiv}
            </div>
            <div className="site-page-column" style={{rowGap: '6px'}}>
              {contentDivs}
            </div>
          </div>
        </div>
      )
    }

    return messages;
  }

  renderNewChatModal() {
    return (
      <div className="modal open">
        <div className="modal-content" style={{width: '300px'}}>
          <div className="site-page-column">
            <div className="site-page-row" style={{justifyContent: 'space-between'}}>
              <div style={{fontSize: '1.1em', paddingBottom: '6px'}}>New Chat</div>
              <div style={{cursor: 'pointer', color: 'var(--panel-subtext-color)'}} onClick={()=>this.setState({showNewChatModal: false})}>
                <BsFillXCircleFill size={22} />
              </div>
            </div>
            <div className="new-chat-modal-button" onClick={()=>this.onDirectChat()}>
              <div>Direct Chat</div>
              <div style={{color: '#000000a0'}}>Start a one-on-one chat with any of your friends.</div>
            </div>
            <div className="new-chat-modal-button" onClick={()=>this.onGroupChat()}>
              <div>Group Chat</div>
              <div style={{color: '#000000a0'}}>Create a private chat room for you and your friends.</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderFriendsModal() {
    let friends = this.state.selectFriends;

    let divs = [];
    for(let i = 0; i < friends.length; i++) {
      divs.push(
        <div key={i} className="chat-page-select-friend" onClick={()=>this.state.selectFriendCallback(friends[i])}>
          <div><Portrait user={friends[i].id} /></div>
          <div>{friends[i].name}</div>
        </div>
      )
    }

    return (
      <div className="modal open">
        <div className="modal-content" style={{width: '300px', maxHeight: '400px'}}>
          <div className="site-page-column">
            <div className="site-page-row">
              <div style={{cursor: 'pointer', color: 'var(--panel-subtext-color)'}} onClick={()=>this.state.selectFriendCallback(null)}>
                <BsFillArrowLeftCircleFill size={22} />
              </div>
              <div style={{fontSize: '1.1em', paddingBottom: '6px'}}>Select Friend</div>
            </div>
            <div className="chat-page-select-friend-container">
              {divs}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderGroupModal() {
    let members = this.state.groupMembers;

    let divs = [];
    for(let i = 0; i < members.length; i++) {
      divs.push(
        <div key={i} className="chat-page-select-friend">
          <div><Portrait user={members[i].id} /></div>
          <div>{members[i].name}</div>
        </div>
      )
    }

    if(members.length == 0) {
      divs.push(
        <div key={0} className="site-page-subtext">There are no friends in this group chat.</div>
      )
    }

    return (
      <div className="modal open">
        <div className="modal-content" style={{width: '300px'}}>
          <div className="site-page-column" style={{rowGap: '5px'}}>
            <div className="site-page-row" style={{justifyContent: 'space-between'}}>
              <div className="site-page-row">
                {this.state.groupNew &&
                  <div style={{cursor: 'pointer', color: 'var(--panel-subtext-color)'}} onClick={()=>this.setState({showGroupModal: false, showNewChatModal: true})}>
                    <BsFillArrowLeftCircleFill size={22} />
                  </div>
                }
                <div style={{fontSize: '1.1em', paddingBottom: '6px'}}>Group Chat</div>
              </div>
              {!this.state.groupNew &&
                <div style={{paddingTop: '3px', cursor: 'pointer', color: 'var(--panel-subtext-color)'}} onClick={()=>this.setState({showGroupModal: false})}>
                  <BsFillXCircleFill size={22} />
                </div>
              }
            </div>
            <div style={{height: '5px'}}/>
            <div>Name:</div>
            <input value={this.state.groupName} onChange={(e:any)=>this.setState({groupName: e.currentTarget.value})} />
            <div style={{height: '5px'}}/>
            <div>Members:</div>
            <div className="chat-page-select-friend-container">
              {divs}
            </div>
            <div style={{height: '10px'}}/>
            <div className="site-page-row" style={{justifyContent: 'space-between'}}>
              <button onClick={()=>this.onAddFriend()}>Add Friend</button>
              {this.state.groupNew ? 
                <button onClick={()=>this.onCreateGroup()}>Create Group</button> : 
                <button onClick={()=>this.onSaveGroup()}>Save Settings</button>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderChat() {
    let chat = Server.chat.getChat(this.state.id);
    let name = '';

    if(this.state.id == 'community') {
      name = 'Community';
    }
    else if(chat && chat.type == 'direct') {
      let profile = Server.public.getProfile(chat.user);
      name = profile.name;
    }
    else if(chat && chat.type == 'group') {
      name = chat.name;
    }

    return (
      <div className="chat-page">
        <div className="chat-page-panel">
          <div className="chat-page-sidebar">
            {this.renderSidebar()}
          </div>
          <div className="chat-page-content">
            <div className="chat-page-header">
              <div>
                {name}
              </div>
              <div className="chat-page-header-back" onClick={()=>this.onBack()} >
                <BsFillArrowLeftCircleFill size={22} />
              </div>
              {chat && chat.type == 'group' &&
                <div className="chat-page-header-settings" onClick={()=>this.onGroupSettings()}>
                  <BsFillGearFill size={22} />
                </div>
              }
              {this.state.id != 'community' &&
                <div className="chat-page-header-leave" onClick={()=>this.onLeaveChat()}>
                  <BsFillXCircleFill size={22} />
                </div>
              }
              {this.state.unreadMessages &&
                <div className="chat-page-notification" onClick={()=>this.setState({scrollToBottom: true})}>
                  <div>New messages!</div>
                  <div>Jump to End</div>
                </div>
              }
              {!this.state.unreadMessages && this.state.solo && this.state.id != 'community' &&
                <div className="chat-page-notification">
                  <div>Everyone has left the chat.</div>
                </div>
              }
              {!Server.account.isLoggedIn() &&
                <div className="chat-page-notification">
                  <div>Login to view the community chat.</div>
                </div>
              }
            </div>
            <div className="chat-page-messages" ref={this.messagesDiv}>
              {this.state.loading ? <div>Loading...</div> : this.renderMessages(chat)}
            </div>
            <div className="chat-page-footer">
              <PostPanel button="Send" onPost={(content:string)=>this.onSend(content)} />
            </div>
          </div>
        </div>
        {this.state.showNewChatModal && this.renderNewChatModal()}
        {this.state.showFriendsModal && this.renderFriendsModal()}
        {this.state.showGroupModal && this.renderGroupModal()}
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
        <MessageModal message={this.state.message} />
        <QuestionModal message={this.state.question} onYes={()=>this.onQuestionYes()} onNo={()=>this.setState({question: ''})} />
      </div>
    )
  }

  render() {
    if(!Server.account.isLoggedIn())
      return <Navigate replace to="/" />;
      
    if(this.state.id == '')
      return this.renderChats();
    else
      return this.renderChat();
  }
}

export default ChatPage;
