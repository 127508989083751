import { UIDialogOptions } from '../common/ui/uiDialog';

export class GameStyle {
  public static fonts = {
    small: {
      family: 'arial',
      size: 14,
      textOffset: {x: 0, y: 0}
    },
    normal: {
      family: 'arial',
      size: 18,
      textOffset: {x: 0, y: -2}
    },
    large: {
      family: 'arial',
      size: 24,
      textOffset: {x: 0, y: 0}
    }
  };

  public static dialog:UIDialogOptions = {
    panel: {
      radius: 20,
      color: 'white',
      borderColor: 'gray',
      borderWidth: 5,
      shadow: {
        color: '#00000080',
        blur: 8,
        offset: {x: 6, y: 6}
      }
    },
    title: {
      text: '',
      color: 'black',
      fontFamily: GameStyle.fonts.large.family,
      fontSize: GameStyle.fonts.large.size,
      fontWeight: '700'
    },
    message: {
      text: '',
      color: 'black',
      fontFamily: GameStyle.fonts.normal.family,
      fontSize: GameStyle.fonts.normal.size
    },
    subtext: {
      text: '',
      color: 'black',
      fontFamily: GameStyle.fonts.normal.family,
      fontSize: GameStyle.fonts.normal.size-1
    },
    button: {
      radius: 10,
      panelColors: {normal: 'blue', disabled: 'gray'},
      textColors: {normal: 'white', pressed: 'white'},
      fontFamily: GameStyle.fonts.normal.family,
      fontSize: GameStyle.fonts.normal.size
    },
    scrollBar: {
      element: null,
      color : '#00000040',
      thumbColor : 'blue',
      radius : 5
    },
    field: {
      panel: {
        color: '#00000040',
        radius: 10
      },
      input: {
        color: 'white',
        fontFamily: GameStyle.fonts.normal.family,
        fontSize: GameStyle.fonts.normal.size
      }
    }
  };

  public static results = {
    label: {
      color: 'black',
      fontFamily: GameStyle.fonts.small.family,
      fontSize: GameStyle.fonts.small.size,
      fontWeight: 'normal'
    },
    score: {
      color: 'black',
      fontFamily: GameStyle.fonts.large.family,
      fontSize: GameStyle.fonts.large.size,
      fontWeight: '700',
    }
  }
}
