import React from 'react';
import { getPortraitImage } from '../util/assets';
import { Server } from '../../server/server';
import '../pages/SitePage.css';

interface PortraitProps {
  user: string;
  size?: number;
  onClick?: Function;
  hideOnline?: boolean;
}

class Portrait extends React.Component<PortraitProps, {}> {
  constructor(props: PortraitProps) {
    super(props);
    this.onFriendUpdated = this.onFriendUpdated.bind(this);
  }
  
  componentDidMount(): void {
    Server.user.addEventListener('friend-updated', this.onFriendUpdated);
  }

  componentWillUnmount() {
    Server.user.removeEventListener('friend-updated', this.onFriendUpdated);
  }

  onFriendUpdated(data:any) {
    if(data.friend == this.props.user)
      this.forceUpdate();
  }

  onClick(e:any) {
    if(this.props.onClick) {
      e.preventDefault();
      e.stopPropagation();
      this.props.onClick(this.props.user);
    }
  }

  render() {
    let user = Server.public.getProfile(this.props.user);

    let online = false;
    if(Server.account.isLoggedIn()) {
      if(this.props.user == Server.user.getId()) 
        online = true;
      else {
        let friend = Server.user.getFriend(this.props.user);
        online = (friend && friend.presence && friend.presence.online);
      }
    }

    if(this.props.hideOnline)
        online = false;

    let size = 44;
    if(this.props.size)
      size *= this.props.size;

    return (
      <div style={{display: 'flex', position: 'relative'}} onClick={(e:any)=>this.onClick(e)}>
        <img className="site-page-portrait" style={{width: size + 'px', height: size + 'px'}} src={getPortraitImage(user)} />
        {online &&
          <div className="site-page-online-circle"/>
        }
      </div>
    )
  }
}

export default Portrait;